import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';

class AccountSupportDropdown extends React.Component {
  constructor(props) {
    super(props);
    const { actions } = props;
    const defaultActions = {
      Alerts: {
        redirectURL: '/alerts',
        optionName: 'Manage account alerts',
      },
      Messaging: {
        redirectURL: '/messaging-twoway/view',
        optionName: 'Send a message',
      },
    };
    const allActions = Object.assign({}, actions, defaultActions);
    this.state = {
      allActions,
      form: {
        accountOptions: {
          value: '',
          rules: {},
        },
      },
    };
  }

  onChangeTarget = e => {
    const { history } = this.props;
    const { allActions } = this.state;
    const { value } = e.target;
    if (value === '') {
      return;
    }

    history.push(allActions[value].redirectURL);
  };

  accountOptions() {
    const { supports } = this.props;
    const { allActions } = this.state;

    const elements = [];
    if (allActions) {
      const keys = Object.keys(allActions);

      keys.forEach(key => {
        if (allActions[key].optionName && supports.includes(key)) {
          elements.push(
            <option
              id={`account-support-dropdown-option-${key.toLowerCase()}`}
              key={`${key.toLowerCase()}`}
              value={key}
              defaultText={allActions[key].optionName}
            />,
          );
        }
      });
    }
    return elements;
  }

  render() {
    const { form } = this.state;
    const options = this.accountOptions();
    return options.length !== 0 ? (
      <DropDown
        cmsTag="GLOBAL:Account-options"
        label="Account options"
        id="account-support-dropdown"
        key={Date.now()} //Force redraw when refreshing due to a bug in the core dropdown component
        field="accountOptions"
        onChange={this.onChangeTarget}
        validation={form.accountOptions}
      >
        <option
          key="0"
          id="account-support-dropdown-default"
          value=""
          defaultText="Please select"
        />
        {options}
      </DropDown>
    ) : null;
  }
}

AccountSupportDropdown.propTypes = {
  account: PropTypes.any,
  actions: PropTypes.any,
  history: PropTypes.any,
  supports: PropTypes.any,
};

export default withRouter(AccountSupportDropdown);
