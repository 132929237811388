import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Content,
  Markdown,
  Check,
  DropDown,
  LinkTag,
  AppMeta,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { CONSTANTS } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import TransferForm from './TransferForm';
import checkDownTime from '../../../shared/HO_components/checkDownTime';
import moment from 'moment';

class Create extends Component {
  submitForm = (form, isSubmit = false) => {
    const { submitForm } = this.props;
    submitForm('ReviewTransfer', form, isSubmit);
  };

  accountOptions(prefix, item, index, extendedId) {
    return (
      <option
        id={`accountFormValidation-default-${extendedId}-option-${index}`}
        key={prefix + item.AccountKey.Context}
        value={item.AccountKey.Key}
      >
        {item.AccountName} {item.AccountNumber}
      </option>
    );
  }

  getWithdrawalsOfYear = account => {
    const { Transactions: transactions = null } = account || {};
    if (!transactions) {
      return [];
    }

    const startOfYear = moment()
      .startOf('year')
      .toISOString();
    const now = moment()
      .startOf('minute')
      .toISOString();
    if (transactions) {
      return transactions.filter(trans => {
        const {
          ExtendedProperties: { GlobalTransactionType, TransactionDate },
        } = trans;
        return (
          moment(TransactionDate).isBetween(startOfYear, now) &&
          GlobalTransactionType === CONSTANTS.TRANSACTION_TYPE_WITHDRAW
        );
      });
    }
  };

  isWithdrawalPermitted = (account, maxWithdrawals) => {
    const withdrawalsOfYear = this.getWithdrawalsOfYear(account);
    if (maxWithdrawals === CONSTANTS.TRANSACTION_MAX_WITHDRAWAL_UNLIMITED) {
      return true;
    }
    const noOfWithdrawals = withdrawalsOfYear.length;
    if (noOfWithdrawals && noOfWithdrawals >= maxWithdrawals) {
      return false;
    }
    return true;
  };

  render() {
    const {
      accounts = {},
      onChangeAccount,
      onCancelled,
      isFetching,
      accountForm,
      setStateDynamic,
      noticeFilter,
      data,
      account,
      allProducts,
      systemDownStatus, //From checkDownTime HOC
      linkedNominatedAccount,
    } = this.props;

    let { NominatedAccount } = linkedNominatedAccount || {};

    if (
      account &&
      !NominatedAccount &&
      !isFetching &&
      Object.keys(account).length
    ) {
      NominatedAccount =
        accounts?.ExtendedProperties?.NominatedAccountDetails?.NominatedAccount;
    }
    let options = [];
    if (accounts) {
      options = noticeFilter(accounts ? accounts.Accounts : []);
      if (!options.length) {
        return <Redirect to="/nominated-account/no-nominated-account" />;
      }
    }
    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }
    let withdrawPermitted = true;
    let product = null;
    if (account) {
      const product = allProducts.find(
        product =>
          product.Product_Code === account.ExtendedProperties.ProductCode,
      );
      withdrawPermitted = this.isWithdrawalPermitted(
        account,
        product.Max_Withdrawals_Per_Year,
      );
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Withdraw to your nominated account step 1"
          metaDescription="Withdraw to your nominated account step 1"
        />
        {systemDownStatus.msg}
        <div className="alert alert-info">
          <Content
            tag="h2"
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:alert-1:h2-1"
            copytext="Protect yourself from fraud"
          />
          <Content
            tag="p"
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:alert-1:p1"
            copytext="Before you proceed please take a moment to ensure that:"
          />
          <ul>
            <li>
              <Markdown
                cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:alert-1:li-1"
                template={{
                  markdown: {
                    companyName: CONTACTS.companyName,
                  },
                }}
                markdown={`You haven't been called by someone asking you to move your money, even by someone claiming to be from $[companyName].`}
              />
            </li>
            <li>
              <Markdown
                cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:alert-1:li-2-part-1"
                markdown={`You are happy with the reason for this payment and have read the relevant scam advice from `}
              />
              <LinkTag
                target="_blank"
                id="friendsagainstscams-link"
                href={'https://www.friendsagainstscams.org.uk/'}
              >
                <Content
                  cmsTag="GLOBAL:friendsagainstscams.org.uk"
                  copytext="friendsagainstscams.org.uk"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-opens-in-a-new-window"
                  copytext=" (opens in a new window)"
                />
              </LinkTag>
              <Markdown
                cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:alert-1:li-2-part-2"
                markdown={`.`}
              />
            </li>
          </ul>
          <Markdown
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
              },
            }}
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:alert-1:p2"
            markdown={`\nIf you have any concerns, please do not complete this transaction and contact us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
          />
        </div>
        <Check
          cmsTag="GLOBAL:I-have-considered-the-information-on-this-page-and-am-happy-to-proceed-with-this-withdrawal"
          label="I have considered the information on this page and am happy to proceed with this withdrawal."
          id="accept"
          field="accept"
          checked={!!accountForm.accept.value}
          value="true"
          validation={accountForm.accept}
          onChange={this.props.onClick}
        />
        <section className="mt-5">
          <Content
            tag="h2"
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:h2-1"
            copytext="Withdrawal details"
          />
          <Markdown
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:section-1"
            markdown={`\nWe will need some information to transfer your money.\n\nSome of our savings accounts are subject to conditions, such as notice periods and withdrawal restrictions.\n\nPlease check the terms and conditions of your Darlington savings account first to ensure you can withdraw money to your nominated account. You can find this in the "Account Details" section.`}
          />
          <DropDown
            cmsTag="GLOBAL:Account-to-withdraw-from"
            label={'Account to withdraw from'}
            id="accountFormValidation"
            key={Date.now() + '1'} //Force redraw when refreshing due to a bug in the core dropdown component
            field="accountFormValidation"
            onChange={onChangeAccount}
            disabled={!accountForm.accept.value}
            validation={accountForm.accountFormValidation}
          >
            <option
              id={`accountFormValidation-default`}
              key="0"
              value=""
              defaultText="Please select"
            ></option>
            {options.map((item, index) => {
              return this.accountOptions('accountType', item, index, 'from');
            })}
          </DropDown>
          {/* <RedirectByAccountDropdown
          label={'Account to withdraw from'}
          extendedId="withdraw-from"
          accounts={accounts}
          optionFunc={this.noticeFilter}
          baseUrl="/nominated-account/create/moveMoney/"
          defaultSelect={`${
            account && account.AccountKey ? account.AccountKey.Key : null
          }`}
          onChangeAccount={onChangeAccount}
          disabled={!form.accept.value}
          filter="noticeCreate"
          id="craete-notice-to-withdraw"
        />
        {accountForm ? (
          <ListItem
            key={'account-0'}
            validation={accountForm.accountFormValidation}
            cmsTag={accountForm.cmsTag}
            tagType={'p'}
            index={0}
            field={'accountFormValidation'}
            id={'0'}
          />
        ) : (
          ''
        )} */}
        </section>
        {account && !NominatedAccount && !isFetching && (
          <div role="alert" className="alert alert-danger">
            <Content
              cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:No-nominated-account:error"
              copytext="The selected account does not have any nominated account."
            />
          </div>
        )}
        {account &&
        NominatedAccount &&
        !isFetching &&
        Object.keys(account).length ? (
          <React.Fragment>
            <section>
              <Content
                tag="h2"
                cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Create:h2-2"
                copytext="Nominated account for withdrawals"
              />
              <Row tag="dl" title="Nominated account details">
                <Col tag="dt" xs={12} md={4} lg={3}>
                  <Content
                    cmsTag="GLOBAL:Account-name"
                    copytext="Account name"
                  />
                </Col>
                <Col
                  id="nominated-account-account-name"
                  tag="dd"
                  xs={6}
                  md={8}
                  lg={9}
                >
                  {NominatedAccount?.Name ? NominatedAccount.Name : ''}
                </Col>

                <Col tag="dt" xs={12} md={4} lg={3}>
                  <Content
                    cmsTag="GLOBAL:Account-number"
                    copytext="Account number"
                  />
                </Col>
                <Col
                  id="nominated-account-number"
                  tag="dd"
                  xs={6}
                  md={8}
                  lg={9}
                >
                  {NominatedAccount?.AccountNumber
                    ? NominatedAccount.AccountNumber
                    : ''}
                </Col>
                <Col tag="dt" xs={12} md={4} lg={3}>
                  <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
                </Col>
                <Col id="nominated-sort-code" tag="dd" xs={6} md={8} lg={9}>
                  {NominatedAccount?.SortCode ? NominatedAccount.SortCode : ''}
                </Col>
              </Row>
            </section>
          </React.Fragment>
        ) : (
          ''
        )}

        <TransferForm
          account={account}
          callAccountValidations={this.props.callAccountValidations}
          saveData={this.props.saveData}
          accountForm={accountForm}
          data={data}
          setStateDynamic={setStateDynamic}
          onCancelled={onCancelled}
          combineForm={this.props.combineForm}
          onSubmit={this.submitForm}
          withdrawPermitted={withdrawPermitted}
          maxWithdrawals={product ? product.Max_Withdrawals_Per_Year : 0}
          nominatedAccount={NominatedAccount}
        />
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  accounts: PropTypes.any,
  data: PropTypes.any,
  saveData: PropTypes.any,
  setStateDynamic: PropTypes.func,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  actionDate: PropTypes.any,
  onChangeAccount: PropTypes.func,
  callAccountValidations: PropTypes.func,
  combineForm: PropTypes.any,
  onCancelled: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  accountForm: PropTypes.any,
  account: PropTypes.any,
  noticeFilter: PropTypes.func,
  submitForm: PropTypes.any,
  onClick: PropTypes.any,
  allProducts: PropTypes.array,
  systemDownStatus: PropTypes.object,
  linkedNominatedAccount: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default checkDownTime(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
  )(withRouter(Create)),
);
