import forgottenUserNameDefinition from './reducers/forgottenUserNameDefinition';
import registrationDefinition from './reducers/registrationDefinition';
import loginDefinition from './reducers/loginDefinition';
import forgottenPasswordDefinition from './reducers/forgottenPasswordDefinition';
import updateSecurityDetailsDefinition from './reducers/updateSecurityDetailsDefinition';

const authenticationDefinition = (state = {}, action) => {
  let newState = state;
  newState = forgottenUserNameDefinition(newState, action);
  newState = registrationDefinition(newState, action);
  newState = loginDefinition(newState, action);
  newState = forgottenPasswordDefinition(newState, action);
  newState = updateSecurityDetailsDefinition(newState, action);
  return newState;
};

export default authenticationDefinition;
