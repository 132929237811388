import React from 'react';
import { Row, Col, Form, Button } from 'reactstrap';
import { FormattedCurrency, Content } from '@myie/interact-dom';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ReviewPayment = props => {
  const {
    form,
    updateStage,
    error,
    submit,
    account,
    referrer = '/accounts',
  } = props;

  return (
    <div id="paysavings-review">
      <Content
        tag="h2"
        cmsTag="Savings-servicing:Pay-by-card:Review-payment:h2-1"
        copytext="Review and confirm account funding"
      />
      <Content
        tag="p"
        cmsTag="Savings-servicing:Pay-by-card:Review-payment:p1"
        copytext="Before we carry out your request, please check that the following details are correct."
      />
      {error ? (
        <Alert id="error-message" color="danger">
          {error}
        </Alert>
      ) : (
        ''
      )}
      <Form autoComplete="off" id="make-payment-form" onSubmit={e => submit(e)}>
        <Row tag="dl" title="Card details">
          <Col tag="dt" sm={6} md={3}>
            <Content cmsTag="GLOBAL:From" copytext="From" />
          </Col>
          <Col tag="dd" sm={6} md={9}>
            <Content
              cmsTag="GLOBAL:Card-ending-CARD-NUMBER"
              template={{
                copytext: {
                  cardNumber: form.cardNumber.value.substr(-4),
                },
              }}
              copytext="Card ending $[cardNumber]"
            />
          </Col>
          <Col tag="dt" sm={6} md={3}>
            <Content cmsTag="GLOBAL:To" copytext="To" />
          </Col>
          <Col tag="dd" sm={6} md={9}>
            {account.AccountName}{' '}
            <FormattedCurrency
              quantity={account.Balance}
              currency={account.CurrencyCode}
            />
          </Col>
          <Col tag="dt" sm={6} md={3}>
            <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
          </Col>
          <Col tag="dd" sm={6} md={9}>
            <FormattedCurrency
              quantity={form.amount.value}
              currency={account.CurrencyCode}
            />
          </Col>
        </Row>
        <div className="form-button-group">
          <Button id="make-payment-submit" type="submit" color="primary">
            <Content
              cmsTag="GLOBAL:Confirm-payment"
              copytext="Confirm payment"
            />
          </Button>
          <Button
            onClick={e => updateStage(e, 'MakePayment')}
            id="make-payment-back"
            type="button"
            color="light"
          >
            <Content
              cmsTag="GLOBAL:Edit-payment-details"
              copytext="Edit payment details"
            />
          </Button>
          <Link className="btn btn-secondary" role="button" to={referrer}>
            <Content
              cmsTag="Savings-servicing:Pay-by-card:Review-payment:Cancel-payment"
              copytext="Cancel-payment"
            />
          </Link>
        </div>
      </Form>
    </div>
  );
};

ReviewPayment.propTypes = {
  account: PropTypes.any,
  error: PropTypes.any,
  form: PropTypes.any,
  info: PropTypes.any,
  referrer: PropTypes.any,
  submit: PropTypes.any,
  updateStage: PropTypes.any,
};

export default ReviewPayment;
