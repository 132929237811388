import React, { Component } from 'react';
import { BreadcrumbItem, Button } from 'reactstrap';
import { storage } from './storageName';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import moment from 'moment';
import { AppMeta, FormErrorList, CONSTANTS } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Create from './Create';
import Confirmation from './Confirmation';
import { CONTACTS } from '@myie/interact-local-dom';
import CreationSuccess from './CreationSuccess';
import dateValidation from './../../utility';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../../shared/HO_components/checkDownTime';
import { Redirect } from 'react-router-dom';
import { accountFilter } from '@myie/interact-savings-servicing-dom';

class CreateNotice extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    const { getAllProducts, allProducts } = props;
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  initialState = () => {
    const { accounts, match } = this.props;
    const { urlParams, refreshUrl } = this.props;
    const stateList = ['createNotice', 'ReviewNotice', 'CompleteNotice'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      this.props.stateData &&
      this.props.stateData.url &&
      this.props.stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'createNotice';
    if (!hasStage) {
      stage = 'createNotice';
      refreshUrl();
    }
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }

    return {
      account: account,
      stage: stage,
      withdrawDate: moment(),
      combinedForm: {},
      data:
        this.props.stateData && this.props.stateData.formData
          ? this.props.stateData.formData
          : {},
      responseData:
        this.props.stateData && this.props.stateData.withdrawalData
          ? this.props.stateData.withdrawalData
          : null,
      responseStatus: null,
    };
  };

  componentWillUnmount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }

  setStateDynamic = (list, isSubmit = false) => {
    if (isSubmit) {
      let newState = { ...this.state };
      newState.combinedForm = list.combinedForm;
      this.setState({ ...newState });
    } else {
      this.setState({ ...this.state, ...list });
    }
  };

  componentDidMount() {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const { makeWithdrawResponse = {}, updateUrl } = nextProps;
    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success' &&
      state.stage !== 'CompleteNotice'
    ) {
      let stateData = {
        url: {
          pageStage: 'CompleteNotice',
        },
      };

      updateUrl(stateData);
      return {
        stage: 'CompleteNotice',
      };
    }
    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Failed'
    ) {
      return {
        responseStatus: makeWithdrawResponse.ResponseStatus,
      };
    }
    return null;
  };

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });

      this.setState({ ...this.state, account });
    }
  }

  // change the stage of the form
  setStage = stage => {
    const { updateUrl } = this.props;
    if (stage === 'createNotice') {
      let stateData = {
        formData: this.state.data,
        url: {
          pageStage: stage,
        },
      };
      updateUrl(stateData);
    }
    this.setState({
      ...this.state,
      stage,
      message: null,
    });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  //update the stage in state
  submitForm = (stage, form, isSubmit = false) => {
    if (stage === 'createNotice') {
      const { resetMakeWithdrawal } = this.props;
      resetMakeWithdrawal();
    }
    let stateData = {
      formData: this.state.data,
      url: {
        pageStage: stage,
      },
    };
    this.props.updateUrl(stateData);
    if (isSubmit) {
      let newState = { ...this.state };
      newState.combinedForm = form;
      this.setState({ ...newState, stage, form, message: null });
    } else {
      this.setState({ ...this.state, stage, form, message: null });
    }
  };

  getActionDate = account => {
    const { holidays, allProducts } = this.props;
    const product = allProducts.find(
      product =>
        product.Product_Code === account.ExtendedProperties.ProductCode,
    );

    const NumberOfDays =
      product && product.Notice_Period_Days ? product.Notice_Period_Days : 0;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  getNoticeExpiryDate = account => {
    const { holidays, allProducts } = this.props;
    const product = allProducts.find(
      product =>
        product.Product_Code === account.ExtendedProperties.ProductCode,
    );

    const NumberOfDays =
      product && product.Notice_Period_Days ? product.Notice_Period_Days : 0;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  onSubmitWithdrawal = () => {
    const { account, data } = this.state;
    const { makeaWithdraw } = this.props;
    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    const balance = AvailableBalance + PendingBalance;

    let formCloseAccount = data['closeAccount'];
    let request = {
      AccountKey: account.AccountKey,
      WithdrawalInformation: {
        ActionDate: this.getActionDate(account),
        CloseAccount: formCloseAccount ? true : false,
      },
      WithdrawalDateSavings: this.getActionDate(account),
      NoticeExpiryDateSavings: this.getNoticeExpiryDate(account),
      WithdrawalRequestDateSavings: moment(data['withdrawalDate']).format(
        'YYYY-MM-DD',
      ),
      WithdrawalFullBalanceSavings: formCloseAccount ? 'TRUE' : 'FALSE',
      WithdrawalValueSavings: data['amount'],
      DepositAccountId: null,
      SubAccountId: account.ExtendedProperties.SubAccountNumber,
      Amount: formCloseAccount ? balance : data['amount'],
      NoticeDate: moment(data['withdrawalDate']).format(
        CONSTANTS.DATE_FORMAT_YYYYMMDD,
      ),
    };

    makeaWithdraw(request, false, true);
  };

  // common onChangeAccount function
  onChangeAccount = e => {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
    const { value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ ...this.initialState(), account });
    }
  };
  // select current stage
  currentStage = (account, fromAccounts) => {
    const { accounts = {} } = this.props;
    const actionDate = this.getActionDate(account);
    const noticeExpiryDate = this.getNoticeExpiryDate(account);

    switch (this.state.stage) {
      case 'createNotice':
        return (
          <Create
            setForm={this.setForm}
            saveData={this.saveData}
            submitForm={this.submitForm}
            setStateDynamic={this.setStateDynamic}
            onChangeAccount={this.onChangeAccount}
            actionDate={actionDate}
            noticeExpiryDate={noticeExpiryDate}
            accounts={accounts}
            data={this.state.data}
            account={account}
            onCancelled={this.onCancelleWithdrawal}
            fromAccounts={fromAccounts}
          />
        );
      case 'ReviewNotice':
        return (
          <Confirmation
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            actionDate={actionDate}
            noticeExpiryDate={noticeExpiryDate}
            setStage={this.setStage}
            data={this.state.data}
            account={account}
            onCancelled={this.onCancelleWithdrawal}
          />
        );
      default:
    }
  };

  onCancelleWithdrawal = () => {
    const { history } = this.props;
    const { account } = this.state;
    const key =
      (account.AccountKey &&
        account.AccountKey.Key &&
        Utility.hexEncode(account.AccountKey.Key)) ||
      '';
    history.push(`/notice-to-withdraw/view/summary/${key}`);
  };

  render() {
    const {
      stage,
      account,
      responseStatus,
      combinedForm,
      history,
    } = this.state;
    const {
      makeWithdrawResponse,
      updateUrl,
      resetComponentState,
      systemDownStatus, //From checkDownTime HOC
      allProducts,
      accounts,
    } = this.props;

    if (responseStatus === 'Failed') {
      return <Redirect to="/network-error" />;
    }
    if (!account || !allProducts) {
      return null;
    }
    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    if (stage === 'CompleteNotice') {
      return (
        <CreationSuccess
          account={account}
          setStage={this.setStage}
          activate={this.activate}
          data={this.state.data}
          withdrawResponse={makeWithdrawResponse}
          updateUrl={updateUrl}
          resetComponentState={resetComponentState}
        />
      );
    }

    let condition = stage === 'ReviewNotice';
    const fromAccounts = accountFilter(
      accounts.Accounts,
      allProducts,
      true,
      false,
      true,
    );

    if (!fromAccounts.length) {
      return (
        <>
          <div role="alert" className="alert alert-danger">
            <Content
              cmsTag="Savings-servicing:Notice-to-withdraw:warning"
              copytext="Please note that there is no eligible account for the withdrawals"
            />
          </div>
          <Button
            id="back-to-add-dynamic-button"
            color="secondary"
            onClick={() => {
              history.push(`/accounts/list`);
            }}
          >
            <Content
              cmsTag="GLOBAL:Back-to-my-details"
              copytext="Back to accounts"
            />
          </Button>
        </>
      );
    }

    return (
      <div id="create-notice-to-withdraw">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Add new notice to withdraw step 1"
          metaDescription="Add new notice to withdraw step 1"
        />
        {systemDownStatus.msg}
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Notice-to-withdraw:Create-notice-to-withdraw:Create-notice:h1"
          copytext="Add new notice to withdraw"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={stage === 'createNotice'}
              className={condition ? 'completed' : ''}
            >
              {this.state.stage === 'createNotice' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {condition ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Notice-details"
                copytext="Notice details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'ReviewNotice'}>
              {this.state.stage === 'ReviewNotice' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        {this.state.stage === 'createNotice' ? (
          <FormErrorList
            validations={combinedForm}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
        ) : (
          ''
        )}
        {this.currentStage(account, fromAccounts)}
      </div>
    );
  }
}

CreateNotice.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  holidays: PropTypes.any,
  account: PropTypes.any,
  urlParams: PropTypes.any,
  stateData: PropTypes.any,
  refreshUrl: PropTypes.any,
  updateUrl: PropTypes.any,
  resetComponentState: PropTypes.func,
  makeWithdrawResponse: PropTypes.any,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
    )(CreateNotice),
  ),
  storage.name,
);
