import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { Validate } from '@myie/interact';
import { Row, Col, Form, Button, BreadcrumbItem, Alert } from 'reactstrap';
import {
  Content,
  Markdown,
  RadioGroup,
  Radio,
  FormattedDate,
  FormErrorList,
  CONSTANTS,
  RULES,
  DropDown,
  Currency,
} from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import AccountDescription from '../shortApply/AccountDescription';
import checkDownTime from '../../shared/HO_components/checkDownTime';

import PropTypes from 'prop-types';

class ShortApplyApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
  }

  initialState = props => {
    const { /*formData, */ formData, selectedProduct } = props;
    let data = formData ? formData : null;
    // if ('accountPreferences' in formData) {
    //   data = formData['accountPreferences'];
    // }
    const formItems = {
      detailsCorrect: {
        rules: {
          required: {
            message:
              'Please confirm the personal and address details we have listed are correct.',
          },
        },
        value: data ? data.detailsCorrect : '',
      },
      depositMethod: {
        rules: {
          stop: true,
          required: {
            message:
              'Please tell us how you will be making your initial deposit.',
          },
        },
        value: data ? data.depositMethod : '',
      },

      depositAmount: {
        rules: {
          stop: true,
          required: {
            message: 'Please enter an initial deposit amount.',
          },
          format: {
            regex: RULES.ammountFormat,
            message: 'Please enter a valid amount.',
          },
          minAmountCheckWithInputValue: {
            message: ` The minimum amount you can have in this type of account is £${selectedProduct.Minimum_Operating_Balance}.`,
            comparison: () => {
              return selectedProduct.Minimum_Operating_Balance;
            },
          },
          maxAmountCheckWithInputValue: {
            message: `The maximum amount you can have in this type of account is £${selectedProduct.Maximum_Operating_Balance}.`,
            comparison: () => {
              return selectedProduct.Maximum_Operating_Balance;
            },
          },
          maxDepositMonthly: {
            message: `The maximum amount you can deposit monthly is £${selectedProduct.Maximum_monthly_deposit}.`,
            comparison: () => {
              return '' + selectedProduct.Maximum_monthly_deposit;
            },
          },
          maxDepositYearly: {
            message: `The maximum amount you can deposit yearly is £${selectedProduct.Maximum_yearly_deposit}.`,
            comparison: () => {
              return '' + selectedProduct.Maximum_yearly_deposit;
            },
          },
        },
        value: data ? data.depositAmount : '',
      },

      // interestPaymentLocation: {
      //   rules: {
      //     stop: true,
      //     required: {
      //       message:
      //         'Please tell us where you would like us to pay your interest to.',
      //     },
      //   },
      //   value: data ? data.interestPaymentLocation : '',
      // },
    };

    if (
      selectedProduct &&
      selectedProduct.Interest_Frequency &&
      selectedProduct.Interest_Frequency === 'both'
    ) {
      formItems.interestPaymentFrequency = {
        rules: {
          stop: true,
          required: {
            message:
              'Please tell us when you would like us to pay your interest.',
          },
        },
        value: data ? data.interestPaymentFrequency : '',
      };
    }
    return {
      data: data ? data : {},
      form: formItems,
    };
  };

  componentDidUpdate() {
    // const { formData } = this.props;
    // const { data } = this.state;

    // if (
    //   !_.isEmpty(formData['accountPreferences']) &&
    //   JSON.stringify(data) !== JSON.stringify(formData['accountPreferences'])
    // ) {
    this.initialState(this.props);
    //}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data } = this.state;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;

    this.setState({ ...this.state, form });
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  onSubmitHandler = e => {
    e.preventDefault();

    let { form, data } = this.state;
    let { updateUrl, setStateDynamic } = this.props;
    let actualForm;

    if (form.depositAmount.value === '0') {
      form.depositAmount.value = '';
    }
    if (form.detailsCorrect.value === 'no') {
      return;
    }
    actualForm = Validate.form(form);
    this.setState({ ...this.state, form: actualForm });

    if (!actualForm.approved) {
      window.scrollTo(0, 0);
      return;
    }
    setStateDynamic({
      stage: 'review',
    });

    let stateData = {
      formData: data,
      url: {
        pageStage: 'review',
      },
    };
    updateUrl(stateData);
    //setShortapply(request);
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;

    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  dropdownOptions(item, index, name) {
    let keys = Object.keys(item);
    return (
      <option id={`${name}-option-${keys[0]}`} key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  onBlur = e => {
    this.updateForm(e);
  };

  changeStage = () => {
    const { setStateDynamic } = this.props;
    setStateDynamic({ stage: 'list' });
  };

  render() {
    const currencyCode = 'GBP';
    const {
      selectedProduct,
      userDetails,
      systemDownStatus, //From checkDownTime HOC
    } = this.props;
    const { form } = this.state;
    if (!selectedProduct || !userDetails) {
      return null;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    const {
      ExtendedProperties: {
        Gender,
        MaritalStatus,
        NINumber,
        ClientAddress,
      } = {},
    } = userDetails;
    const maritalStatus = CONSTANTS.MARITAL_STATUS_CODE.find(status => {
      let keys = Object.keys(status);
      return keys[0] === MaritalStatus;
    });

    return (
      <div id="short-apply-application">
        {systemDownStatus.msg}
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Shared:Short-apply-application:h1"
          template={{
            copytext: {
              productName: selectedProduct.Product_Name,
            },
          }}
          copytext="Apply for $[productName]"
        />
        <div id="apply-form">
          <div className="breadcrumb-container">
            <ol className="breadcrumb">
              <BreadcrumbItem active>
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
                <span className="d-inline d-lg-none d-xl-none">
                  <span className="sr-only">
                    <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                  </span>
                  <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                  2:&nbsp;
                </span>
                <Content
                  cmsTag="BREADCRUMB:Account-details"
                  copytext="Account details"
                />
              </BreadcrumbItem>
              <BreadcrumbItem>
                <span className="d-inline d-lg-none d-xl-none">
                  <span className="sr-only">
                    <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                  </span>
                  <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                  2:&nbsp;
                </span>
                <Content
                  cmsTag="BREADCRUMB:Review-and-submit"
                  copytext="Review and submit"
                />
              </BreadcrumbItem>
            </ol>
          </div>
          <div className="clear-both"></div>
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          <AccountDescription selectedProduct={selectedProduct} />
          <Form
            autoComplete="off"
            onSubmit={this.onSubmitHandler}
            id="apply-form"
            className="short-apply-form"
          >
            <div className="review-sections-container pre-review">
              <section className="summary-section">
                <div className="summary-section-title-container">
                  <Content
                    tag="h2"
                    cmsTag="GLOBAL:"
                    copytext="Personal details"
                  />
                </div>
                <div className="d-md-flex clear-both">
                  <div className="summary-column">
                    <Content
                      tag="h3"
                      tagID="basic-details-label"
                      cmsTag="GLOBAL:Basic-details"
                      copytext="Basic details"
                    />
                    <Row tag="dl" aria-labelledby="basic-details-label">
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content cmsTag="GLOBAL:Name" copytext="Name" />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails
                          ? `${userDetails.Title} ${userDetails.FirstName} ${userDetails.Surname}`
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content cmsTag="GLOBAL:Gender" copytext="Gender" />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {Gender ? CONSTANTS.GENDER[Gender] : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Date-of-birth"
                          copytext="Date of birth"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        <FormattedDate
                          date={userDetails.ExtendedProperties.DateOfBirth}
                          format={CONSTANTS.STANDARD_DATE_FORMAT}
                        />
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Marital-status"
                          copytext="Marital status"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {maritalStatus && Object.values(maritalStatus)[0]}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:National-Insurance-Number"
                          copytext="National Insurance Number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {NINumber}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Are-you-a-UK-resident?"
                          copytext="Are you a UK resident?"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {ClientAddress?.AddressCountry === 'UK' ? 'Yes' : 'No'}
                      </Col>
                    </Row>
                  </div>
                  <div className="summary-column">
                    <Content
                      tagID="contact-details-label"
                      tag="h3"
                      cmsTag="GLOBAL:Contact-details"
                      copytext="Contact details"
                    />
                    <Row tag="dl" aria-labelledby="contact-details-label">
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Mobile-number"
                          copytext="Mobile number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails?.MobileNumber
                          ? `${userDetails?.MobileNumber} `
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Daytime-phone-number"
                          copytext="Daytime phone number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails?.WorkPhone
                          ? `${userDetails?.WorkPhone} `
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Evening-phone-number"
                          copytext="Evening phone number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails?.HomeNumber
                          ? `${userDetails?.HomeNumber} `
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Email-address"
                          copytext="Email address"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails ? `${userDetails.EmailAddress} ` : ''}
                      </Col>
                    </Row>
                    <Content
                      tag="h3"
                      cmsTag="GLOBAL:Contact-preferences"
                      copytext="Contact preferences"
                    />
                    <Content
                      tag="p"
                      tagID="contact-agree-title"
                      cmsTag="Savings-servicing:Shared:Short-apply-application:p1"
                      copytext="You have agreed that we can contact you by:"
                    />
                    <ul aria-labelledby="contact-agree-title">
                      {userDetails &&
                      userDetails?.ExtendedProperties?.MarketingPreference
                        ?.Email === 'True' ? (
                        <li>
                          <Content cmsTag="GLOBAL:Email" copytext="Email" />
                        </li>
                      ) : (
                        ''
                      )}
                      {userDetails &&
                      userDetails?.ExtendedProperties?.MarketingPreference
                        ?.Phone === 'True' ? (
                        <li>
                          <Content cmsTag="GLOBAL:Phone" copytext="Phone" />
                        </li>
                      ) : (
                        ''
                      )}
                      {userDetails &&
                      userDetails?.ExtendedProperties?.MarketingPreference
                        ?.Post === 'True' ? (
                        <li>
                          <Content cmsTag="GLOBAL:Post" copytext="Post" />
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              </section>
              <section className="summary-section mb-5">
                <div className="summary-section-title-container">
                  <Content
                    tag="h2"
                    cmsTag="GLOBAL:Address-details"
                    copytext="Address details"
                  />
                </div>
                <div className="d-md-flex clear-both">
                  <div className="summary-column">
                    {userDetails &&
                    userDetails.ExtendedProperties &&
                    userDetails.ExtendedProperties.ClientAddress ? (
                      <address>
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address1 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address1
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address2 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address2
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address3 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address3
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address4 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address4
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address5 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address5
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .AddressPC !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .AddressPC
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .AddressCountry !== null
                          ? userDetails.ExtendedProperties.ClientAddress
                              .AddressCountry
                          : null}
                      </address>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </section>
              <RadioGroup
                id="detailsCorrect"
                legend="Are the personal and address details we have listed correct?"
                cmsTag="Savings-servicing:Shared:Short-apply-application:legend-1"
                validation={form.detailsCorrect}
                required={true}
                field="detailsCorrect"
              >
                <Radio
                  autoFocus={false}
                  cmsTag="GLOBAL:Yes"
                  label={`Yes`}
                  key={`details-correct-yes`}
                  id={`details-correct-yes`}
                  field="detailsCorrect"
                  onChange={this.onChange}
                  validation={form.detailsCorrect}
                  value={'yes'}
                />

                <Radio
                  autoFocus={false}
                  label={`No`}
                  cmsTag="GLOBAL:No"
                  key={`details-correct-no`}
                  id={`details-correct-no`}
                  field="detailsCorrect"
                  onChange={this.onChange}
                  validation={form.detailsCorrect}
                  value={'no'}
                />
                {!form.approved && !form.detailsCorrect.value ? (
                  <div className="invalid-feedback">
                    <Content
                      tag="p"
                      cmsTag="Savings-servicing:Shared:Short-apply-application:error-message-1"
                      copytext="Please confirm if the details are correct."
                    />
                  </div>
                ) : (
                  ''
                )}

                {form.detailsCorrect &&
                form.detailsCorrect.value &&
                form.detailsCorrect.value === 'no' ? (
                  <Alert color="danger">
                    <Markdown
                      cmsTag="Savings-servicing:Shared:Short-apply-application:alert-1"
                      template={{
                        markdown: {
                          phone: CONTACTS.phone,
                          phoneLink: CONTACTS.phoneLink,
                          email: CONTACTS.email,
                        },
                      }}
                      markdown={`If the details on this page are incorrect, please contact us on <a href="tel:$[phoneLink]">$[phone]</a> or <a href="mailto:$[email]">send us a message</a>. You can change your contact preferences, email or home phone number by visiting My Details.`}
                    />
                  </Alert>
                ) : (
                  ''
                )}
              </RadioGroup>
              {/* <section>
                <Content
                  tag="h2"
                  cmsTag="GLOBAL:Your-finances"
                  copytext="Your finances"
                />
              <Row>
                <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
                  <Content
                    cmsTag="GLOBAL:Employment-status"
                    copytext="Employment status"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
                  {userDetails
                    ? `${userDetails.ExtendedProperties.EmploymentStatus} `
                    : ''}
                </Col>
              </Row>
            </section> */}
              <section>
                <Content
                  tag="h2"
                  cmsTag="GLOBAL:Account-preferences"
                  copytext="Account preferences"
                />
                <section id="deposit-details-section">
                  <Content
                    tag="h3"
                    cmsTag="Savings-servicing:Shared:Short-apply-application:h3-4"
                    copytext="Deposit details"
                  />
                  <DropDown
                    cmsTag="GLOBAL:How-will-you-be-making-your-initial-deposit"
                    label="How will you be making your initial deposit?"
                    id="depositMethod"
                    field="depositMethod"
                    onChange={this.onChange}
                    validation={form.depositMethod}
                  >
                    <option
                      id={`deposit-method-option-please-select`}
                      key="1"
                      value=""
                      defaultText="Please select"
                    ></option>
                    {CONSTANTS &&
                      CONSTANTS.DEPOSIT_METHOD.map((item, index) => {
                        return this.dropdownOptions(
                          item,
                          index,
                          'depositMethod',
                        );
                      })}
                  </DropDown>
                  <Currency
                    showErrors={true}
                    cmsTag="GLOBAL:How-much-will-this-initial-deposit-be"
                    label={'How much will this initial deposit be?'}
                    validation={form.depositAmount}
                    onChange={this.updateCurrency}
                    onBlur={this.updateCurrency}
                    code={currencyCode}
                    field="depositAmount"
                    id="depositAmount"
                    dp={2}
                  />
                </section>
                <section id="interest-payments-section" className="mb-0">
                  <Content
                    tag="h3"
                    cmsTag="Savings-servicing:Shared:Short-apply-application:h3-5"
                    copytext="Interest payments"
                  />
                  <Markdown
                    template={{
                      markdown: {
                        phone: CONTACTS.phone,
                        phoneLink: CONTACTS.phoneLink,
                      },
                    }}
                    cmsTag="Savings-servicing:Shared:Short-apply-application:p2"
                    markdown={`\nYour interest will automatically be paid into this account. If you want your interest to be paid elsewhere, check the terms and conditions of the account and contact our Savings Support Team on <a href="tel:$[phoneLink]">$[phone]</a>.`}
                  />
                  {/* <DropDown
                    cmsTag="LABEL:Where-shall-we-pay-the-interest-to"
                    label="Where shall we pay the interest to?"
                    id="interestPaymentLocation"
                    field="interestPaymentLocation"
                    onChange={this.onChange}
                    validation={form.interestPaymentLocation}
                  >
                    <option
                      id={`interest-payment-location-option-please-select`}
                      key="1"
                      value=""
                      defaultText="Please select"
                    ></option>
                    {CONSTANTS &&
                      CONSTANTS.INTEREST_DESTINATION.map((item, index) => {
                        return this.dropdownOptions(
                          item,
                          index,
                          'interestPaymentLocation',
                        );
                      })}
                  </DropDown> */}
                  {selectedProduct &&
                  selectedProduct.Interest_Frequency &&
                  selectedProduct.Interest_Frequency !== 'both' ? (
                    <Row
                      tag="dl"
                      title="Interest payment frequency details"
                      className="clear-both"
                    >
                      <Col tag="dt" sm="5" md="4" lg="4">
                        <Content
                          cmsTag="GLOBAL:Interest-payment-frequency"
                          copytext="Interest payment frequency"
                        />
                      </Col>
                      <Col tag="dd" sm="7" md="8" lg="8">
                        {selectedProduct.Interest_Frequency}
                      </Col>
                    </Row>
                  ) : (
                    <DropDown
                      cmsTag="GLOBAL:How-often-should-we-make-interest-payments"
                      label="How often should we make interest payments?"
                      id="interestPaymentFrequency"
                      field="interestPaymentFrequency"
                      onChange={this.onChange}
                      validation={form.interestPaymentFrequency}
                    >
                      <option
                        id={`interest-payment-frequency-option-please-select`}
                        key="1"
                        value=""
                        defaultText="Please select"
                      ></option>
                      {CONSTANTS &&
                        CONSTANTS.INTEREST_PAYMENT_FREQUENCY.map(
                          (item, index) => {
                            return this.dropdownOptions(
                              item,
                              index,
                              'interestPaymentFrequency',
                            );
                          },
                        )}
                    </DropDown>
                  )}
                </section>
                <div className="form-button-group float-left w-100">
                  <Button
                    disabled={
                      form.detailsCorrect &&
                      form.detailsCorrect.value &&
                      form.detailsCorrect.value === 'no'
                    }
                    id="create-new-notice"
                    type="submit"
                    color="primary"
                  >
                    <Content
                      cmsTag="GLOBAL:Continue-to-review"
                      copytext="Continue to review"
                    />
                  </Button>
                  <Link
                    id="back-to-product-list-link"
                    className="btn btn-secondary"
                    role="button"
                    to={`/short-apply/index`}
                  >
                    <Content
                      cmsTag="GLOBAL:Back-to-product-list"
                      copytext="Back to product list"
                    />
                  </Link>
                </div>
              </section>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

ShortApplyApplication.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  onSubmit: PropTypes.func,
  setStage: PropTypes.func,
  userDetails: PropTypes.any,
  updateUrl: PropTypes.func,
  setStateDynamic: PropTypes.any,
  formData: PropTypes.any,
  selectedProduct: PropTypes.any,
  data: PropTypes.any,
  setShortapply: PropTypes.func,
  systemDownStatus: PropTypes.object,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default checkDownTime(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
  )(ShortApplyApplication),
);
