import React, { Component } from 'react';
import {
  Content,
  FormattedCurrency,
  Switch,
  Markdown,
} from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import moment from 'moment';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Currency from 'react-currency-formatter';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: '',
      disabled: false,
    };
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Failed'
    ) {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return null;
  };

  submit = () => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    const { onSubmitWithdrawal } = this.props;
    onSubmitWithdrawal();
  };

  cancelWithdrawal = () => {
    const { setStage } = this.props;
    setStage('MakePayment');
  };
  render() {
    const { form = {}, account = {} } = this.props;
    let amount =
      form && form.amount && form.amount.value ? form.amount.value : '';
    let reference =
      form && form.reference && form.reference.value
        ? form.reference.value
        : '';
    const closeOption = false;
    const {
      CurrencyCode,
      RemainingAllowance,
      ExtendedProperties: {
        NominatedAccountNumber,
        NominatedSortCode,
        NominatedAccountName,
      } = {},
    } = account;
    return (
      <React.Fragment>
        <Switch
          id="make-a-withdraw-alert"
          value={this.state.message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          scrolltotop={true}
          contents={{
            failed: {
              defaultValue: 'Make a Withdraw Failed',
            },
          }}
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Make-withdrawal:Confirmation:p1"
          copytext="You are setting a notice to withdraw on the following account:"
        />
        <div className="confirm-make-withdrawal-details">
          <Row tag="dl" title="Account details review">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:From" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}{' '}
              {Currency({
                quantity: RemainingAllowance,
                currency: CurrencyCode,
              })}{' '}
              <span className="d-block">
                {account.AccountNumber}, {account.SortCode}
              </span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:To" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {NominatedAccountName}
              <span className="d-block">
                {NominatedAccountNumber}, {NominatedSortCode}
              </span>
            </Col>
            {!closeOption ? (
              <React.Fragment>
                <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                  <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                  <FormattedCurrency
                    quantity={amount.replace(/,/g, '')}
                    currency={CurrencyCode}
                  />
                </Col>
                {reference ? (
                  <React.Fragment>
                    <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                      <Content cmsTag="GLOBAL:Reference" copytext="Reference" />
                    </Col>
                    <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                      {reference}
                    </Col>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </React.Fragment>
            ) : (
              ''
            )}
          </Row>
          {!reference ? (
            <Content
              tag="p"
              cmsTag="Savings-servicing:Make-withdrawal:Confirmation:Closed:p1"
              copytext="You have requested that the account is closed with this withdrawal"
            />
          ) : (
            ''
          )}
        </div>
        <div className="alert alert-info">
          <Markdown
            cmsTag="GLOBAL:Withdrawal-date-warning"
            markdown={
              'Please note: If your withdrawal date falls on a weekend or bank holiday, it will be processed the following working day.'
            }
          />
        </div>
        <div className="form-button-group">
          <Button
            id="confirm-make-withdrawal"
            type="submit"
            onClick={this.submit}
            color="primary"
            disabled={this.state.disabled}
          >
            <Content
              cmsTag="GLOBAL:Confirm-withdrawal"
              copytext="Confirm withdrawal"
            />
          </Button>
          <Button
            id="cancel-withdrawal"
            onClick={this.cancelWithdrawal}
            color="secondary"
            disabled={this.state.disabled}
          >
            <Content
              cmsTag="GLOBAL:Cancel-withdrawal"
              copytext="Cancel withdrawal"
            />
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  history: PropTypes.any,
  withdrawalParam: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  makeWithdrawResponse: PropTypes.any,
  makeaWithdraw: PropTypes.func,
  account: PropTypes.any,
  form: PropTypes.any,
  setStage: PropTypes.any,
  onSubmitWithdrawal: PropTypes.any,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Confirmation));
