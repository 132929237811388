import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  Content,
  FormattedCurrency,
  FormattedDate,
  Markdown,
  AppMeta,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';
import { Utility } from '@myie/interact';

class CreationSuccess extends Component {
  componentDidMount() {
    const { resetCreateNoticeToWithdraw } = this.props;
    resetCreateNoticeToWithdraw();
  }

  render() {
    const { data = {}, account = {}, withdrawResponse } = this.props;
    let amount = data && data['amount'] ? data['amount'] : '';
    const closeOption = data['closeAccount'] ? data['closeAccount'] : false;
    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    const balance = AvailableBalance + PendingBalance;

    const {
      CurrencyCode,
      //ExtendedProperties: {
      //NominatedAccountNumber,
      //NominatedSortCode,
      //} = {},
    } = account;

    if (!account) {
      return null;
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Your notice to withdraw has been added"
          metaDescription="Your notice to withdraw has been added"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Creation-success:h1"
          copytext="Your notice to withdraw has been added"
        />
        <Row tag="dl" title="Account details">
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:From" copytext="From" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {account.AccountName}&nbsp;
            <span className="d-block">{account.AccountNumber}</span>
          </Col>
          {/*<Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:To" copytext="To" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            <Content
              cmsTag="GLOBAL:Nominated-account"
              copytext="Nominated account"
            />
            &nbsp;
            <span className="d-block">
              {NominatedAccountNumber}, {NominatedSortCode}
            </span>
    </Col>*/}
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {!closeOption ? (
              <FormattedCurrency
                quantity={amount.replace(/,/g, '')}
                currency={CurrencyCode}
              />
            ) : (
              <React.Fragment>
                <FormattedCurrency quantity={balance} currency={CurrencyCode} />
                <Content
                  cmsTag="GLOBAL:-Full-balance"
                  copytext=" (Full balance)"
                />
              </React.Fragment>
            )}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Withdrawal-date"
              copytext="Withdrawal date"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            <FormattedDate
              date={withdrawResponse.NoticeDate}
              format="Do MMMM YYYY"
            />
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Notice-expiry-date"
              copytext="Notice expiry date"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            <FormattedDate
              date={withdrawResponse.ExpiryDate}
              format="Do MMMM YYYY"
            />
          </Col>
        </Row>
        {closeOption ? (
          <Content
            tag="p"
            cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Creation-success:p1"
            copytext="You have requested that the account is closed with this withdrawal."
          />
        ) : (
          ''
        )}
        <Content
          tag="p"
          cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Creation-success:p2"
          copytext="If you have decided to withdraw your full balance and close the account, you will also withdraw any accrued interest on the withdrawal date."
        />
        <Content
          cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Creation-success:p3"
          copytext="Please make the withdrawal on or before the notice expiry date."
        />

        <div className="alert alert-info">
          <Markdown
            cmsTag="GLOBAL:Withdrawal-date-warning"
            markdown={
              'Please note: If your withdrawal date falls on a weekend or bank holiday, it will be processed the following working day.'
            }
          />
        </div>
        <div className="form-button-group">
          <Link
            id="back-to-notice"
            className="btn btn-secondary"
            role="button"
            to={`/notice-to-withdraw/view/summary/${Utility.hexEncode(
              account.AccountKey.Key,
            )}`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-notice-list"
              copytext="Back to notice list"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

CreationSuccess.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  match: PropTypes.any,
  account: PropTypes.any,
  data: PropTypes.any,
  actionDate: PropTypes.any,
  noticeExpiryDate: PropTypes.any,
  withdrawResponse: PropTypes.object,
};

export default connect(
  null,
  mapDispatchToProps,
)(CreationSuccess);
