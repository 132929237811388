import {
  ADD_NOMINATED_ACCOUNT_REQUEST,
  ADD_NOMINATED_ACCOUNT_SUCCESS,
  ADD_NOMINATED_ACCOUNT_FAILURE,
  RESET_ADD_NOMINATED_ACCOUNT,
} from '../actions/nominatedAccountTypes';

const accountDetailsDefinition = (state = {}, action) => {
  switch (action.type) {
    case ADD_NOMINATED_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case ADD_NOMINATED_ACCOUNT_SUCCESS:
      return {
        ...state,
        addNominatedAccountResponse: action.addNominatedAccountResponse,
        isFetching: action.isFetching,
      };
    case ADD_NOMINATED_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_ADD_NOMINATED_ACCOUNT:
      return {
        ...state,
        addNominatedAccountResponse: null,
        isFetching: false,
        error: null,
      };
    default:
      return state;
  }
};

export default accountDetailsDefinition;
