import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { CONTACTS } from '@myie/interact-local-dom';
import { Content, Markdown, LinkTag, AppMeta } from '@myie/interact-dom';

class Unsuccessful extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="registration-unsuccessful">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Registration unsuccessful"
          metaDescription="Registration unsuccessful"
        />
        <Content
          tag="h1"
          tagID="registration-unsuccessful-title"
          cmsTag="Authentication:Registration:Unsuccessful:h1"
          copytext="Registration unsuccessful"
        />
        <p>
          <Markdown
            cmsTag="Authentication:Registration:Unsuccessful:p1"
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                email: CONTACTS.email,
                openTimes: CONTACTS.openTimes,
              },
            }}
            markdown={`We are sorry, but your registration was unsuccessful. Please try again or contact our Online Support Team. You can email <a href="mailto:$[email]">$[email]</a>, or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our telephone lines are open $[openTimes].`}
          />
        </p>
        <div className="form-button-group">
          <Link
            id="registration-unsuccessful-home-button"
            className="btn btn-primary"
            role="button"
            to="/registration/register"
          >
            <Content cmsTag="GLOBAL:Try-again" copytext="Try again" />
          </Link>
          <LinkTag
            id="personal-details-cancel"
            className={`btn btn-secondary`}
            role="button"
            href={CONTACTS.externalHomeURL}
          >
            <Content
              id="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </div>
    );
  }
}

Unsuccessful.propTypes = {
  userLogout: PropTypes.any,
  contacts: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Unsuccessful);
