import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { storage } from './storageName';
import ShortApplyList from './ShortApplyList';
import { mapDispatchToProps as accountsDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as userManagementDispatches } from '@myie/interact-user-management';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';

class OptionOneAndThree extends Component {
  constructor(props) {
    super(props);
    this.timeoutID = null;
    this.state = {
      message: '',
      disabled: false,
    };
  }

  componentDidMount() {
    const {
      getAllProducts,
      fetchUserDetailsIfNeeded,
      fetchAccountsIfNeeded,
    } = this.props;
    let request = {
      ExtendedProperties: {},
    };
    fetchUserDetailsIfNeeded(request);
    fetchAccountsIfNeeded();
    getAllProducts({
      checkLimitExceeded: true,
      isShortApply: true,
    });
  }

  componentWillUnmount() {
    const { resetShortapply } = this.props;
    resetShortapply();
  }

  render() {
    return (
      <ShortApplyList
        updateUrl={this.props.updateUrl}
        setStateDynamic={this.setStateDynamic}
      />
    );
  }
}

OptionOneAndThree.propTypes = {
  submit: PropTypes.func,
  account: PropTypes.any,
  getAllProducts: PropTypes.any,
  form: PropTypes.any,
  reviewPaymentHeader: PropTypes.string,
  showMaturity: PropTypes.any,
  cancelWithdrawal: PropTypes.func,
  changeHeader: PropTypes.func,
  setStateDynamic: PropTypes.func,
  getAccountDetails: PropTypes.func,
  userDetails: PropTypes.any,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  resetShortapply: PropTypes.func,
  fetchUserDetailsIfNeeded: PropTypes.func,
  savingshortapply: PropTypes.object,
  stateData: PropTypes.any,
  refreshUrl: PropTypes.func,
  updateUrl: PropTypes.any,
  urlParams: PropTypes.any,
};

const mapStateToProps = state => {
  const {
    custombrandSavingsServicingDefinition,
    userManagementDefinition,
    accountsDefinition,
  } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...userManagementDefinition,
    ...accountsDefinition,
  };
};
export default loadStateHOC(
  connect(
    mapStateToProps,
    {
      ...userManagementDispatches,
      ...mapDispatchToProps,
      ...accountsDispatchToProps,
    },
  )(withRouter(OptionOneAndThree)),
  storage.name,
);
