import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route } from 'react-router-dom';
import CreateTranfer from './CreateTranfer/CreateTransfer';

class TransferToAnother extends Component {
  render() {
    return (
      <div id="transfer-to-another-account">
        <Switch>
          <PrivateRoute
            path="/transfer-to-another/create/:page?/:id?"
            component={CreateTranfer}
          />

          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export { TransferToAnother };
