import {
  RESET_SET_SHORT_APPLY,
  SET_SHORT_APPLY_REQUEST,
  SET_SHORT_APPLY_SUCCESS,
  SET_SHORT_APPLY_FALIURE,
  RESET_SHORT_APPLY_OPTIONS,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FALIURE,
  RESET_SAVE_SHORT_APPLY,
  SAVE_SHORT_APPLY_REQUEST,
  SAVE_SHORT_APPLY_SUCCESS,
  SAVE_SHORT_APPLY_FALIURE,
} from '../actions/shortApplyTypes';

const brandShortApplyDefinition = (state = {}, action) => {
  switch (action.type) {
    case SET_SHORT_APPLY_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case SET_SHORT_APPLY_SUCCESS:
      return {
        ...state,
        savingshortapply: action.setShortApplyResponse,
        isFetching: action.isFetching,
      };
    case SET_SHORT_APPLY_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_SET_SHORT_APPLY:
      return {
        ...state,
        savingshortapply: null,
        isFetching: false,
        error: null,
      };
    case GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: action.getAllProductsResponse,
        isFetching: action.isFetching,
      };
    case GET_ALL_PRODUCTS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_SHORT_APPLY_OPTIONS:
      return {
        ...state,
        allProducts: null,
        isFetching: false,
        error: null,
      };
    //
    case RESET_SAVE_SHORT_APPLY:
      return {
        ...state,
        saveShortApplyResponse: null,
        isFetching: false,
        error: null,
      };
    case SAVE_SHORT_APPLY_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case SAVE_SHORT_APPLY_SUCCESS:
      return {
        ...state,
        saveShortApplyResponse: action.saveShortApplyResponse,
        isFetching: action.isFetching,
      };
    case SAVE_SHORT_APPLY_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default brandShortApplyDefinition;
