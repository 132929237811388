import React, { Component } from 'react';
import {
  Content,
  AppMeta,
  FormattedCurrency,
  Markdown,
} from '@myie/interact-dom';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';

class CreationSuccess extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { nominatedTransferResponse, updateUrl } = this.props;
    let stateData = {
      formData: null,
      transferData: nominatedTransferResponse,
      url: {
        pageStage: 'Completed',
      },
    };
    updateUrl(stateData);
  }
  componentWillUnmount() {
    const { resetComponentState } = this.props;
    resetComponentState();
  }

  render() {
    const {
      account = {},
      nominatedTransferResponse,
      data,
      nominatedAccountDetails,
    } = this.props;
    const { ClosureAmount = '0' } = nominatedTransferResponse;

    let amount = data && data['amount'] ? data['amount'] : '';
    const closeOption =
      data && data['closeAccount'] ? data['closeAccount'] : false;
    let purposeOption =
      data && data['purposeOptions'] ? data['purposeOptions'] : '';

    const {
      CurrencyCode,
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    const balance = AvailableBalance + PendingBalance;
    if (!nominatedTransferResponse) {
      return null;
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Withdraw to your nominated account success"
          metaDescription="Withdraw to your nominated account success"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Creation-success:h1"
          copytext="Your money has been withdrawn"
        />
        <div className="confirm-transfer-to-another-account-details">
          <Row tag="dl" title="Transfer details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:From" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:To" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {nominatedAccountDetails?.Name}
              <span className="d-block">
                {nominatedAccountDetails?.AccountNumber}
              </span>
              <span className="d-block">
                {nominatedAccountDetails?.SortCode}
              </span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {!closeOption ? (
                <FormattedCurrency
                  quantity={amount.replace(/,/g, '')}
                  currency={CurrencyCode}
                />
              ) : (
                <React.Fragment>
                  <FormattedCurrency
                    quantity={balance}
                    currency={CurrencyCode}
                  />
                  <Content
                    cmsTag="GLOBAL:-Full-balance"
                    copytext=" (Full balance)"
                  />
                </React.Fragment>
              )}
            </Col>

            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Purpose" copytext="Purpose" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {purposeOption}
            </Col>

            {closeOption ? (
              <React.Fragment>
                <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                  <Content
                    cmsTag="GLOBAL:Closing-Balance"
                    copytext="Closing balance"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                  <FormattedCurrency
                    quantity={ClosureAmount}
                    currency={CurrencyCode}
                  />
                  {true && (
                    <Content
                      cmsTag="GLOBAL:-including-any-incurred-interest"
                      copytext=" (including any accrued interest)"
                    />
                  )}
                </Col>
              </React.Fragment>
            ) : (
              ''
            )}
          </Row>
          <div className="alert alert-info">
            <Markdown
              cmsTag="GLOBAL:Withdrawal-date-warning"
              markdown={
                'Please note: If your withdrawal date falls on a weekend or bank holiday, it will be processed the following working day.'
              }
            />
          </div>
        </div>
        <div className="form-button-group">
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

CreationSuccess.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  match: PropTypes.any,
  data: PropTypes.any,
  account: PropTypes.any,
  nominatedTransferResponse: PropTypes.any,
  updateUrl: PropTypes.func,
  resetComponentState: PropTypes.func,
  nominatedAccountDetails: PropTypes.object,
};

export default connect(
  null,
  mapDispatchToProps,
)(CreationSuccess);
