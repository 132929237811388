import React from 'react';
import PropTypes from 'prop-types';
import { Markdown, Content, CustomAlert } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class NominatedAccountPendingSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: props.visibility,
      modal: false,
    };
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { visibility } = this.state;
    return (
      <React.Fragment>
        <CustomAlert
          key={`nominated-account-docs-pending-message`}
          visibility={visibility}
          onDismiss={this.onDismiss}
          className={`alert-warning`}
        >
          <React.Fragment>
            <p>
              <Markdown
                cmsTag="Accounts:Nominated-account-pending-message:alert-1:part-1"
                markdown={`You have a pending request to add or update your nominated account. Please upload the documents required for further verification. `}
              />
              <Link
                id="upload-docs-banner-link"
                to={`/nominated-account-for-withdrawals/details/summary`}
              >
                <Content
                  cmsTag="Accounts:Nominated-account-pending-message:Upload-nominated-account-documents"
                  copytext="Upload nominated account documents"
                />
              </Link>

              <Markdown
                cmsTag="Accounts:Nominated-account-pending-message:alert-1:part-2"
                markdown={`.`}
              />
            </p>
          </React.Fragment>
        </CustomAlert>
      </React.Fragment>
    );
  }
}

NominatedAccountPendingSummary.propTypes = {
  account: PropTypes.any,
  iframe: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  visibility: PropTypes.any,
};

export default NominatedAccountPendingSummary;
