import { Connection, TwoFactor } from '@myie/interact';

import {
  SETUP_USER_MANAGEMENT_ACTIVATE_REQUEST,
  SETUP_USER_MANAGEMENT_ACTIVATE_SUCCESS,
  SETUP_USER_MANAGEMENT_ACTIVATE_FAILURE,
  RESET_SETUP_USER_MANAGEMENT_ACTIVATE,
  SETUP_USER_MANAGEMENT_REQUEST,
  SETUP_USER_MANAGEMENT_FAILURE,
  SETUP_USER_MANAGEMENT_SUCCESS,
  RESET_USER_MANAGEMENT,
  SETUP_IFRAME_REQUEST,
  SETUP_IFRAME_FAILURE,
  SETUP_IFRAME_SUCCESS,
  RESET_IFRAME,
  SETUP_NOMINATED_ACCOUNT_STATUS_REQUEST,
  SETUP_NOMINATED_ACCOUNT_STATUS_FAILURE,
  SETUP_NOMINATED_ACCOUNT_STATUS_SUCCESS,
  RESET_NOMINATED_ACCOUNT_STATUS,
} from './userManagementTypes';

export const resetActivation = () => ({
  type: RESET_SETUP_USER_MANAGEMENT_ACTIVATE,
});

export const setupUserManagementActivateRequest = () => ({
  type: SETUP_USER_MANAGEMENT_ACTIVATE_REQUEST,
  isFetching: true,
  error: null,
});

export const setupUserManagementActivateFailure = error => ({
  type: SETUP_USER_MANAGEMENT_ACTIVATE_FAILURE,
  isFetching: false,
  error: error.message,
});

export const setupUserManagementActivateSuccess = json => ({
  type: SETUP_USER_MANAGEMENT_ACTIVATE_SUCCESS,
  activateUserManagement: json,
  isFetching: false,
  error: null,
});
export const setupUserManagementRequest = () => ({
  type: SETUP_USER_MANAGEMENT_REQUEST,
  isFetching: true,
  error: null,
});

export const setupUserManagementFailure = error => ({
  type: SETUP_USER_MANAGEMENT_FAILURE,
  isFetching: false,
  error: error.message,
});

export const setupUserManagementSuccess = json => ({
  type: SETUP_USER_MANAGEMENT_SUCCESS,
  userManagement: json,
  isFetching: false,
  error: null,
});

export const resetUserManagement = json => ({
  type: RESET_USER_MANAGEMENT,
  userManagement: json,
  isFetching: false,
  error: null,
});

export const setupUserManagementActivate = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(setupUserManagementActivateRequest());

    return fetch(Connection.baseUrl('updateuserdetails') + `activate`, {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(setupUserManagementActivateSuccess(json)))
      .catch(error => dispatch(setupUserManagementActivateFailure(error)));
  });
};

export const setupUserDetails = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(setupUserManagementRequest());
    return fetch(Connection.baseUrl('usermanagement') + `v1.0/setuserdetails`, {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => {
        dispatch(setupUserManagementSuccess(json));
      })
      .catch(error => dispatch(setupUserManagementFailure(error)));
  });
};

export const getIframeRequest = () => ({
  type: SETUP_IFRAME_REQUEST,
  isFetching: true,
  error: null,
});

export const getIframeFailure = error => ({
  type: SETUP_IFRAME_FAILURE,
  isFetching: false,
  error: error.message,
});

export const getIframeSuccess = json => ({
  type: SETUP_IFRAME_SUCCESS,
  IframeResponse: json,
  isFetching: false,
  error: null,
});

export const resetGetIframe = json => ({
  type: RESET_IFRAME,
  IframeResponse: json,
  isFetching: false,
  error: null,
});

export const getIframe = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(getIframeRequest());
    return fetch(Connection.baseUrl('getlinkurl'), {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => {
        dispatch(getIframeSuccess(json));
      })
      .catch(error => dispatch(getIframeFailure(error)));
  });
};

export const getNominatedAccountStatusRequest = () => ({
  type: SETUP_NOMINATED_ACCOUNT_STATUS_REQUEST,
  isFetching: true,
  error: null,
});

export const getNominatedAccountStatusFailure = error => ({
  type: SETUP_NOMINATED_ACCOUNT_STATUS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const getNominatedAccountStatusSuccess = json => ({
  type: SETUP_NOMINATED_ACCOUNT_STATUS_SUCCESS,
  NominatedAccountStatusResponse: json,
  isFetching: false,
  error: null,
});

export const resetGetNominatedAccountStatus = () => ({
  type: RESET_NOMINATED_ACCOUNT_STATUS,
  NominatedAccountStatusResponse: null,
  isFetching: false,
  error: null,
});

export const getNominatedAccountStatus = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(getNominatedAccountStatusRequest());
    return fetch(Connection.baseUrl('getonboardingapp'), {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => {
        dispatch(getNominatedAccountStatusSuccess(json));
      })
      .catch(error => dispatch(getNominatedAccountStatusFailure(error)));
  });
};
