import {
  SETUP_USER_MANAGEMENT_ACTIVATE_REQUEST,
  SETUP_USER_MANAGEMENT_ACTIVATE_SUCCESS,
  SETUP_USER_MANAGEMENT_ACTIVATE_FAILURE,
  RESET_SETUP_USER_MANAGEMENT_ACTIVATE,
  SETUP_USER_MANAGEMENT_REQUEST,
  SETUP_USER_MANAGEMENT_FAILURE,
  SETUP_USER_MANAGEMENT_SUCCESS,
  RESET_USER_MANAGEMENT,
  SETUP_IFRAME_REQUEST,
  SETUP_IFRAME_FAILURE,
  SETUP_IFRAME_SUCCESS,
  RESET_IFRAME,
  SETUP_NOMINATED_ACCOUNT_STATUS_REQUEST,
  SETUP_NOMINATED_ACCOUNT_STATUS_FAILURE,
  SETUP_NOMINATED_ACCOUNT_STATUS_SUCCESS,
  RESET_NOMINATED_ACCOUNT_STATUS,
} from '../actions/userManagementTypes';

const brandUserManagementDefinition = (state = {}, action) => {
  switch (action.type) {
    case SETUP_USER_MANAGEMENT_ACTIVATE_SUCCESS:
      return {
        ...state,
        userManagementActivate: action.activateUserManagement,
        isFetching: action.isFetching,
      };
    case RESET_SETUP_USER_MANAGEMENT_ACTIVATE:
      return {
        ...state,
        userManagementActivate: null,
        isFetching: false,
        errorMessage: null,
      };
    case SETUP_USER_MANAGEMENT_ACTIVATE_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        errorMessage: action.error,
      };
    case SETUP_USER_MANAGEMENT_ACTIVATE_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case SETUP_USER_MANAGEMENT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SETUP_USER_MANAGEMENT_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        errorMessage: action.error,
      };
    case SETUP_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        userManagementSuccess: action.userManagement,
        isFetching: action.isFetching,
      };
    case RESET_USER_MANAGEMENT:
      return {
        ...state,
        userManagementSuccess: null,
        isFetching: false,
        errorMessage: null,
      };

    case SETUP_IFRAME_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SETUP_IFRAME_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        errorMessage: action.error,
      };
    case SETUP_IFRAME_SUCCESS:
      return {
        ...state,
        iframe: action.IframeResponse,
        isFetching: action.isFetching,
      };
    case RESET_IFRAME:
      return {
        ...state,
        iframe: null,
        isFetching: false,
        errorMessage: null,
      };

    case SETUP_NOMINATED_ACCOUNT_STATUS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SETUP_NOMINATED_ACCOUNT_STATUS_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        errorMessage: action.error,
      };
    case SETUP_NOMINATED_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        nominatedAccountStatus: action.NominatedAccountStatusResponse,
        isFetching: action.isFetching,
      };
    case RESET_NOMINATED_ACCOUNT_STATUS:
      return {
        ...state,
        nominatedAccountStatus: null,
        isFetching: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default brandUserManagementDefinition;
