import React from 'react';
import { CardColumns, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Content, AppMeta, CONSTANTS } from '@myie/interact-dom';
import RegisteredServiceItems from './RegisteredServiceItems';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { NominatedAccountDetailsServiceItems } from '@myie/interact-accounts-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class ServicesIndex extends React.Component {
  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  render() {
    let { accounts = {} } = this.props;
    let { Accounts: list = [] } = accounts;
    let accountsToList = [];
    let savingsAccountAvailable;

    list.forEach(account => {
      let thisAccount = account;
      if (
        thisAccount.AccountStatus !== 'Closed' &&
        thisAccount.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED
      ) {
        accountsToList.push(thisAccount);
      }
    });

    if (accounts && accounts.Accounts) {
      savingsAccountAvailable = list.find(account => {
        return (
          account.ExtendedProperties.AccountType ===
          CONSTANTS.ACCOUNT_TYPE_SAVINGS
        );
      });
    }

    return (
      <div id="services" className="secondary-nav">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="My Details"
          metaDescription="Services landing page"
        />
        <Content
          tag="h1"
          cmsTag="Services:Services-index:h1"
          copytext="My Details"
        />
        <CardColumns className="grid-mode">
          {RegisteredServiceItems.map(sb => (
            <Card key={sb.name} id={sb.name} className="card-item">
              {<sb.serviceItem />}
            </Card>
          ))}
          {accounts && accounts.Accounts && savingsAccountAvailable ? (
            <Card
              key="NominatedAccountDetailsServiceItems"
              id="NominatedAccountDetailsServiceItems"
              className="card-item"
            >
              <CardBody>{<NominatedAccountDetailsServiceItems />}</CardBody>
            </Card>
          ) : (
            ''
          )}
        </CardColumns>
      </div>
    );
  }
}

ServicesIndex.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  userDetails: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition } = state;
  return {
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServicesIndex);
