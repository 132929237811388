import * as interestRateActions from './actions/interestRateActions';
import * as brandDocumentActions from './actions/documentActions';
import * as MaturityActions from './actions/MaturityActions';
import * as accountsActions from './actions/accountsActions';
import * as nominatedAccountActions from './actions/nominatedAccountActions';

const mapDispatchToProps = {
  ...interestRateActions,
  ...brandDocumentActions,
  ...MaturityActions,
  ...nominatedAccountActions,
  ...accountsActions,
};

export default mapDispatchToProps;
