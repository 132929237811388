import UpdateSecurityDetailsServiceItems from './UpdateSecurityDetailsServiceItems';

const AuthenticationServiceItems = [
  {
    name: 'update-security-details-service-item',
    serviceItem: UpdateSecurityDetailsServiceItems,
  },
];

export default AuthenticationServiceItems;
