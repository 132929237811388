import React from 'react';
import { Col, Row } from 'reactstrap';
import { Content, FormattedCurrency } from '@myie/interact-dom';
import PropTypes from 'prop-types';

const WithdrawalAccount = ({ account, noticePeriod }) => {
  const {
    ExtendedProperties: { AvailableBalance, PendingBalance } = {},
  } = account;
  const balance = AvailableBalance + PendingBalance;

  return (
    <div
      className="withdraw-account-details clear-both"
      title="Account details"
    >
      <Row tag="dl" title="Account details">
        <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
          <Content cmsTag="GLOBAL:Account-number" copytext="Account number" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
          {account.AccountNumber}
        </Col>
        <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
          <Content cmsTag="GLOBAL:Balance" copytext="Balance" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
          <FormattedCurrency
            quantity={balance}
            currency={account.CurrencyCode}
          />
        </Col>
        <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
          <Content cmsTag="GLOBAL:Notice-period" copytext="Notice period" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
          {noticePeriod}
          <Content cmsTag="GLOBAL:-days" copytext=" days" />
        </Col>
      </Row>
    </div>
  );
};

WithdrawalAccount.propTypes = {
  account: PropTypes.object,
  noticePeriod: PropTypes.number,
};

export default WithdrawalAccount;
