import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';
import { Button } from 'reactstrap';

class NominatedAccountFailed extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      case 'failed':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    const { changeUrl } = this.props;
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <div className="confirm-account-details-add-nominated-account">
          <Content
            tag="h1"
            cmsTag="Accounts:Nominated-account:Edit-nominated-account:Failed:p1"
            copytext="Add/Update Nominated Account"
          />
          <Content
            tag="p"
            cmsTag="Accounts:Nominated-account:Edit-nominated-account:Failed:p2"
            copytext="Your request to add/update your nominated account was unsuccessful. If you have any question, please send us secure message or call our Customer serive Team."
          />
        </div>
        <div className="form-button-group">
          {this.getBackButton(match && match.params ? match.params.page : '')}
        </div>
      </React.Fragment>
    );
  }
}

NominatedAccountFailed.propTypes = {
  page: PropTypes.any,
  data: PropTypes.any,
  changeUrl: PropTypes.func,
  match: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(NominatedAccountFailed);
