import React, { Component } from 'react';
import { AppMeta, Content } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Utility } from '@myie/interact';
import PropTypes from 'prop-types';
import { storage } from './storageName';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as savingsMapDispatchToProps } from '@myie/interact-account-type-savings';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CancellationSuccess from './CancellationSuccess';
import { CONTACTS } from '@myie/interact-local-dom';
import Cancel from './Cancel';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../../shared/HO_components/checkDownTime';
class CancelNoticeToWithdrawView extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState = () => {
    const {
      accounts,
      match,
      noticesList = null,
      getListNoticeToWithdraw,
    } = this.props;
    const { urlParams, refreshUrl } = this.props;
    const stateList = ['ConfirmCancel', 'Success'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);

    let stage =
      this.props.stateData &&
      this.props.stateData.url &&
      this.props.stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'ConfirmCancel';
    if (!hasStage) {
      stage = 'ConfirmCancel';
      refreshUrl();
    }
    let account;
    let selectedNotice = null;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      if (!noticesList) {
        getListNoticeToWithdraw({
          AccountKey: account.AccountKey,
          SubAccountNumber: account.ExtendedProperties.SubAccountNumber,
        });
      } else {
        selectedNotice = noticesList.find(function(notice) {
          return notice.NoticeId === match.params.referenceNo;
        });
      }
    } else {
      account = null;
    }
    return {
      account: account,
      stage: stage,
      identifier: match.params.referenceNo,
      paymentMethod: match.params.paymentMethod,
      page: match.params.page,
      status: '',
      selectedNotice,
    };
  };

  static getDerivedStateFromProps = nextProps => {
    const {
      cancellationResponse = {},
      resetCancellation,
      updateUrl,
    } = nextProps;

    if (cancellationResponse && cancellationResponse.ResponseStatus) {
      if (cancellationResponse.ResponseStatus === 'Success') {
        let stateData = {
          url: {
            pageStage: 'Success',
          },
        };

        updateUrl(stateData);
        return {
          stage: cancellationResponse.ResponseStatus,
        };
      }

      resetCancellation();
      return { status: cancellationResponse.ResponseStatus };
    }
    return null;
  };

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const { match, accounts, noticesList } = this.props;
    let account = null;

    if (accounts && prevProps.accounts !== accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
    if (prevProps.noticesList !== noticesList && noticesList.length) {
      const selectedNotice = noticesList.find(function(notice) {
        return notice.NoticeId === match.params.referenceNo;
      });
      this.setState({ ...this.state, selectedNotice });
    }
  }

  componentWillUnmount() {
    const { resetCancellation } = this.props;
    resetCancellation();
  }

  onConfirmCancellation = () => {
    const { cancelNoticeToWithdraw } = this.props;
    const { selectedAccount, selectedTransaction } = this.state;
    cancelNoticeToWithdraw({
      AccountKey: selectedAccount && selectedAccount.AccountKey,
      TransactionIdentifier: selectedTransaction.TransactionIdentifier,
    });
  };

  // select current stage
  currentStage = account => {
    const { accounts = {} } = this.props;
    const { page, paymentMethod, selectedNotice, identifier } = this.state;
    const { AccountKey: { Key } = {} } = account;
    const accountKey = Key && Utility.hexEncode(Key);
    switch (this.state.stage) {
      case 'ConfirmCancel':
        return (
          <Cancel
            accounts={accounts}
            account={account}
            selectedNotice={selectedNotice}
            page={page}
            paymentMethod={paymentMethod}
            identifier={identifier}
          />
        );
      case 'Success':
        return (
          <CancellationSuccess
            accountKey={accountKey}
            account={account}
            selectedNotice={selectedNotice}
            page={page}
          />
        );

      default:
    }
  };

  render() {
    const { stage, account, status } = this.state;
    const {
      systemDownStatus, //From checkDownTime HOC
    } = this.props;

    if (status === 'Failed') {
      return <Redirect to="/network-error" />;
    }
    if (!account) {
      return null;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    return (
      <React.Fragment>
        {systemDownStatus.msg}
        {stage === 'ConfirmCancel' ? (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              stage="child"
              contacts={CONTACTS}
              title="Delete notice to withdraw"
              metaDescription="Delete notice to withdraw"
            />
            <Content
              tag="h1"
              cmsTag="Savings-servicing:Notice-to-withdrawal:Cancel-notice-to-withdraw:Index:Confirm-cancel:h1"
              template={{
                copytext: {
                  accountName: account.AccountName,
                },
              }}
              copytext="Are you sure you want to delete your notice to withdraw for your $[accountName]?"
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {stage === 'Success' ? (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              stage="child"
              contacts={CONTACTS}
              title="Notice to withdraw cancellation successful"
              metaDescription="Notice to withdraw cancellation successful"
            />
            <Content
              tag="h1"
              cmsTag="Savings-servicing:Notice-to-withdrawal:Cancel-notice-to-withdraw:Index:Success:h1"
              template={{
                copytext: {
                  accountName: account.AccountName,
                },
              }}
              copytext="Your notice to withdraw for your $[accountName] has been deleted"
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {this.currentStage(account)}
      </React.Fragment>
    );
  }
}

CancelNoticeToWithdrawView.propTypes = {
  match: PropTypes.any,
  accounts: PropTypes.any,
  cancellationResponse: PropTypes.any,
  message: PropTypes.any,
  history: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  cancelNoticeToWithdraw: PropTypes.func,
  resetCancellation: PropTypes.func,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  isFetching: PropTypes.any,
  stateData: PropTypes.any,
  urlParams: PropTypes.any,
  refreshUrl: PropTypes.any,
  referenceNo: PropTypes.any,
  range: PropTypes.any,
  getListNoticeToWithdraw: PropTypes.func,
  noticesList: PropTypes.array,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      {
        ...mapDispatchToProps,
        ...mapSavingsServicingDispatchToProps,
        ...savingsMapDispatchToProps,
      },
    )(withRouter(CancelNoticeToWithdrawView)),
  ),
  storage.name,
);
