import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import ReplyConversation from './Reply';
import { Utility } from '@myie/interact';
import ReplyConfirmation from './Confirmation';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import { storage } from './../../storageName';
import PropTypes from 'prop-types';

class ReplyIndex extends React.Component {
  constructor(props) {
    super(props);
    const {
      match,
      allMessages,
      history,
      viewMessage,
      viewConversation,
      urlParams,
      refreshUrl,
    } = this.props;
    let conversation;

    const stateList = ['Reply', 'Confirmation'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      this.props.stateData &&
      this.props.stateData.url &&
      this.props.stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'New';
    if (!hasStage) {
      stage = 'Reply';
      refreshUrl();
    }

    this.state = {
      stage: stage,
      message: null,
      data: {},
    };
    if (allMessages) {
      if (!viewMessage) {
        conversation = allMessages.Conversations.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.ConversationKey.Key) === match.params.id
            );
          }
          return false;
        });

        let request = {
          ConversationKey: conversation.ConversationKey,
          ProductType: 'string',
          ExtendedProperties: {},
        };
        viewConversation(request);
      }
    } else {
      const newUrl = '/messaging-twoway/view';
      history.push(newUrl);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.replyMessageStatus && prevState.stage === 'Reply') {
      let stateData = {
        url: {
          pageStage: 'Confirmation',
        },
      };
      nextProps.updateUrl(stateData);
      return { ...prevState, stage: 'Confirmation' };
    } else return null;
  }

  // save the data for the request from several forms
  saveReplyData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // change the stage of the form
  setReplyStage = stage => {
    const { data } = this.state;
    this.setState({ ...this.state, stage, data, message: null });
  };

  // select current stage
  currentStage = () => {
    const { stage } = this.state;
    const { match } = this.props;

    switch (stage) {
      case 'Reply':
        return (
          <ReplyConversation
            match={match}
            saveReplyData={this.saveReplyData}
            setReplyStage={this.setReplyStage}
            transactionData={this.state.data}
          />
        );
      case 'Confirmation':
        return (
          <ReplyConfirmation
            saveReplyData={this.saveReplyData}
            setReplyStage={this.setReplyStage}
            transactionData={this.state.data}
            verify={this.verify}
          />
        );
      default:
    }
  };

  render() {
    const { error } = this.props;
    let alert;

    const { message } = this.state;
    if (message || error) {
      alert = (
        <Alert id="reply-message-alert" color="danger">
          {message || error}
        </Alert>
      );
    }

    return (
      <div id="messaging-twoway-reply">
        {alert ? { alert } : ''}
        {this.currentStage()}
      </div>
    );
  }
}

ReplyIndex.propTypes = {
  accounts: PropTypes.any,
  allMessages: PropTypes.any,
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  history: PropTypes.any,
  initializeMoveMoney: PropTypes.any,
  match: PropTypes.any,
  replyMessageStatus: PropTypes.any,
  retrievedUserName: PropTypes.object,
  transfer: PropTypes.any,
  refreshUrl: PropTypes.any,
  stateData: PropTypes.any,
  urlParams: PropTypes.any,
  updateUrl: PropTypes.any,
  viewConversation: PropTypes.any,

  viewMessage: PropTypes.any,
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReplyIndex),
  storage.name,
);
