import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TimeOut } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

import Create from './Create';
import Update from './Update';
import Blocked from './Blocked';
import AccessDenied from './AccessDenied';

import { NoMatch } from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { Alert } from 'reactstrap';
import { storage } from './storageName';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rememberme: false, username: null };
  }

  componentDidMount() {
    const { setReferrer, location } = this.props;
    const { from } = location.state || { from: { pathname: '/accounts' } };
    let stage =
      this.props.urlParams && this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : '';
    if (!stage) {
      setReferrer(from);
    }
  }

  toggleRememberMe = value => {
    this.setState({ ...this.state, rememberme: !!value });
  };

  setRememberMeUserName = username => {
    this.setState({ ...this.state, username });
  };

  timeoutReset = () => {
    window.sessionStorage.setItem('timeoutMessage', 'TimeoutReset');
  };

  timeoutObj = {
    history: this.props.history,
    userLogout: this.props.userLogout,
    message: 'SignInTimeout',
    url: '/sign-in',
    onTimeout: this.timeoutReset,
    showTimeoutModal: this.props.showTimeoutModal,
    type: 'LOG_IN',
  };

  timeout = new TimeOut(this.timeoutObj);

  render() {
    const { error, updateUrl } = this.props;
    const { rememberme, username } = this.state;
    let re = new RegExp('^/sign-in[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/sign-in/step-1" />;
    }
    return (
      <React.Fragment>
        {error ? <Alert color="danger">{error}</Alert> : ''}
        <Switch>
          <Route
            exact
            path="/sign-in/step-1"
            render={props => (
              <Create
                {...props}
                timeout={this.timeout}
                toggleRememberMe={this.toggleRememberMe}
                setRememberMeUserName={this.setRememberMeUserName}
                contacts={CONTACTS}
              />
            )}
          />
          <Route
            exact
            path="/sign-in/step-2"
            render={props => (
              <Update
                {...props}
                timeout={this.timeout}
                rememberme={rememberme}
                updateUrl={updateUrl}
                remembermeUsername={username}
              />
            )}
          />
          <Route exact path="/sign-in/blocked" component={Blocked} />
          <Route exact path="/sign-in/access-denied" component={AccessDenied} />
          <Route component={NoMatch} />
        </Switch>
      </React.Fragment>
    );
  }
}

SignIn.propTypes = {
  error: PropTypes.any,
  urlParams: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  setReferrer: PropTypes.any,
  showTimeoutModal: PropTypes.any,
  userLogout: PropTypes.any,
  updateUrl: PropTypes.any,
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    {
      ...mapDispatchToProps,
      ...mapDispatchToProps_shared,
    },
  )(SignIn),
  storage.name,
);
