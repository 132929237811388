import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SendNewMessage from './SendNewMessage';
import Confirmation from './Confirmation';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { Content } from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import { withRouter } from 'react-router-dom';
import { storage } from './../storageName';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';

class NewMessage extends React.Component {
  constructor(props) {
    super(props);
    const { urlParams, refreshUrl } = this.props;
    const stateList = ['New', 'Confirmation'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      this.props.stateData &&
      this.props.stateData.url &&
      this.props.stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'New';
    if (!hasStage) {
      stage = 'New';
      refreshUrl();
    }
    //const { resetReply } = props;
    this.state = {
      stage: stage,
      data: {},
    };
    //resetReply();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { replyMessageStatus, match, accounts, updateUrl } = nextProps;
    const { data } = prevState;

    if (replyMessageStatus && replyMessageStatus.ResponseStatus) {
      if (
        prevState.stage === 'New' &&
        prevState.data &&
        prevState.data.message
      ) {
        let stateData = {
          url: {
            pageStage: 'Confirmation',
          },
        };
        updateUrl(stateData);
        return {
          ...prevState,
          stage: 'Confirmation',
        };
      }
      return null;
    }
    if (match.params.id && accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      if (match.params.subject) {
        data['subject'] = match.params.subject;
      }
      if (match.params && match.params.subject === '5') {
        data['relatedAccount'] = null;
      } else {
        data['relatedAccount'] = account.AccountKey.Key;
        data['relatedAccountObj'] = account;
      }

      return {
        ...prevState,
        data: data,
      };
    }
    return null;
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  componentWillUnmount = () => {
    const { resetReply } = this.props;
    resetReply();
  };

  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data });
  };

  currentStage = (accounts = []) => {
    const { data, stage } = this.state;
    const { replyMessageStatus } = this.props;
    switch (stage) {
      case 'New': {
        return (
          <SendNewMessage
            accounts={accounts}
            saveData={this.saveData}
            selectedData={data}
          />
        );
      }
      case 'Confirmation':
        return (
          <Confirmation
            replyMessageStatus={replyMessageStatus}
            selectedData={data}
          />
        );
      default:
    }
  };

  render() {
    const { accounts } = this.props;
    const { stage } = this.state;
    return (
      <React.Fragment>
        {stage === 'New' ? (
          <Content
            tag="h1"
            cmsTag="Messaging-two-way:New:Index:h1"
            copytext="Send a new message"
          />
        ) : (
          ''
        )}
        {accounts ? this.currentStage(accounts) : false}
      </React.Fragment>
    );
  }
}

NewMessage.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  replyMessageStatus: PropTypes.any,
  resetReply: PropTypes.any,
  stateData: PropTypes.any,
  match: PropTypes.any,
  updateUrl: PropTypes.any,
  urlParams: PropTypes.any,
  refreshUrl: PropTypes.any,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
  )(withRouter(NewMessage)),
  storage.name,
);
