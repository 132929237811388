import React, { Component } from 'react';
import { Content, AppMeta, FormattedCurrency } from '@myie/interact-dom';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
class CreationSuccess extends Component {
  constructor(props) {
    super(props);

    const { responseUrlParams } = this.props;
    //Can not reply on responseUrlParams prop as it keep on changing. So this is taking into state var on component creation
    this.state = {
      callBackParams: responseUrlParams,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      depositResponse,
      updateUrl,
      responseUrlParams,
      checkPaymentHash,
      accountKey,
      fetchAccountsIfNeeded,
    } = this.props;
    fetchAccountsIfNeeded();
    let stateData = {
      formData: null,
      depositData: depositResponse,
      url: {
        pageStage: 'Completed',
      },
    };
    const request = {
      IsPaymentSuccessful: true,
      OrderKey: responseUrlParams.orderKey,
      PaymentAmount: responseUrlParams.paymentAmount,
      PaymentCurrency: responseUrlParams.paymentCurrency,
      PaymentStatus: responseUrlParams.paymentStatus,
      PaymentHash: responseUrlParams.mac2,
      AccountKey: accountKey,
    };

    checkPaymentHash(request);
    updateUrl(stateData);
  }
  componentWillUnmount() {
    const { resetComponentState } = this.props;
    resetComponentState();
  }

  render() {
    const { account } = this.props;

    const {
      callBackParams: { paymentAmount: amount, refID: reference },
    } = this.state;
    if (!account) {
      return null;
    }

    const { CurrencyCode, AccountName, AccountNumber, SortCode } = account;

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Make a deposit success"
          metaDescription="Make a deposit success"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Make-a-deposit:Make-a-deposit:Creation-success:h1"
          copytext="Your deposit was successful"
        />
        <Row tag="dl" title="Deposit details">
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Reference" copytext="Reference" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {reference}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Account-to-make-deposit-to"
              copytext="Account to deposit money into"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {AccountName}
            <span className="d-block">{AccountNumber}</span>
            <span className="d-block">{SortCode}</span>
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            <FormattedCurrency
              quantity={amount / 100}
              currency={CurrencyCode}
            />
          </Col>
        </Row>
        <div className="form-button-group">
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

CreationSuccess.propTypes = {
  match: PropTypes.any,
  data: PropTypes.any,
  account: PropTypes.any,
  depositResponse: PropTypes.any,
  updateUrl: PropTypes.func,
  resetComponentState: PropTypes.func,
  responseUrlParams: PropTypes.object,
  checkPaymentHash: PropTypes.func,
  accountKey: PropTypes.object,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
};
const mapStateToProps = state => {
  const { accountsDefinition } = state;
  return {
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapSavingsServicingDispatchToProps,
  },
)(CreationSuccess);
