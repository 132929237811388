export const FETCH_SAVINGS_TRANSACTIONS_REQUEST =
  'FETCH_SAVINGS_TRANSACTIONS_REQUEST';
export const FETCH_SAVINGS_TRANSACTIONS_SUCCESS =
  'FETCH_SAVINGS_TRANSACTIONS_SUCCESS';
export const FETCH_SAVINGS_TRANSACTIONS_FAILURE =
  'FETCH_SAVINGS_TRANSACTIONS_FAILURE';
export const FETCH_SAVINGS_ACCOUNT_REQUEST = 'FETCH_SAVINGS_ACCOUNT_REQUEST';
export const FETCH_SAVINGS_ACCOUNT_SUCCESS = 'FETCH_SAVINGS_ACCOUNT_SUCCESS';
export const FETCH_SAVINGS_ACCOUNT_FAILURE = 'FETCH_SAVINGS_ACCOUNT_FAILURE';
export const RESET_SAVINGS_ACCOUNT = 'RESET_SAVINGS_ACCOUNT';
