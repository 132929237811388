export const RULES = {
  //Logins
  username: /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{6,23})$/,
  memorableNumber: /^[0-9]{6}$/,
  password: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{8,50}$/,
  usernameOptional: /^$|^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{6,23})$/,
  passwordOptional: /^$|^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s$).{8,50}$/,
  memorableNumberOptional: /^$|[0-9]{6}$/,
  activationCode: /^[a-zA-Z]{2}[0-9]{4}$/,
  activationCode2: /^[0-9]{6}$/,
  passCode: /^[0-9]{1}$/,

  //Account Details
  accountHolderName: /^[a-zA-Z- ']{1,30}$/,
  accountNumber: /^[0-9]{8}$/,
  accountNumber2: /^[0-9]{8,8}$/,
  sortCode: /^[0-9]{2}-[0-9]{2}-[0-9]{2}$/,
  payeeName: /^[a-zA-Z0-9 ']{1,18}$/,
  paymentReference: /^[a-zA-Z0-9]{1,30}$/,
  withdrawalReference: /^[a-zA-Z- .\d/&]{0,18}$/,
  ammountFormat: /^\d+(,\d{3})*\.?\d*$/,
  ammountFormat2: /^\d*(,\d+)*\.?\d*$/,
  dateFormat: /^[^\s].{9}$/,

  //Personal Details
  firstName: /^[a-zA-Z- ']{2,15}$/,
  middleName: /^$|^[a-zA-Z- ']{2,30}$/,
  lastName: /^[a-zA-Z- ']{2,35}$/,
  nationalInsuranceNumber: /^(?!BGbg)(?!GBgb)(?!NKnk)(?!KNkn)(?!TNtn)(?!NTnt)(?!ZZzz)(?:[A-CEGHJ-PR-TW-Za-ceghj-pr-tw-z][A-CEGHJ-NPR-TW-Za-ceghj-npr-tw-z])(?:\s*\d\s*){6}([A-Da-d]|\s)$/,
  mobilePhoneNumber: /^[0|+]{1}7\d{3}(\s)?\d{3}(\s)?\d{3}$/,
  daytimePhoneNumber: /^$|^\s*[0|+]{1}(([+]\s?\d[-\s]?\d|0)?\s?\d([-\s]?\d){9}|[(]\s?\d([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*$/,
  eveningPhoneNumber: /^$|^\s*[0|+]{1}(([+]\s?\d[-\s]?\d|0)?\s?\d([-\s]?\d){9}|[(]\s?\d([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*$/,
  emailAddress: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+){0,320}/,
  dateNotBefore1900: /^([12][09]\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,

  //Address Details
  flatNumber: /^[a-zA-Z0-9- ]{0,6}$/,
  houseName: /^[a-zA-Z0-9- ]{0,40}$/,
  houseNumber: /^[a-zA-Z0-9- ]{0,6}$/,
  street: /^[a-zA-Z0-9- .]{2,40}$/,
  village: /^$|^[^-\s][a-zA-Z0-9- .]{2,40}$/,
  town: /^[^-\s][a-zA-Z0-9- .]{2,30}$/,
  county: /^$|^[a-zA-Z0-9- .]{2,25}$/,
  postcode: /^[a-zA-Z]{1,2}[0-9][0-9A-Za-z]{0,1} {0,1}[0-9][A-Za-z]{2}$/,

  //Your Finances
  TaxIdentification: /^[a-zA-Z0-9]*$/,

  //Messages
  messageBody: /^[^\s](.|[\r\n]){1,750}$/,

  //Savings Goals
  savingsGoalName: /^[a-zA-Z- ]/,
};
