import brandInterestRateDefinition from './reducers/interestRateDefinition';
import brandDocumentsDefinition from './reducers/documentsDefinition';
import maturityDefinition from './reducers/maturityDefinition';
import accountDetailsDefinition from './reducers/accountDetailsDefinition';
import nominatedAccountDefinition from './reducers/nominatedAccountDefinition';

const accountsDefinition = (state = {}, action) => {
  let newState = state;
  newState = brandInterestRateDefinition(newState, action);
  newState = brandDocumentsDefinition(newState, action);
  newState = maturityDefinition(newState, action);
  newState = accountDetailsDefinition(newState, action);
  newState = nominatedAccountDefinition(newState, action);
  return newState;
};

export default accountsDefinition;
