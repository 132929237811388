import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class AccountAlreadyExists extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="registration-account-already-exists">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Account already exists"
          metaDescription="Account already exists"
        />
        <Content
          tag="h1"
          tagID="registration-account-already-exists-title"
          cmsTag="Authentication:Registration:Account-already-exists:h1"
          copytext="Account already exists"
        />
        <Content
          tag="p"
          cmsTag="Authentication:Registration:Account-already-exists:p1"
          copytext={`This account has already been registered. Click on "Sign in" to access your account.`}
        />
        <div className="form-button-group">
          <Link
            id="registration-account-already-exists-sign-in-button"
            className="btn btn-primary"
            role="button"
            to="/sign-in"
          >
            <Content cmsTag="GLOBAL:Sign-in" copytext="Sign in" />
          </Link>
        </div>
      </div>
    );
  }
}

AccountAlreadyExists.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountAlreadyExists);
