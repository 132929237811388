import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Content,
  AppMeta,
  FormattedCurrency,
  CONSTANTS,
  FormattedDate,
} from '@myie/interact-dom';
import { Row, Col, Button } from 'reactstrap';
import { CONTACTS } from '@myie/interact-local-dom';

class Success extends React.Component {
  deletThiseGoal = () => {
    const { deleteGoal } = this.props;
    deleteGoal();
  };

  render() {
    const { goal = {}, account = null, getBackButton, page } = this.props;

    if (account === null) {
      return null;
    }

    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;

    return (
      <div id="Savings-servicing-delete">
        <AppMeta
          id="delete-savings-goal"
          contacts={CONTACTS}
          stage="child"
          title="Review delete goal"
          metaDescription="Review delete goal"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Delete-goal:Delete:h1"
          copytext="Are you sure you want to delete your goal?"
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Delete-goal:Delete:p1"
          copytext="You are about to delete your goal. Your account balance will not be affected and you can add another goal to this account later."
        />
        <Row tag="dl" title={`Goal details`}>
          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Name-of-goal" copytext="Name of goal" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            {goal.Name ? goal.Name : ''}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Linked-account" copytext="Linked account" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            {account && account.AccountName ? account.AccountName : ''}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:You-have" copytext="You have" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedCurrency
              quantity={
                PendingBalance
                  ? AvailableBalance + PendingBalance
                  : AvailableBalance
              }
              currency={account.CurrencyCode}
            />
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:You-need" copytext="You need" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedCurrency
              quantity={goal.Amount}
              currency={account.CurrencyCode}
            />
            <Content cmsTag="GLOBAL:-by-" copytext=" by " />
            <FormattedDate
              date={goal.TargetDate}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>
        </Row>

        <div className="form-button-group">
          <Button
            id="Savings-servicing-delete-submit"
            onClick={this.deletThiseGoal}
            color="primary"
          >
            <Content
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Delete-goal:Delete:Delete-GOALNAME-goal"
              template={{
                copytext: {
                  goalName: goal.Name,
                },
              }}
              copytext="Delete $[goalName] goal"
            />
          </Button>
          {getBackButton(page)}
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  deleteGoal: PropTypes.any,
  getBackButton: PropTypes.func,
  page: PropTypes.string,
  goal: PropTypes.any,
  account: PropTypes.object,
  PendingBalance: PropTypes.any,
  AvailableBalance: PropTypes.any,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Success);
