import RegistrationSidebar from './registration/Sidebar';
import ForgottenNameSidebar from './forgotten-username/Sidebar';
import ForgottenPasswordSidebar from './forgotten-password/Sidebar';
import UpdateSecurityDetailsSidebar from './update-security-details/Sidebar';
import SignedOutSidebar from './signed-out/Sidebar';
import SignInSidebar from './sign-in/Sidebar';

const AuthenticationSidebars = [
  { url: '/registration/', Sidebar: RegistrationSidebar },
  { url: '/forgotten-username/', Sidebar: ForgottenNameSidebar },
  { url: '/sign-in/', Sidebar: SignInSidebar },
  { url: '/signed-out', Sidebar: SignedOutSidebar },
  { url: '/forgotten-password/', Sidebar: ForgottenPasswordSidebar },
  { url: '/update-security-details', Sidebar: UpdateSecurityDetailsSidebar },
];

export default AuthenticationSidebars;
