import React, { Component } from 'react';
import {
  Content,
  RadioGroup,
  Switch,
  Radio,
  Text,
  RULES,
  CONSTANTS,
} from '@myie/interact-dom';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Form } from 'reactstrap';
import { Validate } from '@myie/interact';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-brand-savings-servicing';

class SelectGoal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        goal: {
          rules: {
            required: true,
          },
        },
        goalName: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter a name for your goal.',
            },
            format: {
              regex: RULES.savingsGoalName,
              message: 'Please enter a valid name for your goal',
            },
          },
        },
      },
      disabled: false,
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };
  onChangeGoal = e => {
    const { name, value } = e.target;
    const { options } = this.props;
    let { form } = this.state;
    if (value && value !== 'other') {
      const goal = options.find(element => element.key === value);
      form = Validate.input('goalName', goal.name, form, true);
      this.props.saveData('goalName', goal.name);
    } else {
      form = Validate.input('goalName', '', form, true);
      this.props.saveData('goalName', '');
    }
    this.props.saveData('goal', value);
    form = Validate.input(name, value, form, true);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  changeAccount = () => {
    let {
      setStateDynamic,
      history,
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST,
      match,
    } = this.props;
    if (backPageMode === 'undefined') {
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST;
    }
    let url = '/money-tools/savings-goals/create-goals/' + backPageMode + '/';
    if (
      match &&
      match.params &&
      match.params.id &&
      backPageMode !== CONSTANTS.SOURCE_PAGE_GOAL_LIST
    ) {
      url += match.params.id;
    }
    history.push(url);
    setStateDynamic({
      stage: 'addNewGoal',
    });
  };

  submit = e => {
    e.preventDefault();
    const { setStateDynamic, updateUrl } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form: form });
    if (!form.approved) {
      return;
    }
    let stateData = {
      formData: this.props.formData,
      url: {
        pageStage: 'goalDetails',
      },
    };
    updateUrl(stateData);
    setStateDynamic({
      stage: 'goalDetails',
    });
  };

  getErrorMessage = () => {
    const { form } = this.state;

    let message = null;
    if (form.goal.state && Validate.isInvalid(form.goal.state)) {
      message = (
        <React.Fragment>
          <div
            id="error-goalType-validation"
            aria-live="assertive"
            aria-relevant="additions removals"
            className="invalid-feedback"
          >
            <p id="error-goalType-required">
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Add-a-new-goal:Select-goal:error-message-1"
                copytext="Please select a category for your goal."
              />
            </p>
          </div>
        </React.Fragment>
      );
    }

    return message;
  };

  render() {
    const { form } = this.state;
    const { options, getBackButton, page } = this.props;
    let goals = [];
    if (options && options.length) {
      options.forEach((d, i) => {
        goals.push(
          <Radio
            autoFocus={i === 0}
            label={`${d.name}`}
            key={`goal-${i}`}
            id={`goal-${i}`}
            field="goal"
            className={`${d.name} savings-radio`}
            onChange={this.onChangeGoal}
            validation={form.goal}
            value={d.key}
            radioImage={d.key}
            noFormGroup={true}
          />,
        );
      });
    }

    return (
      <React.Fragment>
        <Switch
          id="add-new-goal-alert"
          value={this.state.message || ''}
          tag="div"
          className="alert alert-danger"
          scrolltotop={true}
          role="alert"
          contents={{
            failed: {
              defaultValue: 'We were unable to add your new goal.',
            },
          }}
        />
        <Form autoComplete="off" id="savings-goals-form" onSubmit={this.submit}>
          <RadioGroup
            id="add-goal-radio-group"
            legend="Choose a category"
            cmsTag="GLOBAL:Choose-a-category"
            className="d-flex flex-wrap align-content-stretch"
            required={true}
            field="goalName"
          >
            {goals}
            {this.getErrorMessage()}
          </RadioGroup>
          <Text
            label="Choose a name for your goal"
            cmsTag="GLOBAL:Choose-a-name-for-your-goal"
            id="goalName"
            field="goalName"
            maxLength={'20'}
            onChange={this.onChange}
            validation={form.goalName}
            onBlur={this.onBlur}
          />

          <div className="form-button-group">
            <Button
              id="savings-goals-submit"
              type="submit"
              color="primary"
              onClick={this.clickNext}
            >
              <Content
                cmsTag="GLOBAL:Enter-goal-details"
                copytext="Enter goal details"
              />
            </Button>
            <Button
              id="savings-goals-change-account"
              color="light"
              onClick={this.changeAccount}
            >
              <Content
                cmsTag="GLOBAL::Choose-another-account"
                copytext="Choose another account"
              />
            </Button>

            {getBackButton(page)}
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

SelectGoal.propTypes = {
  match: PropTypes.any,
  getBackButton: PropTypes.any,
  page: PropTypes.string,
  history: PropTypes.any,
  error: PropTypes.any,
  updateUrl: PropTypes.func,
  isFetching: PropTypes.any,
  createResponse: PropTypes.any,
  formData: PropTypes.object,
  account: PropTypes.any,
  setStage: PropTypes.any,
  form: PropTypes.any,
  actionDate: PropTypes.any,
  options: PropTypes.array,
  saveData: PropTypes.any,
  setStateDynamic: PropTypes.func,
  backPageMode: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SelectGoal));
