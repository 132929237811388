import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Button } from 'reactstrap';

class AddNominatedAccountReferredWithDocs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { match, mode = 'add' } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Your nominated account has been referred"
          metaDescription="Your nominated account has been referred"
        />
        <Content
          tag="h1"
          cmsTag="Accounts:Nominated-account:Referred-with-docs:h1"
          copytext={`Your request for ${
            mode === 'add' ? 'adding' : 'updating'
          } your nominated account has been referred`}
        />
        <Markdown
          cmsTag="Accounts:Nominated-account:Referred-with-docs:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nWe have received your documents and we will require a few days to further verify your request. We will notify you once a decision has been made. \n\nIf you have any questions, please call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />

        <div className="form-button-group">
          {this.getBackButton(match.params.page)}
        </div>
      </React.Fragment>
    );
  }
}

AddNominatedAccountReferredWithDocs.propTypes = {
  page: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  mode: PropTypes.string,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddNominatedAccountReferredWithDocs);
