import React, { Component } from 'react';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import progressLogic from '../../progressLogic';
import { Link } from 'react-router-dom';

class GoalNotice extends Component {
  render() {
    const { goal = {}, account, isEligibleToAddNewGoal = true } = this.props;
    if (!goal) {
      return null;
    }

    const { ExtendedProperties: { AvailableBalance } = {} } = account;
    let balance = AvailableBalance;

    if (
      account.ExtendedProperties.AvailableBalance &&
      account.ExtendedProperties.PendingBalance
    ) {
      balance =
        account.ExtendedProperties.AvailableBalance +
        account.ExtendedProperties.PendingBalance;
    }

    const goalStatus = progressLogic.getGoalStatus(
      balance, //amount you have saved
      goal.Amount, //amount left to save
      goal.Monthly, //number of months left?
      goal.TargetDate, //need to save by this date
    );
    if (goalStatus === 'started') {
      return (
        <Content
          tag="div"
          tagClassName="alert alert-info"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal-notice:alert-1"
          copytext="Your savings goal is all set up. Remember to make payments to help stay on track."
        />
      );
    }
    if (goalStatus === 'complete') {
      return (
        <div className="alert alert-info">
          <Content
            cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal:Goal-notice:alert-2-part-1"
            copytext="Well done you reached your goal! "
          />
          {isEligibleToAddNewGoal && (
            <React.Fragment>
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal:Goal-notice:alert-2-part-2"
                copytext="Why not "
              />
              <Link
                id={`account-type-savings-goals-${account.AccountNumber}`}
                to={'/money-tools/savings-goals/create-goals/goal-list'}
              >
                <Content
                  cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal:Goal-notice:start-a-new-goal"
                  copytext="start a new goal"
                />
              </Link>
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal:Goal-notice:alert-2-part-3"
                copytext="?"
              />
            </React.Fragment>
          )}
        </div>
      );
    }
    if (goalStatus === 'on-target') {
      return (
        <Content
          tag="div"
          tagClassName="alert alert-success"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal:Goal-notice:alert-3"
          copytext="You're on track to reach your goal."
        />
      );
    }
    if (goalStatus === 'behind') {
      return (
        <Content
          tag="div"
          tagClassName="alert alert-danger"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal:Goal-notice:alert-4"
          copytext="You are behind on your savings goal. Please Consider increasing your monthly deposit, reducing your target or extending the goal date."
        />
      );
    }
    return null;
  }
}

GoalNotice.propTypes = {
  goal: PropTypes.object,
  account: PropTypes.object,
  isEligibleToAddNewGoal: PropTypes.bool,
};

export default GoalNotice;
