import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import { Text, Content, FormattedDate, CONSTANTS } from '@myie/interact-dom';

class EditAboutYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      submitForm: {},
      userDetails: null,
      yourTitle: null,
      firstName: null,
      lastName: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { aboutYouForm, userDetails } = nextProps;

    if (aboutYouForm && aboutYouForm !== prevState.aboutYouForm) {
      return {
        ...prevState,
        aboutYouForm,
      };
    }

    if (aboutYouForm && userDetails !== prevState.userDetails) {
      return {
        ...prevState,
        yourTitle: userDetails.Title,
        firstName: userDetails.FirstName,
        middleNames: userDetails.MiddleName,
        lastName: userDetails.Surname,
        aboutYouForm,
        userDetails,
      };
    } else {
      return null;
    }
  }

  extractTitle = titleCode => {
    let title = titleCode;

    // Find the title array entry with the given code
    let titleObject = CONSTANTS.TITLES.find(
      t => Object.keys(t)[0] === titleCode,
    );

    // Extract title text
    if (titleObject) {
      title = titleObject[Object.keys(titleObject)[0]];
    }
    return title;
  };

  render() {
    const {
      userDetails,
      aboutYouData,
      aboutYouForm,
      onChange,
      onBlur,
    } = this.props;

    let yourTitle = '';
    let firstName = '';
    let dateOfBirth = '';

    let lastName = '';
    let nationalInsuranceNumber = '';

    if (userDetails) {
      yourTitle = userDetails.Title;
      firstName = userDetails.FirstName;
      lastName = userDetails.Surname;
      dateOfBirth = userDetails.ExtendedProperties.DateOfBirth;
      nationalInsuranceNumber = userDetails.ExtendedProperties.NINumber;
    }

    yourTitle = this.extractTitle(yourTitle);

    return (
      <fieldset className="mt-5">
        <Content
          tag="legend"
          tagClassName="h2"
          cmsTag="User-management:personal-details:Edit:About-you:legend-1"
          copytext="About you"
        />
        <Row tag="dl" title="About you details">
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Title" copytext="Title" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {yourTitle}
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:First-name" copytext="First name" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {firstName}
          </Col>

          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Last-name" copytext="Last name" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {lastName}
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Date-of-birth" copytext="Date of birth" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            <FormattedDate
              date={dateOfBirth}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content
              cmsTag="GLOBAL:National-Insurance-Number"
              copytext="National Insurance Number"
            />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {nationalInsuranceNumber}
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={6}>
            <Text
              label="Email"
              cmsTag="GLOBAL:Enter-your-email-address"
              id="EmailAddress"
              field="EmailAddress"
              value={aboutYouData.EmailAddress ?? ''}
              maxLength={70}
              onChange={onChange}
              onBlur={onBlur}
              validation={aboutYouForm.EmailAddress}
            />
            <Text
              label="Confirm email"
              cmsTag="GLOBAL:Confirm-your-email-address"
              id="ConfirmEmailAddress"
              field="ConfirmEmailAddress"
              value={aboutYouData.ConfirmEmailAddress ?? ''}
              maxLength={70}
              onChange={onChange}
              onBlur={onBlur}
              validation={aboutYouForm.ConfirmEmailAddress}
            />
            <Text
              label="Home phone number"
              cmsTag="GLOBAL:Home-number"
              id="HomeNumber"
              field="HomeNumber"
              value={aboutYouData.HomeNumber ?? ''}
              maxLength={15}
              onChange={onChange}
              onBlur={onBlur}
              validation={aboutYouForm.HomeNumber}
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

EditAboutYou.propTypes = {
  onEvent: PropTypes.any,
  saveData: PropTypes.any,
  setForm: PropTypes.any,
  userDetails: PropTypes.any,
  aboutYouForm: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  aboutYouData: PropTypes.object,
};

export default EditAboutYou;
