import React from 'react';
import { SideLink } from '@myie/interact-dom';

class PersonalDetailsServiceItems extends React.Component {
  render() {
    return (
      <SideLink
        linkTitle="Personal details"
        linkURL="/personal-details"
        linkID="personal-details-link"
        iconImage="MyServicesPersonalDetails"
        showMessageCount={true}
        linkVersion="primary"
      />
    );
  }
}

export default PersonalDetailsServiceItems;
