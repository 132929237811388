import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReviewAndSubmit from '../shared/ReviewAndSubmit';

class Review extends Component {
  submit = () => {
    const { submit } = this.props;
    submit();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      formData = null,
      getBackButton,
      page,
      account,
      goalName,
    } = this.props;
    if (!formData || !account) {
      return null;
    }

    return (
      <React.Fragment>
        <ReviewAndSubmit
          formData={formData}
          goalName={goalName}
          account={account}
          page={page}
          getBackButton={getBackButton}
          submit={this.submit}
          updateUrl={this.props.updateUrl}
          setStateDynamic={this.props.setStateDynamic}
        />
      </React.Fragment>
    );
  }
}

Review.propTypes = {
  getBackButton: PropTypes.func,
  page: PropTypes.string,
  goal: PropTypes.object,
  submit: PropTypes.func,
  updateUrl: PropTypes.func,
  formData: PropTypes.any,
  goalName: PropTypes.string,
  options: PropTypes.any,
  setStateDynamic: PropTypes.func,
  match: PropTypes.any,
  saveData: PropTypes.any,
  account: PropTypes.any,
};

export default Review;
