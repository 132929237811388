import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Col, Row } from 'reactstrap';
import { Date, Content } from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import { CONSTANTS } from '@myie/interact-dom';

import moment from 'moment';

class TransactionDateRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      localActiveTab: props.activeTab,
      form: {
        startDate: {
          rules: {
            title: 'start date',
            stop: true,
            required: {
              message: 'Please enter a date.',
            },
            pastFormat: {
              message: 'Start date cannot be a future date.',
            },
            compareStartDateLocal: {
              message:
                'Start date must be earlier than or equal to the end date.',
              field: 'endDate',
              comparison: () => {
                return this.state.form.endDate.value;
              },
            },
          },
          value: '',
        },
        endDate: {
          rules: {
            title: 'end date',
            stop: true,
            required: {
              message: 'Please enter a date.',
            },
            pastFormat: {
              message: 'End date cannot be a future date.',
            },
            compareEndDateLocal: {
              message:
                'End date must be later than or equal to the start date.',
              field: 'startDate',
              comparison: () => {
                return this.state.form.startDate.value;
              },
            },
          },
          value: '',
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { localActiveTab, form } = state;

    if (localActiveTab !== props.activeTab) {
      form.startDate.value = '';
      form.endDate.value = '';
      return {
        localActiveTab: props.activeTab,
        form: form,
      };
    }

    return null;
  }

  subtractDate = date => {
    return moment(date).format('DD/MM/YYYY');
  };

  onChange = e => {
    let { name, value } = e.target;
    //value = moment(value).format("DD/MM/YYYY");
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    if (name === 'startDate') {
      form = Validate.input('endDate', form.endDate.value, form, true);
    } else {
      form = Validate.input('startDate', form.startDate.value, form, true);
    }

    this.setState({
      ...this.state,
      form,
    });
  };

  onBlur = e => {
    let { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);

    this.setState({
      ...this.state,
      form,
    });
    const localThis = this;
    //This code will validate the other field.
    //The above state change dosn't do the fix.
    setTimeout(function() {
      let { form } = localThis.state;

      if (name === 'startDate') {
        form = Validate.input('endDate', form.endDate.value, form, true);
      } else {
        form = Validate.input('startDate', form.startDate.value, form, true);
      }

      localThis.setState({
        ...localThis.state,
        form,
      });
    }, 1000);
  };

  submit = e => {
    e.preventDefault();
    const { propTransactions, setTransactions } = this.props;
    let { form } = this.state;
    const from = moment(
      form.startDate.value,
      CONSTANTS.DATE_FORMAT_YYYYMMDD,
    ).unix();
    const to = moment(
      form.endDate.value,
      CONSTANTS.DATE_FORMAT_YYYYMMDD,
    ).unix();

    let newTransaction = [];
    propTransactions.forEach(entry => {
      let date = moment(
        entry.ExtendedProperties.TransactionDate,
        CONSTANTS.DATE_FORMAT_YYYYMMDD,
      ).unix();

      if (date >= from && date <= to) {
        newTransaction.push(entry);
      }
    });
    const newRange = {
      From: moment(form.startDate.value, CONSTANTS.DATE_FORMAT_YYYYMMDD),
      To: moment(form.endDate.value, CONSTANTS.DATE_FORMAT_YYYYMMDD),
    };
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (form.approved) {
      setTransactions(newTransaction, newRange);
    } else if (!from && !to) {
      setTransactions(propTransactions, newRange);
    }
  };

  render() {
    const { form } = this.state;
    return (
      <Col xs="12" sm="12" md="9">
        <Form autoComplete="off" id="date-range" onSubmit={this.submit}>
          <Content
            tagClassName="sr-only"
            tagID="range-bar"
            tag="span"
            cmsTag="Accounts:Components:Detail-page:Tabs:Transaction:Transaction-date-range:Tansactions-range-bar"
            copytext="Transactions range bar"
          />
          <Row className="align-items-end">
            <Col xs={12} sm={12} md={4}>
              <Date
                label="Show transactions from"
                placeholder="dd-mm-yyyy"
                cmsTag="GLOBAL:Start-of-date-range-from"
                id="startDate"
                field="startDate"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.startDate}
                groupClassName="half-field"
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Date
                label="Show transactions to"
                placeholder="dd-mm-yyyy"
                cmsTag="GLOBAL:End-of-date-range-to"
                id="endDate"
                field="endDate"
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.endDate}
                groupClassName="half-field"
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Button
                id="transaction-details-submit-current"
                type="submit"
                color="light"
                size="sm"
              >
                <Content
                  cmsTag="GLOBAL:Filter-transactions"
                  copytext="Filter transactions"
                />
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    );
  }
}

TransactionDateRange.propTypes = {
  className: PropTypes.string,
  account: PropTypes.any,
  transactions: PropTypes.any,
  setTransactions: PropTypes.any,
  propTransactions: PropTypes.any,
  fetchTransactions: PropTypes.any,
  range: PropTypes.any,
  activeTab: PropTypes.string,
  toggle: PropTypes.any,
  setUpdateButton: PropTypes.any,
};

export default TransactionDateRange;
