import { Connection } from '@myie/interact';

import {
  RESET_SET_SHORT_APPLY,
  SET_SHORT_APPLY_REQUEST,
  SET_SHORT_APPLY_SUCCESS,
  SET_SHORT_APPLY_FALIURE,
  RESET_SHORT_APPLY_OPTIONS,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FALIURE,
  RESET_SAVE_SHORT_APPLY,
  SAVE_SHORT_APPLY_REQUEST,
  SAVE_SHORT_APPLY_SUCCESS,
  SAVE_SHORT_APPLY_FALIURE,
} from './shortApplyTypes';

export const resetShortapply = () => ({
  type: RESET_SET_SHORT_APPLY,
});

export const setShortapplyRequest = () => ({
  type: SET_SHORT_APPLY_REQUEST,
  isFetching: true,
  error: null,
});

export const setShortapplySuccess = json => ({
  type: SET_SHORT_APPLY_SUCCESS,
  setShortApplyResponse: json,
  isFetching: false,
  error: null,
});

export const setShortapplyFaliure = error => ({
  type: SET_SHORT_APPLY_FALIURE,
  isFetching: false,
  error: error.message,
});

export const setShortapply = request => dispatch => {
  dispatch(setShortapplyRequest());
  return fetch(
    Connection.baseUrl('accounts') + `v3.0/savings/savingshortapplymarketpref`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(setShortapplySuccess(json)))
    .catch(error => dispatch(setShortapplyFaliure(error)));
};

export const resetGetShowMaturity = () => ({
  type: RESET_SHORT_APPLY_OPTIONS,
});

export const getAllProductsRequest = () => ({
  type: GET_ALL_PRODUCTS_REQUEST,
  isFetching: true,
  error: null,
});

export const getAllProductsSuccess = json => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  getAllProductsResponse: json,
  isFetching: false,
  error: null,
});

export const getAllProductsFaliure = error => ({
  type: GET_ALL_PRODUCTS_FALIURE,
  getAllProductsResponse: null,
  isFetching: false,
  error: error.message,
});

export const getAllProducts = request => dispatch => {
  dispatch(getAllProductsRequest());
  //return fetch(`https://dar-app.test.ie.com/customdar/v1.0/getproducts`, {
  return fetch(Connection.baseUrl('custombrand') + 'v1.0/getallproducts', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getAllProductsSuccess(json)))
    .catch(error => dispatch(getAllProductsFaliure(error)));
};

export const resetSaveShortApply = () => ({
  type: RESET_SAVE_SHORT_APPLY,
});

export const saveShortApplyRequest = () => ({
  type: SAVE_SHORT_APPLY_REQUEST,
  isFetching: true,
  error: null,
});

export const saveShortApplySuccess = json => ({
  type: SAVE_SHORT_APPLY_SUCCESS,
  saveShortApplyResponse: json,
  isFetching: false,
  error: null,
});

export const saveShortApplyFaliure = error => ({
  type: SAVE_SHORT_APPLY_FALIURE,
  isFetching: false,
  error: error.message,
});

export const saveShortApply = request => dispatch => {
  dispatch(saveShortApplyRequest());
  return fetch(Connection.baseUrl('apply') + 'v1.0/savings', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(saveShortApplySuccess(json)))
    .catch(error => dispatch(saveShortApplyFaliure(error)));
};
