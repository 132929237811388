import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from '@myie/interact-dom';

class SummaryMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = { removeClass: false };
  }

  hideMenu = () => {
    this.setState({ removeClass: !this.state.removeClass });
  };

  render() {
    let menuClass = ['nav-link'];
    if (this.state.addClass) {
      menuClass.push('green');
    }
    return (
      <Link
        id="accounts-menu-item"
        className={menuClass.join(' ')}
        to="/accounts/"
        onClick={this.hideMenu}
      >
        <Content cmsTag="MENU:Accounts" copytext="Accounts" />
      </Link>
    );
  }
}

export default SummaryMenuItems;
