import * as registrationActions from './actions/registrationActions';
import * as setupCredentialsActions from './actions/setupCredentialsActions';
import * as credentialsActions from './actions/activation';

const mapDispatchToProps = {
  ...registrationActions,
  ...setupCredentialsActions,
  ...credentialsActions,
};

export default mapDispatchToProps;
