import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { Content } from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { Session } from '@myie/interact';

class SummaryMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessageCount: 0,
    };
  }
  timeoutID = null;

  static getDerivedStateFromProps(nextProps, prevState) {
    const { newMessageCount } = nextProps;

    if (
      newMessageCount &&
      newMessageCount.Count !== prevState.newMessageCount
    ) {
      return {
        ...prevState,
        newMessageCount: newMessageCount.Count,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const { getNewMessageCount } = this.props;
    let request = {
      Type: 'New',
      ExtendedProperties: {},
    };
    getNewMessageCount(request);
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timeoutID);
  }

  startTimer = () => {
    this.timeoutID = setInterval(this.updateCount, 120000);
  };

  clearTimer = () => {
    clearInterval(this.timeoutID);
  };

  updateCount = () => {
    const { getNewMessageCount } = this.props;
    if (!Session.isAuthenticated()) {
      this.clearTimer();
    } else {
      let request = {
        Type: 'New',
        ExtendedProperties: {},
      };
      getNewMessageCount(request);
    }
  };

  hideMenu = () => {
    this.setState({ removeClass: !this.state.removeClass });
  };

  render() {
    const { newMessageCount } = this.state;
    return (
      <Link
        id="messaging-twoway-item"
        className={`nav-link ${newMessageCount ? 'has-messages' : ''}`}
        to="/messaging-twoway/"
        onClick={this.hideMenu}
      >
        <Content cmsTag="MENU:My-messages" copytext="Messages" />
        {newMessageCount ? (
          <React.Fragment>
            <Content
              tagClassName="sr-only"
              tag="span"
              cmsTag="GLOBAL:You-have-"
              copytext="You have "
            />
            <Badge color="primary" className="new-message-notification-badge">
              {newMessageCount}
            </Badge>
            <Content
              tagClassName="sr-only"
              tag="span"
              cmsTag="GLOBAL:-unread-messages"
              copytext=" unread messages"
            />
          </React.Fragment>
        ) : (
          ''
        )}
      </Link>
    );
  }
}

SummaryMenuItems.propTypes = {
  getNewMessageCount: PropTypes.any,
  newMessageCount: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryMenuItems);
