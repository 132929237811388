import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { Button } from 'reactstrap';
import { Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Failed extends React.Component {
  retry = e => {
    const { retry } = this.props;
    e.preventDefault();
    retry();
  };

  render() {
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Two Factor Authentication unsuccessful"
          metaDescription="Two Factor Authentication unsuccessful"
        />
        <Content
          tag="h1"
          cmsTag="Two-factor:Failed:h1"
          copytext="Two Factor Authentication unsuccessful"
        />
        <Content
          tag="p"
          cmsTag="Two-factor:Failed:p1"
          copytext="Unfortunately your request cannot be completed."
        />
        <div className="form-button-group">
          <Button id="failed-retry" color="secondary" onClick={this.retry}>
            <Content cmsTag="GLOBAL:Try-again" copytext="Try again" />
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

Failed.propTypes = {
  finishTwoFactor: PropTypes.any,
  resetChallenge: PropTypes.any,
  resetDestinations: PropTypes.any,
  retry: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Failed);
