import { Connection } from '@myie/interact';

import {
  RESET_GET_DEPOSIT_PRODUCTS,
  GET_DEPOSIT_PRODUCTS_REQUEST,
  GET_DEPOSIT_PRODUCTS_SUCCESS,
  GET_DEPOSIT_PRODUCTS_FALIURE,
  RESET_SAVE_APPLY_FOR_LATER,
  GET_SAVE_APPLY_FOR_LATER_REQUEST,
  GET_SAVE_APPLY_FOR_LATER_SUCCESS,
  GET_SAVE_APPLY_FOR_LATER_FALIURE,
  RESET_RESUME_APPLICATION,
  RESUME_APPLICATION_REQUEST,
  RESUME_APPLICATION_SUCCESS,
  RESUME_APPLICATION_FALIURE,
  RESUME_APPLICATION_DATA,
  RESET_RESUME_APPLICATION_DATA,
  RESET_GET_DEPOSIT_PRODUCT,
  GET_DEPOSIT_PRODUCT_REQUEST,
  GET_DEPOSIT_PRODUCT_SUCCESS,
  GET_DEPOSIT_PRODUCT_FALIURE,
} from './depositProductsTypes';

export const resetShortapply = () => ({
  type: RESET_GET_DEPOSIT_PRODUCTS,
});

export const getDepositProductsRequest = () => ({
  type: GET_DEPOSIT_PRODUCTS_REQUEST,
  isFetching: true,
  error: null,
});

export const getDepositProductsSuccess = json => ({
  type: GET_DEPOSIT_PRODUCTS_SUCCESS,
  getDepositProductsResponse: json,
  isFetching: false,
  error: null,
});

export const getDepositProductsFaliure = error => ({
  type: GET_DEPOSIT_PRODUCTS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getDepositProducts = request => dispatch => {
  dispatch(getDepositProductsRequest());
  return fetch(
    Connection.baseUrl('accounts') + `v3.0/savings/getDepositProducts`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getDepositProductsSuccess(json)))
    .catch(error => dispatch(getDepositProductsFaliure(error)));
};

export const resetSaveShortApplyForLater = () => ({
  type: RESET_SAVE_APPLY_FOR_LATER,
});

export const getSaveShortApplyForLaterRequest = () => ({
  type: GET_SAVE_APPLY_FOR_LATER_REQUEST,
  isFetching: true,
  error: null,
});

export const getSaveShortApplyForLaterSuccess = json => ({
  type: GET_SAVE_APPLY_FOR_LATER_SUCCESS,
  saveShortApplyForLaterResponse: json,
  isFetching: false,
  error: null,
});

export const getSaveShortApplyForLaterFaliure = error => ({
  type: GET_SAVE_APPLY_FOR_LATER_FALIURE,
  isFetching: false,
  error: error.message,
});

export const saveShortApplyForLater = request => dispatch => {
  dispatch(getSaveShortApplyForLaterRequest());
  return fetch(Connection.baseUrl('apply') + 'v1.0/save', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getSaveShortApplyForLaterSuccess(json)))
    .catch(error => dispatch(getSaveShortApplyForLaterFaliure(error)));
};

export const resetResumeApplication = () => ({
  type: RESET_RESUME_APPLICATION,
});

export const resumeApplicationRequest = () => ({
  type: RESUME_APPLICATION_REQUEST,
  isFetching: true,
  error: null,
});

export const resumeApplicationSuccess = json => ({
  type: RESUME_APPLICATION_SUCCESS,
  resumeResponse: json,
  isFetching: false,
  error: null,
});

export const resumeApplicationFaliure = error => ({
  type: RESUME_APPLICATION_FALIURE,
  isFetching: false,
  error: error.message,
});

export const resumeApplication = request => dispatch => {
  dispatch(resumeApplicationRequest());
  return fetch(Connection.baseUrl('apply') + 'v1.0/return', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(resumeApplicationSuccess(json)))
    .catch(error => dispatch(resumeApplicationFaliure(error)));
};

export const resumeApplicationData = json => ({
  type: RESUME_APPLICATION_DATA,
  resumeData: json,
  isFetching: false,
  error: null,
});

export const resetResumeApplicationData = () => ({
  type: RESET_RESUME_APPLICATION_DATA,
});

export const resetGetDepositProduct = () => ({
  type: RESET_GET_DEPOSIT_PRODUCT,
});

export const getDepositProductRequest = () => ({
  type: GET_DEPOSIT_PRODUCT_REQUEST,
  isFetching: true,
  error: null,
});

export const getDepositProductSuccess = json => ({
  type: GET_DEPOSIT_PRODUCT_SUCCESS,
  getDepositProductResponse: json,
  isFetching: false,
  error: null,
});

export const getDepositProductFaliure = error => ({
  type: GET_DEPOSIT_PRODUCT_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getDepositProduct = request => dispatch => {
  dispatch(getDepositProductsRequest());
  return fetch(Connection.baseUrl('custombrand') + 'v1.0/getproduct', {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getDepositProductSuccess(json)))
    .catch(error => dispatch(getDepositProductFaliure(error)));
};
