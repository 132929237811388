import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, FormattedCurrency } from '@myie/interact-dom';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Currency from 'react-currency-formatter';

class WithdrawalSuccess extends Component {
  componentDidMount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }
  render() {
    const { form = {}, account = {} } = this.props;
    let amount =
      form && form.amount && form.amount.value ? form.amount.value : '';
    const closeOption = false;
    const {
      CurrencyCode,
      Balance,
      ExtendedProperties: {
        NominatedAccountNumber,
        NominatedSortCode,
        NominatedAccountName,
      } = {},
    } = account;
    return (
      <React.Fragment>
        <Content
          tag="p"
          cmsTag="Savings-servicing:Make-withdrawal:Withdrawal-success:p1"
          copytext="Your withdrawal to your nominated account will occur in the next working day."
        />
        <Row tag="dl" title="Account details review">
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:From" copytext="From" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {account.AccountName}{' '}
            {Currency({
              quantity: Balance,
              currency: CurrencyCode,
            })}
            &nbsp;
            <span className="d-block">
              {account.AccountNumber}, {account.SortCode}
            </span>
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:To" copytext="To" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {NominatedAccountName}&nbsp;
            <span className="d-block">
              {NominatedAccountNumber}, {NominatedSortCode}
            </span>
          </Col>
          {!closeOption ? (
            <React.Fragment>
              <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
              </Col>
              <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                <FormattedCurrency
                  quantity={amount.replace(/,/g, '')}
                  currency={CurrencyCode}
                />
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}
        </Row>
        <div className="form-button-group">
          <Link
            id="back-to-notice"
            className="btn btn-secondary"
            role="button"
            to={`/accounts/list`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-accounts"
              copytext="Back to accounts"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

WithdrawalSuccess.propTypes = {
  match: PropTypes.any,
  resetMakeWithdrawal: PropTypes.func,
  account: PropTypes.any,
  form: PropTypes.any,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithdrawalSuccess);
