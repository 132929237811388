const mapStateToProps = state => {
  const { messagingSystemDefinition = {} } = state;
  const {
    systemMessages,
    alertVisibleArray: alertVisibleArray = [],
    messageArray: messageArray = [],
    isFetching,
    error: error = null,
  } = messagingSystemDefinition;
  return {
    systemMessages,
    alertVisibleArray,
    messageArray,
    isFetching,
    error,
  };
};

export default mapStateToProps;
