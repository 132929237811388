import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GoalAlert from './GoalAlert';
import { mapDispatchToProps } from '@myie/interact-accounts';
import progressLogic from '../../progressLogic';
import { CONSTANTS } from '@myie/interact-dom';
import { mapDispatchToProps as servicingMapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { Utility } from '@myie/interact';

class Messages extends React.Component {
  constructor(props) {
    super(props);
    const { bannerMessageArray } = this.props;

    this.state = {
      alertVisibleArray:
        bannerMessageArray && bannerMessageArray.length
          ? bannerMessageArray
          : [],
    };
  }

  closeAlert = goal => {
    const { account } = this.props;
    let { alertVisibleArray } = this.state;
    let {
      saveGoalsBannerMessageArray,
      bannerMessageArray,
      updateGoals,
    } = this.props;
    Utility.hexEncode(account.AccountKey.Key);
    let availability;
    if (bannerMessageArray) {
      availability = bannerMessageArray.find(
        element => element === Utility.hexEncode(account.AccountKey.Key),
      );
    }

    if (!availability) {
      alertVisibleArray.push(Utility.hexEncode(account.AccountKey.Key));
    }
    saveGoalsBannerMessageArray(alertVisibleArray);

    let request = {
      AccountKey: account.AccountKey,
      AccountCurrency: 'GBP',
      Category: goal.Category,
      Name: goal.Name,
      TargetDate: goal.TargetDate,
      Amount: goal.Amount,
      Monthly: goal.Monthly,
      Existing: true,
      ExtendedProperties: {
        IpAddress: 'IpAddress',
        Complete: true,
        CustomerNumber: 'CustomerNumber',
        AccountNumber: 'AccountNumber',
      },
    };

    updateGoals(request);
    this.setState({ ...this.state, alertVisibleArray: alertVisibleArray });
  };

  render() {
    const { account, loopIndex, bannerMessageArray, goal } = this.props;
    if (!goal) {
      return null;
    }

    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    let balance = AvailableBalance;
    if (PendingBalance) {
      balance = AvailableBalance + PendingBalance;
    }

    const goalStatus = progressLogic.getGoalStatus(
      balance,
      goal.Amount,
      goal.Monthly,
      goal.TargetDate,
    );
    let availability;
    if (bannerMessageArray) {
      availability = bannerMessageArray.find(
        element => element === Utility.hexEncode(account.AccountKey.Key),
      );
    }

    if (
      !account ||
      availability ||
      goalStatus !== 'complete' ||
      goal.Status === 1
    ) {
      return null;
    }
    if (
      account &&
      account.ExtendedProperties.AccountType === CONSTANTS.ACCOUNT_TYPE_SAVINGS
    ) {
      return (
        <GoalAlert
          closeAlert={this.closeAlert}
          loopIndex={loopIndex}
          account={account}
          goal={goal}
        />
      );
    } else {
      return null;
    }
  }
}

Messages.propTypes = {
  maturityAlertVisibleArray: PropTypes.any,
  fetchSystemMessagesIfNeeded: PropTypes.any,
  maturityMessageArray: PropTypes.any,
  saveMaturityMessageArray: PropTypes.any,
  saveGoalsBannerMessageArray: PropTypes.func,
  systemMessages: PropTypes.any,
  bannerMessageArray: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  accounts: PropTypes.any,
  alertVisibleArray: PropTypes.any,
  account: PropTypes.object,
  goal: PropTypes.object,
  updateGoals: PropTypes.func,
  loopIndex: PropTypes.any,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition, accountsDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...accountsDefinition,
  };
};
export default connect(
  mapStateToProps,
  { ...servicingMapDispatchToProps, ...mapDispatchToProps },
)(Messages);
