import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as brandAccountsMapDispatch } from '@myie/interact-brand-accounts';
import { Utility } from '@myie/interact';

class Messages extends React.Component {
  constructor(props) {
    super(props);
    const { maturityAlertVisibleArray } = this.props;
    this.state = {
      alertVisibleArray:
        maturityAlertVisibleArray && maturityAlertVisibleArray.length
          ? maturityAlertVisibleArray
          : [],
    };
  }

  closeAlert = () => {
    const { account } = this.props;
    let { alertVisibleArray } = this.state;
    let { saveMaturityMessageArray, maturityAlertVisibleArray } = this.props;
    Utility.hexEncode(account.AccountKey.Key);
    let availability;
    if (maturityAlertVisibleArray) {
      availability = maturityAlertVisibleArray.find(
        element => element === Utility.hexEncode(account.AccountKey.Key),
      );
    }

    if (!availability) {
      alertVisibleArray.push(Utility.hexEncode(account.AccountKey.Key));
    }
    saveMaturityMessageArray(alertVisibleArray);
    this.setState({ ...this.state, alertVisibleArray: alertVisibleArray });
  };

  render() {
    return null;
  }
}

Messages.propTypes = {
  maturityAlertVisibleArray: PropTypes.any,
  fetchSystemMessagesIfNeeded: PropTypes.any,
  maturityMessageArray: PropTypes.any,
  saveMaturityMessageArray: PropTypes.any,
  systemMessages: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  accounts: PropTypes.any,
  alertVisibleArray: PropTypes.any,
  account: PropTypes.object,
  loopIndex: PropTypes.any,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandAccountsDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandAccountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...brandAccountsMapDispatch, ...mapDispatchToProps },
)(Messages);
