import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Alert, CardBody } from 'reactstrap';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import Details from './Details';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'Details',
      message: null,
      data: {},
    };
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { replyMessageStatus } = nextProps;

    if (replyMessageStatus && replyMessageStatus.ResponseStatus) {
      return {
        ...prevState,
        stage: 'Confirmation',
      };
    } else {
      return null;
    }
  }

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // select current stage
  currentStage = (accounts = []) => {
    const { stage, data } = this.state;

    switch (stage) {
      case 'Details':
        return accounts && accounts.Accounts && accounts.Accounts.length > 0 ? (
          <Details
            accounts={accounts}
            saveData={this.saveData}
            selectedData={data}
          />
        ) : (
          false
        );
      case 'Confirmation': {
        return <Redirect to="/message-widget/message/success/" />;
      }
      default:
    }
  };

  render() {
    const { error, accounts } = this.props;
    if (!accounts) {
      return null;
    }
    let alert;

    const { message } = this.state;
    if (message || error) {
      alert = (
        <Alert id="messaging-twoway-alert" color="danger">
          {message || error}
        </Alert>
      );
    }

    return (
      <Card id="widget-send-message" color="secondary">
        <CardBody>
          {alert ? alert : ''}
          {this.currentStage(accounts)}
        </CardBody>
      </Card>
    );
  }
}

Message.propTypes = {
  accounts: PropTypes.any,
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  history: PropTypes.any,
  initializeMoveMoney: PropTypes.any,
  payment: PropTypes.any,
  retrievedUserName: PropTypes.object,
  transfer: PropTypes.any,
  replyMessageStatus: PropTypes.any,
};
const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
  )(Message),
);
