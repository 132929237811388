import React, { Component } from 'react';
import { Currency as CurrencyInput, CONSTANTS } from '@myie/interact-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as mapSavingDispatchToProps } from '@myie/interact-account-type-savings';
import { ruleSet as externalTranferRules } from './rules/externalTranfer';
import { ruleSet as internalTranferRules } from './rules/internalTransfer';
import { ruleSet as makeDepositRules } from './rules/makeDeposit';
import { ruleSet as noticeToWithdrawRules } from './rules/noticeToWithdraw';
import { ruleSet as withdrawToNominatedRules } from './rules/withdrawToNominated';
import moment from 'moment';

class Amount extends Component {
  constructor(props) {
    super(props);
    const { account, getAllProducts, allProducts, data } = props;
    this.state = {};
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
    if (account && Object.keys(account).length) {
      if (account.transactions) {
        this.updateAmountRules(account, data);
      } else {
        this.getTransactions(account);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { account, data, toAccount } = this.props;
    if (
      account &&
      Object.keys(account).length &&
      prevProps.account !== account &&
      !account.transactions
    ) {
      this.getTransactions(account);
    }
    if (
      toAccount &&
      Object.keys(toAccount).length &&
      prevProps.toAccount !== account &&
      !toAccount.transactions
    ) {
      this.getTransactions(toAccount);
    }
    if (prevProps.account !== account && account.transactions) {
      this.updateAmountRules(account, data);
    }
    if (
      toAccount &&
      Object.keys(toAccount).length &&
      prevProps.toAccount !== toAccount &&
      toAccount.transactions &&
      account &&
      account.transactions
    ) {
      this.updateAmountRules(account, data, toAccount);
    }
  }

  getRuleSet = (type, account, data, toAccount) => {
    const { allProducts } = this.props;
    switch (type) {
      case 'internal_transfer':
        return internalTranferRules(account, allProducts, data, toAccount);
      case 'notice-to-withdraw':
        return noticeToWithdrawRules(account, allProducts, data);
      case 'withdrawal_to_nominated_account':
        return withdrawToNominatedRules(account, allProducts, data);
      case 'external_transefer':
        return externalTranferRules(account, allProducts, data);
      case 'make_deposit':
        return makeDepositRules(account, allProducts, data);
    }
  };

  updateAmountRules = (account, data, toAccount = null) => {
    const { setAmountRules, ruleType = 'external_transefer' } = this.props;
    if (!account) {
      return null;
    }
    const ruleObj = this.getRuleSet(ruleType, account, data, toAccount);
    setAmountRules(ruleObj);
  };

  getTransactions = account => {
    const { fetchSavingsAccountTransactions } = this.props;
    const to = moment().startOf('day');
    const from = moment()
      .subtract(1, 'years')
      .add(1, 'day')
      .startOf('day');
    const range = {
      To: to.toISOString(),
      From: from.toISOString(),
      Latest: true,
    };
    fetchSavingsAccountTransactions({
      AccountKey: account.AccountKey,
      Range: range,
      ExtendedProperties: {
        ListSubAccountNumbers:
          account.ExtendedProperties.AccountType ===
          CONSTANTS.ACCOUNT_TYPE_MORTGAGE
            ? undefined
            : [account.ExtendedProperties.SubAccountNumber],
        BrandProductType: account.ExtendedProperties.AccountType,
        PaginationDetails: 'false',
      },
    });
  };

  render() {
    const {
      form,
      onChange,
      onBlur,
      disabled,
      code,
      field,
      id,
      inputGroupClassName,
      dp = 2,
      cmsTag,
      label,
      maxLength,
    } = this.props;

    if (form?.amount) {
      return (
        <CurrencyInput
          label={label}
          cmsTag={cmsTag}
          showErrors={true}
          validation={form.amount}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          code={code}
          field={field}
          id={id}
          inputGroupClassName={inputGroupClassName}
          dp={dp}
          maxLength={maxLength}
        />
      );
    }
    return null;
  }
}

Amount.propTypes = {
  account: PropTypes.object,
  toAccount: PropTypes.object,
  data: PropTypes.any,
  form: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setAmountRules: PropTypes.func,
  disabled: PropTypes.bool,
  code: PropTypes.string,
  field: PropTypes.string,
  id: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  dp: PropTypes.number,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  fetchSavingsAccountTransactions: PropTypes.func,
  cmsTag: PropTypes.string,
  label: PropTypes.string,
  ruleType: PropTypes.string,
  maxLength: PropTypes.string,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapSavingsServicingDispatchToProps,
    ...mapSavingDispatchToProps,
  },
)(Amount);
