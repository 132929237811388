import { Session, Settings, Connection } from '@myie/interact';
let interval;

const TimeOut = function(timeOutObj) {
  this.timeoutID = null;

  this.checkTimer = () => {
    return interval !== null;
  };

  this.clearTimer = () => {
    window.clearInterval(interval);
    interval = null;
  };

  this.startTimer = check => {
    if (check && interval) {
      return;
    }
    window.clearInterval(interval);
    this.timeoutID = window.setInterval(this.goInactive, 1000);
    window.sessionStorage.setItem('timeoutID', this.timeoutID);
    interval = this.timeoutID;
    if (Session.isAuthenticated()) {
      fetch(Connection.baseUrl('utility') + `v1.0/ping`, {
        method: 'get',
        headers: Connection.headers(),
      });
    }
  };

  this.goInactive = () => {
    let timeoutSpan;
    if (Session.isAuthenticated()) {
      timeoutSpan = Settings.sessionExpiry || 20;
    } else {
      timeoutSpan = Settings.ticketExpiry || 20;
    }
    let currentTime = new Date().getTime();
    let lastRequestTime = window.sessionStorage.getItem('lastRequestTime');
    let elapsedMinutes = (currentTime - lastRequestTime) / (1000 * 60);

    if (
      elapsedMinutes > timeoutSpan - 1 &&
      elapsedMinutes < timeoutSpan - 0.01
    ) {
      timeOutObj.showTimeoutModal(
        this.startTimer,
        this.clearTimer,
        timeOutObj.type === `SECURITY_DETAILS`
          ? `TimerCountDown:${timeoutSpan * 60 -
              Math.round(elapsedMinutes * 60)}`
          : timeOutObj.message,
        timeOutObj.url,
        timeOutObj.onTimeout,
        timeOutObj.userLogout,
      );
    } else if (elapsedMinutes >= timeoutSpan) {
      if (timeOutObj && timeOutObj.beforeTimeout) {
        timeOutObj.beforeTimeout();
      }
      Session.abandon();
      clearInterval(this.timeoutID);
      interval = null;
      if (timeOutObj) {
        timeOutObj.onTimeout();
        timeOutObj.userLogout();
        if (timeOutObj.history) {
          timeOutObj.history.push(timeOutObj.url || '/signed-out');
        }
      }
    }
  };
};

export default TimeOut;
