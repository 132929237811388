import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Navbar, Button, TabPane, Row, Col } from 'reactstrap';
import ExportAccountCSV from './ExportAccountCSV.js';
import TransactionDateRange from './TransactionDateRange';
import Transaction from './Transaction';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Content, Markdown, Icon, CONSTANTS } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as brandAccountsMapDispatch } from '@myie/interact-brand-accounts';

class TabTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTransactions = this.toggleTransactions.bind(this);
    this.state = {
      range: props.range ? props.range : null,
      key: null,
      unchangedTransactions: [],
      localTransactions: [],
      menu: false,
      localActiveTab: props.activeTab,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  static getDerivedStateFromProps(props, state) {
    const { range } = props;
    const { reverse, localActiveTab } = state;
    if (localActiveTab !== props.activeTab) {
      return {
        range: range,
        localActiveTab: props.activeTab,
      };
    }
    if (
      props.propTransactions &&
      props.propTransactions.length !== state.unchangedTransactions.length &&
      !props.buttonStatus
    ) {
      let transactions = props.propTransactions;
      if (!state.reverse) {
        transactions = _.sortBy(
          transactions,
          'ExtendedProperties.OrderIndex',
        ).reverse();
      } else {
        transactions = _.sortBy(transactions, 'ExtendedProperties.OrderIndex');
      }
      return {
        unchangedTransactions: props.propTransactions,
        localTransactions: transactions.sort(
          reverse
            ? TabTransaction.reverseTransactionSort
            : TabTransaction.forwardTransactionSort,
        ),
        key: props.account.AccountKey.Key,
      };
    }
    if (
      props.account &&
      props.account.AccountKey &&
      props.propTransactions &&
      props.propTransactions.length &&
      state.key &&
      state.key !== props.account.AccountKey.Key
    ) {
      let transactions = props.propTransactions;
      if (!state.reverse) {
        transactions = _.sortBy(
          transactions,
          'ExtendedProperties.OrderIndex',
        ).reverse();
      } else {
        transactions = _.sortBy(transactions, 'ExtendedProperties.OrderIndex');
      }
      props.updateCountAndButton(10, false);

      return {
        localTransactions: transactions.sort(
          reverse
            ? TabTransaction.reverseTransactionSort
            : TabTransaction.forwardTransactionSort,
        ),
        key: props.account.AccountKey.Key,
      };
    }
    if (
      state.localActiveTab !== props.activeTab &&
      props.propTransactions &&
      !props.buttonStatus
    ) {
      let transactions = props.propTransactions;
      if (!state.reverse) {
        transactions = _.sortBy(
          transactions,
          'ExtendedProperties.OrderIndex',
        ).reverse();
      } else {
        transactions = _.sortBy(transactions, 'ExtendedProperties.OrderIndex');
      }
      return {
        localTransactions: transactions.sort(
          reverse
            ? TabTransaction.reverseTransactionSort
            : TabTransaction.forwardTransactionSort,
        ),
      };
    }

    if (range && state.range === null) {
      if (range.Latest) {
        let latestDates = { ...range };
        delete latestDates.Latest;
        return { range, latestDates };
      }
      return { range };
    }

    return null;
  }

  sortTransactions = () => {
    if (this.state.localTransactions) {
      let transactions = this.state.localTransactions;
      if (this.state.reverse) {
        transactions = _.sortBy(
          transactions,
          'ExtendedProperties.OrderIndex',
        ).reverse();
      } else {
        transactions = _.sortBy(transactions, 'ExtendedProperties.OrderIndex');
      }
      transactions.sort(
        !this.state.reverse
          ? TabTransaction.reverseTransactionSort
          : TabTransaction.forwardTransactionSort,
      );

      this.setState({
        reverse: !this.state.reverse,
        localTransactions: transactions,
      });
    }
  };

  static forwardTransactionSort = (a, b) => {
    const d1 = a.ExtendedProperties.TransactionDate.split('/');
    const d2 = b.ExtendedProperties.TransactionDate.split('/');
    const dateA = new Date(d1[2], d1[1], d1[0]);
    const dateB = new Date(d2[2], d2[1], d2[0]);
    return dateB - dateA;
  };

  static reverseTransactionSort = (a, b) => {
    const d1 = a.ExtendedProperties.TransactionDate.split('/');
    const d2 = b.ExtendedProperties.TransactionDate.split('/');
    const dateA = new Date(d1[2], d1[1], d1[0]);
    const dateB = new Date(d2[2], d2[1], d2[0]);
    return dateA - dateB;
  };

  toggleTransactions(range) {
    if (range) {
      this.setState({ collapse: !this.state.collapse, range: range });
    } else {
      this.setState({ collapse: !this.state.collapse });
    }
  }

  setTransactions = (transactions, newRange) => {
    const { updateCountAndButton } = this.props;
    updateCountAndButton(transactions.length, true);

    if (this.state.reverse) {
      transactions = _.sortBy(transactions, 'ExtendedProperties.OrderIndex');
    } else {
      transactions = _.sortBy(
        transactions,
        'ExtendedProperties.OrderIndex',
      ).reverse();
    }
    const sortedTransactions = transactions;
    this.setState({ localTransactions: sortedTransactions, range: newRange });
  };

  olderTransactions = () => {
    const { count, viewMore } = this.props;
    const { localTransactions } = this.state;
    let newCount = count;
    if (localTransactions) {
      newCount = localTransactions.length;
    }

    viewMore(true, newCount);

    // fetchTransactions(request);
    // this.toggleTransactions(range);
  };

  getpageContent = () => {
    const {
      name,
      fetchTransactions,
      account,
      tablist,
      buttonStatus,
      setUpdateButton,
      listCount,
      loading,
      downloadlinkUrl,
    } = this.props;
    const { range, localTransactions, reverse } = this.state;
    const show = this.state.menu ? 'show' : '';
    const open = this.state.menu ? 'open' : 'closed';
    const handlePrintSubmit = event => {
      event.preventDefault();
    };

    let transactions = [];
    let availableTransactions = localTransactions;

    //let totalLength = 0;
    if (availableTransactions) {
      let index = 0;
      // totalLength = account.transactions.Transactions.length;

      availableTransactions.forEach(transaction => {
        if (
          account.ExtendedProperties.AccountType ===
            CONSTANTS.ACCOUNT_TYPE_SAVINGS ||
          transaction?.ExtendedProperties?.Type !== 'PendingTransaction'
        ) {
          if (index < listCount) {
            transactions.push(
              <Transaction
                transaction={transaction}
                accountType={account.ExtendedProperties.AccountType}
                AccountCurrencyCode={account.CurrencyCode}
                productType={account.ExtendedProperties.AccountType}
                key={`transaction-${index}-`}
                position={index}
              />,
            );
            index++;
          }
        }
      });
    }

    return (
      <div
        className="tab-focus"
        tabIndex="0"
        ref={tablist.refs.panels[tablist.refs.names.indexOf(name)]}
        onKeyDown={e => tablist.panelKeyDown(e, name, tablist.refs)}
      >
        <Content
          tagClassName="sr-only"
          tagID="tab-transaction-filter-bar"
          tag="span"
          cmsTag="Account-details:Tab:Transactions:Menu-label"
          copytext="Transactions action and filter bar"
        />
        <Navbar
          className="transactions-navbar"
          aria-labelledby="tab-transaction-filter-bar"
        >
          <form
            action={downloadlinkUrl}
            method="post"
            target="_blank"
            className="print-transactions-form"
            onSubmit={handlePrintSubmit}
          >
            {this.getFormData(account, range, localTransactions, reverse)}
            <Button
              onClick={() => {
                window.print();
              }}
              id="print-button"
              color="light"
              aria-describedby="format-printing-caveat"
            >
              <Icon name="Print" />
              <Content
                tagClassName="sr-only"
                tag="span"
                cmsTag="GLOBAL:Print-transactions"
                copytext="Print transactions"
              />
              <span aria-hidden="true">*</span>
            </Button>
          </form>
          <ExportAccountCSV
            forwardTransactionSort={TabTransaction.forwardTransactionSort}
            reverseTransactionSort={TabTransaction.reverseTransactionSort}
            account={account}
            range={range}
            transactionsList={localTransactions}
            reverse={this.state.reverse}
          />
          <button
            className="navbar-toggler"
            type="button"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Button
            id="toggler"
            className={`filter-transactions-button ` + open}
            onClick={this.toggleMenu}
          >
            <Content
              tag="span"
              tagClassName="sr-only"
              cmsTag="GLOBAL:Filter-transactions"
              copytext="Filter transactions"
            />
            <span aria-hidden="true" className="chevron">
              <Icon name="Chevron" />
            </span>
          </Button>
          <div className={'collapse clear-both w-100 ' + show}>
            <Row className="align-items-end">
              <TransactionDateRange
                setUpdateButton={setUpdateButton}
                activeTab={this.props.activeTab}
                transactions={localTransactions ? localTransactions : []}
                propTransactions={this.props.propTransactions}
                setTransactions={this.setTransactions}
                account={account}
                range={range}
                toggle={this.toggleTransactions}
                fetchTransactions={fetchTransactions}
              />
              <Col xs="12" sm="12" md="3">
                <Button
                  onClick={this.sortTransactions}
                  id="sort-by-date"
                  color="secondary"
                  size="sm"
                  className={
                    this.state.reverse ? 'mr-0 ascending' : 'mr-0 descending'
                  }
                >
                  <Content
                    cmsTag="GLOBAL:Sort-by-date"
                    copytext="Sort by date"
                  />
                  <span className="sr-only">
                    {this.state.reverse ? ' ascending' : ' descending'}
                    <Content
                      cmsTag="Accounts:Components:Detail-page:Tabs:Transaction:Tab-transaction:-Click-to-reverse-this"
                      copytext=" (click to reverse this)"
                    />
                  </span>
                  <span aria-hidden="true" className="chevron">
                    <Icon name="Sort" />
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
          <p className="small mb-0 mt-3" id="format-printing-caveat">
            <span aria-hidden="true">* </span>
            <Markdown
              template={{
                markdown: {
                  phone: CONTACTS.phone,
                  phoneLink: CONTACTS.phoneLink,
                  email: CONTACTS.email,
                  openTimes: CONTACTS.openTimes,
                  companyName: CONTACTS.companyName,
                },
              }}
              cmsTag="Account-details:Tab:Transaction:Print-caveat"
              markdown={` Please note: the printing format in some browsers may not fully work. Please contact us if you need help on <a href="tel:$[phoneLink]">$[phone]</a>.`}
            />
          </p>
        </Navbar>

        <div
          title="list of transactions in date order"
          className="transactions-container pt-4"
        >
          {transactions.length > 0 ? (
            <Row
              aria-hidden="true"
              className="transactions-column-headers d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex"
            >
              <Col xs={2} md={1} className="col-icon"></Col>
              <Col xs={4} sm={4} md={5}></Col>
              <Col xs={3} sm={3} md={3} className="text-right">
                <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
              </Col>
              <Col xs={3} sm={3} md={3} className="text-right">
                {account.ExtendedProperties.AccountType ===
                  CONSTANTS.ACCOUNT_TYPE_SAVINGS && (
                  <Content cmsTag="GLOBAL:Balance" copytext="Balance" />
                )}
              </Col>
            </Row>
          ) : (
            <Content
              tag="p"
              cmsTag="Accounts:Components:Detail-page:Tabs:Transaction:Tab-transaction:No-transactions-available"
              copytext="There are no transactions available."
            />
          )}
          {loading ? (
            <p aria-live="polite">
              <Content
                cmsTag="GLOBAL:Loading"
                copytext="Please wait while the page loads."
              />
            </p>
          ) : transactions.length > 0 ? (
            <Row>{transactions}</Row>
          ) : (
            ''
          )}

          <Row className="mt-5">
            <Col xs={12} sm={6} md={6} className="text-left">
              {!buttonStatus &&
              transactions &&
              transactions.length &&
              availableTransactions.length > 10 ? (
                <Button
                  onClick={() => {
                    this.olderTransactions();
                  }}
                  size="sm"
                  className="btn-nav float-left mr-0"
                  color="link"
                  id="see-more-transactions"
                >
                  <Content
                    cmsTag="Accounts:Components:Detail-page:Tabs:Transaction:Tab-transaction:See-more-transactions"
                    copytext="See more transactions"
                  />
                </Button>
              ) : (
                ''
              )}
            </Col>

            <Col xs={12} sm={6} md={6} className="text-right">
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                size="sm"
                className="btn-nav float-right mr-0 back-to-top"
                color="link"
                id="scroll-to-top"
              >
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="Accounts:Components:Detail-page:Tabs:Transaction:Tab-transaction:End-of-transactions"
                  copytext="End of transactions. Click here to "
                />
                <Content
                  cmsTag="GLOBAL:Scroll-to-top"
                  copytext="Scroll to top"
                />
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  getFormData = (account, range, localTransactions, reverse) => {
    if (!localTransactions) {
      return;
    }
    return (
      <React.Fragment>
        <input
          key="Key"
          type="hidden"
          name="Key"
          value={account.AccountKey.Key}
        ></input>
        <input
          key="Context"
          type="hidden"
          name="Context"
          value={account.AccountKey.Context}
        ></input>
        <input key="From" type="hidden" name="From" value={range.From}></input>
        <input key="To" type="hidden" name="To" value={range.To}></input>
        <input
          key="Sort"
          type="hidden"
          name="Sort"
          value={reverse === true}
        ></input>
        {localTransactions.map((transaction, index) => {
          const UID = transaction.ExtendedProperties?.TransactionId
            ? transaction.ExtendedProperties.TransactionId
            : transaction.TransactionIdentifier;
          return (
            <div key={'transaction-' + UID + index}>
              <input
                key={'transactions.Index' + UID}
                type="hidden"
                name={'transactions.Index'}
                value={UID}
              ></input>
              <input
                key={'transactions[' + UID + '].Date'}
                type="hidden"
                name={'transactions[' + UID + '].Date'}
                value={
                  transaction.ExtendedProperties?.TransactionDate
                    ? transaction.ExtendedProperties?.TransactionDate
                    : transaction.Date
                }
              ></input>
              {transaction.ExtendedProperties &&
                transaction.ExtendedProperties.Type && (
                  <input
                    key={'transactions[' + UID + '].Type'}
                    type="hidden"
                    name={'transactions[' + UID + '].Type'}
                    value={transaction.ExtendedProperties.Type}
                  ></input>
                )}
              <input
                key={'transactions[' + UID + '].Balance'}
                type="hidden"
                name={'transactions[' + UID + '].Balance'}
                value={transaction.Balance}
              ></input>
              <input
                key={'transactions[' + UID + '].Amount'}
                type="hidden"
                name={'transactions[' + UID + '].Amount'}
                value={transaction.Amount}
              ></input>
              <input
                key={'transactions[' + UID + '].TransactionId'}
                type="hidden"
                name={'transactions[' + UID + '].TransactionId'}
                value={UID}
              ></input>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    const { activeTab, account, accountType } = this.props;
    if (!account) {
      return null;
    }
    return (
      <React.Fragment>
        {accountType !== 'mortgage' ? (
          <TabPane
            id="transactions"
            role="tabpanel"
            tabId="transactions"
            aria-labelledby="transactions-tab"
          >
            <Fade in={activeTab === 'transactions'}>
              {this.getpageContent()}
            </Fade>
          </TabPane>
        ) : (
          <React.Fragment>{this.getpageContent()}</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
TabTransaction.propTypes = {
  account: PropTypes.object,
  buttonStatus: PropTypes.bool,
  listCount: PropTypes.any,
  activeTab: PropTypes.string,
  fetchTransactions: PropTypes.func,
  updateCountAndButton: PropTypes.func,
  viewMore: PropTypes.func,
  setUpdateButton: PropTypes.func,
  range: PropTypes.object,
  tablist: PropTypes.object,
  name: PropTypes.any,
  getInterestHistoryIfNeeded: PropTypes.any,
  isFetching: PropTypes.any,
  interestRates: PropTypes.any,
  accounts: PropTypes.any,
  count: PropTypes.any,
  propTransactions: PropTypes.any,
  setCount: PropTypes.any,
  transRange: PropTypes.any,
  updateListCount: PropTypes.any,
  loading: PropTypes.any,
  downloadlinkUrl: PropTypes.any,
  accountType: PropTypes.string,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandAccountsDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandAccountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...brandAccountsMapDispatch },
)(TabTransaction);
