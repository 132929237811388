import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'reactstrap';
import { Content, Markdown, FormattedDate, Text } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import _ from 'lodash';
import { RULES } from '../../../shared/constants/rules';
import Amount from '../../shared/inputAmount';

class TransferForm extends Component {
  constructor(props) {
    super(props);
    const state = this.initialState(props.account, props.data);
    this.state = state;
  }

  componentDidUpdate() {
    const { account: propAccount } = this.props;
    const { account: stateAccount } = this.state;
    if (propAccount && propAccount !== stateAccount) {
      this.setState({ ...this.state, account: propAccount });
    }
  }

  setAmountRules = ({ amount, withdrawalRule }) => {
    const form = { ...this.state.form, amount };
    this.setState({
      ...this.state,
      amount,
      form,
      withdrawalRule,
    });
  };

  initialState = (account, data) => {
    return {
      account: account,
      form: {
        reference: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter a payment reference.',
            },
            format: {
              regex: RULES.paymentReference,
              message: 'Please enter a valid reference.',
            },
          },
          value: data && data['reference'] ? data['reference'] : '',
        },
        payeeName: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter the payee name.',
            },
            format: {
              regex: RULES.payeeName,
              message: 'Please enter a valid payee name.',
            },
          },
          value: data && data['payeeName'] ? data['payeeName'] : '',
        },

        payeeAccount: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter your account number.',
            },
            format: {
              regex: RULES.accountNumber,
              message: 'Please enter a valid account number.',
            },
          },
          value: data && data['payeeAccount'] ? data['payeeAccount'] : '',
        },
      },
    };
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    const { data, setStateDynamic } = this.props;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data, setStateDynamic } = this.props;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const {
      onSubmit,
      account,
      callAccountValidations,
      accountForm,
      setStateDynamic,
    } = this.props;
    let validatedAccountForm = Validate.form(accountForm);
    if (!account) {
      callAccountValidations();
      return;
    }
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    setStateDynamic({
      combinedForm: {
        ...form,
        accountFormValidation: validatedAccountForm.accountFormValidation,
      },
    });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }

    const validatedForm = Validate.form({
      ...form,
      accountFormValidation: validatedAccountForm.accountFormValidation,
    });

    setStateDynamic({
      combinedForm: validatedForm,
    });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    if (form && form.approved) {
      onSubmit(form);
    }
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, amount } = this.state;
    const { data, setStateDynamic } = this.props;

    if (checked) {
      form = _.flow([
        Object.entries,
        arr => arr.filter(([key]) => key !== 'amount' && key !== 'approved'),
        Object.fromEntries,
      ])(form);
    } else {
      form = _.flow([
        Object.entries,
        arr => arr.filter(([key]) => key !== 'approved'),
        Object.fromEntries,
      ])(form);
      if (!form.amount) {
        form = { amount, ...form };
      }
    }
    form = Validate.input(name, checked ? 'true' : null, form);
    let combinedForm = form; // Validate.form(form);
    data[name] = checked ? 'true' : null;
    setStateDynamic({
      data,
      combinedForm: combinedForm,
    });
    let validatedForm = Validate.form(form);
    this.setState({ ...this.state, form: validatedForm });
  };

  render() {
    const { form, account, withdrawalRule } = this.state;
    const isDisabled = !account || !form || !withdrawalRule?.isPermitted;
    const { data } = this.props;
    return (
      <Form
        autoComplete="off"
        id="transfer-to-another-form"
        onSubmit={this.onSubmitHandler}
      >
        {account && withdrawalRule && !withdrawalRule.isPermitted && (
          <div role="alert" className="alert alert-danger">
            <Content
              cmsTag="Savings-servicing:move-money:error-message"
              copytext={withdrawalRule.message}
            />
          </div>
        )}
        <Markdown
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
            },
          }}
          cmsTag="GLOBAL:Closing-account-information"
          markdown={`\nTo close your account and transfer the funds to another Darlington Building Society account, please contact our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>.`}
        />
        <Amount
          form={form}
          account={account}
          onChange={this.updateCurrency}
          onBlur={this.onBlur}
          data={data}
          code={account && account.CurrencyCode}
          setAmountRules={this.setAmountRules}
          disabled={isDisabled}
          label="Amount to transfer"
          cmsTag="GLOBAL:transfer-amount"
          showErrors={true}
          validation={form.amount}
          field="amount"
          id="amount"
          inputGroupClassName="half-field"
          dp={2}
        />
        <div className="form-group">
          <Row tag="dl" title="Payment date">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3} xl={2}>
              <Content cmsTag="GLOBAL:Payment-date" copytext="Payment date" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9} xl={10}>
              <FormattedDate date={new Date()} format="Do MMMM YYYY" />
            </Col>
          </Row>
        </div>

        <Text
          label="Payment reference"
          cmsTag="GLOBAL:Payment-reference"
          id="reference"
          field="reference"
          maxLength={'30'}
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form && form.reference}
          disabled={isDisabled}
        />
        <section>
          <Content
            tag="h2"
            cmsTag="Savings-servicing:Payee-details:h2-1"
            copytext="Payee details"
          />
          <Text
            label="Payee name"
            cmsTag="GLOBAL:Payee-name"
            id="payeeName"
            field="payeeName"
            maxLength={'18'}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form && form.payeeName}
            disabled={isDisabled}
          />
          <Text
            label="Payee account number"
            cmsTag="GLOBAL:payee-account-number"
            id="payeeAccount"
            field="payeeAccount"
            maxLength={'8'}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form && form.payeeAccount}
            disabled={isDisabled}
          />
        </section>
        <div className="alert alert-info">
          <Markdown
            cmsTag="GLOBAL:Transfer-to-another-date-warning"
            markdown={
              'Please note: If your transfer date falls on a weekend or bank holiday, it will be processed the following working day.'
            }
          />
        </div>
        <div className="form-button-group">
          <Button id="review-transfer-to-another" type="submit" color="primary">
            <Content
              cmsTag="GLOBAL:Review-transfer-details"
              copytext="Review transfer details"
            />
          </Button>
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </Form>
    );
  }
}

TransferForm.propTypes = {
  account: PropTypes.object,
  saveData: PropTypes.any,
  callAccountValidations: PropTypes.func,
  accountForm: PropTypes.any,
  setStateDynamic: PropTypes.func,
  data: PropTypes.any,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.any,
};

export default TransferForm;
