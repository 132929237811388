import SummaryMenuItems from './SummaryMenuItems';
import MoneyToolsMenuItems from './moneyTools/MoneyToolsMenuItems';
import { URLS } from '@myie/interact-local-dom';

const SavingsServicingMenuItems = [
  {
    url: URLS.SavingsGoals,
    menuItem: MoneyToolsMenuItems,
  },
  {
    url: URLS.ShortApply,
    menuItem: SummaryMenuItems,
  },
];

export default SavingsServicingMenuItems;
