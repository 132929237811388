import { Connection } from '@myie/interact';
import {
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_REQUEST,
  RESET_DOCUMENT_DOWNLOAD,
  FETCH_DOCUMENT_DOWNLOAD_SUCCESS,
  FETCH_DOCUMENT_DOWNLOAD_FAILURE,
  FETCH_DOCUMENT_DOWNLOAD_REQUEST,
} from './documentTypes';

export const brandRequestDocuments = () => ({
  type: FETCH_DOCUMENTS_REQUEST,
  isFetching: true,
  error: null,
});

export const brandFailedDocuments = error => ({
  type: FETCH_DOCUMENTS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const brandReceiveDocuments = (json, accountkey) => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  documents: json,
  accountkey: accountkey,
  isFetching: false,
  error: null,
});

export const brandFetchDocuments = request => dispatch => {
  dispatch(brandRequestDocuments());
  return fetch(Connection.baseUrl('accounts') + `v3.0/documents/getdocuments`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(brandReceiveDocuments(json, request.AccountKey)))
    .catch(error => dispatch(brandFailedDocuments(error)));
};

export const brandShouldFetchDocuments = (state, request) => {
  if (!state.accounts || !state.accounts.Accounts) {
    return false;
  }
  const index = state.accounts.Accounts.findIndex(
    x => x.AccountKey.Key === request.AccountKey.Key,
  );
  if (index === -1) {
    return false;
  }
  if (!state.accounts.Accounts[index].documents) {
    return true;
  }

  return false;
};

export const brandFetchDocumentsIfNeeded = request => (dispatch, getState) => {
  if (brandShouldFetchDocuments(getState().accountsDefinition, request)) {
    return dispatch(brandFetchDocuments(request));
  }
};

export const brandRequestDocumentDownload = () => ({
  type: FETCH_DOCUMENT_DOWNLOAD_REQUEST,
  isFetching: true,
  error: null,
});

export const brandFailedDocumentDownload = error => ({
  type: FETCH_DOCUMENT_DOWNLOAD_FAILURE,
  isFetching: false,
  error: error.message,
});

export const brandReceiveDocumentDownload = (json, documentKey) => ({
  type: FETCH_DOCUMENT_DOWNLOAD_SUCCESS,
  downloadedDocument: json,
  documentKey: documentKey,
  isFetching: false,
  error: null,
});

export const brandFetchDocumentDownload = request => dispatch => {
  dispatch(brandRequestDocumentDownload());
  return fetch(Connection.baseUrl('accounts') + `v3.0/documents/getdocument`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json =>
      dispatch(brandReceiveDocumentDownload(json, request.DocumentKey)),
    )
    .catch(error => dispatch(brandFailedDocumentDownload(error)));
};

export const brandResetDocumentDownload = () => ({
  type: RESET_DOCUMENT_DOWNLOAD,
});
