import React, { Component } from 'react';
import { BreadcrumbItem, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import moment from 'moment';
import { AppMeta, CONSTANTS, stateUtility } from '@myie/interact-dom';
import { storage } from './storageName';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Create from './Create';
import SelectGoal from './SelectGoal';
import queryString from 'query-string';
import DetailScreen from './DetailScreen';
import Review from './Review';
import { CONTACTS } from '@myie/interact-local-dom';
import Success from '../shared/Success';
import loadStateHOC from '../../../../shared/stateManager/loadStateHOC';
import dateValidation from '../../../utility';

class AddANewGoal extends Component {
  constructor(props) {
    super(props);
    const {
      match,
      stateData,
      urlParams,
      location,
      preservedSavingsGoalState,
      updateState,
      resetPreserveGoalState,
    } = this.props;
    const stateList = [
      'addNewGoal',
      'goalDetails',
      'selectGoal',
      'ReviewAndSubmit',
      'success',
    ];
    const budgetPlannerUrl = queryString.parse(location.search);
    let stage = '';
    let data = stateData && stateData.formData ? stateData.formData : {};
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    if (
      budgetPlannerUrl &&
      budgetPlannerUrl.pageStage &&
      budgetPlannerUrl.calculator &&
      preservedSavingsGoalState
    ) {
      stage = budgetPlannerUrl.pageStage;
      data =
        preservedSavingsGoalState && preservedSavingsGoalState.formData
          ? preservedSavingsGoalState.formData
          : {};
      let stateData = {
        formData: preservedSavingsGoalState.formData,
        url: {
          pageStage: stage,
        },
      };
      updateState(stateData);
      resetPreserveGoalState();
    } else {
      stage =
        stateData &&
        stateData.url &&
        stateData.url.pageStage &&
        props.urlParams &&
        props.urlParams.pageStage
          ? props.urlParams.pageStage
          : 'addNewGoal';
      data = stateData && stateData.formData ? stateData.formData : {};
    }

    if (!hasStage) {
      stage = 'addNewGoal';
    }
    if (match && match.params && match.params.replace) {
      if (props.urlParams && props.urlParams.pageStage) {
        stage = props.urlParams.pageStage;
      } else {
        stage = 'selectGoal';
      }
    }

    stateUtility.setGlobalValue({ storage: storage.name });
    let state = this.initialState();
    this.state = {
      ...state,
      data,
      stage,
    };
  }

  initialState = () => {
    const { accounts, match } = this.props;

    let account;
    if (accounts && match && match.params) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }

    //Change the names of the goals here. This should ideally be changed to use a clients API, but this is here as a fall back.
    return {
      options: [
        { key: 'SavingsGoalCategory1', name: 'Baby' },
        { key: 'SavingsGoalCategory2', name: 'Birthdays' },
        { key: 'SavingsGoalCategory3', name: 'Car' },
        { key: 'SavingsGoalCategory4', name: 'Christmas' },
        { key: 'SavingsGoalCategory5', name: 'Debt reduction' },
        { key: 'SavingsGoalCategory6', name: 'Education' },
        { key: 'SavingsGoalCategory7', name: 'Holiday' },
        { key: 'SavingsGoalCategory8', name: 'House' },
        { key: 'SavingsGoalCategory9', name: 'Rainy day' },
        { key: 'SavingsGoalCategory10', name: 'Something special' },
        { key: 'SavingsGoalCategory11', name: 'Wedding' },
        { key: 'SavingsGoalCategory12', name: 'Your business' },
        { key: 'SavingsGoalCategoryOther', name: 'Other' },
      ],
      account: account,
      accountKey: null,
      stage: {},
      withdrawDate: moment(),
      form: {},
      data: {},
    };
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  saveForBudgetCalculation = () => {
    const { preserveGoalState, match } = this.props;
    let stateData = {
      formData: this.props.stateData.formData,
      accountId: match.params.id,
      mode: 'add',
      url: {
        pageStage: this.stage,
      },
    };

    preserveGoalState(stateData);
  };

  componentDidMount() {
    const {
      fetchAccountsIfNeeded,
      resetCreateGoals,
      getAllProducts,
    } = this.props;
    fetchAccountsIfNeeded();
    getAllProducts({
      checkLimitExceeded: false,
      isShortApply: false,
    });
    resetCreateGoals();
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const {
      makeWithdrawResponse = {},
      accounts,
      viewGoalDetails,
      updateUrl,
      isFetching,
      match,
      createGoalDetails,
      viewGoals,
    } = nextProps;
    if (
      accounts &&
      accounts.Accounts &&
      !viewGoalDetails &&
      !isFetching &&
      !state.called
    ) {
      if (match && match.params.id) {
        let account;
        let keys = [];
        account = accounts.Accounts.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.AccountKey.Key) === match.params.id
            );
          }
          return false;
        });
        if (account) {
          keys.push(account.AccountKey);
        }
        viewGoals({
          AccountKeys: keys,
        });
        return {
          called: true,
        };
      }
    }
    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success'
    ) {
      return {
        stage: 'goalDetails',
      };
    }

    if (
      createGoalDetails &&
      createGoalDetails.ResponseStatus &&
      state.account &&
      state.stage !== 'success'
    ) {
      let stateData = {
        formData: nextProps.stateData.formData,
        url: {
          pageStage: 'success',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'success',
        data: nextProps.stateData.formData,
      };
    }

    return null;
  };

  componentDidUpdate() {
    const { match, accounts, setBackPageMode, backPageMode } = this.props;
    const { accountKey } = this.state;
    if (
      match &&
      match.params &&
      match.params.page &&
      match.params.page !== 'undefined' &&
      backPageMode !== match.params.page
    ) {
      setBackPageMode(match.params.page);
    }
    if (
      accounts &&
      match &&
      match.params &&
      match.params.id &&
      (!accountKey || match.params.id !== accountKey)
    ) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account, accountKey: match.params.id });
    }
  }

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  //update the stage in state
  submitForm = (stage, form) => {
    this.setState({ ...this.state, stage, form, message: null });
  };

  getActionDate = account => {
    const { holidays } = this.props;
    let numberOfDays = '';
    if (account.ExtendedProperties.AccountType === CONSTANTS.REGULAR_SAVINGS) {
      let segments = account.AccountName.split(/\s+/);
      numberOfDays = parseInt(segments[0]);
    }

    const NumberOfDays = numberOfDays === 0 ? 0 : numberOfDays;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'goal-list':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-savings-goal-list"
            copytext="Back to savings goal list"
          />
        );
      case 'myDetails':
      default:
        return null;
    }
  };

  changeUrl = page => {
    const { match, history } = this.props;

    switch (page) {
      case 'goal-list':
        history.push('/money-tools/savings-goals/view');
        break;
      case 'detail':
      case 'account-details':
        {
          let url = `/accounts/details/${match.params.id}`;
          history.push(url);
        }
        break;
      case 'summary':
        history.push('/accounts/list');
        break;
      default:
    }
  };

  getBackButton = (page = CONSTANTS.SOURCE_PAGE_GOAL_LIST) => {
    if (page === 'undefined') {
      if (this.props.backPageMode !== 'undefined') {
        page = this.props.backPageMode;
      } else {
        page = CONSTANTS.SOURCE_PAGE_GOAL_LIST;
      }
    }
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl(page);
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  onSubmitGoals = () => {
    const { account, data } = this.state;
    const { createGoals } = this.props;
    let date = data['date'];
    if (!date) {
      let nextMonth = moment().add(1, 'M');
      date = nextMonth.format('YYYY-MM-DD');
    }
    moment().add(1, 'M');

    let request = {
      AccountKey: account.AccountKey,
      AccountCurrency: 'GBP',
      Category: data['goal'],
      Name: data['goalName'],
      TargetDate: moment(date).utcOffset(0, true),
      Amount: data['amount'],
      Monthly: data['monthlyAmount'],
      Existing: true,
      ExtendedProperties: {
        IpAddress: 'IpAddress',
        CustomerNumber: 'CustomerNumber',
        AccountNumber: 'AccountNumber',
        SubAccountNumber: account?.ExtendedProperties?.SubAccountNumber,
      },
    };

    createGoals(request);
  };

  // common onChangeAccount function
  onChangeAccount = e => {
    const { fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();

    const { name, value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      let { data } = this.state;
      data[name] = value;
      this.setState({ account });
    }
  };
  // select current stage
  currentStage = account => {
    const { accounts = {}, match, updateUrl, backPageMode } = this.props;

    const { options } = this.state;
    switch (this.state.stage) {
      case 'addNewGoal':
        return (
          <Create
            setForm={this.setForm}
            updateUrl={updateUrl}
            getBackButton={this.getBackButton}
            match={match}
            page={match.params.page}
            submitForm={this.submitForm}
            onChangeAccount={this.onChangeAccount}
            accounts={accounts}
            account={account}
            setStateDynamic={this.setStateDynamic}
            onCancelled={this.onCancelleWithdrawal}
            backPageMode={backPageMode}
          />
        );

      case 'selectGoal':
        return (
          <SelectGoal
            saveData={this.saveData}
            getBackButton={this.getBackButton}
            page={match.params.page}
            options={options}
            updateUrl={updateUrl}
            formData={this.state.data}
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            form={this.state.form}
            setStateDynamic={this.setStateDynamic}
            setStage={this.setStage}
            account={account}
            backPageMode={backPageMode}
          />
        );
      case 'goalDetails':
        return (
          <DetailScreen
            submit={this.onSubmitGoals}
            getBackButton={this.getBackButton}
            options={options}
            page={match.params.page}
            saveForBudgetCalculation={this.saveForBudgetCalculation}
            updateUrl={updateUrl}
            saveData={this.saveData}
            setStateDynamic={this.setStateDynamic}
            account={account}
            formData={this.state.data}
            setStage={this.setStage}
            activate={this.activate}
            backPageMode={backPageMode}
          />
        );

      case 'ReviewAndSubmit':
        return (
          <Review
            goalName={
              this.state.data && this.state.data['goalName']
                ? this.state.data['goalName']
                : ''
            }
            saveData={this.saveData}
            options={options}
            getBackButton={this.getBackButton}
            page={match.params.page}
            updateUrl={updateUrl}
            formData={this.state.data}
            submit={this.onSubmitGoals}
            form={this.state.form}
            setStateDynamic={this.setStateDynamic}
            setStage={this.setStage}
            account={account}
            backPageMode={backPageMode}
          />
        );

      default:
    }
  };

  render() {
    const { stage, account } = this.state;
    const { stateData, match } = this.props;

    if (stage === 'success' && match && account) {
      return (
        <Success
          getBackButton={this.getBackButton}
          getContent={this.getContent}
          account={account}
          formData={this.state.data}
          changeUrl={this.changeUrl}
          page={match.params.page}
          mode="add"
          goalName={stateData.formData ? stateData.formData['goalName'] : ''}
          to="/money-tools/savings-goals/view"
          resetCreateGoals={this.props.resetCreateGoals}
        />
      );
    }
    if (!this.props.allProducts) {
      return null;
    }
    return (
      <div id="add-a-new-goal">
        {stage === 'addNewGoal' ? (
          <AppMeta
            id="meta-data"
            contacts={CONTACTS}
            stage="child"
            title="Add a savings goal step 1"
            metaDescription="Add a savings goal step 1"
          />
        ) : (
          ''
        )}
        {stage === 'selectGoal' ? (
          <AppMeta
            id="meta-data"
            contacts={CONTACTS}
            stage="child"
            title="Add a savings goal step 2"
            metaDescription="Add a savings goal step 2"
          />
        ) : (
          ''
        )}
        {stage === 'goalDetails' ? (
          <AppMeta
            id="meta-data"
            contacts={CONTACTS}
            stage="child"
            title="Add a savings goal step 3"
            metaDescription="Add a savings goal step 3"
          />
        ) : (
          ''
        )}

        {stage === 'ReviewAndSubmit' ? (
          <AppMeta
            id="meta-data"
            contacts={CONTACTS}
            stage="child"
            title="Add a savings goal step 4"
            metaDescription="Add a savings goal step 4"
          />
        ) : (
          ''
        )}
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Add-a-new-goal:Add-a-new-goal:h1"
          copytext="Add a savings goal"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={stage === 'addNewGoal'}
              className={
                stage === 'selectGoal' || stage === 'goalDetails'
                  ? 'completed'
                  : ''
              }
            >
              {this.state.stage === 'addNewGoal' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {stage === 'selectGoal' || stage === 'goalDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:account-details"
                copytext="Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem
              active={stage === 'selectGoal'}
              className={stage === 'goalDetails' ? 'completed' : ''}
            >
              {this.state.stage === 'selectGoal' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {stage === 'goalDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Select-a-purpose"
                copytext="Goal purpose"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'goalDetails'}>
              {this.state.stage === 'goalDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-3-" copytext="Step 3 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Goal-details"
                copytext="Goal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'ReviewAndSubmit'}>
              {this.state.stage === 'ReviewAndSubmit' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-4-" copytext="Step 4 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Goal-details"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>

        {this.currentStage(account)}
      </div>
    );
  }
}

AddANewGoal.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  preserveGoalState: PropTypes.func,
  updateUrl: PropTypes.func,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  stateData: PropTypes.object,
  urlParams: PropTypes.object,
  createGoalDetails: PropTypes.object,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  createGoals: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  resetCreateGoals: PropTypes.any,
  holidays: PropTypes.any,
  preservedSavingsGoalState: PropTypes.any,
  resetPreserveGoalState: PropTypes.any,
  updateState: PropTypes.func,
  getAllProducts: PropTypes.func,
  location: PropTypes.any,
  viewGoalDetails: PropTypes.any,
  getDepositProducts: PropTypes.func,
  allProducts: PropTypes.any,
  account: PropTypes.any,
  setBackPageMode: PropTypes.func,
  backPageMode: PropTypes.string,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
  )(withRouter(AddANewGoal)),
  storage.name,
);
