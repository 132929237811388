import React from 'react';
import { Card, CardColumns, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import PropTypes from 'prop-types';
import { mapDispatchToProps as userManagementDispatch } from '@myie/interact-user-management';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as brandUserManagementDispatch } from '@myie/interact-brand-user-management';
import { AppMeta, Content, SideLink, CONSTANTS } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Session } from '@myie/interact';

class MoveMoneyIndex extends React.Component {
  constructor(props) {
    super(props);
    const {
      getAllProducts,
      allProducts,
      getNominatedAccountStatus,
      fetchAccountsIfNeeded,
    } = props;
    fetchAccountsIfNeeded();

    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
    const retrievedUserName = Session.customer();
    const request = {
      userName: retrievedUserName.Username,
      Category: 'NominatedAccount',
      attempt: 1,
    };
    getNominatedAccountStatus(request);
  }

  componentDidMount() {
    const { getUserDetails } = this.props;
    //***Shold be removed from the code.
    const retrievedUserName = Session.customer();
    getUserDetails({ userName: retrievedUserName.Username });
  }
  getMakePaymentLink = (accounts, allProducts) => {
    let isNoticeAvailable = false;

    if (accounts && accounts.Accounts && accounts.Accounts.length > 0) {
      let account;
      for (account of accounts.Accounts) {
        const product = allProducts.find(
          product =>
            product.Product_Code === account.ExtendedProperties.ProductCode,
        );
        switch (account.ExtendedProperties.AccountType) {
          case CONSTANTS.ACCOUNT_TYPE_SAVINGS:
            if (
              account.AccountStatus !== 'Closed' &&
              account.ExtendedProperties?.AccountMode !==
                CONSTANTS.ACCOUNT_CLOSED &&
              product &&
              product.Notice_To_Withdraw_Accounts ===
                CONSTANTS.NOTICE_TO_WITHDRAW
            ) {
              isNoticeAvailable = true;
            }
            break;

          default:
        }
      }
    }

    if (isNoticeAvailable) {
      return (
        <Card className="card-item">
          <SideLink
            linkTitle="Notice to withdraw"
            linkURL="/notice-to-withdraw/view/moveMoney"
            linkID="notice-to-withdraw-link"
            iconImage="NoticeToWithdraw"
            showMessageCount={false}
            linkVersion="primary"
          />
        </Card>
      );
    }
    return false;
  };

  render() {
    let isAvailable = false,
      hasNominatedAccount = false,
      isAccountModeTransact = false;

    const { accounts, allProducts, nominatedAccountStatus } = this.props;
    const { DocumentUploadStatus = null, DecisionStatus = null } =
      nominatedAccountStatus || {};
    if (!accounts || !allProducts) {
      return null;
    }

    if (accounts && accounts.Accounts && accounts.Accounts.length > 0) {
      let account;
      for (account of accounts.Accounts) {
        const { ExtendedProperties: { AccountMode } = {} } = account;
        switch (account.ExtendedProperties.AccountType) {
          case CONSTANTS.ACCOUNT_TYPE_SAVINGS:
            if (
              AccountMode === CONSTANTS.ACCOUNT_TRANSACT ||
              AccountMode === CONSTANTS.ACCOUNT_VIEW_ONLY
            ) {
              isAvailable = true;
            }
            break;

          default:
        }
      }
      hasNominatedAccount = accounts.Accounts.find(
        account =>
          account.ExtendedProperties.HasNominatedAccount !==
          CONSTANTS.NO_NOMINATED_ACCOUNT,
      );

      isAccountModeTransact = accounts.Accounts.find(
        account =>
          account.ExtendedProperties.AccountMode === CONSTANTS.ACCOUNT_TRANSACT,
      );
    }

    return (
      <div id="move-money" className="secondary-nav">
        <AppMeta
          id="meta-data"
          stage="child"
          title="Move Money"
          contacts={CONTACTS}
          metaDescription="Move Money landing page"
        />
        <Content
          tag="h1"
          cmsTag="Move-money:Move-money-index:h1"
          copytext="Move Money"
        />
        {!isAvailable ? (
          <Content
            tag="p"
            cmsTag="Move-money:Move-money-index:p1"
            copytext="Your savings accounts are not eligible for transactions."
          />
        ) : (
          <CardColumns className="grid-mode">
            {hasNominatedAccount &&
              DocumentUploadStatus !==
                CONSTANTS.NOMINATED_ACC_DOC_UPLOAD_STATUS_PENDING &&
              DecisionStatus !== CONSTANTS.NOMINATED_ACC_DECISION_STATUS &&
              isAccountModeTransact && (
                <Card className="card-item">
                  <CardBody>
                    <SideLink
                      linkTitle="Withdraw to your nominated account"
                      linkURL="/nominated-account/create/moveMoney"
                      linkID="one-off-payment-link"
                      iconImage="MoveMoneyOneOffPayment"
                      showMessageCount={false}
                      linkVersion="primary"
                    />
                  </CardBody>
                </Card>
              )}
            {this.getMakePaymentLink(accounts, allProducts)}
            <Card className="card-item">
              <CardBody>
                <SideLink
                  linkTitle={`Transfer money between your ${CONTACTS.companyNameShorthand} savings accounts`}
                  linkURL="/internal-transfer/create/moveMoney"
                  linkID="internal-transfer-link"
                  iconImage="InternalTransfer"
                  showMessageCount={false}
                  linkVersion="primary"
                />
              </CardBody>
            </Card>
            <Card className="card-item">
              <CardBody>
                <SideLink
                  linkTitle={`Transfer to another ${CONTACTS.companyNameShorthand} member's savings account`}
                  linkURL="/transfer-to-another/create/moveMoney"
                  linkID="transfer-into-another-link"
                  iconImage="TransferIntoAnotherAccount"
                  showMessageCount={false}
                  linkVersion="primary"
                />
              </CardBody>
            </Card>
            <Card className="card-item">
              <CardBody>
                <SideLink
                  linkTitle={`Make a deposit`}
                  linkURL="/make-a-deposit/moveMoney/"
                  linkID="make-a-deposit-link"
                  iconImage="MakeADeposit"
                  showMessageCount={false}
                  linkVersion="primary"
                />
              </CardBody>
            </Card>
          </CardColumns>
        )}
      </div>
    );
  }
}

MoveMoneyIndex.propTypes = {
  accounts: PropTypes.any,
  getUserDetails: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  getNominatedAccountStatus: PropTypes.func,
  nominatedAccountStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    accountsDefinition,
    userManagementDefinition,
    custombrandSavingsServicingDefinition,
    customUserManagementDefinition,
  } = state;
  return {
    ...accountsDefinition,
    ...userManagementDefinition,
    ...custombrandSavingsServicingDefinition,
    ...customUserManagementDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...userManagementDispatch,
    ...mapSavingsServicingDispatchToProps,
    ...brandUserManagementDispatch,
  },
)(MoveMoneyIndex);
