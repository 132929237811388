import brandWithdrawalDefinition from './reducers/withdrawalDefinition';
import transferDefinition from './reducers/transferDefinition';
import externalTransferDefinition from './reducers/externalTransferDefinition';
import maturityOptionsDefinition from './reducers/maturityOptionsDefinition';
import brandShortApplyDefinition from './reducers/shortApplyDefinition';
import summaryBoxDefinition from './reducers/summaryBoxDefinition';
import depositProductsDefinition from './reducers/depositProductsDefinition';
import viewGoalsDefinition from './reducers/viewGoalsDefinition';
import budgetPlannerDefinition from './reducers/budgetPlannerDefinition';
import makeAdepositDefinition from './reducers/makeAdepositDefinition';

const custombrandSavingsServicingDefinition = (state = {}, action) => {
  let newState = state;
  newState = brandWithdrawalDefinition(newState, action);
  newState = maturityOptionsDefinition(newState, action);
  newState = externalTransferDefinition(newState, action);
  newState = brandShortApplyDefinition(newState, action);
  newState = summaryBoxDefinition(newState, action);
  newState = transferDefinition(newState, action);
  newState = depositProductsDefinition(newState, action);
  newState = viewGoalsDefinition(newState, action);
  newState = budgetPlannerDefinition(newState, action);
  newState = makeAdepositDefinition(newState, action);

  return newState;
};

export default custombrandSavingsServicingDefinition;
