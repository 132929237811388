import { Connection } from '@myie/interact';
import {
  FETCH_SAVINGS_TRANSACTIONS_SUCCESS,
  FETCH_SAVINGS_TRANSACTIONS_FAILURE,
  FETCH_SAVINGS_TRANSACTIONS_REQUEST,
  RESET_SAVINGS_ACCOUNT,
} from './types';
import {
  FETCH_SAVINGS_ACCOUNT_SUCCESS,
  FETCH_SAVINGS_ACCOUNT_FAILURE,
  FETCH_SAVINGS_ACCOUNT_REQUEST,
} from './types';

export const resetSavingsAccount = key => ({
  type: RESET_SAVINGS_ACCOUNT,
  key: key,
});

export const requestSavingsAccount = () => ({
  type: FETCH_SAVINGS_ACCOUNT_REQUEST,
  isFetching: true,
  error: null,
});

export const failedSavingsAccount = error => ({
  type: FETCH_SAVINGS_ACCOUNT_FAILURE,
  isFetching: false,
  error: error.message,
});

export const receiveSavingsAccount = (json, accountkey) => ({
  type: FETCH_SAVINGS_ACCOUNT_SUCCESS,
  account: json,
  accountkey: accountkey,
  isFetching: false,
  error: null,
});

export const fetchSavingsAccount = request => dispatch => {
  dispatch(requestSavingsAccount());
  return fetch(Connection.baseUrl('accounts') + `v3.0/savings/getaccount`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(receiveSavingsAccount(json, request.AccountKey)))
    .catch(error => dispatch(failedSavingsAccount(error)));
};

export const shouldFetchSavingsAccount = (state, request) => {
  if (!state.accounts || !state.accounts.Accounts) {
    return false;
  }
  const index = state.accounts.Accounts.findIndex(
    x => x.AccountKey.Key === request.AccountKey.Key,
  );
  if (index === -1) {
    return false;
  }
  if (
    !state.accounts.Accounts[index] ||
    !state.accounts.Accounts[index].ExpiryDate
  ) {
    return true;
  }
  const expiry = state.accounts.Accounts[index].ExpiryDate;
  return new Date() > new Date(expiry);
};

export const fetchSavingsAccountIfNeeded = request => (dispatch, getState) => {
  if (shouldFetchSavingsAccount(getState().accountsDefinition, request)) {
    return dispatch(fetchSavingsAccount(request));
  }
};

export const requestSavingsAccountTransactions = () => ({
  type: FETCH_SAVINGS_TRANSACTIONS_REQUEST,
  isFetching: true,
  error: null,
});

export const failedSavingsAccountTransactions = error => ({
  type: FETCH_SAVINGS_TRANSACTIONS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const receiveSavingsAccountTransactions = (json, accountkey, range) => ({
  type: FETCH_SAVINGS_TRANSACTIONS_SUCCESS,
  transactions: json,
  accountkey: accountkey,
  range: range,
  isFetching: false,
  error: null,
});

export const fetchSavingsAccountTransactions = (
  request,
  urlType = 'savings',
) => dispatch => {
  dispatch(requestSavingsAccountTransactions());
  return fetch(
    Connection.baseUrl('accounts') + `v3.0/${urlType}/gettransactions`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json =>
      dispatch(
        receiveSavingsAccountTransactions(
          json,
          request.AccountKey,
          request.Range,
        ),
      ),
    )
    .catch(error => dispatch(failedSavingsAccountTransactions(error)));
};

export const shouldFetchSavingsAccountTransactions = (state, request) => {
  if (!state.accounts || !state.accounts.Accounts) {
    return false;
  }
  const index = state.accounts.Accounts.findIndex(
    x => x.AccountKey.Key === request.AccountKey.Key,
  );
  if (index === -1) {
    return false;
  }
  if (
    !state.accounts.Accounts[index].transactions ||
    !state.accounts.Accounts[index].transactions.ExpiryDate
  ) {
    return true;
  }
  if (
    state.accounts.Accounts[index].transactions &&
    state.accounts.Accounts[index].transactions.Range
  ) {
    if (!request.Range && state.accounts.Accounts[index].transactions.Range) {
      return true;
    }
    if (request.Range && !state.accounts.Accounts[index].transactions.Range) {
      return true;
    }
    if (
      state.accounts.Accounts[index].transactions.Range.To !==
        request.Range.To ||
      state.accounts.Accounts[index].transactions.Range.From !==
        request.Range.From
    ) {
      return true;
    }
  }
  const expiry = state.accounts.Accounts[index].transactions.ExpiryDate;
  return new Date() > new Date(expiry);
};

export const fetchSavingsAccountTransactionsIfNeeded = request => (
  dispatch,
  getState,
) => {
  if (
    shouldFetchSavingsAccountTransactions(
      getState().accountsDefinition,
      request,
    )
  ) {
    return dispatch(fetchSavingsAccountTransactions(request));
  }
};
