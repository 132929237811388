import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { CONTACTS } from '@myie/interact-local-dom';
import {
  TextArea,
  Content,
  FormattedDate,
  AppMeta,
  CONSTANTS,
  Markdown,
  Pagination,
} from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import PropTypes from 'prop-types';
import FormatBody from '../../FormatBody';
import messagingUtility from './../../utility';
import { RULES } from '../../../shared/constants/rules';

class ReplyConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'View',
      message: null,
      offset: 0,
      size: 5,
      data: {},
      form: {
        message: {
          rules: {
            stop: true,
            required: {
              message:
                'You have not reached the minimum amount of text in your message',
            },
            format: {
              regex: RULES.messageBody,
              message: 'Please enter a valid message.',
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  onBlur = e => {
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { replyConversation, viewMessage } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      return;
    }
    if (viewMessage) {
      const request = {
        AccountKey: viewMessage.Conversation.AccountKey
          ? viewMessage.Conversation.AccountKey
          : null,
        ConversationKey: viewMessage.Conversation.ConversationKey,
        MessageBody: form.message.value,
        ExtendedProperties: {},
      };
      replyConversation(request);
    }
  };

  getPreviousPage = (Offset, Size) => {
    const previousOffset = Offset - Size;
    if (previousOffset >= 0) {
      this.setState({ ...this.state, offset: previousOffset });
    }
  };

  getNextPage = (Offset, Size, TotalConversationsCount) => {
    const nextOffset = Offset + Size;
    if (nextOffset <= TotalConversationsCount) {
      this.setState({ ...this.state, offset: nextOffset });
    }
  };

  // select current stag
  render() {
    const { viewMessage } = this.props;
    const { form, offset, size } = this.state;
    let Conversation;

    let messages = [];
    let messageCount = 0;
    if (!viewMessage) {
      return null;
    } else {
      Conversation = viewMessage.Conversation;
    }

    if (Conversation && Conversation.Messages) {
      messageCount = Conversation.Messages.length;
      if (messageCount > size) {
        messages = Conversation.Messages.slice(offset, offset + size);
      } else {
        messages = Conversation.Messages;
      }
    }

    return (
      <div id="conversation-reply">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Send a reply"
          metaDescription="Send a reply"
        />
        <h1>
          <Content
            cmsTag="Messaging-two-way:Conversation:Reply:Reply:h1-part-1"
            copytext='Send a reply to "'
          />
          {messagingUtility.stripEndQuotes(Conversation.Reason)}
          <Content
            cmsTag="Messaging-two-way:Conversation:Reply:Reply:h1-part-2"
            copytext='"'
          />
        </h1>
        <Row
          tag="dl"
          title="Message details"
          className="message-details-header mb-5"
        >
          <Col tag="dt" xs={12} sm={6} md={3}>
            <Content
              cmsTag="GLOBAL:Related-account"
              copytext="Related account"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={9}>
            {Conversation.AccountNumber !== 'XXXXXXXXXable' &&
            Conversation.AccountNumber !== 'Not available' ? (
              <React.Fragment>
                <Content
                  cmsTag="GLOBAL:Account-ending-"
                  copytext="Account ending "
                />
                {Conversation.AccountNumber.substr(-4)}
              </React.Fragment>
            ) : (
              ''
            )}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={3}>
            <Content
              cmsTag="GLOBAL:Reference-number"
              copytext="Reference number"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={9}>
            {Conversation.Reference}
          </Col>
        </Row>
        <Form
          autoComplete="off"
          id="messaging-twoway-reply-form"
          onSubmit={this.submit}
        >
          <TextArea
            cmsTag="GLOBAL:Your-message"
            label="Your reply"
            id="message"
            field="message"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.message}
            maxLength="750"
            autoFocus={true}
            suffix={
              <Content
                id="chars-remaining"
                template={{
                  copytext: {
                    counter: () => {
                      return (
                        750 -
                        (this.state.form.message.value
                          ? this.state.form.message.value.length
                          : 0)
                      );
                    },
                  },
                }}
                copytext="You have $[counter] characters remaining."
              />
            }
          />

          <ul
            className="messages-list list-unstyled"
            title="Message Conversation"
          >
            {messages.map((item, index) => {
              return (
                <li key={`message-${index}`}>
                  {Conversation.Reason.search(
                    CONSTANTS.AUTO_GENERATED_MESSAGE,
                  ) !== -1 ? (
                    <h2 aria-describedby={`view-message-body-${index}`}>
                      <Content
                        tagClassName="sr-only"
                        tag="span"
                        cmsTag="GLOBAL:Sent-by-"
                        copytext="Sent by "
                      />
                      <Markdown
                        template={{
                          markdown: {
                            companyNameShorthand: CONTACTS.companyNameShorthand,
                          },
                        }}
                        cmsTag="GLOBAL:Company-name"
                        markdown={`$[companyNameShorthand]`}
                      />
                      <Content
                        tagClassName="sr-only"
                        tag="span"
                        cmsTag="GLOBAL:-on-"
                        copytext=" on "
                      />
                      <span className="timestamp">
                        <FormattedDate
                          date={item.CreatedDate}
                          format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
                        />
                      </span>
                    </h2>
                  ) : (
                    <h2 aria-describedby={`view-message-body-${index}`}>
                      <Content
                        tagClassName="sr-only"
                        tag="span"
                        cmsTag="GLOBAL:Sent-by-"
                        copytext="Sent by "
                      />
                      {item.Sender.SenderType === 'User' ? (
                        `${item.Sender.SenderName}`
                      ) : (
                        <Markdown
                          template={{
                            markdown: {
                              companyNameShorthand:
                                CONTACTS.companyNameShorthand,
                            },
                          }}
                          cmsTag="GLOBAL:Company-name"
                          markdown={`$[companyNameShorthand]`}
                        />
                      )}
                      <Content
                        tagClassName="sr-only"
                        tag="span"
                        cmsTag="GLOBAL:-on-"
                        copytext=" on "
                      />
                      <span className="timestamp">
                        <FormattedDate
                          date={item.CreatedDate}
                          format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
                        />
                      </span>
                    </h2>
                  )}

                  <p id={`view-message-body-${index}`}>
                    {FormatBody(item.Body)}
                  </p>
                </li>
              );
            })}
          </ul>
          {messageCount > size && (
            <Pagination
              Total={messageCount}
              Offset={offset}
              Size={size}
              getPreviousPage={this.getPreviousPage}
              getNextPage={this.getNextPage}
            />
          )}
          <div className="form-button-group">
            <Button
              id="messaging-twoway-message-submit"
              type="submit"
              color="primary"
            >
              <Content cmsTag="GLOBAL:Send-message" copytext="Send message" />
            </Button>
            <Link
              id="messaging-twoway-message-back-to-link"
              className="btn btn-secondary"
              role="button"
              to="/messaging-twoway/view"
            >
              <Content
                cmsTag="GLOBAL:Back-to-message-list"
                copytext="Back to message list"
              />
            </Link>
          </div>
        </Form>
      </div>
    );
  }
}

ReplyConversation.propTypes = {
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  baseUrl: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  replyConversation: PropTypes.any,
  setReplyStage: PropTypes.any,
  viewMessage: PropTypes.any,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
)(ReplyConversation);
