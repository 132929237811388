import React from 'react';
import { BreadcrumbItem, Alert } from 'reactstrap';
import { Content, AppMeta } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import moment from 'moment';
import MakePayment from './MakePayment';
import { CONTACTS } from '@myie/interact-local-dom';
import Confirmation from './Confirmation';
import WithdrawalSuccess from './WithdrawalSuccess';
import checkDownTime from '../../shared/HO_components/checkDownTime';

const META_TITLE = {
  MakePayment: 'Make a withdrawal Step 1',
  ReviewPayment: 'Make a withdrawal Step 2',
  CompletePayment: 'Withdrawal request accepted',
};

class MakeWithdrawal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    const { accounts, match } = this.props;
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    return {
      account: account,
      stage: 'MakePayment',
      actionDate: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      form: {},
    };
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success'
    ) {
      return {
        stage: 'CompletePayment',
      };
    }

    return null;
  };

  componentDidMount() {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
  }

  componentWillUnmount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
  }

  // common onChangeAccount function
  onChangeAccount = e => {
    const { resetMakeWithdrawal, fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();
    resetMakeWithdrawal();
    const { value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ ...this.initialState(), account });
    }
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  onCancelleWithdrawal = () => {
    const { history } = this.props;
    history.push('/accounts/list');
  };

  //update the stage in state
  submitForm = (stage, form) => {
    if (stage === 'MakePayment') {
      const { resetMakeWithdrawal } = this.props;
      resetMakeWithdrawal();
    }

    this.setState({ ...this.state, stage, form, message: null });
  };

  onCancelleWithdrawal = () => {
    const { history } = this.props;
    history.push('/accounts/list');
  };

  onSubmitWithdrawal = () => {
    const { account, form } = this.state;
    const { makeaWithdraw } = this.props;
    let request = {
      AccountKey: account.AccountKey,
      WithdrawalInformation: {
        ActionDate: this.state.actionDate,
        Amount: form.amount.value.replace(/,/g, ''),
        CloseAccount: false,
        PaymentReference: form.reference.value ? form.reference.value : null,
      },
    };

    makeaWithdraw(request);
  };

  // select current stage
  currentStage = () => {
    const { accounts = {} } = this.props;
    const { account = {} } = this.state;
    switch (this.state.stage) {
      case 'MakePayment':
        return (
          <MakePayment
            key={account.AccountNumber}
            setForm={this.setForm}
            submitForm={this.submitForm}
            onChangeAccount={this.onChangeAccount}
            actionDate={this.state.actionDate}
            accounts={accounts}
            account={account}
            onCancelled={this.onCancelleWithdrawal}
          />
        );
      case 'ReviewPayment':
        return (
          <Confirmation
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            form={this.state.form}
            setStage={this.setStage}
            account={account}
          />
        );
      case 'CompletePayment':
        return (
          <WithdrawalSuccess
            form={this.state.form}
            setStage={this.setStage}
            activate={this.activate}
            account={account}
          />
        );

      default:
    }
  };

  render() {
    const { account, stage } = this.state;
    const {
      error,
      isFetching,
      systemDownStatus, //From checkDownTime HOC
    } = this.props;

    if (!account) {
      return null;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={META_TITLE[stage]}
          metaDescription={META_TITLE[stage]}
        />
        {systemDownStatus.msg}
        <h1>
          {stage === 'MakePayment' ? (
            <Content
              cmsTag="Savings-servicing:Make-withdrawal:Make-withdrawal:h1"
              copytext="Make a withdrawal"
            />
          ) : (
            ''
          )}
          {stage === 'ReviewPayment' ? (
            <Content
              cmsTag="Savings-servicing:Make-withdrawal:Make-withdrawal:Confirm:h1"
              copytext="Confirm withdrawal request"
            />
          ) : (
            ''
          )}
          {stage === 'CompletePayment' ? (
            <Content
              cmsTag="Savings-servicing:Make-withdrawal:Make-withdrawal:Success:h1"
              copytext="Withdrawal request accepted"
            />
          ) : (
            ''
          )}
        </h1>

        {stage !== 'CompletePayment' ? (
          <div className="breadcrumb-container">
            <ol className="breadcrumb">
              <BreadcrumbItem
                active={stage === 'MakePayment'}
                className={
                  this.state.stage === 'ReviewPayment' ||
                  this.state.stage === 'CompletePayment'
                    ? 'completed'
                    : ''
                }
              >
                {this.state.stage === 'MakePayment' ? (
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Active"
                    copytext="Active: "
                  />
                ) : (
                  ''
                )}
                {this.state.stage === 'ReviewPayment' ||
                this.state.stage === 'CompletePayment' ? (
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Completed"
                    copytext="Completed: "
                  />
                ) : (
                  ''
                )}
                <span className="d-inline d-lg-none d-xl-none">
                  <span className="sr-only">
                    <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                  </span>
                  <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                  2:&nbsp;
                </span>
                <Content
                  cmsTag="BREADCRUMB:Withdrawal-details"
                  copytext="Withdrawal details"
                />
              </BreadcrumbItem>
              <BreadcrumbItem active={stage === 'ReviewPayment'}>
                {this.state.stage === 'ReviewPayment' ? (
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Active"
                    copytext="Active: "
                  />
                ) : (
                  ''
                )}
                <span className="d-inline d-lg-none d-xl-none">
                  <span className="sr-only">
                    <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                  </span>
                  <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                  2:&nbsp;
                </span>
                <Content
                  cmsTag="BREADCRUMB:Review-and-submit"
                  copytext="Review and submit"
                />
              </BreadcrumbItem>
            </ol>
          </div>
        ) : (
          ''
        )}

        {!isFetching && error ? <Alert color="danger">{error}</Alert> : null}
        {this.currentStage()}
      </React.Fragment>
    );
  }
}

MakeWithdrawal.propTypes = {
  withdrawalAccount: PropTypes.object,
  history: PropTypes.object,
  resetMakeWithdrawal: PropTypes.func,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  match: PropTypes.any,
  error: PropTypes.any,
  holidays: PropTypes.any,
  isFetching: PropTypes.any,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default checkDownTime(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
  )(withRouter(MakeWithdrawal)),
);
