import {
  RESET_GET_SUMMARY_BOX,
  GET_SUMMARY_BOX_REQUEST,
  GET_SUMMARY_BOX_SUCCESS,
  GET_SUMMARY_BOX_FALIURE,
} from '../actions/summaryBoxPdfTypes';

const summaryBoxDefinition = (state = {}, action) => {
  switch (action.type) {
    case GET_SUMMARY_BOX_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case GET_SUMMARY_BOX_SUCCESS:
      return {
        ...state,
        summaryBox: action.getSummaryBoxResponse,
        isFetching: action.isFetching,
      };
    case GET_SUMMARY_BOX_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_GET_SUMMARY_BOX:
      return {
        ...state,
        summaryBox: null,
        isFetching: false,
        error: null,
      };
    default:
      return state;
  }
};

export default summaryBoxDefinition;
