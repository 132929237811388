import {
  ACTIVATION_REQUEST,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAILURE,
  RESET_ACTIVATION,
  SET_CREDENTIALS_REQUEST,
  SET_CREDENTIALS_SUCCESS,
  SET_CREDENTIALS_FAILURE,
} from '../actions/registrationTypes';

const brandRegistrationDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_ACTIVATION:
      return {
        ...state,
        activationCheck: {},
      };
    case ACTIVATION_SUCCESS:
      return {
        ...state,
        activationCheck: action.activationCheck,
        isFetching: action.isFetching,
      };
    case ACTIVATION_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case SET_CREDENTIALS_SUCCESS:
      return {
        ...state,
        brandCredentialsSetup: action.credentialsSetup,
        isFetching: action.isFetching,
      };
    case SET_CREDENTIALS_REQUEST:
    case SET_CREDENTIALS_FAILURE:
    case ACTIVATION_REQUEST:
    default:
      return state;
  }
};

export default brandRegistrationDefinition;
