import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { mapDispatchToProps } from '@myie/interact-user-management';
import { mapDispatchToProps as brandUserManagementDispatch } from '@myie/interact-brand-user-management';
import { Redirect } from 'react-router-dom';
import { storage } from './storageName';
import AboutYou from './AboutYou';
import EditAboutYou from './EditAboutYou';
import YourAddress from './YourAddress';
import MarketingPreferences from './MarketingPreferences';
import EditMarketingPreferences from './EditMarketingPreferences';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../../shared/HO_components/checkDownTime';
import { Validate } from '@myie/interact';
import {
  Content,
  LinkTag,
  AppMeta,
  sharedUtility,
  FormErrorList,
  stateUtility,
  RULES,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class View extends React.Component {
  constructor(props) {
    super(props);
    const {
      resetUserDetails,
      resetPreferences,
      resetUserManagement,
      setupUserManagementActivate,
      stateData,
      urlParams,
    } = this.props;

    if (
      stateData &&
      stateData.RequireActivationTicket &&
      urlParams &&
      urlParams.twoFa &&
      urlParams.twoFa === 'active'
    ) {
      const RequireActivationTicket = {
        RequireActivationTicket: stateData.RequireActivationTicket,
        ExtendedProperties: null,
      };
      setupUserManagementActivate({
        RequireActivationTicket:
          stateData && RequireActivationTicket ? RequireActivationTicket : '',
      });
    }
    this.state = {
      mode: 'view',
      LocalUserDetails: null,
      localUserDetails: null,
      aboutYouData: {},
      updateStatus: null,
      twoFAStatus: null,
      marketingPreferences: {
        post: false,
        email: false,
      },
      marketingPreferencesForm: {
        preferences: {
          rules: {
            title: 'preferences',
            stop: true,
            required: {
              message: 'Please select a contact option.',
            },
          },
          value: '',
        },
      },
      aboutYouForm: {
        EmailAddress: {
          rules: {
            title: 'EmailAddress',
            stop: true,
            required: {
              message: 'Please enter an email address.',
            },
            email: {
              message: 'Please enter a valid email address.',
            },
          },
          value: '',
        },
        ConfirmEmailAddress: {
          rules: {
            title: 'ConfirmEmailAddress',
            stop: true,
            required: {
              message: 'Please confirm your email address.',
            },
            email: {
              message: 'Please enter a valid email address.',
            },
            compare: {
              message: 'Email addresses do not match.',
              field: 'EmailAddress',
              comparison: () => {
                return this.state.aboutYouForm.EmailAddress.value;
              },
            },
          },
          value: '',
        },
        HomeNumber: {
          rules: {
            title: 'HomeNumber',
            stop: false,
            format: {
              regex: RULES.daytimePhoneNumber,
              message: 'Please enter a valid home phone number.',
            },
          },
          value: '',
        },
      },
    };
    resetUserDetails();
    resetPreferences();
    resetUserManagement();
    stateUtility.setGlobalValue({ storage: storage.name });
  }

  updateMarketingPreferences = e => {
    const { name, value } = e.target;
    const { marketingPreferences } = this.state;
    marketingPreferences[name] = value;

    let { marketingPreferencesForm } = this.state;

    marketingPreferencesForm = Validate.input(
      name,
      value,
      marketingPreferencesForm,
    );

    this.setState({
      ...this.state,
      marketingPreferencesForm,
      marketingPreferences,
      message: null,
    });
  };

  componentWillUnmount() {
    const {
      resetPreferences,
      resetUserDetails,
      resetUserManagement,
      resetComponentState,
    } = this.props;
    resetPreferences();
    resetUserDetails();
    resetUserManagement();
    resetComponentState();
  }

  componentDidMount() {
    sharedUtility.setUrl('/personal-details/edit');
    const { fetchUserDetailsIfNeeded } = this.props;
    let request = {
      ExtendedProperties: {},
    };
    fetchUserDetailsIfNeeded(request);
  }

  componentDidUpdate(prevProps) {
    const { match, userDetails } = this.props;
    let {
      LocalUserDetails,
      marketingPreferencesForm,
      aboutYouForm,
    } = this.state;
    const prevMode =
      prevProps.match && prevProps.match.params.mode
        ? prevProps.match.params.mode
        : '';
    const mode = match && match.params.mode ? match.params.mode : '';
    if (mode !== prevMode) {
      this.setState({ localUserDetails: null, aboutYouData: {} });
    }
    if (userDetails && !LocalUserDetails) {
      marketingPreferencesForm = Validate.input(
        'preferences',
        userDetails?.ExtendedProperties?.MarketingPreference?.ContactPreference,
        marketingPreferencesForm,
      );

      this.setState({
        LocalUserDetails: userDetails,
        marketingPreferencesForm,
        aboutYouForm,
      });
    }
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const {
      resetUserDetails,
      resetPreferences,
      resetActivation,
      resetUserManagement,
      userManagementSuccess,
      setupUserManagementActivate,
      userManagementActivate,
      userDetails,
      updateState,
    } = nextProps;

    if (
      userManagementSuccess &&
      userManagementSuccess.ResponseStatus === 'RequireActivation'
    ) {
      resetUserManagement();
      updateState({
        RequireActivationTicket:
          userManagementSuccess && userManagementSuccess.RequireActivationTicket
            ? userManagementSuccess.RequireActivationTicket
            : '',
      });

      setupUserManagementActivate({
        RequireActivationTicket:
          userManagementSuccess && userManagementSuccess.RequireActivationTicket
            ? userManagementSuccess.RequireActivationTicket
            : '',
      });
    }
    if (userManagementSuccess && userManagementSuccess.ResponseStatus) {
      resetPreferences();
      return { updateStatus: userManagementSuccess.ResponseStatus };
    }
    if (
      userManagementActivate &&
      userManagementActivate.Status &&
      userManagementActivate.Status === 'Success'
    ) {
      resetPreferences();
      resetUserDetails();
      resetActivation();
      return { twoFAStatus: userManagementActivate.Status };
    }
    if (userDetails && !state.localUserDetails) {
      return {
        marketingPreferences: {
          post:
            userDetails.ExtendedProperties.MarketingPreference.Post === 'FALSE'
              ? false
              : true,
          email:
            userDetails.ExtendedProperties.MarketingPreference.Email === 'FALSE'
              ? false
              : true,
        },
        localUserDetails: userDetails,
      };
    }

    return null;
  };

  onChange = e => {
    const { name, value } = e.target;
    let { aboutYouData, aboutYouForm } = this.state;
    aboutYouData[name] = value;
    aboutYouForm = Validate.input(name, value, aboutYouForm);
    this.setState({ ...this.state, aboutYouForm, aboutYouData });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { aboutYouForm } = this.state;
    aboutYouForm = Validate.input(name, value, aboutYouForm, true);
    this.setState({ ...this.state, aboutYouForm });
  };

  updatePreferences = e => {
    e.preventDefault();
    const { aboutYouData } = this.state;
    const { setupUserDetails } = this.props;

    let { aboutYouForm, marketingPreferencesForm } = this.state;

    marketingPreferencesForm = Validate.form(marketingPreferencesForm);
    aboutYouForm = Validate.form(aboutYouForm);
    this.setState({
      ...this.state,
      aboutYouForm,
      marketingPreferencesForm,
    });
    if (!aboutYouForm.approved || !marketingPreferencesForm.approved) {
      window.scrollTo(0, 0);
      return;
    }

    let request = {
      EmailAddress: aboutYouData.EmailAddress,
      HomeNumber: aboutYouData.HomeNumber,
      ExtendedProperties: {
        PreferredContactMethod: marketingPreferencesForm.preferences.value,
      },
    };
    setupUserDetails(request);
  };

  render() {
    const { userDetails, error, match, systemDownStatus } = this.props;
    const {
      updateStatus,
      twoFAStatus,
      aboutYouForm,
      aboutYouData,
      marketingPreferencesForm,
    } = this.state;
    const { marketingPreferences } = this.state;

    const mode = match && match.params.mode ? match.params.mode : '';
    if (error) {
      return <Redirect to="/network-error?origin=editDetails" />;
    }
    if (
      (twoFAStatus && twoFAStatus === 'Success') ||
      (updateStatus && updateStatus === 'Success')
    ) {
      return <Redirect to="/personal-details/edit/success" />;
    }
    if (updateStatus && updateStatus === 'Failed') {
      return <Redirect to="/network-error?origin=editDetails" />;
    }

    if (!userDetails) {
      return null;
    }
    const combinedForm = { ...aboutYouForm, ...marketingPreferencesForm };

    return (
      <div id="personal-details">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={mode === 'view' ? 'Personal details' : 'Edit personal details'}
          metaDescription={
            mode === 'view' ? 'Personal details' : 'Edit personal details'
          }
        />
        {mode === 'view' && (
          <React.Fragment>
            <Content
              tag="h1"
              cmsTag="User-management:Personal-details:View:Index:View:h1"
              copytext="Personal details"
            />
            <Content
              tag="p"
              cmsTag="User-management:Personal-details:View:Index:View:p1"
              copytext={
                'If you wish to change any of your information simply click the "Edit personal details" button.'
              }
            />
            <div id="view-personal-details">
              <AboutYou userDetails={userDetails} />
              <YourAddress userDetails={userDetails} />
              <MarketingPreferences
                marketingPreferences={
                  userDetails?.ExtendedProperties?.MarketingPreference
                }
              />
              <div className="form-button-group">
                <Link
                  id="edit-details-submit"
                  className="btn btn-primary"
                  role="button"
                  to="/personal-details/edit"
                >
                  <Content
                    cmsTag="GLOBAL:Edit-your-personal-details"
                    copytext="Edit your personal details"
                  />
                </Link>
                <Link className="btn btn-secondary" role="button" to="/details">
                  <Content
                    cmsTag="GLOBAL:Back-to-my-details"
                    copytext="Back to my details"
                  />
                </Link>
              </div>
            </div>
          </React.Fragment>
        )}
        {mode === 'edit' &&
          (systemDownStatus?.shouldBlock ? (
            systemDownStatus.msg
          ) : (
            <React.Fragment>
              {systemDownStatus.msg}
              <Content
                tag="h1"
                cmsTag="User-management:Personal-details:Edit:Index:Edit:h1"
                copytext="Edit personal details"
              />
              <div className="mt-3 alert alert-info">
                <p>
                  <Content
                    cmsTag="User-management:Personal-details:View:Index:p1-part-1"
                    copytext="In order to change any of your contact details, you will need to enter an authentication code that we will send to the mobile number associated with your account. Please ensure that you have your mobile phone to hand."
                  />
                </p>
                <p>
                  <Content
                    cmsTag="User-management:Personal-details:View:Index:p1-part-1"
                    copytext="If you wish to change any other personal details, you can do so by calling us on "
                  />
                  <LinkTag id="phone-link" href={`tel:${CONTACTS.phoneLink}`}>
                    <Content
                      cmsTag="User-management:Personal-details:View:Index:send-a-message"
                      copytext={CONTACTS.phone}
                    />
                  </LinkTag>
                  <Content
                    cmsTag="User-management:Personal-details:View:Index:p1-part-1"
                    copytext=" or "
                  />
                  <Link
                    id="send-message"
                    to="/messaging-twoway/new/1/5?returnPage=personalDetails"
                  >
                    <Content
                      cmsTag="User-management:Personal-details:View:Index:send-a-message"
                      copytext="send us a secure message"
                    />
                  </Link>
                  <Content
                    cmsTag="User-management:Personal-details:View:Index:p1-part-2"
                    copytext="."
                  />
                </p>
              </div>

              <FormErrorList
                validations={combinedForm}
                disabled={false}
                required={true}
                groupClassName=""
                title="h2"
                showErrors={true}
              />
              <Form
                autoComplete="off"
                id="edit-personal-details"
                onSubmit={this.updatePreferences}
              >
                <EditAboutYou
                  userDetails={userDetails}
                  aboutYouForm={aboutYouForm}
                  aboutYouData={aboutYouData}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                />
                <YourAddress userDetails={userDetails} />
                <EditMarketingPreferences
                  marketingPreferences={marketingPreferences}
                  marketingPreferencesForm={marketingPreferencesForm}
                  updateMarketingPreferences={this.updateMarketingPreferences}
                />
                <div className="form-button-group">
                  {mode === 'edit' && (
                    <Button type="submit" id="save-changes" color="primary">
                      <Content
                        cmsTag="GLOBAL:Save-changes"
                        copytext="Save changes"
                      />
                    </Button>
                  )}
                  {mode === 'edit' && (
                    <Link
                      id="back-to-personal-detail"
                      className="btn btn-secondary"
                      role="button"
                      to="/personal-details/view"
                    >
                      <Content
                        cmsTag="GLOBAL:Back-to-personal-details"
                        copytext="Back to personal details"
                      />
                    </Link>
                  )}
                </div>
              </Form>
            </React.Fragment>
          ))}
      </div>
    );
  }
}

View.propTypes = {
  contactOptions: PropTypes.object,
  fetchContactOptionsIfNeeded: PropTypes.func,
  fetchUserDetailsIfNeeded: PropTypes.func,
  resetContactOptions: PropTypes.any,
  setUserDetailSuccess: PropTypes.any,
  userManagementSuccess: PropTypes.any,
  userManagementActivate: PropTypes.any,
  resetUserDetails: PropTypes.any,
  setupUserDetails: PropTypes.any,
  resetPreferences: PropTypes.any,
  resetUserManagement: PropTypes.func,
  resetActivation: PropTypes.func,
  setupUserManagementActivate: PropTypes.func,
  resetComponentState: PropTypes.func,
  stateData: PropTypes.any,
  userDetails: PropTypes.object,
  urlParams: PropTypes.object,
  error: PropTypes.any,
  match: PropTypes.object,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const { userManagementDefinition, customUserManagementDefinition } = state;
  return {
    ...userManagementDefinition,
    ...customUserManagementDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      { ...mapDispatchToProps, ...brandUserManagementDispatch },
    )(View),
  ),
  storage.name,
);
