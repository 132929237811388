import messageAttachmentDefinition from './reducers/messageAttachmentDefinition';

const custombrandMessageAttachmentDefinition = (state = {}, action) => {
  let newState = state;
  newState = messageAttachmentDefinition(newState, action);

  return newState;
};

export default custombrandMessageAttachmentDefinition;
