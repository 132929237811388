import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';
import { Content } from '@myie/interact-dom';

class PaymentBreadcrumbs extends React.Component {
  render() {
    const { stage } = this.props;

    return (
      <React.Fragment>
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={stage === 'MakePayment'}
              className={
                this.state.stage === 'ReviewPayment' ||
                this.state.stage === 'CompletePayment' ||
                this.state.stage === 'SuccessfulPayment'
                  ? 'completed'
                  : ''
              }
            >
              {stage === 'MakePayment' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'ReviewPayment' ||
              this.state.stage === 'CompletePayment' ||
              this.state.stage === 'SuccessfulPayment' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Transfer-details"
                copytext="Transfer details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem
              active={stage === 'ReviewPayment'}
              className={
                this.state.stage === 'CompletePayment' ||
                this.state.stage === 'SuccessfulPayment'
                  ? 'completed'
                  : ''
              }
            >
              {stage === 'ReviewPayment' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'CompletePayment' ||
              this.state.stage === 'SuccessfulPayment' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content cmsTag="BREADCRUMB:Review" copytext="Review" />
            </BreadcrumbItem>
            <BreadcrumbItem
              active={stage === 'CompletePayment'}
              className={
                this.state.stage === 'SuccessfulPayment' ? 'completed' : ''
              }
            >
              {stage === 'CompletePayment' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'SuccessfulPayment' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-3-" copytext="Step 3 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Verification"
                copytext="Verification"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'SuccessfulPayment'}>
              {stage === 'SuccessfulPayment' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-4-" copytext="Step 4 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                4:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Confirmation"
                copytext="Confirmation"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
      </React.Fragment>
    );
  }
}

PaymentBreadcrumbs.propTypes = {
  stage: PropTypes.any,
};

export default PaymentBreadcrumbs;
