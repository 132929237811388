import { CONSTANTS } from '@myie/interact-dom';
/* Simple shared functions to help servicing */

export const accountFilter = (
  accounts,
  allProducts,
  from = false,
  to = false,
  isNoticetoWithdraw = false,
) => {
  if (accounts) {
    return accounts.filter(account => {
      const {
        ExtendedProperties: {
          AccountMode,
          ProductCode,
          DepositsAllowed,
          NoticeToWithdraw,
        } = {},
        AccountStatus,
      } = account;
      const product = allProducts.find(
        product => product.Product_Code === ProductCode,
      );

      const commonCodition = AccountStatus !== 'Closed';

      const noticeToWithdrawCondition = isNoticetoWithdraw
        ? (AccountMode === CONSTANTS.ACCOUNT_TRANSACT ||
            AccountMode === CONSTANTS.ACCOUNT_VIEW_ONLY) &&
          NoticeToWithdraw === CONSTANTS.NOTICE_TO_WITHDRAW
        : AccountMode === CONSTANTS.ACCOUNT_TRANSACT;

      if (from) {
        return (
          product?.Max_Withdrawals_Per_Year !== 'NONE' &&
          commonCodition &&
          noticeToWithdrawCondition
        );
      }

      if (to) {
        return (
          commonCodition &&
          (AccountMode === CONSTANTS.ACCOUNT_TRANSACT ||
            AccountMode === CONSTANTS.ACCOUNT_VIEW_ONLY) &&
          DepositsAllowed
        );
      }
      return false;
    });
  }
  return [];
};

export const accountsEligibleToTranfer = (
  fromAccounts = null,
  toAccounts = null,
) => {
  if (fromAccounts && toAccounts) {
    return (
      !(fromAccounts.length && toAccounts.length) ||
      (fromAccounts.length === 1 &&
        toAccounts.length === 1 &&
        fromAccounts[0].AccountKey.Key === toAccounts[0].AccountKey.Key)
    );
  }
};
