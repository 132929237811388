const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition = {} } = state;
  const {
    cancellationResponse,
    createNoticeParam,
    makeWithdrawResponse,
    makeADepositWorldpay,
    savingshortapply,
    allProducts,
    withdrawalParam,
    createResponse,
    depositDetails,
    budgetPlanner,
    internalTransfer,
    allDepositProducts,
    createGoalDetails,
    preservedSavingsGoalState,
    saveShortApplyResponse,
    externalTransfer,
    depositProduct,
    updateGoalDetails,
    deleteGoalDetails,
    summaryBox,
    viewGoalDetails,
    bannerMessageArray,
    setMaturityDeposit,
    isFetching,
    closingBalResponse,
    error = null,
  } = custombrandSavingsServicingDefinition;

  return {
    cancellationResponse,
    internalTransfer,
    createNoticeParam,
    withdrawalParam,
    makeWithdrawResponse,
    makeADepositWorldpay,
    savingshortapply,
    allProducts,
    isFetching,
    createResponse,
    depositDetails,
    budgetPlanner,
    allDepositProducts,
    createGoalDetails,
    preservedSavingsGoalState,
    saveShortApplyResponse,
    externalTransfer,
    depositProduct,
    updateGoalDetails,
    deleteGoalDetails,
    summaryBox,
    viewGoalDetails,
    bannerMessageArray,
    setMaturityDeposit,
    closingBalResponse,
    error,
  };
};

export default mapStateToProps;
