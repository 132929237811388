import React from 'react';
import PropTypes from 'prop-types';

import {
  Content,
  FormattedCurrency,
  CONSTANTS,
  FormattedDate,
} from '@myie/interact-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapStateToProps } from '@myie/interact-accounts';

class MortgageDetailSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { account, tabAccounts } = this.props;
    const interestRate = account.InterestRate;
    const {
      ExtendedProperties: {
        CurrentBalance = 0,
        LoanAmount,
        RemainingTerm = 0,
        OriginalTerm = 0,
      } = {},
    } = account;
    const convertedValue =
      interestRate && Number(interestRate) ? Number(interestRate) : Number(0);

    if (tabAccounts && tabAccounts.length > 1) {
      return (
        <React.Fragment>
          <Row tag="dl" title="Account summary details">
            <Col tag="dt" xs={12} md={5} lg={4}>
              <Content
                cmsTag="GLOBAL:Current-balance"
                copytext="Current balance"
              />
            </Col>
            <Col
              id="sum-of-current-balance-amount"
              tag="dd"
              xs={12}
              md={7}
              lg={8}
            >
              <FormattedCurrency
                quantity={CurrentBalance}
                currency={account.CurrencyCode}
              />
            </Col>
            <Col id="remaining-terms" tag="dt" xs={12} md={5} lg={4}>
              <Content
                cmsTag="GLOBAL:Remaining-terms"
                copytext="Remaining terms"
              />
              <Content
                tagClassName="d-block"
                tag="small"
                cmsTag="GLOBAL:Remaining-terms-description"
                copytext="(This is not a redemption figure)"
              />
            </Col>
            {RemainingTerm && (
              <Col id="remaining-terms-value" tag="dd" xs={12} md={7} lg={8}>
                <Content
                  cmsTag="Account-type-mortgage:Mortgage-detail-summary:Max-remaining-terms-month"
                  copytext={`${RemainingTerm}`}
                />
              </Col>
            )}
            <Col tag="dt" xs={12} md={5} lg={4}>
              <Content cmsTag="GLOBAL:Loan-amount" copytext="Loan amount" />
            </Col>
            <Col id="loan-amount" tag="dd" xs={12} md={7} lg={8}>
              <FormattedCurrency quantity={LoanAmount} currency={'GBP'} />
            </Col>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Row tag="dl" title="Account summary details">
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Current-balance"
              copytext="Current balance"
            />
          </Col>
          <Col id="current-balance-amount" tag="dd" xs={12} md={7} lg={8}>
            <FormattedCurrency
              quantity={CurrentBalance}
              currency={account.CurrencyCode}
            />
          </Col>
          <Col id="remaining-terms" tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Remaining-terms"
              copytext="Remaining terms"
            />
            <Content
              tagClassName="d-block"
              tag="small"
              cmsTag="GLOBAL:Remaining-terms-description"
              copytext="(This is not a redemption figure)"
            />
          </Col>
          <Col id="remaining-terms-value" tag="dd" xs={12} md={7} lg={8}>
            <Content
              cmsTag="Account-type-mortgage:Mortgage-detail-summary:Remaining-terms-months"
              copytext={`${RemainingTerm}`}
            />
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Loan-amount" copytext="Loan amount" />
          </Col>
          <Col id="loan-amount" tag="dd" xs={12} md={7} lg={8}>
            <FormattedCurrency
              quantity={LoanAmount}
              currency={account.CurrencyCode}
            />
          </Col>

          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Original-term" copytext="Original term" />
          </Col>
          <Col id="original-term" tag="dd" xs={12} md={7} lg={8}>
            <Content
              cmsTag="Account-type-mortgage:Mortgage-detail-summary:Original-terms-months"
              copytext={`${OriginalTerm}`}
            />
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Term-start-date"
              copytext="Term start date"
            />
          </Col>
          <Col id="term-start-date" tag="dd" xs={12} md={7} lg={8}>
            <FormattedDate
              date={account.ExtendedProperties.TermStartDate}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Term-end-date" copytext="Term end date" />
          </Col>
          <Col id="term-end-date" tag="dd" xs={12} md={7} lg={8}>
            <FormattedDate
              date={account.ExtendedProperties.TermEndDate}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Mortgage-description"
              copytext="Mortgage description"
            />
          </Col>
          <Col id="product-description" tag="dd" xs={12} md={7} lg={8}>
            {account.ExtendedProperties.ProductDescription}
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Interest-rate" copytext="Interest rate" />
          </Col>
          <Col id="interest-rate-value" tag="dd" xs={12} md={7} lg={8}>
            {convertedValue}%
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

MortgageDetailSummary.propTypes = {
  account: PropTypes.any,
  tabAccounts: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MortgageDetailSummary);
