import React from 'react';
import PropTypes from 'prop-types';
import { Content, Markdown, CONTACTS } from '@myie/interact-dom';
import { Card, Row, Col, CardBody, CardHeader, CardFooter } from 'reactstrap';

class Modals extends React.Component {
  constructor() {
    super();

    // Define the initial state:
    this.state = {
      testdata: '',
    };
  }

  render() {
    //const { i18n } = this.props;

    return (
      <section id="panels-section">
        <p>
          Defined within{' '}
          <code>
            <strong>sass/components/cards.scss</strong>
          </code>
        </p>
        <Row>
          <Col xs="3">
            <Card color="primary">
              <CardHeader>
                <Content
                  tag="h2"
                  cmsTag="Help-contact-sidebar:h2-1"
                  copytext="Help"
                />
              </CardHeader>
              <CardBody>
                <p>
                  <Markdown
                    template={{
                      markdown: {
                        phone: CONTACTS.phone,
                        phoneLink: CONTACTS.phoneLink,
                        email: CONTACTS.email,
                        openTimes: CONTACTS.openTimes,
                      },
                    }}
                    cmsTag="Help-contact-sidebar:p1"
                    markdown={`If you need any help please <a target="_blank" href="#">consult our FAQs<span class="sr-only"> (opens in a new tab)</span></a>, contact us on <a href="mailto:$[email]">$[email]</a> or <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
                  />
                </p>
              </CardBody>
              <CardFooter>
                <a className="btn btn-primary" href="/">
                  Send a message
                </a>
              </CardFooter>
            </Card>
          </Col>
          <Col xs="3">
            <Card color="secondary">
              <CardHeader>
                <h2>
                  <Content id="Help-contact-sidebar:h2-1" copytext="Help" />
                </h2>
              </CardHeader>
              <CardBody>
                <p>
                  <Markdown
                    template={{
                      markdown: {
                        phone: CONTACTS.phone,
                        phoneLink: CONTACTS.phoneLink,
                        email: CONTACTS.email,
                        openTimes: CONTACTS.openTimes,
                      },
                    }}
                    cmsTag="Help-contact-sidebar:p1"
                    markdown={`If you need any help please <a target="_blank" href="#">consult our FAQs<span class="sr-only"> (opens in a new tab)</span></a>, contact us on <a href="mailto:$[email]">$[email]</a> or <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
                  />
                </p>
              </CardBody>
              <CardFooter>
                <a className="btn btn-primary" href="/">
                  Send a message
                </a>
              </CardFooter>
            </Card>
          </Col>
          <Col xs="3">
            <Card color="light">
              <CardHeader>
                <h2>
                  <Content id="Help-contact-sidebar:h2-1" copytext="Help" />
                </h2>
              </CardHeader>
              <CardBody>
                <p>
                  <Markdown
                    template={{
                      markdown: {
                        phone: CONTACTS.phone,
                        phoneLink: CONTACTS.phoneLink,
                        email: CONTACTS.email,
                        openTimes: CONTACTS.openTimes,
                      },
                    }}
                    cmsTag="Help-contact-sidebar:p1"
                    markdown={`If you need any help please <a target="_blank" href="#">consult our FAQs<span class="sr-only"> (opens in a new tab)</span></a>, contact us on <a href="mailto:$[email]">$[email]</a> or <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
                  />
                </p>
              </CardBody>
              <CardFooter>
                <a className="btn btn-primary" href="/">
                  Send a message
                </a>
              </CardFooter>
            </Card>
          </Col>
          <Col xs="3">
            <Card color="dark">
              <CardHeader>
                <h2>
                  <Content id="Help-contact-sidebar:h2-1" copytext="Help" />
                </h2>
              </CardHeader>
              <CardBody>
                <p>
                  <Markdown
                    template={{
                      markdown: {
                        phone: CONTACTS.phone,
                        phoneLink: CONTACTS.phoneLink,
                        email: CONTACTS.email,
                        openTimes: CONTACTS.openTimes,
                      },
                    }}
                    cmsTag="Help-contact-sidebar:p1"
                    markdown={`If you need any help please <a target="_blank" href="#">consult our FAQs<span class="sr-only"> (opens in a new tab)</span></a>, contact us on <a href="mailto:$[email]">$[email]</a> or <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
                  />
                </p>
              </CardBody>
              <CardFooter>
                <a className="btn btn-primary" href="/">
                  Send a message
                </a>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </section>
    );
  }
}

Modals.propTypes = {
  items: PropTypes.string,
};

export default Modals;
