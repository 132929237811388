import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoalDetails from './GoalDetails';

class DetailScreen extends Component {
  submit = () => {
    const { updateUrl, setStateDynamic } = this.props;
    let stateData = {
      formData: this.props.formData,
      url: {
        pageStage: 'ReviewAndSubmit',
      },
    };
    updateUrl(stateData);
    setStateDynamic({
      stage: 'ReviewAndSubmit',
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      formData = {},
      options,
      account,
      getBackButton,
      saveData,
      page,
      updateUrl,
      setStage,
    } = this.props;
    if (!account) {
      return null;
    }

    return (
      <React.Fragment>
        <GoalDetails
          formData={formData}
          saveForBudgetCalculation={this.props.saveForBudgetCalculation}
          getBackButton={getBackButton}
          page={page}
          updateUrl={updateUrl}
          setStage={setStage}
          options={options}
          account={account}
          saveData={saveData}
          submit={this.submit}
        />
      </React.Fragment>
    );
  }
}

DetailScreen.propTypes = {
  submit: PropTypes.func,
  page: PropTypes.string,
  saveForBudgetCalculation: PropTypes.func,
  getBackButton: PropTypes.func,
  setStage: PropTypes.func,
  setStateDynamic: PropTypes.func,
  updateUrl: PropTypes.func,
  formData: PropTypes.any,
  options: PropTypes.any,
  match: PropTypes.any,
  saveData: PropTypes.any,
  account: PropTypes.any,
};

export default DetailScreen;
