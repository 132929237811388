import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { CONTACTS } from '@myie/interact-local-dom';
import { FormattedDate, Content, AppMeta, Markdown } from '@myie/interact-dom';
import FormatBody from '../../FormatBody';
import messagingUtility from './../../utility';
class ReplyConfirmation extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  render() {
    const { replyMessageStatus } = this.props;
    let Conversation;

    let messages = [];
    if (!replyMessageStatus) {
      return null;
    } else {
      Conversation = replyMessageStatus.Conversation;
    }

    if (Conversation && Conversation.Messages) {
      messages = Conversation.Messages;
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Message sent"
          metaDescription="Message sent"
        />
        <h1>
          {replyMessageStatus &&
          replyMessageStatus.ResponseStatus === 'Success' ? (
            <React.Fragment>
              <Content
                cmsTag="Messaging-two-way:Conversation:Reply:Confirmation:h1-part-1"
                copytext='Reply sent to "'
              />

              {messagingUtility.stripEndQuotes(
                Conversation.Reason.replace(/\\/g, ''),
              )}

              <Content
                cmsTag="Messaging-two-way:Conversation:Reply:Confirmation:h1-part-2"
                copytext='"'
              />
            </React.Fragment>
          ) : (
            ''
          )}
          {replyMessageStatus &&
          replyMessageStatus.ResponseStatus === 'Failed' ? (
            <React.Fragment>
              <Content
                cmsTag="Messaging-two-way:Conversation:Reply:Confirmation:Failed:h1"
                copytext="Reply failed"
              />
            </React.Fragment>
          ) : (
            ''
          )}
        </h1>

        <Markdown
          cmsTag="Messaging-two-way:Conversation:Reply:Confirmation:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
            },
          }}
          markdown={`\nThank you for your message. We aim to reply within five working days. If your query is of a more urgent matter, please call us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
        />

        <Row
          tag="dl"
          title="Message details"
          className="message-details-header mb-5"
        >
          {Conversation.AccountNumber !== 'XXXXXXXXXable' &&
          Conversation.AccountNumber !== 'Not available' ? (
            <React.Fragment>
              <Col tag="dt" xs={12} sm={6} md={3}>
                <Content
                  cmsTag="GLOBAL:Related-account"
                  copytext="Related account"
                />
              </Col>
              <Col tag="dd" xs={12} sm={6} md={9}>
                <React.Fragment>
                  <Content
                    cmsTag="GLOBAL:Account-ending-"
                    copytext="Account ending "
                  />
                  {Conversation.AccountNumber
                    ? Conversation.AccountNumber.substr(-4)
                    : ''}
                </React.Fragment>
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:reference-number"
              copytext="Reference number"
            />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            {Conversation.Reference}
          </Col>
        </Row>
        <div className="list-group messages-list">
          {messages.map((item, index) => {
            return (
              <div className="list-group-item" key={`message-${index}`}>
                <h2>
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:Sent-by-" copytext="Sent by " />
                  </span>
                  {item.Sender.SenderType === 'User' ? (
                    `${item.Sender.SenderName}`
                  ) : (
                    <Markdown
                      template={{
                        markdown: {
                          companyNameShorthand: CONTACTS.companyNameShorthand,
                        },
                      }}
                      cmsTag="GLOBAL:Company-name"
                      markdown={`$[companyNameShorthand]`}
                    />
                  )}
                  <span className="timestamp">
                    <FormattedDate
                      date={item.CreatedDate}
                      format={'Do MMMM h:mma'}
                    />
                  </span>
                </h2>
                {FormatBody(item.Body)}
              </div>
            );
          })}
        </div>
        <div className="form-button-group">
          <Link
            id="messaging-twoway-message-back-to-link"
            className="btn btn-secondary"
            role="button"
            to="/messaging-twoway/view"
          >
            <Content
              cmsTag="GLOBAL:Back-to-message-list"
              copytext="Back to message list"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

ReplyConfirmation.propTypes = {
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  replyMessageStatus: PropTypes.any,
  transactionData: PropTypes.any,
  transfer: PropTypes.any,
};

const mapStateToProps = state => {
  const { messagingTwowayDefinition, accountsDefinition } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToPropsMessaging, ...mapDispatchToPropsAccounts },
)(ReplyConfirmation);
