import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Content, Markdown, DropDown } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import TransferForm from './TransferForm';

class Create extends Component {
  constructor(props) {
    super(props);
    const { allProducts, getAllProducts } = this.props;
    this.state = {
      account: null,
      toAccount: null,
    };
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  submitForm = form => {
    const { submitForm } = this.props;
    submitForm('ReviewTransfer', form);
  };

  accountOptions(prefix, item, index, extendedId) {
    return (
      <option
        id={`transfer-dropdown-${extendedId}-option-${index}`}
        key={prefix + item.AccountKey.Context}
        value={item.AccountKey.Key}
      >
        {item.AccountName} {item.AccountNumber}
      </option>
    );
  }

  render() {
    const {
      onChangeFromAccount,
      onChangeToAccount,
      //  isFetching,
      data,
      accountForm,
      callAccountValidations,
      setStateDynamic,
      allProducts,
      fromAccounts,
      toAccounts,
    } = this.props;

    if (!allProducts) {
      return null;
    }

    return (
      <React.Fragment>
        <Content
          tag="h2"
          cmsTag="Savings-servicing:Transfer:Create-transfer:Create:h2-1"
          copytext="Transfer details"
        />
        <Markdown
          cmsTag="Savings-servicing:Transfer:Create-transfer:Create:p1"
          markdown={`\nWe will need some information to transfer your money.\n\nSome of our savings accounts are subject to conditions, such as notice periods, withdrawal restrictions and no deposits.\n\nPlease check the terms and conditions of both your savings accounts to ensure you can withdraw and deposit money. You can find these in the "Account Details" section.`}
        />
        <DropDown
          cmsTag="Accounts:Components:from-account-dropdown.accountType"
          label={'Account to transfer from'}
          id="transfer-from-dropdown"
          key={Date.now() + '1'} //Force redraw when refreshing due to a bug in the core dropdown component
          field="accountFormValidation"
          onChange={onChangeFromAccount}
          validation={accountForm.accountFormValidation}
        >
          <option
            id={`transfer-from-dropdown-default`}
            key="0"
            value=""
            defaultText="Please select"
          ></option>
          {fromAccounts.map((item, index) => {
            return this.accountOptions('accountType', item, index, 'from');
          })}
        </DropDown>
        <DropDown
          cmsTag="Accounts:Components:to-account-dropdown.accountType"
          label={'Account to transfer to'}
          id="transfer-to-dropdown"
          key={Date.now() + '2'} //Force redraw when refreshing due to a bug in the core dropdown component
          field="toAccountFormValidation"
          onChange={onChangeToAccount}
          validation={accountForm.toAccountFormValidation}
        >
          <option
            id={`transfer-to-dropdown-default`}
            key="0"
            value=""
            defaultText="Please select"
          ></option>
          {toAccounts.map((item, index) => {
            return this.accountOptions('accountType', item, index, 'to');
          })}
        </DropDown>
        <TransferForm
          account={this.props.account}
          accountForm={accountForm}
          callAccountValidations={callAccountValidations}
          saveData={this.props.saveData}
          data={data}
          setStateDynamic={setStateDynamic}
          toAccount={this.props.toAccount}
          onSubmit={this.submitForm}
        />
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  data: PropTypes.any,
  saveData: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  callAccountValidations: PropTypes.func,
  setStateDynamic: PropTypes.func,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  combineForm: PropTypes.any,
  actionDate: PropTypes.any,
  onChangeFromAccount: PropTypes.func,
  onChangeToAccount: PropTypes.func,
  onCancelled: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  toAccount: PropTypes.any,
  account: PropTypes.any,
  accountForm: PropTypes.any,
  fromMatch: PropTypes.any,
  submitForm: PropTypes.any,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  fromAccounts: PropTypes.array,
  toAccounts: PropTypes.array,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(Create));
