import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, CardBody, CardHeader, CardFooter } from 'reactstrap';
import Balance from './Balance';
import Interest from './Interest';
import Overview from './Overview';
import Links from './Links';
import { Content, CONSTANTS } from '@myie/interact-dom';

class SavingsSummary extends React.Component {
  render() {
    let colSize = this.props.mode === 1 ? 12 : 6;

    const { account, loopIndex, status, pageMode = 'summary' } = this.props;
    const zeroBalance =
      account.ExtendedProperties &&
      account.ExtendedProperties.AvailableBalance === 0;
    const zeroInterest = !account.ExtendedProperties.Aer;

    const isClosed =
      account.AccountStatus === 'Closed' ||
      account.ExtendedProperties?.AccountMode === CONSTANTS.ACCOUNT_CLOSED;
    const hideBalance = zeroBalance && isClosed ? true : false;
    const hideInterest = zeroInterest && isClosed ? true : false;
    const accountType =
      account.ExtendedProperties && account.ExtendedProperties.AccountType
        ? account.ExtendedProperties.AccountType
        : '';

    return (
      <Card
        body
        className="account-card account-summary"
        id={`account-type-savings-summary-${loopIndex}`}
      >
        <React.Fragment>
          <CardHeader>
            {pageMode === 'detail' ? (
              <h1 id={`account-type-savings-title-${loopIndex}`}>
                {account.AccountName}
              </h1>
            ) : (
              <h2 id={`account-type-savings-title-${loopIndex}`}>
                {account.AccountName}
              </h2>
            )}
            <p className="account-number">
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="GLOBAL:Account-number-"
                copytext="Account number "
              />
              {account && account.AccountNumber ? account.AccountNumber : ''}
            </p>
          </CardHeader>
          <CardBody>
            {account.ExtendedProperties.CompanyName ? (
              <p className="account-name">
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:Company-name"
                  copytext="Company name"
                />
                <Content
                  cmsTag="Account-type-savings:Savings-summary:-COMPANY-NAME"
                  template={{
                    copytext: {
                      CompanyName: account.CompanyName,
                    },
                  }}
                  copytext=" $[CompanyName]"
                />
              </p>
            ) : (
              ''
            )}
            <Row>
              <Col xs={12} md={colSize}>
                {!hideBalance || !hideInterest ? (
                  <Card className="balance-card">
                    <CardBody>
                      <Row tag="dl" title="Account details">
                        <React.Fragment>
                          {!hideBalance ? (
                            <Balance loopIndex={loopIndex} account={account} />
                          ) : (
                            ''
                          )}
                          {!hideInterest ? (
                            <Interest loopIndex={loopIndex} account={account} />
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      </Row>
                    </CardBody>
                  </Card>
                ) : (
                  ''
                )}
              </Col>

              <Col xs={12} md={colSize}>
                <Overview
                  loopIndex={loopIndex}
                  account={account}
                  pageMode={pageMode}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Links
              accountName={account.AccountName}
              pageMode={pageMode}
              loopIndex={loopIndex}
              accountKey={account.AccountKey.Key}
              accountStatus={account.AccountStatus}
              accountType={accountType}
              status={status}
              account={account}
            />
          </CardFooter>
        </React.Fragment>
      </Card>
    );
  }
}

SavingsSummary.propTypes = {
  account: PropTypes.any,
  pageMode: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  status: PropTypes.number,
};

export default SavingsSummary;
