import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';
import { Col, Row, Button } from 'reactstrap';

class AddNominatedAccountSuccess extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    const { changeUrl } = this.props;
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { match, data } = this.props;
    return (
      <React.Fragment>
        <div className="confirm-account-details-add-nominated-account">
          <Content
            tag="p"
            cmsTag="Accounts:Nominated-account:Edit-nominated-account:Success:p1"
            copytext="You have successfully updated your nominated account details."
          />
          <Row tag="dl" title="Add nominated account details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Name-of-account-holder"
                copytext="Name of account holder"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['accountHolderName'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Account-number"
                copytext="Account number"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['accountNumber'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['sortcode'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Payee-reference"
                copytext="Payee reference"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['payeeReference'] : ''}
            </Col>
          </Row>
        </div>
        <div className="form-button-group">
          {this.getBackButton(match && match.params ? match.params.page : '')}
        </div>
      </React.Fragment>
    );
  }
}

AddNominatedAccountSuccess.propTypes = {
  page: PropTypes.any,
  data: PropTypes.any,
  changeUrl: PropTypes.func,
  match: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddNominatedAccountSuccess);
