import React from 'react';
import TransferWidgetTest from './TransferWidgetTest';
import { PrivateRoute } from '@myie/interact-dom';

const Routes = [
  <PrivateRoute
    key="/transfer-widget-test"
    path="/transfer-widget-test"
    component={TransferWidgetTest}
  />,
];

export default Routes;
