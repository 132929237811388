import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  //CONSTANTS,
  DropDown,
} from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import TransferForm from './TransferForm';

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      toAccount: null,
    };
  }

  submitForm = form => {
    const { submitForm } = this.props;
    submitForm('ReviewTransfer', form);
  };

  accountOptions(prefix, item, index, extendedId) {
    return (
      <option
        id={`transfer-dropdown-${extendedId}-option-${index}`}
        key={prefix + item.AccountKey.Context}
        value={item.AccountKey.Key}
      >
        {item.AccountName} {item.AccountNumber}
      </option>
    );
  }

  render() {
    const {
      onChangeFromAccount,
      onChangeToAccount,
      data,
      setStateDynamic,
      accountForm,
      fromAccounts,
      toAccounts,
    } = this.props;

    return (
      <React.Fragment>
        <DropDown
          cmsTag="GLOBAL:Transfer-from"
          label={'Transfer from'}
          id="transfer-from-dropdown"
          key={Date.now() + '1'} //Force redraw when refreshing due to a bug in the core dropdown component
          field="accountFormValidation"
          onChange={onChangeFromAccount}
          validation={accountForm.accountFormValidation}
        >
          <option
            id={`transfer-from-dropdown-default`}
            key="0"
            value=""
            defaultText="Please select"
          ></option>
          {fromAccounts.map((item, index) => {
            return this.accountOptions('accountType', item, index, 'from');
          })}
        </DropDown>

        <DropDown
          cmsTag="GLOBAL:Transfer-to"
          label={'Transfer to'}
          id="transfer-to-dropdown"
          key={Date.now() + '2'} //Force redraw when refreshing due to a bug in the core dropdown component
          field="toAccountFormValidation"
          onChange={onChangeToAccount}
          validation={accountForm.toAccountFormValidation}
        >
          <option
            id={`transfer-to-dropdown-default`}
            key="0"
            value=""
            defaultText="Please select"
          ></option>
          {toAccounts.map((item, index) => {
            return this.accountOptions('accountType', item, index, 'to');
          })}
        </DropDown>

        <TransferForm
          account={this.props.account}
          accountForm={accountForm}
          setStateDynamic={setStateDynamic}
          callAccountValidations={this.props.callAccountValidations}
          saveData={this.props.saveData}
          data={data}
          toAccount={this.props.toAccount}
          onSubmit={this.submitForm}
        />
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  accounts: PropTypes.any,
  data: PropTypes.any,
  setStateDynamic: PropTypes.any,
  saveData: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  callAccountValidations: PropTypes.func,
  match: PropTypes.any,
  actionDate: PropTypes.any,
  noticeFilter: PropTypes.func,
  onChangeFromAccount: PropTypes.func,
  onChangeToAccount: PropTypes.func,
  onCancelled: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  updateState: PropTypes.any,
  toAccount: PropTypes.any,
  account: PropTypes.any,
  accountForm: PropTypes.any,
  fromMatch: PropTypes.any,
  submitForm: PropTypes.any,
  getAllProducts: PropTypes.func,
  fromAccounts: PropTypes.array,
  toAccounts: PropTypes.array,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(Create));
