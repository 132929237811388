import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import {
  Content,
  Markdown,
  FormattedCurrency,
  CONSTANTS,
  FormattedDate,
} from '@myie/interact-dom';

class Success extends Component {
  submitForm = () => {
    const { submit } = this.props;
    submit();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.resetCreateGoals();
  }

  render() {
    const { goalName, account, page, getBackButton, mode = 'add' } = this.props;
    const { formData = {} } = this.props;
    const monthlyAmount = formData['monthlyAmount']
      .toString()
      .replace(/,/g, '');

    return (
      <React.Fragment>
        <h1>
          {mode === 'add' ? (
            <Markdown
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Review-and-submit:Add-screen:h1"
              markdown={`Your savings goal has been added`}
            />
          ) : (
            <Markdown
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Review-and-submit:Edit-screen:h1"
              markdown={`Your savings goal has been edited`}
            />
          )}
        </h1>
        <Row tag="dl" title={`Goal details`}>
          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Name-of-goal" copytext="Name of goal" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            {goalName}
          </Col>

          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Linked-account" copytext="Linked account" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            {account && account.AccountName ? account.AccountName : ''}
          </Col>

          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Amount-to-save" copytext="Amount to save" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedCurrency
              quantity={formData['amount'].toString().replace(/,/g, '')}
              currency={account.CurrencyCode}
            />
          </Col>

          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Goal-date" copytext="Goal date" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedDate
              date={formData['date']}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>

          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content
              cmsTag="GLOBAL:Monthly-contribution"
              copytext="Monthly contribution"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedCurrency quantity={monthlyAmount} currency="GBP" />
          </Col>
        </Row>
        <div className="form-button-group">{getBackButton(page)}</div>
      </React.Fragment>
    );
  }
}

Success.propTypes = {
  submit: PropTypes.func,
  mode: PropTypes.string,
  account: PropTypes.any,
  changeUrl: PropTypes.func,
  getBackButton: PropTypes.func,
  goalName: PropTypes.string,
  updateUrl: PropTypes.func,
  page: PropTypes.string,
  setStateDynamic: PropTypes.func,
  formData: PropTypes.object,
  resetCreateGoals: PropTypes.func,
};

export default Success;
