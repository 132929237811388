import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import {
  Currency,
  Content,
  DropDown /*, TextArea */,
} from '@myie/interact-dom';
import FrequencyDropdown from './frequencyDropdown';
import { Icon } from '@myie/interact-dom';

function ItemDebts(props) {
  const {
    //item,
    index,
    hyphenedName,
    itemName,
    form,
    onChange,
    onBlur,
    onItemDelete,
    // showNote,
    // onNoteClick,
    // onNoteChange,
    // onNoteSave,
    // budgetNoteMaxLength,
  } = props;

  return (
    <React.Fragment>
      <h4>{itemName}</h4>
      <Row>
        <Col xs={12} sm={6} md={4} lg={3}>
          <DropDown
            label={
              <React.Fragment>
                <Content
                  cmsTag="GLOBAL:What-"
                  copytext="What "
                  tag="span"
                  className="sr-only"
                />
                <Content cmsTag="GLOBAL:Debt-type" copytext="Debt type" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-is-ITEMNAME"
                  copytext={` is ` + itemName}
                />
              </React.Fragment>
            }
            id={`debts-` + hyphenedName + `-debt-type-` + index}
            field="type"
            value={form.type.value}
            validation={form.type}
            onChange={onChange}
            onBlur={onBlur}
            required
          >
            <option
              key="0"
              id={`debt-type-` + hyphenedName + `-` + index + `-default`}
              value=""
              defaultText="Please select"
            />
            <option
              key="1"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-1`}
              value="Benefits overpayment"
              defaultText="Benefits overpayment"
            />
            <option
              key="2"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-2`}
              value="Catalogue"
              defaultText="Catalogue"
            />
            <option
              key="3"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-3`}
              value="Council tax arrears"
              defaultText="Council tax arrears"
            />
            <option
              key="4"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-4`}
              value="Credit card"
              defaultText="Credit card"
            />
            <option
              key="5"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-5`}
              value="Family or friends loan"
              defaultText="Family or friends loan"
            />
            <option
              key="6"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-6`}
              value="Gas or electric arrears"
              defaultText="Gas or electric arrears"
            />
            <option
              key="7"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-7`}
              value="Hire purchase"
              defaultText="Hire purchase"
            />
            <option
              key="8"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-8`}
              value="Mortgage arrears"
              defaultText="Mortgage arrears"
            />
            <option
              key="9"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-9`}
              value="Overdraft"
              defaultText="Overdraft"
            />
            <option
              key="10"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-10`}
              value="Payday loan"
              defaultText="Payday loan"
            />
            <option
              key="11"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-11`}
              value="Rent arrears"
              defaultText="Rent arrears"
            />
            <option
              key="12"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-12`}
              value="Store card"
              defaultText="Store card"
            />
            <option
              key="13"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-13`}
              value="TV Licence arrears"
              defaultText="TV Licence arrears"
            />
            <option
              key="14"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-14`}
              value="Water arrears"
              defaultText="Water arrears"
            />
            <option
              key="15"
              id={`debt-type-` + hyphenedName + `-` + index + `-option-15`}
              value="Other"
              defaultText="Other"
            />
          </DropDown>
        </Col>
        <Col xs={12} sm={6} md={4} lg={3}>
          <Currency
            autoComplete="off"
            field="total_owed"
            dp={2}
            id={`debts-` + hyphenedName + `-total-owed-` + index}
            label={
              <React.Fragment>
                <Content cmsTag="GLOBAL:Total-owed" copytext="Total owed" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-for-ITEMNAME"
                  copytext={` for ` + itemName}
                />
              </React.Fragment>
            }
            value={form.total_owed.value}
            validation={form.total_owed}
            onChange={onChange}
            onBlur={onBlur}
            code="GBP"
            groupClassName="with-button"
            required
          />
          {/* Commented out for DAR
          <Button
            className="note-button"
            color={item.note ? 'success' : 'secondary'}
            id={`debts-` + hyphenedName + `-note-button-` + index}
            onClick={onNoteClick}
          >
            <Icon name="NoteIcon" />
            <span className="sr-only">
              <Content
                cmsTag="LABEL:Open-note:Variable"
                template={{
                  copytext: {
                    name: itemName,
                  },
                }}
                copytext="Open note for $[name]"
              />
            </span>
          </Button> */}
        </Col>
        <Col xs={12} sm={6} md={4} lg={3}>
          <Currency
            autoComplete="off"
            field="amount"
            dp={2}
            id={`debts-` + hyphenedName + `-amount-` + index}
            label={
              <React.Fragment>
                <Content
                  cmsTag="GLOBAL:Usual-payment"
                  copytext="Usual payment"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-for-ITEMNAME"
                  copytext={` for ` + itemName}
                />
              </React.Fragment>
            }
            value={form.amount.value}
            validation={form.amount}
            onChange={onChange}
            onBlur={onBlur}
            code="GBP"
            required
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className="align-items-center with-delete-button"
        >
          <FrequencyDropdown
            label={
              <React.Fragment>
                <Content cmsTag="GLOBAL:Frequency" copytext="Frequency" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-of-ITEMNAME"
                  copytext={` of ` + itemName}
                />
              </React.Fragment>
            }
            id={`debts-` + hyphenedName + `-frequency-` + index}
            frequency={form.frequency}
            onChange={onChange}
            onBlur={onBlur}
            groupClassName="with-button"
          />
          <Button
            id={`debts-` + hyphenedName + `-delete-button-` + index}
            color="danger"
            onClick={onItemDelete}
            className="delete-button"
          >
            <Icon name="Delete" />
            <span className="sr-only">
              <Content
                cmsTag="GLOBAL:Delete:Variable"
                copytext={`Delete ` + itemName}
              />
            </span>
          </Button>
        </Col>

        {/* Commented out for DAR
        {showNote && (
          <Col xs={12} md={12} lg={12}>
            <TextArea
              label={`Note for ` + itemName}
              id={`debts-` + hyphenedName + `-note-` + index}
              field="noteText"
              onChange={onNoteChange}
              onBlur={onNoteChange}
              value={form.noteText.value}
              validation={form.noteText}
              maxLength={budgetNoteMaxLength}
            />
            <Button
              id={`debts-` + hyphenedName + `-note-save-button-` + index}
              color="light"
              className="save-note-button"
              onClick={onNoteSave}
            >
              <Content cmsTag="LABEL:Save:Variable" copytext="Save" />
              <span className="sr-only"> {itemName}</span>
            </Button>
          </Col>
        )} */}
      </Row>
    </React.Fragment>
  );
}

ItemDebts.propTypes = {
  category: PropTypes.any,
  group: PropTypes.any,
  index: PropTypes.any,
  item: PropTypes.any,
  categoryIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hyphenedName: PropTypes.any,
  itemName: PropTypes.any,
  form: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onItemDelete: PropTypes.func,
  showNote: PropTypes.bool,
  onNoteClick: PropTypes.func,
  onNoteChange: PropTypes.func,
  onNoteSave: PropTypes.func,
  budgetNoteMaxLength: PropTypes.number,
};

export default ItemDebts;
