import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@myie/interact-dom';

class AboutYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      submitForm: {},
      userDetails: null,
      yourTitle: null,
      firstName: null,
      lastName: null,
    };
  }

  render() {
    const { aboutYouData, aboutYouForm, onChange, onBlur } = this.props;

    return (
      <div className="mt-5">
        <Text
          label="Email"
          cmsTag="GLOBAL:Enter-your-email-address"
          id="EmailAddress"
          field="EmailAddress"
          value={aboutYouData.EmailAddress ?? ''}
          maxLength={70}
          onChange={onChange}
          onBlur={onBlur}
          validation={aboutYouForm.EmailAddress}
        />
        <Text
          label="Confirm email"
          cmsTag="GLOBAL:Confirm-your-email-address"
          id="ConfirmEmailAddress"
          field="ConfirmEmailAddress"
          value={aboutYouData.ConfirmEmailAddress ?? ''}
          maxLength={70}
          onChange={onChange}
          onBlur={onBlur}
          validation={aboutYouForm.ConfirmEmailAddress}
        />
        <Text
          label="Home phone number"
          cmsTag="GLOBAL:Home-number"
          id="HomeNumber"
          field="HomeNumber"
          value={aboutYouData.HomeNumber ?? ''}
          maxLength={15}
          onChange={onChange}
          onBlur={onBlur}
          validation={aboutYouForm.HomeNumber}
        />
      </div>
    );
  }
}

AboutYou.propTypes = {
  onEvent: PropTypes.any,
  saveData: PropTypes.any,
  setForm: PropTypes.any,
  userDetails: PropTypes.any,
  aboutYouForm: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  aboutYouData: PropTypes.object,
};

export default AboutYou;
