import React, { Component } from 'react';
import PropTypes from 'prop-types';
import progressLogic from '../../progressLogic';
import { Icon } from '@myie/interact-dom';

class Progress extends Component {
  state = {
    data: [{ value: 20 } /* Calculate the amount paid in percentage here*/],
  };

  render() {
    const { goal = {}, account } = this.props;
    if (!goal || !account) {
      return null;
    }

    const { ExtendedProperties: { AvailableBalance } = {} } = account;
    let balance = AvailableBalance;

    if (
      account.ExtendedProperties.AvailableBalance &&
      account.ExtendedProperties.PendingBalance
    ) {
      balance =
        account.ExtendedProperties.AvailableBalance +
        account.ExtendedProperties.PendingBalance;
    }
    let heightCalculator = 0;
    if (balance > 0 && balance < goal.Amount) {
      let logic = (balance / goal.Amount) * 100;
      heightCalculator = (logic / 100) * 186;
    } else if (goal.Amount <= balance) {
      heightCalculator = 186;
    }

    let containerWidth = 86.67; /* max-width of graph */
    let containerHeight = 186; /* max-height of graph */
    let barHeight = heightCalculator; /* put calculated amount here */
    let barYAxis = containerHeight - barHeight;
    const goalStatus = progressLogic.getGoalStatus(
      balance,
      goal.Amount,
      goal.Monthly,
      goal.TargetDate,
    );
    let barGroups = this.state.data.map(d => (
      <rect
        key={d}
        x="0"
        y={barYAxis}
        width={containerWidth}
        height={balance === 0 ? 0 : barHeight}
      />
    ));
    return (
      <div className="goal-background">
        <Icon name="Thermometer" />
        <svg
          className={`${goalStatus} goal-chart`}
          width={containerWidth}
          height={containerHeight}
        >
          {barGroups}
        </svg>
      </div>
    );
  }
}

Progress.propTypes = {
  goal: PropTypes.object,
  account: PropTypes.object,
};

export default Progress;
