import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import PropTypes from 'prop-types';

class MoneyToolsMenuItems extends React.Component {
  hideMenu = () => {
    this.setState({ removeClass: !this.state?.removeClass });
  };

  render() {
    return (
      <Link
        id="money-tools-menu-item"
        className="nav-link"
        to="/money-tools/index"
        onClick={this.hideMenu}
      >
        <Content cmsTag="MENU:Money-tools" copytext="Money Tools" />
      </Link>
    );
  }
}
MoneyToolsMenuItems.propTypes = {
  fetchAccountsIfNeeded: PropTypes.func,
  accounts: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapSavingsServicingDispatchToProps, ...mapDispatchToProps },
)(withRouter(MoneyToolsMenuItems));
