import React from 'react';
import Accounts from './Accounts';
import { Route } from 'react-router-dom';
import { NominatedAccountRoute } from './NominatedAccount/Routes';

const Routes = [
  <Route key="/accounts" path="/accounts" component={Accounts} />,
  <Route
    key="/nominated-account-for-withdrawals"
    path="/nominated-account-for-withdrawals"
    component={NominatedAccountRoute}
  />,
];

export default Routes;
