import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { Validate } from '@myie/interact';
import { Row, Col, Form, Button, BreadcrumbItem } from 'reactstrap';
import {
  Content,
  FormattedDate,
  FormErrorList,
  CONSTANTS,
  FormattedCurrency,
  Check,
  Markdown,
} from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import AccountDescription from '../shortApply/AccountDescription';
import { CONTACTS } from '@myie/interact-local-dom';
import PropTypes from 'prop-types';
import requestPayload from '../shortApply/requestPayload';

class ShortApplyApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(props);
  }

  initialState = props => {
    const { /*formData, */ selectedProduct } = props;
    let data = null;
    // if ('accountPreferences' in formData) {
    //   data = formData['accountPreferences'];
    // }
    const formItems = {
      accuracyConfirm: {
        rules: {
          required: {
            message:
              'Please confirm that the information you have provided is accurate.',
          },
        },
        value: false,
      },
    };

    if (
      selectedProduct &&
      selectedProduct.InterestPaymentFrequency &&
      selectedProduct.InterestPaymentFrequency === 'both'
    ) {
      formItems.interestPaymentFrequency = {
        rules: {
          stop: true,
          required: {
            message:
              'Please tell us when you would like us to pay your interest.',
          },
        },
        value: data ? data.interestPaymentFrequency : '',
      };
    }
    return {
      data: data ? data : {},
      form: formItems,
      disabled: false,
    };
  };

  dropdownOptionValue(item, value) {
    let keys = Object.keys(item);
    if (keys[0] === value) {
      return item[keys[0]];
    }
  }

  componentDidUpdate() {
    // const { formData } = this.props;
    // const { data } = this.state;

    // if (
    //   !_.isEmpty(formData['accountPreferences']) &&
    //   JSON.stringify(data) !== JSON.stringify(formData['accountPreferences'])
    // ) {
    this.initialState(this.props);
    //}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data } = this.state;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;

    this.setState({ ...this.state, form });
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, checked } = e.target;
    let { form } = this.state;
    form = Validate.input(name, checked, form);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    e.preventDefault();
    const { userDetails } = this.props;
    let { saveShortApply, selectedProduct, formData } = this.props;
    let { form } = this.state;

    form = Validate.form(form);
    if (form.approved) {
      let request = requestPayload.getPayload(
        formData,
        selectedProduct,
        userDetails,
      );
      saveShortApply(request);
    } else {
      window.scrollTo(0, 0);
      this.setState({ disabled: false });
      this.setState({ form });
    }
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;

    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  dropdownOptions(item, index, name) {
    let keys = Object.keys(item);
    return (
      <option id={`${name}-option-${keys[0]}`} key={index} value={keys[0]}>
        {item[keys[0]]}
      </option>
    );
  }

  changeDetails = () => {
    const { formData, updateUrl, setStateDynamic } = this.props;
    setStateDynamic({
      stage: 'apply',
    });
    let stateData = {
      formData: formData,
      url: {
        pageStage: 'apply',
      },
    };
    setStateDynamic({ stage: 'apply' });
    updateUrl(stateData);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  changeStage = () => {
    const { setStateDynamic } = this.props;
    setStateDynamic({ stage: 'list' });
  };

  render() {
    const { selectedProduct, userDetails, formData } = this.props;
    if (!userDetails) {
      return null;
    }
    const {
      ExtendedProperties: {
        Gender,
        MaritalStatus,
        NINumber,
        ClientAddress,
      } = {},
    } = userDetails;
    const { form } = this.state;
    const maritalStatus = CONSTANTS.MARITAL_STATUS_CODE.find(status => {
      let keys = Object.keys(status);
      return keys[0] === MaritalStatus;
    });

    return (
      <div id="short-apply-application">
        <Form
          autoComplete="off"
          onSubmit={this.onSubmitHandler}
          className="short-apply-form"
        >
          <Content
            tag="h1"
            cmsTag="Savings-servicing:Shared:Short-apply-review:h1"
            template={{
              copytext: {
                productName: selectedProduct.Product_Name,
              },
            }}
            copytext="Apply for $[productName]"
          />

          <div id="apply-form">
            <div className="breadcrumb-container">
              <ol className="breadcrumb">
                <BreadcrumbItem className="completed">
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Completed"
                    copytext="Completed: "
                  />
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    2:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Account-details"
                    copytext="Account details"
                  />
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="BREADCRUMB:Active"
                    copytext="Active: "
                  />
                  <span className="d-inline d-lg-none d-xl-none">
                    <span className="sr-only">
                      <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                    </span>
                    <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                    2:&nbsp;
                  </span>
                  <Content
                    cmsTag="BREADCRUMB:Review-and-submit"
                    copytext="Review and submit"
                  />
                </BreadcrumbItem>
              </ol>
            </div>
            <div className="clear-both"></div>
            <FormErrorList
              validations={form}
              disabled={false}
              required={true}
              groupClassName=""
              title="h2"
              showErrors={true}
            />
            <AccountDescription selectedProduct={selectedProduct} />

            <div className="review-sections-container pre-review">
              <section className="summary-section">
                <div className="summary-section-title-container">
                  <Content
                    tag="h2"
                    cmsTag="GLOBAL:Personal-details"
                    copytext="Personal details"
                  />
                </div>
                <div className="d-md-flex clear-both">
                  <div className="summary-column">
                    <Content
                      tag="h3"
                      tagID="basic-details-label"
                      cmsTag="GLOBAL:Basic-details"
                      copytext="Basic details"
                    />
                    <Row tag="dl" aria-labelledby="basic-details-label">
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content cmsTag="GLOBAL:Name" copytext="Name" />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails
                          ? `${userDetails.Title} ${userDetails.FirstName} ${userDetails.Surname}`
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content cmsTag="GLOBAL:Gender" copytext="Gender" />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {Gender ? CONSTANTS.GENDER[Gender] : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Date-of-birth"
                          copytext="Date of birth"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        <FormattedDate
                          date={userDetails?.ExtendedProperties?.DateOfBirth}
                          format={CONSTANTS.STANDARD_DATE_FORMAT}
                        />
                      </Col>
                      {maritalStatus ? (
                        <React.Fragment>
                          <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                            <Content
                              cmsTag="GLOBAL:Marital-status"
                              copytext="Marital status"
                            />
                          </Col>
                          <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                            {Object.values(maritalStatus)[0]}
                          </Col>
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:National-Insurance-Number"
                          copytext="National Insurance Number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {NINumber}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Are-you-a-UK-resident?"
                          copytext="Are you a UK resident?"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {ClientAddress?.AddressCountry === 'UK' ? 'Yes' : 'No'}
                      </Col>
                    </Row>
                  </div>
                  <div className="summary-column">
                    <Content
                      tag="h3"
                      tagID="contact-details-label"
                      cmsTag="GLOBAL:Contact-details"
                      copytext="Contact details"
                    />
                    <Row tag="dl" aria-labelledby="contact-details-label">
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Mobile-number"
                          copytext="Mobile number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails?.MobileNumber
                          ? `${userDetails?.MobileNumber} `
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Daytime-phone-number"
                          copytext="Daytime phone number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails?.WorkPhone
                          ? `${userDetails?.WorkPhone} `
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Evening-phone-number"
                          copytext="Evening phone number"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails?.HomeNumber
                          ? `${userDetails?.HomeNumber} `
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:Email-address"
                          copytext="Email address"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {userDetails ? `${userDetails.EmailAddress} ` : ''}
                      </Col>
                    </Row>
                    <Content
                      tag="h3"
                      cmsTag="GLOBAL:Contact-preferences"
                      copytext="Contact preferences"
                    />
                    <Content
                      tag="p"
                      tagID="contact-agree-title"
                      cmsTag="Savings-servicing:Shared:Short-apply-review:p1"
                      copytext="You have agreed that we can contact you by:"
                    />

                    <ul aria-labelledby="contact-agree-title">
                      {userDetails &&
                      userDetails?.ExtendedProperties?.MarketingPreference
                        ?.Email === 'True' ? (
                        <li>
                          <Content cmsTag="GLOBAL:Email" copytext="Email" />
                        </li>
                      ) : (
                        ''
                      )}
                      {userDetails &&
                      userDetails?.ExtendedProperties?.MarketingPreference
                        ?.Phone === 'True' ? (
                        <li>
                          <Content cmsTag="GLOBAL:Phone" copytext="Phone" />
                        </li>
                      ) : (
                        ''
                      )}
                      {userDetails &&
                      userDetails?.ExtendedProperties?.MarketingPreference
                        ?.Post === 'True' ? (
                        <li>
                          <Content cmsTag="GLOBAL:Post" copytext="Post" />
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              </section>
              <section className="summary-section">
                <div className="summary-section-title-container">
                  <Content
                    tag="h2"
                    cmsTag="GLOBAL:Address-details"
                    copytext="Address details"
                  />
                </div>
                <div className="d-md-flex clear-both">
                  <div className="summary-column">
                    {userDetails &&
                    userDetails.ExtendedProperties &&
                    userDetails.ExtendedProperties.ClientAddress ? (
                      <address>
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address1 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address1
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address2 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address2
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address3 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address3
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address4 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address4
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .Address5 !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .Address5
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .AddressPC !== null ? (
                          <React.Fragment>
                            {
                              userDetails.ExtendedProperties.ClientAddress
                                .AddressPC
                            }
                            <br />
                          </React.Fragment>
                        ) : null}
                        {userDetails.ExtendedProperties.ClientAddress
                          .AddressCountry !== null
                          ? userDetails.ExtendedProperties.ClientAddress
                              .AddressCountry
                          : null}
                      </address>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </section>
              <section className="summary-section">
                <div className="summary-section-title-container">
                  <h2>
                    <Content
                      cmsTag="GLOBAL:Account-preferences"
                      copytext="Account preferences"
                    />
                  </h2>
                </div>
                <div className="d-md-flex clear-both">
                  <div className="summary-column">
                    <h3>
                      <Content
                        cmsTag="GLOBAL:Deposit-details"
                        copytext="Deposit details"
                      />
                    </h3>
                    <Row tag="dl" title="Deposit details">
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:How-will-you-be-making-your-initial-deposit"
                          copytext="How will you be making your initial deposit?"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {formData
                          ? CONSTANTS.DEPOSIT_METHOD.map(item => {
                              return this.dropdownOptionValue(
                                item,
                                formData[`depositMethod`],
                              );
                            })
                          : ''}
                      </Col>
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:How-much-will-this-initial-deposit-be"
                          copytext="How much will this initial deposit be?"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {formData[`depositAmount`] ? (
                          <FormattedCurrency
                            quantity={formData[`depositAmount`].replace(
                              /,/g,
                              '',
                            )}
                            currency={'GBP'}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <h3>
                      <Content
                        cmsTag="GLOBAL:Interest-payments"
                        copytext="Interest payments"
                      />
                    </h3>
                    <Markdown
                      template={{
                        markdown: {
                          phone: CONTACTS.phone,
                          phoneLink: CONTACTS.phoneLink,
                        },
                      }}
                      cmsTag="Savings-servicing:Shared:Short-apply-application:p2"
                      markdown={`\nYour interest will automatically be paid into this account. If you want your interest to be paid elsewhere, check the terms and conditions of the account and contact our Savings Support Team on <a target="_blank" href="$[phoneLink]">$[phone]</a>.`}
                    />
                    <Row tag="dl" title="Interest payment details">
                      {/* <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="LABEL:Where-shall-we-pay-the-interest-to"
                          copytext="Where shall we pay the interest to?"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {formData
                          ? CONSTANTS.INTEREST_DESTINATION.map(item => {
                              return this.dropdownOptionValue(
                                item,
                                formData[`interestPaymentLocation`],
                              );
                            })
                          : ''}
                      </Col> */}
                      <Col tag="dt" xs="12" sm="6" md="6" lg="6" xl="6">
                        <Content
                          cmsTag="GLOBAL:How-often-should-we-make-interest-payments"
                          copytext="How often should we make interest payments?"
                        />
                      </Col>
                      <Col tag="dd" xs="12" sm="6" md="6" lg="6" xl="6">
                        {selectedProduct &&
                        selectedProduct.Interest_Frequency &&
                        selectedProduct.Interest_Frequency.toLowerCase() !==
                          'both'
                          ? selectedProduct.Interest_Frequency
                          : formData
                          ? CONSTANTS.INTEREST_PAYMENT_FREQUENCY.map(item => {
                              return this.dropdownOptionValue(
                                item,
                                formData[`interestPaymentFrequency`],
                              );
                            })
                          : ''}
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>
            </div>
            <Content
              tag="p"
              cmsTag="Savings-servicing:Shared:Short-apply-review:p2"
              copytext="Please make sure you review the information you have entered. When you are sure that the information you have entered is correct, please submit your application."
            />
            <Content
              tag="p"
              cmsTag="Savings-servicing:Shared:Short-apply-review:p3"
              copytext="Please note that your application will not be considered until it has been submitted."
            />
            <Check
              cmsTag="GLOBAL:I-confirm-the-details-I-have-given-are-accurate"
              label={`I confirm the details I have given are accurate.`}
              id={`accuracyConfirm`}
              field="accuracyConfirm"
              checked={!!form.accuracyConfirm.value}
              value={false}
              validation={form.accuracyConfirm}
              onChange={this.onChange}
            />

            <div className="form-button-group float-left w-100">
              <Button
                id="create-new-notice"
                type="submit"
                color="primary"
                disabled={this.state.disabled}
              >
                <Content
                  cmsTag="GLOBAL:Submit-application"
                  copytext="Submit application"
                />
              </Button>
              <Button
                id="change-details-link"
                className="btn btn-light"
                onClick={this.changeDetails}
              >
                <Content
                  cmsTag="GLOBAL:Change-details"
                  copytext="Change details"
                />
              </Button>
              <Link
                id="back-to-product-list-link"
                className="btn btn-secondary"
                role="button"
                to={`/short-apply/index`}
              >
                <Content
                  cmsTag="GLOBAL:Back-to-product-list"
                  copytext="Back to product list"
                />
              </Link>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

ShortApplyApplication.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  onSubmit: PropTypes.func,
  setStage: PropTypes.func,
  userDetails: PropTypes.any,
  setStateDynamic: PropTypes.any,
  formData: PropTypes.any,
  selectedProduct: PropTypes.any,
  data: PropTypes.any,
  saveShortApply: PropTypes.func,
  updateUrl: PropTypes.func,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(ShortApplyApplication);
