import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import NextPaymentDetails from './NextPaymentDetails';

class Overview extends React.Component {
  render() {
    const { account, loopIndex, nextPayments } = this.props;

    return (
      <NextPaymentDetails
        loopIndex={loopIndex}
        account={account}
        nextPayments={nextPayments}
      />
    );
  }
}

Overview.propTypes = {
  account: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.any,
  range: PropTypes.any,
  viewGoalDetails: PropTypes.object,
  nextPayments: PropTypes.object,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition, accountsDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...accountsDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overview);
