import React from 'react';
import Message from './Message';

class MessageWidgetTest extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>Test Widget:</h1>
        <Message />
      </React.Fragment>
    );
  }
}

export default MessageWidgetTest;
