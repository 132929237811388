import React from 'react';
import { HelpContactSidebar } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class DeleteMessageSidebar extends React.Component {
  render() {
    return (
      <aside className="right-container">
        <HelpContactSidebar contacts={CONTACTS} />
      </aside>
    );
  }
}

export default DeleteMessageSidebar;
