import { Connection } from '@myie/interact';
import {
  UPDATE_BUDGET_PLANNER,
  BUDGET_PLANNER_REQUEST,
  BUDGET_PLANNER_FAILURE,
  BUDGET_PLANNER_SUCCESS,
  BUDGET_PLANNER_SAVE_SUCCESS,
  CLEAR_BUDGET_PLANNER,
  BUDGET_PLANNER_ADD_CATEGORY_ITEM,
  BUDGET_PLANNER_SAVED_VERSION,
  BUDGET_PLANNER_DELETE_CATEGORY_ITEM,
} from './budgetPlannerTypes';

export const clearBudgetPlanner = () => ({
  type: CLEAR_BUDGET_PLANNER,
});

export const updateBudgetPlanner = (
  category,
  group,
  item,
  name,
  value,
  categoryIndex,
) => ({
  type: UPDATE_BUDGET_PLANNER,
  category: category,
  group: group,
  item: item,
  name: name,
  value: value,
  categoryIndex,
});

export const budgetPlannerRequest = () => ({
  type: BUDGET_PLANNER_REQUEST,
  isFetching: true,
  error: null,
});

export const budgetPlannerFailed = error => ({
  type: BUDGET_PLANNER_FAILURE,
  isFetching: false,
  error: error.message,
});

export const budgetPlannerReceive = json => ({
  type: BUDGET_PLANNER_SUCCESS,
  budgetPlanner: json,
  isFetching: false,
  error: null,
});

export const budgetPlannerSaveReceive = (json, isBPAutoSave) => ({
  type: BUDGET_PLANNER_SAVE_SUCCESS,
  budgetSavePlanner: json,
  isFetching: false,
  isBPAutoSave: isBPAutoSave,
  error: null,
});

export const getBudgetPlanner = request => dispatch => {
  dispatch(budgetPlannerRequest());
  return fetch(
    Connection.baseUrl('pfm') + `v1.0/incomeandexpenditure/retrieve`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(budgetPlannerReceive(json)))
    .catch(error => dispatch(budgetPlannerFailed(error)));
};

export const setBudgetPlanner = json => dispatch => {
  dispatch(budgetPlannerReceive(json));
};

export const saveBudgetPlanner = json => ({
  type: BUDGET_PLANNER_SAVED_VERSION,
  savedBudgetPlanner: json,
});

export const addBudgetPlannerCatItem = (categoryType, item) => ({
  type: BUDGET_PLANNER_ADD_CATEGORY_ITEM,
  categoryType,
  item,
  isFetching: false,
  error: null,
});

export const deleteCatitem = deleteItem => ({
  type: BUDGET_PLANNER_DELETE_CATEGORY_ITEM,
  deleteItem,
});

export const deleteBudgetPlannerCatItem = deleteItem => (
  dispatch,
  getState,
) => {
  dispatch(deleteCatitem(deleteItem));
  const state = getState();
  dispatch(
    saveBudgetPlanner(
      state.custombrandSavingsServicingDefinition.budgetPlanner,
    ),
  );
};

export const saveNote = (
  category,
  group,
  itemName,
  name,
  value,
  categoryIndex,
) => (dispatch, getState) => {
  dispatch(
    updateBudgetPlanner(category, group, itemName, name, value, categoryIndex),
  );
  const state = getState();
  dispatch(
    saveBudgetPlanner(
      state.custombrandSavingsServicingDefinition.budgetPlanner,
    ),
  );
};
