import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Blocked extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="forgotten-username-blocked">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Sign in blocked"
          metaDescription="Sign in blocked"
        />
        <Content
          tag="h1"
          tadID="sign-in-blocked-title"
          cmsTag="Authentication:forgotten-username:Blocked:h1"
          copytext="Sign in blocked"
        />
        <Content
          tag="p"
          cmsTag="Authentication:forgotten-username:Blocked:p1"
          copytext="You have tried three times to sign in with incorrect login credentials, so we have blocked your online account access for security purposes."
        />
        <Markdown
          cmsTag="Authentication:forgotten-username:Blocked:p2"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              email: CONTACTS.email,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nPlease call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a> ($[openTimes]) or email us at <a href="mailto:$[email]">$[email]</a> to re-enable access or to change your login credentials.`}
        />

        <div className="form-button-group">
          <Link
            id="forgotten-username-blocked-home-button"
            className="btn btn-secondary"
            role="button"
            to="/"
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </Link>
        </div>
      </div>
    );
  }
}

Blocked.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Blocked);
