import * as withdrawalActions from './actions/withdrawalActions';
import * as maturityOptions from './actions/maturityOptions';
import * as shortApply from './actions/shortApply';
import * as summaryBoxPdf from './actions/summaryBoxPdf';
import * as depositProducts from './actions/depositProducts';
import * as transferActions from './actions/transferActions';
import * as externalTransferActions from './actions/externalTransferActions';
import * as savingsGoals from './actions/savingsGoals';
import * as budgetPlannerActions from './actions/budgetPlannerActions';
import * as makeADepositActions from './actions/makeADepositActions';

const mapDispatchToProps = {
  ...withdrawalActions,
  ...maturityOptions,
  ...shortApply,
  ...externalTransferActions,
  ...transferActions,
  ...summaryBoxPdf,
  ...depositProducts,
  ...savingsGoals,
  ...budgetPlannerActions,
  ...makeADepositActions,
};

export default mapDispatchToProps;
