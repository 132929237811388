import {
  COMMON_SYSTEM_MESSAGE_REQUEST,
  GET_SYSTEM_MESSAGES_SUCCESS,
  COMMON_SYSTEM_MESSAGE_FAILURE,
  SAVE_ARRAY,
  RESET_SYSTEM_MESSAGES,
} from './types';

const messagingSystemDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_SYSTEM_MESSAGES:
      return {
        ...state,
        isFetching: action.isFetching,
        systemMessages: action.systemMessages,
        alertVisibleArray: action.alertVisibleArray,
        messageArray: action.messageArray,
        error: action.error,
      };
    case COMMON_SYSTEM_MESSAGE_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case GET_SYSTEM_MESSAGES_SUCCESS:
      return {
        ...state,
        systemMessages: action.systemMessages,
        isFetching: action.isFetching,
        error: action.error,
      };
    case SAVE_ARRAY:
      return {
        ...state,
        alertVisibleArray: action.alertVisibleArray,
        messageArray: action.messageArray,
      };
    case COMMON_SYSTEM_MESSAGE_FAILURE:
    default:
      return state;
  }
};

export default messagingSystemDefinition;
