import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Form } from 'reactstrap';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import {
  Text,
  Switch,
  Content,
  Markdown,
  RULES,
  FormErrorList,
} from '@myie/interact-dom';
import { Validate } from '@myie/interact';

class SecurityDetails extends React.Component {
  constructor(props) {
    super(props);
    const { history, resetRegistration, formData } = this.props;
    this.state = {
      localPasscodeValidation: [],
      history,
      resetRegistration,
      form: {
        username: {
          rules: {
            title: 'username',
            stop: true,
            required: {
              message: 'Please enter a username.',
            },

            format: {
              regex: RULES.username,
              message: 'Please enter a valid username.',
            },
          },
        },
        password: {
          rules: {
            title: 'password',
            stop: true,
            required: {
              message: 'Please enter a password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
            isSameAsUsername: {
              message: 'Your username and password cannot be the same.',
              field: 'password',
              comparison: () => {
                return this.state.form.username.value;
              },
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            required: {
              message: 'Please confirm your password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
            compare: {
              message: 'Please ensure the password fields match.',
              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
        memorableNumber: {
          rules: {
            title: 'passcode',
            stop: true,
            required: {
              message: 'Please enter a passcode.',
            },
            format: {
              regex: RULES.memorableNumber,
              message: 'Please enter a valid passcode.',
            },
            isSequential: {
              message: 'Your passcode cannot include sequential numbers.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
            isAllSame: {
              message:
                'Your passcode cannot be the same number repeated six times.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
            isDoB: {
              message: 'Your passcode cannot be your date of birth.',
              field: 'memorableNumber',
              comparison: () => {
                return formData.b_day;
              },
            },
          },
        },
        confirmMemorableNumber: {
          rules: {
            title: 'confirm passcode',
            stop: true,
            required: {
              message: 'Please confirm your passcode.',
            },
            format: {
              regex: RULES.memorableNumber,
              message: 'Please enter a valid passcode.',
            },
            compare: {
              message: 'Please ensure the passcode fields match.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
          },
        },
      },
    };
  }
  componentDidMount = () => {
    this.props.timeout.startTimer();
  };

  componentWillUnmount = () => {
    this.props.timeout.clearTimer();
  };

  prevStage = () => {
    const { changeStageAccount } = this.props;
    changeStageAccount();
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    let { form } = this.state;
    const { setCredentials, registration, updateUrl, data } = this.props;
    form = Validate.form(form);
    let localValues = [];

    const lastUsername = form.username.value;
    this.setState({
      ...this.state,
      form,
      lastUsername,
      localPasscodeValidation: localValues,
    });
    if (!form.approved || localValues.length) {
      window.scrollTo(0, 0);
      return;
    }
    let birthDay;
    if (this.props.formData) {
      birthDay = this.props.formData.b_day ? this.props.formData.b_day : '';
    }

    const request = {
      Ticket: registration.Ticket,
      Username: form.username.value,
      CredentialValues: [
        {
          Name: 'Password',
          Value: form.password.value,
          Context: '',
        },
        {
          Name: 'Pin',
          Value: form.memorableNumber.value,
          Context: '',
        },
      ],
      TermsAndConditionsDocumentVersion: '1.0',
      ExtendedProperties: {
        AccountId: this.props.formData
          ? this.props.formData.account_number
          : '',
        IdentityRequest: {
          DateOfBirth: birthDay,
        },
      },
    };
    let newStateData = {
      formData: data,
      url: {
        pageStage: 'accountDetails',
      },
    };
    updateUrl(newStateData);
    setCredentials(request);
  };

  // NOTE FOR JEN - add this to buildErrorMsg function below
  getError(list) {
    let errors = [];
    list.forEach(element => {
      switch (element) {
        case 'Known':
          errors.push(
            <Alert id={element} key={element} color="danger">
              <Markdown
                cmsTag="Accounts:Nominated-account:Add-nominated-account:Referred-no-docs:p1"
                markdown={`You have entered a common password, please <a href="#password">choose another password</a>.`}
              />
            </Alert>,
          );
          break;
        default:
      }
    });
    return errors;
  }

  getLocalError() {
    const { localPasscodeValidation } = this.state;
    let errors = [];
    if (localPasscodeValidation) {
      errors = this.getError(localPasscodeValidation);
    }
    return errors;
  }

  getErrors() {
    const { credentialsSetup = {} } = this.props;
    let errors = [];
    if (
      credentialsSetup.ExtendedProperties &&
      credentialsSetup.ExtendedProperties.IdentityResponse &&
      credentialsSetup.ExtendedProperties.IdentityResponse.CredentialErrors
    ) {
      errors = this.getError(
        credentialsSetup.ExtendedProperties.IdentityResponse.CredentialErrors,
      );
    }

    return errors;
  }

  buildErrorMsg = credentialsSetup => {
    let errorsExist = false;
    let errorMsg = [];

    if (credentialsSetup.Status === 'InvalidCredential') {
      errorMsg = this.getErrors();
      if (errorMsg.length) {
        errorsExist = true;
      }
    } else {
      if (
        credentialsSetup &&
        credentialsSetup.Status &&
        credentialsSetup.Status !== ''
      ) {
        errorsExist = true;
      }
      errorMsg = (
        <Switch
          id="credentialsAlert"
          value={credentialsSetup.Status || ''}
          tag="div"
          className="alert alert-danger"
          scrolltotop={true}
          template={{
            UsernameAlreadyExists: {
              name: () => {
                return this.state.lastUsername;
              },
              random: Math.floor(Math.random() * (9999 - 1000)) + 1000,
            },
          }}
          contents={{
            UsernameAlreadyExists: {
              defaultValue:
                'This username already exists. <a href="#username">Please select another username</a>.',
            },
            // NOTE FOR JEN: Might not be necessary
            NotEnoughCredentials: {
              defaultValue: 'Not enough credentials were submitted.',
            },
            // NOTE FOR JEN: Might not be necessary
            InvalidUsername: {
              defaultValue:
                'The username you have entered is not valid. <a href="#username">Please enter a valid username</a>.',
            },
            InvalidCredentials: {
              defaultValue:
                'You have entered a common password, please try again.',
            },
          }}
        />
      );
    }

    return {
      errorMsg: errorMsg,
      errorsExist: errorsExist,
    };
  };

  render() {
    const { credentialsSetup = {} } = this.props;
    const { form } = this.state;
    let errorObj = this.buildErrorMsg(credentialsSetup);
    let localErrors = this.getLocalError();
    let error;

    return (
      <React.Fragment>
        {error ? error : ''}
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          id="registration-security-details"
          onSubmit={this.submit}
        >
          {/* Backend API error messages */}
          {errorObj.errorMsg}

          {/* Error messages for common passwords API */}
          {localErrors}

          <Text
            description="Your username must be unique and between 6 and 23 characters long, and must contain an upper case letter and a number. You'll need to enter it every time you sign in."
            label="Create a username"
            cmsTag="GLOBAL:Create-your-username"
            id="username"
            suffix="Please note: If somebody else has already taken this username you will not be able to use it."
            field="username"
            onChange={this.onChange}
            onBlur={this.onBlur}
            maxLength={23}
            validation={form.username}
          ></Text>
          <Text
            description="Your password must be between 8 and 50 characters long and must contain at least one upper case letter, one lower case letter, one number and one special character."
            descriptionExample="*!?%/+-"
            label="Create a password"
            cmsTag="GLOBAL:Create-your-password"
            id="password"
            field="password"
            onChange={this.onChange}
            onBlur={this.onBlur}
            maxLength={50}
            validation={form.password}
            type="password"
          />
          <Text
            label="Confirm password"
            id="confirmPassword"
            cmsTag="GLOBAL:Confirm-your-password"
            field="confirmPassword"
            onChange={this.onChange}
            onBlur={this.onBlur}
            maxLength={50}
            validation={form.confirmPassword}
            type="password"
          />
          <Text
            description="Your passcode must be at least 6 numbers long and cannot include your date of birth. Your passcode cannot include sequential numbers and cannot be the same number repeated 6 times. You will be asked for 3 numbers from this code every time you sign in."
            label="Create a passcode"
            cmsTag="GLOBAL:Create-your-passcode"
            id="memorableNumber"
            field="memorableNumber"
            onChange={this.onChange}
            onBlur={this.onBlur}
            maxLength={6}
            validation={form.memorableNumber}
            type="password"
            groupClassName="half-field"
          />
          <Text
            label="Confirm passcode"
            cmsTag="GLOBAL:Confirm-your-passcode"
            id="confirmMemorableNumber"
            field="confirmMemorableNumber"
            onChange={this.onChange}
            onBlur={this.onBlur}
            maxLength={6}
            validation={form.confirmMemorableNumber}
            type="password"
            groupClassName="half-field"
          />
          <div className="form-button-group">
            <Button id="security-details-submit" type="submit" color="primary">
              <Content
                cmsTag="GLOBAL:Complete-regsistration"
                copytext="Complete registration"
              />
            </Button>
            <Button
              id="security-details-cancel"
              color="secondary"
              onClick={this.prevStage}
            >
              <Content
                cmsTag="GLOBAL:Back-to-account-details"
                copytext="Back to account details"
              />
            </Button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

SecurityDetails.propTypes = {
  history: PropTypes.any,
  changeStageAccount: PropTypes.any,
  registration: PropTypes.any,
  resetRegistration: PropTypes.any,
  credentialsSetup: PropTypes.any,
  setCredentials: PropTypes.any,
  timeout: PropTypes.any,
  updateUrl: PropTypes.func,
  data: PropTypes.any,
  setStage: PropTypes.any,
  stateData: PropTypes.any,
  formData: PropTypes.any,
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_shared,
  },
)(SecurityDetails);
