import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { Content, Markdown } from '@myie/interact-dom';
import AERAbbreviation from '../../shared/components/AERAbbreviation';
import { CONTACTS } from '@myie/interact-local-dom';

class AccountDescription extends Component {
  render() {
    const { selectedProduct } = this.props;

    return (
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card className="h-100 mb-0 apply-card">
            <CardHeader>
              <Content
                tag="h2"
                tagID="apply-list-1"
                cmsTag="Savings-servicing:Short-apply:Account-description:h2-1"
                copytext="To apply you must be"
              />
            </CardHeader>
            <CardBody>
              <ul className="bullet-list" aria-labelledby={`apply-list-1`}>
                <li>
                  <Markdown
                    cmsTag="Savings-servicing:Short-apply:Account-description:li-1"
                    template={{
                      markdown: {
                        age: CONTACTS.minimumAge,
                      },
                    }}
                    markdown="Over $[age] years old."
                  />
                </li>
                <li>
                  <Content
                    cmsTag="Savings-servicing:Short-apply:Account-description:li-2"
                    copytext="A personal saver who is a resident in the UK."
                  />
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="eligibility-mobile-spacing"
        >
          <Card className="h-100 mb-0 apply-card">
            <CardHeader>
              <Content
                tag="h2"
                tagID="apply-list-2"
                cmsTag="Savings-servicing:Short-apply:Account-description:h3-2"
                copytext="Key features"
              />
            </CardHeader>
            <CardBody>
              <ul className="bullet-list" aria-labelledby={`apply-list-2`}>
                <li>
                  <Markdown
                    cmsTag="Savings-servicing:Short-apply:Account-description::li-2-part-1"
                    template={{
                      markdown: {
                        InterestRate: selectedProduct?.Interest_rate,
                      },
                    }}
                    markdown="Rate $[InterestRate]% gross/"
                  />
                  <AERAbbreviation />
                  <Content
                    cmsTag="Savings-servicing:Short-apply:Account-description:li-2-part-2"
                    copytext=" variable."
                  />
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

AccountDescription.propTypes = {
  selectedProduct: PropTypes.any,
};

export default AccountDescription;
