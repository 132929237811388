import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignInAlert from './SignInAlert';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as brandAccountsMapDispatch } from '@myie/interact-brand-accounts';

class Message extends React.Component {
  closeAlert = () => {
    let { hideFirstTimeSignInMessage } = this.props;

    hideFirstTimeSignInMessage(false);
  };

  render() {
    const { login, key, firstTimeSignIn = true, hasResetPassword } = this.props;
    //const { Customer: { LastLoggedIn } = {} } = login;
    if (
      (login && login.Customer && login.Customer.LastLoggedIn) ||
      hasResetPassword
    ) {
      return null;
    }

    return (
      <SignInAlert
        visibility={firstTimeSignIn}
        closeAlert={this.closeAlert}
        key={key}
      />
    );
  }
}

Message.propTypes = {
  hideFirstTimeSignInMessage: PropTypes.any,
  key: PropTypes.any,
  maturityAlertVisibleArray: PropTypes.any,
  firstTimeSignIn: PropTypes.any,
  saveMaturityMessageArray: PropTypes.any,
  systemMessages: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  accounts: PropTypes.any,
  alertVisibleArray: PropTypes.any,
  account: PropTypes.object,
  login: PropTypes.any,
  loopIndex: PropTypes.any,
  hasResetPassword: PropTypes.any,
};
const mapStateToProps = state => {
  const {
    authenticationDefinition,
    custombrandAccountsDefinition,
    accountsDefinition,
  } = state;
  return {
    ...accountsDefinition,
    ...authenticationDefinition,
    ...custombrandAccountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...brandAccountsMapDispatch, ...mapDispatchToProps },
)(Message);
