import React from 'react';
import PropTypes from 'prop-types';
import { Markdown, Content, CustomAlert } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class SignInAlert extends React.Component {
  onDismiss = () => {
    const { closeAlert } = this.props;
    closeAlert();
  };

  render() {
    const { key, visibility } = this.props;

    return (
      <React.Fragment>
        <CustomAlert
          key={`sign-in-alert-${key}`}
          visibility={visibility}
          onDismiss={this.onDismiss}
          className={`alert-success`}
        >
          <Content
            tag="h2"
            cmsTag="Accounts:Sign-in-message:Sign-in-alert:h2-1"
            copytext="Welcome to your new online account!"
          />
          <Markdown
            cmsTag="Accounts:Sign-in-message:Sign-in-alert:p1"
            template={{
              markdown: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
              },
            }}
            markdown={`\nIf you would like any help please call us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
          />
        </CustomAlert>
      </React.Fragment>
    );
  }
}

SignInAlert.propTypes = {
  alertVisibleArray: PropTypes.any,
  closeAlert: PropTypes.any,
  accountKey: PropTypes.any,
  account: PropTypes.object,
  message: PropTypes.any,
  key: PropTypes.any,
  MaturityDate: PropTypes.any,
  visibility: PropTypes.any,
  alert: PropTypes.any,
  className: PropTypes.string,
};

export default SignInAlert;
