import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Fade,
  Card,
  Button,
  Alert,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { FormattedCurrency, Content, CONSTANTS } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import {
  mapDispatchToProps,
  mapStateToProps,
} from '@myie/interact-brand-savings-servicing';

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dimensions: null, sidebarPos: 0 };
    this.container = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.budgetPlanner !== this.props.budgetPlanner &&
      this.container
    ) {
      setTimeout(() => {
        this.setState({
          dimensions: {
            width: this.container.offsetWidth,
            height: this.container.getBoundingClientRect().height,
          },
        });
      }, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    if (this.state.dimensions == null) return false;

    const winScroll = document.documentElement.scrollTop
      ? Math.ceil(document.documentElement.scrollTop)
      : Math.ceil(document.body.scrollTop);

    let sidebarPos = this.state.sidebarPos;

    let sideBarBottom =
      this.state.sidebarPos + this.state.dimensions.height * 0.5;

    let docHeight = document.documentElement.scrollHeight
      ? document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      : document.body.scrollHeight - document.body.clientHeight;
    const newPosition = winScroll + this.state.dimensions.height;

    if (winScroll > sideBarBottom) {
      if (newPosition > docHeight) {
        let newHeight =
          this.state.dimensions.height > 530
            ? docHeight - (this.state.dimensions.height - 480)
            : docHeight;
        sidebarPos = Math.ceil(newHeight);
      } else {
        sidebarPos = winScroll;
      }
    } else if (winScroll < sidebarPos) {
      if (newPosition > docHeight) {
        let newHeight =
          this.state.dimensions.height > 530
            ? docHeight - (this.state.dimensions.height - 480)
            : docHeight;
        sidebarPos = Math.ceil(newHeight);
      } else {
        sidebarPos = winScroll;
      }
    }

    this.setState({ ...this.state, sidebarPos });
  };

  getUseBtn = (totalIncome, totalOutgoing, planData) => {
    if (planData.form.Amount) {
      planData.form.Amount = parseFloat(planData.form.Amount).toFixed(2);
    }
    if (totalIncome - totalOutgoing > 0) {
      return (
        <Link
          className="btn btn-primary"
          onClick={this.onClick}
          role="button"
          to={{
            pathname: planData.pathname,
            state: {
              data: planData,
            },
          }}
          id="use-amount"
        >
          <Content
            cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:Use-amount"
            copytext="Use amount"
          />
        </Link>
      );
    }
    return null;
  };

  onClick = value => {
    let {
      preservedSavingsGoalState,
      preserveGoalState,
      history,
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST,
    } = this.props;
    if (preservedSavingsGoalState) {
      preservedSavingsGoalState.formData.monthlyAmount = value.toFixed(2);

      preserveGoalState({
        ...preservedSavingsGoalState,
        goalValue: value,
      });
    }
    if (backPageMode === 'undefined') {
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST;
    }
    if (preservedSavingsGoalState.mode === 'add') {
      history.push(
        `/money-tools/savings-goals/create-goals/${backPageMode}/${preservedSavingsGoalState.accountId}?pageStage=goalDetails&calculator=true`,
      );
    } else {
      history.push(
        `/money-tools/savings-goals/edit-goals/goal-list/${preservedSavingsGoalState.accountId}?pageStage=goalDetails&calculator=true`,
      );
    }
  };

  onClickCancel = () => {
    let {
      preservedSavingsGoalState,
      preserveGoalState,
      history,
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST,
    } = this.props;
    if (preservedSavingsGoalState) {
      preserveGoalState({
        ...preservedSavingsGoalState,
      });
    }
    if (backPageMode === 'undefined') {
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST;
    }
    if (preservedSavingsGoalState.mode === 'add') {
      history.push(
        `/money-tools/savings-goals/create-goals/${backPageMode}/${preservedSavingsGoalState.accountId}?pageStage=goalDetails&calculator=true`,
      );
    } else {
      history.push(
        `/money-tools/savings-goals/edit-goals/goal-list/${preservedSavingsGoalState.accountId}?pageStage=goalDetails&calculator=true`,
      );
    }
  };

  getCategory = (index, type, name, subTotal, maxSubTotal) => {
    // let categoryIndexColor = index;
    // if (type === 'debts') {
    //   categoryIndexColor = 9;
    // } else if (type === 'court_payments') {
    //   categoryIndexColor = 10;
    // }
    if (subTotal === 0) return null;
    const svgWidth = 300;
    const chartWidth = (subTotal / maxSubTotal) * svgWidth;
    const chartDesc = type === 'income' ? 'incoming' : `${type} expense`;
    return (
      <React.Fragment key={type}>
        <Col tag="dt" xs={6}>
          {name}
        </Col>
        {/* {type !== 'income' ? ( */}
        <Col tag="dd" xs={6}>
          <FormattedCurrency quantity={subTotal} currency="GBP" />
        </Col>
        {/* ) : null} */}
        {chartWidth > 0 ? (
          <Col tag="dd" xs={12} aria-hidden="true">
            <svg
              width={svgWidth}
              height="15"
              role="img"
              alt="svg chart"
              aria-hidden="true"
              className={`${type}-bar`}
            >
              <title id={`svgchartTitle-${type}`}>
                <Content
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:Chart-title"
                  template={{
                    copytext: {
                      chartDesc,
                    },
                  }}
                  copytext="chart illustrating $[chartDesc]."
                />
              </title>
              <desc id={`svgchartDesc-${type}`}>
                <Content
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:Chart-description"
                  template={{
                    copytext: {
                      chartDesc,
                    },
                  }}
                  copytext="This chart illustrates your $[chartDesc]."
                />
              </desc>
              <g className="bar-income-wrapper">
                <rect
                  key="test"
                  x="0"
                  y="0"
                  width={chartWidth}
                  height="20"
                  className={`i-and-e-bar`}
                />
              </g>
            </svg>
          </Col>
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    const { budgetPlanner, data, calcAmountPerFrequency } = this.props;
    if (!budgetPlanner) {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    }

    let planData = { form: {}, pathname: '' };
    if (data) {
      planData = JSON.parse(JSON.stringify(data));
    }

    let assetsTotal = 0;
    let totalIncome = 0;
    let totalOutgoing = 0;
    let incomeBars = [];
    let outgoingBars = [];
    let maxSubTotal = null;

    budgetPlanner.income_and_expenditure.categories.forEach(category => {
      let subTotal = 0;
      if (category.items) {
        category.items.forEach(item => {
          subTotal =
            subTotal +
            calcAmountPerFrequency(
              parseFloat(item.amount || 0),
              item.frequency,
            );
          if (Array.isArray(item.items)) {
            item.items.forEach(sub => {
              subTotal =
                subTotal +
                calcAmountPerFrequency(
                  parseFloat(sub.amount || 0),
                  sub.frequency,
                );
            });
          }
        });
      }

      if (!maxSubTotal) {
        maxSubTotal = subTotal;
      } else if (subTotal > maxSubTotal) {
        maxSubTotal = subTotal;
      }

      if (category.type === 'income') {
        totalIncome = totalIncome + subTotal;
        incomeBars.push({
          name: category.name,
          type: category.type.toLowerCase(),
          subTotal,
        });
      } else {
        totalOutgoing = totalOutgoing + subTotal;
        outgoingBars.push({
          name: category.name,
          type: category.type.toLowerCase(),
          subTotal,
        });
      }
    });

    if (budgetPlanner.income_and_expenditure.debts.items) {
      let debtsSubTotal = 0;
      budgetPlanner.income_and_expenditure.debts.items.forEach(item => {
        debtsSubTotal =
          debtsSubTotal +
          calcAmountPerFrequency(parseFloat(item.amount || 0), item.frequency);
      });
      if (!maxSubTotal) {
        maxSubTotal = debtsSubTotal;
      } else if (debtsSubTotal > maxSubTotal) {
        maxSubTotal = debtsSubTotal;
      }

      totalOutgoing = totalOutgoing + debtsSubTotal;
      outgoingBars.push({
        name: 'Debts',
        type: 'debts',
        subTotal: debtsSubTotal,
      });
    }

    if (budgetPlanner.income_and_expenditure.assets.items) {
      budgetPlanner.income_and_expenditure.assets.items.forEach(item => {
        assetsTotal = assetsTotal + parseFloat(item.amount || 0);
      });
    }

    if (budgetPlanner.income_and_expenditure.court_payments.items) {
      let courtPaymentsSubTotal = 0;
      budgetPlanner.income_and_expenditure.court_payments.items.forEach(
        item => {
          courtPaymentsSubTotal =
            courtPaymentsSubTotal +
            calcAmountPerFrequency(
              parseFloat(item.amount || 0),
              item.frequency,
            );
        },
      );
      if (!maxSubTotal) {
        maxSubTotal = courtPaymentsSubTotal;
      } else if (courtPaymentsSubTotal > maxSubTotal) {
        maxSubTotal = courtPaymentsSubTotal;
      }

      totalOutgoing = totalOutgoing + courtPaymentsSubTotal;
      outgoingBars.push({
        name: 'Court Payments',
        type: 'court_payments',
        subTotal: courtPaymentsSubTotal,
      });
    }

    planData.form['Amount'] = totalIncome - totalOutgoing;

    return (
      <div
        className="clearfix scrolling-container"
        style={{
          top: `${this.state.sidebarPos}px`,
        }}
        ref={el => (this.container = el)}
      >
        <React.Fragment>
          <Card color="dark">
            <CardHeader>
              <Content
                tag="h2"
                cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:h2-1"
                copytext="Spending Summary"
              />
            </CardHeader>
            <CardBody>
              <Fade
                className="chart-and-output"
                in={totalIncome > 0 || totalOutgoing > 0}
                style={{
                  display: totalIncome || totalOutgoing ? 'block' : 'none',
                }}
              >
                <Content
                  tag="h3"
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:h3-1"
                  copytext="Income"
                />
                <Row tag="dl" title="Income summary">
                  {incomeBars.map((categoryInfo, index) => {
                    return this.getCategory(
                      index,
                      categoryInfo.type,
                      categoryInfo.name,
                      categoryInfo.subTotal,
                      maxSubTotal,
                    );
                  })}
                </Row>
                <Content
                  tag="h3"
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:h3-2"
                  copytext="Outgoings"
                />
                <Row tag="dl" title="Outgoings summary">
                  {outgoingBars.map((categoryInfo, index) => {
                    return this.getCategory(
                      ++index,
                      categoryInfo.type,
                      categoryInfo.name,
                      categoryInfo.subTotal,
                      maxSubTotal,
                    );
                  })}
                </Row>
                <Alert
                  body
                  color={totalIncome - totalOutgoing > 0 ? 'success' : 'danger'}
                >
                  {totalIncome - totalOutgoing >= 0
                    ? 'You can save '
                    : 'You are overspending by '}
                  <strong>
                    <FormattedCurrency
                      quantity={totalIncome - totalOutgoing}
                      currency="GBP"
                      showNegative={false}
                    />
                  </strong>
                  .
                </Alert>

                {totalIncome - totalOutgoing === 0 ? (
                  <Card className="chart-info">
                    <CardBody>
                      <Content
                        tag="p"
                        cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:alert-1"
                        copytext="You are spending all of what you earn. Try finding ways to reduce your spending so that you can start to save money towards your future."
                      />
                    </CardBody>
                  </Card>
                ) : null}
                {totalIncome - totalOutgoing < 0 && planData.pathname ? (
                  <Card className="chart-info">
                    <CardBody>
                      <Content
                        tag="p"
                        cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:alert-2"
                        copytext="You are spending more than you earn. Try finding ways to reduce your spending so that you can start to save money towards your future."
                      />
                    </CardBody>
                  </Card>
                ) : null}
              </Fade>
            </CardBody>
            <CardFooter>
              {totalIncome === 0 && totalOutgoing === 0 ? (
                <React.Fragment>
                  <Content
                    tag="p"
                    cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:p1"
                    copytext="Enter your Income and Expenditure to see your spending summary"
                  />
                  {planData.pathname ? (
                    <Link
                      className="btn btn-light"
                      role="button"
                      to={{
                        pathname: planData.pathname,
                        state: {
                          data: data,
                        },
                      }}
                    >
                      <Content cmsTag="GLOBAL:Cancel" copytext="Cancel" />
                    </Link>
                  ) : (
                    <Button
                      id="cancel-spending-button"
                      onClick={this.onClickCancel}
                      className="btn btn-light"
                    >
                      <Content cmsTag="GLOBAL:Cancel" copytext="Cancel" />
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Row>
                    {planData.pathname ? (
                      <React.Fragment>
                        {this.getUseBtn(totalIncome, totalOutgoing, planData)}
                        <Col sm="12" md="6">
                          <Link
                            className="btn btn-secondary"
                            role="button"
                            to={{
                              pathname: planData.pathname,
                              state: {
                                data: data,
                              },
                            }}
                          >
                            <Content id="GLOBAL:Cancel" copytext="Cancel" />
                          </Link>
                        </Col>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Col sm="12" md="6">
                          <Button
                            id="use-amount-button"
                            color="primary"
                            onClick={() =>
                              this.onClick(totalIncome - totalOutgoing)
                            }
                            disabled={
                              totalIncome - totalOutgoing > 0 ? false : true
                            }
                          >
                            <Content
                              cmsTag="GLOBAL:Use-amount"
                              copytext="Use amount"
                            />
                          </Button>
                        </Col>
                        <Col sm="12" md="6">
                          <Button
                            id="cancel-amount-button"
                            color="danger"
                            onClick={() => this.onClickCancel()}
                          >
                            <Content cmsTag="GLOBAL:Cancel" copytext="Cancel" />
                          </Button>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                </React.Fragment>
              )}
            </CardFooter>
          </Card>
          <Card color="dark">
            <CardBody>
              <Row tag="dl" title="Assets summary" className="mb-0">
                <Col tag="dt">
                  <Content
                    cmsTag="Savings-servicing:Money-tools:Budget-calculator:Chart:Assets-total"
                    copytext="Assets total"
                  />
                </Col>
                <Col tag="dd">
                  <FormattedCurrency quantity={assetsTotal} currency="GBP" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </React.Fragment>
      </div>
    );
  }
}

Chart.propTypes = {
  account: PropTypes.any,
  budgetPlanner: PropTypes.any,
  preservedSavingsGoalState: PropTypes.object,
  preserveGoalState: PropTypes.func,
  data: PropTypes.any,
  values: PropTypes.any,
  history: PropTypes.any,
  saveBudgetPlanner: PropTypes.func,
  calcAmountPerFrequency: PropTypes.func,
  leftPanelDimensions: PropTypes.object,
  backPageMode: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Chart));
