import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-two-factor';
import { mapDispatchToProps as mapSharedDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Session } from '@myie/interact';

class ExceededAttempts extends React.Component {
  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    const { userLogout } = this.props;
    Session.abandon();
    userLogout();
  };

  render() {
    return (
      <React.Fragment>
        <Content
          tag="h1"
          cmsTag="Two-factor:Exceeded-attempts:h1"
          copytext="Request Cancelled"
        />
        <Markdown
          cmsTag="Two-factor:Exceeded-attempts:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
              email: CONTACTS.email,
            },
          }}
          markdown={`\nYou have entered an incorrect authentication code too many times. For security reasons we have cancelled your request.\n\nPlease try again in 24 hours or contact our Online Support Team. You can email <a href="mailto:$[email]">$[email]</a>, or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our telephone lines are open $[openTimes].`}
        />
        <div className="form-button-group">
          {this.props.isAuthenticated ? (
            <Button
              id="request-cancelled-back-to-signin"
              color="secondary"
              onClick={this.cancel}
            >
              <Content
                cmsTag="GLOBAL:Back-to-accounts"
                copytext="Back to Sign in"
              />
            </Button>
          ) : (
            <LinkTag
              id="personal-details-cancel"
              className={`btn btn-secondary`}
              role="button"
              href={CONTACTS.externalHomeURL}
            >
              <Content
                cmsTag="GLOBAL:Back-to-home-page"
                copytext="Back to home page"
              />
            </LinkTag>
          )}
        </div>
      </React.Fragment>
    );
  }
}

ExceededAttempts.propTypes = {
  finishTwoFactor: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  contacts: PropTypes.object,
  userLogout: PropTypes.func,
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSharedDispatchToProps },
)(ExceededAttempts);
