import React from 'react';
import PropTypes from 'prop-types';

import {
  Content,
  FormattedCurrency,
  Value,
  CONSTANTS,
  AERAbbreviation,
  FormattedDate,
} from '@myie/interact-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapStateToProps } from '@myie/interact-accounts';
import moment from 'moment';

class SavingsDetailSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { account, product, allTransactions } = this.props;
    const {
      Notice_Period_Days,
      Account_Type_Code,
      Max_Withdrawals_Per_Year,
    } = product;

    const {
      ExtendedProperties: {
        InterestPaid,
        CurrentBalance: balance,
        AvailableBalance: availableBalance,
        NoticeAvailable,
      } = {},
    } = account;

    const startOfYear = moment()
      .startOf('year')
      .toISOString();
    const now = moment()
      .startOf('minute')
      .toISOString();

    let noOfWithdrawals = 0;

    allTransactions.forEach(trans => {
      const {
        ExtendedProperties: { GlobalTransactionType, TransactionDate },
      } = trans;
      if (GlobalTransactionType === CONSTANTS.TRANSACTION_TYPE_WITHDRAW) {
        if (moment(TransactionDate).isBetween(startOfYear, now)) {
          noOfWithdrawals++;
        }
      }
    });

    const remainingAllowance = Account_Type_Code === CONSTANTS.CASH_ISA;
    const withdrawalsRemaining =
      Max_Withdrawals_Per_Year ===
        CONSTANTS.TRANSACTION_MAX_WITHDRAWAL_UNLIMITED ||
      Max_Withdrawals_Per_Year === CONSTANTS.TRANSACTION_MAX_WITHDRAWAL_NONE
        ? Max_Withdrawals_Per_Year
        : Max_Withdrawals_Per_Year - noOfWithdrawals;

    return (
      <React.Fragment>
        <Row tag="dl" title="Account summary details">
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Current-balance"
              copytext="Current balance"
            />
          </Col>
          <Col id="current-balance-amount" tag="dd" xs={12} md={7} lg={8}>
            <FormattedCurrency
              quantity={balance}
              currency={account.CurrencyCode}
            />
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Available-balance"
              copytext="Available balance"
            />
          </Col>
          <Col id="available-balance-amount" tag="dd" xs={12} md={7} lg={8}>
            <FormattedCurrency
              quantity={availableBalance}
              currency={account.CurrencyCode}
            />
          </Col>

          {account.InterestRate !== 0 || account.InterestRate !== null ? (
            <React.Fragment>
              <Col tag="dt" xs={12} md={5} lg={4}>
                <AERAbbreviation />
              </Col>
              <Col
                id="summary-interest-rate-value"
                tag="dd"
                xs={12}
                md={7}
                lg={8}
              >
                <Value decimalPlaces={2} value={account.InterestRate} />
                %
                <Content
                  cmsTag="Account-type-savings:Savings-details-summary:-annual-variable-interest"
                  copytext=" annual variable interest"
                />
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}

          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Interest-paid" copytext="Interest paid" />
          </Col>
          <Col id="interest-paid-amount" tag="dd" xs={12} md={7} lg={8}>
            {InterestPaid ? InterestPaid.toLowerCase() : ''}
          </Col>

          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Next-interest-due-date"
              copytext="Next interest due date"
            />
          </Col>
          <Col id="summary-interest-date-value" tag="dd" xs={12} md={7} lg={8}>
            <FormattedDate
              date={account.ExtendedProperties.NextInterestDueDate}
              currentFormat={CONSTANTS.STANDARD_DATE_FORMAT}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>

          {account.AccountStatus !== 'Closed' &&
          account.ExtendedProperties?.AccountMode !==
            CONSTANTS.ACCOUNT_CLOSED &&
          account.ExtendedProperties.MaturityDate ? (
            <React.Fragment>
              <Col tag="dt" xs={12} md={5} lg={4}>
                <Content
                  cmsTag="GLOBAL:Maturity-date"
                  copytext="Maturity date"
                />
              </Col>
              <Col
                id="summary-statement-method-value"
                tag="dd"
                xs={12}
                md={8}
                lg={8}
              >
                <FormattedDate
                  date={account.ExtendedProperties.MaturityDate}
                  currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
                  format={CONSTANTS.STANDARD_DATE_FORMAT}
                />
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}

          {remainingAllowance ? (
            <React.Fragment>
              <Col tag="dt" xs={12} md={5} lg={4}>
                <Content
                  cmsTag="GLOBAL:Remaining-allowance-for-current-tax-year"
                  copytext="Remaining allowance for current tax year"
                />
              </Col>
              <Col
                id="summary-remaining-allowance-value"
                tag="dd"
                xs={12}
                md={7}
                lg={8}
              >
                <FormattedCurrency
                  quantity={
                    account.ExtendedProperties.RemainingAllowanceCurrentTaxYear
                  }
                  currency={account.CurrencyCode}
                />
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}

          {(account.ExtendedProperties.AccountMode ===
            CONSTANTS.ACCOUNT_TRANSACT ||
            account.ExtendedProperties.AccountMode ===
              CONSTANTS.ACCOUNT_VIEW_ONLY) &&
          account.ExtendedProperties.Account_Type_Code ===
            CONSTANTS.NOTICE_ACCOUNT &&
          Notice_Period_Days ? (
            <React.Fragment>
              <Col tag="dt" xs={12} md={5} lg={4}>
                <Content
                  cmsTag="GLOBAL:Notices-to-withdraw"
                  copytext="Notices to withdraw"
                />
              </Col>
              <Col
                id="summary-notices-to-withdraw"
                tag="dd"
                xs={12}
                md={7}
                lg={8}
              >
                {account.ExtendedProperties.NoticesToWithdraw}
              </Col>
              <Col tag="dt" xs={12} md={5} lg={4}>
                <Content
                  cmsTag="GLOBAL:Notices-remaining"
                  copytext={'Notice period'}
                />
              </Col>
              <Col
                id="summary-notices-remaining"
                tag="dd"
                xs={12}
                md={7}
                lg={8}
              >
                {NoticeAvailable}
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}
          {(account.ExtendedProperties.AccountMode ===
            CONSTANTS.ACCOUNT_TRANSACT ||
            account.ExtendedProperties.AccountMode ===
              CONSTANTS.ACCOUNT_VIEW_ONLY) &&
          account.ExtendedProperties.Account_Type_Code !==
            CONSTANTS.NOTICE_ACCOUNT &&
          Max_Withdrawals_Per_Year >= 0 ? (
            <>
              <Col tag="dt" xs={12} md={5} lg={4}>
                <Content
                  cmsTag="GLOBAL:Notices-remaining"
                  copytext={'Withdrawals Remaining'}
                />
              </Col>
              <Col id="withdrawals-remaining" tag="dd" xs={12} md={7} lg={8}>
                {withdrawalsRemaining}
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
        <Content
          tag="p"
          tagClassName="caveat"
          tagID="available-balance"
          cmsTag="Account-type-savings:Savings-details-summary:p1"
          copytext="Please note: Your available balance may not show your most recent transactions."
        />
      </React.Fragment>
    );
  }
}

SavingsDetailSummary.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  product: PropTypes.object,
  allTransactions: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavingsDetailSummary);
