import { Connection } from '@myie/interact';

import {
  INTERESTHISTORY_REQUEST,
  INTERESTHISTORY_SUCCESS,
  INTERESTHISTORY_FAILURE,
  RESET_INTERESTHISTORY,
} from './interestRateTypes';

export const resetInterestHistory = () => ({
  type: RESET_INTERESTHISTORY,
});
export const interestHistoryRequest = () => ({
  type: INTERESTHISTORY_REQUEST,
  isFetching: true,
  error: null,
});

export const interestHistoryFailed = error => ({
  type: INTERESTHISTORY_FAILURE,
  isFetching: false,
  error: error.message,
});

export const interestHistoryReceive = (json, accountkey) => ({
  type: INTERESTHISTORY_SUCCESS,
  interestHistory: json,
  accountkey: accountkey,
  isFetching: false,
  error: null,
});

export const getInterestHistory = request => dispatch => {
  dispatch(interestHistoryRequest());
  return fetch(Connection.baseUrl('custombrand') + `v1.0/getinteresthistory`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(interestHistoryReceive(json, request.AccountKey)))
    .catch(error => dispatch(interestHistoryFailed(error)));
};
export const brandShouldFetchInterestHistory = (state, request) => {
  if (!state.accounts || !state.accounts.Accounts) {
    return false;
  }
  const index = state.accounts.Accounts.findIndex(
    x => x.AccountKey.Key === request.AccountKey.Key,
  );
  if (index === -1) {
    return false;
  }
  if (!state.accounts.Accounts[index].InterestHistory) {
    return true;
  }

  return false;
};

export const getInterestHistoryIfNeeded = request => (dispatch, getState) => {
  if (brandShouldFetchInterestHistory(getState().accountsDefinition, request)) {
    return dispatch(getInterestHistory(request));
  }
};
