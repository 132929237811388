import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CONTACTS } from '@myie/interact-local-dom';
import { Content, AppMeta, Markdown } from '@myie/interact-dom';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import FormatBody from '../FormatBody';
import messagingUtility from './../utility';

class Confirmation extends React.Component {
  renderSuccessContent = () => {
    const { replyMessageStatus, selectedData } = this.props;
    return (
      <React.Fragment>
        <div className="confirmation-details">
          <Row tag="dl" title="Message details">
            {selectedData.relatedAccountObj ? (
              <React.Fragment>
                <Col tag="dt" xs={12} sm={6} md={3}>
                  <Content
                    cmsTag="GLOBAL:Related-account"
                    copytext="Related account"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={9}>
                  {replyMessageStatus.Conversation.AccountNumber !==
                    'XXXXXXXXXable' &&
                  replyMessageStatus.Conversation.AccountNumber !==
                    'Not available' ? (
                    <React.Fragment>
                      <Content
                        cmsTag="GLOBAL:Account-ending-"
                        copytext="Account ending "
                      />
                      {replyMessageStatus.Conversation.AccountNumber
                        ? replyMessageStatus.Conversation.AccountNumber.substr(
                            -4,
                          )
                        : ''}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Col>
              </React.Fragment>
            ) : (
              false
            )}

            {replyMessageStatus.Conversation.Reason ? (
              <React.Fragment>
                <Col tag="dt" xs={12} sm={6} md={3}>
                  <Content cmsTag="GLOBAL:Subject" copytext="Subject" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={9}>
                  {messagingUtility.stripEndQuotes(
                    replyMessageStatus.Conversation.Reason,
                  )}
                </Col>
              </React.Fragment>
            ) : (
              false
            )}

            <Col tag="dt" xs={12} sm={6} md={3}>
              <Content
                cmsTag="GLOBAL:Reference-number"
                copytext="Reference number"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={9}>
              <Content cmsTag="GLOBAL:Reference-" copytext="Reference " />
              {replyMessageStatus.Conversation.Reference}
            </Col>
          </Row>
          <div className="list-group messages-list">
            <p className="list-group-item" key={`message-0`}>
              {FormatBody(replyMessageStatus.Conversation.Messages[0].Body)}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { replyMessageStatus } = this.props;

    return (
      <div id="new-message-confirmation">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Message sent"
          metaDescription="Message sent"
        />
        <Content
          tag="h1"
          cmsTag="Messaging-two-way:New:Confirmation:h1"
          copytext="Your message has been sent"
        />
        <Markdown
          cmsTag="Messaging-two-way:New:Confirmation:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
            },
          }}
          markdown={`\nThank you for your message. We aim to reply within five working days. If your query is of a more urgent matter, please call us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
        />

        {replyMessageStatus.ResponseStatus === 'Success'
          ? this.renderSuccessContent()
          : false}
        <div className="form-button-group">
          <Link
            id="new-message-confirmation-back"
            className="btn btn-secondary"
            role="button"
            to="/messaging-twoway/view"
          >
            <Content
              cmsTag="GLOBAL:Back-to-message-list"
              copytext="Back to message list"
            />
          </Link>
        </div>
      </div>
    );
  }
}

Confirmation.propTypes = {
  replyMessageStatus: PropTypes.any,
  resetContactOptions: PropTypes.any,
  resetReply: PropTypes.any,
  resetUserDetails: PropTypes.any,
  selectedData: PropTypes.any,
  setStage: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Confirmation);
