import React from 'react';
import { Route } from 'react-router-dom';
import Registration from './registration';
import SignIn from './sign-in';
import ForgottenUsername from './forgotten-username';
import ForgottenPassword from './forgotten-password';
import UpdateSecurityDetailsIndex from './update-security-details/';
import SignedOut from './signed-out/SignedOut';

const Routes = [
  <Route key="/sign-in" path="/sign-in" component={SignIn} />,
  <Route key="/registration" path="/registration" component={Registration} />,
  <Route
    key="/forgotten-username"
    path="/forgotten-username"
    component={ForgottenUsername}
  />,
  <Route
    key="/forgotten-password"
    path="/forgotten-password"
    component={ForgottenPassword}
  />,
  <Route
    key="/update-security-details"
    path="/update-security-details"
    component={UpdateSecurityDetailsIndex}
  />,
  <Route key="/signed-out" path="/signed-out" component={SignedOut} />,
];

export default Routes;
