import React, { Component } from 'react';
import { Content, FormattedCurrency, AppMeta } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-brand-savings-servicing';
import queryString from 'query-string';
import { CONTACTS } from '@myie/interact-local-dom';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: null,
      disabled: false,
    };
    props.initializeMoveMoney();
  }
  submit = () => {
    const { onSubmitWithdrawal } = this.props;
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    onSubmitWithdrawal();
  };

  editTransfer = () => {
    const { setStage, urlParams, data, updateUrl } = this.props;
    if (urlParams && urlParams.page && urlParams.page === 'account') {
      const data = {
        url: { editTransfer: 'true' },
      };
      const newUrl = queryString.stringifyUrl({
        url: `/accounts/list`,
        query: data.url,
      });
      this.props.history.push(newUrl);
    } else {
      let stateData = {
        formData: data,
        url: {
          pageStage: 'createTransfer',
        },
      };
      updateUrl(stateData);
    }

    setStage('createTransfer');
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { internalTransfer = {} } = nextProps;

    if (internalTransfer && internalTransfer.ResponseStatus === 'Error') {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return {
      message: null,
      disabled: false,
    };
  };

  render() {
    const { account = {}, toAccount = {}, data, urlParams } = this.props;

    let amount = data && data['amount'] ? data['amount'] : '';
    const closeOption = false;
    const { CurrencyCode } = account;

    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    const balance = AvailableBalance + PendingBalance;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Transfer money between your ${CONTACTS.companyNameShorthand} savings accounts step 2`}
          metaDescription={`Transfer money between your ${CONTACTS.companyNameShorthand} savings accounts step 2`}
        />
        {this.state.message && (
          <Content
            id="create-transfer-review-alert"
            tag="div"
            cmsTag="Savings-servicing:Transfer:Create-transfer:Create-transfer:p1"
            className="alert alert-danger"
            role="alert"
            scrolltotop={true}
            template={{
              copytext: {
                companyNameShorthand: CONTACTS.companyNameShorthand,
              },
            }}
            copytext="Transfer to your $[companyNameShorthand] savings account failed"
          />
        )}
        <Content
          tag="p"
          cmsTag="Savings-servicing:Transfer:Create-transfer:Confirmation:p1"
          copytext="Please check that we have the correct details before continuing."
        />
        <div className="alert alert-info">
          <Content
            tag="p"
            tagID="transfer-details-note"
            cmsTag="Savings-servicing:Transfer:Create-transfer:Confirmation:p2"
            copytext="Please note:"
          />
          <ul className="transfer-details-note mb-0">
            <li>
              <Content
                cmsTag="Savings-servicing:Transfer:Create-transfer:Confirmation:li-1"
                copytext="Any transfers made on a weekend or a bank holiday will not reach your account until the next working day. Please do not attempt the transfer again unless advised to do so."
              />
            </li>
            <li>
              <Content
                cmsTag="Savings-servicing:Transfer:Create-transfer:Confirmation:li-2"
                copytext="If you have decided to transfer your full balance and close the account, you will also transfer any accrued interest."
              />
            </li>
          </ul>
        </div>
        <div className="confirm-transfer-to-another-account-details pt-5">
          <Row tag="dl" title="Transfer details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:From" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:To" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {toAccount.AccountName}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {!closeOption ? (
                <FormattedCurrency
                  quantity={amount.replace(/,/g, '')}
                  currency={CurrencyCode}
                />
              ) : (
                <React.Fragment>
                  <FormattedCurrency
                    quantity={balance}
                    currency={CurrencyCode}
                  />
                  <Content
                    cmsTag="GLOBAL:-Full-balance"
                    copytext=" (Full balance)"
                  />
                </React.Fragment>
              )}
            </Col>
          </Row>
        </div>

        <div className="form-button-group">
          <Button
            id="confirm-transfer"
            type="submit"
            onClick={this.submit}
            disabled={this.state.disabled}
            color="primary"
          >
            <Content
              cmsTag="GLOBAL:Confirm-transfer"
              copytext="Confirm transfer"
            />
          </Button>
          <Button
            id="edit-transfer-details"
            onClick={this.editTransfer}
            color="light"
            disabled={this.state.disabled}
          >
            <Content
              cmsTag="GLOBAL:Edit-transfer-details"
              copytext="Edit transfer details"
            />
          </Button>
          {urlParams && urlParams.page && urlParams.page === 'account' ? (
            <Link
              id="back-to-accounts"
              className="btn btn-secondary"
              role="button"
              to={`/accounts/list`}
            >
              <Content
                cmsTag="GLOBAL:Back-to-accounts"
                copytext="Back to accounts"
              />
            </Link>
          ) : (
            <Link
              id="back-to-move-money"
              className="btn btn-secondary"
              role="button"
              to={`/move-money/index`}
            >
              <Content
                cmsTag="GLOBAL:Back-to-move-money"
                copytext="Back to move money"
              />
            </Link>
          )}
        </div>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  urlParams: PropTypes.any,
  toAccount: PropTypes.any,
  data: PropTypes.any,
  createNoticeParam: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  createResponse: PropTypes.any,
  onSubmitWithdrawal: PropTypes.any,
  createNoticeToWithdraw: PropTypes.func,
  account: PropTypes.any,
  setStage: PropTypes.any,
  form: PropTypes.any,
  updateUrl: PropTypes.any,
  actionDate: PropTypes.any,
  internalTransfer: PropTypes.object,
  initializeMoveMoney: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Confirmation));
