import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Markdown, Content, CustomAlert } from '@myie/interact-dom';

class NominatedAccountUnsetSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: props.visibility || true,
    };
  }

  onDismiss = () => {
    this.setState({
      visibility: false,
    });
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { visibility } = this.state;
    return (
      <CustomAlert
        key={`nominated-account-unset-message`}
        visibility={visibility}
        onDismiss={this.onDismiss}
        className={`alert-warning`}
      >
        <React.Fragment>
          <p>
            <Markdown
              cmsTag="Accounts:Nominated-account-unset-message:alert-1:part-1"
              markdown={`You have not set up your nominated accounts for withdrawals. `}
            />
            <Link
              id={`add-nominated-account`}
              to={'/nominated-account-for-withdrawals/add/summary'}
            >
              <Content
                cmsTag="Accounts:Nominated-account-unset-message:Add-a-nominated-account"
                copytext="Add a nominated account"
              />
            </Link>
            <Markdown
              cmsTag="Accounts:Nominated-account-unset-message:alert-1:part-2"
              markdown={`.`}
            />
          </p>
        </React.Fragment>
      </CustomAlert>
    );
  }
}

NominatedAccountUnsetSummary.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  visibility: PropTypes.any,
};

export default NominatedAccountUnsetSummary;
