import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import {
  Content,
  Check,
  Markdown,
  RadioGroup,
  Radio,
  LinkTag,
  AppMeta,
  FormErrorList,
} from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { Button, Form, Alert } from 'reactstrap';
import { CONSTANTS } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import AccountDescription from '../../savings-servicing/shortApply/AccountDescription';

class Eligibility extends Component {
  constructor(props) {
    super(props);
    let validations = {
      residentsConfirm: {
        rules: {
          required: {
            message:
              'Please confirm that you are a resident in the UK and pay tax in the UK.',
          },
        },
        value: false,
      },
      confirmSummary: {
        rules: {
          required: {
            message: 'Please agree to the Terms and Conditions.',
          },
        },
        value: false,
      },
      privacyPolicyConfirm: {
        rules: {
          required: {
            message: 'Please read the Privacy Notice.',
          },
        },
        value: false,
      },
      fscsConfirm: {
        rules: {
          required: {
            message: 'Please read the FSCS information sheet.',
          },
        },
        value: false,
      },
    };
    const required = {
      message:
        'Please tell us if you want to transfer your ISA to this new account.',
    };

    this.state = {
      transferYourIsa: required,
      selectedProduct: null,
      validations: validations,
      form: null,
    };
  }

  static getDerivedStateFromProps = (nextProps, thisState) => {
    const { selectedProduct } = nextProps;
    const { validations, form } = thisState;
    let newForm = null;

    let accountType = '';
    if (selectedProduct && !form) {
      accountType = CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
        return selectedProduct.Account_Type_Code === element;
      });
      if (accountType) {
        newForm = {
          isThisTheOnlyIsa: {
            rules: {
              required: {
                message:
                  'Please tell us if this is the only ISA you have opened or funded in the current tax year.',
              },
            },
            value: '',
          },
          transferYourIsa: {
            rules: {},
            value: '',
          },
          residentsConfirm: validations.residentsConfirm,
          confirmSummary: validations.confirmSummary,
          privacyPolicyConfirm: validations.privacyPolicyConfirm,
          fscsConfirm: validations.fscsConfirm,
        };
      } else {
        newForm = validations;
      }

      return {
        form: newForm,
      };
    }

    return null;
  };

  onChange = e => {
    const { name, checked, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, checked ? value : null, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    let { form } = this.state;
    const { match, setStateDynamic, updateUrl } = this.props;

    let newform = Validate.form(form);
    if (newform.approved) {
      setStateDynamic({
        stage: 'apply',
      });

      let stateData = {
        url: {
          pageStage: 'apply',
        },
      };
      updateUrl(stateData);
      this.setState({ ...this.state, form: newform });
    } else if (match.params && match.params.id) {
      window.scrollTo(0, 0);
      this.setState({ form: newform });
    }
  };

  changeStage = () => {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onEvent = e => {
    const { name, value } = e.target;
    let { form, transferYourIsa } = this.state;
    form = Validate.input(name, value, form, true);
    if (name === 'isThisTheOnlyIsa' && value === 'no') {
      form.transferYourIsa.rules = {
        required: transferYourIsa,
      };
    }
    if (name === 'isThisTheOnlyIsa' && value === 'yes') {
      form.transferYourIsa.rules = {};
      form = Validate.input('transferYourIsa', '', form, true);

      //transferYourIsa should have no value
    }
    this.setState({ ...this.state, form });
  };

  render() {
    const { form } = this.state;
    const { selectedProduct } = this.props;

    if (!selectedProduct || !form) {
      return null;
    }

    const accountType = CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
      return selectedProduct.Account_Type_Code === element;
    });

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Apply for ${selectedProduct?.Product_Name}`}
          metaDescription={`Apply for ${selectedProduct?.Product_Name}`}
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Shared:Eligibility:h1"
          template={{
            copytext: {
              accountName: selectedProduct?.Product_Name,
            },
          }}
          copytext="Apply for $[accountName]"
        />
        <section>
          <Content
            tag="h2"
            cmsTag="Savings-servicing:Shared:Eligibility:h2-1"
            copytext="Information about this account"
          />
          <AccountDescription selectedProduct={selectedProduct} />
          <div className="downloads-container">
            <Content
              tag="p"
              cmsTag="Savings-servicing:Shared:Eligibility:p1"
              copytext="Please download and read our Darlingtonline Terms and Conditions and Privacy Notice before you apply."
            />
            <LinkTag
              id="download-product-terms-button"
              className={`btn btn-light`}
              target="_blank"
              role="button"
              href={CONTACTS?.termsAndConditionsURL}
            >
              <Content
                cmsTag="GLOBAL:Download-terms-and-conditions"
                template={{
                  copytext: {
                    companySiteName: CONTACTS.companySiteName,
                  },
                }}
                copytext="$[companySiteName] Terms and Conditions"
              />
            </LinkTag>
            <LinkTag
              id="download-privacy-notice-button"
              className={`btn btn-light`}
              target="_blank"
              role="button"
              href={CONTACTS.privacyPolicyURL}
            >
              <Content
                cmsTag="GLOBAL:Download-privacy-notice"
                copytext="Privacy notice"
              />
            </LinkTag>
          </div>
        </section>
        <section>
          <Form autoComplete="off" onSubmit={this.onSubmitHandler}>
            <Content
              tag="h2"
              cmsTag="Savings-servicing:Shared:Eligibility:h2-2"
              copytext="Let's get started"
            />
            <FormErrorList
              validations={form}
              disabled={false}
              required={true}
              groupClassName=""
              title="h2"
              showErrors={true}
            />
            {accountType ? (
              <React.Fragment>
                <RadioGroup
                  legend="Is this the only ISA opened or funded in the current tax year?"
                  cmsTag="GLOBAL:Is-this-the-only-ISA-opened-or-funded-in-the-current-tax-year"
                  required={true}
                  field="isThisTheOnlyIsa"
                  id="isThisTheOnlyIsa"
                  validation={form?.isThisTheOnlyIsa}
                >
                  <Radio
                    label="Yes"
                    cmsTag="GLOBAL:Yes"
                    id="only-ISA-yes"
                    field="isThisTheOnlyIsa"
                    onChange={this.onEvent}
                    validation={form?.isThisTheOnlyIsa}
                    value="yes"
                  />
                  <Radio
                    label="No"
                    cmsTag="GLOBAL:No"
                    id="only-ISA-no"
                    field="isThisTheOnlyIsa"
                    onChange={this.onEvent}
                    validation={form?.isThisTheOnlyIsa}
                    value="no"
                  />
                </RadioGroup>
                <RadioGroup
                  legend="Do you want to transfer your ISA to this new account?"
                  cmsTag="GLOBAL:Do-you-want-to-transfer-your-ISA-to-this-new-account"
                  required={true}
                  id="transferYourIsa"
                  field="transferYourIsa"
                  validation={form?.transferYourIsa}
                  disabled={
                    form.isThisTheOnlyIsa?.value === 'yes' ||
                    form.isThisTheOnlyIsa?.value === ''
                      ? true
                      : false
                  }
                >
                  <Radio
                    cmsTag="GLOBAL:Yes"
                    label="Yes"
                    id="transfer-ISA-yes"
                    field="transferYourIsa"
                    onChange={this.onEvent}
                    validation={form?.transferYourIsa}
                    value="yes"
                    disabled={
                      form.isThisTheOnlyIsa?.value === 'yes' ||
                      form.isThisTheOnlyIsa?.value === ''
                        ? true
                        : false
                    }
                  />
                  <Radio
                    cmsTag="GLOBAL:No"
                    label="No"
                    id="transfer-ISA-no"
                    field="transferYourIsa"
                    onChange={this.onEvent}
                    validation={form?.transferYourIsa}
                    value="no"
                    disabled={
                      form.isThisTheOnlyIsa?.value === 'yes' ||
                      form.isThisTheOnlyIsa?.value === ''
                        ? true
                        : false
                    }
                  />
                  {form.transferYourIsa &&
                  form.transferYourIsa?.value &&
                  form.transferYourIsa?.value === 'no' ? (
                    <Alert color="danger">
                      <Markdown
                        cmsTag="Savings-servicing:Shared:Eligibility:only-invest-in-one-isa"
                        markdown={`You can only invest in one ISA in a tax year. You must transfer your existing ISA to us if you have invested in an ISA with elsewhere.`}
                      />
                    </Alert>
                  ) : (
                    ''
                  )}

                  {form.transferYourIsa &&
                  form.transferYourIsa?.value &&
                  form.transferYourIsa?.value === 'yes' ? (
                    <Alert color="info">
                      <Markdown
                        cmsTag="Savings-servicing:Shared:Eligibility:ISA-transfer-form"
                        markdown={`To transfer your ISA please <a href="${CONTACTS.cashISAFormURL}" target="_blank" rel="noopener noreferrer" type="application/pdf">download the ISA Transfer authority form<span className="sr-only"> (opens in a new window)</span></a>.`}
                      />
                    </Alert>
                  ) : (
                    ''
                  )}
                </RadioGroup>
              </React.Fragment>
            ) : (
              ''
            )}

            <Check
              autoFocus={false}
              cmsTag="GLOBAL:I-confirm-that-I-am-a-resident-in-the-UK-and-pay-tax-only-in-the-UK"
              label={`I confirm I am a UK resident.`}
              key={`residentsConfirm`}
              id={`residentsConfirm`}
              field="residentsConfirm"
              onChange={this.onChange}
              validation={form.residentsConfirm}
              checked={!!form.residentsConfirm.value}
              value={'new'}
            />
            <Check
              autoFocus={false}
              label={
                <Markdown
                  cmsTag="GLOBAL:Agree-to-the-conditions"
                  template={{
                    markdown: {
                      url: '',
                    },
                  }}
                  markdown={`I confirm that I have downloaded, read and agree with the <a href="${CONTACTS?.termsAndConditionsURL}" target="_blank" rel="noopener noreferrer"type="application/pdf">Terms and Conditions<span className="sr-only"> (Downloads a PDF)</span></a>.`}
                />
              }
              key={`confirmSummary`}
              id={`confirmSummary`}
              field="confirmSummary"
              onChange={this.onChange}
              validation={form.confirmSummary}
              checked={!!form.confirmSummary.value}
              value={'new'}
            />
            <Check
              autoFocus={false}
              label={
                <Markdown
                  cmsTag="GLOBAL:Agree-privacy-policy"
                  markdown={`I confirm that I have downloaded, read and agree with the <a href="${CONTACTS.privacyPolicyURL}" target="_blank" rel="noopener noreferrer" type="application/pdf">Privacy policy<span className="sr-only"> (Downloads a PDF)</span></a>.`}
                />
              }
              key={`privacyPolicyConfirm`}
              id={`privacyPolicyConfirm`}
              field="privacyPolicyConfirm"
              onChange={this.onChange}
              validation={form.privacyPolicyConfirm}
              checked={!!form.privacyPolicyConfirm.value}
              value={'new'}
            />
            <Check
              autoFocus={false}
              label={
                <Markdown
                  cmsTag="GLOBAL:Agree-statement-details"
                  markdown={`I confirm that I have read the <a href="${CONTACTS.FSCSURL}" target="_blank" rel="noopener noreferrer" type="application/pdf">Financial Services Compensation Scheme (FSCS) Information Sheet<span className="sr-only"> (Downloads a PDF)</span></a>.`}
                />
              }
              key={`fscsConfirm`}
              id={`fscsConfirm`}
              field="fscsConfirm"
              onChange={this.onChange}
              validation={form.fscsConfirm}
              checked={!!form.fscsConfirm.value}
              value={'entire'}
            />
            <Alert color="info">
              <Markdown
                cmsTag="GLOBAL:FPN-Personal-information-blurb"
                markdown={`\nThe personal information we will collect from you will be shared with fraud prevention agencies who will use it to prevent fraud and money-laundering and to verify your identity. If fraud is detected, you could be refused certain services, finance, or employment. Further details of how your information will be used by us and these fraud prevention agencies, and your data protection rights, can be found by <a href="${CONTACTS.privacyPolicyURL}" target="_blank" rel="noopener noreferrer">Privacy - Darlington Building Society/fraud prevention policy<span className="sr-only"> (Opens in a new window)</span></a>.`}
              />
            </Alert>
            <div className="form-button-group float-left w-100">
              <Button
                id="continue-application-submit-button"
                type="submit"
                color="primary"
              >
                <Content
                  cmsTag="GLOBAL:Continue-to-account-details"
                  copytext="Continue to account details"
                />
              </Button>

              <Link
                id="personal-details-link"
                className="btn btn-secondary"
                role="button"
                to={`/short-apply/index`}
              >
                <Content
                  cmsTag="GLOBAL:View-other-accounts"
                  copytext="View other accounts"
                />
              </Link>
            </div>
          </Form>
        </section>
      </React.Fragment>
    );
  }
}

Eligibility.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  account: PropTypes.any,
  selectedProduct: PropTypes.any,
  form: PropTypes.any,
  setStage: PropTypes.any,
  setStateDynamic: PropTypes.any,
  contacts: PropTypes.object,
  resumeApplication: PropTypes.func,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Eligibility),
);
