import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { symbols, Content, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';
import { Validate } from '@myie/interact';
import Currency from 'react-currency-formatter';

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        relatedAccount: {
          rules: {
            title: 'Source Account',
            stop: true,
            required: {
              message: 'Please select a related account.',
            },
          },
        },
        message: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter a message.',
            },
          },
        },
      },
    };
  }

  accountOptions = (prefix, item, id) => {
    let name = item.AccountName;
    if (item.Balance) {
      name =
        name +
        ': ' +
        Currency({ quantity: item.Balance, currency: item.CurrencyCode });
    }
    return (
      <option
        key={prefix + '-' + item.AccountKey.Key + '-' + id}
        id={id}
        value={item.AccountKey.Key}
      >
        {name}
      </option>
    );
  };

  submit = e => {
    e.preventDefault();
    let { form } = this.state;
    const { replyConversation, selectedData } = this.props;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      return;
    }
    let request = {
      AccountKey: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.AccountKey
        : null,
      ConversationKey: null,
      MessageBody: selectedData.message,
      Reason: selectedData.relatedAccountObj
        ? 'Account number: ' + selectedData.relatedAccountObj.AccountNumber
        : 'General Enquiry',
      ProductType: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.ExtendedProperties.AccountType
        : null,
      ExtendedProperties: {},
    };
    replyConversation(request);
  };

  onBlur = e => {
    const { saveData } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onChangeAccount = e => {
    const { saveData, accounts } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    const accountKey = value;
    let source;
    form = Validate.input(name, value, form, true);
    if (accounts) {
      source = accounts.Accounts.find(
        account => account.AccountKey.Key === accountKey,
      );
    }
    saveData(name, value);
    saveData('relatedAccountObj', source);
    this.setState({ ...this.state, form });
  };

  onChange = e => {
    const { saveData } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    saveData(name, value);
    this.setState({ ...this.state, form });
  };

  getCurrencySymbol = CurrencyCode => {
    return symbols[CurrencyCode];
  };

  render() {
    const { selectedData } = this.props;
    const { form } = this.state;
    if (selectedData) {
      form.relatedAccount.value = selectedData.relatedAccount;
      form.message.value = selectedData.message;
    }

    return (
      <React.Fragment>
        <Content
          tag="h2"
          cmsTag="Widget-send-message:Details:h2-1"
          copytext="Got a question?"
        />
        <Markdown
          cmsTag="Widget-send-message:Details:section-1"
          markdown={`\nIt's easy to contact us securely with any queries you have about your account. \n\nUnfortunately we can't accept online requests to change your personal details, but for all other queries simply "send us a secure message" using the button. \n\nWe aim to reply within 2 working days.`}
        />
        <Link
          id="widget-send-message-button"
          className="btn btn-primary"
          role="button"
          to="/messaging-twoway/new"
        >
          <Content cmsTag="GLOBAL:Send-message" copytext="Send message" />
        </Link>
        <Markdown
          cmsTag="Widget-send-message:Details:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nWe're also happy to speak to you directly about your account. Please call $[companyName] Customer Services on <a href="tel:$[phoneLink]">$[phone]</a> $[openTimes].`}
        />
      </React.Fragment>
    );
  }
}

Details.propTypes = {
  accounts: PropTypes.any,
  fetchTransferMapIfNeeded: PropTypes.any,
  replyConversation: PropTypes.any,
  saveData: PropTypes.any,
  selectedData: PropTypes.any,
  setStage: PropTypes.any,
  transactionData: PropTypes.any,
  transfermap: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Details);
