import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '@myie/interact-dom';
import View from './View';
import New from './New';
import Conversation from './Conversation';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-messaging-twoway';

class MessagingTwoway extends Component {
  componentWillUnmount() {
    const { resetMessages } = this.props;
    resetMessages();
  }

  render() {
    if (
      this.props.location.pathname.match(
        new RegExp('^/messaging-twoway[/]{0,1}$'),
      )
    ) {
      return <Redirect to="/messaging-twoway/view" />;
    }

    return (
      <div id="messaging-twoway-container" aria-live="polite">
        <Switch>
          <PrivateRoute exact path="/messaging-twoway/view" component={View} />
          <PrivateRoute
            path="/messaging-twoway/new/:id?/:subject?"
            component={New}
          />
          <PrivateRoute
            path="/messaging-twoway/message/"
            component={Conversation}
          />
        </Switch>
      </div>
    );
  }
}

MessagingTwoway.propTypes = {
  location: PropTypes.object,
  resetMessages: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(MessagingTwoway);
