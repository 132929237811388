import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';

class DeleteConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'View',
      message: null,
    };
  }

  confirmDelete = () => {
    const { deleteConversation, viewMessage } = this.props;
    const request = {
      ConversationKey: viewMessage.Conversation.ConversationKey,
      ProductType: 'string',
      ConversationProperties: [
        {
          Name: 'Deleted',
          Value: true,
        },
      ],
      ExtendedProperties: {},
    };
    deleteConversation(request);
  };

  // select current stag
  render() {
    const { viewMessage, match } = this.props;

    let Conversation;

    if (!viewMessage) {
      return null;
    } else {
      Conversation = viewMessage.Conversation;
    }

    return (
      <div id="conversation-delete">
        <Content
          tag="h1"
          cmsTag="Messaging-two-way:Conversation:Delete:Delete:h1"
          copytext="Delete message"
        />
        <Content
          tag="p"
          cmsTag="Messaging-two-way:Conversation:Delete:Delete:p1"
          copytext="Please make sure this is the correct conversation before deleting it."
        />
        <Row
          tag="dl"
          title="Delete message details"
          className="message-details-header"
        >
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Subject" copytext="Subject" />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            <Content cmsTag="GLOBAL:Re-" copytext="Re: " />
            {Conversation.Reason}
          </Col>
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Related-account"
              copytext="Related account"
            />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            {`${Conversation.AccountNumber}${Conversation.SortCode}`}
          </Col>
          <Col tag="dt" xs={12} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Reference-number"
              copytext="Reference number"
            />
          </Col>
          <Col tag="dd" xs={12} md={8} lg={9}>
            {Conversation.Reference}
          </Col>
        </Row>
        <div className="form-button-group">
          <Button
            id="messaging-twoway-delete-submit"
            type="submit"
            color="primary"
            onClick={this.confirmDelete}
          >
            <Content cmsTag="GLOBAL:Confirm-delete" copytext="Confirm delete" />
          </Button>
          <Link
            id="messaging-twoway-delete-cancel"
            className="btn btn-secondary"
            role="button"
            to={`/messaging-twoway/message/view/${match.params.id}`}
          >
            <Content
              cmsTag="Messaging-two-way:Conversation:Delete:Delete:Cancel"
              copytext="Cancel"
            />
          </Link>
        </div>
      </div>
    );
  }
}

DeleteConversation.propTypes = {
  baseUrl: PropTypes.any,
  deleteConversation: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  setReplyStage: PropTypes.any,
  viewMessage: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteConversation);
