import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { connect } from 'react-redux';
import { Content, FormattedDate, CONSTANTS } from '@myie/interact-dom';

class NoticeWithdrawalDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isComponentLoaded: false,
    };
  }

  setInitialRange() {}

  componentDidMount() {
    this.setState({ ...this.state, isComponentLoaded: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.account === nextProps.account &&
      this.state.isComponentLoaded === nextState.isComponentLoaded
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { props } = this;

    const { account, loopIndex } = props;

    let dateList = account.ExtendedProperties.ListNoticeToWithdraw;

    let maxDate;
    if (
      account.ExtendedProperties &&
      account.ExtendedProperties.ListNoticeToWithdraw
    ) {
      maxDate = new Date(
        Math.min(
          ...dateList.map(e => new Date(moment(e.WithdrawDate, 'DD-MM-YYYY'))),
        ),
      );
    }

    if (
      account.ExtendedProperties &&
      account.ExtendedProperties.ListNoticeToWithdraw &&
      account.ExtendedProperties.ListNoticeToWithdraw.length &&
      (account.AccountStatus !== 'Closed' &&
        account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED)
    ) {
      //Transactions available - show latest 3 transactions

      return (
        <div
          key={`account-type-notice-summary-${loopIndex}`}
          className="transactions-summary-section"
        >
          <h2 className={`notice-to-withdraw-title h6`}>
            <Content
              cmsTag="Account-type-savings:Notice-withdrawal-date:h3-1"
              copytext="Notice to withdraw"
            />
          </h2>
          <p className="pb-0">
            <Content
              cmsTag="Account-type-savings:Notice-withdrawal-date:p1-part-1"
              copytext="You have set up a notice to withdraw. This will be processed on "
            />
            <FormattedDate
              date={maxDate ? maxDate : ''}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
            <Content
              cmsTag="Account-type-savings:Notice-withdrawal-date:p1-part-2"
              copytext=" and be returned to your nominated account the next working day."
            />
          </p>
        </div>
      );
    } else {
      return null;
    }
  }
}

NoticeWithdrawalDate.propTypes = {
  account: PropTypes.any,
  isDetail: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.number,
  isFetching: PropTypes.bool,
  viewGoalDetails: PropTypes.any,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition, accountsDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...accountsDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoticeWithdrawalDate);
