export const SETUP_USER_MANAGEMENT_ACTIVATE_REQUEST =
  'SETUP_USER_MANAGEMENT_ACTIVATE_REQUEST';

export const SETUP_USER_MANAGEMENT_ACTIVATE_SUCCESS =
  'SETUP_USER_MANAGEMENT_ACTIVATE_SUCCESS';

export const SETUP_USER_MANAGEMENT_ACTIVATE_FAILURE =
  'SETUP_USER_MANAGEMENT_ACTIVATE_FAILURE';

export const RESET_SETUP_USER_MANAGEMENT_ACTIVATE =
  'RESET_SETUP_USER_MANAGEMENT_ACTIVATE';

export const SETUP_USER_MANAGEMENT_REQUEST = 'SETUP_USER_MANAGEMENT_REQUEST';

export const SETUP_USER_MANAGEMENT_FAILURE = 'SETUP_USER_MANAGEMENT_FAILURE';

export const SETUP_USER_MANAGEMENT_SUCCESS = 'SETUP_USER_MANAGEMENT_SUCCESS';

export const RESET_USER_MANAGEMENT = 'RESET_USER_MANAGEMENT';

export const SETUP_IFRAME_REQUEST = 'SETUP_IFRAME_REQUEST';

export const SETUP_IFRAME_FAILURE = 'SETUP_IFRAME_FAILURE';

export const SETUP_IFRAME_SUCCESS = 'SETUP_IFRAME_SUCCESS';

export const RESET_IFRAME = 'RESET_IFRAME';

export const SETUP_NOMINATED_ACCOUNT_STATUS_REQUEST =
  'SETUP_NOMINATED_ACCOUNT_STATUS_REQUEST';

export const SETUP_NOMINATED_ACCOUNT_STATUS_FAILURE =
  'SETUP_NOMINATED_ACCOUNT_STATUS_FAILURE';

export const SETUP_NOMINATED_ACCOUNT_STATUS_SUCCESS =
  'SETUP_NOMINATED_ACCOUNT_STATUS_SUCCESS';

export const RESET_NOMINATED_ACCOUNT_STATUS = 'RESET_NOMINATED_ACCOUNT_STATUS';

export const LINKED_NOMINATED_ACCOUNT_SUCCESS =
  'LINKED_NOMINATED_ACCOUNT_SUCCESS';
export const LINKED_NOMINATED_ACCOUNT_FAILURE =
  'LINKED_NOMINATED_ACCOUNT_FAILURE';
