import React from 'react';
import PropTypes from 'prop-types';

// TODO: use connect HOC to connect to CMS data in store

function tabNavHOC(Component) {
  class TabNavigationKeyboard extends React.Component {
    constructor(props) {
      super(props);
      let tab =
        props.urlParams && props.urlParams.tab
          ? props.urlParams.tab
          : 'summary';
      this.state = {
        tabs: null,
        tabNames: [],
        activeKeyTab: {
          tab: tab,
          e: null,
        },
      };
    }

    callback = (event, index, refs) => {
      const { tabs, panels } = refs;
      setTimeout(() => {
        panels.forEach((element, i) => {
          tabs[i].current.tabIndex =
            element.current.clientHeight !== 0 ? 0 : -1;
        });
      }, 250);
    };

    panelKeyDown = (event, name, refs) => {
      const { tabs, panels } = refs;
      const key = event.which || event.keyCode;
      let keys = {
        tab: 9,
      };
      const index = refs.names.indexOf(name);
      switch (key) {
        case keys.tab:
          if (event.shiftKey) {
            if (event.target === panels[index].current) {
              event.preventDefault();
              tabs.forEach((element, i) => {
                element.current.tabIndex = index === i ? 0 : -1;
              });
              tabs[index].current.focus();
            }
          }
          break;
        default:
      }
    };
    changeTab = tab => {
      const activeKeyTab = {
        tab: tab,
        e: null,
      };

      this.setState({ activeKeyTab: activeKeyTab });
    };

    tabKeyDown = (event, name, refs) => {
      const { tabNames } = this.state;
      const { tabs, panels } = refs;
      const key = event.which || event.keyCode;
      let keys = {
        end: 35,
        home: 36,
        left: 37,
        right: 39,
        tab: 9,
        space: 32,
      };
      const index = refs.names.indexOf(name);
      switch (key) {
        case keys.home:
          event.preventDefault();
          tabs[0].current.focus();
          break;
        case keys.end:
          event.preventDefault();
          tabs[tabs.length - 1].current.focus();
          break;
        case keys.right: {
          event.preventDefault();
          let rightIndex = index < tabs.length - 1 ? index + 1 : 0;
          tabs.forEach((element, i) => {
            element.current.tabIndex = rightIndex === i ? 0 : -1;
          });
          const activeKeyTab = {
            tab: tabNames[rightIndex],
            e: event,
          };

          this.setState({ activeKeyTab: activeKeyTab });
          tabs[rightIndex].current.focus();
          break;
        }
        case keys.left: {
          event.preventDefault();

          let leftIndex = index > 0 ? index - 1 : tabs.length - 1;
          tabs.forEach((element, i) => {
            element.current.tabIndex = leftIndex === i ? 0 : -1;
          });
          const activeKeyTab = {
            tab: tabNames[leftIndex],
            e: event,
          };

          this.setState({ activeKeyTab: activeKeyTab });
          tabs[leftIndex].current.focus();
          break;
        }

        case keys.tab:
          if (!event.shiftKey) {
            event.preventDefault();
            panels.forEach(element => {
              element.current.focus();
            });
          }
          if (event.shiftKey) {
            this.callback(event, index, refs);
          }
          break;
        case keys.space:
          event.preventDefault();
          tabs[index].current.click();
          break;
        default:
      }
    };

    tabInit = names => {
      const t = {
        refs: {
          names: names,
          tabs: [],
          panels: [],
        },
        panelKeyDown: this.panelKeyDown,
        tabKeyDown: this.tabKeyDown,
      };

      for (let index = 0; index < names.length; index++) {
        t.refs.tabs.push(React.createRef());
        t.refs.panels.push(React.createRef());
      }
      this.setState({ tabs: t, tabNames: names });
    };

    render() {
      // eslint-disable-next-line no-unused-vars
      const { ...rest } = this.props;
      const { tabs, activeKeyTab } = this.state;

      return (
        <Component
          activeKeyTab={activeKeyTab}
          changeTab={this.changeTab}
          keyTabs={tabs}
          tabInit={this.tabInit}
          {...rest}
        />
      );
    }
  }
  TabNavigationKeyboard.propTypes = {
    cmsTag: PropTypes.any,
    history: PropTypes.any,
    id: PropTypes.any,
    match: PropTypes.any,
    pendingContent: PropTypes.any,
    publishedContent: PropTypes.any,
    showOrigin: PropTypes.any,
    urlParams: PropTypes.any,
    location: PropTypes.any,
  };

  return TabNavigationKeyboard;
}
export default tabNavHOC;
