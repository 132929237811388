import brandRegistrationDefinition from './reducers/registrationDefinition';
import brandSetupCredentialsDefinition from './reducers/setupCredentialsDefinition';

const custombrandAuthenticationDefinition = (state = {}, action) => {
  let newState = state;
  newState = brandRegistrationDefinition(newState, action);
  newState = brandSetupCredentialsDefinition(newState, action);
  return newState;
};

export default custombrandAuthenticationDefinition;
