import {
  FORGOTTEN_UN_REQUEST,
  FORGOTTEN_UN_SUCCESS,
  FORGOTTEN_UN_FAILURE,
  RESET_FORGOTTEN_UN,
} from '../actions/forgottenUserNameTypes';

const forgottenUserNameDefinition = (state = {}, action) => {
  switch (action.type) {
    case RESET_FORGOTTEN_UN:
      return {
        ...state,
        retrievedUserName: {},
      };
    case FORGOTTEN_UN_SUCCESS:
      return {
        ...state,
        retrievedUserName: action.forgottenUN,
        isFetching: action.isFetching,
      };
    case FORGOTTEN_UN_REQUEST:
    case FORGOTTEN_UN_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default forgottenUserNameDefinition;
