import {
  EXTERNAL_TRANSFER_MONEY_REQUEST,
  EXTERNAL_TRANSFER_MONEY_SUCCESS,
  EXTERNAL_TRANSFER_MONEY_FAILURE,
  EXTERNAL_TRANSFER_RESET,
} from '../actions/externalTransferTypes';

const externalTransferDefinition = (state = {}, action) => {
  switch (action.type) {
    case EXTERNAL_TRANSFER_RESET:
      return {
        ...state,
        isFetching: action.isFetching,
        externalTransfer: action.externalTransferResponse,
        error: action.error,
      };
    case EXTERNAL_TRANSFER_MONEY_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case EXTERNAL_TRANSFER_MONEY_SUCCESS:
      return {
        ...state,
        externalTransfer: action.externalTransferResponse,
        isFetching: action.isFetching,
        error: action.error,
      };
    case EXTERNAL_TRANSFER_MONEY_FAILURE:
      return {
        ...state,
        internalTransfer: null,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default externalTransferDefinition;
