import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  Content,
  Markdown,
  ContactNumber,
  EmailAddress,
  Icon,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: '',
    };
  }

  componentDidMount() {
    this.updateLocation();
  }

  componentDidUpdate() {
    this.updateLocation();
  }

  updateLocation = () => {
    this.unlisten = this.props.history.listen(location => {
      this.event = { eventupdate: location, test: 'test' };
    });
    const location = this.props.location;
    if (location !== this.state.location) {
      this.setState({ location });
    }
  };

  faqClick = e => {
    if (navigator.userAgent.includes('Firefox')) {
      e.preventDefault();
      window.open(CONTACTS.HelpURL);
      return false;
    }
    return true;
  };

  render() {
    return (
      <footer>
        <div className="quick_links">
          <div className="constraint_wide">
            <div className="menu-quick-links-footer-container">
              <ul id="quick-links" className="text_links">
                <li
                  id="menu-item-418"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-418"
                >
                  <a href="https://www.darlington.co.uk/savings-accounts/">
                    Savings Accounts
                  </a>
                </li>
                <li
                  id="menu-item-431"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-431"
                >
                  <a href="https://www.darlington.co.uk/mortgages/">
                    Mortgages
                  </a>
                </li>
                <li
                  id="menu-item-417"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-417"
                >
                  <a href="https://www.darlington.co.uk/independent-financial-advice/">
                    Independent Financial Advice
                  </a>
                </li>
              </ul>
            </div>
            <ul className="social_links">
              <li>
                <a
                  href="https://www.facebook.com/DarlingtonBuildingSociety"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook-link"
                >
                  <Icon name="Facebook" />
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:Facebook" copytext="Facebook" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/darlingtonbs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter-link"
                >
                  <Icon name="Twitter" />
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:Twitter" copytext="Twitter" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/darlington-building-society"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  <Icon name="LinkedIn" />
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:LinkedIn" copytext="LinkedIn" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/darlingtonbs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="instagram-link"
                >
                  <Icon name="Instagram" />
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:Instagram" copytext="Instagram" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC0rvq6VOcjPdcRTWX6IAjSQ/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="youtube-link"
                >
                  <Icon name="Youtube" />
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:YouTube" copytext="YouTube" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer">
          <div className="constraint_wide">
            <div className="footer_column">
              <a
                className="footer-logo-link"
                href="/"
                title="Darlington Building Society"
              >
                <Icon name="FooterLogo" />
              </a>
              <address className="address">
                <Markdown
                  cmsTag="Footer:Address"
                  markdown={`Darlington Building Society   
                    Sentinel House, Morton Road,   
                    Darlington, County Durham, DL1 4PT.`}
                />
              </address>
              <p className="telephone">
                <Content cmsTag="Footer:Telephone:-" copytext="Tel: " />
                <ContactNumber contacts={CONTACTS} />
                <br />
                <Content cmsTag="Footer:Email:-" copytext="Email: " />
                <EmailAddress contacts={CONTACTS} />
              </p>
            </div>
            <div className="footer_column">
              <h2>
                <Content cmsTag="Footer:h2-1" copytext="Who We Are" />
              </h2>
              <ul id="footer-column-one" className="menu">
                <li
                  id="menu-item-3020"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3020"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/who-we-are/"
                  >
                    <Content cmsTag="Footer:link-1" copytext="Who We Are" />
                  </a>
                </li>
                <li
                  id="menu-item-3019"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3019"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/who-we-are/a-mutual-at-heart/"
                  >
                    <Content
                      cmsTag="Footer:link-2"
                      copytext="A mutual at heart"
                    />
                  </a>
                </li>
                <li
                  id="menu-item-1106"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1106"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/who-we-are/corporate-governance/"
                  >
                    <Content
                      cmsTag="Footer:link-3"
                      copytext="Corporate Governance"
                    />
                  </a>
                </li>
                <li
                  id="menu-item-1105"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1105"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/who-we-are/corporate-governance/annual-general-meeting-agm/"
                  >
                    <Content
                      cmsTag="Footer:link-4"
                      copytext="Annual General Meeting (AGM)"
                    />
                  </a>
                </li>
                <li
                  id="menu-item-3021"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3021"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/who-we-are/corporate-governance/the-board-of-directors/"
                  >
                    <Content
                      cmsTag="Footer:link-5"
                      copytext="The Board of Directors"
                    />
                  </a>
                </li>
                <li
                  id="menu-item-5884"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5884"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/who-we-are/corporate-governance/key-society-documents/"
                  >
                    <Content
                      cmsTag="Footer:link-6"
                      copytext="Key Society Documents"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer_column">
              <h2 className="smile">
                <Content cmsTag="Footer:h2-2" copytext="Useful Links" />
              </h2>
              <ul id="footer-column-two" className="menu">
                <li
                  id="menu-item-5882"
                  className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-3848 current_page_item menu-item-5882"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/darlingtonline/"
                  >
                    <Content cmsTag="Footer:link-6" copytext="Darlingtonline" />
                  </a>
                </li>
                <li
                  id="menu-item-5871"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5871"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/savings-accounts/"
                  >
                    <Content
                      cmsTag="Footer:link-7"
                      copytext="Savings Accounts"
                    />
                  </a>
                </li>
                <li
                  id="menu-item-5872"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5872"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/mortgages/"
                  >
                    <Content cmsTag="Footer:link-8" copytext="Mortgages" />
                  </a>
                </li>
                <li
                  id="menu-item-5731"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5731"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/our-community/"
                  >
                    <Content cmsTag="Footer:link-9" copytext="Our Community" />
                  </a>
                </li>
                <li
                  id="menu-item-5805"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5805"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/who-we-are/careers/"
                  >
                    <Content cmsTag="Footer:link-10" copytext="Careers" />
                  </a>
                </li>
                <li
                  id="menu-item-5733"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5733"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/media-hub/"
                  >
                    <Content cmsTag="Footer:link-11" copytext="Media Hub" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer_column">
              <h2>
                <Content cmsTag="Footer:h2-3" copytext="Help &amp; Contact" />
              </h2>
              <ul id="footer-column-three" className="menu">
                <li
                  id="menu-item-5873"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5873"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/member-support/feedback-and-complaints/"
                  >
                    <Content
                      cmsTag="Footer:link-12"
                      copytext="Feedback and Complaints"
                    />
                  </a>
                </li>
                <li
                  id="menu-item-4367"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4367"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/savings-accounts/savings-help/"
                  >
                    <Content cmsTag="Footer:link-13" copytext="Savings Help" />
                  </a>
                </li>
                <li
                  id="menu-item-4366"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4366"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/mortgages/mortgage-help/"
                  >
                    <Content cmsTag="Footer:link-14" copytext="Mortgage help" />
                  </a>
                </li>
                <li
                  id="menu-item-4368"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4368"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.darlington.co.uk/member-support/"
                  >
                    <Content
                      cmsTag="Footer:link-15"
                      copytext="Member Support"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer_legal_container">
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} className="footer_column">
              <p className="legal">
                <Content
                  cmsTag="Footer:p:legal"
                  copytext="Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Registered Number 205895."
                />
              </p>
              <div className="footer__imagelink">
                <a
                  href="https://www.fscs.org.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="fscs-logo"
                    src="/fscs-logo.jpg"
                    alt="FSCS Protected"
                  />
                  <small>
                    <Content
                      cmsTag="Footer:p:FSCS"
                      copytext="Financial Services Compensation Scheme"
                    />
                  </small>
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <div className="below_footer">
          <div className="constraint_wide">
            <div className="menu-legal-container">
              <ul id="legal-menu" className="menu">
                <li
                  id="menu-item-487"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-487"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/accessibility/"
                  >
                    Accessibility
                  </a>
                </li>
                <li
                  id="menu-item-486"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-486"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/privacy/"
                  >
                    Privacy
                  </a>
                </li>
                <li
                  id="menu-item-1111"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1111"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/cookies/"
                  >
                    Cookies
                  </a>
                </li>
                <li
                  id="menu-item-1112"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1112"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/site-terms-and-conditions/"
                  >
                    Site Terms and Conditions
                  </a>
                </li>
                <li
                  id="menu-item-1113"
                  className="menu-item
                        menu-item-type-post_type menu-item-object-page
                        menu-item-1113"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.darlington.co.uk/society-legals/"
                  >
                    Society Legals
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Connect.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  children: PropTypes.any,
};

export default withRouter(Connect);
