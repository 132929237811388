import React from 'react';
import PropTypes from 'prop-types';
import { mapStateToProps } from '@myie/interact-accounts';
import { connect } from 'react-redux';
import { NoMatch, AppMeta, Content } from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import LocalAccountDetails from './LocalAccountDetails';
import { CONTACTS } from '@myie/interact-local-dom';

class AccountDetailsTemplate extends React.Component {
  render() {
    const { accounts } = this.props;

    let account = {};
    const thisProps = this.props;
    if (accounts && accounts.Accounts) {
      account = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            thisProps.match.params.id
          );
        }
        return false;
      });
      if (!account) {
        return <NoMatch />;
      }
    } else {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={
            account && account.AccountName
              ? `Account details: ${account.AccountName}`
              : 'Account details'
          }
          metaDescription="Account Details for your account"
        />
        <LocalAccountDetails account={account} accounts={accounts} />
      </React.Fragment>
    );
  }
}

AccountDetailsTemplate.propTypes = {
  match: PropTypes.object,
  accounts: PropTypes.any,
};
export default connect(mapStateToProps)(AccountDetailsTemplate);
