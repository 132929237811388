import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Navbar, Nav, NavItem, TabPane, Row, Col } from 'reactstrap';
import { Check, Content, Markdown } from '@myie/interact-dom';
import Document from './Document';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as brandAccountsMapDispatch } from '@myie/interact-brand-accounts';

class TabDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadTypes: [],
      documentIdData: {
        id: '',
        count: 0,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      documents,
      account,
      activeTab,
      brandFetchDocumentsIfNeeded,
      accounts,
      isFetching,
    } = props;
    const { downloadTypes, documentIdData } = state;

    if (
      activeTab === 'statements' &&
      accounts &&
      !account.documents &&
      !isFetching
    ) {
      brandFetchDocumentsIfNeeded({
        AccountKey: account.AccountKey,
      });
    }
    if (documents && documents.Documents) {
      documents.Documents.forEach(document => {
        if (
          !downloadTypes.some(
            doc => doc.label === document.ExtendedProperties.DisplayType,
          )
        ) {
          const docType = {
            id: document.ExtendedProperties.DisplayType.replace(/\s+/g, ''),
            label: document.ExtendedProperties.DisplayType,
            checked: true,
          };
          downloadTypes.push(docType);
        }
      });

      return {
        downloadTypes,
        documentIdData,
      };
    } else return null;
  }

  onDocTypesChange = e => {
    const { downloadTypes } = this.state;
    let docTypeIndex = 0;
    downloadTypes.forEach(docType => {
      if (docType.id === e.target.id) {
        downloadTypes[docTypeIndex].checked = !docType.checked;
      }
      docTypeIndex++;
    });
    this.setState({ ...this.state, downloadTypes });
  };

  setDocumentId = id => {
    const { documentIdData } = this.state;
    documentIdData.count = 1;
    documentIdData.id = id;
    this.setState({ ...this.state, documentIdData });
  };

  getDocTypes = documentTypes => {
    const docTypeButtons = [];
    if (documentTypes) {
      documentTypes.forEach((docType, index) => {
        const id = docType.id;
        docTypeButtons.push(
          <NavItem key={index}>
            <Check
              key={id}
              label={docType.label}
              id={docType.label.replace(/\s+/g, '')}
              field={id}
              onChange={this.onDocTypesChange}
              value={docType.label}
              checked={docType.checked}
            />
          </NavItem>,
        );
      });
    }

    return docTypeButtons;
  };
  render() {
    const { activeTab, tablist, name, account } = this.props;
    const { downloadTypes } = this.state;
    const documentList = [];

    if (account && account.documents && account.documents.Documents) {
      let index = 0;
      account.documents.Documents.forEach(document => {
        if (
          downloadTypes.some(
            doc =>
              doc.label === document.ExtendedProperties.DisplayType &&
              doc.checked,
          )
        ) {
          documentList.push(
            <Document
              document={document}
              key={index}
              selectedAccount={account}
              setDocument={this.setDocumentId}
              documentIdData={this.state.documentIdData}
            />,
          );
        }
        index++;
      });
    }

    if (documentList.length === 0) {
      return null;
    }
    return (
      <TabPane
        id="statements"
        role="tabpanel"
        tabId="statements"
        aria-labelledby="statements-tab"
      >
        <Fade in={activeTab === 'statements'}>
          <div
            className="tab-focus"
            tabIndex="0"
            ref={tablist.refs.panels[tablist.refs.names.indexOf(name)]}
            onKeyDown={e => tablist.panelKeyDown(e, name, tablist.refs)}
          >
            <Navbar
              color="light"
              className="form-inline documents-navbar mb-4"
              aria-label="Transactions menu and filter bar"
            >
              <Content
                tag="h2"
                className="mt-0 mb-0"
                cmsTag="Accounts:Components:Details-page:Tabs:Document:Tab-document:h2-1"
                copytext="Download type"
              />
              <Nav>{this.getDocTypes(downloadTypes)}</Nav>
            </Navbar>

            <Markdown
              cmsTag="Accounts:Components:Details-page:Tabs:Document:Tab-document:p1"
              markdown={`\nPlease contact us if you would like a statement that doesn't appear in this list.`}
            />

            {documentList.length > 0 ? (
              <React.Fragment>
                <Row aria-hidden="true" className="pt-4">
                  <Col xs={3} md={2}></Col>
                  <Col xs={3} md={6}>
                    <small>
                      <Content
                        cmsTag="Accounts:Components:Details-page:Tabs:Document:Tab-document:Document-name"
                        copytext="Document name"
                      />
                    </small>
                  </Col>
                  <Col xs={3} md={2}>
                    <small>
                      <Content
                        cmsTag="Accounts:Components:Details-page:Tabs:Document:Tab-document:Document-type"
                        copytext="Document type"
                      />
                    </small>
                  </Col>
                  <Col xs={3} sm={3} md={2}></Col>
                </Row>
                <ul id="document-list" title="List of documents in date order">
                  {documentList}
                </ul>
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        </Fade>
      </TabPane>
    );
  }
}
TabDocument.propTypes = {
  account: PropTypes.object,
  activeTab: PropTypes.string,
  documents: PropTypes.object,
  brandFetchDocumentsIfNeeded: PropTypes.func,
  isFetching: PropTypes.bool,
  tablist: PropTypes.object,
  name: PropTypes.any,
  accounts: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandAccountsDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandAccountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...brandAccountsMapDispatch },
)(TabDocument);
