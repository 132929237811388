import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BreadcrumbItem, Alert } from 'reactstrap';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { mapDispatchToProps } from '@myie/interact-authentication';
import { CONTACTS } from '@myie/interact-local-dom';
import { mapDispatchToProps as brandAuthenticationDispatch } from '@myie/interact-brand-authentication';
import { Redirect } from 'react-router-dom';
import { storage } from './storageName';
import PersonalDetails from '../shared/PersonalDetails';
import AccountDetails from '../shared/AccountDetails';
import SecurityDetails from './SecurityDetails';
import { Session } from '@myie/interact';
import {
  TimeOut,
  Markdown,
  Content,
  Switch,
  AppMeta,
  stateUtility,
} from '@myie/interact-dom';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../shared/HO_components/checkDownTime';

const META_TITLE = {
  PersonalDetails: 'Registration Step 1',
  accountDetails: 'Registration Step 2',
  SecurityDetails: 'Registration Step 3',
};

class Register extends React.Component {
  constructor(props) {
    super(props);
    const { urlParams, refreshUrl, resetRegistration } = props;
    const stateList = ['PersonalDetails', 'accountDetails', 'SecurityDetails'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      props.stateData &&
      props.stateData.pageStage &&
      props.urlParams &&
      props.urlParams.pageStage
        ? props.urlParams.pageStage
        : 'PersonalDetails';
    if (!hasStage) {
      resetRegistration();
      stage = 'PersonalDetails';
      refreshUrl(stateList);
    }

    this.state = {
      stateList: stateList,
      stage: stage,
      hasStage: hasStage,
      message: null,
      credentialErrors: [],
      requestFailedCount: 0,
      data:
        props.stateData && props.stateData.formData
          ? props.stateData.formData
          : {},
      fetching: false,
    };
    stateUtility.setGlobalValue({ storage: storage.name });
  }

  componentWillUnmount() {
    const { resetActivation } = this.props;
    resetActivation();
  }

  componentDidMount() {
    let stage;
    if (this.props.match) {
      const { id } = this.props.match.params;
      if (id) {
        stage = id;
      }
    }
    if (stage !== 'activate') {
      return <Redirect to="/registration/register/" />;
    }
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, prevState) => {
    const {
      resetRegistration,
      registration = {},
      setupcredentialsActivate,
      credentialsSetup = {},
      updateUrl,
      urlParams,
    } = nextProps;

    if (credentialsSetup.Status === 'RequireActivation') {
      resetRegistration();

      setupcredentialsActivate({
        RequireActivationTicket:
          credentialsSetup && credentialsSetup.RequireActivationTicket
            ? credentialsSetup.RequireActivationTicket
            : '',
      });
    }

    if (
      registration.Status === 'Success' &&
      prevState.stage !== 'SecurityDetails' &&
      urlParams &&
      urlParams.pageStage &&
      urlParams.pageStage !== 'SecurityDetails'
    ) {
      let stateData = {
        url: {
          pageStage: 'SecurityDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'SecurityDetails',
        fetching: false,
      };
    }
    const credentialErrors =
      registration.ExtendedProperties &&
      registration.ExtendedProperties.IdentityResponse &&
      registration.ExtendedProperties.IdentityResponse.CredentialErrors
        ? registration.ExtendedProperties.IdentityResponse.CredentialErrors
        : null;
    let failedCount = prevState.requestFailedCount;
    if (credentialErrors) {
      failedCount = failedCount + 1;
    }

    //if account restricted or sopra invalid status
    if (
      registration &&
      (registration.Status === 'AccountRestricted' ||
        registration.Status === 'InvalidStatus')
    ) {
      failedCount = failedCount + 1;
      resetRegistration();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        message: registration.Status,
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
        fetching: false,
      };
    }

    // If ticket invalid
    if (registration && registration.Status === 'IdentifyAndVerifyFailed') {
      resetRegistration();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        message: 'IdentifyAndVerifyFailed',
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
        fetching: false,
      };
    }

    if (
      registration &&
      registration.ExtendedProperties &&
      registration.Status === 'InvalidAccountStatus'
    ) {
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        message: 'InvalidAccountStatus',
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
      };
    }

    if (
      registration &&
      registration.ExtendedProperties &&
      registration.ExtendedProperties.IdentityResponse &&
      registration.ExtendedProperties.IdentityResponse.CredentialErrors
    ) {
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      resetRegistration();
      return {
        stage: 'PersonalDetails',
        credentialErrors:
          registration.ExtendedProperties.IdentityResponse.CredentialErrors,
        fetching: false,
        requestFailedCount: failedCount,
      };
    }

    if (credentialsSetup.Status === 'InvalidTicket') {
      resetRegistration();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        data: {},
        stage: 'PersonalDetails',
        message: 'InvalidTicket',
        fetching: false,
      };
    }

    return null;
  };

  componentDidUpdate(prevProps) {
    const { stage, stateList } = this.state;
    let hasStage = false;
    if (prevProps.urlParams && prevProps.urlParams.pageStage) {
      hasStage = stateList.find(
        element => element === prevProps.urlParams.pageStage,
      );
    }
    if (
      prevProps.stateData &&
      prevProps.stateData.url &&
      prevProps.stateData.url.pageStage &&
      prevProps.urlParams &&
      prevProps.urlParams.pageStage &&
      hasStage &&
      stage !== prevProps.urlParams.pageStage
    ) {
      this.setState({
        ...this.state,
        stage: prevProps.urlParams.pageStage,
      });
    }
  }

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null, credentialErrors: [] });
  };

  // change the stage of the form
  setStage = stage => {
    if (stage !== 'PersonalDetails') {
      this.setState({
        ...this.state,
        stage,
        credentialErrors: [],
        message: null,
      });
    } else {
      this.setState({ ...this.state, stage, message: null });
    }
  };

  timeoutReset = () => {
    const { updateUrl } = this.props;
    let stateData = {
      url: {
        pageStage: 'PersonalDetails',
      },
    };
    updateUrl(stateData);
    this.setState({
      ...this.state,
      data: {},
      stage: 'PersonalDetails',
      message: 'Timeout',
    });
  };

  timeoutObj = {
    history: null,
    userLogout: this.props.userLogout,
    message: 'RegistrationTimeout',
    url: '/registration',
    onTimeout: this.timeoutReset,
    showTimeoutModal: this.props.showTimeoutModal,
    type: 'SECURITY_DETAILS',
  };

  timeout = new TimeOut(this.timeoutObj);

  // verify the identification and verfication entries

  changeStageAccount = () => {
    const { updateUrl, resetRegistration } = this.props;
    resetRegistration();
    let stateData = {
      url: {
        pageStage: 'accountDetails',
      },
    };
    updateUrl(stateData);
    this.setState({ ...this.state, fetching: false });
  };

  verify = () => {
    const { data } = this.state;
    const { register } = this.props;
    this.setState({ fetching: true });

    // personal data
    let accountNumber = '';
    switch (data.type_Of_account) {
      case 'SavingsAccount':
        accountNumber = data.savings_account_number;
        break;
      case 'MortgageAccount':
        accountNumber = data.mortgage_account_number;
        break;
      default:
    }

    const request = {
      TermsAndConditionsDocumentVersion: 'v1.0',
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
        {
          Type: 'lastName',
          Value: data.lastname,
        },
        {
          Type: 'dateOfBirth',
          Value: data.b_day,
        },
        {
          Type: 'postCode',
          Value: data.postcode,
        },
        {
          Type: 'accountNumber',
          Value: accountNumber,
        },
      ],
      ExtendedProperties: {},
    };

    register(request);
  };

  activate = () => {
    const { data } = this.state;
    const { register } = this.props;

    // personal data
    const request = {
      TermsAndConditionsDocumentVersion: 'v1.0',
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
      ],
      ExtendedProperties: {},
    };
    register(request);
  };

  checkIfFetching = () => {
    const { fetching } = this.state;
    return fetching;
  };

  getCredentialErrors = credentialErrors => {
    if (credentialErrors) {
      return credentialErrors.map(item => {
        switch (item) {
          case 'InvalidClientStatus':
            return (
              <Alert key={item} color="danger">
                <Markdown
                  cmsTag="Authentication:Registration:Register:alert-1"
                  template={{
                    markdown: {
                      phone: CONTACTS.phone,
                      phoneLink: CONTACTS.phoneLink,
                      companyName: CONTACTS.companyName,
                    },
                  }}
                  markdown={`There has been an issue verifying your identity. Please contact $[companyName] on <a href="tel:$[phoneLink]">$[phone]</a>, quoting status code 101.`}
                />
              </Alert>
            );
          case 'OnlineStatusX':
            return (
              <Alert key={item} color="danger">
                <Markdown
                  cmsTag="Authentication:Registration:Register:alert-2"
                  template={{
                    markdown: {
                      phone: CONTACTS.phone,
                      phoneLink: CONTACTS.phoneLink,
                      companyName: CONTACTS.companyName,
                    },
                  }}
                  markdown={`You are restricted from accessing your accounts online. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
                />
              </Alert>
            );
          case 'InvalidAccountStatus':
            if (!credentialErrors.includes('OnlineStatusX')) {
              return (
                <Alert key={item} color="danger">
                  <Markdown
                    cmsTag="Authentication:Registration:Register:alert-2"
                    template={{
                      markdown: {
                        phone: CONTACTS.phone,
                        phoneLink: CONTACTS.phoneLink,
                        companyName: CONTACTS.companyName,
                      },
                    }}
                    markdown={`There has been an issue accessing your account. Please contact $[companyName] on <a href="tel:$[phoneLink]">$[phone]</a>, quoting status code 102.`}
                  />
                </Alert>
              );
            }
        }
      });
    }
    return null;
  };

  // select current stage
  currentStage = message => {
    const {
      updateUrl,
      stateData,
      updateState,
      urlParams,
      refreshUrl,
    } = this.props;
    switch (this.state.stage) {
      case 'accountDetails':
        return (
          <AccountDetails
            saveData={this.saveData}
            setStage={this.setStage}
            updateUrl={updateUrl}
            verify={this.verify}
            disableForm={this.checkIfFetching()}
            message={message}
            privacyPolicy={true}
          />
        );

      case 'SecurityDetails':
        return (
          <SecurityDetails
            urlParams={urlParams}
            updateState={updateState}
            changeStageAccount={this.changeStageAccount}
            updateUrl={updateUrl}
            stateData={stateData}
            formData={this.state.data}
            timeout={this.timeout}
            setStage={this.setStage}
            disableForm={this.checkIfFetching()}
          />
        );
      case 'PersonalDetails':
      default:
        return (
          <PersonalDetails
            contacts={CONTACTS}
            urlParams={urlParams}
            updateUrl={updateUrl}
            refreshUrl={refreshUrl}
            saveData={this.saveData}
            data={this.state.data}
            setStage={this.setStage}
            verify={this.verify}
            disableForm={this.checkIfFetching()}
            message={message}
            parentPage="register"
          />
        );
    }
  };

  render() {
    const {
      registration = {},
      credentialsSetup = {},
      systemDownStatus, //From checkDownTime HOC
    } = this.props;
    let { stage, requestFailedCount, credentialErrors } = this.state;
    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }

    if (requestFailedCount === 3) {
      return <Redirect to="/registration/failed" />;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    // redirect on these credential setup responses
    switch (credentialsSetup.Status) {
      case 'Success':
        return <Redirect to="/registration/success" />;
      case 'Unsuccessful':
        return <Redirect to="/registration/unsuccessful" />;
      case 'Blocked':
        return <Redirect to="/registration/access-denied" />;
      case 'AlreadyActive':
        return <Redirect to="/registration/alreadyhascredentials" />;
      default:
    }

    // redirect on these varification responses
    switch (registration.Status) {
      case 'Blocked':
        return <Redirect to="/registration/access-denied" />;
      case 'NotAllowed':
        return <Redirect to="/registration/notallowed" />;
      case 'AlreadyActive':
        return <Redirect to="/registration/alreadyhascredentials" />;
      default:
    }

    const { message } = this.state;
    const detailErrors = this.getCredentialErrors(credentialErrors);

    return (
      <div id="registration-register">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={META_TITLE[stage]}
          metaDescription={META_TITLE[stage]}
        />
        {systemDownStatus.msg}
        <Content
          tag="h1"
          cmsTag="Authentication:Registration:Register:h1"
          copytext="Registration"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={this.state.stage === 'PersonalDetails'}
              className={
                this.state.stage === 'accountDetails' ||
                this.state.stage === 'SecurityDetails'
                  ? 'completed'
                  : ''
              }
            >
              {this.state.stage === 'PersonalDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'accountDetails' ||
              this.state.stage === 'SecurityDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                3:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Personal-details"
                copytext="Personal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem
              active={this.state.stage === 'accountDetails'}
              className={
                this.state.stage === 'SecurityDetails' ? 'completed' : ''
              }
            >
              {this.state.stage === 'accountDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'SecurityDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                3:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={this.state.stage === 'SecurityDetails'}>
              {this.state.stage === 'SecurityDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-3-" copytext="Step 3 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                3:&nbsp;
              </span>
              <Content cmsTag="BREADCRUMB:Security" copytext="Security" />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>

        {!detailErrors && (
          <Switch
            id="registration-alert"
            value={message || ''}
            tag="div"
            className="alert alert-danger"
            role="alert"
            template={{
              AccountRestricted: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                companyName: CONTACTS.companyName,
              },
              InvalidStatus: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                companyName: CONTACTS.companyName,
              },
            }}
            contents={{
              AccountRestricted: {
                defaultValue:
                  'You are restricted from accessing your accounts online. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.',
              },
              InvalidStatus: {
                defaultValue:
                  'There has been an issue accessing your account. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.',
              },
              Timeout: {
                defaultValue:
                  'For security reasons we have logged you out. Please restart the registration process.',
              },
              IdentifyAndVerifyFailedWarning: {
                defaultValue:
                  'Please be aware, as this is your fourth attempt, you only have one attempt left before a new authentication code needs to be generated.',
              },
              IdentifyAndVerifyFailed: {
                defaultValue:
                  'The details you have entered do not match our records. Please try again.',
              },
              InvalidTicket: {
                defaultValue:
                  'Your session has timed out. Please restart the registration process.',
              },
              Invalid: {
                defaultValue: `The details you have entered have not been recognised. Please check and try again.`,
              },
            }}
          />
        )}
        {detailErrors}
        {stage === 'PersonalDetails' ? (
          <React.Fragment>
            <Markdown
              id="importantNotice"
              markdown={`\nYou'll need to confirm your account and personal details. We will send an authentication code via a text message to the mobile number that is registered to your account to enter before you can sign in.`}
            />
          </React.Fragment>
        ) : null}

        {this.currentStage(message)}
      </div>
    );
  }
}

Register.propTypes = {
  credentialsSetup: PropTypes.object,
  stateData: PropTypes.object,
  history: PropTypes.any,
  register: PropTypes.any,
  refreshUrl: PropTypes.any,
  urlParams: PropTypes.any,
  updateUrl: PropTypes.any,
  activationCheck: PropTypes.any,
  registrationcheck: PropTypes.any,
  registration: PropTypes.object,
  showTimeoutModal: PropTypes.any,
  userLogout: PropTypes.any,
  updateState: PropTypes.func,
  isFetching: PropTypes.bool,
  resetActivation: PropTypes.any,
  credentialsActivate: PropTypes.any,
  match: PropTypes.any,
  resetRegistration: PropTypes.func,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    authenticationDefinition,
    custombrandAuthenticationDefinition,
  } = state;
  return {
    ...authenticationDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      {
        ...mapDispatchToProps,
        ...mapDispatchToProps_shared,
        ...brandAuthenticationDispatch,
      },
    )(Register),
  ),
  storage.name,
);
