import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class AddNominatedAccountReferredNoDocs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  uploadDocument = () => {
    const { clickNodocs, history, match } = this.props;
    let pageMode = 'myDetails';
    let accountId = null;
    if (
      match &&
      match.params &&
      match.params.page &&
      match.params.page !== 'undefined'
    ) {
      pageMode = match.params.page;
    }
    if (
      match &&
      match.params &&
      match.params.id &&
      match.params.id !== 'undefined' &&
      pageMode === 'account-details'
    ) {
      accountId = match.params.id;
    }
    history.push(
      `/nominated-account-for-withdrawals/upload/${pageMode}/${
        accountId ? accountId : ''
      }`,
    );
    if (clickNodocs) {
      clickNodocs();
    }
  };

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };
  render() {
    const { match, mode } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Your nominated account has been referred"
          metaDescription="Your nominated account has been referred"
        />
        <Content
          tag="h1"
          cmsTag="Accounts:Nominated-account:Referred-no-docs:h1"
          copytext={`Your request to ${mode} your nominated account has been referred`}
        />
        <Markdown
          cmsTag="Accounts:Nominated-account:Add-nominated-account:Referred-no-docs:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
              email: CONTACTS.email,
            },
          }}
          markdown={`\nYour request to ${mode} your nominated account has been referred and you have failed to upload the additional documents for further verification.\n\nPlease click on "Upload documents" to send us the information we require. If you have any questions, you can email <a href="tel:$[email]">$[email]</a>, or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our telephone lines are open $[openTimes].`}
        />

        <div className="form-button-group">
          <Button
            id="Upload-documents"
            type="submit"
            onClick={this.uploadDocument}
            color="primary"
          >
            <Content
              cmsTag="GLOBAL:Upload-documents"
              copytext="Upload documents"
            />
          </Button>

          {this.getBackButton(match.params.page)}
        </div>
      </React.Fragment>
    );
  }
}

AddNominatedAccountReferredNoDocs.propTypes = {
  page: PropTypes.any,
  match: PropTypes.any,
  clickNodocs: PropTypes.func,
  history: PropTypes.any,
  mode: PropTypes.string,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddNominatedAccountReferredNoDocs);
