import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { Validate } from '@myie/interact';
import IncomeAndExpenditure from './IncomeAndExpenditure';
import { Utility } from '@myie/interact';
import Chart from './Chart';
import { Content } from '@myie/interact-dom';
import moment from 'moment';
import { BUDGET_PLANNER } from './bp_mock';

const expiryDate = {
  expected: [],
  message: 'must be in the future',
  validate: function(value) {
    const month = value.substr(0, 2) - 0;
    const year = value.substr(3, 2) - 0;
    const now = new Date();
    const current = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const century = Math.floor(current.getFullYear() / 100);
    const date = new Date(century * 100 + year, month, 0);
    return date >= current;
  },
};

Validate.addTest(expiryDate, 'expiryDate');

class BudgetPlanner extends React.Component {
  constructor(props) {
    super(props);
    const { location = {} } = props;
    const { state: passedState = {} } = location;
    const { data } = passedState;
    this.state = { data: data, account: null };
    const { budgetPlanner, setBudgetPlanner } = props;
    if (!budgetPlanner) {
      setBudgetPlanner(BUDGET_PLANNER);
    }
    // if (!account) {
    //   getAccount({});
    // }
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  componentWillUnmount() {
    const { clearBudgetPlanner } = this.props;
    clearBudgetPlanner();
  }

  componentDidUpdate() {
    const { budgetSavePlanner, isBPAutoSave, history } = this.props;
    if (
      !this.state.data &&
      budgetSavePlanner &&
      budgetSavePlanner.status === 'Success' &&
      !isBPAutoSave
    ) {
      history.push('/signed-out');
    }
    const { match, accounts } = this.props;
    const { accountKey } = this.state;
    if (
      accounts &&
      match &&
      match.params &&
      match.params.id &&
      (!accountKey || match.params.id !== accountKey)
    ) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account, accountKey: match.params.id });
    }
  }

  calcAmountPerFrequency = (total, frequency) => {
    let amount;
    if (total === null) {
      total = 0;
    }
    switch (frequency) {
      case 'day':
        amount = (total * 365) / 12;
        break;
      case 'work day':
        amount = (total * 252) / 12; // Needs verifying
        break;
      case 'week':
        amount = (total * 52) / 12;
        break;
      case 'fortnight':
        amount = (total * 26) / 12;
        break;
      case 'four-week':
        amount = (total * 13) / 12;
        break;
      case 'quarter':
        amount = total / 3;
        break;
      case '6 months':
        amount = total / 6;
        break;
      case 'year':
        amount = total / 12;
        break;
      case 'month':
        amount = total;
        break;
      default:
        amount = 0;
    }
    return +amount.toFixed(2);
  };

  renderContent() {
    const { budgetPlanner, backPageMode } = this.props;
    const { account } = this.state;
    if (
      !this.state.data &&
      budgetPlanner &&
      budgetPlanner.income_and_expenditure.submitted
    ) {
      return (
        <React.Fragment>
          <Content
            tag="h1"
            className="mb-5"
            cmsTag="Savings-servicing:Money-tools:Budget-calculator:Index:Signed-in:h1"
            copytext="Thanks for signing in"
          />
          <Content
            tag="p"
            cmsTag="Savings-servicing:Money-tools:Budget-calculator:Index:Signed-in:p1"
            template={{
              copytext: {
                last_updated: moment(
                  budgetPlanner.income_and_expenditure.last_updated,
                ).format('LL'),
              },
            }}
            copytext="You completed a Budget Planner on $[last_updated]. There's nothing else we need you to do right now."
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div id="budget-calculator">
            <div className="left-container">
              <Content
                tag="h1"
                cmsTag="Savings-servicing:Money-tools:Budget-calculator:Index:h1"
                copytext="Budget calculator"
              />
              <Content
                tag="p"
                cmsTag="Savings-servicing:Money-tools:Budget-calculator:Index:p1"
                copytext="You can use our handy budget planner to calculate how much you can save after bills, utilities and other expenses. The information you enter will not be saved or stored to your account and will only be used to calculate how much you can save."
              />
              {!budgetPlanner || !account ? (
                <p aria-live="polite">
                  <Content
                    cmsTag="GLOBAL:Loading"
                    copytext="Please wait while the page loads."
                  />
                </p>
              ) : (
                <IncomeAndExpenditure
                  incomeAndExpenditure={budgetPlanner.income_and_expenditure}
                  calcAmountPerFrequency={this.calcAmountPerFrequency}
                />
              )}
            </div>
            <div className="right-container bp-right-container">
              <Chart
                data={this.state.data}
                calcAmountPerFrequency={this.calcAmountPerFrequency}
                backPageMode={backPageMode}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <AppMeta
          id="meta-data"
          stage="child"
          title="Budget Planner"
          metaDescription="Budget Planner description"
        /> */}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

BudgetPlanner.propTypes = {
  account: PropTypes.any,
  budgetPlanner: PropTypes.any,
  budgetSavePlanner: PropTypes.any,
  isBPAutoSave: PropTypes.any,
  getAccount: PropTypes.any,
  getBudgetPlanner: PropTypes.any,
  isFetching: PropTypes.any,
  location: PropTypes.object,
  history: PropTypes.object,
  accounts: PropTypes.object,
  resetPayByCard: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  match: PropTypes.any,
  updateBudgetPlan: PropTypes.any,
  clearBudgetPlanner: PropTypes.func,
  setBudgetPlanner: PropTypes.func,
  backPageMode: PropTypes.string,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapDispatchToPropsAccounts },
)(withRouter(BudgetPlanner));
