export const BUDGET_PLANNER_REQUEST = 'BUDGET_PLANNER_REQUEST';
export const BUDGET_PLANNER_SUCCESS = 'BUDGET_PLANNER_SUCCESS';
export const BUDGET_PLANNER_FAILURE = 'BUDGET_PLANNER_FAILURE';
export const UPDATE_BUDGET_PLANNER = 'UPDATE_BUDGET_PLANNER';
export const CLEAR_BUDGET_PLANNER = 'CLEAR_BUDGET_PLANNER';

export const BUDGET_PLANNER_SAVED_VERSION = 'BUDGET_PLANNER_SAVED_VERSION';

export const BUDGET_PLANNER_SAVE_SUCCESS = 'BUDGET_PLANNER_SAVE_SUCCESS';

export const BUDGET_PLANNER_ADD_CATEGORY_ITEM =
  'BUDGET_PLANNER_ADD_CATEGORY_ITEM';
export const BUDGET_PLANNER_DELETE_CATEGORY_ITEM =
  'BUDGET_PLANNER_DELETE_CATEGORY_ITEM';
