import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'reactstrap';
import { Validate } from '@myie/interact';
import { Link } from 'react-router-dom';
import {
  DropDown,
  TextArea,
  Content,
  AppMeta,
  CONSTANTS,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { RULES } from '../../shared/constants/rules';

class SendNewMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        relatedAccount: {
          rules: {
            stop: true,
            required: {
              message: 'Please select an account.',
            },
          },
        },
        subject: {
          rules: {
            stop: true,
            required: {
              message: 'Please select a subject.',
            },
          },
        },
        message: {
          rules: {
            stop: true,
            required: {
              message:
                'You have not reached the minimum amount of text in your message.',
            },

            format: {
              regex: RULES.messageBody,
              message: 'Please enter a valid message.',
            },
          },
        },
      },
      disabled: false,

      subjectOptions: [
        { key: 1, value: 'Maturity options' },
        { key: 2, value: 'Change personal details' },
        { key: 3, value: 'Transfer/Withdrawals' },
        { key: 4, value: 'Mortgage account enquiry ' },
        { key: 5, value: 'Other' },
      ],
      returnPages: {
        personalDetails: {
          path: '/personal-details/view',
          text: 'Back to personal details',
        },
      },
    };
  }

  accountOptions = (prefix, item, id) => {
    let name = item.AccountNumber;

    return (
      <option
        key={`${prefix}-${item.AccountKey.Key}-${id}`}
        id={id}
        value={item.AccountKey.Key}
      >
        {name}
      </option>
    );
  };

  subjectOptions = (prefix, item, id) => {
    return (
      <option key={`${prefix}-${item.key}-${id}`} id={id} value={item.value}>
        {item.value}
      </option>
    );
  };

  submit = e => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    e.preventDefault();
    let { form } = this.state;
    const { replyConversation, selectedData } = this.props;

    let messageBody = '';

    if (selectedData) {
      messageBody = selectedData.message;
      if (selectedData.relatedAccountObj) {
        messageBody = `${selectedData.message}`;
      }
    }
    let newForm = {};
    if (selectedData && selectedData.subject === '5') {
      newForm = { subject: form.subject, message: form.message };
    } else {
      newForm = form;
    }

    form = Validate.form(newForm);

    this.setState({ ...this.state, form });
    if (!form.approved) {
      this.setState({ disabled: false });
      return;
    }
    this.setState({ disabled: true });
    let request = {
      AccountKey: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.AccountKey
        : null,
      ConversationKey: null,
      MessageBody: messageBody,
      Reason: form.subject.value ? form.subject.value : 'General Enquiry',
      ProductType: selectedData.relatedAccountObj
        ? selectedData.relatedAccountObj.ExtendedProperties.AccountType
        : null,
      ExtendedProperties: {},
    };
    replyConversation(request);
  };

  onBlur = e => {
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onChangeAccount = e => {
    const { saveData, accounts } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    const accountKey = value;
    let source;
    form = Validate.input(name, value, form, true);
    if (accounts) {
      source = accounts.Accounts.find(
        account => account.AccountKey.Key === accountKey,
      );
    }
    saveData(name, value);
    saveData('relatedAccountObj', source);
    this.setState({ ...this.state, form });
  };

  onChangeSubject = e => {
    const { saveData } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onChange = e => {
    const { saveData } = this.props;
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    saveData(name, value);
    this.setState({ ...this.state, form });
  };

  render() {
    const { accounts, selectedData, location } = this.props;
    const { search } = location;
    const returnPage =
      search && queryString.parse(search)
        ? queryString.parse(search).returnPage
        : '';

    const { form, subjectOptions, returnPages } = this.state;
    const returnPageOption = returnPages[returnPage];
    let accountList = [];
    if (accounts.Accounts) {
      accountList = accounts.Accounts.filter(
        account =>
          account.AccountStatus !== 'Closed' &&
          account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED,
      );
    }
    let selectedSubject = '';
    let selectedAccount = null;
    let description = '';
    let hideInput = false;
    if (selectedData) {
      if (selectedData.relatedAccount) {
        form.relatedAccount.value = selectedData.relatedAccount;
        if (accounts) {
          selectedAccount = accounts.Accounts.find(
            account => account.AccountKey.Key === selectedData.relatedAccount,
          );
        }
      }

      form.message.value = selectedData.message;
      form.subject.value = selectedData.subject;
    }
    if (
      selectedData &&
      selectedData.subject &&
      (selectedData.subject === '7' ||
        selectedData.subject === '3' ||
        selectedData.subject === '5')
    ) {
      selectedSubject = subjectOptions.find(
        element => element.key === parseInt(selectedData.subject),
      );
      if (parseInt(selectedData.subject) === 5) {
        description =
          'Please provide accurate details of the information you wish to change.';
      }
      if (parseInt(selectedData.subject) === 7) {
        description =
          'Please enter your new nominated account holder name, account number & sort code within your message.';
      }
      if (parseInt(selectedData.subject) === 3) {
        description =
          "For new withdrawal requests, please enter your Notice account number, amount to withdraw & withdrawal date. If you'd like to cancel or update an existing withdrawal request, please provide the details of the particular Notice to withdraw.";
      }
      form.subject.value = selectedSubject ? selectedSubject.value : '';
      hideInput = true;
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Send a new message"
          metaDescription="Send a new message"
        />
        <Form autoComplete="off" id="messaging-twoway-new-message">
          {hideInput ? (
            <Row tag="dl" title="Message details">
              {selectedData.subject !== '5' ? (
                <React.Fragment>
                  <Col tag="dt" xs={12} sm={6} md={3}>
                    <Content
                      cmsTag="GLOBAL:Related-account"
                      copytext="Related account"
                    />
                  </Col>
                  <Col tag="dd" xs={12} sm={6} md={9}>
                    {selectedAccount
                      ? `${selectedAccount.AccountName} ${selectedAccount.AccountNumber} `
                      : ''}
                  </Col>
                </React.Fragment>
              ) : (
                ''
              )}
              <Col tag="dt" xs={12} sm={6} md={3}>
                <Content cmsTag="GLOBAL:Subject" copytext="Subject" />
              </Col>
              <Col tag="dd" xs={12} sm={6} md={9}>
                {form.subject.value}
              </Col>
            </Row>
          ) : (
            ''
          )}
          {!hideInput ? (
            <DropDown
              cmsTag="GLOBAL:Related-account"
              label="Related account"
              disabled={accountList.length === 0}
              id="related-account"
              field="relatedAccount"
              onChange={this.onChangeAccount}
              onBlur={this.onBlur}
              validation={form.relatedAccount}
            >
              <option
                key="0"
                id="related-account-default"
                value=""
                defaultText="Please select"
              ></option>
              <option
                key="1"
                id="related-account-option-1"
                value="general enquiry"
                defaultText="General enquiry"
              ></option>
              {accountList.map((item, index) => {
                return this.accountOptions(
                  'related-account',
                  item,
                  'related-account-option-' + (index + 2),
                );
              })}
            </DropDown>
          ) : (
            ''
          )}
          {!hideInput ? (
            <DropDown
              cmsTag="GLOBAL:Subject"
              label="Subject"
              id="subject"
              field="subject"
              onChange={this.onChangeSubject}
              onBlur={this.onBlur}
              validation={form.subject}
            >
              <option
                id="subject-option-default"
                key="0"
                value=""
                defaultText="Please select"
              ></option>
              {subjectOptions.map((item, index) => {
                return this.subjectOptions(
                  'subject',
                  item,
                  'subject-option-' + index,
                );
              })}
            </DropDown>
          ) : (
            ''
          )}

          <TextArea
            cmsTag="GLOBAL:Your-message"
            description={hideInput ? description : ''}
            label="Your message"
            id="message"
            field="message"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.message}
            maxLength="750"
            suffix={
              <Content
                id="chars-remaining"
                template={{
                  copytext: {
                    counter: () => {
                      return (
                        750 -
                        (this.state.form.message.value
                          ? this.state.form.message.value.length
                          : 0)
                      );
                    },
                  },
                }}
                copytext="You have $[counter] characters remaining."
              />
            }
          />

          <div className="form-button-group">
            <Button
              id="messaging-twoway-send-message"
              type="submit"
              color="primary"
              onClick={this.submit}
              disabled={this.state.disabled}
            >
              <Content cmsTag="GLOBAL:Send-message" copytext="Send message" />
            </Button>
            <Link
              id="messaging-twoway-send-cancel"
              className="btn btn-secondary"
              role="button"
              to={
                returnPageOption
                  ? returnPageOption.path
                  : '/messaging-twoway/view'
              }
            >
              <Content
                cmsTag="GLOBAL:Back-to-message-list"
                copytext={
                  returnPageOption
                    ? returnPageOption.text
                    : 'Back to message list'
                }
              />
            </Link>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

SendNewMessage.propTypes = {
  accounts: PropTypes.any,
  replyConversation: PropTypes.any,
  saveData: PropTypes.any,
  selectedData: PropTypes.any,
  location: PropTypes.any,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SendNewMessage),
);
