import React from 'react';

import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { Content, CONSTANTS, AppMeta } from '@myie/interact-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { ApplyAccountSummaryComponent } from '@myie/interact-savings-servicing-dom';
import PropTypes from 'prop-types';
import { CONTACTS } from '@myie/interact-local-dom';

class ShortApplyList extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { allProducts, setStateDynamic } = this.props;
    if (!allProducts) {
      return null;
    }
    let shortApplyAvailable = false;
    if (allProducts && allProducts.length > 0) {
      allProducts.forEach(product => {
        if (product.Display_For_Short_Apply === 'Y') {
          shortApplyAvailable = true;
        }
      });
    }

    return (
      <div id="short-apply-list">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Apply for a new savings account"
          metaDescription="Apply for a new savings account"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Short-apply:Short-apply-list:h1"
          copytext="Apply for a new online product"
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Short-apply:Short-apply-list:p1"
          copytext="Our online savings accounts offer an easy and secure way for you to manage your money online."
        />
        {!shortApplyAvailable ? (
          <Content
            tag="p"
            cmsTag="Savings-servicing:Short-apply:Short-apply-list:p2"
            copytext="Unfortunately we don't have any products available at this time, please check again later."
          />
        ) : (
          <ApplyAccountSummaryComponent
            setStateDynamic={setStateDynamic}
            page={'short-apply'}
            hideSelect={true}
            showIn={CONSTANTS.SHOW_IN_SHORTAPPLY}
            DepositProducts={allProducts ? allProducts : []}
          />
        )}
      </div>
    );
  }
}

ShortApplyList.propTypes = {
  accounts: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  updateUrl: PropTypes.func,
  getAllProducts: PropTypes.any,
  allProducts: PropTypes.any,
  setStage: PropTypes.func,
  setStateDynamic: PropTypes.func,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(ShortApplyList);
