import React, { Component } from 'react';
import { storage } from './../storageName';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import { Card, CardHeader, CardBody } from 'reactstrap';
import moment from 'moment';
import {
  CONSTANTS,
  dateValidation,
  Content,
  FormErrorList,
} from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Create from './Create';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../shared/HO_components/checkDownTime';
import queryString from 'query-string';
import { stateUtility } from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import {
  accountFilter,
  accountsEligibleToTranfer,
} from '@myie/interact-savings-servicing-dom';

class QuickTransfer extends Component {
  constructor(props) {
    super(props);
    const { allProducts, getAllProducts } = this.props;
    this.state = this.initialState(this.state);
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  initialState = state => {
    const { updateState, location, resetComponentState } = this.props;
    let stage = 'createTransfer';
    const stateObj = {
      excludePaths: [location.pathname],
    };
    updateState(stateObj);
    const currentState = queryString.parse(location.search);
    if (!currentState.editTransfer) {
      resetComponentState();
    }

    return {
      account: null,
      toAccount: null,
      combinedForm: {},
      accountForm: {
        accountFormValidation: {
          rules: {
            stop: true,
            required: {
              message: 'Please select an account to transfer from.',
            },
          },
          value: state && state.account ? state.account.AccountKey.Key : '',
        },
        toAccountFormValidation: {
          rules: {
            stop: true,
            required: {
              message: 'Please select an account to transfer to.',
            },
          },
          value: state && state.toAccount ? state.toAccount.AccountKey.Key : '',
        },
      },
      stage: stage,
      withdrawDate: moment(),
      data:
        this.props.stateData &&
        this.props.stateData.formData &&
        currentState.editTransfer
          ? this.props.stateData.formData
          : {},
    };
  };

  componentWillUnmount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }

  componentDidMount() {
    const {
      resetMakeWithdrawal,
      fetchAccountsIfNeeded,
      isAccountFetching,
    } = this.props;
    if (!isAccountFetching) {
      fetchAccountsIfNeeded();
    }
    resetMakeWithdrawal();
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const { makeWithdrawResponse = {}, location } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success'
    ) {
      return {
        stage: 'CompleteNotice',
      };
    }
    if (nextProps.accounts && !state.account) {
      let account = null;
      let toAccount = null;
      const currentState = queryString.parse(location.search);
      const { stateData } = nextProps;
      let { accountForm } = state;
      let newForm = accountForm;
      if (nextProps.accounts && currentState.editTransfer) {
        if (stateData && stateData.To) {
          toAccount = nextProps.accounts.Accounts.find(function(element) {
            return Utility.hexEncode(element.AccountKey.Key) === stateData.To;
          });

          newForm = Validate.input(
            'toAccountFormValidation',
            toAccount.AccountKey.Key,
            newForm,
          );
        }
        if (stateData && stateData.From) {
          account = nextProps.accounts.Accounts.find(function(element) {
            return Utility.hexEncode(element.AccountKey.Key) === stateData.From;
          });
          newForm = Validate.input(
            'accountFormValidation',
            account.AccountKey.Key,
            newForm,
          );
        }
      }
      return {
        account: account,
        toAccount: toAccount,
        accountForm: newForm,
      };
    }

    return null;
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  //update the stage in state

  callAccountValidations = () => {
    const { toAccount, accountForm, combinedForm } = this.state;
    window.scrollTo(0, 0);
    if (!toAccount) {
      let form = Validate.form(accountForm);
      this.setState({
        ...this.state,
        accountForm: form,
        combinedForm: {
          ...combinedForm,
          ...form,
        },
      });
      return;
    }
  };
  submitForm = form => {
    const { toAccount, account } = this.state;

    let stateData = {
      formData: this.state.data,
    };

    this.props.updateUrl(stateData);

    const data = {
      formData: this.state.data,
      From: Utility.hexEncode(account.AccountKey.Key),
      To: Utility.hexEncode(toAccount.AccountKey.Key),
      url: { page: 'account', pageStage: 'ReviewTransfer' },
    };
    const keys = Object.keys(data);
    keys.forEach(key => {
      stateUtility.setPageState('internalTransfer', data[key], key);
    });
    this.setState({ ...this.state, form, message: null });
    const newUrl = queryString.stringifyUrl({
      url: `/internal-transfer/create/moveMoney/${Utility.hexEncode(
        account.AccountKey.Key,
      )}/${Utility.hexEncode(toAccount.AccountKey.Key)}`,
      query: data.url,
    });
    this.props.history.push(newUrl);
  };

  getActionDate = account => {
    const { holidays } = this.props;
    let numberOfDays = '';
    if (account.ExtendedProperties.AccountType === CONSTANTS.REGULAR_SAVINGS) {
      let segments = account.AccountName.split(/\s+/);
      numberOfDays = parseInt(segments[0]);
    }

    const NumberOfDays = numberOfDays === 0 ? 0 : numberOfDays;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  onSubmitWithdrawal = () => {
    const { account, form } = this.state;
    const { makeaWithdraw } = this.props;

    let request = {
      AccountKey: account.AccountKey,
      WithdrawalInformation: null,
      WithdrawalDateSavings: this.getActionDate(account),
      WithdrawalRequestDateSavings: moment().format('YYYY-MM-DD'),
      WithdrawalFullBalanceSavings: 'FALSE',
      WithdrawalValueSavings: form.amount.value.replace(/,/g, ''),
      DepositAccountId: null,
    };

    makeaWithdraw(request);
  };

  // common onChangeAccount function
  onChangeFromAccount = e => {
    const { accounts, updateState } = this.props;
    const { value } = e.target;
    let { accountForm, combinedForm } = this.state;
    let newForm = accountForm;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      if (!account) {
        return;
      }
      newForm = Validate.input('accountFormValidation', value, accountForm);
      let object = {};
      object.From = Utility.hexEncode(account.AccountKey.Key);
      updateState(object);
      newForm = Validate.input('toAccountFormValidation', null, newForm);
      this.setState({
        account,
        toAccount: null,
        accountForm: newForm,
        combinedForm: { ...combinedForm, ...newForm },
      });
    }
  };
  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };
  // common onChangeAccount function
  onChangeToAccount = e => {
    const { accounts, updateState } = this.props;
    const { value } = e.target;
    let { accountForm, account, combinedForm } = this.state;
    let newForm = accountForm;
    if (accounts && accounts.Accounts) {
      const toAccount = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      if (!toAccount) {
        return;
      }
      newForm = Validate.input('toAccountFormValidation', value, accountForm);
      let object = {};
      object.To = Utility.hexEncode(toAccount.AccountKey.Key);
      updateState(object);
      if (!account) {
        newForm = Validate.input('accountFormValidation', '', newForm);
      }
      this.setState({
        toAccount,
        accountForm: newForm,
        combinedForm: { ...combinedForm, ...newForm },
      });
    }
  };
  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  componentDidUpdate(prevProps) {
    const { account, toAccount } = this.state;
    const { accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      let updatedAccount, updatedToAccount;
      if (account && Object.keys(account).length) {
        updatedAccount = accounts.Accounts.find(function(element) {
          return element.AccountKey.Key === account.AccountKey.Key;
        });
      }
      if (toAccount && Object.keys(toAccount).length) {
        updatedToAccount = accounts.Accounts.find(function(element) {
          return element.AccountKey.Key === toAccount.AccountKey.Key;
        });
      }

      this.setState({
        ...this.state,
        account: updatedAccount,
        toAccount: updatedToAccount,
      });
    }
  }

  render() {
    const {
      accounts,
      match,
      updateState,
      systemDownStatus, //From checkDownTime HOC
      allProducts,
    } = this.props;
    const { toAccount, account, combinedForm } = this.state;
    if (!accounts || !allProducts) {
      return null;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    const fromAccounts = accountFilter(accounts.Accounts, allProducts, true);
    const toAccounts = accountFilter(
      accounts.Accounts,
      allProducts,
      false,
      true,
    );

    if (accountsEligibleToTranfer(fromAccounts, toAccounts)) {
      return null;
    }

    return (
      <Card
        body
        id="widget-movemoney-transfer"
        color="secondary"
        className="mt-5"
      >
        {systemDownStatus.msg}
        <CardHeader>
          <Content
            tag="h2"
            cmsTag="Widget-quick-transfer:Transfer:h2-1"
            copytext="Quick Transfer"
          />
        </CardHeader>

        <CardBody>
          {this.state.stage === 'createTransfer' ? (
            <FormErrorList
              validations={combinedForm}
              disabled={false}
              required={true}
              groupClassName=""
              title="h2"
              showErrors={true}
            />
          ) : (
            ''
          )}
          {this.state.stage === 'createTransfer' ? (
            <React.Fragment>
              <Create
                setForm={this.setForm}
                callAccountValidations={this.callAccountValidations}
                accountForm={this.state.accountForm}
                setStateDynamic={this.setStateDynamic}
                updateState={updateState}
                submitForm={this.submitForm}
                onChangeFromAccount={this.onChangeFromAccount}
                onChangeToAccount={this.onChangeToAccount}
                fromMatch={match ? match.params.id : ''}
                saveData={this.saveData}
                data={this.state.data}
                account={account}
                toAccount={toAccount}
                fromAccounts={fromAccounts}
                toAccounts={toAccounts}
              />
            </React.Fragment>
          ) : (
            ''
          )}
        </CardBody>
      </Card>
    );
  }
}

QuickTransfer.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  resetComponentState: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  holidays: PropTypes.any,
  account: PropTypes.any,
  urlParams: PropTypes.any,
  updateState: PropTypes.any,
  stateData: PropTypes.any,
  location: PropTypes.any,
  refreshUrl: PropTypes.any,
  updateUrl: PropTypes.any,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  isAccountFetching: PropTypes.bool,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
    isAccountFetching: accountsDefinition.isFetching,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
    )(withRouter(QuickTransfer)),
    { moduleName: 'QuickTransfer' },
  ),
  storage.name,
);
