import React from 'react';
import PropTypes from 'prop-types';
import Category from './Category';
import { Content } from '@myie/interact-dom';

class IncomeAndExpenditure extends React.Component {
  render() {
    const { incomeAndExpenditure, calcAmountPerFrequency } = this.props;
    let IncCategories = [];
    let OutgCategories = [];
    incomeAndExpenditure.categories.forEach((category, index) => {
      if (category.type === 'income') {
        IncCategories.push(
          <Category
            key={`category-${category.type}`}
            category={category}
            categoryIndex={index}
            calcAmountPerFrequency={calcAmountPerFrequency}
          />,
        );
      } else {
        OutgCategories.push(
          <Category
            key={`category-${category.type}`}
            category={category}
            categoryIndex={index}
            calcAmountPerFrequency={calcAmountPerFrequency}
          />,
        );
      }
    });

    OutgCategories.push(
      <Category
        key={`category-debts`}
        category={incomeAndExpenditure.debts}
        categoryIndex={'debts'}
        calcAmountPerFrequency={calcAmountPerFrequency}
      />,
    );

    OutgCategories.push(
      <Category
        key={`category-court-payments`}
        category={incomeAndExpenditure.court_payments}
        categoryIndex={'court_payments'}
        calcAmountPerFrequency={calcAmountPerFrequency}
      />,
    );

    OutgCategories.push(
      <Category
        key={`category-assets`}
        category={incomeAndExpenditure.assets}
        categoryIndex={'assets'}
        calcAmountPerFrequency={calcAmountPerFrequency}
      />,
    );

    return (
      <React.Fragment>
        <div>{incomeAndExpenditure.description}</div>
        <Content
          tag="h2"
          cmsTag="Savings-servicing:Money-tools:Budget-calculator:Income-and-expenditure:h2-1"
          copytext="Income"
        />
        {IncCategories}
        <Content
          tag="h2"
          className="mt-5"
          cmsTag="Savings-servicing:Money-tools:Budget-calculator:Income-and-expenditure:h2-2"
          copytext="Outgoings"
        />
        <Content
          cmsTag="Savings-servicing:Money-tools:Budget-calculator:Income-and-expenditure:p1"
          tag="p"
          className="mb-0"
          copytext="Enter the total amount you pay, including towards any arrears you may have."
        />
        {OutgCategories}
      </React.Fragment>
    );
  }
}

IncomeAndExpenditure.propTypes = {
  incomeAndExpenditure: PropTypes.any,
  calcAmountPerFrequency: PropTypes.func,
};

export default IncomeAndExpenditure;
