import { Connection } from '@myie/interact';

import {
  RESET_GET_SUMMARY_BOX,
  GET_SUMMARY_BOX_REQUEST,
  GET_SUMMARY_BOX_SUCCESS,
  GET_SUMMARY_BOX_FALIURE,
} from './summaryBoxPdfTypes';

export const resetShortapply = () => ({
  type: RESET_GET_SUMMARY_BOX,
});

export const summaryBoxRequest = () => ({
  type: GET_SUMMARY_BOX_REQUEST,
  isFetching: true,
  error: null,
});

export const summaryBoxSuccess = json => ({
  type: GET_SUMMARY_BOX_SUCCESS,
  getSummaryBoxResponse: json,
  isFetching: false,
  error: null,
});

export const summaryBoxFaliure = error => ({
  type: GET_SUMMARY_BOX_FALIURE,
  isFetching: false,
  error: error.message,
});

export const getSummaryBox = request => dispatch => {
  dispatch(summaryBoxRequest());
  return fetch(Connection.baseUrl('accounts') + `v3.0/savings/summaryboxpdf`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.blob())
    .then(json => dispatch(summaryBoxSuccess(json)))
    .catch(error => dispatch(summaryBoxFaliure(error)));
};
