import SummaryMenuItems from './SummaryMenuItems';

const MoveMoneyMenuItems = [
  {
    url: '/details',
    menuItem: SummaryMenuItems,
  },
];

export default MoveMoneyMenuItems;
