import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import MortgageDetailSummary from './MortgageDetailSummary';
import SubAccountSummary from './SubAccountSummary';
import { Session } from '@myie/interact';
import { Nav, TabContent } from 'reactstrap';
import MortgageSummary from './MortgageSummary';

import _ from 'lodash';
import {
  TabNavItem,
  TabTransaction,
  TabDocument,
  TabSummary,
} from '@myie/interact-accounts-dom';
import { mapDispatchToProps as mapAccountsDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapDispatchToProps as brandAccountsMapDispatch } from '@myie/interact-brand-accounts';
import { Content } from '@myie/interact-dom';
import MortgageTabSummary from './MortgageTabSummary';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { storage } from './storageName';
import loadStateHOC from '../shared/stateManager/loadStateHOC';
import tabNavHOC from '../shared/HO_components/tabNavHOC';

class MortgageDetails extends React.Component {
  constructor(props) {
    super(props);
    const { account, tabInit } = props;

    let tabNames = [];
    let tabAccounts = account.ExtendedProperties.SubAccounts
      ? account.ExtendedProperties.SubAccounts
      : [];
    if (tabAccounts.length) {
      tabAccounts = tabAccounts.map((tabItem, index) => {
        const name = tabItem.ExtendedProperties.ProductDescription || '';
        const nameEditted = name.replace(/\s+/g, '-') + '-' + ++index;
        tabNames.push(nameEditted);
        tabItem.tabAccountName = nameEditted;
        tabItem.caption = name;
        return tabItem;
      });
    }
    tabInit(['summary', ...tabNames]);
    let tab =
      props.urlParams && props.urlParams.tab ? props.urlParams.tab : 'summary';
    this.state = {
      tabAccounts: tabAccounts,
      tabNames: tabNames,
      reversed: false,
      activeTab: tab,
      AccountKey: props.account.AccountKey.Key,
      count: 0,
      buttonStatus: false,
      allTransactions: [],
      transactionOffset: 0,
      listCount: 10,
      range: this.setInitialRange(props),
      downloadTypes: [],
      loadingTransactions: true,
    };
  }
  componentDidUpdate() {
    let { activeTab } = this.state;
    const { activeKeyTab } = this.props;
    if (activeKeyTab && activeKeyTab.tab && activeKeyTab.tab !== activeTab) {
      this.setTab(activeKeyTab.tab);
    }
  }

  setUpdateButton = status => {
    this.setState({ ...this.state, buttonStatus: status });
  };

  updateListCount = count => {
    this.setState({ ...this.state, listCount: count });
  };

  updateCountAndButton = (count, status) => {
    this.setState({ ...this.state, listCount: count, buttonStatus: status });
  };

  viewMore = (status, count) => {
    this.setState({
      ...this.state,
      viewMoreAvtive: true,
      listCount: count,
      buttonStatus: status,
    });
  };

  setInitialRange(props) {
    if (props.range) {
      return props.range;
    }
    const to = moment
      .utc()
      .startOf('day')
      .toISOString();
    const from = moment
      .utc()
      .subtract(1, 'months')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    return {
      To: to,
      From: from,
      Latest: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { AccountKey, loadingTransactions, ...rest } = state;
    const { account, fetchSavingsAccountTransactions } = props;
    if (
      account &&
      account.AccountKey &&
      account.AccountKey.Key &&
      AccountKey !== account.AccountKey.Key
    ) {
      let rangeLocal = state.range;
      const from = moment
        .utc()
        .subtract(25, 'years')
        .add(1, 'day')
        .startOf('day')
        .toISOString();
      rangeLocal.From = from;
      fetchSavingsAccountTransactions(
        {
          AccountKey: account.AccountKey,
          Range: rangeLocal,
          ExtendedProperties: {
            ListSubAccountNumbers: [
              account.ExtendedProperties.SubAccountNumber,
            ],
            BrandProductType: account.ExtendedProperties.AccountType,
            PaginationDetails: 'true',
            Limit: '50',
            Offset: '0',
          },
        },
        'loan',
      );
      return {
        ...rest,
        AccountKey: account.AccountKey.Key,
        loadingTransactions: true,
        allTransactions: [],
        transactionOffset: 50,
      };
    }

    if (
      account &&
      account.transactions &&
      account.transactions.Transactions &&
      loadingTransactions
    ) {
      return {
        ...rest,
        allTransactions: account.transactions.Transactions,
        loadingTransactions: false,
      };
    }
    if (
      account &&
      account.AccountKey &&
      account.AccountKey.Key &&
      AccountKey !== account.AccountKey.Key
    ) {
      return {
        ...rest,
        activeTab: 'summary',
        AccountKey: account.AccountKey.Key,
        count: 0,
      };
    }

    return null;
  }

  setCount = count => {
    this.setState({ ...this.state, count });
  };

  orderTransactions = () => {
    const { allTransactions } = this.state;
    let newList = [];
    if (allTransactions) {
      newList = allTransactions.map(transaction => {
        transaction.newDate = moment(
          transaction.ExtendedProperties.TransactionDate,
          'DD/MM/YYYY',
        ).unix();
        transaction.sortId = transaction.ExtendedProperties.TransactionId;
        return transaction;
      });
      newList = _.sortBy(newList, 'sortId');
      newList = _.sortBy(newList, 'newDate').reverse();
    }

    return newList;
  };

  getSubAccountContent = () => {
    let content = [];
    const { keyTabs } = this.props;
    const { tabAccounts, activeTab } = this.state;
    let { loopIndex = 0 } = this.props;
    tabAccounts.forEach((element, index) => {
      content.push(
        <MortgageTabSummary
          key={index}
          name={element.tabAccountName}
          activeTab={activeTab}
          tablist={keyTabs}
        >
          <SubAccountSummary
            loopIndex={loopIndex}
            account={element}
            brandProductType={element.ExtendedProperties.AccountType}
          />
        </MortgageTabSummary>,
      );
    });
    return content;
  };

  getSubAccountTabs = () => {
    let content = [];
    const { keyTabs } = this.props;
    const { tabAccounts, activeTab } = this.state;
    tabAccounts.forEach((element, index) => {
      content.push(
        <TabNavItem
          key={index}
          name={element.tabAccountName}
          caption={element.caption}
          activeTab={activeTab}
          tablist={keyTabs}
          onClick={e => this.setTabEvent(e)}
        />,
      );
    });
    return content;
  };

  componentDidMount() {
    const {
      getDownloadlinkUrl,
      account,
      fetchSavingsAccountTransactions,
    } = this.props;
    getDownloadlinkUrl(Session);

    const to = moment().startOf('day');
    const from = moment()
      .subtract(25, 'years')
      .add(1, 'day')
      .startOf('day');
    const range = {
      To: to.toISOString(),
      From: from.toISOString(),
      Latest: true,
    };

    fetchSavingsAccountTransactions(
      {
        AccountKey: account.AccountKey,
        Range: range,
        ExtendedProperties: {
          ListSubAccountNumbers: [account.ExtendedProperties.SubAccountNumber],
          BrandProductType: account.ExtendedProperties.AccountType,
          PaginationDetails: 'true',
          Limit: '50',
          Offset: '0',
        },
      },
      'loan',
    );
  }

  setTabEvent(e) {
    const { changeTab } = this.props;
    e.preventDefault();
    const current = e.target.href.substring(e.target.href.indexOf('#') + 1);
    changeTab(current);
  }

  setTab(tab) {
    const current = tab;
    switch (current) {
      case 'summary': {
        let rangeLocal = this.state.range;
        const from = moment
          .utc()
          .subtract(40, 'years')
          .add(1, 'day')
          .startOf('day')
          .toISOString();
        rangeLocal.From = from;

        break;
      }
      default:
    }
    let stateData = {
      url: {
        tab: current,
      },
    };
    this.props.updateUrl(stateData);
    this.setState({
      ...this.state,
      activeTab: current,
      buttonStatus: false,
      listCount: 10,
    });
  }

  render() {
    const { activeTab, range, loadingTransactions, tabAccounts } = this.state;
    const { props } = this;
    let {
      account,
      accounts,
      keyTabs,
      downloadlinkUrl,
      location = { pathname: '' },
      fetchSavingsAccountTransactionsIfNeeded,
      loopIndex = 0,
    } = props;

    let currentAccount = null;
    let orderedTransactions = this.orderTransactions();
    if (activeTab !== 'summary') {
      const accountList = account.ExtendedProperties.SubAccounts;
      const accountName = activeTab.replace(/-+/g, ' ');
      const selectedAccount = accountList.find(
        element => element.AccountName === accountName,
      );
      if (selectedAccount) {
        orderedTransactions = orderedTransactions.filter(transaction => {
          const { ExtendedProperties: { SubAccountId } = {} } = transaction;
          return selectedAccount.AccountNumber === SubAccountId;
        });
      }
    }

    if (accounts && accounts.Accounts) {
      currentAccount = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            location.pathname.split('/').pop()
          );
        }
        return false;
      });
    }

    if (!currentAccount) {
      currentAccount = account;
    }

    let activeTabOverride = activeTab;

    return (
      <div id={`account-details-${loopIndex}`} className="account-header">
        <MortgageSummary
          loopIndex={loopIndex}
          account={account}
          pageMode={'detail'}
        />
        <Content
          tagClassName="sr-only"
          tag="span"
          cmsTag="Account-type-mortgage:Mortgage-details:Account-details-tab-menu"
          copytext="Account details tab menu"
        />
        <Nav tabs aria-labelledby="mortgage-account-tab-menu" role="tablist">
          {keyTabs ? (
            <React.Fragment>
              <TabNavItem
                name="summary"
                caption="Overall Mortgage"
                activeTab={activeTabOverride}
                tablist={keyTabs}
                onClick={e => this.setTabEvent(e)}
              />
              {account.ExtendedProperties.SubAccounts &&
              account.ExtendedProperties.SubAccounts.length
                ? this.getSubAccountTabs()
                : ''}
            </React.Fragment>
          ) : (
            ''
          )}
        </Nav>
        <TabContent activeTab={activeTabOverride}>
          {keyTabs ? (
            <React.Fragment>
              {' '}
              <TabSummary
                name="summary"
                activeTab={activeTabOverride}
                tablist={keyTabs}
              >
                <MortgageDetailSummary
                  loopIndex={loopIndex}
                  account={account}
                  tabAccounts={tabAccounts}
                  brandProductType={account.ExtendedProperties.AccountType}
                />
                <TabDocument
                  name="statements"
                  account={account}
                  activeTab={activeTabOverride}
                  tablist={keyTabs}
                />
                {keyTabs && (
                  <TabTransaction
                    name="transactions"
                    accountType="mortgage"
                    fetchTransactions={fetchSavingsAccountTransactionsIfNeeded}
                    account={account}
                    propTransactions={orderedTransactions}
                    downloadlinkUrl={downloadlinkUrl}
                    activeTab={'transactions'}
                    setUpdateButton={this.setUpdateButton}
                    updateListCount={this.updateListCount}
                    updateCountAndButton={this.updateCountAndButton}
                    listCount={this.state.listCount}
                    viewMore={this.viewMore}
                    buttonStatus={this.state.buttonStatus}
                    tablist={keyTabs}
                    setCount={this.setCount}
                    count={this.state.count}
                    range={range}
                    loading={loadingTransactions}
                    interestRates={
                      this.props.interestHistory
                        ? this.props.interestHistory
                        : {}
                    }
                  />
                )}
              </TabSummary>
              {account.ExtendedProperties.SubAccounts &&
              account.ExtendedProperties.SubAccounts.length
                ? this.getSubAccountContent()
                : ''}
            </React.Fragment>
          ) : (
            ''
          )}
        </TabContent>
      </div>
    );
  }
}

MortgageDetails.propTypes = {
  account: PropTypes.any,
  accounts: PropTypes.object,
  documents: PropTypes.object,
  tabInit: PropTypes.object,
  activeKeyTab: PropTypes.object,
  fetchSavingsAccountIfNeeded: PropTypes.any,
  fetchSavingsAccountTransactions: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.any,
  fetchDocumentsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.number,
  getInterestHistory: PropTypes.any,
  interestHistory: PropTypes.any,
  updateUrl: PropTypes.any,
  location: PropTypes.any,
  isFetching: PropTypes.any,
  urlParams: PropTypes.any,
  range: PropTypes.any,
  changeTab: PropTypes.any,
  keyTabs: PropTypes.any,
  downloadlinkUrl: PropTypes.any,
  savedTransactions: PropTypes.array,
  saveTransactions: PropTypes.func,
  getDownloadlinkUrl: PropTypes.func,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandAccountsDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandAccountsDefinition,
  };
};

export default loadStateHOC(
  tabNavHOC(
    withRouter(
      connect(
        mapStateToProps,
        {
          ...mapDispatchToProps,
          ...mapAccountsDispatchToProps,
          ...brandAccountsMapDispatch,
        },
      )(MortgageDetails),
    ),
  ),
  storage.name,
);
