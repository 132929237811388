import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DefaultSidebar from './DefaultSidebar';
import { withRouter } from 'react-router-dom';
import RegisteredSidebars from './CustomSidebars';
import { TwoFactorSidebars } from '@myie/interact-two-factor-dom';
import queryString from 'query-string';

TwoFactorSidebars.forEach(sb => {
  RegisteredSidebars.push(sb);
});

const HasSidebar = props => {
  const pathname = props.location.pathname;
  let Sidebar = DefaultSidebar;

  RegisteredSidebars.forEach(sb => {
    if (pathname && pathname.startsWith(sb.url)) {
      if (pathname !== sb.url && sb.url === '/short-apply/index') {
        Sidebar = null;
      } else {
        Sidebar = sb.Sidebar;
      }
    }
  });

  if (Sidebar) {
    return true;
  } else {
    return false;
  }
};

class Sidebar extends Component {
  render() {
    const pathname = this.props.location.pathname;
    let Sidebar = DefaultSidebar;
    const { location } = this.props;
    const currentState = queryString.parse(location.search);

    RegisteredSidebars.forEach(sb => {
      if (pathname && pathname.startsWith(sb.url)) {
        if (
          (sb.url === '/registration/' || sb.url === '/forgotten-password/') &&
          currentState.twoFa === 'active'
        ) {
          RegisteredSidebars.forEach(sb => {
            if (sb.url === '2fa') {
              Sidebar = sb.Sidebar;
            }
          });
        } else if (pathname !== sb.url && sb.url === '/short-apply/index') {
          Sidebar = null;
        } else {
          Sidebar = sb.Sidebar;
        }
      }
    });

    if (Sidebar !== null) {
      return <Sidebar />;
    } else {
      return null;
    }
  }
}

Sidebar.propTypes = {
  location: PropTypes.any,
};

export default withRouter(Sidebar);
export { HasSidebar };
