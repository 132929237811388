import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route } from 'react-router-dom';
import CreateTranfer from './CreateTranfer/CreateTransfer';
import MaxAccounts from './CreateTranfer/MaxAccounts';

class TransferRoute extends Component {
  render() {
    return (
      <div id="internal-transfer">
        <Switch>
          <PrivateRoute
            path="/internal-transfer/create/:page?/:id?/:toId?"
            component={CreateTranfer}
          />
          <PrivateRoute
            path="/internal-transfer/max-accounts-limit"
            component={MaxAccounts}
          />

          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export { TransferRoute };
