import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Content, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class MarketingPreferences extends React.Component {
  render() {
    const { marketingPreferences } = this.props;

    return (
      <section className="mt-5">
        <Content
          tag="h2"
          cmsTag="User-management:Personal-details:View:Marketing-preferences:h2-1"
          copytext="Contact preferences"
        />
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:View:Marketing-preferences:p1"
          copytext="From time to time we will need to contact you with important information for example, changes to your savings account interest rate, and other regulatory information we are required to send you."
        />
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:View:Marketing-preferences:p2"
          copytext="We have an ongoing commitment to reduce our environmental impact and we would like to send this information to you by email. If you are happy for us to do this, please tick the box. Otherwise, we will keep you up to date with important account information by post."
        />
        <Row tag="dl" title="Marketing preferences list">
          <Col tag="dt" xs={12} sm={4} md={9}>
            {marketingPreferences?.ContactPreference === 'W' ? (
              <React.Fragment>
                <Content
                  cmsTag="GLOBAL:Preferred-contact-method"
                  copytext="Preferred contact method: "
                />{' '}
                <Content cmsTag="GLOBAL:Post" copytext="Post" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Content
                  cmsTag="GLOBAL:Preferred-contact-method"
                  copytext="Preferred contact method: "
                />{' '}
                <Content cmsTag="GLOBAL:Email" copytext="Email" />
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:View:Marketing-preferences:p3"
          copytext="We also have a society newsletter which highlights any new savings accounts, mortgages, and other offers that we think you may be interested in by email. You can unsubscribe from the newsletter at any time by clicking unsubscribe on the email."
        />
        <Markdown
          template={{
            markdown: {
              companySiteName: CONTACTS.companySiteName,
            },
          }}
          cmsTag="User-management:Personal-details:View:Marketing-preferences:section-1"
          markdown={`If you ever need to change your contact preferences, please give us a call, email or pop into one of our branches. You can also update your preferences within $[companySiteName].`}
        />
        <br />
        <br />
        <Markdown
          template={{
            markdown: {
              privacyPolicyURL: CONTACTS.privacyPolicyURL,
            },
          }}
          cmsTag="User-management:Personal-details:View:Marketing-preferences:section-2"
          markdown={`To find out how we use your information, and keep it safe, please read our <a target="_blank" href="$[privacyPolicyURL]">Privacy Policy</a> for more information.`}
        />
      </section>
    );
  }
}

MarketingPreferences.propTypes = {
  userDetails: PropTypes.object,
  marketingPreferences: PropTypes.object,
};

export default MarketingPreferences;
