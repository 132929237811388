import {
  TRANSACTIONS_ARRAY,
  DOWNLOAD_URL,
  FIRST_TIME_SIGN_IN,
} from '../actions/accountsTypes';

const accountDetailsDefinition = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTIONS_ARRAY:
      return {
        ...state,
        savedTransactions: action.savedTransactions,
      };
    case FIRST_TIME_SIGN_IN:
      return {
        ...state,
        firstTimeSignIn: action.firstTimeSignIn,
      };
    case DOWNLOAD_URL:
      return {
        ...state,
        downloadlinkUrl: action.downloadlinkUrl,
      };
    default:
      return state;
  }
};

export default accountDetailsDefinition;
