import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { AppMeta, Content } from '@myie/interact-dom';
import { mapDispatchToProps as brandUserManagementDispatch } from '@myie/interact-user-management';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CONTACTS } from '@myie/interact-local-dom';

class ViewNominatedAccount extends React.Component {
  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const {
      match,
      nominatedAccountDetails: {
        NominatedAccount: { AccountNumber, Name, SortCode } = {},
      } = {},
    } = this.props;

    const editURL = `/nominated-account-for-withdrawals/edit/${
      match.params.page ? match.params.page + '/' : ''
    }${match.params.id ? match.params.id : ''}`;

    return (
      <div id="nominated-account-details">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Your nominated account for withdrawals"
          metaDescription="Your nominated account for withdrawals"
        />
        <Content
          tag="h1"
          cmsTag="Accounts:Nominated-account:View-nominated-account:h1"
          copytext="Your nominated account for withdrawals"
        />
        <Content
          tag="p"
          cmsTag="Accounts:Nominated-account:View-nominated-account:p1"
          copytext="This is a list of the nominated accounts in your name that you can transfer money to."
        />
        <Content
          tag="h2"
          cmsTag="Accounts:Nominated-account:View-nominated-account:h2-1"
          copytext="Account details"
        />
        <Row tag="dl" title="Add nominated account">
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Name-of-account-holder"
              copytext="Name of account holder"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {Name}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Account-number" copytext="Account number" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {AccountNumber}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {SortCode}
          </Col>
        </Row>
        <div className="form-button-group">
          <Link
            id={`edit-nominated-account`}
            className={`btn btn-primary`}
            role="button"
            to={editURL}
          >
            <Content
              cmsTag="GLOBAL:Edit-nominated-account"
              copytext="Edit nominated account"
            />
          </Link>

          {this.getBackButton(match.params.page)}
        </div>
      </div>
    );
  }
}

ViewNominatedAccount.propTypes = {
  nominatedAccountDetails: PropTypes.object,
  match: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = state => {
  const { userManagementDefinition } = state;
  return {
    ...userManagementDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...brandUserManagementDispatch,
  },
)(ViewNominatedAccount);
