/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import { AccountsSidebars } from '@myie/interact-accounts-dom'
import { AuthenticationSidebars } from '@myie/interact-authentication-dom'
import { TwoFactorSidebars } from '@myie/interact-two-factor-dom'
import { MessagingTwowaySidebars } from '@myie/interact-messaging-twoway-dom'
import { StyleguideSidebars } from '@myie/interact-styleguide-dom'
import {SavingsServicingSidebars } from '@myie/interact-savings-servicing-dom'
 

const RegisteredSidebars = []

AccountsSidebars.forEach(sb => {
	RegisteredSidebars.push(sb)
}); 

AuthenticationSidebars.forEach(sb => {
	RegisteredSidebars.push(sb)
}); 

TwoFactorSidebars.forEach(sb => {
	RegisteredSidebars.push(sb)
}); 

MessagingTwowaySidebars.forEach(sb => {
	RegisteredSidebars.push(sb)
}); 

SavingsServicingSidebars.forEach(sb => {
	RegisteredSidebars.push(sb)
}); 

StyleguideSidebars.forEach(sb => {
	RegisteredSidebars.push(sb)
}); 



export default RegisteredSidebars
