import React, { Component } from 'react';
import { Content } from '@myie/interact-dom';
import { Col, Row, Button, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';

class AddNominatedAccountSuccessConfirm extends Component {
  submit = () => {
    const { submitForm } = this.props;
    submitForm();
  };

  editDetails = () => {
    const { updateUrl, setStage, data } = this.props;
    let stateData = {
      formData: data,
      url: {
        pageStage: 'AccountDetails',
      },
    };
    updateUrl(stateData);
    setStage('AccountDetails');
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { match, stage, data } = this.props;
    return (
      <React.Fragment>
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem className="completed">
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="BREADCRUMB:Completed"
                copytext="Completed: "
              />

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'Confirm'}>
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="BREADCRUMB:Active"
                copytext="Active: "
              />

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>

        <div className="confirm-account-details-add-nominated-account">
          <Row tag="dl" title="Nominated account details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Name-of-account-holder"
                copytext="Name of account holder"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['accountHolderName'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Account-number"
                copytext="Account number"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['accountNumber'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['sortcode'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Payee-reference"
                copytext="Payee reference"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['payeeReference'] : ''}
            </Col>
          </Row>
        </div>
        <Content
          tag="p"
          cmsTag="Accounts:Nominated-account:Add-nominated-account:Confirm:p1"
          copytext={`When you confirm your account details, we may ask you to provide documentation to prove the account is yours if our checks are not sufficient.`}
        />
        <div className="form-button-group">
          <Button
            id="confirm-transfer"
            type="submit"
            onClick={this.submit}
            color="primary"
          >
            <Content
              cmsTag="GLOBAL:Confirm-details"
              copytext="Confirm details"
            />
          </Button>
          <Button
            id="edit-transfer"
            type="submit"
            onClick={this.editDetails}
            color="light"
          >
            <Content cmsTag="GLOBAL:Edit-details" copytext="Edit details" />
          </Button>
          {this.getBackButton(match.params.page)}
        </div>
      </React.Fragment>
    );
  }
}

AddNominatedAccountSuccessConfirm.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  stage: PropTypes.any,
  updateUrl: PropTypes.func,
  submitForm: PropTypes.func,
  data: PropTypes.any,
  setStage: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(AddNominatedAccountSuccessConfirm));
