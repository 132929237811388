import React from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Success extends React.Component {
  render() {
    return (
      <div id="user-management-edit-success">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Edit personal details success"
          metaDescription="Edit personal details success"
        />
        <Content
          tag="h1"
          cmsTag="User-management:Personal-details:Success:h1"
          copytext="Your personal details have been updated"
        />
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:Success:p1"
          copytext="You have updated your personal details."
        />
        <div className="form-button-group">
          <Link
            className="btn btn-secondary"
            role="button"
            to="/personal-details/view"
          >
            <Content
              cmsTag="GLOBAL:Back-to-personal-details"
              copytext="Back to personal details"
            />
          </Link>
        </div>
      </div>
    );
  }
}

Success.propTypes = {};

export default Success;
