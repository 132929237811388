import { RULES } from '../../shared/constants/rules';

export const form = {
  firstname: {
    rules: {
      stop: true,
      required: {
        message: 'Please enter your first name.',
      },
      format: {
        regex: RULES.firstName,
        message: 'Please enter a valid first name.',
      },
    },
  },
  lastname: {
    rules: {
      stop: true,
      required: {
        message: 'Please enter your last name.',
      },
      format: {
        regex: RULES.lastName,
        message: 'Please enter a valid last name.',
      },
    },
  },
  b_day: {
    rules: {
      title: 'bday',
      stop: true,
      required: {
        message: 'Please enter your date of birth.',
      },
      pastFormat: {
        message: 'Your date of birth cannot be a future date.',
      },
      format: {
        regex: /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
        message: 'Please enter a valid date',
      },
      under13: {
        message: 'You must be over 13 to register.',
      },
    },
  },
  postcode: {
    rules: {
      title: 'Postcode',
      stop: true,
      required: {
        message: 'Please enter your home postcode.',
      },
      postcode: {
        message: 'Please enter a valid home postcode.',
      },
    },
  },
};
