import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { InputGroup, InputGroupText, Button, Input, Label } from 'reactstrap';
import { Validate } from '@myie/interact';
import { Content, Markdown } from '@myie/interact-dom';
import Errors, { HasErrors } from './Errors';

class AddressLookupQAS extends React.Component {
  constructor(props) {
    super(props);
    const {
      label,
      subLabel,
      description,
      placeholder,
      suffix,
      validation,
      managedContent,
      lookupForm,
    } = props;

    this.state = {
      error: false,
      results: [],
      isSelected: lookupForm.addressInput.value ? true : false,
    };

    this.searchAddressInput = React.createRef(null);

    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      placeholder: { defaultValue: placeholder },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  }

  componentDidMount() {
    this.props.resetGetAddressList();
  }

  componentDidUpdate() {
    const { addressList } = this.props;
    const { results } = this.state;

    if (JSON.stringify(results) !== JSON.stringify(addressList)) {
      this.setState({ results: addressList });
    }
  }

  clearSelectedAddress = () => {
    const { resetGetAddressList, addressIndex } = this.props;
    resetGetAddressList();
    this.setState(
      {
        results: [],
        isSelected: false,
      },
      () => {
        this.props.onSelectAddress('addressInput', '', addressIndex);
        if (this.searchAddressInput && this.searchAddressInput.current) {
          this.searchAddressInput.current.focus();
        }
      },
    );
  };

  onClickAddress = address => {
    const { getAddress } = this.props;
    getAddress(address.format, this.selectGetAddress);
  };

  selectGetAddress = () => {
    const { addressDetails, addressIndex } = this.props;
    this.setState(
      {
        results: [],
        isSelected: true,
      },
      () => {
        this.props.onSelectAddress(
          'addressInput',
          addressDetails.address,
          addressIndex,
        );
      },
    );
  };

  handleChange = e => {
    let inputValue = e.target.value;
    const {
      onChangeAddressSearchInput,
      resetGetAddressList,
      addressIndex,
    } = this.props;

    if (inputValue && inputValue.trim()) {
      this.props.onSelectAddress('addressInput', inputValue, addressIndex);
      onChangeAddressSearchInput(inputValue, addressIndex);
    } else {
      this.clearSelectedAddress();
      resetGetAddressList();
    }
  };

  stringToHTML = str => {
    let dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  };

  getTitle = results => {
    const { title = 'h2', id = '' } = this.props;

    console.log(title);

    switch (title) {
      case 'h2':
        return (
          <React.Fragment>
            {results?.length > 1 ? (
              <h2 id={`${id}-result-list-title`}>
                <Markdown
                  template={{
                    markdown: {
                      adressNumber: results.length,
                    },
                  }}
                  cmsTag="GLOBAL:We-found-ADDRESSNUMBER-addresses"
                  markdown={`We found $[adressNumber] addresses:`}
                />
              </h2>
            ) : (
              <Content
                tag="h2"
                cmsTag="GLOBAL:We-found-1-address"
                copytext="We found 1 address:"
              />
            )}
          </React.Fragment>
        );
      case 'h3':
        return (
          <React.Fragment>
            {results?.length > 1 ? (
              <h3 id={`${id}-result-list-title`}>
                <Markdown
                  template={{
                    markdown: {
                      adressNumber: results.length,
                    },
                  }}
                  cmsTag="GLOBAL:We-found-ADDRESSNUMBER-addresses"
                  markdown={`We found $[adressNumber] addresses:`}
                />
              </h3>
            ) : (
              <Content
                tag="h3"
                cmsTag="GLOBAL:We-found-1-address-"
                copytext="We found 1 address:"
              />
            )}
          </React.Fragment>
        );
      case 'h4':
        return (
          <React.Fragment>
            {results?.length > 1 ? (
              <h4 id={`${id}-result-list-title`}>
                <Markdown
                  template={{
                    markdown: {
                      adressNumber: results.length,
                    },
                  }}
                  cmsTag="GLOBAL:We-found-ADDRESSNUMBER-addresses"
                  markdown={`We found $[adressNumber] addresses:`}
                />
              </h4>
            ) : (
              <content
                tag="h4"
                cmsTag="GLOBAL:We-found-1-address-"
                copytext="We found 1 address:"
              />
            )}
          </React.Fragment>
        );

      default:
    }
  };

  render() {
    const { props } = this;

    const {
      inputGroupClassName = '',
      suffixIsDiv,
      suffixAlert,
      groupClassName = '',
      disabled,
      required,
      validation = {},
      contentValue,
      labelClassName = '',
      field,
      id,
      // managedContent,
      children,
      showErrors = true,
      currentAddressIndex,
      addressIndex,
      lookupForm,
      noAddressFound,
      // ...rest
    } = props;

    const { results, isSelected } = this.state;
    const { state = {} } = validation;

    // if (!managedContent()) return '';
    // Object.keys(managedContent()).forEach(k => {
    //   delete rest[k];
    // });

    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    const suffix = contentValue(ContentType.Text, 'suffix');

    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;

    // const isRequired =
    //   required || (validation && validation.rules && validation.rules.required);

    const isRequired =
      required ||
      (lookupForm &&
        lookupForm.addressInput &&
        lookupForm.addressInput.rules &&
        lookupForm.addressInput.rules.required);

    let dynamicProps = {};
    if (placeholder) {
      dynamicProps.placeholder = placeholder;
    }

    let dynamicAriaDescribedBy = {};

    if (suffix && !HasErrors(validation)) {
      dynamicAriaDescribedBy['aria-describedby'] =
        `error-${fieldName.replace('[', '-').replace(']', '')}-validation` +
        ` ${id}-desc`;
    } else {
      if (suffix) {
        dynamicAriaDescribedBy['aria-describedby'] = `${id}-desc`;
      }

      if (!HasErrors(validation)) {
        dynamicAriaDescribedBy['aria-describedby'] = `error-${fieldName
          .replace('[', '-')
          .replace(']', '')}-validation`;
      }
    }

    return (
      <div
        className={`form-group address-form-group ${groupClassName} ${
          disabled ? 'disabled-input' : ''
        } ${isRequired ? 'required-input' : ''}`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-required-field"
                  copytext=" (required field)"
                />
              </React.Fragment>
            ) : (
              ''
            )}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        {!_.isObject(lookupForm.addressInput.value) && !isSelected && (
          <InputGroup className={inputGroupClassName}>
            <Input
              invalid={Validate.isInvalid(state)}
              valid={Validate.isValid(state)}
              id={id}
              name={fieldName}
              value={lookupForm.addressInput.value}
              type="text"
              disabled={disabled}
              innerRef={this.searchAddressInput}
              {...dynamicProps}
              {...dynamicAriaDescribedBy}
              onChange={this.handleChange}
            />
            {suffix ? (
              suffixAlert ? (
                <div
                  className="alert alert-info mt-3 w-auto d-inline-block"
                  id={`${id}-desc`}
                >
                  <p>{suffix}</p>
                </div>
              ) : suffixIsDiv ? (
                <div className="suffix" id={`${id}-desc`}>
                  {suffix}
                </div>
              ) : (
                <p className="suffix" id={`${id}-desc`}>
                  {suffix}
                </p>
              )
            ) : (
              ''
            )}
            {children}
            {showErrors ? (
              <Errors
                contentValue={contentValue}
                validation={validation}
                field={field}
              />
            ) : (
              ''
            )}

            {lookupForm.addressInput.value ? (
              <InputGroupText>
                <Button
                  color="light"
                  disabled={disabled}
                  className="address-clear-button"
                  onClick={() => this.clearSelectedAddress()}
                >
                  <Content
                    cmsTag="GLOBAL:Clear-address"
                    copytext="Clear address"
                  />
                </Button>
              </InputGroupText>
            ) : (
              ''
            )}
          </InputGroup>
        )}

        <div
          aria-live="polite"
          aria-atomic="true"
          className="address-lookup-results-container"
        >
          {noAddressFound && currentAddressIndex === addressIndex ? (
            <Content
              tag="p"
              cmsTag="Long-apply:Apply-pages:Address-details:p1"
              copytext="No addresses found"
            />
          ) : (
            ''
          )}
          {results?.length > 0 && currentAddressIndex === addressIndex ? (
            <React.Fragment>
              {this.getTitle(results)}
              <div className="results custom-scrollbar">
                <ul
                  className="list-unstyled"
                  aria-describedby={`${id}-result-list-title`}
                >
                  {results.map(result => (
                    <li key={result.global_address_key}>
                      <button
                        type="button"
                        onClick={() => this.onClickAddress(result)}
                      >
                        {result.text}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
          {lookupForm.addressInput.value &&
            _.isObject(lookupForm.addressInput.value) &&
            isSelected && (
              <div className="chosen-address-container">
                <address aria-describedby={`${id}-result-selected-title`}>
                  {Object.keys(lookupForm.addressInput.value).map(
                    (key, index) => (
                      <p
                        className={'mb-0'}
                        key={lookupForm.addressInput.value[key] + '-' + index}
                      >
                        {lookupForm.addressInput.value[key]}
                      </p>
                    ),
                  )}
                </address>

                <Button
                  className="address-clearselected--button btn btn-light btn-sm"
                  onClick={() => this.clearSelectedAddress()}
                >
                  <Content
                    cmsTag="GLOBAL:Change-address"
                    copytext="Change address"
                  />
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }
}

AddressLookupQAS.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.element,
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  description: PropTypes.string,
  groupClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  subLabel: PropTypes.string,
  addresses: PropTypes.object,
  inputGroupClassName: PropTypes.string,
  validation: PropTypes.object,
  disabled: PropTypes.bool,
  resetGetAddressList: PropTypes.func,
  addressList: PropTypes.array,
  required: PropTypes.bool,
  onChangeAddressSearchInput: PropTypes.func,
  id: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  showErrors: PropTypes.bool,
  suffixAlert: PropTypes.bool,
  currentAddressIndex: PropTypes.string,
  suffixIsDiv: PropTypes.bool,
  addressIndex: PropTypes.string,
  onSelectAddress: PropTypes.func,
  lookupForm: PropTypes.object,
  getAddress: PropTypes.func,
  addressDetails: PropTypes.object,
  noAddressFound: PropTypes.any,
  title: PropTypes.string,
  results: PropTypes.any,
};

export default contentManaged(AddressLookupQAS);
