import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '@myie/interact-dom';
import View from './View';
import AdditionalDetails from './AdditionalDetails';
import Success from './Success';

class PersonalDetails extends Component {
  render() {
    if (
      this.props.location.pathname.match(
        new RegExp('^/personal-details[/]{0,1}$'),
      )
    ) {
      return <Redirect to="/personal-details/view" />;
    }

    return (
      <div id="personalDetails">
        <Switch>
          <PrivateRoute
            exact
            path="/personal-details/additional-details"
            component={AdditionalDetails}
          />
          <PrivateRoute exact path="/personal-details/:mode" component={View} />
          <PrivateRoute
            exact
            path="/personal-details/edit/success"
            component={Success}
          />
        </Switch>
      </div>
    );
  }
}

PersonalDetails.propTypes = {
  location: PropTypes.object,
};

export default PersonalDetails;
