import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Content, FormattedDate, CONSTANTS } from '@myie/interact-dom';

class AboutYou extends React.Component {
  extractTitle = titleCode => {
    let title = titleCode;

    // Find the title array entry with the given code
    let titleObject = CONSTANTS.TITLES.find(
      t => Object.keys(t)[0] === titleCode,
    );

    // Extract title text
    if (titleObject) {
      title = titleObject[Object.keys(titleObject)[0]];
    }
    return title;
  };

  render() {
    const { userDetails } = this.props;

    let yourTitle = '';
    let firstName = '';
    let dateOfBirth = '';

    let lastName = '';
    let emailAddress = '';
    let homeNumber = '';
    let nationalInsuranceNumber = '';
    let mobileNumber = '';

    if (userDetails) {
      yourTitle = userDetails.Title;
      firstName = userDetails.FirstName;
      lastName = userDetails.Surname;
      emailAddress = userDetails.EmailAddress;
      dateOfBirth = userDetails.ExtendedProperties.DateOfBirth;
      homeNumber = userDetails.HomeNumber;
      mobileNumber = userDetails.MobileNumber;
      nationalInsuranceNumber = userDetails.ExtendedProperties.NINumber;
    }

    yourTitle = this.extractTitle(yourTitle);

    return (
      <section className="mt-5">
        <Content
          tag="h2"
          cmsTag="User-management:Personal-details:View:About-you:h2-1"
          copytext="About you"
        />
        <Row tag="dl" title="About you details">
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Title" copytext="Title" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {yourTitle}
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:First-name" copytext="First name" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {firstName}
          </Col>

          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Last-name" copytext="Last name" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {lastName}
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Date-of-birth" copytext="Date of birth" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            <FormattedDate
              date={dateOfBirth}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Email" copytext="Email address" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {emailAddress}
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Home-number" copytext="Home phone number" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {homeNumber}
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content cmsTag="GLOBAL:Mobile-number" copytext="Mobile number" />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {mobileNumber}
          </Col>
          <Col tag="dt" xs={12} sm={4} md={3}>
            <Content
              cmsTag="GLOBAL:National-Insurance-Number"
              copytext="National Insurance Number"
            />
          </Col>
          <Col tag="dd" xs={12} sm={8} md={9}>
            {nationalInsuranceNumber}
          </Col>
        </Row>
      </section>
    );
  }
}

AboutYou.propTypes = {
  userDetails: PropTypes.object,
};

export default AboutYou;
