import { Connection } from '@myie/interact';
const appUtility = (function() {
  let timeoutMessage;
  const setTimeoutMessage = str => {
    timeoutMessage = str;
  };

  const getTimeoutMessage = () => {
    return timeoutMessage;
  };

  const userSignout = () => {
    fetch(Connection.baseUrl('webauthentication') + `v2.0/user/sign-out`, {
      method: 'post',
      headers: Connection.headers(),
      body: '{}',
    });
  };

  return {
    setTimeoutMessage: setTimeoutMessage,
    getTimeoutMessage: getTimeoutMessage,
    userSignout: userSignout,
  };
})();

export default appUtility;
