import { Connection, TwoFactor } from '@myie/interact';

import {
  ADD_NOMINATED_ACCOUNT_REQUEST,
  ADD_NOMINATED_ACCOUNT_SUCCESS,
  ADD_NOMINATED_ACCOUNT_FAILURE,
  RESET_ADD_NOMINATED_ACCOUNT,
} from './nominatedAccountTypes';

export const resetAddNominatedAccount = () => ({
  type: RESET_ADD_NOMINATED_ACCOUNT,
});
export const addNominatedAccountRequest = () => ({
  type: ADD_NOMINATED_ACCOUNT_REQUEST,
  isFetching: true,
  error: null,
});

export const failedAddNominatedAccount = error => ({
  type: ADD_NOMINATED_ACCOUNT_FAILURE,
  isFetching: false,
  error: error.message,
});

export const activationAddNominatedAccount = json => ({
  type: ADD_NOMINATED_ACCOUNT_SUCCESS,
  addNominatedAccountResponse: json,
  isFetching: false,
  error: null,
});

export const addNominatedAccount = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(addNominatedAccountRequest());

    return fetch(Connection.baseUrl('onboarding'), {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(activationAddNominatedAccount(json)))
      .catch(error => dispatch(failedAddNominatedAccount(error)));
  });
};
