import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'reactstrap';
import {
  Content,
  Markdown,
  FormattedDate,
  Currency as CurrencyInput,
  RULES,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';

class WithdrawalWithClose extends Component {
  constructor(props) {
    super(props);
    const {
      ExtendedProperties: { AvailableBalance } = {},
    } = this.props.account;
    this.state = {
      form: {
        amount: {
          rules: {
            title: 'Amount',
            stop: true,
            required: {
              message: 'Please enter an amount.',
            },
            format: {
              regex: RULES.ammountFormat,
              message: 'Please enter a valid amount.',
            },

            minAmountCheck: {
              message: 'Please enter a minimum amount of £1000.',
              comparison: () => {
                if (AvailableBalance < 1000) {
                  return null;
                } else {
                  return [1000, AvailableBalance];
                }
              },
            },
            minAmountFullBalance: {
              message: 'Please enter your full balance to proceed.',
              comparison: () => {
                return AvailableBalance;
              },
            },
            compareWithAvailableBalance: {
              message: `To close your account and transfer the funds to another ${CONTACTS.companyName} account, please contact our Online Support Team on ${CONTACTS.phone}`,
              comparison: () => {
                const {
                  ExtendedProperties: { AvailableBalance } = {},
                } = this.props.account;

                return AvailableBalance;
              },
            },
          },
          value: '',
        },
      },
    };
  }

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  updateCurrency = e => {
    let { name, value } = e.target;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });

    if (!form.approved) {
      return;
    }
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }

    onSubmit(form);
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form } = this.state;
    form = Validate.input(name, checked ? 'true' : null, form);
    if (checked) {
      form.amount.value = '';
    }
    this.setState({ ...this.state, form });
  };

  render() {
    const { onCancelled, account = {}, actionDate } = this.props;
    const { form } = this.state;

    return (
      <Form
        autoComplete="off"
        id="notice-to-withdraw-form"
        onSubmit={this.onSubmitHandler}
      >
        <h2>
          <Content
            cmsTag="Savings-servicing:Shared:Withdrawal-with-close:h2-1"
            copytext="Withdrawal details"
          />
        </h2>
        <Markdown
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          cmsTag="GLOBAL:Closing-account-information"
          markdown={`\nIf you wish to close the account, please contact us on <a href="tel:$[phoneLink]">$[phone]</a>. Our telephone lines are open $[openTimes].`}
        />
        <CurrencyInput
          label="Amount"
          cmsTag="LABEL:amount"
          showErrors={true}
          validation={form.amount}
          onChange={this.updateCurrency}
          onBlur={this.onBlur}
          code={account.CurrencyCode}
          field="amount"
          id="amount"
          inputGroupClassName="half-field"
          dp={2}
        />
        <div className="form-group">
          <Row tag="dl" title="Nominated account withdrawal details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
              <Content
                cmsTag="LABEL:Withdrawal-date"
                copytext="Withdrawal date"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
              <FormattedDate date={actionDate} format="Do MMMM YYYY" />
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
              <Content
                cmsTag="LABEL:Withdrawal-request-date"
                copytext="Withdrawal request date"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
              <FormattedDate date={new Date()} format="Do MMMM YYYY" />
            </Col>
          </Row>
        </div>
        <div className="alert alert-info">
          <Markdown
            cmsTag="GLOBAL:Withdrawal-date-warning"
            markdown={
              'Please note: If your withdrawal date falls on a weekend or bank holiday, it will be processed the following working day.'
            }
          />
        </div>
        <div className="form-button-group">
          <Button
            id="continue-withdraw-with-close"
            type="submit"
            color="primary"
          >
            <Content
              cmsTag="Savings-servicing:Shared:Withdrawal-with-close:Continue"
              copytext="Continue"
            />
          </Button>
          <Button
            id="cancel-notice-to-withdraw"
            type="button"
            color="secondary"
            onClick={onCancelled}
          >
            <Content
              cmsTag="Savings-servicing:Shared:Withdrawal-with-close:Cancel"
              copytext="Cancel"
            />
            <span className="sr-only">
              <Content
                cmsTag="Savings-servicing:Shared:Withdrawal-with-close:Sr-only:-withdrawal"
                copytext=" withdrawal"
              />
            </span>
          </Button>
        </div>
      </Form>
    );
  }
}

WithdrawalWithClose.propTypes = {
  account: PropTypes.object,
  actionDate: PropTypes.any,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default WithdrawalWithClose;
