import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from '@myie/interact-dom';

class SummaryMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = { removeClass: false };
  }

  hideMenu = () => {
    this.setState({ removeClass: !this.state.removeClass });
  };
  render() {
    return (
      <Link
        id="move-money-menu-item"
        className="nav-link"
        onClick={this.hideMenu}
        to={`/move-money/index`}
      >
        <Content cmsTag="MENU:Move-money" copytext="Move Money" />
      </Link>
    );
  }
}

export default SummaryMenuItems;
