import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class Success extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="forgotten-password-success">
        <Content
          tag="h1"
          tagID="forgotten-password-success-title"
          cmsTag="Authentication:Forgotten-password:Success:h1"
          copytext="Request successful"
        />
        <Content
          tag="p"
          cmsTag="Authentication:Forgotten-password:Success:p1"
          copytext="Your security details have been reset."
        />
        <div className="form-button-group">
          <Link
            id="forgotten-password-continue"
            className="btn btn-primary"
            role="button"
            to="/accounts"
          >
            <Content cmsTag="GLOBAL:Sign-in" copytext="Sign in" />
          </Link>
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Success);
