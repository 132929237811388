import { Connection } from '@myie/interact';

import {
  MESSAGE_ATTACHMENT_REQUEST,
  MESSAGE_ATTACHMENT_SUCCESS,
  MESSAGE_ATTACHMENT_FAILURE,
  RESET_MESSAGE_ATTACHMENT,
} from './messageAttachmentsTypes';

export const resetMessageAttachments = () => ({
  type: RESET_MESSAGE_ATTACHMENT,
});

export const messageAttachmentsRequest = () => ({
  type: MESSAGE_ATTACHMENT_REQUEST,
  isFetching: true,
  error: null,
});

export const messageAttachmentsFailed = error => ({
  type: MESSAGE_ATTACHMENT_FAILURE,
  isFetching: false,
  error: error.message,
});

export const messageAttachmentsReceive = json => ({
  type: MESSAGE_ATTACHMENT_SUCCESS,
  messageAttachments: json,
  isFetching: false,
  error: null,
});

export const getMessageAttachments = request => dispatch => {
  dispatch(messageAttachmentsRequest());

  return fetch(
    Connection.baseUrl('custombrand') + `v1.0/autogenmessageattachments`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(messageAttachmentsReceive(json)))
    .catch(error => dispatch(messageAttachmentsFailed(error)));
};
