import React from 'react';
import { HelpContactSidebar, SignInSidebarComponent } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class ForgottenNameSidebar extends React.Component {
  render() {
    return (
      <aside className="right-container">
        <HelpContactSidebar contacts={CONTACTS} />
        <SignInSidebarComponent />
      </aside>
    );
  }
}

export default ForgottenNameSidebar;
