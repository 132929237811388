const productTable = (function() {
  let table = {
    '1 Year Fixed Rate Cash ISA (24)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-1YR-FRISA-24-V1.pdf',
    },
    '1 Yr Fixed Rate Cash ISA (18)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-1YR-FRISA-18-V1.pdf',
    },
    '1Yr Fixed Rate Cash ISA 7': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-1YR-FRISA-7-V1.pdf',
    },
    '2 Year Fixed Rate Bond (26)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-26.pdf',
    },
    '2 Year Fixed Rate Bond 30': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-30.pdf',
    },
    '2 Year Fixed Rate Bond 27': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-27.pdf',
    },
    '2 Year Fixed Rate Bond 28': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-28.pdf',
    },
    '2 Year Fixed Rate Cash ISA (15)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-2YR-FRISA-15-V1.pdf',
    },
    '2 Year Fixed Rate Cash ISA (21)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-2YR-FRISA-21-V1.pdf',
    },
    '2 Yr Fixed Rate Cash ISA (19)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-2YR-FRISA-19-V1.pdf',
    },
    '2 Yr Fixed Rate Cash ISA (20)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-2YR-FRISA-20-V1.pdf',
    },
    '3 Year Fixed Rate Cash ISA (16)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-3YR-FRISA-16-V1.pdf',
    },
    '3 Year Fixed Rate Cash ISA (22)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-3YR-FRISA-22-V1.pdf',
    },
    '3 Year Fixed Rate Cash ISA (25)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-3YR-FRISA-25-V1.pdf',
    },
    '35 Day Notice Account': {
      noticeToWithdraw: 'TEUE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Notice Account',
      noticeExpiryPeriod: 65,
      noticePeriod: 35,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-35-Day-Notice.pdf',
    },
    '5 Year Fixed Rate Bond 29': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-5-Year-Fixed-Rate-Bond-29.pdf',
    },
    '5 Year Fixed Rate Cash ISA (23)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-5YR-FRISA-23-V1.pdf',
    },
    '7 Day Notice Account': {
      noticeToWithdraw: 'TRUE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Notice Account',
      noticePeriod: 7,
      noticeExpiryPeriod: 37,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-7-Day-Notice.pdf',
    },
    '90 Day Notice Account': {
      noticeToWithdraw: 'TRUE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Notice Account',
      noticePeriod: 90,
      noticeExpiryPeriod: 120,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-90-Day-Notice.pdf',
    },
    'Autumn Bond (Issue 3)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Autumn-Bond-Issue-3.pdf',
    },
    'Bonus Cash ISA': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Cash ISA',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Bonus-Cash-ISA.pdf',
    },
    'Bonus Cash ISA 2': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Cash ISA',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Bonus-Cash-ISA-2.pdf',
    },
    'Bonus Cash ISA 3': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Cash ISA',
      noticePeriod: null,
      tandC: 'http://www.darlington.co.uk/summary-box/cash-isa-3',
    },
    'Capital Supersaver': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Regular Saver',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Capital-Supersaver.pdf',
    },
    'Cash ISA': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Cash ISA',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Cash-ISA.pdf',
    },
    'Cash ISA 2': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Cash ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/08/Summary-Box-Cash-ISA-2-v1.pdf',
    },
    'Corporate Deposit Account 1': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Corporate',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/11/Summary-Box-Corporate-Deposit-Account.pdf',
    },
    'Darly Young Saver Account': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Junior Account',
      noticePeriod: null,
      tandC: 'https://www.darlington.co.uk/summary-box/darly-young-saver',
    },
    'Fixed Rate Bond 20': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-20.pdf',
    },
    'Fixed Rate Bond 24': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-24.pdf',
    },
    'Fixed Rate Bond 7': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-7.pdf',
    },
    'Fixed Rate Cash ISA 11': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-3YR-FRISA-11-V1.pdf',
    },
    'Fixed Rate Cash ISA 13': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-3YR-FRISA-9-V1.pdf',
    },
    'Fixed Rate Cash ISA 17': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-1YR-FRISA-17-V1.pdf',
    },
    'Fixed Rate Cash ISA 9': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate ISA',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-3YR-FRISA-9-V1.pdf',
    },
    'Fixed Rate Investment Bond 25b': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-5-Year-Fixed-Rate-Bond-25.pdf',
    },
    'Foundation Account': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Junior Account',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/11/Summary-Box-Foundation-Account.pdf',
    },
    'FR Income Bond 1 Mthly': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Fixed-Rate-Monthly-Income-Bond-1.pdf',
    },
    'Help To Buy ISA': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'ISA',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Help-to-Buy-ISA.pdf',
    },
    'High Days & Holidays Bonus Saver': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Regular Saver',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-High-Days-and-Holidays-Saver.pdf',
    },
    'High Five Saver': {
      noticeToWithdraw: 'FALSE',
      accountType: 'Limited Access ',
      savingsGoalAccount: false,
      maxWithdrawals: 5,
      noticePeriod: null,
      tandC: 'https://www.darlington.co.uk/summary-box/high-five-saver',
    },
    'Instant Access': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: true,
      accountType: 'Instant Access',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/08/Summary-Box-Instant-Access-v1.pdf',
    },
    'Jr High Days & Holidays Sav': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Junior Account',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Junior-High-Days-and-Holidays-Account.pdf',
    },
    'Junior Cash ISA': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Junior Account',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Junior-Cash-ISA.pdf',
    },
    'Junior Cash ISA (2)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Junior Account',
      noticePeriod: null,
      tandC: 'https://www.darlington.co.uk/summary-box/junior-cash-isa-2',
    },
    'Loyalty Account': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Instant Access',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Loyalty-Account.pdf',
    },
    'Member Exclusive 45 Day Notice': {
      noticeToWithdraw: 'TRUE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Notice Account',
      noticePeriod: 45,
      noticeExpiryPeriod: 75,
      tandC:
        'https://www.darlington.co.uk/summary-box/member-exclusive-45-day-notice',
    },
    'Monthly Income Bond 1': {
      noticeToWithdraw: 'TRUE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Notice Account',
      noticePeriod: 55,
      noticeExpiryPeriod: 85,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Monthly-Income-Bond.pdf',
    },
    'Special Occasion Saver': {
      noticeToWithdraw: 'FALSE',
      accountType: 'Regular Saver',
      savingsGoalAccount: false,
      noticePeriod: null,
      maxWithdrawals: 4,
      tandC: 'https://www.darlington.co.uk/summary-box/special-occasion-saver',
    },
    'Spec Occ Saver': {
      noticeToWithdraw: 'FALSE',
      accountType: 'Regular Saver',
      savingsGoalAccount: false,
      noticePeriod: null,
      maxWithdrawals: 4,
      tandC: 'https://www.darlington.co.uk/summary-box/special-occasion-saver',
    },
    'Spring Bond (Issue 5)': {
      noticeToWithdraw: 'FALSE',
      accountType: 'Fixed Rate Bond',
      savingsGoalAccount: false,
      maxWithdrawals: 'NONE',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Spring-Bond-Issue-5.pdf',
    },
    'Summer Bond Issue 4': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/09/Summary-Box-Summer-Bond-Issue-4.pdf',
    },
    'Teen Goal Saver': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: true,
      accountType: 'Junior Account',
      noticePeriod: null,
      tandC: 'https://www.darlington.co.uk/summary-box/teen-goal-saver',
    },
    'Treasurers Deposit Account 1': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Charity Account',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/11/Summary-Box-Treasurers-Deposit-Account.pdf',
    },
    'Triple Access Saver': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 3,
      savingsGoalAccount: false,
      accountType: 'Limited Access ',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Triple-Access-Saver-Account.pdf',
    },
    'Winter Bond Issue 4': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-Winter-Bond-Issue-4.pdf',
    },
    '2 Year Fixed Rate Bond 31': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/10/Summary-Box-2-Year-Fixed-Rate-Bond-31-V2.pdf',
    },
    'Instant Access (Monthly)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'Unlimited',
      savingsGoalAccount: false,
      accountType: 'Instant Access',
      noticePeriod: null,
      tandC:
        'https://www.darlington.co.uk/wp-content/uploads/2021/08/Summary-Box-Instant-Access-v1.pdf',
    },

    'Fixed Rate Bond 7 (Monthly)': {
      noticeToWithdraw: 'FALSE',
      maxWithdrawals: 'NONE',
      savingsGoalAccount: false,
      accountType: 'Fixed Rate Bond',
      noticePeriod: null,
      tandC:
        'https://dbs.createdbyhush.digital/wp-content/uploads/2021/08/Summary-Box-2-Year-Fixed-Rate-Bond-7.pdf',
    },
  };

  const getProductTable = () => {
    return table;
  };

  return {
    getProductTable: getProductTable,
  };
})();

export default productTable;
