import {
  RESET_DEPOSIT_ACCOUNT,
  DEPOSIT_ACCOUNT_REQUEST,
  DEPOSIT_ACCOUNT_SUCCESS,
  DEPOSIT_ACCOUNT_FALIURE,
  RESET_MATURITY_OPTIONS,
  MATURITY_OPTIONS_REQUEST,
  MATURITY_OPTIONS_SUCCESS,
  MATURITY_OPTIONS_FALIURE,
  RESET_SHOW_MATURITY_OPTIONS,
  SHOW_MATURITY_OPTIONS_REQUEST,
  SHOW_MATURITY_OPTIONS_SUCCESS,
  SHOW_MATURITY_OPTIONS_FALIURE,
} from '../actions/maturityOptionsTypes';

const brandMaturityOptionsDefinition = (state = {}, action) => {
  switch (action.type) {
    case DEPOSIT_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case DEPOSIT_ACCOUNT_SUCCESS:
      return {
        ...state,
        depositDetails: action.getDepositResponse,
        isFetching: action.isFetching,
      };
    case DEPOSIT_ACCOUNT_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_DEPOSIT_ACCOUNT:
      return {
        ...state,
        depositDetails: null,
        isFetching: false,
        error: null,
      };
    case MATURITY_OPTIONS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case MATURITY_OPTIONS_SUCCESS:
      return {
        ...state,
        setMaturityDeposit: action.setDepositResponse,
        isFetching: action.isFetching,
      };
    case MATURITY_OPTIONS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_MATURITY_OPTIONS:
      return {
        ...state,
        setMaturityDeposit: null,
        isFetching: false,
        error: null,
      };
    case SHOW_MATURITY_OPTIONS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case SHOW_MATURITY_OPTIONS_SUCCESS:
      return {
        ...state,
        showMaturity: action.showMaturityResponse,
        isFetching: action.isFetching,
      };
    case SHOW_MATURITY_OPTIONS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_SHOW_MATURITY_OPTIONS:
      return {
        ...state,
        showMaturity: null,
        isFetching: false,
        error: null,
      };
    default:
      return state;
  }
};

export default brandMaturityOptionsDefinition;
