import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import {
  Content,
  CONSTANTS,
  FormattedDate /*Markdown,*/,
} from '@myie/interact-dom';
//import { CONTACTS } from '@myie/interact-local-dom';

class MaturityDetails extends React.Component {
  // select current stage

  render() {
    const {
      //AccountName,
      loopIndex,
      account,
      ExtendedProperties: {
        MaturitySelected,
        MaturityDate /*MaturityCallDepositAccount */,
      } = {},
    } = account;

    // NOTE FOR JEN: This code needs to be refactored. It was in src\dar\account-type-savings\NoticeWithdrawalDate.js for DFC but needs fixing.

    // if (
    //   !MaturityDate &&
    //   MaturityCallDepositAccount === 'TRUE' &&
    //   MaturitySelected === 'TRUE'
    // ) {
    //   return (
    //     <React.Fragment>
    //         <Content
    //           tag="p"
    //           cmsTag="Account-type-savings:Maturity-details:p1"
    //           copytext="Thank you for selecting your Call deposit option, we will process your request shortly. Your balance and accrued interest will be transferred to your chosen option."
    //         />
    //       <p>
    //         <Markdown
    //           cmsTag="Account-type-savings:Notice-withdrawal-date:Maturity-selected-p2-part-1"
    //           markdown={`If you change your mind, you can contact us by `}
    //         />
    //         <Link
    //           id={`maturity-secure-message-link`}
    //           to={'/messaging-twoway/new'}
    //         >
    //           <Content
    //             cmsTag="Account-type-savings:Notice-withdrawal-date:secure-message"
    //             copytext="secure message"
    //           />
    //         </Link>
    //         <Markdown
    //           template={{
    //             markdown: {
    //               phone: CONTACTS.phone,
    //               phoneLink: CONTACTS.phoneLink,
    //             },
    //           }}
    //           cmsTag="Account-type-savings:Maturity-details:p2
    //           markdown={`\nIf you change your mind, you can contact us by <a id="maturity-secure-message-link" href="/messaging-twoway/new">secure message</a> or by given us a call on <a href="tel:$[phoneLink]">$[phone]</a>}
    //         />
    //       </p>
    //     </React.Fragment>
    //   );
    // }

    // if (!MaturityDate && MaturityCallDepositAccount === 'TRUE') {
    //   return (
    //     <div
    //       key={`account-type-notice-summary-${loopIndex}`}
    //       className="maturity-icon transferred"
    //     >
    //       <p>
    //         <Markdown
    //           cmsTag="Account-type-savings:Notice-withdrawal-date:alert-1"
    //           template={{ markdown: { name: AccountName } }}
    //           markdown={`Your fixed rate deposit account has now matured and has since been transferred into a $[name]. Please advise us if you would want us to reinvest these funds or return them to you.`}
    //         />
    //       </p>
    //       <Link
    //         id="matures-in-30-cancel"
    //         className="btn btn-sm btn-secondary clear-both mt-2 mb-2"
    //         to={
    //           '/maturity-options/' +
    //           Utility.hexEncode(account.AccountKey.Key) +
    //           '/matured'
    //         }
    //       >
    //         <Content
    //           cmsTag="GLOBAL:View-maturity-options"
    //           copytext="View maturity options"
    //         />
    //       </Link>
    //     </div>
    //   );
    // }

    // if (!MaturityDate && MaturityCallDepositAccount === 'TRUE') {
    //   return (
    //     <div
    //       key={`account-type-notice-summary-${loopIndex}`}
    //       className="maturity-icon transferred"
    //     >
    //       <p>
    //         <Markdown
    //           cmsTag="Account-type-savings:Maturity-details:alert-1"
    //           template={{ markdown: { name: AccountName } }}
    //           markdown={`Your fixed rate deposit account has now matured and has since been transferred into a $[name]. Please advise us if you would want us to reinvest these funds or return them to you.`}
    //         />
    //       </p>
    //       <Link
    //         id="matures-in-30-cancel"
    //         className="btn btn-sm btn-secondary clear-both mt-2 mb-2"
    //         to={
    //           '/maturity-options/' +
    //           Utility.hexEncode(account.AccountKey.Key) +
    //           '/matured'
    //         }
    //       >
    //         <Content
    //           cmsTag="GLOBAL:View-maturity-options"
    //           copytext="View maturity options"
    //         />
    //       </Link>
    //     </div>
    //   );
    // }

    return (
      <React.Fragment>
        {/* {this.currentMessage()} */}

        {account.AccountStatus !== 'Closed' &&
        account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED &&
        MaturitySelected === 'TRUE' ? (
          <Card
            id={`account-type-fixed-rate-summary-${loopIndex}`}
            body
            className="payment-card"
          >
            <CardBody>
              <Row tag="dl" title="Maturity details">
                <Col tag="dt" xs={12} sm={12} md={12} lg={5}>
                  <Content cmsTag="GLOBAL:Matures-on" copytext="Matures on" />
                </Col>
                <Col tag="dd" xs={12} sm={12} md={12} lg={7}>
                  <FormattedDate
                    date={MaturityDate}
                    format={CONSTANTS.STANDARD_DATE_FORMAT}
                    currentFormat={CONSTANTS.DATE_FORMAT_DDMMYYY}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

MaturityDetails.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
};

export default MaturityDetails;
