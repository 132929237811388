export const SAVINGS_PLAN = 'SAVINGS_PLAN';

export const REGULAR_SAVINGS = 'REGULAR_SAVINGS';
export const CHARITY_ACCOUNT = 'CHARITY_ACCOUNT';
export const INSTANT_ACCESS = 'INSTANT_ACCESS';
export const LIMITED_ACCESS = 'LIMITED_ACCESS';
export const ISA = 'ISA';
export const JUNIOR_ACCOUNT = 'JUNIOR_ACCOUNT';
export const CORPORATE = 'CORPORATE';
export const NOTICE_ACCOUNTS = 'NOTICE_ACCOUNTS';
export const NOTICE_TO_WITHDRAW = 'Y';
export const CASH_ISA = 'Cash ISA';
export const FIXED_RATE_BOND = 'FIXED_RATE_BOND';
export const FIXED_RATE_ISA = 'FIXED_RATE_ISA';
export const PRODUCT_TYPE_SAVINGS = 'Savings';
export const PRODUCT_TYPE_MORTGAGE = 'Mortgage';
export const ACCOUNT_TYPE_MORTGAGE = 'MORTGAGES';
export const ACCOUNT_TYPE_SAVINGS = 'SAVINGS';
export const ACCOUNT_TYPE_EASY_ACCESS = 'EASY_ACCESS';
export const ACCOUNT_TYPE_FIXED_TERM = 'FIXED_TERM';
export const DEFAULT_CURRENCY = 'GBP';
export const NOTICE_ACCOUNT = 'NOTICE ACCOUNT';
export const ACCOUNT_VIEW_ONLY = 'V';
export const ACCOUNT_TRANSACT = 'T';
export const NO_NOMINATED_ACCOUNT = 'N';
export const ACCOUNT_CLOSED = 'X';

export const NOMINATED_ACC_DECISION_STATUS = 'PENDING';
export const NOMINATED_ACC_DOC_UPLOAD_STATUS_PENDING = 'PENDING';
export const NOMINATED_ACC_DECISION_STATUS_CLOSED = 'CLOSE';
export const NOMINATED_ACC_DECISION_STATUS_DECLINE = 'DECLINE';

export const COMPONENT_QUICK_TRANSFER = 'QuickTransfer';
export const COMPONENT_ACCOUNT_LIST = 'AccountsList';

export const MIN_SHORT_APPLY_AGE = 18;
export const SHOW_IN_MATURITY = 'SHOW_IN_MATURITY=TRUE';
export const SHOW_NOTICE_TO_WITHDRAW_BUTTON = true;
export const SHOW_In_SAVINGS_GOALS = 'SHOW_IN_SAVINGS_GOALS=TRUE';
export const SHOW_IN_SHORTAPPLY = 'SHOW_IN_SHORTAPPLY=TRUE';
export const AUTO_GENERATED_MESSAGE = 'AutoGeneratedMessage';
export const SAVINGS_PLAN_SUPPORTS = [
  'NominatedAccount',
  'SendMessage',
  'ManageGoals',
];
export const REGULAR_SAVINGS_SUPPORTS = [
  'NominatedAccount',
  'NoticeWithdrawal',
  'SendMessage',
  'ManageGoals',
];
export const ACCOUNT_TYPE_CODE = ['Fixed Rate ISA', 'Cash ISA', 'ISA'];

export const MANAGE_GOALS = 'ManageGoals';

export const TRANSACTION_TYPE_DEPOSIT = 'R';
export const TRANSACTION_TYPE_WITHDRAW = 'W';
export const TRANSACTION_MAX_WITHDRAWAL_UNLIMITED = 'Unlimited';
export const TRANSACTION_MAX_WITHDRAWAL_NONE = 'NONE';

export const SYSTEM_DOWN_NORMAL = 'NORMAL';
export const SYSTEM_DOWN_UPCOMING = 'UPCOMING';
export const SYSTEM_DOWN_ONGOING = 'ONGOING';
export const SYSTEM_DOWN_UNKNOWN = 'UNKNOWN';

export const DATE_FORMAT_DDMMYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const STANDARD_DATE_FORMAT = 'Do MMMM YYYY';
export const STANDARD_DATE_FORMAT_TIME = 'Do MMMM YYYY h:mma';
export const TITLES = [
  { MIS: 'Miss' },
  { MR: 'Mr' },
  { MRS: 'Mrs' },
  { MS: 'Ms' },
  { MX: 'Mx' },
  { BRG: 'Brigadier' },
  { CAN: 'Canon' },
  { CAP: 'Captain' },
  { COL: 'Colonel' },
  { CMR: 'Commander' },
  { CPL: 'Corporal' },
  { DAM: 'Dame' },
  { DR: 'Dr' },
  { FLT: 'Flight Lieutenant' },
  { JDG: 'Judge' },
  { LDY: 'Lady' },
  { LCD: 'Lieutenant Commander' },
  { LRD: 'Lord' },
  { LT: 'Lieutenant' },
  { LTC: 'Lieutenant Colonel' },
  { MDM: 'Madame' },
  { MAJ: 'Major' },
  { MST: 'Master' },
  { PRF: 'Professor' },
  { REV: 'Reverend' },
  { SIR: 'Sir' },
];

export const MARITAL_STATUS_CODE = [
  { C: 'Civil Partnership' },
  { H: 'Cohabiting' },
  { D: 'Divorced' },
  { E: 'Engaged' },
  { M: 'Married' },
  { P: 'Partner' },
  { S: 'Single' },
  { T: 'Separated' },
  { W: 'Widowed' },
  { O: 'Other' },
];

export const SAVINGS_REASON = [
  { A: 'Option A' },
  { B: 'Option B' },
  { C: 'Option C' },
];

export const COUNTRYLIST = [
  { UK: 'United Kingdom' },
  { AF: 'Afghanistan' },
  { AX: 'Aland Islands' },
  { AL: 'Albania' },
  { DZ: 'Algeria' },
  { AS: 'American Samoa' },
  { AD: 'Andorra' },
  { AO: 'Angola' },
  { AI: 'Anguilla' },
  { AQ: 'Antarctica' },
  { AG: 'Antigua & Barbuda' },
  { AR: 'Argentina' },
  { AM: 'Armenia' },
  { AW: 'Aruba' },
  { AU: 'Australia' },
  { AT: 'Austria' },
  { AZ: 'Azerbaijan' },
  { BS: 'Bahamas' },
  { BH: 'Bahrain' },
  { BD: 'Bangladesh' },
  { BB: 'Barbados' },
  { BY: 'Belarus' },
  { BE: 'Belgium' },
  { BZ: 'Belize' },
  { BJ: 'Benin' },
  { BM: 'Bermuda' },
  { BT: 'Bhutan' },
  { BO: 'Bolivia, Plurinational State' },
  { BQ: 'Bonaire, Sint Eustatius & Saba' },
  { BA: 'Bosnia & Herzegovina' },
  { BW: 'Botswana' },
  { BV: 'Bouvet Island' },
  { BR: 'Brazil' },
  { IO: 'British Indian Ocean Territory' },
  { BN: 'Brunei Darussalam' },
  { BG: 'Bulgaria' },
  { BF: 'Burkina Faso' },
  { BI: 'Burundi' },
  { KH: 'Cambodia' },
  { CM: 'Cameroon' },
  { CA: 'Canada' },
  { KY: 'Cayman Islands' },
  { CF: 'Central African Republic' },
  { TD: 'Chad' },
  { CL: 'Chile' },
  { CH: 'China' },
  { CX: 'Christmas Island' },
  { CC: 'Cocos (Keeling) Islands' },
  { CD: 'Congo, Democratic Republic of' },
  { CK: 'Cook Islands' },
  { CR: 'Costa Rica' },
  { CI: "Cote d'Ivoire" },
  { HR: 'Croatia' },
  { CU: 'Cuba' },
  { CW: 'Curacao' },
  { CY: 'Cyprus' },
  { CZ: 'Czech Republic' },
  { DK: 'Denmark' },
  { DJ: 'Djibouti' },
  { DM: 'Dominica' },
  { DO: 'Dominican Republic' },
  { EC: 'Ecuador' },
  { EG: 'Egypt' },
  { SV: 'El Salvador' },
  { GQ: 'Equatorial Guinea' },
  { ER: 'Eritrea' },
  { EE: 'Estonia' },
  { ET: 'Ethiopia' },
  { FK: 'Falkland Islands' },
  { FO: 'Faroe Islands' },
  { FJ: 'Fiji' },
  { FI: 'Finland' },
  { FR: 'France' },
  { FX: 'France (European Territory)' },
  { GF: 'French Guiana' },
  { PF: 'French Polynesia' },
  { TF: 'French Southern Territories' },
  { GA: 'Gabon' },
  { GM: 'Gambia' },
  { GE: 'Georgia' },
  { DE: 'Germany' },
  { GH: 'Ghana' },
  { GI: 'Gibraltar' },
  { GR: 'Greece' },
  { GL: 'Greenland' },
  { GD: 'Grenada' },
  { GP: 'Guadeloupe' },
  { GU: 'Guam' },
  { GT: 'Guatemala' },
  { GG: 'Guernsey' },
  { GN: 'Guinea' },
  { GY: 'Guyana' },
  { HT: 'Haiti' },
  { HM: 'Heard Island & McDonald Island' },
  { VA: 'Holy See (Vatican City State)' },
  { HN: 'Honduras' },
  { HK: 'Hong Kong' },
  { HU: 'Hungary' },
  { IS: 'Iceland' },
  { IN: 'India' },
  { ID: 'Indonesia' },
  { IR: 'Iran' },
  { IQ: 'Iraq' },
  { IE: 'Ireland' },
  { IM: 'Isle of Man' },
  { IL: 'Israel' },
  { IT: 'Italy' },
  { JM: 'Jamacia' },
  { JP: 'Japan' },
  { JE: 'Jersey' },
  { JO: 'Jordan' },
  { KZ: 'Kazakhstan' },
  { KE: 'Kenya' },
  { KS: 'Kingdom of Saudi Arabia' },
  { KI: 'Kiribati' },
  { KP: "Korea (Democratic People's Republic)" },
  { KR: 'Korea (the Republic of)' },
  { XK: 'Kosovo' },
  { KW: 'Kuwait' },
  { KG: 'Kyrgyzstan' },
  { LA: "Lao People's Democratic Republic" },
  { LV: 'Latvia' },
  { LB: 'Lebanon' },
  { LS: 'Lesotho' },
  { LR: 'Liberia' },
  { LY: 'Libya' },
  { LI: 'Liechtenstein' },
  { LT: 'Lithuania' },
  { LU: 'Luxembourg' },
  { MO: 'Macau' },
  { MK: 'Macedonia, fr Yugoslav Republic' },
  { MG: 'Madagascar' },
  { MW: 'Malawi' },
  { MY: 'Malaysia' },
  { MV: 'Maldives' },
  { ML: 'Mali' },
  { MT: 'Malta' },
  { MH: 'Marshall Islands' },
  { MQ: 'Martinique' },
  { MR: 'Mauritania' },
  { MU: 'Mauritius' },
  { YT: 'Mayotte' },
  { MX: 'Mexico' },
  { FM: 'Micronesia, Federated States' },
  { MD: 'Moldova, Republic of' },
  { MC: 'Monaco' },
  { MN: 'Mongolia' },
  { ME: 'Montenegro' },
  { MS: 'Montserrat' },
  { MA: 'Morocco' },
  { MZ: 'Mozambique' },
  { MM: 'Myanmar' },
  { NA: 'Namibia' },
  { NR: 'Nauru' },
  { NP: 'Nepal' },
  { NL: 'Netherlands' },
  { AN: 'Netherlands Antilles' },
  { NC: 'New Caledonia' },
  { NZ: 'New Zealand' },
  { NI: 'Nicaragua' },
  { NE: 'Niger' },
  { NG: 'Nigeria' },
  { NU: 'Niue' },
  { NF: 'Norfolk Island' },
  { MP: 'Northern Mariana Islands' },
  { NO: 'Norway' },
  { OM: 'Oman' },
  { PK: 'Pakistan' },
  { PW: 'Palau' },
  { PS: 'Palestine, State of' },
  { PA: 'Panama' },
  { PG: 'Papua New Guinea' },
  { PY: 'Paraguay' },
  { PE: 'Peru' },
  { PH: 'Philippines' },
  { PN: 'Pitcairn Islands' },
  { PL: 'Poland' },
  { PT: 'Portugal' },
  { PR: 'Puerto Rico' },
  { QA: 'Qatar' },
  { CV: 'Republic of Cabo Verde' },
  { CG: 'Republic of the Congo' },
  { RE: 'Reunion' },
  { RO: 'Romania' },
  { RU: 'Russian Federation' },
  { RW: 'Rwanda' },
  { BL: 'Saint Barthelemy' },
  { KN: 'Saint Kitts & Nevis' },
  { LC: 'Saint Lucia' },
  { MF: 'Saint Martin' },
  { WS: 'Samoa' },
  { SM: 'San Marino' },
  { PM: 'Sanit Pierre & Miquelon' },
  { ST: 'Sao Tome and Principe' },
  { SN: 'Senegal' },
  { RS: 'Serbia' },
  { SC: 'Seychelles' },
  { SL: 'Sierra Leone' },
  { SP: 'Singapore' },
  { SX: 'Sint Maarten' },
  { SK: 'Slovakia' },
  { SI: 'Slovenia' },
  { SB: 'Solomon Islands' },
  { SO: 'Somalia' },
  { SA: 'South Africa' },
  { GS: 'South Georgia & South Sandwich' },
  { SS: 'South Sudan' },
  { ES: 'Spain' },
  { LK: 'Sri Lanka' },
  { SH: 'St Helena Ascension & Tristan' },
  { VC: 'St Vincent & the Grenadines' },
  { SD: 'Sudan' },
  { SR: 'Suriname' },
  { SJ: 'Svalbard and Jan Mayan' },
  { SZ: 'Swaziland' },
  { SE: 'Sweden' },
  { SW: 'Switzerland' },
  { SY: 'Syrian Arab Republic' },
  { TA: 'Taiwan' },
  { TJ: 'Tajikistan' },
  { TZ: 'Tanzania' },
  { TH: 'Thailand' },
  { TL: 'Timor-Leste' },
  { TG: 'Togo' },
  { TK: 'Tokelau' },
  { TO: 'Tonga' },
  { TT: 'Trinidad and Tobago' },
  { TN: 'Tunisia' },
  { TR: 'Turkey' },
  { TM: 'Turkmenistan' },
  { TC: 'Turks and Caicos Islands' },
  { TV: 'Tuvalu' },
  { UG: 'Uganda' },
  { UA: 'Ukraine' },
  { KM: 'Union of the Comoros' },
  { AE: 'United Arab Emirates' },
  { UM: 'United States Minor Outlying' },
  { UY: 'Uruguay' },
  { US: 'USA' },
  { UZ: 'Uzbekistan' },
  { VU: 'Vanuatu' },
  { VE: 'Venezuela, Bolivarian Republic' },
  { VN: 'Vietnam' },
  { VI: 'Virgin Islands, U.S' },
  { VG: 'Virgin Islands (British)' },
  { WF: 'Wallis and Futuna' },
  { EH: 'Western Sahara' },
  { YE: 'Yemen' },
  { ZM: 'Zambia' },
  { ZW: 'Zimbabwe' },
];

export const NATIONALITY = [
  { BRIT: 'British' },
  { AFR: 'African' },
  { ASI: 'Asian' },
  { AUST: 'Austrian' },
  { BEL: 'Belgian' },
  { BUL: 'Bulgarian' },
  { CRO: 'Croatian' },
  { CYP: 'Cypriot' },
  { DAN: 'Danish' },
  { EST: 'Estonian' },
  { FIN: 'Finnish' },
  { FR: 'French' },
  { GER: 'German' },
  { GRE: 'Greek' },
  { HUN: 'Hungarian' },
  { ICE: 'Icelandic' },
  { IND: 'Indian' },
  { IRE: 'Irish' },
  { ITA: 'Italian' },
  { LAT: 'Latvian' },
  { LIE: 'Liechtensteiner' },
  { LIT: 'Lithuanian' },
  { LUX: 'Luxembourger' },
  { MAL: 'Maltese' },
  { NOR: 'Norwegian' },
  { OTEEA: 'Other Outside EEA' },
  { OTH: 'Other' },
  { POL: 'Polish' },
  { POR: 'Portuguese' },
  { ROM: 'Romanian' },
  { SLO: 'Slovakian' },
  { SLOV: 'Slovenian' },
  { SPA: 'Spanish' },
  { SWE: 'Swedish' },
  { SWI: 'Swiss' },
  { USA: 'American' },
];

export const DEPOSIT_METHOD = [
  { C: 'Cash' },
  { DQ: 'Debit Card' },
  { QC: 'Cheque' },
  { SO: 'Bank Transfer' },
];

export const GENDER = { M: 'Male', F: 'Female' };
//export const MARITAL_STATUS_CODE = { C: 'Civil Partnership', H: 'Cohabiting', D: 'Divorced', E: 'Engaged', M: 'Married', P: 'Partner', S: 'Single', T: 'Separated',W: 'Widowed', O: 'Other'    }

export const INTEREST_DESTINATION = [
  { thisAccount: 'This account' },
  { nominatedAccount: 'Nominated account' },
];

export const INTEREST_PAYMENT_FREQUENCY = [{ MO: 'Monthly' }, { YE: 'Yearly' }];

export const SOURCE_PAGE_ADD_GOAL = 'ADD_GOAL';

export const SOURCE_PAGE_GOAL_LIST = 'goal-list';

export const TWENTY_FIVE_THOUSAND = 25000;
