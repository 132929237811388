import {
  TRANSACTIONS_ARRAY,
  DOWNLOAD_URL,
  FIRST_TIME_SIGN_IN,
} from './accountsTypes';
import { Connection } from '@myie/interact';

export const saveTransactions = (transactions = []) => ({
  type: TRANSACTIONS_ARRAY,
  savedTransactions: transactions,
});

export const hideFirstTimeSignInMessage = (status = true) => ({
  type: FIRST_TIME_SIGN_IN,
  firstTimeSignIn: status,
});

export const getDownloadlinkUrl = Session => ({
  type: DOWNLOAD_URL,
  downloadlinkUrl:
    Connection.baseUrl('custombrand') +
    `v1.0/statement?sid=${Session.ticket()}`,
});
