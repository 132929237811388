import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';

class MaxAccounts extends Component {
  render() {
    return (
      <div id="max-withdrawal-limit">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Transfer money between your ${CONTACTS.companyNameShorthand} savings accounts - You only have one account`}
          metaDescription={`Transfer money between your ${CONTACTS.companyNameShorthand} savings accounts - You only have one account`}
        />
        <Content
          tag="h1"
          template={{
            copytext: {
              companyNameShorthand: CONTACTS.companyNameShorthand,
            },
          }}
          cmsTag="Savings-servicing:Transfer:max-withdrawal-limit:h1"
          copytext={`Transfer money between your $[companyNameShorthand] savings accounts`}
        />
        <Markdown
          cmsTag="Savings-servicing:Transfer-to-another-account:max-withdrawal-limit:Index:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              email: CONTACTS.email,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nYou must have at least two accounts that are eligible for internal money transfer. For any questions, please contact our Online Support Team on <a id="contact_form" href="mailto:$[email]">$[email]</a> or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />
        <div className="form-button-group">
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </div>
    );
  }
}

MaxAccounts.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  match: PropTypes.any,
  account: PropTypes.any,
  toAccount: PropTypes.object,
  urlParams: PropTypes.string,
  data: PropTypes.object,
};

export default MaxAccounts;
