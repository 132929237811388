import MoneyToolsSidebar from './moneyTools/MoneyToolsSidebar';
import ShortApplySidebar from './shortApply/ShortApplySidebar';
const SavingsServicingSidebars = [
  {
    url: '/short-apply/index',
    Sidebar: ShortApplySidebar,
  },
  {
    url: '/short-apply/not-available',
    Sidebar: ShortApplySidebar,
  },
  {
    url: '/money-tools/index',
    Sidebar: MoneyToolsSidebar,
  },
  {
    url: '/money-tools/budget-calculator',
    Sidebar: null,
  },
];

export default SavingsServicingSidebars;
