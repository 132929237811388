import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Content,
  FormattedCurrency,
  FormattedDate,
  CONSTANTS,
  AppMeta,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';

class CancellationSuccess extends Component {
  componentDidMount() {
    const { resetCancellation } = this.props;
    resetCancellation();
  }
  render() {
    const { account = {}, accountKey, page, selectedNotice } = this.props;
    const { CurrencyCode } = account;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Delete notice to withdraw success"
          metaDescription="Delete notice to withdraw success"
        />
        <Row tag="dl" title="Account details review">
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {selectedNotice.Amount !== 0 ? (
              <FormattedCurrency
                quantity={selectedNotice.Amount}
                currency={CurrencyCode}
              />
            ) : (
              <Content cmsTag="GLOBAL::All" copytext="All" />
            )}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Withdraw-date" copytext="Withdrawal date" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            <FormattedDate
              date={selectedNotice.WithdrawalDate}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
              currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
            />
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Notice-expiry-date"
              copytext="Notice expiry date"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {selectedNotice.NoticeExpiryDate && (
              <FormattedDate
                date={selectedNotice.NoticeExpiryDate}
                format={CONSTANTS.STANDARD_DATE_FORMAT}
                currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
              />
            )}
          </Col>
        </Row>

        <div className="form-button-group">
          <Link
            id="back-to-notice"
            className="btn btn-secondary"
            role="button"
            to={`/notice-to-withdraw/view/${page}/${accountKey}`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-notice-list"
              copytext="Back to notice list"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

CancellationSuccess.propTypes = {
  resetCancellation: PropTypes.func,
  accountKey: PropTypes.any,
  page: PropTypes.any,
  identifier: PropTypes.any,
  account: PropTypes.any,
  selectedNotice: PropTypes.object,
};

export default connect(
  null,
  mapDispatchToProps,
)(CancellationSuccess);
