import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class UpdateSecurityDetailsSuccess extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="update-security-details-success">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Update security details success"
          metaDescription="Update security details success"
        />
        <Content
          tag="h1"
          cmsTag="Authentication::Update-security-details:Update-security-details-success:h1"
          copytext="Your security details have been updated"
        />
        <Content
          tag="p"
          cmsTag="Authentication::Update-security-details:Update-security-details-success:p1"
          copytext="Please ensure that you keep a secure record of your updated details."
        />
        <div className="form-button-group">
          <Link
            id="security-details-done"
            className="btn btn-secondary"
            role="button"
            to="/details"
          >
            <Content
              cmsTag="GLOBAL:Back-to-my-details"
              copytext="Back to my details"
            />
          </Link>
        </div>
      </div>
    );
  }
}

UpdateSecurityDetailsSuccess.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateSecurityDetailsSuccess);
