import moment from 'moment';
const progressLogic = (function() {
  const getGoalStatus = (balance, goalAmount, monthlyAmount, targetDate) => {
    const targetAmount = parseInt(goalAmount.toString().replace(/,/g, ''));
    const monthlyPayment = parseInt(monthlyAmount.toString().replace(/,/g, ''));
    let date = moment(targetDate);
    let today = moment();
    const diff = date.diff(today, 'months');

    let calculatedAmount = monthlyPayment + balance;

    if (diff > 0 && balance < targetAmount) {
      calculatedAmount = diff * monthlyPayment + balance;
    }

    if (balance === 0) {
      return 'started';
    }
    if (balance >= targetAmount) {
      return 'complete';
    }
    if (calculatedAmount >= goalAmount) {
      return 'on-target';
    }
    if (calculatedAmount < goalAmount) {
      return 'behind';
    }
  };

  return {
    getGoalStatus: getGoalStatus,
  };
})();

export default progressLogic;
