import React from 'react';
import PropTypes from 'prop-types';
import { Content, CONSTANTS } from '@myie/interact-dom';
import { Redirect } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as userManagementDispatch } from '@myie/interact-user-management';
import { mapDispatchToProps as brandUserManagementDispatch } from '@myie/interact-brand-user-management';
import ViewNominatedAccount from './ViewNominatedAccount';
import { withRouter } from 'react-router-dom';
import AddNominatedAccountScreen from './AddNominatedAccountScreen';
import ReferredWithDocs from './ReferredWithDocs';
import ReferredNoDocs from './ReferredNoDocs';
import ProcessingApplication from './ProcessingApplication';
import { connect } from 'react-redux';
import { Session } from '@myie/interact';

class NominatedAccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      called: false,
      accountCalled: false,
    };
  }

  componentDidMount() {
    const {
      getUserDetails,
      fetchAccountsIfNeeded,
      getNominatedAccountStatus,
      accounts,
    } = this.props;
    if (!accounts) {
      fetchAccountsIfNeeded();
    }
    //***Shold be removed from the code.
    const retrievedUserName = Session.customer();
    getUserDetails({ userName: retrievedUserName.Username });
    const request = {
      userName: retrievedUserName.Username,
      Category: 'NominatedAccount',
      attempt: 1,
    };
    getNominatedAccountStatus(request);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (this.props.isFetchingNominatedAcc) {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    }

    let {
      accounts = {},
      userDetails,
      match,
      nominatedAccountStatus,
    } = this.props;

    let {
      Accounts: list = [],
      ExtendedProperties: { NominatedAccountDetails = {} },
    } = accounts;
    let accountsToList = [];
    let savingsAccountAvailable;

    list.forEach(account => {
      let thisAccount = account;
      if (
        thisAccount.AccountStatus !== 'Closed' &&
        thisAccount.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED
      ) {
        accountsToList.push(thisAccount);
      }
    });

    if (accounts && accounts.Accounts) {
      savingsAccountAvailable = list.find(account => {
        return (
          account.ExtendedProperties.AccountType ===
          CONSTANTS.ACCOUNT_TYPE_SAVINGS
        );
      });
    }
    if (
      nominatedAccountStatus &&
      nominatedAccountStatus.DocumentUploadStatus === 'PENDING'
    ) {
      return (
        <ReferredNoDocs
          history={this.props.history}
          match={match}
          mode={NominatedAccountDetails?.NominatedAccount ? 'edit' : 'add'}
          userDetails={userDetails}
        />
      );
    }

    if (
      nominatedAccountStatus &&
      nominatedAccountStatus.DocumentUploadStatus === 'COMPLETE' &&
      nominatedAccountStatus.DecisionStatus !== 'ACCEPT' &&
      nominatedAccountStatus.DecisionStatus !==
        CONSTANTS.NOMINATED_ACC_DECISION_STATUS_CLOSED
    ) {
      return (
        <ReferredWithDocs
          history={this.props.history}
          match={match}
          mode={
            NominatedAccountDetails?.LinkedAccountIdentifier ? 'edit' : 'add'
          }
          userDetails={userDetails}
        />
      );
    }
    if (NominatedAccountDetails?.LinkedAccountIdentifier) {
      return (
        <ViewNominatedAccount
          history={this.props.history}
          match={match}
          nominatedAccountDetails={NominatedAccountDetails}
        />
      );
    }

    if (
      nominatedAccountStatus &&
      nominatedAccountStatus.DocumentUploadStatus === 'COMPLETE' &&
      nominatedAccountStatus.DecisionStatus === 'ACCEPT'
    ) {
      return (
        <ProcessingApplication
          history={this.props.history}
          match={match}
          userDetails={userDetails}
        />
      );
    }

    if (
      nominatedAccountStatus &&
      (!nominatedAccountStatus.DecisionStatus ||
        nominatedAccountStatus.DecisionStatus ===
          CONSTANTS.NOMINATED_ACC_DECISION_STATUS_CLOSED) &&
      accounts &&
      accounts.Accounts &&
      savingsAccountAvailable
    ) {
      return (
        <AddNominatedAccountScreen
          history={this.props.history}
          match={match}
          userDetails={userDetails}
        />
      );
    }

    return null;
  }
}
NominatedAccountDetails.propTypes = {
  fetchAccountsIfNeeded: PropTypes.func,
  getUserDetails: PropTypes.func,
  accounts: PropTypes.object,
  userDetails: PropTypes.object,
  getNominatedAccountStatus: PropTypes.func,
  history: PropTypes.any,
  match: PropTypes.any,
  nominatedAccountStatus: PropTypes.any,
  queryLinkedAccountReq: PropTypes.func,
  linkedNominatedAccResp: PropTypes.object,
  isFetchingNominatedAcc: PropTypes.any,
};
const mapStateToProps = state => {
  const {
    userManagementDefinition,
    accountsDefinition,
    customUserManagementDefinition,
  } = state;
  return {
    ...userManagementDefinition,
    ...accountsDefinition,
    ...customUserManagementDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      ...brandUserManagementDispatch,
      ...userManagementDispatch,
      ...mapDispatchToProps,
    },
  )(NominatedAccountDetails),
);
