import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, LinkTag } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Blocked extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="sign-in-blocked">
        <Content
          tag="h1"
          cmsTag="Authentication:Sign-in:Blocked:h1"
          copytext="Login credentials blocked"
        />
        <Content
          tag="p"
          cmsTag="Authentication:Sign-in:Blocked:p1"
          copytext="You have tried three times to login with incorrect login credentials. Your account has now been blocked."
        />
        <Markdown
          cmsTag="Authentication:Sign-in:Blocked:p2"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              email: CONTACTS.email,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nPlease contact our Online Support Team who can help you access your account. You can email <a href="tel:$[email]">$[email]</a>, or call us on <a href="tel:$[phoneLink]">$[phone]</a>. Our telephone lines are open $[openTimes].`}
        />
        <div className="form-button-group">
          <LinkTag
            role="button"
            href={CONTACTS.externalHomeURL}
            className="btn btn-secondary"
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </div>
    );
  }
}

Blocked.propTypes = {
  userLogout: PropTypes.any,
  contacts: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Blocked);
