/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import React from 'react';
import PropTypes from 'prop-types';
import Messages  from './BannerMessages/Messages';
import { CONSTANTS } from '@myie/interact-dom';


class SavingsGoalMessage extends React.Component {
render() {
// eslint-disable-next-line no-unused-vars
const { account, loopIndex, mode, viewGoalDetails } = this.props
let goal;
if (viewGoalDetails && viewGoalDetails.Goals) {
  goal = viewGoalDetails.Goals.find(function(element) {
	return element.AccountKey.Key === account.AccountKey.Key;
  });
}

switch (account.ExtendedProperties.AccountType) {
		case CONSTANTS.ACCOUNT_TYPE_SAVINGS: return (<Messages loopIndex={loopIndex} account={account} mode={mode} goal={goal}/>); 

default: return null
}
}
}

SavingsGoalMessage.propTypes = {
account: PropTypes.any,
loopIndex: PropTypes.any,
mode: PropTypes.any,
viewGoalDetails: PropTypes.object,
}

export default SavingsGoalMessage