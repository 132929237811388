export const CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST =
  'CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST';
export const CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS =
  'CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS';
export const CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE =
  'CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE';
export const RESET_CANCEL_NOTICE_TO_WITHDRAWAL =
  'RESET_CANCEL_NOTICE_TO_WITHDRAWAL';

export const SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS =
  'SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS';

export const CREATE_NOTICE_TO_WITHDRAWAL_REQUEST =
  'CREATE_NOTICE_TO_WITHDRAWAL_REQUEST';
export const CREATE_NOTICE_TO_WITHDRAWAL_SUCCESS =
  'CREATE_NOTICE_TO_WITHDRAWAL_SUCCESS';
export const CREATE_NOTICE_TO_WITHDRAWAL_FAILURE =
  'CREATE_NOTICE_TO_WITHDRAWAL_FAILURE';
export const RESET_CREATE_NOTICE_TO_WITHDRAWAL =
  'RESET_CREATE_NOTICE_TO_WITHDRAWAL';

export const SET_MAKE_A_WITHDRAW_PARAM = 'SET_MAKE_A_WITHDRAW_PARAM';
export const MAKE_A_WITHDRAW_REQUEST = 'MAKE_A_WITHDRAW_REQUEST';
export const MAKE_A_WITHDRAW_SUCCESS = 'MAKE_A_WITHDRAW_SUCCESS';
export const MAKE_A_WITHDRAW_FALIURE = 'MAKE_A_WITHDRAW_FALIURE';
export const RESET_MAKE_A_WITHDRAW = 'RESET_MAKE_A_WITHDRAW';
export const BANK_HOLIDAYS = 'BANK_HOLIDAYS';

export const NOTICE_TO_WITHDRAWAL_LIST_SUCCESS =
  'NOTICE_TO_WITHDRAWAL_LIST_SUCCESS';
export const NOTICE_TO_WITHDRAWAL_LIST_FAILURE =
  'NOTICE_TO_WITHDRAWAL_LIST_FAILURE';
export const WITHDRAWAL_CLOSING_BAL_SUCCESS = 'WITHDRAWAL_CLOSING_BAL_SUCCESS';
export const WITHDRAWAL_CLOSING_BAL_FAILURE = 'WITHDRAWAL_CLOSING_BAL__FAILURE';

export const GET_NOMINATED_ACCOUNT_REQUEST = 'GET_NOMINATED_ACCOUNT_REQUEST';
export const GET_NOMINATED_ACCOUNT_SUCCESS = 'GET_NOMINATED_ACCOUNT_SUCCESS';
export const GET_NOMINATED_ACCOUNT_FAILURE = 'GET_NOMINATED_ACCOUNT_FAILURE';
export const RESET_NOMINATED_ACCOUNT = 'RESET_NOMINATED_ACCOUNT';
