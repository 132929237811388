import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomAlert from './CustomAlert';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-messaging-system';

class SystemMessage extends React.Component {
  constructor(props) {
    super(props);
    const {
      fetchSystemMessagesIfNeeded,
      alertVisibleArray,
      messageArray,
    } = this.props;
    const request = { ExtendedProperties: {} };
    fetchSystemMessagesIfNeeded(request);
    this.state = {
      alertVisibleArray:
        alertVisibleArray && alertVisibleArray.length ? alertVisibleArray : [],
      messages: messageArray && messageArray.length ? messageArray : [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { systemMessages, saveSytemMessageArray } = nextProps;
    let { alertVisibleArray, messages } = prevState;
    if (
      systemMessages?.SystemMessages &&
      !alertVisibleArray.length &&
      !messages.length
    ) {
      return systemMessages.SystemMessages.map((message, index) => {
        alertVisibleArray[index] = true;
        messages[index] = message.Body;
        saveSytemMessageArray(alertVisibleArray, messages);
        return {
          ...prevState,
          ...alertVisibleArray,
          ...messages,
        };
      });
    } else {
      return null;
    }
  }

  closeAlert = index => {
    let { alertVisibleArray, messages } = this.state;
    let { saveSytemMessageArray } = this.props;
    alertVisibleArray[index] = false;
    saveSytemMessageArray(alertVisibleArray, messages);
    this.setState({ ...this.state, alertVisibleArray: alertVisibleArray });
  };

  renderAlerts = (alertVisibleArray, messages) => {
    return messages.map((message, index) => {
      return (
        <CustomAlert
          key={index}
          alertVisibleArray={alertVisibleArray}
          message={message}
          index={index}
          closeAlert={this.closeAlert}
          className={`alert-success`}
        />
      );
    });
  };

  render() {
    const { alertVisibleArray, messages } = this.state;
    if (alertVisibleArray.length && messages.length) {
      return this.renderAlerts(alertVisibleArray, messages);
    } else {
      return null;
    }
  }
}

SystemMessage.propTypes = {
  alertVisibleArray: PropTypes.any,
  fetchSystemMessagesIfNeeded: PropTypes.any,
  messageArray: PropTypes.any,
  saveSytemMessageArray: PropTypes.any,
  systemMessages: PropTypes.any,
  className: PropTypes.string,
};

const mapStateToProps = state => {
  const { messagingSystemDefinition } = state;
  return {
    ...messagingSystemDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToPropsAccounts,
)(SystemMessage);
