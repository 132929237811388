import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Form } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { storage } from './storageName';
import queryString from 'query-string';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';
import { mapDispatchToProps } from '@myie/interact-authentication';
// Add additional reducer function for clearing error state on dismount
import { mapDispatchToProps as brandUpdateDispatch } from '@myie/interact-brand-authentication';
import {
  Text,
  Switch,
  Content,
  Markdown,
  AppMeta,
  RULES,
  sharedUtility,
  FormErrorList,
  stateUtility,
  CONSTANTS,
} from '@myie/interact-dom';
import { Validate, Session } from '@myie/interact';
import { CONTACTS } from '@myie/interact-local-dom';
import ExampleAbbreviation from '../../shared/components/ExampleAbbreviation';
class UpdateSecurityDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      resetUpdateSecurityDetails,
      setupcredentialsActivate,
      stateData,
      urlParams,
      userDetails,
    } = this.props;

    if (
      stateData &&
      stateData.RequireActivationTicket &&
      urlParams &&
      urlParams.twoFa &&
      urlParams.twoFa === 'active'
    ) {
      const RequireActivationTicket = {
        RequireActivationTicket: stateData.RequireActivationTicket,
        ExtendedProperties: null,
      };
      setupcredentialsActivate({
        RequireActivationTicket:
          stateData && RequireActivationTicket ? RequireActivationTicket : '',
      });
    }

    let birthDate = moment(userDetails?.ExtendedProperties?.DateOfBirth).format(
      CONSTANTS.DATE_FORMAT_YYYYMMDD,
    );
    this.state = {
      localPasscodeValidation: [],
      message: null,
      updateStatus: null,
      twoFAStatus: null,
      submitForm: {},
      form: {
        username: {
          rules: {
            title: 'username',
            stop: true,
            format: {
              regex: RULES.usernameOptional,
              message: 'Please enter a valid username.',
            },
          },
        },
        password: {
          rules: {
            title: 'password',
            stop: true,
            format: {
              regex: RULES.passwordOptional,
              message: 'Please enter a valid password.',
            },
            isSameAsUsername: {
              message: 'Your username and password cannot be the same.',
              field: 'password',
              comparison: () => {
                return this.state.form.username.value;
              },
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            format: {
              regex: RULES.passwordOptional,
              message: 'Please enter a valid password.',
            },
            compare: {
              message: 'Please ensure the password fields match.',
              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
        memorableNumber: {
          rules: {
            title: 'passcode',
            stop: true,
            format: {
              regex: RULES.memorableNumberOptional,
              message: 'Please enter a valid passcode.',
            },
            isSequential: {
              message: 'Your passcode cannot include sequential numbers.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
            isAllSame: {
              message:
                'Your passcode cannot be the same number repeated six times.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
            isDoB: {
              message: 'Your passcode cannot be your date of birth.',
              field: 'memorableNumber',
              comparison: () => {
                return birthDate;
              },
            },
          },
        },
        confirmMemorableNumber: {
          rules: {
            title: 'confirm passcode',
            stop: true,
            format: {
              regex: RULES.memorableNumberOptional,
              message: 'Please enter a valid passcode.',
            },
            compare: {
              message: 'Please ensure the passcode fields match.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
          },
        },
      },
    };
    resetUpdateSecurityDetails();
    stateUtility.setGlobalValue({ storage: storage.name });
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (form[name]) {
      form = Validate.input(name, value, form);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    if (form[name] && e.target.value) {
      form = Validate.input(name, value, form, true);
    }
    this.setState({
      ...this.state,
      form,
    });
  };

  submit = e => {
    e.preventDefault();
    const { updateCredentials } = this.props;
    let { form, message = {}, submitForm = {} } = this.state;
    let {
      username,
      password,
      confirmPassword,
      memorableNumber,
      confirmMemorableNumber,
    } = form;
    let localValues = [];
    if (form.username.value) {
      const usernameRules = { username };
      submitForm = { ...submitForm, ...usernameRules };
    } else {
      delete submitForm.username;
    }

    if (form.password.value) {
      const passwordRules = { password };
      const confirmPasswordRules = { confirmPassword };
      submitForm = { ...submitForm, ...passwordRules, ...confirmPasswordRules };
      form = Validate.input('password', form.password.value, form, true);
      form = Validate.input(
        'confirmPassword',
        form.confirmPassword.value,
        form,
        true,
      );
    } else {
      delete submitForm.password;
      delete submitForm.confirmPassword;
    }

    if (form.memorableNumber.value) {
      const memorableNumberRules = { memorableNumber };
      const confirmMemorableNumberRules = {
        confirmMemorableNumber,
      };
      submitForm = {
        ...submitForm,
        ...memorableNumberRules,
        ...confirmMemorableNumberRules,
      };
      form = Validate.input(
        'memorableNumber',
        form.memorableNumber.value,
        form,
        true,
      );
      form = Validate.input(
        'confirmMemorableNumber',
        form.confirmMemorableNumber.value,
        form,
        true,
      );
      const checkAllSame = sharedUtility.checkAllSame(
        form.memorableNumber.value,
      )
        ? 'AllSame'
        : '';
      const isSequential = sharedUtility.isSequential(
        form.memorableNumber.value,
      )
        ? 'Sequential'
        : '';
      if (checkAllSame) {
        localValues.push(checkAllSame);
      }
      if (isSequential) {
        localValues.push(isSequential);
      }
    } else {
      delete submitForm.memorableNumber;
      delete submitForm.confirmMemorableNumber;
    }

    if (Object.keys(submitForm).length === 0) {
      message = 'MustHaveCredential';
      this.setState({
        ...this.state,
        form,
        submitForm,
        message,
      });
      window.scrollTo(0, 0);
      return;
    } else {
      message = null;
    }
    submitForm = Validate.form(submitForm);
    this.setState({
      ...this.state,
      form,
      submitForm,
      message,
      localPasscodeValidation: localValues,
    });
    if (!submitForm.approved || localValues.length) {
      window.scrollTo(0, 0);
      return;
    }

    const request = {
      Username: form.username.value,
      CredentialValues: [],
      ExtendedProperties: null,
    };

    if (form.password.value) {
      request.CredentialValues.push({
        Name: 'Password',
        Value: form.password.value,
        Context: 'string',
      });
    }

    if (form.memorableNumber.value) {
      request.CredentialValues.push({
        Name: 'Pin',
        Value: form.memorableNumber.value,
        Context: 'string',
      });
    }

    updateCredentials(request);
    this.setState({
      ...this.state,
      form,
      submitForm,
      message,
    });
    if (!submitForm.approved || localValues.length) {
      window.scrollTo(0, 0);
      return;
    }
  };

  static getDerivedStateFromProps = nextProps => {
    const {
      resetUpdateSecurityDetails,
      updateSecurityDetails,
      setupcredentialsActivate,
      updateState,
    } = nextProps;

    if (
      updateSecurityDetails &&
      updateSecurityDetails.Status === 'RequireActivation'
    ) {
      resetUpdateSecurityDetails();
      updateState({
        RequireActivationTicket:
          updateSecurityDetails && updateSecurityDetails.RequireActivationTicket
            ? updateSecurityDetails.RequireActivationTicket
            : '',
      });

      setupcredentialsActivate({
        RequireActivationTicket:
          updateSecurityDetails && updateSecurityDetails.RequireActivationTicket
            ? updateSecurityDetails.RequireActivationTicket
            : '',
      });
    }
    if (updateSecurityDetails && updateSecurityDetails.Status) {
      if (updateSecurityDetails.Status === 'Success') {
        resetUpdateSecurityDetails();
      }
      return { updateStatus: updateSecurityDetails.Status };
    }

    return null;
  };

  componentWillUnmount = () => {
    // Call reducer function to clear error in redux store
    const { resetComponentState, updateSecurityDetailsReceive } = this.props;

    updateSecurityDetailsReceive({});
    resetComponentState();
  };

  getErrors = () => {
    const { updateSecurityDetails = {} } = this.props;
    let errors = [];
    let fieldErrors = {};
    if (
      updateSecurityDetails &&
      updateSecurityDetails.ExtendedProperties &&
      updateSecurityDetails.ExtendedProperties.IdentityResponse &&
      updateSecurityDetails.ExtendedProperties.IdentityResponse.CredentialErrors
    ) {
      updateSecurityDetails.ExtendedProperties.IdentityResponse.CredentialErrors.forEach(
        element => {
          switch (element) {
            case 'Known':
              fieldErrors.password = element;
              return errors.push(
                <Alert id={element} key={element} color="danger">
                  <Markdown
                    cmsTag="Authentication:Update-security-details:Update-security-details:alert-1"
                    markdown={`You have entered a common password, please <a href="#password">choose another password</a>.`}
                  />
                </Alert>,
              );
            default:
          }
        },
      );
    }

    return {
      errorMsg: errors,
      fieldErrors: fieldErrors,
    };
  };

  buildErrorMsg = updateSecurityDetails => {
    let errorsExist = false;
    let errorMsg = [];

    if (
      updateSecurityDetails &&
      updateSecurityDetails.Status === 'InvalidCredential'
    ) {
      errorMsg = this.getErrors().errorMsg;
      errorsExist = true;
    } else {
      if (
        updateSecurityDetails &&
        updateSecurityDetails.Status &&
        updateSecurityDetails.Status !== '' &&
        updateSecurityDetails.Status !== 'Success'
      ) {
        errorsExist = true;

        errorMsg = (
          <Switch
            id="update-security-details-alert"
            value={updateSecurityDetails.Status || ''}
            tag="div"
            className="alert alert-danger"
            role="alert"
            scrolltotop={true}
            contents={{
              MustHaveCredential: {
                defaultValue:
                  'You must input at least one credential to change.',
              },
              UsernameExists: {
                defaultValue:
                  'Please select another username as your chosen one already exists.',
              },
              NoRegistered: {
                defaultValue:
                  'The details you have entered do not match our records. Please try again.',
              },
              Blocked: {
                defaultValue:
                  'Access to your account was denied for security reasons.',
              },
              NotEnoughCredentials: {
                defaultValue: 'Not enough credentials were submitted.',
              },
              InvalidCredentials: {
                defaultValue:
                  'You have entered a common password, please try again.',
              },
              InvalidUsername: {
                defaultValue:
                  'Your username must be between 6 and 23 characters.',
              },
            }}
          />
        );
      }
    }

    return { errorMsg: errorMsg, errorsExist: errorsExist };
  };

  render() {
    const { updateSecurityDetails, location } = this.props;
    const currentState = queryString.parse(location.search);
    const retrievedUserName = Session.customer();
    let { form, message, updateStatus } = this.state;
    let errorMsgObj = this.buildErrorMsg(updateSecurityDetails);
    if (updateStatus) {
      switch (updateStatus) {
        case 'UsernameExists':
          message = 'UsernameExists';
          break;
        case 'NoRegistered':
          message = 'NoRegistered';
          break;
        case 'Blocked':
          message = 'Blocked';
          break;
        case 'NotEnoughCredentials':
          message = 'NotEnoughCredentials';
          break;
        case 'InvalidCredentials':
          message = 'InvalidCredentials';
          break;
        case 'InvalidUsername':
          message = 'InvalidUsername';
          break;
        default:
      }
    }

    if (updateStatus && updateStatus === 'Success') {
      const customer = Session.customer();
      const ticket = Session.ticket();
      customer.Username = this.state.form.username.value
        ? this.state.form.username.value
        : retrievedUserName.Username;
      Session.set(ticket, customer);
      return <Redirect to="/update-security-details/success" />;
    }
    if (currentState && currentState.twoFa !== 'active') {
      return (
        <div id="update-security-details-inner">
          <AppMeta
            id="meta-data"
            contacts={CONTACTS}
            stage="child"
            title="Security details"
            metaDescription="Security details"
          />
          <Content
            tag="h1"
            cmsTag="Authentication:Update-security-details:Update-security-details:h1"
            copytext="Security details"
          />
          {errorMsgObj.errorMsg}
          <FormErrorList
            validations={form}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
          {message ? (
            <Alert
              id="reg-error-exists"
              role="alert"
              aria-live="assertive"
              className="invalid-feedback"
            >
              <Content
                cmsTag="Authentication:Update-security-details:Update-security-details:alert-2"
                copytext="You have not made any changes to your security details. Please check your changes and try again."
              />
            </Alert>
          ) : null}
          <Form
            autoComplete="off"
            id="update-security-details-form"
            onSubmit={this.submit}
          >
            <section>
              <Content
                tag="h2"
                cmsTag="Authentication:Update-security-details:Update-security-details:h2-1"
                copytext="Your username"
              />
              <Markdown
                cmsTag="Authentication:Update-security-details:Update-security-details:p1"
                template={{
                  markdown: {
                    userName:
                      retrievedUserName && retrievedUserName.Username
                        ? retrievedUserName.Username
                        : '',
                  },
                }}
                markdown={`\nYour current username is **$[userName]**. If you would like to change your username, type a new one in the "New username" field and click the "Save security details" button.`}
              />
              <ul className="bullet-list">
                <li>
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l1"
                    copytext="Your username must be unique and between 6 and 23 characters long."
                  />
                </li>
                <li>
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l2"
                    copytext="Your username must contain at least one upper case letter and a number."
                  />
                </li>
              </ul>

              <Text
                id="username"
                label="New username"
                cmsTag="GLOBAL:New-username"
                field="username"
                min={6}
                maxLength={23}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.username}
              />
            </section>
            <section>
              <Content
                tag="h2"
                cmsTag="Authentication:Update-security-details:Update-security-details:h2-2"
                copytext="Your password"
              />
              <Markdown
                cmsTag="Authentication:Update-security-details:Update-security-details:p2"
                markdown={`\nFor security reasons we cannot display your current password. If you would like to change your password, enter a new password in both the "New password" and "Confirm new password" fields and click the "Save security details" button.`}
              />
              <ul className="bullet-list">
                <li>
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l3"
                    copytext="Your password must be between 8 and 50 characters long."
                  />
                </li>
                <li>
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l4-part-1"
                    copytext="Your password must contain at least one upper case letter, one lower case letter, one number and one special character ("
                  />
                  <ExampleAbbreviation />
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l4-part-2"
                    copytext=" *!?%/+-)."
                  />
                </li>
              </ul>
              <Text
                id="password"
                label="New password"
                cmsTag="GLOBAL:New-password"
                field="password"
                maxLength={50}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.password}
                type="password"
              />
              <Text
                id="confirmPassword"
                label="Confirm new password"
                cmsTag="GLOBAL:Confirm-new-password"
                field="confirmPassword"
                maxLength={50}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmPassword}
                type="password"
              />
            </section>
            <section>
              <Content
                tag="h2"
                cmsTag="Authentication:Update-security-details:Update-security-details:h2-3"
                copytext="Your passcode"
              />
              <Markdown
                cmsTag="Authentication:Update-security-details:Update-security-details:p3"
                markdown={`\nFor security reasons we cannot display your current passcode. If you would like to change your passcode, enter it in the  
                "New passcode" and "Confirm new passcode" fields and click "Save security details". You will be asked for three numbers from this code every time you sign in.`}
              />
              <ul className="bullet-list">
                <li>
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l5"
                    copytext="Your passcode must be at least six numbers long."
                  />
                </li>
                <li>
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l6"
                    copytext="Your passcode cannot include your date of birth."
                  />
                </li>
                <li>
                  <Content
                    cmsTag="Authentication:Update-security-details:Update-security-details:l7"
                    copytext="Your passcode cannot include sequential numbers and cannot be the same number repeated six times."
                  />
                </li>
              </ul>
              <Text
                id="memorableNumber"
                label="New passcode"
                cmsTag="GLOBAL:New-passcode"
                field="memorableNumber"
                maxLength={6}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.memorableNumber}
                type="password"
                groupClassName="half-field"
              />
              <Text
                id="confirmMemorableNumber"
                label="Confirm new passcode"
                cmsTag="GLOBAL:Confirm-new-passcode"
                field="confirmMemorableNumber"
                maxLength={6}
                onChange={this.onChange}
                onBlur={this.onBlur}
                validation={form.confirmMemorableNumber}
                type="password"
                groupClassName="half-field"
              />
            </section>
            <div className="form-button-group">
              <Button
                id="security-details-submit"
                type="submit"
                color="primary"
              >
                <Content
                  cmsTag="GLOBAL:Save-security-details"
                  copytext="Save security details"
                />
              </Button>
              <Link
                id="security-details-cancel"
                className="btn btn-secondary"
                role="button"
                to="/details"
              >
                <Content
                  cmsTag="GLOBAL:Back-to-my-details"
                  copytext="Back to my details"
                />
              </Link>
            </div>
          </Form>
        </div>
      );
    }
    return null;
  }
}

UpdateSecurityDetails.propTypes = {
  retrievedUserName: PropTypes.object,
  updateCredentials: PropTypes.any,
  updateSecurityDetails: PropTypes.any,
  updateSecurityDetailsReceive: PropTypes.func,
  login: PropTypes.any,
  resetUpdateSecurityDetails: PropTypes.func,
  setupcredentialsActivate: PropTypes.func,
  stateData: PropTypes.any,
  location: PropTypes.any,
  urlParams: PropTypes.any,
  resetComponentState: PropTypes.func,
  userDetails: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    authenticationDefinition,
    custombrandAuthenticationDefinition,
    userManagementDefinition,
  } = state;
  return {
    ...authenticationDefinition,
    ...custombrandAuthenticationDefinition,
    ...userManagementDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    // Combine reducer functions
    {
      ...mapDispatchToProps,
      ...brandUpdateDispatch,
    },
  )(UpdateSecurityDetails),
  storage.name,
);
