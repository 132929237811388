import React, { Component } from 'react';
import { Form, Button } from 'reactstrap';
import { Content, Markdown, Check } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import Amount from '../../shared/inputAmount';

class WithdrawalForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = this.props.account;
    const balance = AvailableBalance + PendingBalance;
    const data = this.props.data;
    return {
      form: {
        closeAccount: {
          rules: {
            title: 'Close Account',
            conditionalCheck: {
              balance: balance,
              message:
                'You have decided to withdraw your full balance. Please confirm that you are happy to close the account.',
              comparison: () => {
                const value = this.state.form.amount
                  ? this.state.form.amount.value
                  : 'NA';

                return value;
              },
            },
          },
          value: data && data['closeAccount'] ? data['closeAccount'] : null,
        },
      },
    };
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    const { data, setStateDynamic } = this.props;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data, setStateDynamic } = this.props;
    let newValue = this.formatCurrencyInput(value);
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  formatCurrencyInput = value => {
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    return newValue;
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { setStateDynamic, onSubmit } = this.props;
    let { form, amount } = this.state;

    if (form.closeAccount.value) {
      form = {
        closeAccount: form.closeAccount,
      };
    } else {
      if (!form.amount) {
        form = {
          amount: amount,
          closeAccount: form.closeAccount,
        };
      }
    }

    form = Validate.form(form);
    this.setState({ ...this.state, form });
    setStateDynamic(
      {
        combinedForm: { ...form },
      },
      true,
    );
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    if (typeof onSubmit === 'function') {
      onSubmit(form, true);
    }
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, amount } = this.state;

    if (checked) {
      form = {
        closeAccount: form.closeAccount,
      };
    } else {
      if (!form.amount) {
        form = {
          amount: amount,
          closeAccount: form.closeAccount,
        };
      }
    }

    form = Validate.input(name, checked ? 'true' : null, form);
    this.props.saveData(name, checked ? 'true' : null);
    this.setState({ ...this.state, form });
  };

  setAmountRules = ({ amount, withdrawalRule }) => {
    const form = { ...this.state.form, amount };
    this.setState({
      ...this.state,
      amount,
      form,
      withdrawalRule,
    });
  };

  render() {
    const { onCancelled, account = {}, data } = this.props;
    const { form, withdrawalRule } = this.state;
    return (
      <Form
        autoComplete="off"
        id="notice-to-withdraw-form"
        onSubmit={this.onSubmitHandler}
      >
        <h2 className="mt-5 float-left clear-both w-100">
          <Content
            cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Withdrawal-form:h2-1"
            copytext="Withdrawal details"
          />
        </h2>
        {account && withdrawalRule && !withdrawalRule.isPermitted && (
          <div role="alert" className="alert alert-danger">
            <Content
              cmsTag="Savings-servicing:move-money:error-message"
              copytext={withdrawalRule.message}
            />
          </div>
        )}
        <Check
          cmsTag="GLOBAL:I-wish-to-withdraw-my-balance-and-close-the-account-including-accrued-interest-within-the-notice-period"
          label="I wish to withdraw my full balance and close the account (including accrued interest within the notice period)."
          id="closeAccount"
          field="closeAccount"
          checked={!!form.closeAccount.value}
          value="true"
          validation={form.closeAccount}
          onChange={this.onClick}
        />

        {!form.closeAccount.value && (
          <Amount
            form={form}
            account={account}
            onChange={this.updateCurrency}
            onBlur={this.onBlur}
            data={data}
            code={account && account.CurrencyCode}
            setAmountRules={this.setAmountRules}
            label="Amount to withdraw"
            cmsTag="GLOBAL:Amount-to-withdraw"
            showErrors={true}
            validation={form.amount}
            field="amount"
            id="amount"
            inputGroupClassName="half-field"
            dp={2}
            ruleType="notice-to-withdraw"
          />
        )}
        <div className="alert alert-info">
          <Markdown
            cmsTag="GLOBAL:Withdrawal-date-warning"
            markdown={
              'Please note: If your withdrawal date falls on a weekend or bank holiday, it will be processed the following working day.'
            }
          />
        </div>
        <div className="form-button-group">
          <Button id="create-withdrawal" type="submit" color="primary">
            <Content
              cmsTag="GLOBAL:Review-new-notice"
              copytext="Review new notice"
            />
          </Button>
          <Button
            id="cancel-notice-to-withdraw"
            type="button"
            color="secondary"
            onClick={onCancelled}
          >
            <Content
              cmsTag="GLOBAL:Back-to-notice-list"
              copytext="Back to notice list"
            />
          </Button>
        </div>
      </Form>
    );
  }
}

WithdrawalForm.propTypes = {
  account: PropTypes.object,
  setStateDynamic: PropTypes.any,
  actionDate: PropTypes.any,
  noticeExpiryDate: PropTypes.any,
  saveData: PropTypes.func,
  onCancelled: PropTypes.func,
  data: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default WithdrawalForm;
