import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import ServicesIndex from './ServicesIndex';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

class Services extends React.Component {
  render() {
    const { error } = this.props;
    let re = new RegExp('^/details[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/details/index" />;
    }
    return (
      <div id="services-routing">
        {error ? <Alert color="danger">{error}</Alert> : ''}
        <Switch>
          <PrivateRoute exact path="/details/index" component={ServicesIndex} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

Services.propTypes = {
  error: PropTypes.any,
  location: PropTypes.any,
};

export default Services;
