import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import AccountsList from './AccountsList';
import AccountDetailsTemplate from './AccountDetailsTemplate';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as brandUserManagementDispatch } from '@myie/interact-user-management';
import { mapDispatchToProps as servicingMapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as userManagementDispatch } from '@myie/interact-brand-user-management';
import { Session } from '@myie/interact';
import { Alert } from 'reactstrap';
import NominatedAccount from './NominatedAccount';

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    localStorage.removeItem('firstLogin');
    this.state = {
      called: false,
      accountCalled: false,
    };
  }
  componentDidMount() {
    const {
      fetchAccountsIfNeeded,
      getUserDetails,
      getIframe,
      getNominatedAccountStatus,
      isAccountFetching,
    } = this.props;
    //***Shold be removed from the code.
    const retrievedUserName = Session.customer();
    getUserDetails({ userName: retrievedUserName.Username });
    getIframe({ Category: 'NominatedAccount' });
    if (!isAccountFetching) {
      fetchAccountsIfNeeded();
    }
    const request = {
      userName: retrievedUserName.Username,
      Category: 'NominatedAccount',
      attempt: 1,
    };
    getNominatedAccountStatus(request);
  }

  static getDerivedStateFromProps(props, state) {
    const { accounts, isFetching, viewGoals } = props;

    let keys = [];
    if (accounts && accounts.Accounts && !isFetching && !state.called) {
      accounts.Accounts.forEach(account => {
        keys.push(account.AccountKey);
      });

      viewGoals({
        AccountKeys: keys,
      });
      return {
        called: true,
        accountCalled: true,
      };
    }
    if (accounts && !state.accountCalled) {
      return {
        accountCalled: true,
      };
    }
    return null;
  }

  render() {
    const { error } = this.props;
    const re = new RegExp('^/accounts[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/accounts/list" />;
    }
    return (
      <div id="accounts">
        {error ? <Alert color="danger">{error}</Alert> : ''}
        <Switch>
          <PrivateRoute
            exact
            path="/accounts/list/:flag?"
            render={routeProps => <AccountsList {...routeProps} />}
          />
          <PrivateRoute
            path="/accounts/details/:id"
            component={AccountDetailsTemplate}
          />
          <PrivateRoute
            path="/accounts/nominated-account/:page?/:id?"
            component={NominatedAccount}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

Accounts.propTypes = {
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  account: PropTypes.any,
  userDetails: PropTypes.object,
  getUserDetails: PropTypes.any,
  viewGoals: PropTypes.func,
  accounts: PropTypes.object,
  viewGoalDetails: PropTypes.object,
  getIframe: PropTypes.func,
  nominatedAccountStatus: PropTypes.object,
  isFetching: PropTypes.any,
  getNominatedAccountStatus: PropTypes.func,
  resetViewGoals: PropTypes.func,
  isAccountFetching: PropTypes.bool,
};
const mapStateToProps = state => {
  const {
    custombrandSavingsServicingDefinition,
    accountsDefinition,
    userManagementDefinition,
  } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...userManagementDefinition,
    ...accountsDefinition,
    isAccountFetching: accountsDefinition.isFetching,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...servicingMapDispatchToProps,
    ...brandUserManagementDispatch,
    ...userManagementDispatch,
  },
)(Accounts);
