import React from 'react';
import {
  HelpContactSidebar,
  RegisterSidebarComponent,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class SignInSidebar extends React.Component {
  render() {
    return (
      <aside className="right-container">
        <HelpContactSidebar contacts={CONTACTS} />
        <RegisterSidebarComponent />
      </aside>
    );
  }
}

SignInSidebar.propTypes = {};

export default SignInSidebar;
