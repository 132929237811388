import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route } from 'react-router-dom';
import ViewGoals from './viewGoals/ViewGoals';
import Delete from './deleteGoal/';
import GoalDetailsForm from './editGoal/';
import ReplaceGoal from './ReplaceGoal';
import AddANewGoal from './addANewGoal/AddANewGoal';

class SavingsGoalsRoutes extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          path="/money-tools/savings-goals/view"
          component={ViewGoals}
        />
        <PrivateRoute
          path="/money-tools/savings-goals/create-goals/:page?/:id?/:replace?"
          component={AddANewGoal}
        />
        <PrivateRoute
          path="/money-tools/savings-goals/delete-goals/:page?/:id?"
          component={Delete}
        />
        <PrivateRoute
          path="/money-tools/savings-goals/edit-goals/:page?/:id?"
          component={GoalDetailsForm}
        />
        <PrivateRoute
          path="/money-tools/savings-goals/replace-goal/:page?/:id?"
          component={ReplaceGoal}
        />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default SavingsGoalsRoutes;
