import { Connection, TwoFactor } from '@myie/interact';

import {
  ACTIVATION_REQUEST,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAILURE,
  RESET_ACTIVATION,
  SET_CREDENTIALS_REQUEST,
  SET_CREDENTIALS_SUCCESS,
  SET_CREDENTIALS_FAILURE,
} from './registrationTypes';

export const resetActivation = () => ({
  type: RESET_ACTIVATION,
});
export const activationRequest = () => ({
  type: ACTIVATION_REQUEST,
  isFetching: true,
  error: null,
});

export const activationFailed = error => ({
  type: ACTIVATION_FAILURE,
  isFetching: false,
  error: error.message,
});

export const activationReceive = json => ({
  type: ACTIVATION_SUCCESS,
  activationCheck: json,
  isFetching: false,
  error: null,
});

export const registrationcheck = request => dispatch => {
  dispatch(activationRequest());
  return fetch(Connection.baseUrl('custombrand') + `v1.0/registrationcheck`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(activationReceive(json)))
    .catch(error => dispatch(activationFailed(error)));
};

export const setCredentialsRequest = () => ({
  type: SET_CREDENTIALS_REQUEST,
  isFetching: true,
  error: null,
});

export const setCredentialsFailed = error => ({
  type: SET_CREDENTIALS_FAILURE,
  isFetching: false,
  error: error.message,
});

export const setCredentialsReceive = json => ({
  type: SET_CREDENTIALS_SUCCESS,
  credentialsSetup: json,
  isFetching: false,
  error: null,
});

export const brandSetCredentials = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(setCredentialsRequest());
    return fetch(
      Connection.baseUrl('webauthentication') + `v2.0/user/credentials/setup`,
      {
        method: 'post',
        headers: Connection.headers(),
        body: JSON.stringify(request),
      },
    )
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(setCredentialsReceive(json)))
      .catch(error => dispatch(setCredentialsFailed(error)));
  });
};
