import { CONSTANTS, RULES } from '@myie/interact-dom';
import moment from 'moment';

const getDepositInfo = transactions => {
  const startOfYear = moment()
    .startOf('year')
    .toISOString();
  const startOfMonth = moment()
    .startOf('month')
    .toISOString();
  const now = moment()
    .startOf('minute')
    .toISOString();
  let depositsOfMonth = 0,
    depositsOfYear = 0;
  transactions.forEach(trans => {
    const {
      ExtendedProperties: { GlobalTransactionType, TransactionDate },
    } = trans;
    if (GlobalTransactionType === CONSTANTS.TRANSACTION_TYPE_DEPOSIT) {
      if (moment(TransactionDate).isBetween(startOfYear, now)) {
        depositsOfYear += trans.ExtendedProperties.Amount;
      }
      if (moment(TransactionDate).isBetween(startOfMonth, now)) {
        depositsOfMonth += trans.ExtendedProperties.Amount;
      }
    }
  });
  return {
    depositsOfMonth,
    depositsOfYear,
  };
};

export const ruleSet = (account, allProducts, data) => {
  if (!account) {
    return {};
  }
  const product = allProducts.find(
    product => product.Product_Code === account.ExtendedProperties.ProductCode,
  );
  const {
    Maximum_Operating_Balance,
    Maximum_monthly_deposit,
    Maximum_yearly_deposit,
  } = product;
  const {
    ExtendedProperties: {
      TotalAmountAvailableToDeposit,
      AvailableBalance,
      PendingBalance,
    },
  } = account;
  const { depositsOfMonth, depositsOfYear } = getDepositInfo(
    account.transactions.Transactions,
  );
  const balance = AvailableBalance + PendingBalance;
  const amount = {
    rules: {
      title: 'Amount',
      stop: true,
      required: {
        message: 'Please enter an amount to deposit.',
      },
      format: {
        regex: RULES.ammountFormat,
        message: 'Please enter a valid amount to deposit.',
      },
      maxOperationalBalance: {
        message: `Please check and re-enter the amount as the maximum balance required for this account is £${Maximum_Operating_Balance}.`,
        comparison: () => {
          return [Maximum_Operating_Balance, balance];
        },
      },
      isMonthlyAllowanceExceeded: {
        message: `Please check and re-enter the amount as the maximum monthly allowance for this account is £${Maximum_monthly_deposit}.${
          depositsOfMonth
            ? ` You have already used £${depositsOfMonth} of this allowance.`
            : ''
        }`,
        comparison: () => {
          return [Maximum_monthly_deposit, depositsOfMonth];
        },
      },
      isYearlyAllowanceExceeded: {
        message: `Please check and re-enter the amount as the maximum yearly allowance for this account is  £${Maximum_yearly_deposit}.${
          depositsOfYear
            ? ` You have already used £${depositsOfYear} of this allowance.`
            : ''
        }`,
        comparison: () => {
          return [Maximum_yearly_deposit, depositsOfYear];
        },
      },
      totalDepositAvailableExceeded: {
        message: `Please check and re-enter the amount as the total amount available to deposit for this account is  £${TotalAmountAvailableToDeposit}.`,
        comparison: () => {
          return [TotalAmountAvailableToDeposit || 0, 0];
        },
      },
    },
    value: data && data['amount'] ? data['amount'] : '',
    hasUpdated: Date.now(),
  };
  return { amount };
};
