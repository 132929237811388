import React from 'react';
import { Button, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Content } from '@myie/interact-dom';
const url = require('url');

class CompletePayment extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    // use this for posting to iframe
    try {
      this.formRef.current.submit();
    } catch (e) {
      // add code here to trap issues
    }
  }

  iframeLoaded = e => {
    const { submit, referrer, history } = this.props;
    try {
      const href = this.iframeRef.current.contentWindow.location.href;
      const search = this.iframeRef.current.contentWindow.location.search;
      if (href.indexOf('3ds-success.html') >= 0) {
        submit(e, search);
      } else if (href.indexOf('3ds-cancel.html') >= 0) {
        history.push(referrer);
      }
    } catch (e) {
      // usually means it iframe is cross-domain, so not our site
    }
  };

  render() {
    const { props } = this;
    const {
      updateStage,
      //submit,
      error,
      referrer = '/accounts',
      payment,
    } = props;
    let urlParsed = null;
    let params = null;
    if (payment.ThreeDSecureUrl) {
      urlParsed = url.parse(payment.ThreeDSecureUrl);
      params = urlParsed.query.split(',');
    }

    return (
      <div id="paysavings-complete">
        {error}

        <React.Fragment>
          {/* used for post method */}
          {urlParsed && (
            <form
              ref={this.formRef}
              action={urlParsed.href.replace(urlParsed.search, '')}
              method="post"
              target="payByCardFrame"
            >
              {params.map(param => {
                return (
                  <input
                    key={param.split('=')[0]}
                    type="hidden"
                    name={param.split('=')[0]}
                    value={param.split('=')[1]}
                  ></input>
                );
              })}
            </form>
          )}
          {payment.ResponseStatus === 'PerformThreeDSecure' ? (
            <iframe
              ref={this.iframeRef}
              style={{ width: '100%', height: '300px', border: 'none' }}
              sandbox="allow-scripts allow-forms allow-same-origin allow-top-navigation"
              name="payByCardFrame"
              //src={payment.ThreeDSecureUrl} uncomment if using get method
              title="3d Secure"
              onLoad={this.iframeLoaded}
            ></iframe>
          ) : (
            ''
          )}
        </React.Fragment>
        <Form
          autoComplete="off"
          id="complete-payment-form"
          onSubmit={e => updateStage(e, 'MakePayment')}
        >
          {payment.ResponseStatus !== 'PerformThreeDSecure' ? (
            <div className="form-button-group">
              <Button
                id="make-payment-3ds-back"
                type="submit"
                color="secondary"
              >
                <Content
                  cmsTag="GLOBAL:Back-to-payment-page"
                  copytext="Back to payment page"
                />
              </Button>
              <Link className="btn btn-light" role="button" to={referrer}>
                <Content
                  cmsTag="Savings-servicing:Pay-by-card:Complete-payment:Cancel"
                  copytext="Cancel"
                />
              </Link>
            </div>
          ) : (
            ''
          )}
        </Form>
      </div>
    );
  }
}

CompletePayment.propTypes = {
  error: PropTypes.any,
  history: PropTypes.any,
  payment: PropTypes.any,
  referrer: PropTypes.string,
  submit: PropTypes.any,
  updateStage: PropTypes.any,
};

export default CompletePayment;
