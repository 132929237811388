import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { Currency, Content /*, TextArea */ } from '@myie/interact-dom';
import FrequencyDropdown from './frequencyDropdown';
import { Icon } from '@myie/interact-dom';

function ItemCourtPayments(props) {
  const {
    // item,
    index,
    hyphenedName,
    itemName,
    form,
    onChange,
    onBlur,
    onItemDelete,
    // showNote,
    // onNoteClick,
    // onNoteChange,
    // onNoteSave,
    // budgetNoteMaxLength,
  } = props;

  return (
    <React.Fragment>
      <h4>{itemName}</h4>
      <Row>
        <Col xs={12} sm={6} md={4} lg={4}>
          <Currency
            groupClassName="with-button"
            autoComplete="off"
            field="total_owed"
            dp={2}
            label={
              <React.Fragment>
                <Content cmsTag="GLOBAL:Total-owed" copytext="Total owed" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-for-ITEMNAME"
                  template={{
                    copytext: {
                      name: itemName,
                    },
                  }}
                  copytext=" for $[name]"
                />
              </React.Fragment>
            }
            id={`court-payments-` + hyphenedName + `-total-owed-` + index}
            value={form.total_owed.value}
            validation={form.total_owed}
            onChange={onChange}
            onBlur={onBlur}
            code="GBP"
            required
          />
          {/* Commented out for DAR
          <Button
            className="note-button"
            color={item.note ? 'success' : 'secondary'}
            id={`court-payments-` + hyphenedName + `-note-button-` + index}
            onClick={onNoteClick}
          >
            <Icon name="NoteIcon" />
            <span className="sr-only">
              <Content
                cmsTag="LABEL:Open-note:Variable"
                template={{
                  copytext: {
                    name: itemName,
                  },
                }}
                copytext="Open note for $[name]"
              />
            </span>
          </Button> */}
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
          <Currency
            label={
              <React.Fragment>
                <Content
                  cmsTag="GLOBAL:Usual-payment"
                  copytext="Usual payment"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-for-ITEMNAME"
                  template={{
                    copytext: {
                      name: itemName,
                    },
                  }}
                  copytext=" for $[name]"
                />
              </React.Fragment>
            }
            autoComplete="off"
            field="amount"
            dp={2}
            id={`court-payments-` + hyphenedName + `-amount-` + index}
            value={form.amount.value}
            validation={form.amount}
            onChange={onChange}
            onBlur={onBlur}
            code="GBP"
            required
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          lg={4}
          className="align-items-center with-delete-button"
        >
          <FrequencyDropdown
            label={
              <React.Fragment>
                <Content cmsTag="GLOBAL:Frequency" copytext="Frequency" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-of-ITEMNAME"
                  template={{
                    copytext: {
                      name: itemName,
                    },
                  }}
                  copytext=" of $[name]"
                />
              </React.Fragment>
            }
            id={`court-payments-` + hyphenedName + `-frequency-` + index}
            frequency={form.frequency}
            onChange={onChange}
            onBlur={onBlur}
            groupClassName="with-button"
            required
          />
          <Button
            id={`court-payments-` + hyphenedName + `-delete-button-` + index}
            color="danger"
            className="delete-button"
            onClick={onItemDelete}
          >
            <Icon name="Delete" />
            <Content
              tag="span"
              tagClassName="sr-only"
              cmsTag="GLOBAL:Delete-ITEMNAME"
              copytext={`Delete ` + itemName}
            />
          </Button>
        </Col>
        {/* Commented out for DAR 
        {showNote && (
          <Col xs={12} md={12} lg={12}>
            <TextArea
              label={`Note for ` + itemName}
              id={`court-payments-` + hyphenedName + `-note-` + index}
              field="noteText"
              onChange={onNoteChange}
              onBlur={onNoteChange}
              value={form.noteText.value}
              validation={form.noteText}
              maxLength={budgetNoteMaxLength}
            />
            <Button
              id={
                `court-payments-` + hyphenedName + `-note-save-button-` + index
              }
              className="save-note-button"
              color="light"
              onClick={onNoteSave}
            >
              <Content cmsTag="LABEL:Save:Variable" copytext="Save" />
              <span className="sr-only"> {itemName}</span>
            </Button>
          </Col>
        )} */}
      </Row>
    </React.Fragment>
  );
}

ItemCourtPayments.propTypes = {
  category: PropTypes.any,
  group: PropTypes.any,
  index: PropTypes.any,
  item: PropTypes.any,
  categoryIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hyphenedName: PropTypes.any,
  itemName: PropTypes.any,
  form: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onItemDelete: PropTypes.func,
  showNote: PropTypes.bool,
  onNoteClick: PropTypes.func,
  onNoteChange: PropTypes.func,
  onNoteSave: PropTypes.func,
  budgetNoteMaxLength: PropTypes.number,
};

export default ItemCourtPayments;
