import React from 'react';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';

class YourAddress extends React.Component {
  render() {
    const { userDetails } = this.props;

    let addressLine1 = '';
    let addressLine2 = '';
    let addressLine3 = '';
    let townCity = '';
    let county = '';
    let postcode = '';

    let customerAddress = userDetails.ExtendedProperties.ClientAddress;
    if (userDetails && userDetails.ExtendedProperties.CorrespondenceAddress) {
      customerAddress = userDetails.ExtendedProperties.CorrespondenceAddress;
    }
    addressLine1 = customerAddress.Address1;
    addressLine2 = customerAddress.Address2;
    addressLine3 = customerAddress.Address3;
    townCity = customerAddress.Address4;
    county = customerAddress.Address5;
    postcode = customerAddress.AddressPC;

    return (
      <section className="mt-5">
        <Content
          tag="h2"
          cmsTag="User-management:Personal-details:View:Your-address:h2-1"
          copytext="Your correspondence address"
        />
        <address>
          {addressLine1 !== null ? (
            <React.Fragment>
              {addressLine1}
              <br />
            </React.Fragment>
          ) : (
            ''
          )}
          {addressLine2 !== null ? (
            <React.Fragment>
              {addressLine2}
              <br />
            </React.Fragment>
          ) : (
            ''
          )}
          {addressLine3 !== null ? (
            <React.Fragment>
              {addressLine3}
              <br />
            </React.Fragment>
          ) : (
            ''
          )}
          {townCity !== null ? (
            <React.Fragment>
              {townCity}
              <br />
            </React.Fragment>
          ) : (
            ''
          )}
          {county !== null ? (
            <React.Fragment>
              {county}
              <br />
            </React.Fragment>
          ) : (
            ''
          )}
          {postcode !== null ? (
            <React.Fragment>
              {postcode}
              <br />
            </React.Fragment>
          ) : (
            ''
          )}
        </address>
      </section>
    );
  }
}

YourAddress.propTypes = {
  userDetails: PropTypes.object,
};

export default YourAddress;
