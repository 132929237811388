import React from 'react';
import PropTypes from 'prop-types';
import { Content, FormattedDate, CONSTANTS } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-system';

/*
  This component checks if the system is on Read-only mode and handles each status
*/

function checkDownTime(Component, { moduleName = null } = {}) {
  class CheckDownTimeHOC extends React.Component {
    constructor(props) {
      super(props);
      const { isSystemReadOnly } = props;
      isSystemReadOnly();
    }

    getComponentProps = (BatchStartTime, BatchEndTime) => {
      let showMsg = true,
        moduleReadOnly = false;
      let onGoingMsg = (
        <div role="alert" className="alert alert-danger">
          <Content
            cmsTag="SystemMessage:Servicing:downtime:ongoing"
            copytext="Please note that this service is not currently available due to planned system maintenance.  The maintenance is due to complete on "
          />
          <FormattedDate
            date={BatchEndTime}
            format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
          />
        </div>
      );
      let upcomingMsg = (
        <div role="alert" className="alert alert-danger">
          <Content
            cmsTag="SystemMessage:Servicing:downtime:upcoming:part-1"
            copytext="We will be starting planned systems maintenance soon.  This service will not be available between "
          />
          <FormattedDate
            date={BatchStartTime}
            format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
          />
          <Content
            cmsTag="SystemMessage:Servicing:downtime:upcoming:part-2"
            copytext=". and "
          />
          <FormattedDate
            date={BatchEndTime}
            format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
          />
        </div>
      );
      if (moduleName === CONSTANTS.COMPONENT_QUICK_TRANSFER) {
        showMsg = false;
      }
      if (moduleName === CONSTANTS.COMPONENT_ACCOUNT_LIST) {
        moduleReadOnly = true;
        upcomingMsg = (
          <div role="alert" className="alert alert-danger">
            <Content
              cmsTag="SystemMessage:Servicing:downtime:AccountList:upcoming:part-1"
              copytext="Please note that some services will not be available due to planned system maintenance. The maintenance is due between "
            />
            <FormattedDate
              date={BatchStartTime}
              format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
            />
            <Content
              cmsTag="SystemMessage:Servicing:AccountList:upcoming:part-2"
              copytext=" and "
            />
            <FormattedDate
              date={BatchEndTime}
              format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
            />
          </div>
        );
        onGoingMsg = (
          <div role="alert" className="alert alert-danger">
            <Content
              cmsTag="SystemMessage:Servicing:AccountList:downtime:ongoing"
              copytext="Please note that some services are not currently available due to planned system maintenance.  The maintenance is due to complete on "
            />
            <FormattedDate
              date={BatchEndTime}
              format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
            />
          </div>
        );
      }
      return { showMsg, moduleReadOnly, onGoingMsg, upcomingMsg };
    };

    getDownTimeStatus = ({
      CurrentDowntimeStatus,
      BatchStartTime,
      BatchEndTime,
    }) => {
      const {
        showMsg,
        moduleReadOnly,
        onGoingMsg,
        upcomingMsg,
      } = this.getComponentProps(BatchStartTime, BatchEndTime);
      switch (CurrentDowntimeStatus) {
        case 'ONGOING':
          return {
            msg: showMsg ? onGoingMsg : null,
            shouldBlock: !moduleReadOnly,
          };
        case 'UPCOMING':
          return {
            msg: showMsg ? upcomingMsg : null,
            shouldBlock: false,
          };
        default:
          return { msg: null, shouldBlock: false };
      }
    };

    render() {
      const { systemMessages = {}, ...rest } = this.props;
      const status = this.getDownTimeStatus(systemMessages);

      return <Component systemDownStatus={status} {...rest} />;
    }
  }
  CheckDownTimeHOC.propTypes = {
    isSystemReadOnly: PropTypes.func,
    systemMessages: PropTypes.object,
    moduleName: PropTypes.string,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(CheckDownTimeHOC));
}

export default checkDownTime;
