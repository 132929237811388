import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card, CardHeader, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import { mapDispatchToProps as mapDispatchToPropsAttachments } from '@myie/interact-brand-message-attachments';
import { mapDispatchToProps as mapDispatchToPropsMessaging } from '@myie/interact-messaging-twoway';
import { Utility } from '@myie/interact';
import PropTypes from 'prop-types';
import { CONTACTS } from '@myie/interact-local-dom';
import {
  FormattedDate,
  Value,
  Content,
  AppMeta,
  Markdown,
  CONSTANTS,
  Pagination,
} from '@myie/interact-dom';
import FormatBody from '../FormatBody';

class ViewConversation extends React.Component {
  constructor(props) {
    super(props);
    const {
      match,
      allMessages,
      history,
      viewMessage,
      viewConversation,
    } = this.props;
    let conversation;
    this.state = {
      stage: 'View',
      message: null,
      offset: 0,
      size: 5,
      attachments: null,
      pdfList: [],
      attachmentRequested: false,
      data: {
        accountTypeField: 'All',
        accountField: 'All',
      },
    };
    if (allMessages) {
      if (!viewMessage) {
        conversation = allMessages.Conversations.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.ConversationKey.Key) === match.params.id
            );
          }
          return false;
        });

        let request = {
          ConversationKey: conversation.ConversationKey,
          ProductType: 'string',
          ExtendedProperties: {},
        };
        viewConversation(request);
      }
    } else {
      const newUrl = '/messaging-twoway/view';
      history.push(newUrl);
    }
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }
  componentWillUnmount() {
    const { resetMessageAttachments } = this.props;
    resetMessageAttachments();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      getMessageAttachments,
      viewMessage,
      isFetching,
      messageAttachments,
    } = nextProps;
    if (messageAttachments && !prevState.attachments) {
      return {
        attachments: messageAttachments.Attachments,
      };
    }

    if (
      viewMessage &&
      viewMessage.Conversation &&
      viewMessage.Conversation.Reason.search(
        CONSTANTS.AUTO_GENERATED_MESSAGE,
      ) !== -1 &&
      viewMessage.Conversation.Reason.search('hasattachments') !== -1 &&
      !messageAttachments &&
      getMessageAttachments &&
      !prevState.attachmentRequested &&
      !isFetching
    ) {
      const reason = viewMessage.Conversation.Reason;
      const dash = reason.split('-');
      const hashes = dash[0].split('##');
      const exclamation = hashes[1].split('!');
      let request = { EmailDocumentId: exclamation[1] };
      getMessageAttachments(request);
      return { attachmentRequested: true };
    }

    return {
      ...prevState,
      allMessages: nextProps.allMessages ? nextProps.allMessages : null,
    };
  }

  isConversationClosed = Conversation => {
    let closed = false;
    if (Conversation) {
      if (Conversation.ConversationProperties) {
        let convProperties = Conversation.ConversationProperties;
        convProperties.forEach(convProp => {
          if (convProp.Name === 'Closed') {
            if (convProp.Value === 'True') {
              closed = true;
            }
          }
        });
      }
    }
    return closed;
  };

  base64toPDF = data => {
    //let { pdfList } = this.state;
    let bufferArray = this.base64ToArrayBuffer(data);
    let blobStore = new Blob([bufferArray], { type: 'application/pdf' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    let newData = window.URL.createObjectURL(blobStore);

    const file = newData;
    // const newList = [file, ...pdfList];
    //this.setState({ ...this.state, pdfList: newList });
    //window.URL.revokeObjectURL(newData);
    return file;
  };

  base64ToArrayBuffer = data => {
    let bString = window.atob(data);
    let bLength = bString.length;
    let bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      let ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  executeAction = e => {
    const value = e.target.value;

    const { history, match } = this.props;
    let newUrl;
    switch (value) {
      case 'Reply':
        newUrl =
          '/messaging-twoway/message/' +
          value.toLowerCase() +
          '/' +
          match.params.id;
        history.push(newUrl);
        break;
      case 'Delete':
        newUrl =
          '/messaging-twoway/message/' +
          value.toLowerCase() +
          '/' +
          match.params.id;
        history.push(newUrl);
        break;
      default:
    }
  };

  getPreviousPage = (Offset, Size) => {
    const previousOffset = Offset - Size;
    if (previousOffset >= 0) {
      this.setState({ ...this.state, offset: previousOffset });
    }
  };

  getNextPage = (Offset, Size, TotalConversationsCount) => {
    const nextOffset = Offset + Size;
    if (nextOffset <= TotalConversationsCount) {
      this.setState({ ...this.state, offset: nextOffset });
    }
  };

  // select current stage
  render() {
    const { viewMessage } = this.props;
    const { attachments, offset, size } = this.state;

    let Conversation;
    let supportActions = [];
    let messages = [];
    let messageCount = 0;
    if (!viewMessage) {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    } else {
      Conversation = viewMessage.Conversation;
    }

    if (Conversation && Conversation.Supports) {
      supportActions = Conversation.Supports;
    }

    if (Conversation && Conversation.Messages) {
      messageCount = Conversation.Messages.length;
      if (messageCount > size) {
        messages = Conversation.Messages.slice(offset, offset + size);
      } else {
        messages = Conversation.Messages;
      }
    }

    return (
      <div id="conversation-view">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Message details"
          metaDescription="Message details"
        />
        <h1>
          {Conversation.Reason.search(CONSTANTS.AUTO_GENERATED_MESSAGE) !==
          -1 ? (
            <Content
              cmsTag="Messaging-two-way:Conversation:View:h1"
              copytext="Message About Your Account"
            />
          ) : (
            <Value value={Conversation.Reason} />
          )}
        </h1>
        <Row
          tag="dl"
          title="Message details"
          className="message-details-header mb-5"
        >
          {Conversation.Reason.search(CONSTANTS.AUTO_GENERATED_MESSAGE) ===
          -1 ? (
            <React.Fragment>
              <Col tag="dt" xs={12} sm={6} md={3}>
                <Content
                  cmsTag="GLOBAL:Related-account"
                  copytext="Related account"
                />
              </Col>
              <Col tag="dd" xs={12} sm={6} md={9}>
                {Conversation.AccountNumber !== 'XXXXXXXXXable' &&
                Conversation.AccountNumber !== 'Not available' ? (
                  <React.Fragment>
                    <Content
                      cmsTag="GLOBAL:Account-ending-"
                      copytext="Account ending "
                    />
                    {Conversation.AccountNumber.substr(-4)}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Col>
            </React.Fragment>
          ) : (
            ''
          )}

          <Col tag="dt" xs={12} sm={6} md={3}>
            <Content
              cmsTag="GLOBAL:Reference-number"
              copytext="Reference number"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={9}>
            {Conversation.Reference}
          </Col>
        </Row>
        <ul
          className="messages-list list-unstyled"
          title="Message Conversation"
        >
          {messages.map((item, index) => {
            return (
              <li key={`message-${index}`}>
                {Conversation.Reason.search(
                  CONSTANTS.AUTO_GENERATED_MESSAGE,
                ) !== -1 ? (
                  <h2 aria-describedby={`view-message-body-${index}`}>
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="GLOBAL:Sent-by-"
                      copytext="Sent by "
                    />
                    <Markdown
                      template={{
                        markdown: {
                          companyNameShorthand: CONTACTS.companyNameShorthand,
                        },
                      }}
                      cmsTag="GLOBAL:Company-name"
                      markdown={`$[companyNameShorthand]`}
                    />
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="GLOBAL:-on-"
                      copytext=" on "
                    />
                    <span className="timestamp">
                      <FormattedDate
                        date={item.CreatedDate}
                        format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
                      />
                    </span>
                  </h2>
                ) : (
                  <h2 aria-describedby={`view-message-body-${index}`}>
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="GLOBAL:Sent-by-"
                      copytext="Sent by "
                    />
                    {item.Sender.SenderType === 'User' ? (
                      `${item.Sender.SenderName}`
                    ) : (
                      <Markdown
                        template={{
                          markdown: {
                            companyNameShorthand: CONTACTS.companyNameShorthand,
                          },
                        }}
                        cmsTag="GLOBAL:Company-name"
                        markdown={`$[companyNameShorthand]`}
                      />
                    )}
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="GLOBAL:-on-"
                      copytext=" on "
                    />
                    <span className="timestamp">
                      <FormattedDate
                        date={item.CreatedDate}
                        format={CONSTANTS.STANDARD_DATE_FORMAT_TIME}
                      />
                    </span>
                  </h2>
                )}

                <p id={`view-message-body-${index}`}>{FormatBody(item.Body)}</p>
              </li>
            );
          })}
        </ul>
        {messageCount > size && (
          <Pagination
            Total={messageCount}
            Offset={offset}
            Size={size}
            getPreviousPage={this.getPreviousPage}
            getNextPage={this.getNextPage}
          />
        )}
        <div className="form-button-group">
          {Conversation.Reason.search(CONSTANTS.AUTO_GENERATED_MESSAGE) ===
          -1 ? (
            <Button
              key={`button-Reply`}
              id="confirm-submit-reply"
              color="primary"
              onClick={this.executeAction}
              value={'Reply'}
              disabled={
                supportActions.includes('Reply') &&
                !this.isConversationClosed(Conversation)
                  ? false
                  : true
              }
            >
              <Content
                cmsTag="Messaging-two-way:Conversation:View:Reply"
                copytext="Reply"
              />
              <Content
                tagClassName="sr-only"
                tag="span"
                cmsTag="Messaging-two-way:Conversation:View:-to-this-message"
                copytext=" to this message"
              />
            </Button>
          ) : null}

          <Link
            id="back-to-messages-link-messaging"
            className="btn btn-secondary"
            to="/messaging-twoway/view"
          >
            <Content
              cmsTag="GLOBAL:Back-to-message-list"
              copytext="Back to message list"
            />
          </Link>
        </div>
        {Conversation.Reason.search('hasattachments') !== -1 ? (
          <Card color="primary" className="attached-files-container">
            <CardHeader>
              <Content
                tag="h2"
                cmsTag="Messaging-two-way:Conversation:View:h2-1"
                copytext="Attached files"
              />
            </CardHeader>
            <CardBody>
              {attachments &&
                attachments.map(item => {
                  return (
                    <figure key={item.Key}>
                      <a
                        href={this.base64toPDF(item.Value)}
                        type="application/pdf"
                        id={item.Key}
                        download={item.Key}
                        onClick={this.downloadAttachment}
                      >
                        {item.Key}
                        <Content
                          tagClassName="sr-only"
                          tag="span"
                          cmsTag="GLOBAL:-downloads-a-PDF"
                          copytext=" (downloads a PDF)"
                        />
                      </a>
                    </figure>
                  );
                })}
            </CardBody>
          </Card>
        ) : (
          ''
        )}
      </div>
    );
  }
}

ViewConversation.propTypes = {
  allMessages: PropTypes.any,
  baseUrl: PropTypes.any,
  fetchAllMessagesIfNeeded: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  viewConversation: PropTypes.any,
  getMessageAttachments: PropTypes.func,
  resetMessageAttachments: PropTypes.func,
  isFetching: PropTypes.any,
  messageAttachments: PropTypes.any,
  viewMessage: PropTypes.any,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
};

const mapStateToProps = state => {
  const {
    messagingTwowayDefinition,
    accountsDefinition,
    custombrandMessageAttachmentDefinition,
  } = state;
  return {
    ...messagingTwowayDefinition,
    ...accountsDefinition,
    ...custombrandMessageAttachmentDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToPropsMessaging,
    ...mapDispatchToPropsAccounts,
    ...mapDispatchToPropsAttachments,
  },
)(ViewConversation);
