import { Connection } from '@myie/interact';

import {
  RESET_VIEW_GOALS,
  VIEW_GOALS_REQUEST,
  VIEW_GOALS_SUCCESS,
  VIEW_GOALS_FALIURE,
  RESET_CREATE_GOALS,
  CREATE_GOALS_REQUEST,
  CREATE_GOALS_SUCCESS,
  CREATE_GOALS_FALIURE,
  RESET_UPDATE_GOALS,
  UPDATE_GOALS_REQUEST,
  UPDATE_GOALS_SUCCESS,
  UPDATE_GOALS_FALIURE,
  RESET_DELETE_GOALS,
  DELETE_GOALS_REQUEST,
  DELETE_GOALS_SUCCESS,
  PRESERVE_GOALS_STATE,
  DELETE_GOALS_FALIURE,
  RESET_PRESERVE_GOALS_STATE,
  SAVINGS_GOALS_BANNER_MESSAGW_ARRAY,
  SET_BACK_PAGE_MODE,
} from './savingsGoalsTypes';

export const resetViewGoals = () => ({
  type: RESET_VIEW_GOALS,
});

export const viewGoalsRequest = () => ({
  type: VIEW_GOALS_REQUEST,
  isFetching: true,
  error: null,
});

export const viewGoalsSuccess = json => ({
  type: VIEW_GOALS_SUCCESS,
  viewGoalsResponse: json,
  isFetching: false,
  error: null,
});

export const viewGoalsFaliure = error => ({
  type: VIEW_GOALS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const viewGoals = request => dispatch => {
  dispatch(viewGoalsRequest());
  return fetch(Connection.baseUrl('savingsservicing') + `v1.0/goals/view`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(viewGoalsSuccess(json)))
    .catch(error => dispatch(viewGoalsFaliure(error)));
};

export const resetCreateGoals = () => ({
  type: RESET_CREATE_GOALS,
});

export const CreateGoalsRequest = () => ({
  type: CREATE_GOALS_REQUEST,
  isFetching: true,
  error: null,
});

export const CreateGoalsSuccess = json => ({
  type: CREATE_GOALS_SUCCESS,
  createGoalsResponse: json,
  isFetching: false,
  error: null,
});

export const CreateGoalsFaliure = error => ({
  type: CREATE_GOALS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const createGoals = request => dispatch => {
  dispatch(CreateGoalsRequest());
  return fetch(
    Connection.baseUrl('savingsservicing') + `v1.0/goals/goal/create`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(CreateGoalsSuccess(json)))
    .catch(error => dispatch(CreateGoalsFaliure(error)));
};

export const resetUpdateGoals = () => ({
  type: RESET_UPDATE_GOALS,
});

export const updateGoalsRequest = () => ({
  type: UPDATE_GOALS_REQUEST,
  isFetching: true,
  error: null,
});

export const updateGoalsSuccess = json => ({
  type: UPDATE_GOALS_SUCCESS,
  updateGoalsResponse: json,
  isFetching: false,
  error: null,
});

export const updateGoalsFaliure = error => ({
  type: UPDATE_GOALS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const updateGoals = request => dispatch => {
  dispatch(updateGoalsRequest());
  return fetch(
    Connection.baseUrl('savingsservicing') + `v1.0/goals/goal/update`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(updateGoalsSuccess(json)))
    .catch(error => dispatch(updateGoalsFaliure(error)));
};

export const resetDeleteGoals = () => ({
  type: RESET_DELETE_GOALS,
});

export const deleteGoalsRequest = () => ({
  type: DELETE_GOALS_REQUEST,
  isFetching: true,
  error: null,
});

export const deleteGoalsSuccess = json => ({
  type: DELETE_GOALS_SUCCESS,
  deleteGoalsResponse: json,
  isFetching: false,
  error: null,
});

export const deleteGoalsFaliure = error => ({
  type: DELETE_GOALS_FALIURE,
  isFetching: false,
  error: error.message,
});

export const deleteGoals = request => dispatch => {
  dispatch(deleteGoalsRequest());
  return fetch(
    Connection.baseUrl('savingsservicing') + `v1.0/goals/goal/delete`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(deleteGoalsSuccess(json)))
    .catch(error => dispatch(deleteGoalsFaliure(error)));
};

export const saveGoalsBannerMessageArray = bannerMessageArray => ({
  type: SAVINGS_GOALS_BANNER_MESSAGW_ARRAY,
  bannerMessageArray: bannerMessageArray,
});

export const preserveGoalState = json => ({
  type: PRESERVE_GOALS_STATE,
  preservedSavingsGoalState: json,
  isFetching: false,
  error: null,
});

export const resetPreserveGoalState = () => ({
  type: RESET_PRESERVE_GOALS_STATE,
});

export const setBackPageMode = (pageMode = 'goal-list') => ({
  type: SET_BACK_PAGE_MODE,
  pageMode,
});
