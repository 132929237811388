import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapDispatchToProps as mapDispatchToPropsAccounts } from '@myie/interact-accounts';
import _ from 'lodash';
import {
  Markdown,
  FormattedCurrency,
  Content,
  CONSTANTS,
} from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, CardBody } from 'reactstrap';

class SelectAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'Details',
      message: null,
      data: {},
    };
  }

  static getDerivedStateFromProps = nextProps => {
    const { accounts = {}, fetchAccountsIfNeeded, isFetching } = nextProps;

    if (!accounts && !isFetching) {
      fetchAccountsIfNeeded();
    }

    return null;
  };

  generateAccountInfo(account) {
    switch (account.ExtendedProperties.AccountType) {
      case 'Loan':
        return (
          <React.Fragment>
            {account.AccountNumber} {account.SortCode}
          </React.Fragment>
        );
      case CONSTANTS.ACCOUNT_TYPE_SAVINGS:
        return (
          <React.Fragment>
            {account.AccountNumber} {account.SortCode}
          </React.Fragment>
        );
      case 'Current':
      case 'LoyaltyCard':
      case 'MotorLoan':
        return <React.Fragment>{account.AccountNumber}</React.Fragment>;

      case 'CreditCard':
        return (
          <Markdown
            cmsTag="GLOBAL:ending-CARD-NUMBER"
            template={{
              markdown: {
                value: account.AccountNumber,
              },
            }}
            markdown={`ending $[value]`}
          />
        );
      default:
        return null;
    }
  }

  sortAccountList = accounts => {
    let mortgageList = [];
    let savingsList = [];
    accounts.forEach(account => {
      let thisAccount = account;

      if (
        account.ExtendedProperties.AccountType ===
        CONSTANTS.ACCOUNT_TYPE_MORTGAGE
      ) {
        if (account.ExtendedProperties.SubAccounts) {
          const { ExtendedProperties: { CurrentBalance } = {} } = account;
          let outstandingAmount = CurrentBalance;
          account.ExtendedProperties.SubAccounts.forEach(element => {
            const { ExtendedProperties: { CurrentBalance } = {} } = element;

            outstandingAmount = outstandingAmount + CurrentBalance;
          });
          account.outstandingAmount = outstandingAmount;
        }
        mortgageList.push(thisAccount);
      } else {
        const {
          ExtendedProperties: { AvailableBalance, PendingBalance } = {},
        } = account;
        thisAccount.AvailableBalance = AvailableBalance + PendingBalance;
        savingsList.push(thisAccount);
      }
    });
    savingsList = _.orderBy(savingsList, 'AvailableBalance', 'desc');
    mortgageList = _.orderBy(mortgageList, 'outstandingAmount', 'desc');

    const list = savingsList.concat(mortgageList);
    return list;
  };

  addPeriods = accounts => {
    if (accounts) {
      return accounts.map(account => {
        let segments = account.AccountName.split(/\s+/);
        const intValue = parseInt(segments[0]);
        let period = 0;
        switch (segments[1].toLowerCase()) {
          case 'year':
            period = intValue * 360;
            break;
          case 'month':
            period = intValue * 30;
            break;
          case 'day':
            period = intValue;
            break;
          case 'hour':
            period = intValue / 24;
            break;
          case 'minute':
            period = intValue / 1440;
            break;
          default:
        }
        account.period = period;
        return account;
      });
    }
    return accounts;
  };

  render() {
    const { accounts, location = { pathname: '' } } = this.props;
    let currentAccount = null;
    let accountsToList = [];
    if (accounts && accounts.Accounts) {
      currentAccount = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            location.pathname.split('/').pop()
          );
        }
        return false;
      });
    }
    if (accounts && accounts.Accounts && accounts.Accounts.length > 0) {
      accountsToList = accounts.Accounts.filter(account => {
        return (
          account.AccountStatus !== 'Closed' &&
          account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED
        );
      });
    }

    accountsToList = this.sortAccountList(accountsToList);
    return (
      <section>
        <Card color="dark" id="select-accounts-widget">
          <CardHeader>
            <Content
              tag="h2"
              cmsTag="Widget-select-account:Select-accounts:h2-1"
              copytext="Account quick links"
            />
          </CardHeader>
          <CardBody>
            {accountsToList.length > 0
              ? accountsToList.map((account, index) => {
                  // // status === 1 means only show closed accounts
                  // if (
                  //   account.AccountStatus !== 'Closed' &&
                  //   this.props.status === 1
                  // ) {
                  //   return null;
                  // }
                  // // dar have asked not to see closed accounts in sidebar
                  // if (
                  //   account.AccountStatus === 'Closed' /*&& !this.props.status*/
                  // ) {
                  //   return null;
                  // }

                  return (
                    <Link
                      to={
                        '/accounts/details/' +
                        Utility.hexEncode(account.AccountKey.Key)
                      }
                      className={`select-account ${
                        currentAccount &&
                        currentAccount.AccountKey.Key === account.AccountKey.Key
                          ? 'active'
                          : ''
                      }`.trim()}
                      key={`select-account-list-item-${index}`}
                    >
                      <span className="sr-only">
                        {currentAccount &&
                        currentAccount.AccountKey.Key === account.AccountKey.Key
                          ? 'You are currently viewing '
                          : 'View '}
                      </span>
                      <span
                        id={`select-account-list-item-${index}`}
                        className="d-block"
                      >
                        {account.AccountName}
                      </span>
                      <span className="pt-4">
                        <Content
                          tag="span"
                          tagClassName="sr-only"
                          cmsTag="GLOBAL:Balance:-"
                          copytext="Balance: "
                        />
                        <FormattedCurrency
                          quantity={
                            account.ExtendedProperties.PendingBalance
                              ? account.ExtendedProperties.AvailableBalance +
                                account.ExtendedProperties.PendingBalance
                              : account.ExtendedProperties.AvailableBalance
                          }
                          currency={account.CurrencyCode}
                        />
                      </span>
                    </Link>
                  );
                })
              : ''}
          </CardBody>
        </Card>
      </section>
    );
  }
}

SelectAccounts.propTypes = {
  accounts: PropTypes.any,
  error: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  i18n: PropTypes.any,
  isFetching: PropTypes.any,
  location: PropTypes.any,
  status: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition } = state;
  return {
    ...accountsDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { ...mapDispatchToPropsAccounts },
  )(SelectAccounts),
);
