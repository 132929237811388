import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Utility } from '@myie/interact';
import { Content, CONSTANTS } from '@myie/interact-dom';

class Links extends React.Component {
  getLinks = () => {
    const {
      accountType,
      loopIndex,
      accountKey,
      accountStatus,
      accountName,
      account,
    } = this.props;

    switch (accountType) {
      case 'REGULAR_SAVINGS':
        if (accountStatus === 'Active') {
          if (
            account.ExtendedProperties.MaturityCallDepositAccount === 'TRUE'
          ) {
            return;
          } else {
            return (
              <Link
                id={`savings-account-list-notice-to-withdraw-link-${loopIndex}`}
                className="mr-0 btn-light btn"
                to={
                  '/notice-to-withdraw/view/summary/' +
                  Utility.hexEncode(accountKey)
                }
              >
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:View-"
                  copytext="View "
                />
                <Content
                  cmsTag="GLOBAL:Notice-to-withdraw"
                  copytext="Notice to withdraw"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-for-ACCOUNT-NAME"
                  template={{
                    copytext: {
                      accountName: accountName,
                    },
                  }}
                  copytext=" for $[accountName]"
                />
              </Link>
            );
          }
        }
        break;
      case 'EASY_ACCESS':
        if (accountStatus === 'Active') {
          return (
            <Link
              id={`account-list-make-a-payment-link-${loopIndex}`}
              className="mr-0 btn-primary btn"
              to={'/make-withdrawal/' + Utility.hexEncode(accountKey)}
            >
              <Content
                cmsTag="GLOBAL:Make-a-withdrawal"
                copytext="Make a withdrawal"
              />
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="GLOBAL:-for-ACCOUNT-NAME"
                template={{
                  copytext: {
                    accountName: accountName,
                  },
                }}
                copytext=" for $[accountName]"
              />
            </Link>
          );
        }
        break;
      default:
    }
  };

  render() {
    const { props } = this;
    const { loopIndex, account, accountKey, pageMode = 'summary' } = props;
    const {
      ExtendedProperties: {
        AccountMode,
        NoticeToWithdraw,
        DepositsAllowed = false,
      } = {},
      AccountStatus,
    } = account;
    return (
      <div className="pt-4" id={`account-type-savings-link-${loopIndex}`}>
        {pageMode !== 'detail' ? (
          <Link
            id={`savings-account-list-show-details-link-${loopIndex}`}
            className="btn-light btn"
            to={'/accounts/details/' + Utility.hexEncode(props.accountKey)}
          >
            <span className="sr-only">
              <Content cmsTag="GLOBAL:View-" copytext="View " />
            </span>
            <Content
              cmsTag="GLOBAL:Account-details"
              copytext="Account details"
            />
            <span className="sr-only">
              <Content cmsTag="GLOBAL:-for-" copytext=" for " />
              {props.accountName}
            </span>
          </Link>
        ) : (
          ''
        )}

        {(AccountMode === CONSTANTS.ACCOUNT_TRANSACT ||
          AccountMode === CONSTANTS.ACCOUNT_VIEW_ONLY) &&
        AccountStatus !== 'Closed' &&
        DepositsAllowed ? (
          <Link
            id={`savings-account-list-show-make-deposit-link-${loopIndex}`}
            className="btn-light btn"
            to={
              `/make-a-deposit/${pageMode}/` +
              Utility.hexEncode(props.accountKey)
            }
          >
            <span className="sr-only">
              <Content cmsTag="GLOBAL:View-" copytext="View " />
            </span>
            <Content
              cmsTag="GLOBAL:Account-make-a-deposit"
              copytext="Make a deposit"
            />
          </Link>
        ) : (
          ''
        )}

        {pageMode === 'detail' ? (
          <Link
            id={`account-detail-view-nominated-account-${loopIndex}`}
            className="btn-light btn"
            to={`/nominated-account-for-withdrawals/details/account-details/${Utility.hexEncode(
              account.AccountKey.Key,
            )}`}
          >
            <Content
              cmsTag="GLOBAL:view-nominated-account"
              copytext="View nominated account"
            />
            <span className="sr-only">
              <Content cmsTag="GLOBAL:-for-" copytext=" for " />
              {props.accountName}
            </span>
          </Link>
        ) : (
          ''
        )}

        {(AccountMode === CONSTANTS.ACCOUNT_TRANSACT ||
          AccountMode === CONSTANTS.ACCOUNT_VIEW_ONLY) &&
        NoticeToWithdraw === CONSTANTS.NOTICE_TO_WITHDRAW &&
        AccountStatus !== 'Closed' ? (
          <Link
            id={`account-list-notice-to-withdraw-link-${loopIndex}`}
            className="mr-0 btn-light btn"
            to={
              `/notice-to-withdraw/view/${pageMode}/` +
              Utility.hexEncode(accountKey)
            }
          >
            <span className="sr-only">
              <Content
                cmsTag="Account-type-savings:Links:Go-to-"
                copytext="Go to "
              />
            </span>
            <Content
              cmsTag="Account-type-savings:Links:Notice-to-withdraw"
              copytext="Notice to withdraw"
            />
            <span className="sr-only">
              <Content cmsTag="GLOBAL:-for-" copytext=" for " />
              {props.accountName}
            </span>
          </Link>
        ) : (
          ''
        )}
      </div>
    );
  }
}

Links.propTypes = {
  mode: PropTypes.any,
  pageMode: PropTypes.any,
  status: PropTypes.number,
  accountKey: PropTypes.any,
  accountName: PropTypes.any,
  loopIndex: PropTypes.any,
  accountType: PropTypes.string,
  accountStatus: PropTypes.string,
  account: PropTypes.any,
};

export default Links;
