/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Markdown, CONSTANTS } from '@myie/interact-dom';
import { SavingsSummary } from '@myie/interact-account-type-savings-dom';
import { MortgageSummary } from '@myie/interact-account-type-mortgage-dom';


class LocalAccountSummary extends React.Component {
render() {
// eslint-disable-next-line no-unused-vars
const { account, loopIndex, mode } = this.props
switch (account.ExtendedProperties.AccountType) {
		case CONSTANTS.ACCOUNT_TYPE_SAVINGS:
            return (<SavingsSummary loopIndex={loopIndex} account={account} mode={mode}/>); 
        case CONSTANTS.ACCOUNT_TYPE_MORTGAGE:    
              return (<MortgageSummary loopIndex={loopIndex} account={account} mode={mode}/>); 

default: return (
<Alert color="warning" className="account-card account-summary">
    <Markdown cmsTag="Account-summary:PRODUCTTYPE-not-supported-alert-1" template={ { markdown: { productType: account.ExtendedProperties.AccountType, }, } } markdown={`Account type **$[productType]** not supported`} />
</Alert>
)
}
}
}

LocalAccountSummary.propTypes = {
account: PropTypes.any,
loopIndex: PropTypes.any,
mode: PropTypes.any
}

export default LocalAccountSummary