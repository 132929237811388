import React, { Component } from 'react';
import { BreadcrumbItem } from 'reactstrap';
import { storage } from '../storageName';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import moment from 'moment';
import {
  CONSTANTS,
  sharedUtility,
  stateUtility,
  FormErrorList,
} from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { accountFilter } from '@myie/interact-savings-servicing-dom';
import Create from './Create';
import Confirmation from './Confirmation';
import queryString from 'query-string';
import CreationSuccess from './CreationSuccess';
import dateValidation from './../../utility';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../../shared/HO_components/checkDownTime';
import { Validate } from '@myie/interact';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';

class CreateTransfer extends Component {
  constructor(props) {
    super(props);
    sharedUtility.setUrl('/transfer-to-another/create/moveMoney');
    this.state = this.initialState(this.state);
    const { allProducts, getAllProducts } = this.props;
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  initialState = state => {
    const { accounts } = this.props;
    const {
      urlParams,
      refreshUrl,
      stateData,
      externalTransferMoney,
    } = this.props;
    const stateList = ['createTransfer', 'ReviewTransfer', 'CompleteNotice'];
    if (
      stateData &&
      stateData.request &&
      urlParams &&
      urlParams.twoFa &&
      urlParams.twoFa === 'active'
    ) {
      const request = stateData.request;
      externalTransferMoney(request);
    }

    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'createTransfer';
    if (!hasStage) {
      stage = 'createTransfer';
      refreshUrl();
    }
    let account;
    if (accounts) {
      if (stateData && stateData.From) {
        account = accounts.Accounts.find(function(element) {
          return Utility.hexEncode(element.AccountKey.Key) === stateData.From;
        });
      }
    } else {
      account = null;
    }
    stateUtility.setGlobalValue({ storage: storage.name });
    return {
      account: account,
      stage: stage,
      combinedForm: {},
      withdrawDate: moment(),
      accountForm: {
        accountFormValidation: {
          rules: {
            stop: true,
            required: {
              message: 'Please select an account to transfer from.',
            },
          },
          value: state && state.account ? state.account.AccountKey.Key : '',
        },
      },
      data:
        this.props.stateData && this.props.stateData.formData
          ? this.props.stateData.formData
          : {},
      form: {},
    };
  };

  componentWillUnmount() {
    const { resetExternalTransfer } = this.props;
    resetExternalTransfer();
  }

  componentDidMount() {
    const { resetExternalTransfer, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    resetExternalTransfer();
  }

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const { externalTransfer = {}, stateData, updateUrl } = nextProps;
    let newForm = state.accountForm;
    if (
      externalTransfer &&
      externalTransfer.ResponseStatus === 1 &&
      state.stage !== 'CompleteNotice'
    ) {
      let stateData = {
        formData: state.data,
        url: {
          pageStage: 'CompleteNotice',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'CompleteNotice',
      };
    }
    if (nextProps.accounts && !state.account) {
      let account;
      if (nextProps.accounts) {
        if (stateData && stateData.From) {
          account = nextProps.accounts.Accounts.find(function(element) {
            return Utility.hexEncode(element.AccountKey.Key) === stateData.From;
          });
          newForm = Validate.input(
            'accountFormValidation',
            account.AccountKey.Key,
            newForm,
          );
        }
      } else {
        account = null;
      }
      return {
        account: account,
        accountForm: newForm,
      };
    }

    return null;
  };

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      const account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      this.setState({ ...this.state, account });
    }
  }

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };
  callAccountValidations = () => {
    const { account, accountForm, combinedForm } = this.state;
    window.scrollTo(0, 0);
    if (!account) {
      let newAccountForm = Validate.form(accountForm);
      this.setState({
        ...this.state,
        accountForm: newAccountForm,
        combinedForm: {
          ...combinedForm,
          ...newAccountForm,
        },
      });
      return;
    }
  };

  //update the stage in state
  submitForm = (stage, form) => {
    if (stage === 'createTransfer') {
      const { resetMakeWithdrawal } = this.props;
      resetMakeWithdrawal();
    }
    let stateData = {
      formData: this.state.data,
      url: {
        pageStage: stage,
      },
    };
    this.props.updateUrl(stateData);
    this.setState({ ...this.state, stage, form, message: null });
  };

  getActionDate = account => {
    const { holidays } = this.props;
    let numberOfDays = '';
    if (account.ExtendedProperties.AccountType === CONSTANTS.REGULAR_SAVINGS) {
      let segments = account.AccountName.split(/\s+/);
      numberOfDays = parseInt(segments[0]);
    }

    const NumberOfDays = numberOfDays === 0 ? 0 : numberOfDays;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  onSubmitWithdrawal = () => {
    const { account, data, stage } = this.state;
    const { externalTransferMoney } = this.props;

    let request = {
      AccountKey: account.AccountKey,
      FromSociety: account.ExtendedProperties.Society,
      FromSubAccountId: account.ExtendedProperties.SubAccountNumber,
      TargetAccountNumber: data.payeeAccount,
      TargetPayeeName: data.payeeName,
      TargetSubAccountId: '1',
      PaymentReference: data.reference,
      Amount: data.amount,
      ShouldCloseAccount: false,
    };

    let stateData = {
      formData: this.state.data,
      request: request,
      url: {
        pageStage: stage,
      },
    };
    this.props.updateUrl(stateData);
    externalTransferMoney(request);
  };

  // common onChangeAccount function
  onChangeAccount = e => {
    const { accounts, updateState } = this.props;
    let { accountForm, combinedForm } = this.state;
    let newForm = accountForm;

    const { value } = e.target;
    if (accounts && accounts.Accounts && value !== '') {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      newForm = Validate.input('accountFormValidation', value, newForm);
      let object = {};
      object.From = Utility.hexEncode(account.AccountKey.Key);
      updateState(object);
      this.setState({
        account,
        accountForm: newForm,
        combinedForm: { ...combinedForm, ...newForm },
      });
    }
  };
  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // select current stage
  currentStage = (account, options) => {
    const { accounts = {} } = this.props;
    switch (this.state.stage) {
      case 'createTransfer':
        return (
          <Create
            setForm={this.setForm}
            accountForm={this.state.accountForm}
            setStateDynamic={this.setStateDynamic}
            callAccountValidations={this.callAccountValidations}
            submitForm={this.submitForm}
            onChangeAccount={this.onChangeAccount}
            saveData={this.saveData}
            data={this.state.data}
            accounts={accounts}
            account={account}
            onCancelled={this.onCancelleWithdrawal}
            options={options}
          />
        );
      case 'ReviewTransfer':
        return (
          <Confirmation
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            form={this.state.form}
            updateUrl={this.props.updateUrl}
            data={this.state.data}
            setStage={this.setStage}
            account={account}
          />
        );

      default:
    }
  };

  onCancelleWithdrawal = () => {
    const { history } = this.props;
    const { account } = this.state;
    const key =
      (account.AccountKey &&
        account.AccountKey.Key &&
        Utility.hexEncode(account.AccountKey.Key)) ||
      '';
    history.push(`/notice-to-withdraw/view/summary/${key}`);
  };

  render() {
    const { stage, account, combinedForm } = this.state;
    const {
      accounts,
      location,
      systemDownStatus, //From checkDownTime HOC
      allProducts,
    } = this.props;
    const currentState = queryString.parse(location.search);
    if (!accounts) {
      return null;
    }
    const options = accountFilter(accounts.Accounts, allProducts, true);

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    let condition =
      this.state.stage === 'ReviewTransfer' ||
      this.state.stage === 'CompleteNotice';
    if (stage === 'CompleteNotice') {
      return (
        <CreationSuccess
          account={account}
          setStage={this.setStage}
          data={this.state.data}
          activate={this.activate}
        />
      );
    }
    if (currentState && currentState.twoFa !== 'active') {
      return (
        <div id="transfer-to-another-account">
          {systemDownStatus.msg}
          <Content
            tag="h1"
            cmsTag="Savings-servicing:Transfer-to-another-account:Create-transfer:Create-transfer:h1"
            template={{
              copytext: {
                companyNameShorthand: CONTACTS.companyNameShorthand,
              },
            }}
            copytext="Transfer to another $[companyNameShorthand] member's savings account"
          />
          {options.length === 0 ? (
            <>
              <Content
                tag="p"
                cmsTag="Move-money:Move-money-index:p1"
                copytext="Your savings accounts are not eligible for transactions."
              />
              <Link
                id="back-to-move-money"
                className="btn btn-secondary"
                role="button"
                to={`/move-money/index`}
              >
                <Content
                  cmsTag="GLOBAL:Back-to-move-money"
                  copytext="Back to move money"
                />
              </Link>
            </>
          ) : (
            <>
              <div className="breadcrumb-container">
                <ol className="breadcrumb">
                  <BreadcrumbItem
                    active={stage === 'createTransfer'}
                    className={condition ? 'completed' : ''}
                  >
                    {stage === 'createTransfer' ? (
                      <Content
                        tag="span"
                        tagClassName="sr-only"
                        cmsTag="BREADCRUMB:Active"
                        copytext="Active: "
                      />
                    ) : (
                      ''
                    )}
                    {condition ? (
                      <Content
                        tag="span"
                        tagClassName="sr-only"
                        cmsTag="BREADCRUMB:Completed"
                        copytext="Completed: "
                      />
                    ) : (
                      ''
                    )}
                    <span className="d-inline d-lg-none d-xl-none">
                      <span className="sr-only">
                        <Content
                          cmsTag="BREADCRUMB:Step-1-"
                          copytext="Step 1 "
                        />
                      </span>
                      <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                      2:&nbsp;
                    </span>
                    <Content
                      cmsTag="BREADCRUMB:Transfer-details"
                      copytext="Transfer details"
                    />
                  </BreadcrumbItem>
                  <BreadcrumbItem active={stage === 'ReviewTransfer'}>
                    {stage === 'ReviewTransfer' ? (
                      <Content
                        tag="span"
                        tagClassName="sr-only"
                        cmsTag="BREADCRUMB:Active"
                        copytext="Active: "
                      />
                    ) : (
                      ''
                    )}
                    <span className="d-inline d-lg-none d-xl-none">
                      <span className="sr-only">
                        <Content
                          cmsTag="BREADCRUMB:Step-2-"
                          copytext="Step 2 "
                        />
                      </span>
                      <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                      2:&nbsp;
                    </span>
                    <Content
                      cmsTag="BREADCRUMB:Review-and-submit"
                      copytext="Review and submit"
                    />
                  </BreadcrumbItem>
                </ol>
              </div>
              {this.state.stage === 'createTransfer' ? (
                <FormErrorList
                  validations={combinedForm}
                  disabled={false}
                  required={true}
                  groupClassName=""
                  title="h2"
                  showErrors={true}
                />
              ) : (
                ''
              )}
              {this.currentStage(account, options)}
            </>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

CreateTransfer.propTypes = {
  accounts: PropTypes.any,
  updateState: PropTypes.func,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  holidays: PropTypes.any,
  account: PropTypes.any,
  urlParams: PropTypes.any,
  stateData: PropTypes.any,
  refreshUrl: PropTypes.any,
  updateUrl: PropTypes.any,
  location: PropTypes.any,
  resetExternalTransfer: PropTypes.any,
  externalTransferMoney: PropTypes.any,
  systemDownStatus: PropTypes.object,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
    )(CreateTransfer),
  ),
  storage.name,
);
