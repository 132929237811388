import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import {
  Content,
  FormattedDate,
  FormattedCurrency,
  CONSTANTS,
} from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
class NoticeToWithdraw extends Component {
  onCancelHandler = () => {
    const { onCancel, loopIndex } = this.props;
    onCancel(loopIndex);
  };

  validateWithdrawalDate = withdrawalDate => {
    let today = moment().format('YYYY-MM-DD');
    return moment(today).isBefore(moment(withdrawalDate));
  };

  render() {
    const {
      page,
      withdrawal,
      Index,
      accountKey,
      account: {
        CurrencyCode,
        ExtendedProperties: { NominatedAccountNumber } = {},
      } = {},
      loopIndex,
      paymentMethod,
    } = this.props;

    return (
      <Card className="notice-to-withdraw-summary-card" color="dark">
        <CardBody>
          <Row>
            <Col sm={12} md={8} lg={8}>
              <Row
                tag="dl"
                title={`Account ${NominatedAccountNumber} details summary`}
              >
                <Col tag="dt" xs={12} sm={6} md={4}>
                  <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8}>
                  {withdrawal.Amount ? (
                    <FormattedCurrency
                      quantity={withdrawal.Amount}
                      currency={withdrawal.CurrencyCode || CurrencyCode}
                    />
                  ) : (
                    <Content
                      cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraws:Notice-to-withdraw:Full-balance-and-any-accrued-interest"
                      copytext="Full Balance and any accrued interest"
                    />
                  )}
                </Col>
                <Col tag="dt" xs={12} sm={6} md={4}>
                  <Content
                    cmsTag="GLOBAL:Withdraw-date"
                    copytext="Withdrawal date"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8}>
                  <FormattedDate
                    date={withdrawal.WithdrawalDate}
                    currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
                    format={CONSTANTS.STANDARD_DATE_FORMAT}
                  />
                </Col>
                <Col tag="dt" xs={12} sm={6} md={4}>
                  <Content
                    cmsTag="GLOBAL:Notice-expiry-date"
                    copytext="Notice expiry date"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8}>
                  {withdrawal.NoticeExpiryDate && (
                    <FormattedDate
                      date={withdrawal.NoticeExpiryDate}
                      currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
                      format={CONSTANTS.STANDARD_DATE_FORMAT}
                    />
                  )}
                </Col>
              </Row>
              {!this.validateWithdrawalDate(withdrawal.WithdrawalDate) && (
                <Content
                  tag="div"
                  tagClassName="alert alert-danger"
                  cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraw:Notice-to-withdraw:Delete-warning"
                  copytext="You cannot delete your notice on the day of the transfer."
                />
              )}
            </Col>
            <Col sm={12} md={4} lg={4}>
              {!this.validateWithdrawalDate(withdrawal.WithdrawalDate) ? (
                ''
              ) : (
                <Link
                  id={`cancel-notice-${Index}`}
                  className={`btn btn-danger float-right btn-sm`}
                  role="button"
                  to={`/notice-to-withdraw/cancel/${page}/${accountKey}/${Index}/${paymentMethod}`}
                >
                  <Content cmsTag="GLOBAL:Delete" copytext="Delete" />
                  <span className="sr-only"> {loopIndex}</span>
                </Link>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

NoticeToWithdraw.propTypes = {
  withdrawal: PropTypes.any,
  account: PropTypes.any,
  onCancel: PropTypes.func,
  loopIndex: PropTypes.any,
  Index: PropTypes.any,
  accountKey: PropTypes.any,
  page: PropTypes.any,
  paymentMethod: PropTypes.string,
};

export default NoticeToWithdraw;
