import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Alert } from 'reactstrap';
import { mapDispatchToProps } from '@myie/interact-authentication';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { mapDispatchToProps as brandAuthenticationDispatch } from '@myie/interact-brand-authentication';
import {
  Text,
  Content,
  Switch,
  sharedUtility,
  RULES,
  FormErrorList,
} from '@myie/interact-dom';
import { Validate } from '@myie/interact';

class SecurityDetails extends React.Component {
  constructor(props) {
    super(props);
    const { setupCredentials, stateData, urlParams } = props;

    if (
      stateData &&
      stateData.RequireActivationTicket &&
      urlParams &&
      urlParams.twoFa &&
      urlParams.twoFa === 'active'
    ) {
      const RequireActivationTicket = {
        RequireActivationTicket: stateData.RequireActivationTicket,
        ExtendedProperties: null,
      };
      setupCredentials(RequireActivationTicket);
    }
    this.state = {
      localPasscodeValidation: [],
      updateForgottenLoading: false,
      form: {
        password: {
          rules: {
            title: 'password',
            stop: true,
            required: {
              message: 'Please enter a password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
          },
        },
        confirmPassword: {
          rules: {
            title: 'confirm password',
            stop: true,
            required: {
              message: 'Please confirm your password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
            compare: {
              message: 'Please ensure the password fields match.',

              field: 'password',
              comparison: () => {
                return this.state.form.password.value;
              },
            },
          },
        },
        memorableNumber: {
          rules: {
            title: 'Passcode',
            stop: true,
            required: {
              message: 'Please enter a passcode.',
            },
            format: {
              regex: RULES.memorableNumber,
              message: 'Please enter a valid passcode.',
            },
          },
        },
        confirmMemorableNumber: {
          rules: {
            title: 'confirm passcode',
            stop: true,
            required: {
              message: 'Please confirm your passcode.',
            },
            format: {
              regex: RULES.memorableNumber,
              message: 'Please enter a valid passcode.',
            },
            compare: {
              message: 'Please ensure the passcode fields match.',
              field: 'memorableNumber',
              comparison: () => {
                return this.state.form.memorableNumber.value;
              },
            },
          },
        },
      },
    };
  }

  static getDerivedStateFromProps = (prevProps, nextState) => {
    const {
      setupCredentialsActivate,
      updateForgotten,
      resetSetupCredentials,
      stateData,
      updateForgottenDetails,
    } = prevProps;

    // If personal details step - Success
    if (
      setupCredentialsActivate &&
      setupCredentialsActivate.Status === 'Success'
    ) {
      resetSetupCredentials();
      let birthDay = prevProps.dob;
      const { form } = nextState;
      const request = {
        Ticket: setupCredentialsActivate.ResetUserCredentialsTicket,
        CredentialValues: [
          {
            Name: 'Password',
            Value: form.password.value
              ? form.password.value
              : stateData.securityForm.password.value,
            Context: '',
          },
          {
            Name: 'Pin',
            Value: form.memorableNumber.value
              ? form.memorableNumber.value
              : stateData.securityForm.memorableNumber.value,
            Context: '',
          },
        ],
        TermsAndConditionsDocumentVersion: '1.0',
        ExtendedProperties: {
          IdentityRequest: {
            DateOfBirth: birthDay ? birthDay : stateData.formData.b_day,
          },
        },
      };
      updateForgotten(request);
      return {
        updateForgottenLoading: true,
      };
    }

    if (updateForgottenDetails?.Status === 'InvalidCredentials') {
      return {
        updateForgottenLoading: false,
      };
    }

    return null;
  };

  componentDidMount = () => {
    this.props.timeout.startTimer();
  };

  componentWillUnmount = () => {
    this.props.timeout.clearTimer();
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  changeStage = () => {
    const { updateUrl } = this.props;
    let stateData = {
      url: {
        pageStage: 'accountDetails',
      },
    };
    updateUrl(stateData);
  };

  submit = e => {
    e.preventDefault();
    let { form } = this.state;
    const { stateData, updateState } = this.props;
    let localValues = [];
    const { setupCredentials, RequireActivationTicket } = this.props;
    form = Validate.form(form);
    const checkAllSame = sharedUtility.checkAllSame(form.memorableNumber.value)
      ? 'AllSame'
      : '';
    const isSequential = sharedUtility.isSequential(form.memorableNumber.value)
      ? 'Sequential'
      : '';
    const dob = sharedUtility.checkDob(
      stateData.formData.b_day,
      form.memorableNumber.value,
    )
      ? 'Dob'
      : '';
    if (checkAllSame) {
      localValues.push(checkAllSame);
    }
    if (isSequential) {
      localValues.push(isSequential);
    }
    if (dob) {
      localValues.push(dob);
    }
    this.setState({
      ...this.state,
      form,
      localPasscodeValidation: localValues,
    });
    if (!form.approved || localValues.length) {
      return;
    }
    let storageData = {
      securityForm: form,
    };
    updateState(storageData);
    setupCredentials(RequireActivationTicket);
  };

  getLocalError() {
    const { localPasscodeValidation } = this.state;
    let errors = [];
    if (localPasscodeValidation) {
      localPasscodeValidation.forEach(element => {
        switch (element) {
          case 'Dob':
            return errors.push(
              <Alert id={element} key={element} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Security-details:DOB-error"
                  copytext="Your passcode cannot be your date of birth."
                />
              </Alert>,
            );
          case 'AllSame':
            return errors.push(
              <Alert id={element} key={element} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Security-details:All-same-error"
                  copytext="Your passcode cannot be the same number repeated six times."
                />
              </Alert>,
            );
          case 'Sequential':
            return errors.push(
              <Alert id={element} key={element} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Security-details:Sequential-error"
                  copytext="Your passcode cannot include sequential numbers."
                />
              </Alert>,
            );
          case 'Known':
            return errors.push(
              <Alert id={element} key={element} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Security-details:Known-error"
                  copytext="You have entered a common password, please try again."
                />
              </Alert>,
            );
          case 'Username':
            return errors.push(
              <Alert id={element} key={element} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Security-details:Username-error"
                  copytext="Your username and password cannot be the same."
                />
              </Alert>,
            );
          default:
        }
      });
    }
    return errors;
  }

  getErrors() {
    const { updateForgottenDetails = {} } = this.props;
    let errors = [];
    if (
      updateForgottenDetails.ExtendedProperties &&
      updateForgottenDetails.ExtendedProperties.IdentityResponse &&
      updateForgottenDetails.ExtendedProperties.IdentityResponse
        .CredentialErrors
    ) {
      updateForgottenDetails.ExtendedProperties.IdentityResponse.CredentialErrors.forEach(
        element => {
          switch (element) {
            case 'Dob':
              return errors.push(
                <Alert id={element} key={element} color="danger">
                  <Content
                    cmsTag="Authentication:Forgotten-password:Security-details:DOB-error"
                    copytext="Your passcode cannot be your date of birth."
                  />
                </Alert>,
              );
            case 'AllSame':
              return errors.push(
                <Alert id={element} key={element} color="danger">
                  <Content
                    cmsTag="Authentication:Forgotten-password:Security-details:All-same-error"
                    copytext="Your passcode cannot be the same number repeated six times."
                  />
                </Alert>,
              );
            case 'Sequential':
              return errors.push(
                <Alert id={element} key={element} color="danger">
                  <Content
                    cmsTag="Authentication:Forgotten-password:Security-details:Sequential-error"
                    copytext="Your passcode cannot include sequential numbers."
                  />
                </Alert>,
              );
            case 'Known':
              return errors.push(
                <Alert id={element} key={element} color="danger">
                  <Content
                    cmsTag="Authentication:Forgotten-password:Security-details:Known-error"
                    copytext="You have entered a common password, please try again."
                  />
                </Alert>,
              );
            case 'Username':
              return errors.push(
                <Alert id={element} key={element} color="danger">
                  <Content
                    cmsTag="Authentication:Forgotten-password:Security-details:Username-error"
                    copytext="Your username and password cannot be the same."
                  />
                </Alert>,
              );
            default:
          }
        },
      );
    }

    return errors;
  }

  buildErrorMsg = updateForgottenDetails => {
    let errorsExist = false;
    let errorMsg = [];

    if (updateForgottenDetails.Status === 'InvalidCredential') {
      errorMsg = this.getErrors();
      errorsExist = true;
    } else {
      if (
        updateForgottenDetails &&
        updateForgottenDetails.Status &&
        updateForgottenDetails.Status !== ''
      ) {
        errorsExist = true;
      }
      errorMsg = (
        <Switch
          cmsTag="Authentication:Forgotten-password:Security-details:serverErrorMessages"
          value={updateForgottenDetails.Status || ''}
          tag="div"
          className="alert alert-danger"
          scrolltotop={true}
          template={{
            UsernameAlreadyExists: {
              name: () => {
                return this.state.form.username.value;
              },
              random: () => {
                return Math.floor(Math.random() * (9999 - 1000)) + 1000;
              },
            },
          }}
          contents={{
            UsernameAlreadyExists: {
              defaultValue:
                'The username you specified is already in use. try something like $[name]$[random]',
            },

            NotEnoughCredentials: {
              defaultValue: 'Not enough credentials were submitted',
            },
            InvalidUsername: {
              defaultValue:
                'Your username must be between 6 and 23 characters.',
            },
            InvalidCredentials: {
              defaultValue:
                'You have entered a common password, please try again.',
            },
          }}
        />
      );
    }

    return { errorMsg: errorMsg, errorsExist: errorsExist };
  };

  render() {
    const { updateForgottenDetails = {} } = this.props;
    const { form, updateForgottenLoading } = this.state;
    let errorMsgObj = this.buildErrorMsg(updateForgottenDetails);
    let localErrors = this.getLocalError();
    if (updateForgottenLoading) {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    }
    return (
      <React.Fragment>
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          id="forgotten-pw-security-details"
          onSubmit={this.submit}
        >
          {errorMsgObj.errorMsg}
          {localErrors}
          <Text
            description="Your password must be between 8 and 50 characters, and contain at least one upper case letter, one lower case letter, a number and special character."
            label="Set your password"
            descriptionExample="*!?%/+-"
            cmsTag="GLOBAL:Set-your-password"
            id="password"
            field="password"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.password}
            type="password"
          />
          <Text
            label="Confirm password"
            cmsTag="GLOBAL:Confirm-your-password"
            id="confirmPassword"
            field="confirmPassword"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.confirmPassword}
            type="password"
          />
          <Text
            description="Your passcode must be at least 6 numbers long and cannot include your date of birth. Your passcode cannot include sequential numbers and cannot be the same number repeated 6 times. You will be asked for 3 numbers from this code every time you sign in."
            label="Set your passcode"
            cmsTag="GLOBAL:Set-your-passcode"
            id="memorableNumber"
            field="memorableNumber"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.memorableNumber}
            type="password"
            maxLength={6}
          />
          <Text
            label="Confirm passcode"
            cmsTag="GLOBAL:Confirm-your-passcode"
            id="confirmMemorableNumber"
            field="confirmMemorableNumber"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.confirmMemorableNumber}
            type="password"
            maxLength={6}
          />
          <div className="form-button-group">
            <Button id="security-details-submit" type="submit" color="primary">
              <Content
                cmsTag="GLOBAL:Update-details-and-sign-in"
                copytext="Update details and sign in"
              />
            </Button>
            <Button
              id="security-details-back-button"
              type="submit"
              onClick={this.changeStage}
              color="secondary"
            >
              <Content
                cmsTag="GLOBAL:Back-to-account-details"
                copytext="Back to account details"
              />
            </Button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

SecurityDetails.propTypes = {
  updateForgottenDetails: PropTypes.object,
  updateForgotten: PropTypes.any,
  urlParams: PropTypes.any,
  timeout: PropTypes.any,
  updateState: PropTypes.func,
  updateUrl: PropTypes.func,
  setupCredentialsActivate: PropTypes.any,
  formData: PropTypes.any,
  setupCredentials: PropTypes.any,
  stateData: PropTypes.any,
  isFetching: PropTypes.bool,
  forgottenPasswordDetails: PropTypes.any,
  RequireActivationTicket: PropTypes.any,
  dateOfBirth: PropTypes.any,
  dob: PropTypes.string,
};

const mapStateToProps = state => {
  const {
    authenticationDefinition,
    custombrandAuthenticationDefinition,
  } = state;
  return {
    ...authenticationDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapDispatchToProps_shared,
    ...brandAuthenticationDispatch,
  },
)(SecurityDetails);
