import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Register from './Register';
import Blocked from './Blocked';
import AccessDenied from './AccessDenied';
import Success from './Success';
import Unsuccessful from './Unsuccessful';
import AccountAlreadyExists from './AccountAlreadyExists';
import NotAllowed from './NotAllowed';
import RequestFailed from './RequestFailed';

import { NoMatch, sharedUtility } from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { Alert } from 'reactstrap';

class Registration extends React.Component {
  componentDidMount() {
    sharedUtility.setUrl('/registration');
    const { setReferrer, location } = this.props;
    const { from } = location.state || { from: { pathname: '/accounts' } };
    setReferrer(from);
  }

  render() {
    const { error } = this.props;
    let re = new RegExp('^/registration[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/registration/register" />;
    }

    return (
      <div id="registration">
        {error ? <Alert color="danger">{error}</Alert> : ''}
        <Switch>
          <Route
            exact
            path="/registration/register/:id?"
            component={Register}
          />
          <Route exact path="/registration/blocked" component={Blocked} />
          <Route
            exact
            path="/registration/access-denied"
            component={AccessDenied}
          />
          <Route exact path="/registration/success" component={Success} />
          <Route exact path="/registration/failed" component={RequestFailed} />
          <Route
            exact
            path="/registration/unsuccessful"
            component={Unsuccessful}
          />
          <Route
            exact
            path="/registration/alreadyhascredentials"
            component={AccountAlreadyExists}
          />
          <Route exact path="/registration/notallowed" component={NotAllowed} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

Registration.propTypes = {
  error: PropTypes.any,
  location: PropTypes.any,
  setReferrer: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Registration);
