import {
  FETCH_SAVINGS_TRANSACTIONS_SUCCESS,
  FETCH_SAVINGS_TRANSACTIONS_FAILURE,
  FETCH_SAVINGS_TRANSACTIONS_REQUEST,
  RESET_SAVINGS_ACCOUNT,
} from './types';
import {
  FETCH_SAVINGS_ACCOUNT_SUCCESS,
  FETCH_SAVINGS_ACCOUNT_FAILURE,
  FETCH_SAVINGS_ACCOUNT_REQUEST,
} from './types';
import update from 'immutability-helper';
import _ from 'lodash';
const accountsDefinition = (state = {}, action) => {
  let oldAccounts;
  let index;
  let account;
  switch (action.type) {
    case RESET_SAVINGS_ACCOUNT: {
      oldAccounts = state.accounts;
      index = oldAccounts.Accounts.findIndex(
        x => x.AccountKey.Key === action.key,
      );
      account = oldAccounts.Accounts[index];
      let updatedAccounts;
      if (account && account.transactions) {
        const newTransactions = [];
        const transactions = account.transactions;
        const withNewTransactions = update(transactions, {
          Transactions: { $set: newTransactions },
        });
        const accountWithTransactions = update(account, {
          transactions: { $set: withNewTransactions },
        });
        updatedAccounts = update(oldAccounts, {
          Accounts: { [index]: { $set: accountWithTransactions } },
        });
      } else {
        updatedAccounts = oldAccounts;
      }

      return {
        ...state,
        accounts: updatedAccounts,
        isFetching: false,
        error: null,
      };
    }

    case FETCH_SAVINGS_TRANSACTIONS_SUCCESS: {
      oldAccounts = state.accounts;
      index = oldAccounts.Accounts.findIndex(
        x => x.AccountKey.Key === action.accountkey.Key,
      );
      account = oldAccounts.Accounts[index];
      const transactions = action.transactions;
      let accountTransactions = [];
      if (account.transactions) {
        accountTransactions = account.transactions.Transactions;
      }

      const newTransactions = _.uniqBy(
        [...accountTransactions, ...transactions.Transactions],
        'TransactionIdentifier',
      );

      const withNewTransactions = update(transactions, {
        Transactions: { $set: newTransactions },
      });
      const withTransactionsRange = update(withNewTransactions, {
        Range: { $set: action.range },
      });
      const accountWithTransactions = update(account, {
        transactions: { $set: withTransactionsRange },
      });
      const updatedAccounts = update(oldAccounts, {
        Accounts: { [index]: { $set: accountWithTransactions } },
      });
      return {
        ...state,
        accounts: updatedAccounts,
        isFetching: action.isFetching,
      };
    }

    case FETCH_SAVINGS_ACCOUNT_SUCCESS: {
      oldAccounts = state.accounts;
      index = oldAccounts.Accounts.findIndex(
        x => x.AccountKey.Key === action.accountkey.Key,
      );
      account = oldAccounts.Accounts[index];
      const newAccount = action.account;
      const withOldTransactions = update(newAccount, {
        transactions: { $set: account.transactions },
      });
      const updatedByAccountsFetch = update(oldAccounts, {
        Accounts: { [index]: { $set: withOldTransactions } },
      });
      return {
        ...state,
        accounts: updatedByAccountsFetch,
        isFetching: action.isFetching,
      };
    }

    case FETCH_SAVINGS_TRANSACTIONS_FAILURE:
    case FETCH_SAVINGS_TRANSACTIONS_REQUEST:
    case FETCH_SAVINGS_ACCOUNT_FAILURE:
    case FETCH_SAVINGS_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default accountsDefinition;
