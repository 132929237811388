import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import {
  FormattedCurrency,
  Content,
  FormattedDate,
  CONSTANTS,
} from '@myie/interact-dom';

class NextPaymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: 0,
    };
  }

  render() {
    const { props } = this;
    const { account, loopIndex, nextPayments } = props;
    let nextPaymentAmount = account.ExtendedProperties.NextPaymentAmount === 0;
    const hideInterest =
      nextPaymentAmount &&
      (account.AccountStatus === 'Closed' ||
        account.ExtendedProperties?.AccountMode === CONSTANTS.ACCOUNT_CLOSED)
        ? true
        : false;

    let payments = Object.keys(nextPayments).map(paymentDate => {
      return (
        <Row key={paymentDate} tag="dl" title="Next payment">
          <Col tag="dt" xs={12}>
            <Content cmsTag="GLOBAL:Next-payment" copytext="Next payment" />
          </Col>
          <Col tag="dd" xs={12}>
            <span className="mr-2">
              <FormattedDate
                date={paymentDate}
                currentFormat={CONSTANTS.STANDARD_DATE_FORMAT}
                format={CONSTANTS.STANDARD_DATE_FORMAT}
              />
            </span>
            <span>
              <FormattedCurrency
                quantity={
                  nextPayments[paymentDate] ? nextPayments[paymentDate] : 0
                }
                currency={account.CurrencyCode}
              />
            </span>
          </Col>
        </Row>
      );
    });
    if (!hideInterest && payments.length) {
      return (
        <Card
          id={`account-type-mortgage-${loopIndex}`}
          className="payment-card"
        >
          <CardBody>{payments}</CardBody>
        </Card>
      );
    } else {
      return null;
    }
  }
}

NextPaymentDetails.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  loadingTransactions: PropTypes.any,
  transactions: PropTypes.any,
  nextPayments: PropTypes.object,
};

export default NextPaymentDetails;
