import React from 'react';
import { Card, CardColumns, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '@myie/interact-accounts';
import PropTypes from 'prop-types';
import { AppMeta, Content, SideLink, CONSTANTS } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class MoneyToolsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEligible: false,
      eligibleCheck: false,
    };
  }

  componentDidUpdate() {
    const { accounts } = this.props;
    const { eligibleCheck } = this.state;
    if (accounts && !eligibleCheck) {
      this.checkGoalEligibility(accounts.Accounts);
    }
  }

  checkGoalEligibility = (accounts = []) => {
    let acct;
    for (let index = 0; index < accounts.length; index++) {
      acct = accounts[index];
      if (
        acct.AccountStatus &&
        (acct.AccountStatus.toLowerCase() !== 'closed' &&
          acct.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED)
      ) {
        if (
          acct.ExtendedProperties.SavingsGoal === 'Y' ||
          acct.ExtendedProperties.HasSavingsGoals
        ) {
          //this.isEligible = true;
          this.setState({ isEligible: true, eligibleCheck: true });
        }
        // else if (acct.ExtendedProperties.HasSavingsGoals) {
        //   this.isEligible = true;
        //   break;
        // } else {
        //   this.isEligible = false;
        // }
      }
    }
  };

  render() {
    const { isEligible } = this.state;

    return (
      <div id="money-tools" className="secondary-nav">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Money Tools"
          metaDescription="Money Tools landing page"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Money-tools:Money-tools-index:h1"
          copytext="Money tools"
        />
        <CardColumns className="grid-mode">
          {isEligible ? ( //availability
            <Card className="card-item">
              <CardBody>
                <SideLink
                  linkTitle="Savings goals"
                  linkURL="/money-tools/savings-goals/view"
                  linkID="savings-goals-link"
                  iconImage="SavingsGoals"
                  showMessageCount={false}
                  linkVersion="primary"
                />
              </CardBody>
            </Card>
          ) : (
            ''
          )}

          {/* <Card className="card-item">
              <CardBody>
                <SideLink
                  linkTitle="Budget calculator"
                  linkURL="/money-tools/budget-calculator"
                  linkID="budget-calculator-link"
                  iconImage="BudgetCalculator"
                  showMessageCount={false}
                  linkVersion="primary"
                />
              </CardBody>
          </Card> */}
        </CardColumns>
      </div>
    );
  }
}

MoneyToolsIndex.propTypes = {
  accounts: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoneyToolsIndex);
