/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import React from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS } from '@myie/interact-dom';
import { Messages } from '@myie/interact-account-type-savings-dom';


class AccountSummary extends React.Component {
render() {
// eslint-disable-next-line no-unused-vars
const { account, loopIndex, mode } = this.props
switch (account.ExtendedProperties.AccountType) {
		case CONSTANTS.ACCOUNT_TYPE_SAVINGS: return (<Messages loopIndex={loopIndex} account={account} mode={mode}/>); 

default: return null
}
}
}

AccountSummary.propTypes = {
account: PropTypes.any,
loopIndex: PropTypes.any,
mode: PropTypes.any
}

export default AccountSummary