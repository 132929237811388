import {
  MAKE_A_DEPOSIT_FAILURE,
  MAKE_A_DEPOSIT_REQUEST,
  MAKE_A_DEPOSIT_SUCCESS,
  RESET_MAKE_A_DEPOSIT,
} from '../actions/makeADepositTypes';

const makeAdepositDefinition = (state = {}, action) => {
  switch (action.type) {
    case MAKE_A_DEPOSIT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case MAKE_A_DEPOSIT_SUCCESS:
      return {
        ...state,
        makeADepositWorldpay: action.makeADepositResponse,
        isFetching: action.isFetching,
      };
    case MAKE_A_DEPOSIT_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_MAKE_A_DEPOSIT:
      return {
        ...state,
        makeADepositWorldpay: null,
        isFetching: false,
        error: null,
      };

    default:
      return state;
  }
};

export default makeAdepositDefinition;
