import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Switch, Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { appUtility } from '@myie/interact-dom';
import { Session } from '@myie/interact';

class SignedOut extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    window.sessionStorage.removeItem('timeoutMessage');
    clearInterval(window.sessionStorage.getItem('timeoutID'));
    appUtility.setTimeoutMessage('');
    Session.abandon();
    userLogout();
  }

  componentDidMount() {
    const { userLogout } = this.props;
    clearInterval(window.sessionStorage.getItem('timeoutID'));
    Session.abandon();
    userLogout();
  }

  constructor(props) {
    super(props);
    let signoutMessage = window.sessionStorage.getItem('timeoutMessage');
    if (!signoutMessage) {
      signoutMessage = appUtility.getTimeoutMessage();
    }
    let error;
    if (signoutMessage) {
      error = signoutMessage;
    }
    this.state = { error: error };
  }

  render() {
    const { error } = this.state;
    return (
      <div id="signed-out">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Signed out"
          metaDescription="You have been signed out"
        />
        <Content
          tag="h1"
          cmsTag="Authentication:Signed-out:Signed-out:h1"
          copytext="Signed out"
        />
        <Switch
          id="message"
          className="alert alert-danger"
          value={error || ''}
          contents={{
            TimeoutReset: {
              defaultValue:
                'You took too long to complete the sign in process. Please try again.',
            },
            UnauthorizedSignedOut: {
              defaultValue:
                'You have been logged out of your current session due to issues with your session on the server. Please sign in again to continue using the system.',
            },
            SignedOut: {
              defaultValue:
                'You have been logged out of your current session due to inactivity. Please sign in again to continue using the system.',
            },
          }}
        />

        <Content
          tag="p"
          cmsTag="Authentication:Signed-out:Signed-out:p1"
          copytext="Thank you for using our secure online system."
        />

        <div className="form-button-group">
          <Link className="btn btn-primary" role="button" to="/sign-in">
            <Content cmsTag="GLOBAL:Sign-in" copytext="Sign in" />
          </Link>
        </div>
      </div>
    );
  }
}

SignedOut.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignedOut);
