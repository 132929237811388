import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { Utility } from '@myie/interact';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import {
  Progress,
  GoalNotice,
  progressLogic,
} from '@myie/interact-savings-servicing-dom';
import { connect } from 'react-redux';
import { Content, FormattedCurrency, CONSTANTS } from '@myie/interact-dom';

class SavingsGoals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isComponentLoaded: false,
    };
  }

  setInitialRange() {}

  componentDidMount() {
    this.setState({ ...this.state, isComponentLoaded: true });
  }

  render() {
    const { props } = this;

    const { account, viewGoalDetails, loopIndex, pageMode = 'summary' } = props;

    let goal;
    if (viewGoalDetails && viewGoalDetails.Goals) {
      goal = viewGoalDetails.Goals.find(function(element) {
        return element.AccountKey.Key === account.AccountKey.Key;
      });
    }

    const { ExtendedProperties: { CurrentBalance } = {} } = account;

    let goalStatus;
    let diffMonth = 0;
    let date;
    let diffDays = 0;
    let leftToPay;
    if (goal) {
      goalStatus = progressLogic.getGoalStatus(
        CurrentBalance,
        goal.Amount,
        goal.Monthly,
        goal.TargetDate,
      );
      date = moment(goal.TargetDate);
      let today = moment();
      diffMonth = date.diff(today, 'months');
      diffDays = date.diff(today, 'days');
      const targetAmount = parseInt(goal.Amount.toString().replace(/,/g, ''));
      leftToPay = targetAmount - CurrentBalance;
    }

    // remove invalid property either SavingsGoal or SavingsGoals
    if (
      account.ExtendedProperties &&
      account.ExtendedProperties.SavingsGoal &&
      account.ExtendedProperties.SavingsGoal === 'Y' &&
      !account.ExtendedProperties.HasSavingsGoals &&
      (account.AccountStatus !== 'Closed' &&
        account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED)
    ) {
      //Transactions available - show latest 3 transactions

      return (
        <div
          key={`account-type-notice-summary-${loopIndex}`}
          className="goal-summary-section"
        >
          <Content
            tag="h3"
            tagClassName="saving-for-something-title"
            cmsTag="Account-type-savings:Savings-goals:h3-1"
            copytext="Saving for something?"
          />
          <p>
            <Content
              cmsTag="Account-type-savings:Savings-goals:p1-part-1"
              copytext="Whether you're saving towards that new car, holiday, or house, personalise your savings account and "
            />
            <Link
              className={`set-up-a-savings-goal-link`}
              to={
                '/money-tools/savings-goals/create-goals/' +
                pageMode +
                '/' +
                Utility.hexEncode(account.AccountKey.Key)
              }
            >
              <Content
                cmsTag="Account-type-savings:Savings-goals:set-up-a-savings-goal"
                copytext="set up a savings goal"
              />
            </Link>
            <Content
              cmsTag="Account-type-savings:Savings-goals:p1-part-2"
              copytext="."
            />
          </p>
        </div>
      );
    }

    if (
      account.ExtendedProperties &&
      account.ExtendedProperties.SavingsGoal &&
      account.ExtendedProperties.SavingsGoal === 'Y' &&
      account.ExtendedProperties.HasSavingsGoals &&
      goal &&
      (account.AccountStatus !== 'Closed' &&
        account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED)
    ) {
      //Transactions available - show latest 3 transactions

      return (
        <div
          key={`account-type-notice-summary-${loopIndex}`}
          className={`goal-summary-section mb-3 ${goalStatus}`}
        >
          <Row>
            <Col xs={12} sm={6} md={4} lg={4}>
              <Progress goal={goal} account={account} />
            </Col>
            <Col xs={12} sm={6} md={8} lg={8}>
              {goalStatus === 'started' ? (
                <Content
                  tag="h3"
                  cmsTag="Account-type-savings:Savings-goals:Goal-started:h3-2"
                  copytext="Goal started"
                />
              ) : (
                ''
              )}
              {goalStatus === 'complete' ? (
                <Content
                  tag="h3"
                  cmsTag="Account-type-savings:Savings-goals:Goal-completed:h3-3"
                  copytext="Goal completed"
                />
              ) : (
                ''
              )}
              {goalStatus !== 'complete' && goalStatus !== 'started' ? (
                <React.Fragment>
                  <p className="left-to-save">
                    <strong>
                      <FormattedCurrency
                        quantity={leftToPay}
                        currency={account.CurrencyCode}
                      />
                    </strong>
                    <Content
                      cmsTag="Account-type-savings:Savings-goals:-left-to-save"
                      copytext=" left to save."
                    />
                  </p>
                  {diffDays > 30 ? (
                    <React.Fragment>
                      <p className="months-left">
                        <strong>{diffMonth}</strong>
                        <Content
                          cmsTag="Account-type-savings:Savings-goals:-months-left"
                          copytext=" months left."
                        />
                      </p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p className="days-left">
                        <strong>{diffDays}</strong>
                        <Content
                          cmsTag="Account-type-savings:Savings-goals:-days-left"
                          copytext=" days left."
                        />
                      </p>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                ''
              )}
              <GoalNotice goal={goal} account={account} />
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }
}

SavingsGoals.propTypes = {
  account: PropTypes.any,
  isDetail: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.number,
  isFetching: PropTypes.bool,
  viewGoalDetails: PropTypes.any,
  pageMode: PropTypes.string,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition, accountsDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...accountsDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavingsGoals);
