import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, BreadcrumbItem, Alert } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ErrorPanel, CommonError } from '@myie/interact-dom';
import { mapDispatchToProps } from '@myie/interact-authentication';
import { CONTACTS } from '@myie/interact-local-dom';
import { mapDispatchToProps as brandAuthenticationDispatch } from '@myie/interact-brand-authentication';
import { mapDispatchToProps as mapSharedDispatchToProps } from '@myie/interact-shared';
import { Session, Validate, Utility } from '@myie/interact';
import {
  Content,
  Switch,
  Text,
  AppMeta,
  FormErrorList,
} from '@myie/interact-dom';
import { RULES } from '../../shared/constants/rules';

class UpdateLogin extends React.Component {
  constructor(props) {
    super(props);
    if (
      !props.login ||
      !props.login.SignInTicket ||
      !props.login.SignInTicket.Data
    ) {
      return;
    }
    let contextStr;
    props.login.SignInTicket.Data.CredentialStates.forEach(element => {
      if (element.Name === 'Pin') {
        contextStr = element.Context.replace(/'/g, '"');
      }
    });
    if (!contextStr) {
      this.props.history.push('/error');
    }

    const context = JSON.parse(contextStr);
    const pin = context.Positions; //[2, 3, 5];
    this.state = {
      numberStatus: '',
      activationSent: false,
      count: 0,
      form: {
        pin: [
          {
            value: '',
            state: {},
            rules: {
              stop: true,
              title: `Position ${pin[0] + 1}`,
              required: {
                message: 'Please enter details for your passcode.',
                template: {
                  position: pin[0] + 1,
                },
              },
              format: {
                regex: RULES.passCode,
                message: 'Please enter a valid passcode.',
                template: {
                  position: pin[0] + 1,
                },
              },
            },
          },
          {
            value: '',
            state: {},
            rules: {
              title: `Position ${pin[1] + 1}`,
              stop: true,
              required: {
                message: 'Please enter details for your passcode.',
                template: {
                  position: pin[1] + 1,
                },
              },
              format: {
                regex: RULES.passCode,
                message: 'Please enter a valid passcode.',
                template: {
                  position: pin[1] + 1,
                },
              },
            },
          },

          {
            value: '',
            state: {},
            rules: {
              title: `Position ${pin[2] + 1}`,
              stop: true,
              required: {
                message: 'Please enter details for your passcode.',
                template: {
                  position: pin[2] + 1,
                },
              },
              format: {
                regex: RULES.passCode,
                message: 'Please enter a valid passcode.',
                template: {
                  position: pin[2] + 1,
                },
              },
            },
          },
        ],
      },
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const {
      login,
      setupcredentialsActivate,
      isFetching,
      credentialsActivate,
    } = nextProps;
    if (credentialsActivate && credentialsActivate.Status === 'Success') {
      Session.set(
        credentialsActivate.SessionTicket,
        credentialsActivate.Customer,
      );
      return {
        numberStatus: 'ActivationSuccess',
      };
    }

    if (login) {
      if (
        login.Status === 'RequireActivation' &&
        !isFetching &&
        !prevState.activationSent
      ) {
        if (login.RequireActivationTicket) {
          setupcredentialsActivate({
            RequireActivationTicket: login.RequireActivationTicket,
          });
          return {
            activationSent: true,
          };
        }
      }

      if (login.statusCount === 2 && login.Status === 'Failed') {
        return {
          numberStatus: 'warning',
        };
      }
      if (login.Status !== 'Failed') {
        return {
          numberStatus: login.Status,
          count: 0,
        };
      }
      if (login.statusCount === 3 && prevState.numberStatus === 'warning') {
        return null;
      }
      if (login.statusCount === 1 && login.Status === 'Failed' && !isFetching) {
        return {
          numberStatus: login.Status,
        };
      }
    }
    return null;
  };

  componentDidMount = () => {
    const { updateUrl } = this.props;
    let stateData = {
      url: {
        pageStage: 'Incomplete',
      },
    };
    updateUrl(stateData);
    this.props.timeout.startTimer();
  };

  componentWillUnmount = () => {
    this.props.timeout.clearTimer();
  };

  numberSuffix = number => {
    switch (number % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { updateLogin, login } = this.props;
    let { form, count } = this.state;
    let countValue = count;
    form = Validate.form(form);
    if (form.approved) {
      countValue = count + 1;
    }
    this.setState({ ...this.state, form, count: countValue });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    const request = {
      Ticket: login.SignInTicket,
      CredentialValues: [
        {
          Name: 'Pin',
          Value: form.pin[0].value + form.pin[1].value + form.pin[2].value,
          Context: null,
        },
      ],
      ExtendedProperties: null,
    };
    updateLogin(request);
  };
  clickBack = () => {
    this.props.history.push('/sign-in/step-1');
  };

  render() {
    const { rememberme, remembermeUsername, credentialsActivate } = this.props;

    if (!this.props.login) {
      return <Redirect to="/sign-in/step-1" />;
    }
    const { numberStatus, activationSent } = this.state;
    let pin = [];
    let error;
    const login = this.props.login;
    if (login) {
      switch (numberStatus) {
        case 'Success': {
          if (rememberme) {
            localStorage.setItem(
              'rememberMe',
              Utility.hexEncode(remembermeUsername),
            );
          } else {
            localStorage.removeItem('rememberMe');
          }
          Session.set(login.SessionTicket, login.Customer);

          if (localStorage.getItem('firstLogin'))
            return <Redirect to={'/personal-details/additional-details'} />;

          if (activationSent) {
            localStorage.setItem('firstLogin', true);
            return <Redirect to={'/personal-details/additional-details'} />;
          }
          let path = this.props.referrer.pathname;
          if (this.props.referrer.pathname.endsWith('list/err')) {
            path = '/accounts/list';
          }
          return <Redirect to={path} />;
        }

        case 'ActivationSuccess': {
          if (rememberme) {
            localStorage.setItem(
              'rememberMe',
              Utility.hexEncode(remembermeUsername),
            );
          } else {
            localStorage.removeItem('rememberMe');
          }
          Session.set(
            credentialsActivate.SessionTicket,
            credentialsActivate.Customer,
          );

          if (localStorage.getItem('firstLogin'))
            return <Redirect to={'/personal-details/additional-details'} />;

          if (activationSent) {
            localStorage.setItem('firstLogin', true);
            return <Redirect to={'/personal-details/additional-details'} />;
          }
          return <Redirect to={this.props.referrer.pathname} />;
        }
        case 'Failed':
          error = 'Failed';
          break;
        case 'Blocked':
          return <Redirect to="/sign-in/access-denied" />;
        case 'CredentialBlocked':
          return <Redirect to="/sign-in/blocked" />;
        case 'warning':
          error = 'warning';
          break;
        case 'Incomplete':
          error = null;
          break;
        case 'RequireActivation':
          error = null;
          break;
        case 'InvalidTicket':
          return <Redirect to="/sign-in/step-1" />;
        default:
          error = 'UnknownStatus';
      }
    }

    let contextStr;
    login.SignInTicket.Data.CredentialStates.forEach(element => {
      if (element.Name === 'Pin') {
        contextStr = element.Context.replace(/'/g, '"');
      }
    });
    if (!contextStr) {
      return <Redirect to="/error" />;
    }
    const context = JSON.parse(contextStr);
    pin = context.Positions; // [2, 3, 5];

    let ErrorListHandler = [];
    ErrorListHandler.push(this.state.form.pin[0]);
    ErrorListHandler.push(this.state.form.pin[1]);
    ErrorListHandler.push(this.state.form.pin[2]);

    const errorOrder = CommonError(ErrorListHandler);

    const formError = {
      pin: this.state.form.pin[errorOrder.index],
    };

    return (
      <div id="sign-in-step-2">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Sign in Step 2"
          metaDescription="Sign in step 2"
        />
        <Content
          tag="h1"
          cmsTag="Authentication:Sign-in:Update:h1"
          copytext="Sign in"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem className="completed">
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="BREADCRUMB:Completed"
                copytext="Completed: "
              />
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Username-and-password"
                copytext="Username &amp; password"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="BREADCRUMB:Active"
                copytext="Active: "
              />
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content cmsTag="BREADCRUMB:Passcode" copytext="Passcode" />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
        {error === 'Failed' ? (
          <Alert color="danger">
            <Content
              cmsTag="Authentication:Sign-in:alert-1"
              copytext="The details entered do not match the data we have stored. Please check and re-enter your passcode."
            />
          </Alert>
        ) : (
          ''
        )}

        {error === 'warning' ? (
          <Alert color="danger">
            <Content
              cmsTag="Authentication:Sign-in:alert-2"
              copytext="Please be aware as this is your second attempt you have one attempt left, after that you will be locked out of your account. If you are locked out, you can reset your passcode to access your account."
            />
          </Alert>
        ) : (
          ''
        )}
        <Switch
          id="login-pin-alert"
          value={error || ''}
          tag="div"
          className="alert alert-danger"
          template={{
            UnknownStatus: {
              loginStatus: this.props.login ? this.props.login.Status : '',
            },
          }}
          contents={{
            UnknownStatus: {
              defaultValue: 'Returned unknown status $[loginStatus]',
            },
          }}
        />
        {formError && formError.pin ? (
          <FormErrorList
            validations={formError}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
        ) : (
          ''
        )}

        <Form autoComplete="off" id="login-update-form" onSubmit={this.submit}>
          <fieldset>
            <Content
              tag="legend"
              tagClassName="label"
              template={{
                copytext: {
                  pos1: `${pin[0] + 1}${this.numberSuffix(pin[0] + 1)}`,
                  pos2: `${pin[1] + 1}${this.numberSuffix(pin[1] + 1)}`,
                  pos3: `${pin[2] + 1}${this.numberSuffix(pin[2] + 1)}`,
                },
              }}
              cmsTag="Authentication:Sign-in:Update:legend"
              copytext="Enter the $[pos1], $[pos2], and $[pos3] digits of your passcode"
            />
            <Text
              type="password"
              label="Pin position 1"
              cmsTag="Authentication:Sign-in:Update:Pin-position-1"
              groupClassName="d-inline float-left"
              labelClassName="sr-only"
              className="pin-input"
              id="pin-value-1"
              field="pin[0]"
              onChange={this.onChange}
              onBlur={this.onBlur}
              autoFocus={true}
              validation={this.state.form.pin[0]}
              showErrors={false}
              maxLength="1"
            />
            <Text
              type="password"
              label="Pin position 2"
              cmsTag="Authentication:Sign-in:Update:Pin-position-2"
              groupClassName="d-inline float-left"
              labelClassName="sr-only"
              className="pin-input"
              id="pin-value-2"
              field="pin[1]"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={this.state.form.pin[1]}
              showErrors={false}
              maxLength="1"
            />
            <Text
              type="password"
              label="Pin position 3"
              cmsTag="Authentication:Sign-in:Update:Pin-position-3"
              groupClassName="d-inline float-left"
              labelClassName="sr-only"
              className="pin-input"
              id="pin-value-3"
              field="pin[2]"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={this.state.form.pin[2]}
              showErrors={false}
              maxLength="1"
            />
            <p className="suffix clear-both">
              <Link to="/forgotten-password">
                <Content
                  cmsTag="Authentication:Sign-in:Update:Reset-my-passcode"
                  copytext="Reset my passcode"
                />
              </Link>
            </p>
            <div id="pin-error-group" className="clear-both">
              {errorOrder.showError ? (
                <ErrorPanel
                  id="pin-value-1"
                  validation={this.state.form.pin[errorOrder.index]}
                  field={['pin', errorOrder.index]}
                />
              ) : (
                ''
              )}
            </div>
          </fieldset>
          <div className="form-button-group">
            {error === 'CredentialBlocked' ? (
              <Link
                to="/sign-in/step-1"
                className="btn btn-secondary"
                role="button"
              >
                <Content
                  cmsTag="GLOBAL:Back-to-home-page"
                  copytext="Back to home page"
                />
              </Link>
            ) : (
              <React.Fragment>
                <Button
                  id="login-update-submit"
                  type="submit"
                  color="primary"
                  disabled={this.props.isFetching}
                >
                  <Content cmsTag="GLOBAL:sign-in" copytext="Sign in" />
                </Button>
                <Button
                  id="login-update-cancel"
                  color="secondary"
                  onClick={() => this.clickBack()}
                >
                  <Content
                    cmsTag="GLOBAL:Back-to-step-1"
                    copytext="Back to step 1"
                  />
                </Button>
                {/* <Link
                  to="/sign-in/step-1"
                  className="btn btn-secondary"
                  role="button"
                >
                  <Content
                    cmsTag="GLOBAL:Back-to-step-1"
                    copytext="Back to step 1"
                  />
                </Link> */}
              </React.Fragment>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

UpdateLogin.propTypes = {
  history: PropTypes.any,
  login: PropTypes.any,
  referrer: PropTypes.any,
  timeout: PropTypes.any,
  updateLogin: PropTypes.any,
  rememberme: PropTypes.bool,
  credentialsActivate: PropTypes.any,
  updateUrl: PropTypes.any,
  remembermeUsername: PropTypes.string,
  isFetching: PropTypes.bool,
};

const mapStateToProps = state => {
  const {
    authenticationDefinition,
    custombrandAuthenticationDefinition,
  } = state;
  return {
    ...authenticationDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      ...mapDispatchToProps,
      ...mapSharedDispatchToProps,
      ...brandAuthenticationDispatch,
    },
  )(UpdateLogin),
);
