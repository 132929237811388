import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { CONTACTS } from '@myie/interact-local-dom';
import { Content, Markdown, LinkTag, AppMeta } from '@myie/interact-dom';

class RequestFailed extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    const { contacts } = this.props;
    return (
      <div id="registration-request-failed">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Request failed"
          metaDescription="Request failed"
        />
        <Content
          tag="h1"
          tagID="registration-request-failed-title"
          cmsTag="Authentication:Registration:Request-failed:h1"
          copytext="Unfortunately your request failed"
        />
        <Markdown
          cmsTag="Authentication:Registration:Request-failed:p1"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nPlease call our Online Support Team on <a href="tel:$[phoneLink]">$[phone]</a>. Our lines are open $[openTimes].`}
        />
        <div className="form-button-group">
          <LinkTag
            role="button"
            href={contacts.externalHomeURL}
            className="btn btn-secondary"
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </LinkTag>
        </div>
      </div>
    );
  }
}

RequestFailed.propTypes = {
  userLogout: PropTypes.any,
  contacts: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestFailed);
