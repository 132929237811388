import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import ShortApplyIndex from './ShortApplyIndex';
import ShortApplyProduct from './ShortApplyProduct';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-accounts';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import TranchExceeded from './TranchExceeded';

class ShortApply extends React.Component {
  render() {
    const { error } = this.props;
    let re = new RegExp('^/short-apply[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/short-apply/index" />;
    }
    return (
      <div id="short-apply">
        {error ? <Alert color="danger">{error}</Alert> : ''}
        <Switch>
          <PrivateRoute
            exact
            path="/short-apply/index/"
            component={ShortApplyIndex}
          />
          <PrivateRoute
            exact
            path="/short-apply/index/:id"
            component={ShortApplyProduct}
          />
          <PrivateRoute
            exact
            path="/short-apply/not-available/"
            component={TranchExceeded}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

ShortApply.propTypes = {
  error: PropTypes.any,
  location: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortApply);
