import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import {
  Text,
  Content,
  Check,
  Markdown,
  DropDown,
  FormErrorList,
} from '@myie/interact-dom';
import { Validate } from '@myie/interact';
import { CONTACTS } from '@myie/interact-local-dom';
import { RULES } from '../../shared/constants/rules';

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        type_Of_account: {
          rules: {
            title: 'type of account',
            stop: true,
            required: {
              message: 'Please select the account type.',
            },
          },
        },
        terms_conditions: {
          value: false,
          rules: {
            title: 'terms and conditions',
            stop: true,
            required: {
              message: `Please confirm that you have read and agreed to ${CONTACTS.companyNamePosessive} Terms & Conditions and Privacy Policy.`,
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onAccTypeChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    let dynamicProps;

    let savingsacc = {
      savings_account_number: {
        rules: {
          title: 'savings account',
          stop: true,
          required: {
            message: 'Please enter your savings account number.',
          },
          format: {
            regex: RULES.accountNumber2,
            message: 'Please enter a valid account number.',
          },
        },
      },
    };

    let mortgageacc = {
      mortgage_account_number: {
        rules: {
          title: 'mortgage account',
          stop: true,
          required: {
            message: 'Please enter your mortgage account number.',
          },
          format: {
            regex: RULES.accountNumber2,
            message: 'Please enter a valid account number.',
          },
        },
      },
    };
    switch (value) {
      case 'SavingsAccount':
        dynamicProps = savingsacc;
        break;
      case 'MortgageAccount':
        dynamicProps = mortgageacc;
        break;
      default:
    }
    /* discard current data and refresh form data with dynamic props  */
    let cardType = {
      terms_conditions: form.terms_conditions,
      type_Of_account: form.type_Of_account,
    };
    form = { ...dynamicProps, ...cardType };
    form = Validate.input(name, value, form);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form } = this.state;
    form = Validate.input(name, checked ? 'true' : null, form);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { verify } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    verify();
  };

  resetStage = () => {
    const { resetRegistration, updateUrl } = this.props;
    updateUrl({
      url: { pageStage: 'PersonalDetails' },
    });
    resetRegistration();
  };

  render() {
    const { form } = this.state;
    let disabledForm = false;
    const { parentPage = 'register' } = this.props;
    if (this.props.disableForm || this.props.isFetching) {
      disabledForm = true;
    }
    let error;

    let savingsacc = (
      <FormGroup key={'savings_account_number'}>
        <Text
          label="Savings account number"
          cmsTag="GLOBAL:savings-account-number"
          id="savings_account_number"
          field="savings_account_number"
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form.savings_account_number}
          maxLength={'8'}
          disabled={disabledForm}
        />
      </FormGroup>
    );

    let mortgageacc = (
      <FormGroup key={'mortgage_account_number'}>
        <Text
          label="Mortgage account number"
          cmsTag="GLOBAL:mortgage-account-number"
          id="mortgage_account_number"
          field="mortgage_account_number"
          onChange={this.onChange}
          onBlur={this.onBlur}
          validation={form.mortgage_account_number}
          maxLength={'8'}
          disabled={disabledForm}
        />
      </FormGroup>
    );

    return (
      <React.Fragment>
        {error ? error : ''}
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          id="authentication-personal-details"
          onSubmit={this.submit}
        >
          <DropDown
            label="What type of account do you have? If you have a savings and mortgage account you can select either."
            id="type_Of_account"
            field="type_Of_account"
            onChange={this.onAccTypeChange}
            onBlur={this.onBlur}
            validation={form.type_Of_account}
            disabled={disabledForm}
          >
            <option
              id="type-of-account-default"
              key="0"
              value=""
              defaultText="Please select"
            ></option>
            <option
              key="1"
              id="type-of-account-option-1"
              value="SavingsAccount"
              defaultText="Savings"
            ></option>
            <option
              key="2"
              id="type-of-account-option-2"
              value="MortgageAccount"
              defaultText="Mortgage"
            ></option>
          </DropDown>
          {(() => {
            switch (form.type_Of_account.value) {
              case 'SavingsAccount':
                return savingsacc;
              case 'MortgageAccount':
                return mortgageacc;
              default:
            }
          })()}
          <Check
            id={`terms_conditions`}
            autoFocus={false}
            label={
              <Markdown
                cmsTag="GLOBAL:I-confirm-that-I-have-read-and-agreed-to-the-Terms-Conditions-and-Privacy-Notice"
                markdown={`I confirm that I have read and agreed to ${CONTACTS.companyNamePosessive} <a href="${CONTACTS.termsAndConditionsURL}" target="_blank">Terms &amp; Conditions<span class="sr-only"> (opens in a new window)</span></a> and <a href="${CONTACTS.privacyPolicyURL}" target="_blank">Privacy Policy<span class="sr-only"> (opens in a new window)</span></a>.`}
              />
            }
            description="Please read the terms and conditions and the privacy policy to continue."
            field="terms_conditions"
            checked={form && !!form.terms_conditions.value}
            value={'false'}
            onChange={this.onClick}
            validation={form.terms_conditions}
          />
          <div className="form-button-group">
            {parentPage === 'register' ? (
              <Button
                id="register-account-details-submit"
                type="submit"
                color="primary"
                disabled={disabledForm}
              >
                <Content
                  cmsTag="Authentication:Shared:Account-details:Continue"
                  copytext="Continue"
                />
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-to-step-3"
                  copytext=" to step 3"
                />
              </Button>
            ) : parentPage === 'forgotten-username' ? (
              <Button
                id="forgotten-username-account-details-submit"
                type="submit"
                color="primary"
                disabled={disabledForm}
              >
                <Content
                  cmsTag="Authentication:Shared:Account-details:Retrieve-username"
                  copytext="Retrieve username"
                />
              </Button>
            ) : parentPage === 'forgotten-password' ? (
              <Button
                id="forgotten-password-account-details-submit"
                type="submit"
                color="primary"
                disabled={disabledForm}
              >
                <Content
                  cmsTag="Authentication:Shared:Account-details:Continue"
                  copytext="Continue"
                />
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-to-step-3"
                  copytext=" to step 3"
                />
              </Button>
            ) : (
              <Button
                id="account-details-submit"
                type="submit"
                color="primary"
                disabled={disabledForm}
              >
                <Content
                  cmsTag="Authentication:Shared:Account-details:Continue"
                  copytext="Continue"
                />
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-to-step-3"
                  copytext=" to step 3"
                />
              </Button>
            )}
            {disabledForm ? (
              <Link
                id="personal-details-cancel"
                className="btn btn-secondary disabled"
                role="button"
                to="/sign-in/step-1"
                onClick={event => event.preventDefault()}
              >
                <Content
                  cmsTag="GLOBAL:Back-to-step-1"
                  copytext="Back to step 1"
                />
              </Link>
            ) : (
              <Button
                id="registration-unsuccess-home-button"
                color="secondary"
                onClick={this.resetStage}
              >
                <Content
                  cmsTag="GLOBAL:Back-to-step-1"
                  copytext="Back to step 1"
                />
              </Button>
            )}
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

AccountDetails.propTypes = {
  history: PropTypes.any,
  saveData: PropTypes.any,
  match: PropTypes.any,
  updateUrl: PropTypes.any,
  setStage: PropTypes.any,
  verify: PropTypes.any,
  disableForm: PropTypes.bool,
  isFetching: PropTypes.bool,
  message: PropTypes.string,
  resetRegistration: PropTypes.any,
  parentPage: PropTypes.string,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AccountDetails),
);
