import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToProps } from '@myie/interact-messaging-twoway';
import { Transfer } from '@myie/interact-widget-quick-transfer-dom';
import {
  HelpContactSidebar,
  LinkTag,
  Content,
  Markdown,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class SummarySideBar extends React.Component {
  render() {
    return (
      <aside className="right-container">
        <HelpContactSidebar contacts={CONTACTS} />

        <LinkTag
          target="_blank"
          id="advert-link"
          href={`${CONTACTS.sidebarAdvertURL}`}
          className="advert-link"
        >
          <Markdown
            cmsTag="Account-summary:sidebar-img"
            markdown={`<img
            alt="Regular savings account advert"
            src="https://www.darlington.co.uk/wp-content/uploads/2022/07/DBS-regular-savings-scaled.jpg"
          />`}
          />
          <Content
            tagClassName="sr-only"
            tag="span"
            cmsTag="GLOBAL:-opens-in-a-new-window"
            copytext=" (opens in a new window)"
          />

          <Markdown
            className="advert-copy"
            cmsTag="Accounts:Summary-sidebar:sidebar-copy"
            markdown={`##Looking for a regular savings account?\nTake your first step today and open an account with as little as £1.`}
          />
        </LinkTag>

        <Transfer />
      </aside>
    );
  }
}

SummarySideBar.propTypes = {
  i18n: PropTypes.any,
  newMessageCount: PropTypes.object,
};

export default connect(mapStateToProps)(SummarySideBar);

/*export default ('accounts')(
  connect(mapStateToProps)(SummarySideBar),
); */
