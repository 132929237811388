import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Markdown, LinkTag } from '@myie/interact-dom';
import { Utility } from '@myie/interact';
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AERAbbreviation from '../../shared/components/AERAbbreviation';
import { CONTACTS } from '@myie/interact-local-dom';

class ApplyAccountSummaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkId: '',
      fileName: '',
      downloadedFiles: [],
      event: {},
      localRefs: null,
    };
  }
  componentDidMount() {
    const { resetMakeWithdrawal } = this.props;
    resetMakeWithdrawal();
  }

  static getDerivedStateFromProps(props, state) {
    //const { linkId, fileName, downloadedFiles, localRefs } = state;
    const { localRefs } = state;

    //const { resetShortapply, DepositProducts } = props;
    const { DepositProducts } = props;
    if (DepositProducts && DepositProducts.length && !localRefs) {
      let thisRefs = {};

      DepositProducts.forEach((product, index) => {
        let variable = `download${index}`;
        thisRefs[variable] = React.createRef();
      });
      return {
        ...state,
        localRefs: { ...thisRefs },
      };
    }

    // if ((linkId, fileName, summaryBox)) {
    //   downloadedFiles.push(linkId);
    //   const newFilename = `${fileName}.pdf`;
    //   const url = window.URL.createObjectURL(new Blob([summaryBox]));
    //   const link = localRefs[`${linkId}`].current;
    //   link.href = null;
    //   link.href = url;
    //   link.setAttribute('download', newFilename);
    //   // Start download
    //   link.click();
    //   resetShortapply();
    // }

    return null;
  }

  select = product => {
    const { selectProduct } = this.props;
    selectProduct(product);
  };
  getMoreDetails = product => {
    this.selectDownloadPdf(product);
  };

  selectDownloadPdf = (e, product, id) => {
    const { getSummaryBox } = this.props;
    const { downloadedFiles } = this.state;
    const foundFile = downloadedFiles.find(element => element === id);
    if (!foundFile) {
      let request = {
        SavingsProductEncodedKey: product.EncodedKey,
        ProductName: product.ProductName,
      };
      this.setState({
        linkId: id,
        fileName: request.ProductName.replace(' ', '_'),
        event: e.target,
      });
      getSummaryBox(request);
    }
  };
  simulateClick = e => {
    console.log(e);
  };

  changeStage = product => {
    const { setStateDynamic } = this.props;
    setStateDynamic({ stage: 'details', selectedProduct: product });
  };

  getAccounts = products => {
    const { page } = this.props;
    const { localRefs } = this.state;
    let list = [];
    let tc_url;
    if (products && products.length > 0) {
      products.forEach((product, index) => {
        //const aERIndex = product.Notes.indexOf('AER');
        let variableRate = product.Interest_rate;
        //let minimumAge = product.minimumAge;
        // const percentageIndex = variableRate.indexOf('%');
        // if (percentageIndex > 0) {
        //   variableRate = variableRate.substring(0, percentageIndex);
        // }
        tc_url = '';
        if (product?.Product_Specific_TAndCs_Link) {
          if (product.Product_Specific_TAndCs_Link?.search('http:') === -1) {
            tc_url = `https://${product.Product_Specific_TAndCs_Link}`;
          } else {
            tc_url = `${product.Product_Specific_TAndCs_Link}`;
          }
        }
        if (
          product.Display_For_Short_Apply === 'Y' &&
          product.Product_Status === 'A' &&
          localRefs
        )
          list.push(
            <Card key={product.Product_Code} className="apply-card">
              <CardHeader>
                <h2>
                  <span className="sr-only">
                    <Content cmsTag="GLOBAL:Option-" copytext="Option-" />
                    {index + 1}:{' '}
                  </span>
                  {product.Product_Name}
                </h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" sm="12" md="6">
                    <h3 id={`apply-list-${index}`}>
                      <Content
                        cmsTag="Savings-servicing:Shared:Apply-account-summary-component:h3-1"
                        copytext="To apply you must be"
                      />
                    </h3>
                    <ul
                      className="bullet-list"
                      aria-labelledby={`apply-list-${index}`}
                    >
                      <li>
                        <Markdown
                          cmsTag="Savings-servicing:Shared:Apply-account-summary-component:li-1"
                          template={{
                            markdown: {
                              age: CONTACTS.minimumAge,
                            },
                          }}
                          markdown="Over $[age] years old."
                        />
                      </li>
                      <li>
                        <Content
                          cmsTag="Savings-servicing:Shared:Apply-account-summary-component:li-2"
                          copytext="A personal saver who is a resident in the UK."
                        />
                      </li>
                    </ul>
                  </Col>
                  <Col xs="12" sm="12" md="6">
                    <h3 id={`features-list-${index}`}>
                      <Content
                        cmsTag="Savings-servicing:Shared:Apply-account-summary-component:h3-2"
                        copytext="Key features"
                      />
                    </h3>
                    <ul
                      className="bullet-list"
                      aria-labelledby={`features-list-${index}`}
                    >
                      <li>
                        <Markdown
                          cmsTag="Savings-servicing:Shared:Apply-account-summary-component:li-1-part-1"
                          template={{
                            markdown: {
                              InterestRate: variableRate,
                            },
                          }}
                          markdown="Rate $[InterestRate]% gross/"
                        />
                        <AERAbbreviation />
                        <Content
                          cmsTag="Savings-servicing:Shared:Apply-account-summary-component:li-1-part-2"
                          copytext=" variable."
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {page === 'short-apply' ? (
                  <Link
                    className="btn btn-primary apply-now-link"
                    role="button"
                    to={`/short-apply/index/${Utility.hexEncode(
                      product.Product_Code,
                    )}`}
                  >
                    <Content cmsTag="GLOBAL:Apply-now" copytext="Apply now" />
                  </Link>
                ) : (
                  <Button
                    id={`more-details-button-${index}`}
                    type="submit"
                    onClick={() => {
                      this.getMoreDetails(product, `download${index}`);
                    }}
                    color="primary"
                  >
                    <Content cmsTag="GLOBAL:Apply-now" copytext="Apply now" />
                    <span className="sr-only">
                      <Content cmsTag="GLOBAL:-for-" copytext=" for " />
                      {product.ProductName}
                    </span>
                  </Button>
                )}
                <LinkTag
                  className={`btn btn-light more-information-button`}
                  target="_blank"
                  role="button"
                  href={tc_url}
                >
                  <Content
                    cmsTag="Savings-servicing:Shared:Apply-account-summary-component:More-information"
                    copytext="More information"
                  />
                  <Content
                    tag="span"
                    tagClassName="sr-only"
                    cmsTag="GLOBAL:-opens-in-a-new-tab"
                    copytext=" (opens in a new tab)"
                  />
                </LinkTag>
              </CardFooter>
            </Card>,
          );
      });
    }

    return list;
  };

  render() {
    const { DepositProducts = [] } = this.props;

    return <React.Fragment>{this.getAccounts(DepositProducts)}</React.Fragment>;
  }
}

ApplyAccountSummaryComponent.propTypes = {
  match: PropTypes.any,
  resetMakeWithdrawal: PropTypes.func,
  selectProduct: PropTypes.func,
  account: PropTypes.any,
  form: PropTypes.any,
  hideSelect: PropTypes.bool,
  showIn: PropTypes.any,
  DepositProducts: PropTypes.any,
  page: PropTypes.string,
  updateUrl: PropTypes.func,
  getSummaryBox: PropTypes.func,
  summaryBox: PropTypes.any,
  getDirectDownloadPdf: PropTypes.func,
  setStateDynamic: PropTypes.any,
  resetShortapply: PropTypes.any,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyAccountSummaryComponent);
