import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Utility } from '@myie/interact';
import {
  Content,
  Markdown,
  FormattedCurrency,
  Date,
  Icon,
  FormattedDate,
  CONSTANTS,
  FormErrorList,
  Currency as CurrencyInput,
} from '@myie/interact-dom';
import { Form, Button, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

class AddAndEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
    };
  }

  submit = e => {
    e.preventDefault();
    const { submit, decision, mode } = this.props;
    if (mode === 'add') {
      submit(e);
    } else {
      submit(decision);
    }
  };

  budgetCalculator = () => {
    const { saveForBudgetCalculation } = this.props;
    saveForBudgetCalculation();
  };

  render() {
    const {
      onChange,
      onBlur,
      getBackButton,
      newDateChange,
      currentBalance,
      changeGoalType,
      updateCurrency,
      changeNewAmount,
      monthlyAmount,
      form,
      page,
      mode = 'add',
      goalName,
      goalKey,
      targetAmount,
      targetDate,
      suggestedMonthlyAmount,
      suggestedAmount,
      suggestedMonthMonth,
      account,
    } = this.props;

    return (
      <React.Fragment>
        <h2 className="media goal-icon-title">
          <Icon name={goalKey} />
          <span className="media-body ml-3">
            <Markdown
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:h2-1"
              template={{
                markdown: {
                  goal: goalName,
                },
              }}
              markdown={`$[goal] goal details`}
            />
          </span>
        </h2>
        <p>
          <Content
            cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:p1-part-1"
            copytext="Your current balance is "
          />
          <strong>
            <FormattedCurrency
              quantity={currentBalance}
              currency={account.CurrencyCode}
            />
          </strong>
          <Content
            cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:p1-part-2"
            copytext="."
          />
        </p>
        <Content
          tag="div"
          tagClassName="alert alert-info"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-1"
          copytext="Please note: This will deduct any pending notices to withdraw you have requested and will not include pending transactions."
        />

        <FormErrorList
          validations={this.state.form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          id="savings-goals-add-amount-form"
          onSubmit={this.submit}
        >
          <CurrencyInput
            showErrors={true}
            label={'How much do you want to save?'}
            validation={form.amount}
            onChange={updateCurrency}
            onBlur={onBlur}
            code={account.CurrencyCode}
            field="amount"
            id="amount"
            dp={2}
            groupClassName="half-field"
          />
          {form.amount && currentBalance < targetAmount ? (
            <Alert color="info" className="float-left">
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-2-part-1"
                copytext="Pay at least "
              />
              <FormattedCurrency
                quantity={monthlyAmount}
                currency={account.CurrencyCode}
              />
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-2-part-2"
                copytext=" per month to meet your goal."
              />
            </Alert>
          ) : (
            ''
          )}

          <Date
            label="When do you want to save it by?"
            cmsTag="GLOBAL:When-do-you-want-to-save-it-by"
            id="date"
            placeholder="dd-mm-yyyy"
            field="date"
            showErrors={true}
            onChange={onChange}
            onBlur={onBlur}
            validation={form.date}
            groupClassName="half-field"
          />

          <CurrencyInput
            label="How much will you save each month?"
            showErrors={true}
            validation={form.monthlyAmount}
            onChange={updateCurrency}
            onBlur={onBlur}
            code={account.CurrencyCode}
            field="monthlyAmount"
            id="monthlyAmount"
            dp={2}
            groupClassName="half-field"
          />
          {targetAmount <= currentBalance ||
          (form.monthlyAmount &&
            form.monthlyAmount.value &&
            suggestedAmount >= targetAmount) ? (
            <Alert
              color="success"
              className="goal-success-alert d-inline-block w-auto"
            >
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-3-part-1"
                copytext="You will be able to save "
              />
              <FormattedCurrency
                quantity={targetAmount}
                currency={account.CurrencyCode}
              />
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-3-part-2"
                copytext=" by "
              />
              <FormattedDate
                date={targetDate}
                format={CONSTANTS.STANDARD_DATE_FORMAT}
              />
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-3-part-3"
                copytext="."
              />
            </Alert>
          ) : (
            ''
          )}
          {targetAmount > currentBalance &&
          form.monthlyAmount &&
          form.monthlyAmount.value &&
          suggestedAmount < targetAmount ? (
            <Alert
              color="danger"
              className="goal-failure-alert d-inline-block w-auto"
            >
              <Content
                tag="p"
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-4:p1"
                copytext="Unfortunately, your goal cannot be met with the details you have entered."
              />
              <Content
                tag="p"
                tagID="possible-options"
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:alert-4:p2"
                copytext="Please consider one of the following changes:"
              />
              <ol aria-labelledby={`possible-options`}>
                <li>
                  <button
                    onClick={() => changeNewAmount('amount', suggestedAmount)}
                  >
                    <Content
                      cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:li-1"
                      copytext="Reduce the total amount to "
                    />
                    <FormattedCurrency
                      quantity={suggestedAmount}
                      currency={account.CurrencyCode}
                    />
                    .
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      newDateChange(
                        'date',
                        moment(suggestedMonthMonth).format('YYYY-MM-DD'),
                      )
                    }
                  >
                    <Content
                      cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:li-2"
                      copytext="Extend date to "
                    />
                    <FormattedDate
                      date={suggestedMonthMonth}
                      format={CONSTANTS.STANDARD_DATE_FORMAT}
                    />
                    .
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      changeNewAmount('monthlyAmount', suggestedMonthlyAmount)
                    }
                  >
                    <Content
                      cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:li-3"
                      copytext="Increase your monthly contribution to "
                    />
                    <FormattedCurrency
                      quantity={suggestedMonthlyAmount}
                      currency={account.CurrencyCode}
                    />
                    .
                  </button>
                </li>
              </ol>
            </Alert>
          ) : (
            ''
          )}
          <p>
            <Content
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:p1-part-1"
              copytext="Use our "
            />
            <Link
              to={`/money-tools/budget-calculator/${Utility.hexEncode(
                account.AccountKey.Key,
              )}`}
              onClick={() => this.budgetCalculator()}
              id="open-budget-calculator"
              className="d-inline w-auto p-0 m-0"
              size="sm"
            >
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:budget-calculator"
                copytext="budget calculator"
              />
            </Link>
            <Content
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Add-and-edit-form:p1-part-2"
              copytext=" to help you calculate how much you can save."
            />
          </p>

          <div className="form-button-group">
            <Button id="savings-goals-submit" type="submit" color="primary">
              <Content
                cmsTag="GLOBAL:Review-goal-details"
                copytext="Review goal details"
              />
            </Button>

            {mode === 'add' ? (
              <Button
                id="savings-goals-choose-another-type"
                onClick={() => changeGoalType()}
                color="light"
              >
                <Content
                  cmsTag="GLOBAL:Change-goal-type"
                  copytext="Change goal type"
                />
              </Button>
            ) : (
              ''
            )}
            {getBackButton(page)}
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

AddAndEditForm.propTypes = {
  resetViewGoals: PropTypes.func,
  data: PropTypes.object,
  saveForBudgetCalculation: PropTypes.func,
  updateUrl: PropTypes.func,
  options: PropTypes.any,
  setStage: PropTypes.func,
  match: PropTypes.any,
  saveData: PropTypes.any,
  account: PropTypes.any,
  decision: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  submit: PropTypes.func,
  getBackButton: PropTypes.func,
  newDateChange: PropTypes.func,
  currentBalance: PropTypes.any,
  changeGoalType: PropTypes.func,
  updateCurrency: PropTypes.func,
  changeNewAmount: PropTypes.func,
  monthlyAmount: PropTypes.any,
  formData: PropTypes.object,
  form: PropTypes.object,
  page: PropTypes.string,
  mode: PropTypes.string,
  goalName: PropTypes.string,
  goalKey: PropTypes.any,
  targetAmount: PropTypes.any,
  targetDate: PropTypes.any,
  nextMonth: PropTypes.any,
  suggestedMonthlyAmount: PropTypes.any,
  suggestedAmount: PropTypes.any,
  suggestedMonthMonth: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddAndEditForm);
