import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReviewAndSubmit from '../shared/ReviewAndSubmit';

class Review extends Component {
  submit = () => {
    const { submitForm } = this.props;
    submitForm();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      formData = null,
      goal,
      account = null,
      page,
      getBackButton,
    } = this.props;
    if (!formData || !goal || !account) {
      return null;
    }

    return (
      <React.Fragment>
        <ReviewAndSubmit
          formData={formData}
          account={account}
          page={page}
          goalName={goal.Name}
          getBackButton={getBackButton}
          goal={goal}
          submit={this.submit}
          updateUrl={this.props.updateUrl}
          setStateDynamic={this.props.setStateDynamic}
        />
      </React.Fragment>
    );
  }
}

Review.propTypes = {
  getBackButton: PropTypes.func,
  page: PropTypes.string,
  submit: PropTypes.func,
  submitForm: PropTypes.func,
  goal: PropTypes.object,
  updateUrl: PropTypes.func,
  formData: PropTypes.any,
  options: PropTypes.any,
  setStateDynamic: PropTypes.func,
  match: PropTypes.any,
  saveData: PropTypes.any,
  account: PropTypes.any,
};

export default Review;
