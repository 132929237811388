import approve from 'approvejs';
import moment from 'moment';
import { CONSTANTS, sharedUtility } from '@myie/interact-dom';

const checkboxGroup = {
  expects: ['comparison'],
  message: 'Please select at least one option.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const valueArray = comparison();
      return valueArray.some(e => e);
    }
    return true;
  },
};

const optionalPhone = {
  expects: [],
  message: '{title} must be a valid phone number.',
  validate: function(value) {
    if (!value) {
      return true;
    }
    let reg = new RegExp(/^[0+](?!(.*\d){16})([0-9\s]{0,}[0-9]+)$/);
    return reg.test(value);
  },
};

const thisValuehigherThan = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return num > balance;
    }
    return true;
  },
};

const compareWithAvailableBalance = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return num < balance;
    }
    return true;
  },
};

const conditionalCheck = {
  expects: ['comparison', 'balance'],
  message: 'You have decided to withdraw your full balance.',
  validate: function(value, pars) {
    const { comparison, balance } = pars;
    if (typeof comparison === 'function') {
      const inputAmount = comparison();
      if (value) {
        return true;
      }
      const input =
        inputAmount && Number(String(inputAmount).replace(/,/g, ''));
      const balanceToCompare = balance;
      return balanceToCompare !== input;
    }
    return true;
  },
};

const compareWithBalanceGreaterThan = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      if (num < balance) {
        return false;
      }
    }
    return true;
  },
};

const insufficientCheck = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      if (balance < 1) {
        return false;
      }
    }
    return true;
  },
};

const equalToTheBalance = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      if (num === balance) {
        return false;
      }
    }
    return true;
  },
};

const compareWithBalanceLessThan = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      if (num > balance) {
        return false;
      }
    }
    return true;
  },
};

const compareWithBalanceEqual = {
  expects: ['comparison'],
  message: 'Please enter an amount less than available balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      if (num === balance) {
        return false;
      }
    }
    return true;
  },
};

const minAmountCheck = {
  expects: ['comparison'],
  message: 'Please enter a minimum amount of £1000.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const values = comparison();
      if (values) {
        const num = value && Number(String(value).replace(/,/g, ''));
        return num >= values[0];
      }
    }
    return true;
  },
};

const minAmountCheckWithInputValue = {
  expects: ['comparison'],
  message: '',
  validate: function(value, par) {
    const { comparison } = par;
    if (typeof comparison === 'function') {
      const parValue = comparison();
      if (parValue) {
        const num = value && Number(String(value).replace(/,/g, ''));

        return num >= parValue;
      }
    }
    return true;
  },
};

const maxAmountCheckWithInputValue = {
  expects: ['comparison'],
  message: '',
  validate: function(value, par) {
    const { comparison } = par;
    if (typeof comparison === 'function') {
      const parValue = comparison();
      if (parValue) {
        const num = value && Number(String(value).replace(/,/g, ''));

        return num <= parValue;
      }
    }
    return true;
  },
};

const maxDepositMonthly = {
  expects: ['comparison'],
  message: '',
  validate: function(value, par) {
    const { comparison } = par;
    if (typeof comparison === 'function') {
      const parValue = comparison();
      if (parValue) {
        const num = value && Number(String(value).replace(/,/g, ''));

        return num <= parValue;
      }
    }
    return true;
  },
};

const maxDepositYearly = {
  expects: ['comparison'],
  message: '',
  validate: function(value, par) {
    const { comparison } = par;
    if (typeof comparison === 'function') {
      const parValue = comparison();
      if (parValue) {
        const num = value && Number(String(value).replace(/,/g, ''));

        return num <= parValue;
      }
    }
    return true;
  },
};

const maxAmountCheck = {
  expects: ['comparison'],
  message: 'Please enter a minimum amount of £1000.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const values = comparison();
      if (values) {
        const num = value && Number(String(value).replace(/,/g, ''));
        if (values[1] > values[0]) {
          return num <= values[0];
        }
      }
    }
    return true;
  },
};

const maxAmountCheckCheckbox = {
  expects: ['comparison'],
  message: 'Please enter a minimum amount of £1000.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const values = comparison();
      if (values) {
        if (value && values[1] >= values[0]) {
          return values[0] >= values[1];
        }
      }
    }
    return true;
  },
};

const minAmountFullBalance = {
  expects: ['comparison'],
  message: 'Please enter your full balance to proceed.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const balance = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      if (balance < 1000) {
        return num >= balance;
      }
    }
    return true;
  },
};

const pastFormat16 = {
  expects: [],
  message: 'Your date of birth cannot be a future date.',
  validate: function(value) {
    const dateDiff = moment().diff(
      moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD),
      'years',
    );
    if (dateDiff < 16) {
      return false;
    }
    return true;
  },
};

const under13 = {
  expects: [],
  message: 'You must be over 13.',
  validate: function(value) {
    const dateDiff = moment().diff(
      moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD),
      'years',
    );
    if (dateDiff < 13) {
      return false;
    }
    return true;
  },
};

const pastFormat = {
  expects: [],
  message: 'Your date of birth cannot be a future date.',
  validate: function(value) {
    const dateDiff = moment().diff(
      moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD),
      'days',
    );
    const dateOfMonth = moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD).date();
    const todayDate = moment().date();

    if (dateDiff < 0) {
      return false;
    } else if (dateDiff < 1) {
      if (dateOfMonth !== todayDate) {
        return false;
      }
    }
    return true;
  },
};

const pastFormatLiving = {
  expects: [],
  message: 'Please select a date in the past.',
  validate: function(value) {
    const dateDiff = moment().diff(
      moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD),
      'days',
    );
    const dateOfMonth = moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD).date();
    const todayDate = moment().date();

    if (dateDiff < 0) {
      return false;
    } else if (dateDiff < 1) {
      if (dateOfMonth !== todayDate) {
        return false;
      }
    }
    return true;
  },
};

const compareEndDateLocal = {
  expects: ['comparison', 'field'],
  message: '{title} must be greater than or equal to the {field}.',
  validate: function(value, pars) {
    const { comparison } = pars;
    const fromSec = moment(comparison(), CONSTANTS.DATE_FORMAT_YYYYMMDD).unix();
    const toSec = moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD).unix();

    if (typeof comparison === 'function') {
      return toSec >= fromSec;
    }
    return toSec >= fromSec;
  },
};

const compareStartDateLocal = {
  expects: ['comparison', 'field'],
  message: '{title} must be less than or equal to the {field}.',
  validate: function(value, pars) {
    const { comparison } = pars;
    const fromSec = moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD).unix();
    const toSec = moment(comparison(), CONSTANTS.DATE_FORMAT_YYYYMMDD).unix();

    if (typeof comparison === 'function') {
      return toSec >= fromSec;
    }
    return toSec >= fromSec;
  },
};

const futureFormat = {
  expects: [],
  message: 'Your date of birth cannot be a future date.',
  validate: function(value) {
    const dateDiff = moment().diff(
      moment(value, CONSTANTS.DATE_FORMAT_YYYYMMDD),
      'days',
    );
    if (dateDiff > 0) {
      return false;
    }
    return true;
  },
};

const isSequential = {
  expects: ['comparison'],
  message: 'Your passcode cannot include sequential numbers.',
  validate: function(value) {
    const isSequentialValue = sharedUtility.isSequential(value);

    if (isSequentialValue) {
      return false;
    }

    return true;
  },
};

const isAllSame = {
  expects: ['comparison'],
  message: 'Your passcode cannot be the same number repeated six times.',
  validate: function(value) {
    const checkAllSameValue = sharedUtility.checkAllSame(value);

    if (checkAllSameValue) {
      return false;
    }

    return true;
  },
};

const isDoB = {
  expects: ['comparison'],
  message: 'Your passcode cannot be your date of birth.',
  validate: function(value, pars) {
    const { comparison } = pars;
    let dob = '';

    if (typeof comparison === 'function') {
      dob = comparison();
      const dobValue = sharedUtility.checkDob(dob, value);

      if (dobValue) {
        return false;
      }

      return true;
    }
  },
};

const isSameAsUsername = {
  expects: ['comparison'],
  message: 'Your username and password cannot be the same.',
  validate: function(value, pars) {
    const { comparison } = pars;
    let username = '';

    if (typeof comparison === 'function') {
      username = comparison();
      const usernameValue = sharedUtility.checkAllSame(username, value);

      if (usernameValue) {
        return false;
      }

      return true;
    }
  },
};

const isAllowanceExceeded = {
  expects: ['comparison'],
  message: 'Please enter an amount less than your allowance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const [maximumAllowed, alreadyUsed] = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return (
        maximumAllowed === 0 ||
        (alreadyUsed < maximumAllowed && num <= maximumAllowed)
      );
    }
    return false;
  },
};

const minOperationalBalance = {
  expects: ['comparison'],
  message: 'Please enter minimum operational balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const [Minimum_Operating_Balance, balance] = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return balance - num >= Minimum_Operating_Balance;
    }
    return false;
  },
};

const minOperationalBalanceIfNotFullWithdraw = {
  expects: ['comparison'],
  message: 'Please enter minimum operational balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const [Minimum_Operating_Balance, balance] = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      if (num !== balance) {
        return balance - num >= Minimum_Operating_Balance;
      }
      return true;
    }
    return false;
  },
};

const maxOperationalBalance = {
  expects: ['comparison'],
  message: 'Please enter minimum operational balance.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const [Maximum_Operating_Balance, balance] = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return balance + num <= Maximum_Operating_Balance;
    }
    return false;
  },
};

const maxAmountAllowedCheck = {
  expects: ['comparison'],
  message: 'Please enter a maximum amount of £25,000.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      const maxAmount = comparison();
      const num = value && Number(String(value).replace(/,/g, ''));
      return num <= maxAmount;
    }
    return true;
  },
};

approve.addTest(compareEndDateLocal, 'compareEndDateLocal');
approve.addTest(compareStartDateLocal, 'compareStartDateLocal');
approve.addTest(conditionalCheck, 'conditionalCheck');
approve.addTest(futureFormat, 'futureFormat');
approve.addTest(pastFormat, 'pastFormat');
approve.addTest(compareWithAvailableBalance, 'compareWithAvailableBalance');
approve.addTest(thisValuehigherThan, 'thisValuehigherThan');
approve.addTest(minAmountFullBalance, 'minAmountFullBalance');
approve.addTest(minAmountCheck, 'minAmountCheck');
approve.addTest(checkboxGroup, 'checkboxGroup');
approve.addTest(compareWithBalanceLessThan, 'compareWithBalanceLessThan');
approve.addTest(compareWithBalanceEqual, 'compareWithBalanceEqual');
approve.addTest(compareWithBalanceGreaterThan, 'compareWithBalanceGreaterThan');
approve.addTest(maxAmountCheck, 'maxAmountCheck');
approve.addTest(maxAmountCheckCheckbox, 'maxAmountCheckCheckbox');
approve.addTest(equalToTheBalance, 'equalToTheBalance');
approve.addTest(insufficientCheck, 'insufficientCheck');
approve.addTest(pastFormat16, 'pastFormat16');
approve.addTest(under13, 'under13');
approve.addTest(optionalPhone, 'optionalPhone');
approve.addTest(pastFormatLiving, 'pastFormatLiving');
approve.addTest(isSequential, 'isSequential');
approve.addTest(isAllSame, 'isAllSame');
approve.addTest(isDoB, 'isDoB');
approve.addTest(isSameAsUsername, 'isSameAsUsername');
approve.addTest(minAmountCheckWithInputValue, 'minAmountCheckWithInputValue');
approve.addTest(maxAmountCheckWithInputValue, 'maxAmountCheckWithInputValue');
approve.addTest(isAllowanceExceeded, 'isMonthlyAllowanceExceeded');
approve.addTest(isAllowanceExceeded, 'isYearlyAllowanceExceeded');
approve.addTest(isAllowanceExceeded, 'totalDepositAvailableExceeded');
approve.addTest(minOperationalBalance, 'minOperationalBalance');
approve.addTest(maxOperationalBalance, 'maxOperationalBalance');
approve.addTest(maxAmountAllowedCheck, 'maxAmountAllowedCheck');
approve.addTest(maxDepositYearly, 'maxDepositYearly');
approve.addTest(maxDepositMonthly, 'maxDepositMonthly');
approve.addTest(
  minOperationalBalanceIfNotFullWithdraw,
  'minOperationalBalanceIfNotFullWithdraw',
);
