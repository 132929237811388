import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '@myie/interact-dom';

class FrequencyDropdown extends Component {
  render() {
    const { frequency, onChange, onBlur, ...rest } = this.props;

    return (
      <DropDown
        label="Frequency"
        field="frequency"
        cmsTag={`GLOBAL:Frequency`}
        value={frequency.value}
        validation={frequency}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      >
        <option key="0" value="" defaultText="Please select" />
        <option key="1" value="day" defaultText="Daily" />
        <option key="2" value="week" defaultText="Weekly" />
        <option key="3" value="fortnight" defaultText="Fortnightly" />
        <option key="4" value="four-week" defaultText="Four-weekly" />
        <option key="5" value="month" defaultText="Monthly" />
        <option key="6" value="quarter" defaultText="Quarterly" />
        <option key="7" value="6 months" defaultText="Six-monthly" />
        <option key="8" value="year" defaultText="Yearly" />
      </DropDown>
    );
  }
}

FrequencyDropdown.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  form: PropTypes.object,
  frequency: PropTypes.object,
};

export default FrequencyDropdown;
