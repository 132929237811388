import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { BreadcrumbItem, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { storage } from './storageName';
import { AppMeta, Content } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { Utility } from '@myie/interact';
import queryString from 'query-string';
import Review from './Review';
import GoalDetailsForm from './GoalDetailsForm';
import Success from '../shared/Success';
import moment from 'moment';
import loadStateHOC from '../../../../shared/stateManager/loadStateHOC';
import { CONTACTS } from '@myie/interact-local-dom';

class EditGoal extends Component {
  constructor(props) {
    super(props);

    const {
      stateData,
      urlParams,
      preservedSavingsGoalState,
      updateState,
      location,
      resetPreserveGoalState,
    } = props;
    const stateList = ['goalDetails', 'reviewAndsubmit', 'success'];

    const budgetPlannerUrl = queryString.parse(location.search);
    let stage = '';
    let data = stateData && stateData.formData ? stateData.formData : {};
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    if (
      budgetPlannerUrl &&
      budgetPlannerUrl.pageStage &&
      budgetPlannerUrl.calculator &&
      preservedSavingsGoalState
    ) {
      stage = budgetPlannerUrl.pageStage;
      data =
        preservedSavingsGoalState && preservedSavingsGoalState.formData
          ? preservedSavingsGoalState.formData
          : {};
      let stateData = {
        formData: preservedSavingsGoalState.formData,
        url: {
          pageStage: stage,
        },
      };
      updateState(stateData);
      resetPreserveGoalState();
    } else {
      stage =
        stateData &&
        stateData.url &&
        stateData.url.pageStage &&
        props.urlParams &&
        props.urlParams.pageStage
          ? props.urlParams.pageStage
          : 'goalDetails';
      data = stateData && stateData.formData ? stateData.formData : {};
    }

    if (!hasStage) {
      stage = 'goalDetails';
    }

    this.state = {
      stage: stage,
      data: data,
      account: null,
    };
  }

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps(props, state) {
    const {
      accounts,
      match,
      viewGoalDetails,
      viewGoals,
      urlParams,
      updateGoalDetails,
      isFetching,
      formData,
      updateUrl,
    } = props;

    let account;
    let keys = [];

    if (accounts && accounts.Accounts && !state.account) {
      if (match && match.params.id) {
        account = accounts.Accounts.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.AccountKey.Key) === match.params.id
            );
          }
          return false;
        });

        return {
          account: account,
        };
      }
    }

    if (
      accounts &&
      accounts.Accounts &&
      state.account &&
      !viewGoalDetails &&
      !isFetching &&
      !state.called
    ) {
      if (match && match.params.id) {
        account = state.account;
        if (account) {
          keys.push(account.AccountKey);
        }
        viewGoals({
          AccountKeys: keys,
        });
        return {
          called: true,
        };
      }
    }

    if (!formData && viewGoalDetails && !urlParams.pageStage) {
      let goal;
      goal = viewGoalDetails.Goals.find(element => {
        if (element) {
          return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
        }

        return null;
      });
      let data = {
        amount: goal ? goal.Amount : '',
        date: goal ? goal.TargetDate : '',
        monthlyAmount: goal ? goal.Monthly : '',
      };
      let stateData = {
        formData: data,
        url: {
          pageStage: 'goalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'goalDetails',
        data: data,
      };
    }

    if (
      updateGoalDetails &&
      updateGoalDetails.ResponseStatus &&
      viewGoalDetails &&
      state.account &&
      state.stage !== 'success'
    ) {
      let stateData = {
        formData: props.stateData.formData,
        url: {
          pageStage: 'success',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'success',
        data: props.stateData.formData,
      };
    }
    return null;
  }
  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  saveForBudgetCalculation = () => {
    const { preserveGoalState, match } = this.props;
    let stateData = {
      formData: this.props.stateData.formData,
      accountId: match.params.id,
      mode: 'edit',
      url: {
        pageStage: this.stage,
      },
    };

    preserveGoalState(stateData);
  };

  changeUrl = () => {
    const { match, history } = this.props;

    switch (match.params.page) {
      case 'goal-list':
        history.push('/money-tools/savings-goals/view');
        break;
      case 'detail':
      case 'account-details':
        {
          let url = `/accounts/details/${match.params.id}`;
          history.push(url);
        }
        break;
      case 'summary':
        history.push('/accounts/list');
        break;
      default:
    }
  };

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  componentWillUnmount() {
    const { resetUpdateGoals, resetViewGoals } = this.props;
    resetUpdateGoals();
    resetViewGoals();
  }

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'goal-list':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-savings-goal-list"
            copytext="Back to savings goal list"
          />
        );
      case 'myDetails':
      default:
        return null;
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  submitForm = () => {
    const { account, data } = this.state;
    const { viewGoalDetails, match, updateGoals } = this.props;

    let goal;
    goal = viewGoalDetails.Goals.find(element => {
      if (element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      }

      return null;
    });
    let request = {
      AccountKey: account.AccountKey,
      AccountCurrency: 'GBP',
      Category: goal.Category,
      Name: goal.Name,
      TargetDate: moment(data['date']).utcOffset(0, true),
      Amount: data['amount'],
      Monthly: data['monthlyAmount'], //matchform.monthlyAmount.value,
      Existing: true,
      ExtendedProperties: {
        IpAddress: 'IpAddress',
        CustomerNumber: 'CustomerNumber',
        AccountNumber: 'AccountNumber',
      },
    };

    updateGoals(request);
  };

  currentStage = () => {
    const { viewGoalDetails, accounts, match, updateUrl } = this.props;
    const { account, options } = this.state;
    let goal;
    if (accounts && viewGoalDetails && viewGoalDetails.Goals) {
      goal = viewGoalDetails.Goals.find(element => {
        if (element) {
          return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
        }

        return null;
      });
    }
    switch (this.state.stage) {
      case 'goalDetails':
        return (
          <GoalDetailsForm
            saveData={this.saveData}
            getBackButton={this.getBackButton}
            setStage={this.setStage}
            saveForBudgetCalculation={this.saveForBudgetCalculation}
            page={match.params.page}
            formData={this.state.data}
            data={this.state.data}
            updateUrl={updateUrl}
            account={this.state.account}
            goal={goal}
            options={options}
            submit={this.submit}
          />
        );
      case 'reviewAndsubmit':
        return (
          <Review
            saveData={this.saveData}
            submitForm={this.submitForm}
            getBackButton={this.getBackButton}
            page={match.params.page}
            setStateDynamic={this.setStateDynamic}
            updateUrl={updateUrl}
            formData={this.state.data}
            account={account}
            submit={this.onSubmitGoals}
            form={this.state.form}
            goal={goal}
          />
        );
      default:
    }
  };

  render() {
    const { viewGoalDetails, match } = this.props;
    const { account, stage, data } = this.state;

    if (!viewGoalDetails || !account || !data) {
      return null;
    }

    let goal;
    if (account && viewGoalDetails && viewGoalDetails.Goals) {
      goal = viewGoalDetails.Goals.find(element => {
        if (element) {
          return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
        }

        return null;
      });
    }
    if (stage === 'success') {
      return (
        <Success
          getBackButton={this.getBackButton}
          getContent={this.getContent}
          account={account}
          formData={this.state.data}
          changeUrl={this.changeUrl}
          page={match.params.page}
          mode="edit"
          goal={goal}
          goalName={goal.Name}
          to="/money-tools/savings-goals/view"
          resetCreateGoals={this.props.resetCreateGoals}
        />
      );
    }
    return (
      <div id="edit-goal">
        <AppMeta
          id="edit-savings-goal"
          contacts={CONTACTS}
          stage="child"
          title="Edit Goal"
          metaDescription="Edit Goal"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Edit-goal:Index:h1"
          copytext="Edit savings goal"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem active={stage === 'goalDetails'}>
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="BREADCRUMB:Active"
                copytext="Active: "
              />
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:goal-details"
                copytext="Goal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'reviewAndsubmit'}>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        {this.currentStage()}
      </div>
    );
  }
}

EditGoal.propTypes = {
  options: PropTypes.any,
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  createGoalDetails: PropTypes.func,
  match: PropTypes.any,
  updateUrl: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  location: PropTypes.any,
  deleteGoals: PropTypes.func,
  updateGoals: PropTypes.func,
  resetCreateGoals: PropTypes.any,
  viewGoalDetails: PropTypes.any,
  account: PropTypes.any,
  updateGoalDetails: PropTypes.object,
  preservedSavingsGoalState: PropTypes.any,
  updateState: PropTypes.func,
  resetPreserveGoalState: PropTypes.func,
  resetUpdateGoals: PropTypes.func,
  deleteGoalDetails: PropTypes.any,
  urlParams: PropTypes.object,
  viewGoals: PropTypes.func,
  stateData: PropTypes.object,
  preserveGoalState: PropTypes.func,
  formData: PropTypes.object,
  resetViewGoals: PropTypes.func,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
  )(withRouter(EditGoal)),
  storage.name,
);
