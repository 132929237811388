import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { Currency, Content /*, TextArea */ } from '@myie/interact-dom';
import { Validate, Settings } from '@myie/interact';

import {
  mapDispatchToProps,
  mapStateToProps,
} from '@myie/interact-brand-savings-servicing';
import FrequencyDropdown from './frequencyDropdown';
import ItemDebts from './ItemDebts';
import ItemCourtPayments from './ItemCourtPayments';
import ItemAssets from './ItemAssets';
import { Icon } from '@myie/interact-dom';

const numericRange = {
  expects: ['min', 'max'],
  message: 'must be within range',
  validate: function(value, pars) {
    if (!value) {
      return true;
    }
    return value >= pars.min && value <= pars.max;
  },
};

Validate.addTest(numericRange, 'numericRange');

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: this.getInitForm(),
      showNote: false,
    };
  }

  getInitForm = () => {
    const { item, categoryIndex } = this.props;
    let form;
    if (categoryIndex === 'debts') {
      form = {
        type: {
          value: item.type ? item.type : '',
          rules: {
            required: { message: 'Please enter a valid frequency.' },
            stop: true,
          },
        },
        total_owed: {
          value: item.total_owed ? item.total_owed : '',
          rules: {
            numericRange: {
              min: 0.01,
              max: 10000000,
              message: 'Please enter a valid amount.',
            },
            stop: true,
          },
        },
        amount: {
          value: item.amount ? item.amount : '',
          rules: {
            numericRange: {
              min: 0.01,
              max: 10000000,
              message: 'Please enter a valid amount.',
            },
            stop: true,
          },
        },
        frequency: {
          value: item.frequency ? item.frequency : '',
          rules: {
            required: { message: 'Please enter a valid frequency.' },
            stop: true,
          },
        },
      };
    } else if (categoryIndex === 'court_payments') {
      form = {
        total_owed: {
          value: item.total_owed ? item.total_owed : '',
          rules: {
            numericRange: {
              min: 0.01,
              max: 10000000,
              message: 'Please enter a valid amount.',
            },
            stop: true,
          },
        },
        amount: {
          value: item.amount ? item.amount : '',
          rules: {
            numericRange: {
              min: 0.01,
              max: 10000000,
              message: 'Please enter a valid amount.',
            },
            stop: true,
          },
        },
        frequency: {
          value: item.frequency ? item.frequency : '',
          rules: {
            required: { message: 'Please enter a valid frequency.' },
            stop: true,
          },
        },
      };
    } else if (categoryIndex === 'assets') {
      form = {
        amount: {
          value: item.amount ? item.amount : '',
          rules: {
            numericRange: {
              min: 0.01,
              max: 10000000,
              message: 'Please enter a valid amount.',
            },
            stop: true,
          },
        },
      };
    } else {
      form = {
        amount: {
          value: item.amount ? item.amount : '',
          rules: {
            numericRange: {
              min: 0.01,
              max: 10000000,
              message: 'Please enter a valid amount.',
            },
            stop: true,
          },
        },
        frequency: {
          value: item.frequency ? item.frequency : '',
          rules: {
            stop: true,
            required: { message: 'Please enter a valid frequency.' },
          },
        },
      };
    }

    form = {
      ...form,
      noteText: {
        value: item.note ? item.note : '',
        rules: {
          stop: true,
        },
      },
    };

    return form;
  };

  onChange = e => {
    const {
      updateBudgetPlanner,
      clearGroupItem,
      category,
      group,
      item,
      categoryIndex,
      categoryType,
    } = this.props;
    const { name, value } = e.target;
    let { form } = this.state;
    let itemName;
    if (
      (categoryType === 'debts') |
      (categoryType === 'court_payments') |
      (categoryType === 'assets')
    ) {
      itemName = item.type;
    } else {
      itemName = item.name;
    }
    if (
      name === 'amount' &&
      value &&
      categoryIndex !== 'assets' &&
      !form.frequency.value
    ) {
      form.frequency.rules = {
        required: { message: 'Please enter a valid frequency.' },
        stop: true,
      };
      form = Validate.input('frequency', '', form, true);
    }
    if (
      name === 'amount' &&
      !value &&
      categoryIndex !== 'assets' &&
      !form.frequency.value
    ) {
      form.frequency.rules = {};
      form = Validate.input('frequency', '', form, true);
    }
    if (
      name === 'frequency' &&
      !value &&
      categoryIndex !== 'assets' &&
      !form.amount.value
    ) {
      form.frequency.rules = {};
      form = Validate.input('frequency', '', form, true);
    }
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
    updateBudgetPlanner(category, group, itemName, name, value, categoryIndex);
    if (clearGroupItem && name === 'amount' && value > 0) {
      clearGroupItem();
    }
  };

  onBlur = e => {
    const {
      updateBudgetPlanner,
      clearGroupItem,
      category,
      group,
      item,
      // budgetPlanner,
      //  saveBudgetPlanner,
      categoryIndex,
      categoryType,
    } = this.props;
    const { name, value } = e.target;
    let { form } = this.state;
    let itemName;
    if (
      (categoryType === 'debts') |
      (categoryType === 'court_payments') |
      (categoryType === 'assets')
    ) {
      itemName = item.type;
    } else {
      itemName = item.name;
    }
    if (
      name === 'amount' &&
      value &&
      categoryIndex !== 'assets' &&
      !form.frequency.value
    ) {
      form.frequency.rules = {
        required: { message: 'Please enter a valid frequency.' },
        stop: true,
      };
      form = Validate.input('frequency', '', form, true);
    }
    if (
      name === 'amount' &&
      !value &&
      categoryIndex !== 'assets' &&
      !form.frequency.value
    ) {
      form.frequency.rules = {};
      form = Validate.input('frequency', '', form, true);
    }
    if (
      name === 'frequency' &&
      !value &&
      categoryIndex !== 'assets' &&
      !form.amount.value
    ) {
      form.frequency.rules = {};
      form = Validate.input('frequency', '', form, true);
    }
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
    updateBudgetPlanner(category, group, itemName, name, value, categoryIndex);
    if (clearGroupItem && name === 'amount' && value > 0) {
      clearGroupItem();
    }
    //saveBudgetPlanner(budgetPlanner);
  };

  onItemDelete = () => {
    const {
      deleteBudgetPlannerCatItem,
      categoryIndex,
      categoryType,
      index,
    } = this.props;
    let deleteItem = {
      categoryIndex,
      categoryType,
      itemIndex: index,
    };
    deleteBudgetPlannerCatItem(deleteItem);
  };

  onNoteClick = () => {
    let { showNote } = this.state;
    this.setState({ ...this.state, showNote: !showNote });
  };

  onNoteChange = e => {
    let { form } = this.state;
    const { name, value } = e.target;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  onNoteSave = () => {
    const {
      saveNote,
      category,
      group,
      categoryIndex,
      categoryType,
      item,
    } = this.props;
    let itemName;
    if (
      (categoryType === 'debts') |
      (categoryType === 'court_payments') |
      (categoryType === 'assets')
    ) {
      itemName = item.type;
    } else {
      itemName = item.name;
    }
    let { form } = this.state;
    const name = 'noteText';
    const value = form.noteText.value;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
    if (form.noteText.state.approval.approved) {
      saveNote(category, group, itemName, 'note', value, categoryIndex);
    }
  };

  getItemContent = (
    item,
    index,
    category,
    group,
    hyphenedCategory,
    hyphenedGroup,
    hyphenedName,
    itemName,
    form,
    onEdit,
    categoryIndex,
    categoryType,
    //budgetNoteMaxLength,
  ) => {
    //const { showNote } = this.state;
    let frequencyDropdown = (
      <FrequencyDropdown
        label={
          <React.Fragment>
            <Content cmsTag="LABEL:Frequency" copytext="Frequency" />
            <Content
              cmsTag="LABEL:-of-"
              copytext=" of "
              tag="span"
              className="sr-only"
            />
            <Content
              cmsTag="LABEL:Item-name:Variable"
              copytext={itemName}
              tag="span"
              className="sr-only"
            />
          </React.Fragment>
        }
        id={hyphenedCategory + `-` + hyphenedName + `-frequency`}
        frequency={form.frequency}
        onChange={this.onChange}
        onBlur={this.onBlur}
        groupClassName="with-button"
      />
    );
    return (
      <Row>
        <Col xs={12} lg={4} className="align-self-center">
          <h4>{itemName}</h4>
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <Currency
            label={
              <React.Fragment>
                <Content
                  cmsTag="LABEL:Item-name:Variable"
                  copytext={itemName}
                  tag="span"
                  className="sr-only"
                />
                <Content cmsTag="LABEL:-Amount" copytext=" Amount" />
              </React.Fragment>
            }
            autoComplete="off"
            field="amount"
            dp={2}
            id={hyphenedCategory + `-` + hyphenedName + `-amount`}
            value={form.amount.value}
            validation={form.amount}
            onChange={this.onChange}
            onBlur={this.onBlur}
            code="GBP"
            groupClassName="with-button"
          />
          {/* Commented out for DAR
          <Button
            className="note-button"
            color={item.note ? 'success' : 'secondary'}
            id={hyphenedCategory + `-` + hyphenedName + `-note-button`}
            onClick={this.onNoteClick}
          >
            <Icon name="NoteIcon" />
            <span className="sr-only">
              <Content
                cmsTag="LABEL:Open-note:Variable"
                template={{
                  copytext: {
                    name: itemName,
                  },
                }}
                copytext="Open note for $[name]"
              />
            </span>
          </Button> */}
        </Col>

        {categoryType === 'other-costs' ? (
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={4}
            className="align-items-center with-delete-button"
          >
            {frequencyDropdown}
            {categoryType === 'other-costs' && (
              <Button
                id={hyphenedCategory + `-` + hyphenedName + `-delete-button`}
                color="danger"
                onClick={this.onItemDelete}
                className="delete-button"
              >
                <Icon name="Delete" />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:Delete-ITEMNAME"
                  copytext={`Delete ` + itemName}
                />
              </Button>
            )}
          </Col>
        ) : (
          <Col xs={12} sm={6} md={6} lg={4}>
            {frequencyDropdown}
          </Col>
        )}

        {/* Commented out for DAR
         {showNote && (
          <Col xs={12} md={12} lg={12}>
            <TextArea
              label={`Note for ` + itemName}
              id={hyphenedCategory + `-` + hyphenedName + `note-textarea`}
              field="noteText"
              onChange={this.onNoteChange}
              onBlur={this.onNoteChange}
              value={form.noteText.value}
              validation={form.noteText}
              maxLength={budgetNoteMaxLength}
            />
            <Button
              id={hyphenedCategory + `-` + hyphenedName + `-save-note-button`}
              className="save-note-button"
              color="light"
              onClick={this.onNoteSave}
            >
              <Content cmsTag="LABEL:Save:Variable" copytext="Save" />
              <span className="sr-only"> {itemName}</span>
            </Button>
          </Col>
        )} */}
      </Row>
    );
  };

  render() {
    const {
      item,
      index,
      category,
      group,
      categoryIndex,
      onEdit,
      categoryType,
    } = this.props;
    const { form } = this.state;

    let itemName;
    if (item.name) {
      itemName = item.name;
    } else {
      itemName = item.type.replace(/-/g, ' ');

      itemName = itemName.charAt(0).toUpperCase() + itemName.slice(1);
    }

    let hyphenedName = itemName.replace(/[^A-Za-z0-9]/g, '-').toLowerCase();
    let hyphenedCategory =
      category.replace(/[^A-Za-z0-9]/g, '-').toLowerCase() || 'group';
    let hyphenedGroup =
      group.replace(/[^A-Za-z0-9]/g, '-').toLowerCase() || 'item';

    let content;

    if (categoryType === 'debts') {
      content = (
        <ItemDebts
          item={item}
          index={index}
          category={category}
          group={group}
          hyphenedCategory={hyphenedCategory}
          hyphenedGroup={hyphenedGroup}
          hyphenedName={hyphenedName}
          itemName={itemName}
          form={form}
          onChange={this.onChange}
          onBlur={this.onBlur}
          categoryIndex={categoryIndex}
          onItemDelete={this.onItemDelete}
          showNote={this.state.showNote}
          onNoteClick={this.onNoteClick}
          onNoteChange={this.onNoteChange}
          onNoteSave={this.onNoteSave}
          budgetNoteMaxLength={Settings.budgetNoteMaxLength}
        />
      );
    } else if (categoryType === 'court_payments') {
      content = (
        <ItemCourtPayments
          item={item}
          index={index}
          category={category}
          group={group}
          hyphenedCategory={hyphenedCategory}
          hyphenedGroup={hyphenedGroup}
          hyphenedName={hyphenedName}
          itemName={itemName}
          form={form}
          onChange={this.onChange}
          onBlur={this.onBlur}
          categoryIndex={categoryIndex}
          onItemDelete={this.onItemDelete}
          showNote={this.state.showNote}
          onNoteClick={this.onNoteClick}
          onNoteChange={this.onNoteChange}
          onNoteSave={this.onNoteSave}
          budgetNoteMaxLength={Settings.budgetNoteMaxLength}
        />
      );
    } else if (categoryType === 'assets') {
      content = (
        <ItemAssets
          item={item}
          index={index}
          category={category}
          group={group}
          hyphenedCategory={hyphenedCategory}
          hyphenedGroup={hyphenedGroup}
          hyphenedName={hyphenedName}
          itemName={itemName}
          form={form}
          onChange={this.onChange}
          onBlur={this.onBlur}
          categoryIndex={categoryIndex}
          onItemDelete={this.onItemDelete}
          showNote={this.state.showNote}
          onNoteClick={this.onNoteClick}
          onNoteChange={this.onNoteChange}
          onNoteSave={this.onNoteSave}
          budgetNoteMaxLength={Settings.budgetNoteMaxLength}
        />
      );
    } else {
      content = this.getItemContent(
        item,
        index,
        category,
        group,
        hyphenedCategory,
        hyphenedGroup,
        hyphenedName,
        itemName,
        form,
        onEdit,
        categoryIndex,
        categoryType,
        Settings.budgetNoteMaxLength,
      );
    }

    return <React.Fragment>{content}</React.Fragment>;
  }
}

Item.propTypes = {
  category: PropTypes.any,
  clearGroupItem: PropTypes.any,
  group: PropTypes.any,
  index: PropTypes.any,
  item: PropTypes.any,
  updateBudgetPlanner: PropTypes.any,
  budgetPlanner: PropTypes.object,
  saveBudgetPlanner: PropTypes.func,
  categoryIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onEdit: PropTypes.func,
  categoryType: PropTypes.string,
  deleteBudgetPlannerCatItem: PropTypes.func,
  saveNote: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Item);
