import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '@myie/interact';
import currency from 'react-currency-formatter';
import symbols from './symbols';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Content } from '@myie/interact-dom';

/**
 * Currency component
 * will display an input field
 * with decimal places according to the currency,
 * if no currency is given, no decimal places will be added
 */
class Currency extends React.Component {
  constructor(props) {
    super(props);
    this.setManageContent();
  }

  componentDidUpdate(prevProps) {
    /* 
      - When rules become dynamic, manageContent should be reinitialized
      - hasUpdated should contain a unique value like date.now()
    */
    const { validation } = this.props;
    if (
      prevProps.validation &&
      prevProps.validation.hasUpdated &&
      prevProps.validation.hasUpdated !== validation.hasUpdated
    ) {
      this.setManageContent();
    }
  }

  setManageContent = () => {
    const {
      label,
      subLabel,
      description,
      placeholder,
      maxLength,
      suffix,
      managedContent,
      validation,
    } = this.props;
    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      placeholder: { defaultValue: placeholder },
      maxLength: { defaultValue: maxLength },
      suffix: { defaultValue: suffix },
      validation: validation,
    });
  };

  render() {
    const { props } = this;

    const {
      dp: dpProp = null,
      suffixIsDiv,
      suffixAlert,
      code,
      children,
      validation = {},
      inputGroupClassName = '',
      labelClassName = '',
      groupClassName = '',
      showErrors = true,
      id,
      field,
      disabled,
      required,
      onChange,
      onBlur,
      maxDigits = 10, //Currency Default restricted to 10 digits, make this - false to remove the restriction
      contentValue,
      managedContent,
      ...rest
    } = props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const { value = '', state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const placeholder = contentValue(ContentType.Text, 'placeholder');
    const maxLength = contentValue(ContentType.Text, 'maxLength');
    const suffix = contentValue(ContentType.Text, 'suffix');

    // added this cludge to find position of symbol and decimal places (when not specified) as I didn't want to duplicate the react-currency-formatter code
    // there may be a better way to do this in the future
    let position = null;
    let dp = dpProp;
    if (code) {
      position = currency({
        quantity: 0,
        currency: code,
        symbol: '!',
        decimal: '.',
      });
      if (!dp) {
        //If dp not specified, use decimal places related to the currency
        let tempExtract =
          position.replace(' ', '').length - position.indexOf('.') - 1;
        dp = position.endsWith('!') ? tempExtract - 1 : tempExtract;
      }
    }
    const dpPat = !dp || dp === 0 ? '' : `([.]{1}[0-9]{0,${dp}})?`;
    const digitPat = !maxDigits ? '*' : `{0,${maxDigits}}`;
    const pattern = `^([0-9]{1,3},?)${digitPat}${dpPat}$`;
    const inputOverride = (e, dp, func) => {
      const { target = {} } = e;
      const { value = '' } = target;
      if (
        dp > 0 &&
        value.indexOf('.') !== -1 &&
        value.length - value.indexOf('.') > dp + 1
      ) {
        return;
      }
      const found = value.match(new RegExp(pattern));
      if (value && !found) {
        return;
      }

      if (value && target.validity && !target.validity.valid) {
        return;
      }
      if (!value || !isNaN(parseFloat(value))) {
        if (func) {
          func(e);
        }
      }
    };

    let fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const symbol = symbols[code] || code;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);

    let dynamicProps = {};
    if (placeholder) {
      dynamicProps.placeholder = placeholder;
    }

    if (maxLength) {
      dynamicProps.maxLength = maxLength;
    }

    let dynamicAriaDescribedBy = {};

    if (suffix && !HasErrors(validation)) {
      dynamicAriaDescribedBy['aria-describedby'] =
        `error-${fieldName.replace('[', '-').replace(']', '')}-validation` +
        ` ${id}-desc`;
    } else {
      if (suffix) {
        dynamicAriaDescribedBy['aria-describedby'] = `${id}-desc`;
      }

      if (!HasErrors(validation)) {
        dynamicAriaDescribedBy['aria-describedby'] = `error-${fieldName
          .replace('[', '-')
          .replace(']', '')}-validation`;
      }
    }

    return (
      <FormGroup
        className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
          isRequired ? 'required-input' : ''
        }`.trim()}
      >
        {label || subLabel || description ? (
          <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
            {label}
            {isRequired ? (
              <React.Fragment>
                <span aria-hidden="true">*</span>
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:-required-field"
                  copytext=" (required field)"
                />
              </React.Fragment>
            ) : (
              ''
            )}{' '}
            {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
            {description.length > 0 ? (
              <span className="description">{description}</span>
            ) : (
              ''
            )}
          </Label>
        ) : (
          ''
        )}
        <InputGroup className={inputGroupClassName}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {position && position.startsWith('!') ? `${symbol}` : ' '}
            </InputGroupText>
          </InputGroupAddon>

          <Input
            onBlur={event => inputOverride(event, dp, onBlur)}
            onChange={event => inputOverride(event, dp, onChange)}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            id={id}
            name={fieldName}
            value={value}
            type="text"
            inputMode={dp === 0 ? 'numeric' : 'decimal'}
            pattern={pattern}
            required={required}
            disabled={disabled}
            {...dynamicProps}
            {...dynamicAriaDescribedBy}
            {...rest}
          />
          {position && position.endsWith('!') ? (
            <InputGroupAddon addonType="append">
              <InputGroupText>{symbol}</InputGroupText>
            </InputGroupAddon>
          ) : (
            ''
          )}
          {suffix ? (
            suffixAlert ? (
              <div
                className="alert alert-info mt-3 w-auto d-inline-block"
                id={`${id}-desc`}
              >
                <p>{suffix}</p>
              </div>
            ) : suffixIsDiv ? (
              <div className="suffix" id={`${id}-desc`}>
                {suffix}
              </div>
            ) : (
              <p className="suffix" id={`${id}-desc`}>
                {suffix}
              </p>
            )
          ) : (
            ''
          )}
          {children}
          {showErrors ? (
            <Errors
              contentValue={contentValue}
              validation={validation}
              field={field}
            />
          ) : (
            ''
          )}
        </InputGroup>
      </FormGroup>
    );
  }
}

Currency.propTypes = {
  managedContent: PropTypes.func.isRequired,
  contentValue: PropTypes.func.isRequired,
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Currency code
   */
  code: PropTypes.string,
  /**
   * Currency description
   */
  description: PropTypes.string,
  /**
   * Input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * decimal places
   */
  dp: PropTypes.number,
  /**
   * Maximum number of digits
   */
  maxDigits: PropTypes.number,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Currency ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Style class name for input group
   */
  inputGroupClassName: PropTypes.string,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * onBlur function
   */
  onBlur: PropTypes.func.isRequired,
  /**
   * onChange function
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Max length
   */
  maxLength: PropTypes.string,
  /**
   * Input validation
   */
  validation: PropTypes.object,
  /**
   * Suffix alert
   */
  suffixAlert: PropTypes.bool,
  /**
   * Suffix div
   */
  suffixIsDiv: PropTypes.bool,
};

export default contentManaged(Currency);
