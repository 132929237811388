import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { Currency, Content /*, TextArea*/ } from '@myie/interact-dom';
import { Icon } from '@myie/interact-dom';

function ItemAssets(props) {
  const {
    //item,
    index,
    hyphenedName,
    itemName,
    form,
    onChange,
    onBlur,
    onItemDelete,
    // showNote,
    // onNoteClick,
    // onNoteChange,
    // onNoteSave,
    // budgetNoteMaxLength,
  } = props;

  return (
    <Row>
      <Col xs={12} sm={6} md={6} lg={6} className="align-self-center">
        <h4>{itemName}</h4>
      </Col>
      <Col xs={11} sm={5} md={5} lg={5}>
        <Currency
          label={
            <React.Fragment>
              <Content cmsTag="GLOBAL:Value" copytext="Value" />
              <Content
                cmsTag="GLOBAL:-of-"
                copytext=" of "
                tag="span"
                className="sr-only"
              />
              <Content
                cmsTag="GLOBAL:Item-name:Variable"
                copytext={itemName}
                tag="span"
                className="sr-only"
              />
            </React.Fragment>
          }
          groupClassName="with-button"
          autoComplete="off"
          field="amount"
          dp={2}
          id={`assets-` + hyphenedName + `-value-` + index}
          value={form.amount.value}
          validation={form.amount}
          onChange={onChange}
          onBlur={onBlur}
          code="GBP"
          required
        />
        {/* Commented out for DAR
        <Button
          className="note-button"
          color={item.note ? 'success' : 'secondary'}
          id={`assets-` + hyphenedName + `-note-button-` + index}
          onClick={onNoteClick}
        >
          <Icon name="NoteIcon" />
          <span className="sr-only">
            <Content
              cmsTag="LABEL:Open-note:Variable"
              template={{
                copytext: {
                  name: itemName,
                },
              }}
              copytext="Open note for $[name]"
            />
          </span>
        </Button> */}
      </Col>
      <Col
        xs={1}
        sm={1}
        md={1}
        lg={1}
        className="align-items-center with-delete-button"
      >
        <Button
          id={`assets-` + hyphenedName + `-delete-button-` + index}
          color="danger"
          className="delete-button"
          onClick={onItemDelete}
        >
          <Icon name="Delete" />
          <Content
            tag="span"
            tagClassName="sr-only"
            cmsTag="GLOBAL:Delete-ITEMNAME"
            copytext={`Delete ` + itemName}
          />
        </Button>
      </Col>
      {/* Commented out for DAR
      {showNote && (
        <Col xs={12} md={12} lg={12}>
          <TextArea
            label={`Note for ` + itemName}
            id={`assets-` + hyphenedName + `-note-textarea-` + index}
            field="noteText"
            onChange={onNoteChange}
            onBlur={onNoteChange}
            value={form.noteText.value}
            validation={form.noteText}
            maxLength={budgetNoteMaxLength}
          />
          <Button
            id={`assets-` + hyphenedName + `-save-note-button-` + index}
            className="save-note-button"
            color="light"
            onClick={onNoteSave}
          >
            <Content cmsTag="LABEL:Save:Variable" copytext="Save" />
            <span className="sr-only"> {itemName}</span>
          </Button>
        </Col>
      )} */}
    </Row>
  );
}

ItemAssets.propTypes = {
  category: PropTypes.any,
  group: PropTypes.any,
  index: PropTypes.any,
  item: PropTypes.any,
  categoryIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hyphenedName: PropTypes.any,
  itemName: PropTypes.any,
  form: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onItemDelete: PropTypes.func,
  showNote: PropTypes.bool,
  onNoteClick: PropTypes.func,
  onNoteChange: PropTypes.func,
  onNoteSave: PropTypes.func,
  budgetNoteMaxLength: PropTypes.number,
};

export default ItemAssets;
