import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';

class CreationSuccess extends Component {
  componentDidMount() {
    const { resetCreateNoticeToWithdraw } = this.props;
    resetCreateNoticeToWithdraw();
  }

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Withdraw to your nominated account
          - no account set up"
          metaDescription="Withdraw to your nominated account
          - no account set up"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Unavailable:h1"
          copytext="Withdraw to your nominated account"
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Unavailable:p1"
          copytext='There is no nominated account set up in your name for withdrawals. Please click on "Add nominated account" to set one up.'
        />
        <div className="form-button-group">
          <Link
            id="no-nominated-account-add-new"
            className="btn btn-primary"
            role="button"
            to={`/nominated-account-for-withdrawals/add/${
              match.params.page ? match.params.page : ''
            }`}
          >
            <Content
              cmsTag="GLOBAL:Add-nominated-account"
              copytext="Add nominated account"
            />
          </Link>
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

CreationSuccess.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  match: PropTypes.any,
  account: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(CreationSuccess);
