import React, { Component } from 'react';
import {
  Content,
  FormattedCurrency,
  FormattedDate,
  Switch,
  AppMeta,
} from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-brand-savings-servicing';
import { CONTACTS } from '@myie/interact-local-dom';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: '',
      disabled: false,
    };
  }
  submit = () => {
    const { onSubmitWithdrawal } = this.props;
    if (this.state.disabled) {
      return;
    }
    // this.setState({ disabled: true });
    onSubmitWithdrawal();
  };

  editTransfer = () => {
    const { setStage } = this.props;
    let stateData = {
      url: {
        pageStage: 'createTransfer',
      },
    };
    this.props.updateUrl(stateData);
    setStage('createTransfer');
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { externalTransfer = {} } = nextProps;

    if (externalTransfer && externalTransfer.ResponseStatus === 0) {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return null;
  };

  render() {
    const { account = {}, actionDate, data } = this.props;
    let amount = data && data['amount'] ? data['amount'] : '';
    const closeOption = false;
    let reference = data && data['reference'] ? data['reference'] : '';
    let payeeAccount = data && data['payeeAccount'] ? data['payeeAccount'] : '';
    let payeeName = data && data['payeeName'] ? data['payeeName'] : '';

    const {
      CurrencyCode,
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    const balance = AvailableBalance + PendingBalance;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Transfer to another ${CONTACTS.companyNameShorthand} member's savings account step 2`}
          metaDescription={`Transfer to another ${CONTACTS.companyNameShorthand} member's savings account step 2`}
        />
        <Switch
          id="create-transfer-review-alert"
          value={this.state.message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          scrolltotop={true}
          template={{
            failed: {
              companyNameShorthand: CONTACTS.companyNameShorthand,
            },
          }}
          contents={{
            failed: {
              defaultValue:
                "Transfer to another $[companyNameShorthand] member's savings account failed",
            },
          }}
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Transfer-to-another-account:Create-transfer:Confirmation:p1"
          copytext="Please check that we have the correct details before continuing."
        />
        <div className="alert alert-info">
          <Content
            tag="p"
            tagID="transfer-details-note"
            cmsTag="Savings-servicing:Transfer-to-another-account:Create-transfer:Confirmation:alert-1:p1"
            copytext="Please note:"
          />
          <ul className="transfer-details-note mb-0">
            <li>
              <Content
                cmsTag="Savings-servicing:Transfer-to-another-account:Create-transfer:Confirmation:alert-1:li-1"
                copytext="Any transfers made on a weekend or a bank holiday will not reach your account until the next working day. Please do not attempt the transfer again unless advised to do so."
              />
            </li>
            <li>
              <Content
                cmsTag="Savings-servicing:Transfer-to-another-account:Create-transfer:Confirmation:alert-1:li-2"
                copytext="If you have decided to transfer your full balance and close the account, you will also transfer any accrued interest."
              />
            </li>
          </ul>
        </div>
        <div className="confirm-transfer-to-another-account-details pt-5">
          <Row tag="dl" title="Transfer details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:From" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}&nbsp;
              <span className="d-block">{account.AccountNumber}</span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:To" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {payeeName}&nbsp;
              <span className="d-block">{payeeAccount}</span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {closeOption ? (
                <React.Fragment>
                  <FormattedCurrency
                    quantity={balance}
                    currency={CurrencyCode}
                  />
                  <Content
                    cmsTag="GLOBAL:-Full-balance"
                    copytext=" (Full balance)"
                  />
                </React.Fragment>
              ) : (
                <FormattedCurrency
                  quantity={amount.replace(/,/g, '')}
                  currency={CurrencyCode}
                />
              )}
            </Col>
            {reference ? (
              <React.Fragment>
                <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                  <Content cmsTag="GLOBAL:Reference" copytext="Reference" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                  {reference}
                </Col>
              </React.Fragment>
            ) : (
              ''
            )}
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Transfer-date" copytext="Transfer date" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              <FormattedDate date={actionDate} format="Do MMMM YYYY" />
            </Col>
          </Row>
        </div>

        <div className="form-button-group">
          <Button
            id="confirm-transfer"
            type="submit"
            onClick={this.submit}
            disabled={this.state.disabled}
            color="primary"
          >
            <Content
              cmsTag="GLOBAL:Confirm-transfer"
              copytext="Confirm transfer"
            />
          </Button>
          <Button
            id="edit-transfer-details"
            onClick={this.editTransfer}
            color="light"
            disabled={this.state.disabled}
          >
            <Content
              cmsTag="GLOBAL:Edit-transfer-details"
              copytext="Edit transfer details"
            />
          </Button>

          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  updateUrl: PropTypes.any,
  createNoticeParam: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  createResponse: PropTypes.any,
  onSubmitWithdrawal: PropTypes.any,
  createNoticeToWithdraw: PropTypes.func,
  account: PropTypes.any,
  setStage: PropTypes.any,
  form: PropTypes.any,
  actionDate: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Confirmation));
