import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { form } from './PersonalDetailsFormObj';
import {
  Text,
  Content,
  LinkTag,
  FormErrorList,
  DateMonthYear,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Validate } from '@myie/interact';

class PersonalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      b_dayRulesFormat: { ...form.b_day.rules.format },
      form: form,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.props.saveData(name, value);
    this.setState({ ...this.state, form });
  };

  updateValidation = (field, validation) => {
    let form = this.state.form;
    form[field] = validation;
    form = Validate.input(field, validation.value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { data, updateUrl } = this.props;
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    let stateData = {
      formData: data,
      url: {
        pageStage: 'accountDetails',
      },
    };
    updateUrl(stateData);
  };

  render() {
    const { form, b_dayRulesFormat } = this.state;
    const { parentPage = 'register' } = this.props;
    let disabledForm = false;
    if (this.props.disableForm || this.props.isFetching) {
      disabledForm = true;
    }
    let error;
    return (
      <React.Fragment>
        {error ? error : ''}
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          noValidate={true}
          autoComplete="off"
          id="authentication-personal-details"
          onSubmit={this.submit}
        >
          <Text
            label="First name"
            cmsTag="GLOBAL:First-name"
            id="firstname"
            field="firstname"
            maxLength={'50'}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.firstname}
            disabled={disabledForm}
          />

          <Text
            label="Last name"
            cmsTag="GLOBAL:Last-name"
            id="lastname"
            field="lastname"
            maxLength={'50'}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.lastname}
            disabled={disabledForm}
          />

          <DateMonthYear
            label="Date of birth"
            cmsTag="GLOBAL:Date-of-birth"
            id="b_day"
            field="b_day"
            groupClassName="half-field"
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.b_day}
            disabled={disabledForm}
            updateValidation={this.updateValidation}
            validationRulesFormat={b_dayRulesFormat}
          />

          <Text
            label="Postcode"
            cmsTag="GLOBAL:Home-postcode"
            id="postcode"
            field="postcode"
            groupClassName="half-field"
            maxLength={'8'}
            onChange={this.onChange}
            onBlur={this.onBlur}
            validation={form.postcode}
            disabled={disabledForm}
          />
          <div className="form-button-group">
            {parentPage === 'register' ? (
              <React.Fragment>
                <Button
                  id="register-personal-details-submit"
                  type="submit"
                  color="primary"
                  disabled={disabledForm}
                >
                  <Content
                    cmsTag="Authentication:Shared:Personal-details:Continue"
                    copytext="Continue"
                  />
                </Button>
                <LinkTag
                  id="personal-details-cancel"
                  className={`btn btn-secondary ${
                    disabledForm ? 'disabled' : ''
                  }`}
                  role="button"
                  href={CONTACTS.externalHomeURL}
                >
                  <Content
                    cmsTag="GLOBAL:Back-to-home-page"
                    copytext="Back to home page"
                  />
                </LinkTag>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  id="forgotten-creds-personal-details-submit"
                  type="submit"
                  color="primary"
                  disabled={disabledForm}
                >
                  <Content
                    cmsTag="Authentication:Shared:Personal-details:Continue"
                    copytext="Continue"
                  />
                </Button>
                <Link
                  id="personal-details-cancel"
                  className={`btn btn-secondary ${
                    disabledForm ? 'disabled' : ''
                  }`}
                  role="button"
                  to="/sign-in/step-1"
                >
                  <Content
                    cmsTag="GLOBAL:Back-to-sign-in"
                    copytext="Back to sign in"
                  />
                </Link>
              </React.Fragment>
            )}
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

PersonalDetails.propTypes = {
  saveData: PropTypes.any,
  refreshUrl: PropTypes.any,
  urlParams: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  updateUrl: PropTypes.any,
  setStage: PropTypes.any,
  data: PropTypes.object,
  verify: PropTypes.any,
  disableForm: PropTypes.bool,
  isFetching: PropTypes.bool,
  message: PropTypes.string,
  parentPage: PropTypes.string,
  contacts: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PersonalDetails),
);
