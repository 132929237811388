import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-accounts';
import { Utility } from '@myie/interact';
import { Content, NoMatch, Markdown, Check } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Row, Col, Button } from 'reactstrap';
import { Session } from '@myie/interact';

class NominatedAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        fraud_confirm: {
          value: false,
          rules: {
            title: 'terms and conditions',
            stop: true,
            required: {
              message: `Please confirm that you have read and agreed to ${CONTACTS.companyNamePosessive} Terms & Conditions and Privacy Policy.`,
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && match.params.page && match.params.id) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        default:
      }
    }
  };

  onChangeAccount = e => {
    const { fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();
    const { value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ account });
    }
  };

  static getDerivedStateFromProps(props) {
    if (props.location) {
      return { url: props.location };
    }
    return null;
  }

  render() {
    const { accounts } = this.props;

    let account = null;
    const customer = Session.customer();
    const thisProps = this.props;
    let page = '';
    if (thisProps.match && thisProps.match.params.page) {
      page = thisProps.match.params.page;
    }

    if (
      thisProps.match &&
      thisProps.match.params.id &&
      accounts &&
      accounts.Accounts
    ) {
      account = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            thisProps.match.params.id
          );
        }
        return false;
      });
      if (!account) {
        return <NoMatch />;
      }
    }

    return (
      <div id="nominated-account">
        {accounts ? (
          <React.Fragment>
            <Content
              tagID="nominated-account-title"
              tag="h1"
              cmsTag="Accounts:Nominated-account:h1"
              copytext="Withdraw to your nominated account"
            />
            <div className="alert alert-info alert-dismissible">
              <Content
                tag="h2"
                tagID="fraud-alert-title"
                cmsTag="Accounts:Nominated-account:alert-1:h2-1"
                copytext="Protect yourself from fraud"
              />
              <Content
                tag="p"
                cmsTag="Accounts:Nominated-account:alert-1:p1"
                copytext="Before you proceed please take a moment to ensure that:"
              />
              <ul>
                <li>
                  <Markdown
                    cmsTag="Accounts:Nominated-account:alert-1:li-1"
                    template={{
                      markdown: {
                        companyName: CONTACTS.companyName,
                      },
                    }}
                    markdown={`You haven't been called by someone asking you to move your money; even by someone claiming to be from $[companyName].`}
                  />
                </li>
                <li>
                  <Markdown
                    cmsTag="Accounts:Nominated-account:alert-1:li-2"
                    markdown={`You are happy with the reason for this payment and have read the relevant scam advice <a href="www.friendsagainstscams.org.uk">www.friendsagainstscams.org.uk</a>.`}
                  />
                </li>
              </ul>
              <Markdown
                cmsTag="Accounts:Nominated-account:alert-1:p2"
                template={{
                  markdown: {
                    phone: CONTACTS.phone,
                    phoneLink: CONTACTS.phoneLink,
                  },
                }}
                markdown={`\nIf you have any concerns, please do not complete this transaction and contact us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
              />
            </div>
            <Check
              id={`fraud-confirm`}
              autoFocus={false}
              cmsTag="GLOBAL:Fraud-withdrawal-confirmation"
              label={
                'I have considered the information on this page and am happy to proceed with this withdrawal.'
              }
              field="fraud_confirm"
              //checked={fraud_confirm.value}
              value={'false'}
              onClick={this.onClick}
              //validation={fraud_confirm}
            />
            <section>
              <Content
                tag="h2"
                cmsTag="Accounts:Nominated-account:h2-1"
                copytext="Withdrawal details"
              />
              <Markdown
                cmsTag="Accounts:Nominated-account:alert-1:section-1"
                markdown={`\nWe will need some information to withdraw to a nominated account.\n\nSome products are subject to specific conditions such as notice periods and withdrawal restrictions.\n\nIf you break these terms, you may receive interest penalties or a forfeit of bonus. If you do not have enough interest built up to pay this, the rest will be taken from your balance when you close your account or when your interest is next due to be added to your balance.\n\nPlease check our savings account information or the Closed Feature Guide on our website or contact us for more information.`}
              />
              <RedirectByAccountDropdown
                accounts={accounts}
                extendedId="withdraw-from"
                label="Account to withdraw from"
                baseUrl={`/accounts/nominated-account/${page}/`}
                defaultSelect={`${
                  account && account.AccountKey ? account.AccountKey.Key : ''
                }`}
                id="nominated-accounts"
                onChangeAccount={this.onChangeAccount}
                filter="all"
              />
            </section>
            {account ? (
              <React.Fragment>
                <section>
                  <Content
                    tag="h2"
                    tagClassName="float-start clear-both w-100"
                    cmsTag="Accounts:Nominated-account:h2-2"
                    copytext="Nominated account for withdrawals"
                  />
                  <Row tag="dl" title="Nominated account details">
                    <Col tag="dt" xs={12} md={4} lg={3}>
                      <Content
                        cmsTag="GLOBAL:Name-of-account-holder"
                        copytext="Name of account holder"
                      />
                    </Col>
                    <Col
                      id="nominated-account-account-holder-name"
                      tag="dd"
                      xs={6}
                      md={8}
                      lg={9}
                    >
                      {customer.Username ? customer.Name : ''}
                    </Col>

                    <Col tag="dt" xs={12} md={4} lg={3}>
                      <Content
                        cmsTag="GLOBAL:Account-number"
                        copytext="Account number"
                      />
                    </Col>
                    <Col
                      id="nominated-account-number"
                      tag="dd"
                      xs={6}
                      md={8}
                      lg={9}
                    >
                      {account &&
                      account.ExtendedProperties &&
                      account.ExtendedProperties.NominatedAccountNumber
                        ? account.ExtendedProperties.NominatedAccountNumber
                        : ''}
                    </Col>
                    <Col tag="dt" xs={12} md={4} lg={3}>
                      <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
                    </Col>
                    <Col id="nominated-sort-code" tag="dd" xs={6} md={8} lg={9}>
                      {account &&
                      account.ExtendedProperties &&
                      account.ExtendedProperties.NominatedSortCode
                        ? account.ExtendedProperties.NominatedSortCode
                        : ''}
                    </Col>
                    <Col tag="dt" xs={12} md={4} lg={3}>
                      <Content cmsTag="GLOBAL:Reference" copytext="Reference" />
                      <Content
                        tag="small"
                        cmsTag="Accounts:Nominated-account:Nominated-account-reference-explanation"
                        copytext=" (this is the reference that will show in transactions)"
                      />
                    </Col>
                    <Col id="nominated-sort-code" tag="dd" xs={6} md={8} lg={9}>
                      <Content
                        cmsTag="GLOBAL:Nominated-account-for-withdrawal"
                        copytext="Nominated account for withdrawal"
                      />
                    </Col>
                  </Row>
                </section>
                <div className="form-button-group">
                  <Link
                    id="change-details-button"
                    className="btn btn-primary"
                    role="button"
                    to={`/messaging-twoway/new/${Utility.hexEncode(
                      account.AccountKey ? account.AccountKey.Key : '',
                    )}/7`}
                  >
                    <Content
                      cmsTag="GLOBAL:Edit-nominated-account"
                      copytext="Edit nominated account"
                    />
                  </Link>
                  <Button
                    id="back-to-accounts-button"
                    color="secondary"
                    onClick={() => this.changeUrl()}
                  >
                    <Content
                      cmsTag="GLOBAL:Back-to-accounts"
                      copytext="Back to accounts"
                    />
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          ''
        )}
      </div>
    );
  }
}

NominatedAccount.propTypes = {
  fetchAccountsIfNeeded: PropTypes.any,
  accounts: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NominatedAccount);
