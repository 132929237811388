import React from 'react';
import Savings from './Savings';
import { NoticeToWithdrawals } from './NoticeToWithdrawal/Routes';
import MakeWithdrawalRoute from './MakeWithdrawal/Routes';
import { TransferToAnother } from './TransferToAnotherAccount/Routes';
import { NominatedAccount } from './NominatedAccount/Routes';
import { TransferRoute } from './Transfer/Routes';
import { MakeADepositRoute } from './MakeADeposit/Routes';
import ShortApply from './shortApply/ShortApply';
import { Route } from 'react-router-dom';
import MoneyToolsRoutes from './moneyTools/Routes';

const Routes = [
  <Route
    key="/savings-servicing"
    path="/savings-servicing"
    component={Savings}
  />,
  <Route
    key="/notice-to-withdraw"
    path="/notice-to-withdraw"
    component={NoticeToWithdrawals}
  />,
  <Route
    key="/make-withdrawal"
    path="/make-withdrawal"
    component={MakeWithdrawalRoute}
  />,
  <Route
    key="/nominated-account"
    path="/nominated-account"
    component={NominatedAccount}
  />,
  <Route
    key="/transfer-to-another"
    path="/transfer-to-another"
    component={TransferToAnother}
  />,
  <Route
    key="/internal-transfer"
    path="/internal-transfer"
    component={TransferRoute}
  />,
  <Route
    key="/make-a-deposit"
    path="/make-a-deposit"
    component={MakeADepositRoute}
  />,
  <Route key="/short-apply" path="/short-apply" component={ShortApply} />,
  <Route key="/money-tools" path="/money-tools" component={MoneyToolsRoutes} />,
];

export default Routes;
