import { Connection, TwoFactor } from '@myie/interact';
import {
  EXTERNAL_TRANSFER_MONEY_REQUEST,
  EXTERNAL_TRANSFER_MONEY_SUCCESS,
  EXTERNAL_TRANSFER_MONEY_FAILURE,
  EXTERNAL_TRANSFER_RESET,
} from './externalTransferTypes';

export const resetExternalTransfer = () => ({
  type: EXTERNAL_TRANSFER_RESET,
  isFetching: false,
  externalTransferResponse: null,
  error: null,
});

export const requestExternalTransferMoney = () => ({
  type: EXTERNAL_TRANSFER_MONEY_REQUEST,
  isFetching: true,
  error: null,
});

export const externalTransferMoneySuccess = json => ({
  type: EXTERNAL_TRANSFER_MONEY_SUCCESS,
  externalTransferResponse: json,
  isFetching: false,
  error: null,
});

export const externalTransferMoneyFailure = error => ({
  type: EXTERNAL_TRANSFER_MONEY_FAILURE,
  isFetching: false,
  error: error.message,
});

export const externalTransferMoney = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(requestExternalTransferMoney());
    return fetch(Connection.baseUrl('movemoney') + `v1.0/externaltransfer`, {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(externalTransferMoneySuccess(json)))
      .catch(error => dispatch(externalTransferMoneyFailure(error)));
  });
};
