const appUtility = (function() {
  const getPayload = (formData, selectedProduct, userDetails) => {
    let accountDetails = null;

    const obj = {
      Application: {
        DepositFundsOption: formData?.depositMethod,
        ProductTypeCode: selectedProduct?.Product_Code,
        DepositAmount: {
          Amount: parseInt(
            formData?.depositAmount.toString().replace(/,/g, ''),
          ),
          ExtendedProperties: [{}, {}, {}],
        },
        PayInterestIntoAnotherAccount: accountDetails?.interestPaymentLocation,
        InterestPaymentFrequency: selectedProduct?.Interest_Frequency,
        firstname: userDetails.FirstName,
        lastname: userDetails.Surname,
        DOB: userDetails?.ExtendedProperties?.DateOfBirth,
        postcode: userDetails.Postcode,
        ExtendedProperties: [{}, {}, {}],
      },
      ExtendedProperties: [
        {
          Name: 'ApplicationType',
          Value: 'ShortApply',
        },
      ],
    };

    return obj;
  };

  return {
    getPayload: getPayload,
  };
})();

export default appUtility;
