import {
  RESET_VIEW_GOALS,
  VIEW_GOALS_REQUEST,
  VIEW_GOALS_SUCCESS,
  VIEW_GOALS_FALIURE,
  RESET_CREATE_GOALS,
  CREATE_GOALS_REQUEST,
  CREATE_GOALS_SUCCESS,
  CREATE_GOALS_FALIURE,
  RESET_UPDATE_GOALS,
  UPDATE_GOALS_REQUEST,
  UPDATE_GOALS_SUCCESS,
  UPDATE_GOALS_FALIURE,
  RESET_DELETE_GOALS,
  DELETE_GOALS_REQUEST,
  DELETE_GOALS_SUCCESS,
  DELETE_GOALS_FALIURE,
  PRESERVE_GOALS_STATE,
  RESET_PRESERVE_GOALS_STATE,
  SAVINGS_GOALS_BANNER_MESSAGW_ARRAY,
  SET_BACK_PAGE_MODE,
} from '../actions/savingsGoalsTypes';

const viewGoalsDefinition = (state = {}, action) => {
  switch (action.type) {
    case SAVINGS_GOALS_BANNER_MESSAGW_ARRAY:
      return {
        ...state,
        bannerMessageArray: action.bannerMessageArray,
      };
    case VIEW_GOALS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case VIEW_GOALS_SUCCESS:
      return {
        ...state,
        viewGoalDetails: action.viewGoalsResponse,
        isFetching: action.isFetching,
      };
    case VIEW_GOALS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_VIEW_GOALS:
      return {
        ...state,
        viewGoalDetails: null,
        isFetching: false,
        error: null,
      };
    ///
    case CREATE_GOALS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case CREATE_GOALS_SUCCESS:
      return {
        ...state,
        createGoalDetails: action.createGoalsResponse,
        isFetching: action.isFetching,
      };
    case CREATE_GOALS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_CREATE_GOALS:
      return {
        ...state,
        createGoalDetails: null,
        isFetching: false,
        error: null,
      };
    ///
    case UPDATE_GOALS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case UPDATE_GOALS_SUCCESS:
      return {
        ...state,
        updateGoalDetails: action.updateGoalsResponse,
        isFetching: action.isFetching,
      };
    case UPDATE_GOALS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_UPDATE_GOALS:
      return {
        ...state,
        updateGoalDetails: null,
        isFetching: false,
        error: null,
      };
    ///

    case PRESERVE_GOALS_STATE:
      return {
        ...state,
        preservedSavingsGoalState: action.preservedSavingsGoalState,
        isFetching: action.isFetching,
      };
    case RESET_PRESERVE_GOALS_STATE:
      return {
        ...state,
        preservedSavingsGoalState: null,
        isFetching: false,
        error: null,
      };
    ///
    case DELETE_GOALS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case DELETE_GOALS_SUCCESS:
      return {
        ...state,
        deleteGoalDetails: action.deleteGoalsResponse,
        isFetching: action.isFetching,
      };
    case DELETE_GOALS_FALIURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_DELETE_GOALS:
      return {
        ...state,
        deleteGoalDetails: null,
        isFetching: false,
        error: null,
      };
    case SET_BACK_PAGE_MODE:
      return {
        ...state,
        backPageMode: action.pageMode,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default viewGoalsDefinition;
