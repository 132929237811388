import React, { Component } from 'react';
import { BreadcrumbItem } from 'reactstrap';
import { storage } from '../storageName';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import { CONSTANTS } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Create from './Create';
import CardDetails from './CardDetails';
import CreationSuccess from './CreationSuccess';
import CreationFailed from './CreationFailed';
//port dateValidation from '../../utility';
import queryString from 'query-string';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import { Session } from '@myie/interact';

class MakeADeposit extends Component {
  constructor(props) {
    super(props);
    const { fetchAccountsIfNeeded, accounts } = this.props;
    this.state = this.initialState(this.state);
    if (!accounts) {
      fetchAccountsIfNeeded();
    }
  }

  initialState = () => {
    const {
      location,
      urlParams,
      refreshUrl,
      match,
      accounts,
      stateData,
    } = this.props;
    const currentState = queryString.parse(location.search);
    const stateList = ['createDeposit', 'ReviewDeposit', 'Completed', 'failed'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'createDeposit';

    if (!hasStage) {
      stage = 'createDeposit';
      refreshUrl();
    }

    stage =
      currentState && currentState.page && currentState.page === 'success'
        ? 'Completed'
        : currentState &&
          currentState.page &&
          currentState.page.toLowerCase() === 'failed'
        ? 'failed'
        : stage;
    let account;
    if (accounts && match.params.id) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    let data =
      this.props.stateData && this.props.stateData.formData
        ? this.props.stateData.formData
        : {};
    return {
      account: account,
      stage: stage,
      worldpayUrl: '',
      data: data,
      responseData:
        this.props.stateData && this.props.stateData.depositData
          ? this.props.stateData.depositData
          : null,
      form: {},
    };
  };

  componentWillUnmount() {
    const { resetMakeADeposit } = this.props;
    resetMakeADeposit();
  }

  componentDidMount() {
    const { resetMakeADeposit } = this.props;
    const { location } = this.props;
    const currentState = queryString.parse(location.search);
    const stage =
      currentState && currentState.page && currentState.page === 'success'
        ? 'Completed'
        : currentState &&
          currentState.page &&
          currentState.page.toLowerCase() === 'failed'
        ? 'failed'
        : this.state.stage;
    let stateData = {
      url: {
        pageStage: stage,
      },
    };
    this.props.updateUrl(stateData);
    resetMakeADeposit();
  }

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  componentDidUpdate(prevProps) {
    const { match, accounts } = this.props;
    const { account } = this.state;
    if (
      match.params.id &&
      (!account ||
        Utility.hexEncode(account.AccountKey.Key) !== match.params.id ||
        prevProps.accounts !== accounts)
    ) {
      const currentAccount = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      if (currentAccount) {
        this.setState({ ...this.state, account: currentAccount });
      }
    }
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const { makeADepositWorldpay, resetMakeADeposit } = nextProps;
    let newForm = state.accountForm;
    const { data, account } = state;

    if (
      makeADepositWorldpay &&
      makeADepositWorldpay.Reply &&
      makeADepositWorldpay.Reply.OrderStatus &&
      makeADepositWorldpay.Reply.OrderStatus.Reference &&
      makeADepositWorldpay.Reply.OrderStatus.Reference.Value &&
      !state.worldpayUrl
    ) {
      resetMakeADeposit();
      const redirectUrl =
        makeADepositWorldpay.Reply.OrderStatus.Reference.Value +
        '&successURL=' +
        window.location.protocol.slice(0, -1) +
        '%3A%2F%2F' +
        window.location.host +
        `%2Fmake-a-deposit%2FmoveMoney%2F${Utility.hexEncode(
          account.AccountKey.Key,
        )}%3Fpage%3Dsuccess%26refID%3D` +
        makeADepositWorldpay.Reply.OrderStatus.Reference.Id +
        '&failureURL=' +
        window.location.protocol.slice(0, -1) +
        '%3A%2F%2F' +
        window.location.host +
        `%2Fmake-a-deposit%2FmoveMoney%2F${Utility.hexEncode(
          account.AccountKey.Key,
        )}%3Fpage%3Dfailed%26refID%3D` +
        makeADepositWorldpay.Reply.OrderStatus.Reference.Id +
        '%26amount%3D' +
        data.amount.replace(/,/g, '') +
        '&cancelURL=' +
        window.location.protocol.slice(0, -1) +
        '%3A%2F%2F' +
        window.location.host +
        '%2Fmake-a-deposit%2FmoveMoney%3Fpage%3Dcancel';

      window.location.assign(redirectUrl);

      return {
        worldpayUrl: newForm,
      };
    }

    return null;
  };

  accountList = accounts => {
    if (accounts) {
      return accounts.filter(account => {
        const {
          ExtendedProperties: { AccountMode, DepositsAllowed } = {},
          AccountStatus,
        } = account;
        return (
          (AccountMode === CONSTANTS.ACCOUNT_TRANSACT ||
            AccountMode === CONSTANTS.ACCOUNT_VIEW_ONLY) &&
          AccountStatus !== 'Closed' &&
          DepositsAllowed
        );
      });
    }
    return [];
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  submitForm = (stage, form) => {
    if (stage === 'createDeposit') {
      const { resetMakeWithdrawal } = this.props;
      resetMakeWithdrawal();
    }
    let stateData = {
      formData: this.state.data,
      url: {
        pageStage: stage,
      },
    };
    this.props.updateUrl(stateData);
    this.setState({ ...this.state, stage, form, message: null });
  };

  onSubmitWithdrawal = () => {
    const { data, account } = this.state;
    const { makeADeposit } = this.props;
    const customer = Session.customer();
    makeADeposit({
      CustomerId: customer.UserId,
      Amount: data.amount,
      AccountKey: account.AccountKey,
    });
  };

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // select current stage
  currentStage = account => {
    switch (this.state.stage) {
      case 'createDeposit':
        return (
          <Create
            setForm={this.setForm}
            accountForm={this.state.accountForm}
            callAccountValidations={this.callAccountValidations}
            match={this.props.match}
            submitForm={this.onSubmitWithdrawal}
            onChangeAccount={this.onChangeAccount}
            saveData={this.saveData}
            data={this.state.data}
            setStateDynamic={this.setStateDynamic}
            accountList={this.accountList}
            account={account}
            updateAccountKey={this.updateAccountKey}
          />
        );
      case 'ReviewDeposit':
        return (
          <CardDetails
            form={this.state.form}
            updateUrl={this.props.updateUrl}
            combinedForm={this.state.combinedForm}
            setStateDynamic={this.setStateDynamic}
            data={this.state.data}
            setStage={this.setStage}
            account={account}
          />
        );
      default:
    }
  };

  render() {
    const { stage, account, responseData, responseStatus } = this.state;
    const { accounts, updateUrl, resetComponentState, urlParams } = this.props;

    if (responseStatus === 'Failed') {
      return <Redirect to="/network-error" />;
    }
    if (!accounts) {
      return null;
    }

    let condition = this.state.stage === 'ReviewDeposit';

    if (stage === 'Completed') {
      return (
        <CreationSuccess
          account={account}
          data={this.state.data}
          setStage={this.setStage}
          depositResponse={responseData}
          updateUrl={updateUrl}
          resetComponentState={resetComponentState}
          responseUrlParams={urlParams}
        />
      );
    } else if (stage === 'failed') {
      return (
        <CreationFailed
          account={account}
          data={this.state.data}
          setStage={this.setStage}
          depositResponse={responseData}
          updateUrl={updateUrl}
          resetComponentState={resetComponentState}
          responseUrlParams={urlParams}
        />
      );
    }

    return (
      <React.Fragment>
        <h1>
          <Content
            cmsTag="Savings-servicing:Make-a-deposit:Make-a-deposit:Make-a-deposit:h1"
            copytext="Make a deposit"
          />
        </h1>
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={stage === 'createDeposit'}
              className={condition ? 'completed' : ''}
            >
              {stage === 'createDeposit' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {stage === 'ReviewDeposit' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'ReviewDeposit'}>
              {stage === 'ReviewDeposit' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        {this.currentStage(account)}
      </React.Fragment>
    );
  }
}

MakeADeposit.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeADeposit: PropTypes.func,
  holidays: PropTypes.any,
  account: PropTypes.any,
  getUserDetails: PropTypes.func,
  urlParams: PropTypes.any,
  stateData: PropTypes.any,
  refreshUrl: PropTypes.any,
  updateUrl: PropTypes.any,
  makeADepositWorldpay: PropTypes.any,
  resetComponentState: PropTypes.any,
  resetMakeADeposit: PropTypes.func,
  updateState: PropTypes.any,
  location: PropTypes.any,
  userDetails: PropTypes.any,
  getAllProducts: PropTypes.func,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    {
      ...mapDispatchToProps,
      ...mapSavingsServicingDispatchToProps,
    },
  )(withRouter(MakeADeposit)),
  storage.name,
);
