import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { HelpContactSidebar, SignInSidebarComponent } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class RegistrationSidebar extends React.Component {
  render() {
    const pathname = this.props.location.pathname;
    if (pathname.endsWith('/success')) {
      return (
        <aside className="right-container">
          <HelpContactSidebar contacts={CONTACTS} />
        </aside>
      );
    }
    return (
      <aside className="right-container">
        <HelpContactSidebar contacts={CONTACTS} />
        <SignInSidebarComponent />
      </aside>
    );
  }
}

RegistrationSidebar.propTypes = {
  location: PropTypes.any,
};

RegistrationSidebar.propTypes = {};

export default withRouter(RegistrationSidebar);
