import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { FormattedCurrency, Content } from '@myie/interact-dom';

class Balance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: 0,
    };
  }

  render() {
    const { props } = this;
    const { account, outstandingAmount } = props;

    return (
      <React.Fragment>
        <Col
          tag="dt"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="align-self-center"
        >
          <Content
            cmsTag="Account-type-mortgage:Balance:Your-outstanding-balance-is"
            copytext="Your outstanding balance is:"
          />
        </Col>
        <Col tag="dd" xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormattedCurrency
            quantity={outstandingAmount}
            currency={account.CurrencyCode}
          />
        </Col>
      </React.Fragment>
    );
  }
}

Balance.propTypes = {
  account: PropTypes.any,
  accounts: PropTypes.any,
  loopIndex: PropTypes.any,
  loadingTransactions: PropTypes.any,
  outstandingAmount: PropTypes.any,
  transactions: PropTypes.any,
};

export default Balance;
