import React, { Component } from 'react';
import { BreadcrumbItem } from 'reactstrap';
import { storage } from '../storageName';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import moment from 'moment';
import { CONSTANTS, FormErrorList } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as userManagementDispatch } from '@myie/interact-brand-user-management';
import { mapDispatchToProps as mapSavingDispatchToProps } from '@myie/interact-account-type-savings';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Create from './Create';
import Confirmation from './Confirmation';
import CreationSuccess from './CreationSuccess';
import NominatedPending from './NominatedPending';
import dateValidation from '../../utility';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import { Validate, Session } from '@myie/interact';

class NominatedTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState(this.state);
    const {
      allProducts,
      getAllProducts,
      getNominatedAccountStatus,
      fetchAccountsIfNeeded,
    } = props;
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
    const retrievedUserName = Session.customer();
    const request = {
      userName: retrievedUserName.Username,
      Category: 'NominatedAccount',
      attempt: 1,
    };
    getNominatedAccountStatus(request);
    fetchAccountsIfNeeded();
    this.getTransactions(this.state.account);
  }

  getTransactions = account => {
    if (account && !account.Transactions) {
      const { fetchSavingsAccountTransactions } = this.props;
      const to = moment().startOf('day');
      const from = moment()
        .subtract(1, 'years')
        .add(1, 'day')
        .startOf('day');
      const range = {
        To: to.toISOString(),
        From: from.toISOString(),
        Latest: true,
      };
      fetchSavingsAccountTransactions({
        AccountKey: account.AccountKey,
        Range: range,
        ExtendedProperties: {
          ListSubAccountNumbers:
            account.ExtendedProperties.AccountType ===
            CONSTANTS.ACCOUNT_TYPE_MORTGAGE
              ? undefined
              : [account.ExtendedProperties.SubAccountNumber],
          BrandProductType: account.ExtendedProperties.AccountType,
          PaginationDetails: 'false',
        },
      });
    }
  };

  initialState = state => {
    const { accounts, stateData } = this.props;
    const { urlParams, refreshUrl } = this.props;
    const stateList = ['createTransfer', 'ReviewTransfer', 'Completed'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'createTransfer';
    if (!hasStage) {
      stage = 'createTransfer';
      refreshUrl();
    }
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === stateData.From;
      });
    } else {
      account = null;
    }
    let data =
      this.props.stateData && this.props.stateData.formData
        ? this.props.stateData.formData
        : {};
    return {
      account: account,
      stage: stage,
      withdrawDate: moment(),
      combinedForm: {},
      accountForm: {
        accept: {
          rules: {
            title: 'Close Account',
            stop: true,
            required: {
              message:
                'Please confirm that you are happy to proceed with your withdrawal.',
            },
          },
          value: data && data['accept'] ? data['accept'] : null,
        },
        accountFormValidation: {
          rules: {
            stop: true,
            required: {
              message: 'Please select an account to transfer from.',
            },
          },
          value: state && state.account ? state.account.AccountKey.Key : '',
        },
      },
      data: data,
      responseData:
        this.props.stateData && this.props.stateData.transferData
          ? this.props.stateData.transferData
          : null,
      form: {},
    };
  };

  componentWillUnmount() {
    const { resetMakeWithdrawal, resetNominatedAccount } = this.props;
    resetMakeWithdrawal();
    resetNominatedAccount();
  }

  componentDidMount() {
    const { resetMakeWithdrawal, resetNominatedAccount } = this.props;
    resetMakeWithdrawal();
    if (this.state.stage !== 'Completed') {
      resetNominatedAccount();
    }
  }

  setStateDynamic = (list, isSubmit = false) => {
    if (isSubmit) {
      let newState = { ...this.state };
      newState.combinedForm = list.combinedForm;
      this.setState({ ...newState });
    } else {
      this.setState({ ...this.state, ...list });
    }
  };

  componentDidUpdate(prevProps, preState) {
    const { accounts } = this.props;
    const { account } = this.state;
    if (account && account.AccountNumber !== preState?.account?.AccountNumber) {
      this.getTransactions(this.state.account);
    }
    if (account && accounts !== prevProps.accounts) {
      const updatedAccount = accounts?.Accounts.find(function(element) {
        return element.AccountKey.Key === account.AccountKey.Key;
      });
      if (account !== updatedAccount) {
        this.setState({ ...this.state, account: updatedAccount });
      }
    }
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const { makeWithdrawResponse = {}, stateData } = nextProps;
    let newForm = state.accountForm;
    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success'
    ) {
      return {
        stage: 'Completed',
      };
    }
    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Failed'
    ) {
      return {
        responseStatus: makeWithdrawResponse.ResponseStatus,
      };
    }
    if (nextProps.accounts && !state.account) {
      let account;
      if (nextProps.accounts) {
        if (stateData && stateData.From) {
          account = nextProps.accounts.Accounts.find(function(element) {
            return Utility.hexEncode(element.AccountKey.Key) === stateData.From;
          });
          newForm = Validate.input(
            'accountFormValidation',
            account.AccountKey.Key,
            newForm,
          );
        }
      } else {
        account = null;
      }
      return {
        account: account,
        accountForm: newForm,
      };
    }

    return null;
  };

  noticeFilter = accounts => {
    const { allProducts } = this.props;

    if (!allProducts) return [];
    if (accounts) {
      return accounts.filter(account => {
        const {
          ExtendedProperties: {
            AccountMode,
            ProductCode,
            HasNominatedAccount,
          } = {},
          AccountStatus,
        } = account;

        const product = allProducts.find(
          product => product.Product_Code === ProductCode,
        );
        return (
          product &&
          product.Max_Withdrawals_Per_Year !== 'None' &&
          AccountMode === CONSTANTS.ACCOUNT_TRANSACT &&
          AccountStatus !== 'Closed' &&
          HasNominatedAccount !== CONSTANTS.NO_NOMINATED_ACCOUNT
        );
      });
    }
    return [];
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { accountForm, data, combinedForm } = this.state;
    accountForm = Validate.input(name, checked ? 'true' : null, accountForm);
    data[name] = checked ? 'true' : null;
    this.setState({
      ...this.state,
      accountForm,
      data,
      combinedForm: {
        ...combinedForm,
        ...accountForm,
      },
    });
  };

  updateField = (name, value) => {
    let { accountForm } = this.state;
    accountForm = Validate.input(name, value ? 'true' : null, accountForm);
    return accountForm;
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  callAccountValidations = () => {
    const { accountForm, combinedForm } = this.state;
    let form = Validate.form(accountForm);
    window.scrollTo(0, 0);
    this.setState({
      ...this.state,
      accountForm: form,
      combinedForm: {
        ...combinedForm,
        ...form,
      },
    });
  };

  //update the stage in state
  submitForm = (stage, form, isSubmit = false) => {
    if (stage === 'createTransfer') {
      const { resetMakeWithdrawal, resetNominatedAccount } = this.props;
      resetMakeWithdrawal();
      resetNominatedAccount();
    }
    let stateData = {
      formData: this.state.data,
      url: {
        pageStage: stage,
      },
    };
    this.props.updateUrl(stateData);
    if (isSubmit) {
      let newState = { ...this.state };
      newState.combinedForm = form;
      this.setState({ ...newState, stage, form, message: null });
    } else {
      this.setState({ ...this.state, stage, form, message: null });
    }
  };

  getActionDate = account => {
    const { holidays } = this.props;
    let numberOfDays = '';
    if (account.ExtendedProperties.AccountType === CONSTANTS.REGULAR_SAVINGS) {
      let segments = account.AccountName.split(/\s+/);
      numberOfDays = parseInt(segments[0]);
    }

    const NumberOfDays = numberOfDays === 0 ? 0 : numberOfDays;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  onSubmitWithdrawal = () => {
    const { account, data } = this.state;
    const { makeaWithdraw, accounts, linkedNominatedAccount } = this.props;
    let formCloseAccount = data['closeAccount'] || false;
    const request = {
      AccountKey: account.AccountKey,
      ToLinkedAccountIdentifier:
        linkedNominatedAccount && linkedNominatedAccount.LinkedAccountIdentifier
          ? linkedNominatedAccount.LinkedAccountIdentifier
          : accounts.ExtendedProperties.NominatedAccountDetails
              .LinkedAccountIdentifier,
      CustomerReference: data['purposeOptions'],
      SubAccountId: account.ExtendedProperties.SubAccountNumber,
      Amount: formCloseAccount ? undefined : data['amount'],
    };

    makeaWithdraw(request, formCloseAccount);
  };

  // common onChangeAccount function
  onChangeAccount = e => {
    const { accounts, updateState, getNominatedAccount } = this.props;
    let { accountForm, combinedForm } = this.state;
    let newForm = accountForm;

    const { value } = e.target;
    if (accounts && accounts.Accounts && value !== '') {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });

      const requestPayload = {
        AccountKey: account.AccountKey,
        SubAccountId: account.ExtendedProperties.SubAccountNumber,
      };
      getNominatedAccount(requestPayload);

      newForm = Validate.input('accountFormValidation', value, newForm);
      let object = {};
      object.From = Utility.hexEncode(account.AccountKey.Key);
      updateState(object);
      this.setState({
        account,
        accountForm: newForm,
        combinedForm: { ...combinedForm, ...newForm },
      });
    }
  };
  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // select current stage
  currentStage = account => {
    const { accounts = {}, linkedNominatedAccount } = this.props;
    switch (this.state.stage) {
      case 'createTransfer':
        return (
          <Create
            setForm={this.setForm}
            accountForm={this.state.accountForm}
            callAccountValidations={this.callAccountValidations}
            submitForm={this.submitForm}
            onChangeAccount={this.onChangeAccount}
            saveData={this.saveData}
            onClick={this.onClick}
            data={this.state.data}
            setStateDynamic={this.setStateDynamic}
            accounts={accounts}
            noticeFilter={this.noticeFilter}
            account={account}
            linkedNominatedAccount={linkedNominatedAccount}
          />
        );
      case 'ReviewTransfer':
        return (
          <Confirmation
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            updateField={this.updateField}
            form={this.state.form}
            updateUrl={this.props.updateUrl}
            combinedForm={this.state.combinedForm}
            setStateDynamic={this.setStateDynamic}
            data={this.state.data}
            setStage={this.setStage}
            account={account}
            accounts={accounts}
            linkedNominatedAccount={linkedNominatedAccount}
          />
        );
      default:
    }
  };

  render() {
    const {
      stage,
      account,
      responseData,
      responseStatus,
      combinedForm,
    } = this.state;
    const {
      accounts,
      makeWithdrawResponse,
      updateUrl,
      resetComponentState,
      userDetails,
      match,
      nominatedAccountStatus,
      linkedNominatedAccount,
    } = this.props;

    if (
      userDetails &&
      userDetails.ExtendedProperties &&
      userDetails.ExtendedProperties.TruNarrativeDecisionStatus &&
      userDetails.ExtendedProperties.TruNarrativeDecisionStatus === 'pending'
    ) {
      return <NominatedPending />;
    }

    if (
      accounts?.ExtendedProperties.HasNominatedAccount ===
      CONSTANTS.NO_NOMINATED_ACCOUNT
    ) {
      if (
        nominatedAccountStatus.DecisionStatus ===
        CONSTANTS.NOMINATED_ACC_DECISION_STATUS
      ) {
        return <Redirect to={`/nominated-account/request-pending`} />;
      }
      return (
        <Redirect
          to={`/nominated-account/no-nominated-account/${
            match.params.page ? match.params.page : ''
          }`}
        />
      );
    }

    if (responseStatus === 'Failed') {
      return <Redirect to="/network-error" />;
    }
    if (!accounts) {
      return null;
    }

    let condition = this.state.stage === 'ReviewTransfer';

    if (stage === 'Completed') {
      return (
        <CreationSuccess
          account={account}
          data={this.state.data}
          setStage={this.setStage}
          nominatedTransferResponse={responseData || makeWithdrawResponse}
          updateUrl={updateUrl}
          resetComponentState={resetComponentState}
          nominatedAccountDetails={
            linkedNominatedAccount && linkedNominatedAccount.NominatedAccount
              ? linkedNominatedAccount.NominatedAccount
              : accounts.ExtendedProperties?.NominatedAccountDetails
                  .NominatedAccount
          }
        />
      );
    }

    return (
      <div id="nominated-account">
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Nominated-transfer:h1"
          copytext="Withdraw to your nominated account"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={stage === 'createTransfer'}
              className={condition ? 'completed' : ''}
            >
              {stage === 'createTransfer' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {condition ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Withdrawal-details"
                copytext="Withdrawal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'ReviewTransfer'}>
              {stage === 'ReviewTransfer' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        {this.state.stage === 'createTransfer' ? (
          <FormErrorList
            validations={combinedForm}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
        ) : (
          ''
        )}
        {this.currentStage(account)}
      </div>
    );
  }
}

NominatedTransfer.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  holidays: PropTypes.any,
  account: PropTypes.any,
  getUserDetails: PropTypes.func,
  urlParams: PropTypes.any,
  stateData: PropTypes.any,
  refreshUrl: PropTypes.any,
  updateUrl: PropTypes.any,
  makeWithdrawResponse: PropTypes.any,
  resetComponentState: PropTypes.any,
  updateState: PropTypes.any,
  userDetails: PropTypes.any,
  allProducts: PropTypes.array,
  getAllProducts: PropTypes.func,
  getNominatedAccountStatus: PropTypes.func,
  nominatedAccountStatus: PropTypes.object,
  fetchSavingsAccountTransactions: PropTypes.func,
  getNominatedAccount: PropTypes.func,
  linkedNominatedAccount: PropTypes.object,
  resetNominatedAccount: PropTypes.func,
};

const mapStateToProps = state => {
  const {
    accountsDefinition,
    custombrandSavingsServicingDefinition,
    customUserManagementDefinition,
  } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
    ...customUserManagementDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    {
      ...mapDispatchToProps,
      ...mapSavingsServicingDispatchToProps,
      ...userManagementDispatch,
      ...mapSavingDispatchToProps,
    },
  )(NominatedTransfer),
  storage.name,
);
