import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { stateUtility, CONSTANTS } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import Eligibility from '../shared/Eligibility';
import { storage } from './storageName';
import ShortApplyApplication from '../shared/ShortApplyApplication';
import DecisionSuccessISA from './application-responses/DecisionSuccessISA';
import DecisionSuccessNonISA from './application-responses/DecisionSuccessNonISA';
import DecisionReferred from './application-responses/Referred';
import Declined from './application-responses/Declined';
import Awaiting from './application-responses/Awaiting';
import ShortApplyReview from '../shared/ShortApplyReview';
import ApplicationSuccess from './ApplicationSuccess';
import { mapDispatchToProps as accountsDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as userManagementDispatches } from '@myie/interact-user-management';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';

class ShortApplyProduct extends Component {
  constructor(props) {
    super(props);
    const { urlParams, stateData, resetSaveShortApply } = this.props;
    const stateList = [
      'details',
      'apply',
      'review',
      'applySuccess',
      'Refer',
      'referredWithDocs',
      'Decline',
    ];

    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'details';
    if (!hasStage) {
      stage = 'details';
      resetSaveShortApply();
    }

    stateUtility.setGlobalValue({ storage: storage.name });
    this.timeoutID = null;
    this.state = {
      message: '',
      selectedProduct: null,
      stage: stage,
      disabled: false,
    };
  }

  componentDidMount() {
    const {
      //getAllProducts,
      fetchUserDetailsIfNeeded,
      getDepositProduct,
      match,
      fetchAccountsIfNeeded,
    } = this.props;
    let request = {
      ExtendedProperties: {},
    };
    fetchUserDetailsIfNeeded(request);
    fetchAccountsIfNeeded();
    // getAllProducts({
    //   checkLimitExceeded: true,
    // });
    getDepositProduct({
      ProductId: Utility.hexDecode(match.params.id),
    });
  }

  componentWillUnmount() {
    const { resetSaveShortApply } = this.props;
    resetSaveShortApply();
  }
  static getDerivedStateFromProps = (nextProps, thisState) => {
    const {
      allProducts,
      match,
      saveShortApplyResponse,
      stateData,
      updateUrl,
    } = nextProps;
    const { selectedProduct, stage } = thisState;

    let product = null;

    if (allProducts && !selectedProduct) {
      allProducts.forEach(element => {
        if (
          match.params &&
          match.params.id === Utility.hexEncode(element.Product_Code)
        ) {
          product = element;
        }
      });

      return {
        selectedProduct: product,
      };
    }

    if (
      saveShortApplyResponse &&
      saveShortApplyResponse.Status === 'Pending' &&
      stage !== 'Refer'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'Refer',
        },
      };

      updateUrl(data);
      return {
        stage: 'Refer',
      };
    }
    if (
      saveShortApplyResponse &&
      saveShortApplyResponse.Status === 'Referred' &&
      stage !== 'referredWithDocs'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'referredWithDocs',
        },
      };

      updateUrl(data);
      return {
        stage: 'referredWithDocs',
      };
    }

    if (
      saveShortApplyResponse &&
      (saveShortApplyResponse.Status === 'Decline' ||
        saveShortApplyResponse.Status === 'Failed') &&
      stage !== 'Decline'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'Decline',
        },
      };

      updateUrl(data);
      return {
        stage: 'Decline',
      };
    }

    if (
      saveShortApplyResponse &&
      saveShortApplyResponse.Status === 'Accept' &&
      stage !== 'applySuccess'
    ) {
      let data = {
        formData: {
          ...stateData.formData,
        },
        url: {
          pageStage: 'applySuccess',
        },
      };

      updateUrl(data);
      return {
        stage: 'applySuccess',
      };
    }

    return null;
  };
  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  selectProduct = product => {
    const { setStateDynamic, form } = this.props;
    this.setState({ ...this.state, selectedProduct: product });
    setStateDynamic({
      reviewPaymentHeader:
        form && form.destination && form.destination.value === 'new'
          ? 'review'
          : 'optionThree',
      selectedProduct: product,
    });
  };

  render() {
    const { stage, selectedProduct } = this.state;
    let ISA = '';
    const {
      userDetails,
      accounts = {},
      savingshortapply,
      depositProduct,
    } = this.props;
    ISA = CONSTANTS.ACCOUNT_TYPE_CODE.find(element => {
      return selectedProduct.Account_Type_Code === element;
    });
    if (depositProduct && !depositProduct.Product) {
      return <Redirect to="/short-apply/not-available" />;
    }
    if (stage === 'Refer') {
      return (
        <Awaiting saveShortApplyResponse={this.props.saveShortApplyResponse} />
      );
    }

    if (stage === 'referredWithDocs') {
      return (
        <DecisionReferred
          saveLongApplyData={this.props.saveShortApplyResponse}
        />
      );
    }

    if (stage === 'Decline') {
      return <Declined saveLongApplyData={this.props.saveShortApplyResponse} />;
    }

    if (stage === 'applySuccess' && ISA) {
      return (
        <DecisionSuccessISA
          formData={
            this.props.stateData && this.props.stateData.formData
              ? this.props.stateData.formData
              : {}
          }
          selectedProduct={this.state.selectedProduct}
        />
      );
    }
    if (stage === 'applySuccess' && !ISA) {
      return (
        <DecisionSuccessNonISA
          formData={
            this.props.stateData && this.props.stateData.formData
              ? this.props.stateData.formData
              : {}
          }
          selectedProduct={this.state.selectedProduct}
        />
      );
    }
    switch (stage) {
      case 'details':
        return (
          <Eligibility
            selectedProduct={this.state.selectedProduct}
            updateUrl={this.props.updateUrl}
            setStateDynamic={this.setStateDynamic}
          />
        );
      case 'apply':
        return (
          <ShortApplyApplication
            accounts={accounts}
            selectedProduct={this.state.selectedProduct}
            formData={
              this.props.stateData && this.props.stateData.formData
                ? this.props.stateData.formData
                : {}
            }
            updateUrl={this.props.updateUrl}
            setStateDynamic={this.setStateDynamic}
            userDetails={userDetails}
          />
        );
      case 'review':
        return (
          <ShortApplyReview
            accounts={accounts}
            selectedProduct={this.state.selectedProduct}
            setStateDynamic={this.setStateDynamic}
            updateUrl={this.props.updateUrl}
            userDetails={userDetails}
            formData={
              this.props.stateData && this.props.stateData.formData
                ? this.props.stateData.formData
                : {}
            }
          />
        );
      case 'success':
        return <ApplicationSuccess savingshortapply={savingshortapply} />;

      default:
    }

    return null;
  }
}

ShortApplyProduct.propTypes = {
  submit: PropTypes.func,
  account: PropTypes.any,
  getAllProducts: PropTypes.any,
  form: PropTypes.any,
  reviewPaymentHeader: PropTypes.string,
  showMaturity: PropTypes.any,
  cancelWithdrawal: PropTypes.func,
  changeHeader: PropTypes.func,
  saveShortApplyResponse: PropTypes.object,
  setStateDynamic: PropTypes.func,
  getAccountDetails: PropTypes.func,
  userDetails: PropTypes.any,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  resetSaveShortApply: PropTypes.func,
  fetchUserDetailsIfNeeded: PropTypes.func,
  savingshortapply: PropTypes.object,
  stateData: PropTypes.any,
  getDepositProduct: PropTypes.func,
  match: PropTypes.any,
  updateUrl: PropTypes.any,
  depositProduct: PropTypes.object,
  urlParams: PropTypes.any,
};

const mapStateToProps = state => {
  const {
    custombrandSavingsServicingDefinition,
    userManagementDefinition,
    accountsDefinition,
  } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...userManagementDefinition,
    ...accountsDefinition,
  };
};
export default loadStateHOC(
  connect(
    mapStateToProps,
    {
      ...userManagementDispatches,
      ...mapDispatchToProps,
      ...accountsDispatchToProps,
    },
  )(withRouter(ShortApplyProduct)),
  storage.name,
);
