import React, { Component } from 'react';
import {
  Content,
  FormattedCurrency,
  FormattedDate,
  CONSTANTS,
} from '@myie/interact-dom';
import { Col, Row } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';

class Cancel extends Component {
  constructor(props) {
    super(props);
    const { cancelNoticeToWithdraw, selectedNotice, account = {} } = props;

    this.state = {
      account: account,
      cancelNoticeToWithdraw: cancelNoticeToWithdraw,
      disabled: false,
      selectedNotice,
    };
  }

  onConfirmCancellation = () => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    const { cancelNoticeToWithdraw, identifier, paymentMethod } = this.props;
    const { account = {} } = this.state;

    cancelNoticeToWithdraw({
      AccountKey: account && account.AccountKey,
      SubAccountId: account.ExtendedProperties.SubAccountNumber,
      NoticeId: identifier,
      PaymentMethod: paymentMethod,
    });
  };

  render() {
    const { AccountKey: { Key } = {} } = this.props.account;
    const accountKey = Key && Utility.hexEncode(Key);

    const { account = {}, selectedNotice } = this.state;
    const { AccountName, CurrencyCode } = account;

    if (!selectedNotice) {
      return null;
    }

    return (
      <React.Fragment>
        <Content
          tag="p"
          template={{
            copytext: {
              accountName: AccountName,
            },
          }}
          cmsTag="Savings-servicing:Notice-to-withdrawal:Cancel-notice-to-withdraw:Cancel:p1"
          copytext="You are about to delete a notice to withdraw for your $[accountName]."
        />
        <Row tag="dl" title="Account details review">
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {selectedNotice.Amount !== 0 ? (
              <FormattedCurrency
                quantity={selectedNotice.Amount}
                currency={CurrencyCode}
              />
            ) : (
              <Content cmsTag="GLOBAL::All" copytext="All" />
            )}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Withdraw-date" copytext="Withdrawal date" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            <FormattedDate
              date={selectedNotice.WithdrawalDate}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
              currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
            />
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content
              cmsTag="GLOBAL:Notice-expiry-date"
              copytext="Notice expiry date"
            />
          </Col>
          {selectedNotice.NoticeExpiryDate && (
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              <FormattedDate
                date={selectedNotice.NoticeExpiryDate}
                format={CONSTANTS.STANDARD_DATE_FORMAT}
                currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
              />
            </Col>
          )}
        </Row>

        <div className="form-button-group">
          <Button
            id="confirm-cancel-notice"
            type="submit"
            onClick={this.onConfirmCancellation}
            color="primary"
            disabled={this.state.disabled}
          >
            <Content cmsTag="GLOBAL:Delete-notice" copytext="Delete notice" />
          </Button>
          <Link
            id="cancel-notice-to-withdraw"
            className="btn btn-secondary"
            role="button"
            to={`/notice-to-withdraw/view/${this.props.page}/${accountKey}`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-notice-list"
              copytext="Back to notice list"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

Cancel.propTypes = {
  match: PropTypes.any,
  accounts: PropTypes.any,
  cancellationResponse: PropTypes.any,
  message: PropTypes.any,
  history: PropTypes.any,
  account: PropTypes.any,
  identifier: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  cancelNoticeToWithdraw: PropTypes.func,
  page: PropTypes.any,
  getListNoticeToWithdraw: PropTypes.func,
  noticesList: PropTypes.array,
  paymentMethod: PropTypes.string,
  selectedNotice: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(Cancel));
