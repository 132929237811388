import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { Content, Switch, Markdown } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

class Confirmation extends React.Component {
  onCancel = () => {
    this.props.initializeMoveMoney();
    this.props.resetData();
  };

  componentWillUnmount() {
    const { resetReply } = this.props;
    resetReply();
  }

  renderSuccessContent = () => {
    return (
      <Markdown
        tag="p"
        cmsTag="Widget-send-message:Confirmation:p1"
        markdown={`Thank you, your secure message has been received. We'll ensure we get back to you within two working days.`}
      />
    );
  };

  render() {
    const { replyMessageStatus } = this.props;
    if (!replyMessageStatus) {
      return null;
    }
    let alertId = null;
    if (replyMessageStatus) {
      switch (replyMessageStatus.ResponseStatus) {
        case 'Success':
          alertId = 'new-message-widget-success';
          break;
        case 'Failed':
          alertId = 'new-message-widget-failure';
          break;
        default:
          alertId = 'new-message-widget-unknown';
          break;
      }
    }

    return (
      <React.Fragment>
        <div id="new-message-widget-confirmation">
          <Content
            tag="h1"
            cmsTag="Widget-send-message:Confirmation:h1"
            copytext="Message acknowledgement"
          />
          {replyMessageStatus && (
            // NOTE FOR JEN: check if this needs to be an alert in a future project
            <Alert
              id={alertId}
              color={
                replyMessageStatus &&
                replyMessageStatus.ResponseStatus === 'Success'
                  ? 'success'
                  : 'danger'
              }
            >
              <Switch
                id="confirmAlert"
                tag="<>"
                scrolltotop={true}
                value={replyMessageStatus.ResponseStatus}
                defaultText="An unknown error occured please check your accounts before retrying."
                contents={{
                  Success: {
                    defaultValue: 'Your message has been sent.',
                  },
                  Failed: {
                    defaultValue: 'Your message was not sent. Try again.',
                  },
                  default: {
                    defaultValue:
                      'An unknown error occured. Please check your accounts before retrying.',
                  },
                }}
              />
            </Alert>
          )}
          {replyMessageStatus.ResponseStatus === 'Success'
            ? this.renderSuccessContent()
            : false}
          <div className="form-button-group">
            <Link
              id="new-message-widget-confirmation-back"
              className="btn btn-secondary"
              role="button"
              to="/accounts/list"
            >
              <Content
                cmsTag="GLOBAL:Back-to-accounts"
                copytext="Back-to-accounts"
              />
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  initializeMoveMoney: PropTypes.any,
  replyMessageStatus: PropTypes.any,
  resetData: PropTypes.any,
  resetReply: PropTypes.any,
  transactionData: PropTypes.any,
  transfer: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Confirmation);
