import React, { Component } from 'react';
import { Form, Button } from 'reactstrap';
import { Content, Markdown } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import { Amount } from '@myie/interact-savings-servicing-dom';

class TransferForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
    };
  }

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    const { accountForm, data, setStateDynamic } = this.props;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...accountForm,
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  formatCurrencyInput = value => {
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    return newValue;
  };

  updateCurrency = e => {
    let { name, value } = e.target;
    let newValue = this.formatCurrencyInput(value);
    let { form } = this.state;
    const { accountForm, data, setStateDynamic } = this.props;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...accountForm,
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const {
      onSubmit,
      toAccount,
      account,
      callAccountValidations,
      accountForm,
      setStateDynamic,
    } = this.props;
    let validatedAccountForm = Validate.form(accountForm);
    if (!toAccount || !account) {
      callAccountValidations();
      return;
    }
    let { form } = this.state;
    form = Validate.form(form);
    this.setState({ ...this.state, form });
    setStateDynamic({
      combinedForm: { ...validatedAccountForm, ...form },
    });
    if (!form.approved) {
      window.scrollTo(0, 0);
      return;
    }
    if (typeof onSubmit === 'function') {
      onSubmit(form);
    }

    onSubmit(form);
  };

  onClick = e => {
    const { name, checked } = e.target;
    const { accountForm, data, setStateDynamic } = this.props;
    let { form, amount } = this.state;

    if (!form.amount) {
      form = {
        amount: amount,
        closeAccount: false,
      };
    }

    form = Validate.input(name, checked, form);
    data[name] = checked ? 'true' : null;
    setStateDynamic({
      data,
      combinedForm: {
        ...accountForm,
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  setAmountRules = ({ amount, withdrawalRule }) => {
    const form = { ...this.state.form, amount };
    this.setState({
      ...this.state,
      amount,
      form,
      withdrawalRule,
    });
  };

  render() {
    const { account = {}, toAccount, data } = this.props;
    const { form, withdrawalRule } = this.state;
    const isDisabled = withdrawalRule && !withdrawalRule.isPermitted;

    return (
      <Form
        aria-live="assertive"
        autoComplete="off"
        id="internal-transfer-form"
        onSubmit={this.onSubmitHandler}
      >
        {account && withdrawalRule && !withdrawalRule.isPermitted && (
          <div role="alert" className="alert alert-danger">
            {withdrawalRule.message && (
              <Content
                cmsTag="Savings-servicing:move-money:error-message"
                copytext={withdrawalRule.message}
              />
            )}
          </div>
        )}
        <Amount
          form={form}
          account={account}
          toAccount={toAccount}
          onChange={this.updateCurrency}
          onBlur={this.onBlur}
          data={data}
          code={account && account.CurrencyCode}
          setAmountRules={this.setAmountRules}
          label="Amount to transfer"
          cmsTag="GLOBAL:Amount-to-transfer"
          showErrors={true}
          validation={form.amount}
          field="amount"
          id="amount"
          inputGroupClassName="half-field"
          dp={2}
          ruleType="internal_transfer"
          maxLength="9"
          disabled={isDisabled}
        />

        <div className="alert alert-info">
          <Markdown
            cmsTag="GLOBAL:Internal-transfer-date-warning"
            markdown={
              'Transfers made on the weekend or bank holidays will leave your account on the next working day.'
            }
          />
        </div>
        <Button id="quick-transfer-buttons" type="submit" color="primary">
          <Content
            cmsTag="GLOBAL:Review-transfer-details"
            copytext="Review transfer details"
          />
        </Button>
      </Form>
    );
  }
}

TransferForm.propTypes = {
  account: PropTypes.object,
  setStateDynamic: PropTypes.func,
  saveData: PropTypes.any,
  accountForm: PropTypes.any,
  callAccountValidations: PropTypes.func,
  data: PropTypes.any,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func,
  toAccount: PropTypes.any,
  isFetching: PropTypes.any,
};

export default TransferForm;
