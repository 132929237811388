import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Markdown, Content, CONSTANTS } from '@myie/interact-dom';
import { Link } from 'react-router-dom';

class MaturityAlert extends React.Component {
  onDismiss = () => {
    const { closeAlert, goal } = this.props;
    closeAlert(goal);
  };

  render() {
    const { loopIndex, visibility, account, goal } = this.props;

    if (
      account.AccountStatus !== 'Closed' &&
      account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED
    ) {
      return (
        <React.Fragment>
          <Alert
            color="info"
            key={loopIndex}
            isOpen={visibility}
            toggle={this.onDismiss}
            className="mt-3 goal-alert"
          >
            <Markdown
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Banner-messages:Goal-alert:alert-1"
              template={{ markdown: { name: goal.Name } }}
              markdown={`##Congratulations, you have achieved your goal! \n\nWhy not start up your next savings goal?`}
            />

            <Link
              id={loopIndex}
              to={'/money-tools/savings-goals/create-goals/goal-list/'}
            >
              <Content
                cmsTag="GLOBAL:Set-up-a-new-savings-goal"
                copytext="Set up a new savings goal"
              />
            </Link>
          </Alert>
        </React.Fragment>
      );
    } else return null;
  }
}

MaturityAlert.propTypes = {
  alertVisibleArray: PropTypes.any,
  closeAlert: PropTypes.any,
  accountKey: PropTypes.any,
  account: PropTypes.object,
  message: PropTypes.any,
  goal: PropTypes.any,
  loopIndex: PropTypes.any,
  MaturityDate: PropTypes.any,
  visibility: PropTypes.any,
  alert: PropTypes.any,
};

export default MaturityAlert;
