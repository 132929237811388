import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import {
  AppMeta,
  Content,
  stateUtility,
  Iframe,
  Markdown,
  RULES,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { connect } from 'react-redux';
import { storage } from './storageName';
import { mapDispatchToProps } from '@myie/interact-brand-accounts';
import AddNominatedAccountReferredNoDocs from '../ReferredNoDocs';
import ReferredWithDocs from '../ReferredWithDocs';
import { mapDispatchToProps as userManagementDispatch } from '@myie/interact-user-management';
import { mapDispatchToProps as brandUserManagementDispatch } from '@myie/interact-brand-user-management';
import AccountDetails from './AccountDetails';
import Confirm from './Confirm';
import Success from './Success';
import Failed from './Failed';
import { Redirect } from 'react-router-dom';
import { Validate } from '@myie/interact';
import { Session } from '@myie/interact';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../../shared/HO_components/checkDownTime';

class EditNominatedAccount extends Component {
  constructor(props) {
    super(props);
    const {
      urlParams,
      refreshUrl,
      stateData,
      resetGetNominatedAccountStatus,
      accounts: { ExtendedProperties: exProps = [] },
    } = this.props;
    const stateList = [
      'AccountDetails',
      'Confirm',
      'Success',
      'Refer',
      'pending',
      'successWithDocs',
    ];

    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'AccountDetails';
    if (!hasStage) {
      stage = 'AccountDetails';
      resetGetNominatedAccountStatus();
      refreshUrl();
    }

    const {
      NominatedAccount: nominatedAccObj = null,
      PayeeReference,
    } = exProps.NominatedAccountDetails;
    const nominatedAcc = nominatedAccObj
      ? {
          accountHolderName: nominatedAccObj.Name,
          accountNumber: nominatedAccObj.AccountNumber,
          sortcode: nominatedAccObj.SortCode.match(/.{1,2}/g).join('-'),
          payeeReference: PayeeReference,
        }
      : null;

    const data =
      props.stateData && props.stateData.formData
        ? props.stateData.formData
        : nominatedAcc;

    const addNominatedAccountResponse =
      props.stateData && props.stateData.addNominatedAccountResponse
        ? props.stateData.addNominatedAccountResponse
        : null;
    const count = data && data['statusCount'] ? data['statusCount'] : 0;
    const state = this.initialState(data);
    stateUtility.setGlobalValue({ storage: storage.name });
    this.timeoutID = null;
    this.state = {
      ...state,
      data,
      statusCount: count,
      stage,
      ref: '',
      addNominatedAccountResponse: addNominatedAccountResponse,
      started: false,
    };
  }

  initialState = data => {
    return {
      form: {
        accountHolderName: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter your account holder name.',
            },
            format: {
              regex: RULES.accountHolderName,
              message: 'Please enter a valid account holder name.',
            },
          },
          value:
            data && data['accountHolderName'] ? data['accountHolderName'] : '',
        },
        accountNumber: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter your account number.',
            },
            format: {
              regex: RULES.accountNumber,
              message: 'Please enter a valid account number.',
            },
          },
          value: data && data['accountNumber'] ? data['accountNumber'] : '',
        },

        sortcode: {
          rules: {
            title: 'bday',
            stop: true,
            required: {
              message: 'Please enter your sort code.',
            },
            format: {
              regex: RULES.sortCode,
              message: 'Please enter a valid sort code.',
            },
          },
          value: data && data['sortcode'] ? data['sortcode'] : '',
        },
        payeeReference: {
          rules: {
            stop: true,
            required: {
              message: 'Please enter payee reference.',
            },
          },
          value: data && data['payeeReference'] ? data['payeeReference'] : '',
        },
        termsAndConditions: {
          rules: {
            title: 'Close Account',
            required: {
              message:
                'Please confirm that this account will be used for faster payments.',
            },
          },
          value:
            data && data['termsAndConditions']
              ? data['termsAndConditions']
              : null,
        },
      },
    };
  };

  componentDidUpdate() {
    const { userDetails } = this.props;
    const {
      addNominatedAccountResponse,
      updateUrl,
      nominatedAccountStatus,
      resetAddNominatedAccount,
    } = this.props;
    const { stage, data, ref } = this.state;
    if (
      addNominatedAccountResponse &&
      addNominatedAccountResponse.ResponseStatus &&
      addNominatedAccountResponse.ResponseStatus === 'Success' &&
      addNominatedAccountResponse.ProgressStatusCode === 'AWAITING_RESPONSE' &&
      stage !== 'Refer'
    ) {
      resetAddNominatedAccount();
      let stateData = {
        formData: data,
        addNominatedAccountResponse: addNominatedAccountResponse,
        url: {
          pageStage: 'Refer',
        },
      };
      this.startTimer();
      updateUrl(stateData);
      this.setState({
        ...this.state,
        addNominatedAccountResponse: addNominatedAccountResponse,
        stage: 'Refer',
      });
    }
    if (
      userDetails &&
      userDetails.ExtendedProperties &&
      userDetails.ExtendedProperties.BankAccountDetails &&
      !data
    ) {
      let newData = {};
      newData['accountHolderName'] =
        userDetails.ExtendedProperties.BankAccountDetails.AccountHolderName;
      newData['accountNumber'] =
        userDetails.ExtendedProperties.BankAccountDetails.AccountNumber;
      newData['sortcode'] =
        userDetails.ExtendedProperties.BankAccountDetails.SortCode;
      const state = this.initialState(newData);

      this.setState({ ...state, data: newData });
    }
    if (
      nominatedAccountStatus &&
      nominatedAccountStatus.ClientApplicationRef &&
      !ref
    ) {
      this.setState({
        ...this.state,
        ref: nominatedAccountStatus.ClientApplicationRef,
      });
    }

    if (
      addNominatedAccountResponse &&
      addNominatedAccountResponse.ResponseStatus &&
      addNominatedAccountResponse.ResponseStatus === 'Success' &&
      addNominatedAccountResponse.ProgressStatusCode !== 'AWAITING_RESPONSE' &&
      stage !== 'Success'
    ) {
      let stateData = {
        formData: data,
        url: {
          pageStage: 'Success',
        },
      };
      this.clearTimer();
      updateUrl(stateData);
      this.setState({ started: false, stage: 'Success' });
    }

    if (
      nominatedAccountStatus &&
      nominatedAccountStatus.DocumentUploadStatus === 'COMPLETE' &&
      nominatedAccountStatus.DecisionStatus === 'ACCEPT' &&
      ref &&
      ref !== nominatedAccountStatus.ClientApplicationRef &&
      stage !== 'Success'
    ) {
      let stateData = {
        formData: data,
        url: {
          pageStage: 'Success',
        },
      };
      this.clearTimer();
      updateUrl(stateData);
      this.setState({
        started: false,
        stage: 'Success',
        ref: nominatedAccountStatus.ClientApplicationRef,
      });
    }
  }

  componentDidMount() {
    const {
      getNominatedAccountStatus,
      getUserDetails,
      resetAddNominatedAccount,
    } = this.props;
    getUserDetails({});
    resetAddNominatedAccount();
    //***Shold be removed from the code.
    const retrievedUserName = Session.customer();
    const request = {
      userName: retrievedUserName.Username,
      Category: 'NominatedAccount',
      attempt: 1,
    };
    getNominatedAccountStatus(request);

    window.scrollTo(0, 0);
  }
  updateAccountStatus = () => {
    const { getNominatedAccountStatus } = this.props;
    //***Shold be removed from the code.
    const retrievedUserName = Session.customer();
    const request = {
      userName: retrievedUserName.Username,
      Category: 'NominatedAccount',
      attempt: 2,
    };
    getNominatedAccountStatus(request);
  };
  startTimer = () => {
    const { started } = this.state;
    if (!started) {
      this.timeoutID = setInterval(this.updateAccountStatus, 12000);
      this.setState({ started: true });
    }
  };
  clearTimer = () => {
    clearInterval(this.timeoutID);
  };
  componentWillUnmount() {
    const { resetGetNominatedAccountStatus } = this.props;
    const { resetAddNominatedAccount } = this.props;
    resetAddNominatedAccount();
    resetGetNominatedAccountStatus();
    this.clearTimer && this.clearTimer();
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const { data } = state;
    const {
      updateUrl,
      nominatedAccountStatus,
      resetAddNominatedAccount,
      getIframe,
      iframe,
    } = nextProps;

    // if (
    //   nominatedAccountStatus &&
    //   nominatedAccountStatus.Counter &&
    //   !state.statusCount &&
    //   data
    // ) {
    //   data['statusCount'] = nominatedAccountStatus.Counter;
    //   return {
    //     statusCount: nominatedAccountStatus.Counter,
    //     data: data,
    //   };
    // }

    if (
      //state.statusCount &&
      nominatedAccountStatus &&
      nominatedAccountStatus.Counter &&
      state.statusCount !== nominatedAccountStatus.Counter
    ) {
      data['statusCount'] = nominatedAccountStatus.Counter;
      let status = 'AccountDetails';
      if (
        (nominatedAccountStatus.DecisionStatus === 'PENDING' ||
          nominatedAccountStatus.DecisionStatus === 'REFERRED') &&
        nominatedAccountStatus.DocumentUploadStatus === 'PENDING'
      ) {
        status = 'pending';
      }
      if (
        nominatedAccountStatus.DecisionStatus === 'ACCEPT' &&
        nominatedAccountStatus.DocumentUploadStatus === 'COMPLETE'
      ) {
        status = 'AccountDetails';
      }
      if (
        nominatedAccountStatus.DecisionStatus === 'DECLINE' &&
        nominatedAccountStatus.DocumentUploadStatus === 'COMPLETE'
      ) {
        status = 'Failed';
      }
      if (
        (nominatedAccountStatus.DecisionStatus === 'PENDING' ||
          nominatedAccountStatus.DecisionStatus === 'REFERRED') &&
        nominatedAccountStatus.DocumentUploadStatus === 'COMPLETE'
      ) {
        status = 'successWithDocs';
      }
      let stateData = {
        formData: data,
        url: {
          pageStage: status,
        },
      };
      resetAddNominatedAccount();
      updateUrl(stateData);
      return {
        stage: status,
        statusCount: nominatedAccountStatus.Counter,
        data: data,
      };
    }

    if (
      nominatedAccountStatus &&
      nominatedAccountStatus.DocumentUploadStatus === 'PENDING' &&
      !iframe
    ) {
      getIframe({});
      return { started: false };
    }

    return null;
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    this.setState({ ...this.state, form, data });
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, data } = this.state;
    form = Validate.input(name, checked ? 'true' : null, form);
    data[name] = checked ? 'true' : null;

    this.setState({ ...this.state, form, data });
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  submitForm = () => {
    const {
      addNominatedAccount,
      accounts: { ExtendedProperties: exProps = [] },
    } = this.props;
    const { data } = this.state;
    const {
      NominatedAccount: nominatedAccObj = null,
      LinkedAccountIdentifier = null,
    } = exProps.NominatedAccountDetails;
    //***Shold be removed from the code.
    const retrievedUserName = Session.customer();
    let addEditVal = 'EDIT';
    if (
      (nominatedAccObj &&
        nominatedAccObj.AccountNumber !== data['accountNumber']) ||
      nominatedAccObj.SortCode !== data['sortcode'].replace(/-/g, '')
    ) {
      addEditVal = 'ADD';
    }

    const request = {
      userName: retrievedUserName.Username,
      AccountHolderName: data['accountHolderName'],
      AccountNumber: data['accountNumber'],
      SortCode: data['sortcode'],
      FirstName: data['accountHolderName'],
      IsPrimary: 'True',
      Category: 'NominatedAccount',
      PayeeReference: data['payeeReference'],
      AddEditType: addEditVal,
      LinkedAccountIdentifier: LinkedAccountIdentifier
        ? LinkedAccountIdentifier
        : undefined,
    };

    addNominatedAccount(request);
  };

  clickNodocs = () => {
    this.setStage('Refer');
  };

  onSubmitHandler = () => {
    const { form } = this.state;
    const { updateUrl } = this.props;
    let validatedForm = Validate.form(form);
    if (!validatedForm.approved) {
      window.scrollTo(0, 0);
      this.setState({ ...this.state, form: validatedForm });
      return;
    }

    let stateData = {
      formData: this.state.data,
      url: {
        pageStage: 'Confirm',
      },
    };
    updateUrl(stateData);
    this.setState({ ...this.state, form: validatedForm, stage: 'Confirm' });
  };

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  // select current stage
  currentStage = () => {
    const { form } = this.state;
    const { updateUrl, match } = this.props;
    switch (this.state.stage) {
      case 'AccountDetails':
        return (
          <AccountDetails
            onChange={this.onChange}
            data={this.state.data}
            stage={this.state.stage}
            submit={this.onSubmitHandler}
            onBlur={this.onBlur}
            onClick={this.onClick}
            form={form}
          />
        );
      case 'Confirm':
        return (
          <Confirm
            submitForm={this.submitForm}
            updateUrl={updateUrl}
            setStage={this.setStage}
            data={this.state.data}
            stage={this.state.stage}
          />
        );
      case 'Success':
        return (
          <Success
            changeUrl={this.changeUrl}
            match={match}
            data={this.state.data}
          />
        );
      case 'Failed':
        return (
          <Failed
            changeUrl={this.changeUrl}
            match={match}
            data={this.state.data}
          />
        );
      default:
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-edit-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'detail':
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  render() {
    const {
      stage,
      status,
      addNominatedAccountResponse,
      nominatedAccountStatus,
    } = this.state;

    const {
      match,
      iframe,
      systemDownStatus, //From checkDownTime HOC
    } = this.props;

    let addNominatedIframe = addNominatedAccountResponse
      ? addNominatedAccountResponse.UploadDocLinkUrl
      : '';
    let iframeResponseUrl =
      nominatedAccountStatus &&
      nominatedAccountStatus.DocumentUploadStatus === 'PENDING' &&
      stage === 'Refer' &&
      iframe &&
      iframe.LinkUrl
        ? iframe.LinkUrl
        : '';

    const IframeUrl = addNominatedIframe
      ? addNominatedIframe
      : iframeResponseUrl;

    if (status === 'Failed') {
      return <Redirect to="/network-error" />;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    if (stage === 'pending') {
      return (
        <AddNominatedAccountReferredNoDocs
          mode="edit"
          clickNodocs={this.clickNodocs}
          history={this.props.history}
          match={match}
        />
      );
    }

    if (stage === 'successWithDocs') {
      return (
        <ReferredWithDocs
          mode="edit"
          history={this.props.history}
          match={match}
        />
      );
    }
    if (stage === 'Refer') {
      return (
        <React.Fragment>
          <AppMeta
            id="meta-data"
            contacts={CONTACTS}
            stage="child"
            title="Edit your nominated account for withdrawals - documents required"
            metaDescription="Edit your nominated account for withdrawals - documents required"
          />
          <Content
            tag="h1"
            cmsTag="Accounts:Nominated-account:Edit-nominated-account:Index:h1"
            copytext="Edit your nominated account for withdrawals"
          />
          <Markdown
            cmsTag="Accounts:Nominated-account:Edit-nominated-account:Index:Refer:Section-1"
            markdown={`\nWe need more documentation to prove the account is yours before we can update your nominated account.\n\nIf you do not have your documents, you can return to "Nominated account details" within the next 48 hours and your nominated account details will be saved. After this time, you will need to fill in the account details again.`}
          />
          <Iframe
            iframeclassname="w-100 trunarrative-iframe"
            title="Upload documents iframe"
            url={IframeUrl}
          />
          <div className="form-button-group">
            {this.getBackButton(match.params.page)}
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {systemDownStatus.msg}
        {stage === 'AccountDetails' ? (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              stage="child"
              contacts={CONTACTS}
              title="Edit your nominated account for withdrawals"
              metaDescription="Edit your nominated account for withdrawals"
            />
            <Content
              tag="h1"
              cmsTag="Accounts:Nominated-account:Edit-nominated-account:Index:h1"
              copytext="Edit your nominated account for withdrawals"
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {stage === 'Confirm' ? (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title="Edit your nominated account for withdrawals"
              metaDescription="Edit your nominated account for withdrawals"
            />
            <Content
              tag="h1"
              cmsTag="Accounts:Nominated-account:Edit-nominated-account:Index:h1"
              copytext="Edit your nominated account for withdrawals"
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {stage === 'Success' ? (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title="Your nominated account for withdrawals has been updated"
              metaDescription="Your nominated account for withdrawals has been updated"
            />
            <Content
              tag="h1"
              cmsTag="Accounts:Nominated-account:Edit-Nominated-account:Index:Success:h1"
              copytext="Your nominated account for withdrawals has been updated"
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {stage === 'ReferredWithDocs' ? (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              contacts={CONTACTS}
              stage="child"
              title="Your request to add a nominated account has been referred"
              metaDescription="Your request to add a nominated account has been referred"
            />
            <Content
              tag="h1"
              cmsTag="Accounts:Nominated-account:Edit-nominated-account:Index:Referred-with-docs:h1"
              copytext="Your request to add a nominated account has been referred"
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {stage === 'ReferredNoDocs' ? (
          <React.Fragment>
            <AppMeta
              id="meta-data"
              stage="child"
              contacts={CONTACTS}
              title="Your request to add a nominated account has been referred and you have failed to upload the additional documents for verification"
              metaDescription="Your request to add a nominated account has been referred and you have failed to upload the additional documents for verification"
            />
            <Content
              tag="h1"
              cmsTag="Accounts:Nominated-account:Edit-nominated-account:Index:Referred-no-docs:h1"
              copytext="Your request to add a nominated account has been referred and you have failed to upload the additional documents for verification"
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {this.currentStage()}
      </React.Fragment>
    );
  }
}

EditNominatedAccount.propTypes = {
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  getUserDetails: PropTypes.func,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  data: PropTypes.any,
  refreshUrl: PropTypes.any,
  makeWithdrawResponse: PropTypes.any,
  addNominatedAccount: PropTypes.func,
  resetGetNominatedAccountStatus: PropTypes.func,
  stateData: PropTypes.any,
  getNominatedAccountStatus: PropTypes.func,
  updateUrl: PropTypes.any,
  urlParams: PropTypes.any,
  userDetails: PropTypes.object,
  iframe: PropTypes.any,
  nominatedAccountStatus: PropTypes.any,
  resetCreateGoals: PropTypes.any,
  resetAddNominatedAccount: PropTypes.any,
  addNominatedAccountResponse: PropTypes.object,
  account: PropTypes.any,
  accounts: PropTypes.object,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    userManagementDefinition,
    accountsDefinition,
    customUserManagementDefinition,
  } = state;
  return {
    ...userManagementDefinition,
    ...accountsDefinition,
    ...customUserManagementDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      {
        ...mapDispatchToProps,
        ...brandUserManagementDispatch,
        ...userManagementDispatch,
      },
    )(withRouter(EditNominatedAccount)),
  ),
  storage.name,
);
