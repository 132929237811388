import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { CONSTANTS, FormattedDate } from '@myie/interact-dom';
import { Content, FormattedCurrency } from '@myie/interact-dom';

class Transaction extends React.Component {
  render() {
    const { props } = this;

    const { transaction, AccountCurrencyCode } = props;
    const {
      ExtendedProperties: { Balance, Pending, Amount, TransactionDate } = {},
      Description,
    } = transaction;

    const { position } = props;

    let balance = Balance;

    return (
      <Col
        id={`data-transaction-id-${position}`}
        data-transaction-id={transaction.TransactionIdentifier}
        //itemProp={`transactionDate-${transaction.Date}`}
        xs={12}
        className="transaction-row"
      >
        <Row>
          <Col
            xs={12}
            sm={3}
            md={3}
            className="col-transaction align-self-center"
          >
            <div className="transaction-date">
              <FormattedDate
                date={TransactionDate}
                format={CONSTANTS.STANDARD_DATE_FORMAT}
                currentFormat={CONSTANTS.DATE_FORMAT_YYYYMMDD}
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={3}
            md={3}
            className="col-transaction align-self-center"
          >
            <div className="transaction-description">
              {Description}{' '}
              {Pending &&
              this.props.productType !== CONSTANTS.ACCOUNT_TYPE_MORTGAGE
                ? '(Pending)'
                : ''}{' '}
            </div>
          </Col>
          <Col
            xs={6}
            sm={3}
            md={3}
            className="text-sm-right col-transaction align-self-center"
          >
            <Content
              tagClassName="transaction-in-out d-inline d-sm-none d-md-none d-lg-none d-xl-none"
              tag="small"
              cmsTag="Accounts:Components:Detail-page:TabsAccounts:Components:Detail-page:Tabs:Transaction:Transaction:Amount-"
              copytext="Amount: "
            />
            <span className="transaction-payments">
              <FormattedCurrency
                quantity={Amount}
                currency={AccountCurrencyCode}
              />
            </span>
          </Col>
          <Col
            xs={6}
            sm={3}
            md={3}
            className="text-sm-right col-transaction align-self-center"
          >
            <Content
              tagClassName="transaction-in-out d-inline d-sm-none d-md-none d-lg-none d-xl-none"
              tag="small"
              cmsTag="Accounts:Components:Detail-page:TabsAccounts:Components:Detail-page:Tabs:Transaction:Transaction:Balance-"
              copytext="Balance: "
            />
            <span className="transaction-balance">
              <FormattedCurrency
                quantity={balance}
                currency={AccountCurrencyCode}
              />
            </span>
          </Col>
        </Row>
      </Col>
    );
  }
}

Transaction.propTypes = {
  AccountCurrencyCode: PropTypes.any,
  position: PropTypes.any,
  productType: PropTypes.string,
  transaction: PropTypes.any,
};

export default Transaction;
