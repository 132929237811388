import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Content, Markdown } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import WithdrawalAccount from '../../shared/WithdrawalAccount';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import WithdrawalForm from './WithdrawalForm';

class Create extends Component {
  constructor(props) {
    super(props);
    const { allProducts, getAllProducts } = this.props;
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  submitForm = (form, isSubmit = false) => {
    const { submitForm } = this.props;
    submitForm('ReviewNotice', form, isSubmit);
  };

  render() {
    const {
      account,
      fromAccounts,
      onChangeAccount,
      onCancelled,
      actionDate,
      noticeExpiryDate,
      isFetching,
      setStateDynamic,
      saveData,
      allProducts,
    } = this.props;

    if (!allProducts) {
      return null;
    }
    const product = allProducts.find(
      product =>
        product.Product_Code === account.ExtendedProperties.ProductCode,
    );

    const noticePeriod =
      product && product.Notice_Period_Days ? product.Notice_Period_Days : 0;

    return (
      <React.Fragment>
        <section>
          <Content
            tag="h2"
            cmsTag="Savings-servicing:notice-to-withdrawal:Create-notice-to-withdraw:Create:h2-1"
            copytext="Notice to withdraw details"
          />
          <Markdown
            cmsTag="Savings-servicing:notice-to-withdrawal:Create-notice-to-withdraw:Create:section-1"
            markdown={`\nWe will need some information to add a new notice to withdraw.\n\nIf you need to change your nominated account please ensure this is done before the notice period starts. You must also ensure you have the minimum balance in the account at the point you undertake the withdrawal.\n\nSome savings accounts are subject to specific conditions such as notice periods and withdrawal restrictions.\n\nIf you break these terms, you may receive interest penalties or a forfeit of bonus. If you do not have enough interest built up to pay this, the rest will be taken from your balance when you close your account or when your interest is next due to be added to your balance.\n\nPlease check our savings account information or the Closed Feature Guide on our website or contact us for more information.`}
          />
          <RedirectByAccountDropdown
            label={'Notice Account'}
            extendedId="notice-account"
            accOptions={fromAccounts}
            baseUrl="/notice-to-withdraw/create/"
            defaultSelect={`${account.AccountKey.Key}`}
            onChangeAccount={onChangeAccount}
            filter="noticeCreate"
            id="create-notice-to-withdraw"
          />
        </section>
        <section>
          <Content
            tag="h2"
            cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Create:h2-2"
            copytext="Account details"
          />
          <WithdrawalAccount
            account={account}
            withdrawDate={actionDate}
            noticeExpiryDate={noticeExpiryDate}
            noticePeriod={noticePeriod}
          />

          {!isFetching && Object.keys(account).length ? (
            <WithdrawalForm
              saveData={saveData}
              data={this.props.data}
              setStateDynamic={setStateDynamic}
              account={account}
              actionDate={actionDate}
              noticeExpiryDate={noticeExpiryDate}
              onCancelled={onCancelled}
              onSubmit={this.submitForm}
            />
          ) : null}
        </section>
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  accounts: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  data: PropTypes.any,
  actionDate: PropTypes.any,
  noticeExpiryDate: PropTypes.any,
  onChangeAccount: PropTypes.func,
  onCancelled: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  makeaWithdraw: PropTypes.func,
  account: PropTypes.any,
  saveData: PropTypes.any,
  setStateDynamic: PropTypes.func,
  submitForm: PropTypes.any,
  form: PropTypes.any,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  fromAccounts: PropTypes.array,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(Create));
