import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BreadcrumbItem, Alert } from 'reactstrap';
import { mapDispatchToProps as mapDispatchToProps_shared } from '@myie/interact-shared';
import { mapDispatchToProps as brandAuthenticationDispatch } from '@myie/interact-brand-authentication';
import { mapDispatchToProps as authenticationDispatch } from '@myie/interact-authentication';
import { storage } from './storageName';
import { Redirect } from 'react-router-dom';
import PersonalDetails from '../shared/PersonalDetails';
import SecurityDetails from './SecurityDetails';
import AccountDetails from '../shared/AccountDetails';
import { Session } from '@myie/interact';

import {
  TimeOut,
  Switch,
  Content,
  Markdown,
  AppMeta,
  stateUtility,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';

const META_TITLE = {
  PersonalDetails: 'Forgotten password Step 1',
  accountDetails: 'Forgotten password Step 2',
  Security: 'Forgotten password Step 3',
};
class Retrieve extends React.Component {
  constructor(props) {
    super(props);
    const { urlParams, refreshUrl } = props;
    const stateList = ['PersonalDetails', 'accountDetails', 'Security'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);

    let stage =
      props.stateData &&
      props.stateData.pageStage &&
      props.urlParams &&
      props.urlParams.pageStage
        ? props.urlParams.pageStage
        : 'PersonalDetails';
    if (!hasStage) {
      stage = 'PersonalDetails';
      props.resetForgottenPassword();
      refreshUrl(stateList);
    }

    this.state = {
      stateList: stateList,
      stage: stage,
      message: null,
      credentialErrors: [],
      requestFailedCount: 0,
      data:
        props.stateData && props.stateData.formData
          ? props.stateData.formData
          : {},
      RequireActivationTicket: null,
      keyCount: 1,
    };
    stateUtility.setGlobalValue({ storage: storage.name });
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (prevProps, nextState) => {
    const {
      resetForgottenPassword,
      setupCredentialsActivate,
      forgottenPasswordDetails = {},
      updateForgottenDetails = {},
      updateUrl,
    } = prevProps;
    const credentialErrors =
      forgottenPasswordDetails.ExtendedProperties &&
      forgottenPasswordDetails.ExtendedProperties.IdentityResponse &&
      forgottenPasswordDetails.ExtendedProperties.IdentityResponse
        .CredentialErrors
        ? forgottenPasswordDetails.ExtendedProperties.IdentityResponse
            .CredentialErrors
        : null;

    let failedCount = nextState.requestFailedCount;
    if (credentialErrors) {
      failedCount = failedCount + 1;
    }

    // If personal details step - Success
    if (
      setupCredentialsActivate &&
      setupCredentialsActivate.Status === 'Success'
    ) {
      return {
        stage: 'Security', //Go to next step - Security
        message: null,

        data: {}, // Security details step does not require previous data
      };
    }

    //if account restricted or sopra invalid status
    if (
      forgottenPasswordDetails.Status === 'AccountRestricted' ||
      forgottenPasswordDetails.Status === 'InvalidStatus'
    ) {
      failedCount = failedCount + 1;
      resetForgottenPassword();
      return {
        data: {},
        stage: 'PersonalDetails',
        requestFailedCount: failedCount,
        credentialErrors: credentialErrors,
        message: forgottenPasswordDetails.Status,
      };
    }

    // If personal details step - RequireActivation
    if (forgottenPasswordDetails.Status === 'RequireActivation') {
      resetForgottenPassword();
      // personal data
      let stateData = {
        RequireActivationTicket:
          forgottenPasswordDetails.RequireActivationTicket,
        url: {
          pageStage: 'Security',
        },
      };
      updateUrl(stateData);
      return {
        data: {},
        stage: 'Security',
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
        RequireActivationTicket: {
          RequireActivationTicket:
            forgottenPasswordDetails.RequireActivationTicket,
          ExtendedProperties: null,
        },
        message: null,
      };
    }

    if (forgottenPasswordDetails.Status === 'NotRegistered') {
      resetForgottenPassword();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        data: {},
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
        stage: 'PersonalDetails',
        message: 'NotRegistered',
      };
    }

    if (forgottenPasswordDetails.Status === 'IdentifyAndVerifyFailed') {
      resetForgottenPassword();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
        message: 'IdentifyAndVerifyFailed',
      };
    }

    if (updateForgottenDetails.Status === 'InvalidTicket') {
      resetForgottenPassword();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        data: {},
        stage: 'PersonalDetails',
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
        message: 'InvalidTicket',
      };
    }

    if (
      updateForgottenDetails &&
      updateForgottenDetails.ExtendedProperties &&
      updateForgottenDetails.ExtendedProperties.IdentityResponse &&
      updateForgottenDetails.ExtendedProperties.IdentityResponse
        .CredentialErrors
    ) {
      resetForgottenPassword();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        credentialErrors:
          updateForgottenDetails.ExtendedProperties.IdentityResponse
            .CredentialErrors,
        fetching: false,
        requestFailedCount: failedCount,
      };
    }

    // If ticket invalid
    if (
      setupCredentialsActivate &&
      setupCredentialsActivate.Status === 'InvalidTicket'
    ) {
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        message: 'InvalidTicket',
      };
    }

    // If ticket invalid
    if (
      setupCredentialsActivate &&
      setupCredentialsActivate.Status === 'IdentifyAndVerifyFailed'
    ) {
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        message: 'IdentifyAndVerifyFailed',
      };
    }

    return null;
  };

  componentDidUpdate(prevProps) {
    const { stage, stateList } = this.state;
    let hasStage = false;
    if (prevProps.urlParams && prevProps.urlParams.pageStage) {
      hasStage = stateList.find(
        element => element === prevProps.urlParams.pageStage,
      );
    }
    if (
      prevProps.stateData &&
      prevProps.stateData.url &&
      prevProps.stateData.url.pageStage &&
      prevProps.urlParams &&
      prevProps.urlParams.pageStage &&
      hasStage &&
      stage !== prevProps.urlParams.pageStage
    ) {
      this.setState({
        ...this.state,
        stage: prevProps.urlParams.pageStage,
      });
    }
  }

  componentWillUnmount() {
    const { resetComponentState } = this.props;
    resetComponentState();
  }

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null, credentialErrors: [] });
  };

  // change the stage of the form
  setStage = stage => {
    if (stage !== 'PersonalDetails') {
      this.setState({
        ...this.state,
        stage,
        credentialErrors: [],
        message: null,
      });
    } else {
      this.setState({ ...this.state, stage, message: null });
    }
  };

  getCredentialErrors = credentialErrors => {
    if (credentialErrors) {
      return credentialErrors.map(item => {
        switch (item) {
          case 'Dob':
            return (
              <Alert id={item} key={item} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Retrieve:DOB-error"
                  copytext="Your passcode cannot be your date of birth."
                />
              </Alert>
            );

          case 'AllSame':
            return (
              <Alert id={item} key={item} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Retrieve:All-same-error"
                  copytext="Your passcode cannot be the same number repeated six times."
                />
              </Alert>
            );

          case 'Sequential':
            return (
              <Alert id={item} key={item} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Retrieve:Sequential-error"
                  copytext="Your passcode cannot include sequential numbers."
                />
              </Alert>
            );

          case 'Known':
            return (
              <Alert id={item} key={item} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Retrieve:Known-error"
                  copytext="You have entered a common password, please try again."
                />
              </Alert>
            );

          case 'Username':
            return (
              <Alert id={item} key={item} color="danger">
                <Content
                  cmsTag="Authentication:Forgotten-password:Retrieve:Username-error"
                  copytext="Your username and password cannot be the same."
                />
              </Alert>
            );
          case 'InvalidClientStatus':
            return (
              <Alert key={item} color="danger">
                <Markdown
                  cmsTag="Authentication:Forgotten-password:Retrieve:Invalid-client-status-error"
                  template={{
                    markdown: {
                      phone: CONTACTS.phone,
                      phoneLink: CONTACTS.phoneLink,
                      companyName: CONTACTS.companyName,
                    },
                  }}
                  markdown={`There has been an issue verifying your identity. Please contact $[companyName] on <a href="tel:$[phoneLink]">$[phone]</a>, quoting status code 101.`}
                />
              </Alert>
            );
          case 'OnlineStatusX':
            return (
              <Alert key={item} color="danger">
                <Markdown
                  cmsTag="Authentication:Forgotten-password:Retrieve:online-restricted"
                  template={{
                    markdown: {
                      phone: CONTACTS.phone,
                      phoneLink: CONTACTS.phoneLink,
                      companyName: CONTACTS.companyName,
                    },
                  }}
                  markdown={`You are restricted from accessing your accounts online. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
                />
              </Alert>
            );
          case 'InvalidAccountStatus':
            if (!credentialErrors.includes('OnlineStatusX')) {
              return (
                <Alert color="danger" key={item}>
                  <Markdown
                    cmsTag="Authentication:Forgotten-password:Retrieve:Invalid-account-status-error"
                    template={{
                      markdown: {
                        phone: CONTACTS.phone,
                        phoneLink: CONTACTS.phoneLink,
                        companyName: CONTACTS.companyName,
                      },
                    }}
                    markdown={`There has been an issue accessing your account. Please contact $[companyName] on <a href="tel:$[phoneLink]">$[phone]</a>, quoting status code 102.`}
                  />
                </Alert>
              );
            }
            break;
          default:
        }
        return null;
      });
    }
  };

  timeoutReset = () => {
    this.setState({
      ...this.state,
      data: {},
      stage: 'PersonalDetails',
      message: 'Timeout',
    });
  };

  timeoutObj = {
    history: this.props.history,
    userLogout: this.props.userLogout,
    message: 'ForgottenPasswordTimeout',
    url: '/forgotten-password',
    onTimeout: this.timeoutReset,
    showTimeoutModal: this.props.showTimeoutModal,
    type: 'FORGOT_CREDENTIALS',
  };

  timeout = new TimeOut(this.timeoutObj);

  // verify the identification and verfication entries
  verify = () => {
    const { data } = this.state;
    const { resetForgotten } = this.props;
    let accountNumber = '';
    switch (data.type_Of_account) {
      case 'SavingsAccount':
        accountNumber = data.savings_account_number;
        break;
      case 'MortgageAccount':
        accountNumber = data.mortgage_account_number;
        break;
      default:
    }

    // personal data
    const request = {
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
        {
          Type: 'lastName',
          Value: data.lastname,
        },
        {
          Type: 'dateOfBirth',
          Value: data.b_day,
        },
        {
          Type: 'postCode',
          Value: data.postcode,
        },
        {
          Type: 'accountNumber',
          Value: accountNumber,
        },
      ],
      ExtendedProperties: null,
    };

    let nextKeyCount = this.state.keyCount + 1;
    this.setState({
      data: {},
      dob: data.b_day,
      keyCount: nextKeyCount,
    });
    resetForgotten(request);
  };

  // select current stage
  currentStage = () => {
    const { message } = this.state;
    const {
      updateUrl,
      stateData,
      updateState,
      urlParams,
      refreshUrl,
    } = this.props;
    switch (this.state.stage) {
      case 'accountDetails':
        return (
          <AccountDetails
            saveData={this.saveData}
            setStage={this.setStage}
            data={this.state.data}
            updateUrl={updateUrl}
            verify={this.verify}
            message={message}
            privacyPolicy={false}
          />
        );

      case 'Security':
        return (
          <SecurityDetails
            urlParams={urlParams}
            updateState={updateState}
            updateUrl={updateUrl}
            stateData={stateData}
            formData={this.state.data}
            dateOfBirth={this.state.dateOfBirth}
            timeout={this.timeout}
            dob={this.state.dob}
            RequireActivationTicket={this.state.RequireActivationTicket}
          />
        );
      case 'PersonalDetails':
      default:
        return (
          <PersonalDetails
            verify={this.verify}
            data={this.state.data}
            urlParams={urlParams}
            updateUrl={updateUrl}
            refreshUrl={refreshUrl}
            saveData={this.saveData}
            setStage={this.setStage}
            key={this.state.keyCount}
            disableForm={this.props.isFetching}
          />
        );
    }
  };

  render() {
    const {
      forgottenPasswordDetails = {},
      updateForgottenDetails = {},
    } = this.props;
    let { requestFailedCount } = this.state;
    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }

    if (requestFailedCount === 3) {
      return <Redirect to="/registration/failed" />;
    }

    switch (updateForgottenDetails.Status) {
      case 'Success':
        Session.set(
          updateForgottenDetails.SessionTicket,
          updateForgottenDetails.Customer,
        );
        return <Redirect to="/accounts/list" />;
      case 'Blocked':
        return <Redirect to="/forgotten-password/access-denied" />;
      default:
    }

    switch (forgottenPasswordDetails.Status) {
      case 'Blocked':
        return <Redirect to="/forgotten-password/access-denied" />;
      default:
    }

    const { message, stage } = this.state;

    return (
      <div id="forgotten-password-retrieve">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title={META_TITLE[stage]}
          metaDescription={META_TITLE[stage]}
        />

        {stage === 'PersonalDetails' ? (
          <Content
            tag="h1"
            cmsTag="Authentication:Forgotten-password:Retrieve:h1"
            copytext="Forgotten password or passcode"
          />
        ) : (
          ''
        )}
        {stage === 'accountDetails' ? (
          <Content
            tag="h1"
            cmsTag="Authentication:Forgotten-password:Retrieve:h1"
            copytext="Forgotten password or passcode"
          />
        ) : (
          ''
        )}
        {stage === 'Security' ? (
          <Content
            tag="h1"
            cmsTag="Authentication:Forgotten-password:Retrieve:Security:h1"
            copytext="Reset password or passcode"
          />
        ) : (
          ''
        )}
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={this.state.stage === 'PersonalDetails'}
              className={
                this.state.stage === 'accountDetails' ||
                this.state.stage === 'security' ||
                this.state.stage === 'Security'
                  ? 'completed'
                  : ''
              }
            >
              {this.state.stage === 'PersonalDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'accountDetails' ||
              this.state.stage === 'security' ||
              this.state.stage === 'Security' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                3:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Personal-details"
                copytext="Personal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem
              active={this.state.stage === 'accountDetails'}
              className={
                this.state.stage === 'security' ||
                this.state.stage === 'Security'
                  ? 'completed'
                  : ''
              }
            >
              {this.state.stage === 'accountDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'security' ||
              this.state.stage === 'Security' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                3:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem
              active={
                this.state.stage === 'security' ||
                this.state.stage === 'Security'
              }
            >
              {this.state.stage === 'security' ||
              this.state.stage === 'Security' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-3-" copytext="Step 3 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                3:&nbsp;
              </span>
              <Content cmsTag="BREADCRUMB:Security" copytext="Security" />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
        {!this.state.credentialErrors ? (
          <Switch
            id="forgotten-password-retrieve-error"
            value={message || ''}
            tag="div"
            className="alert alert-danger"
            role="alert"
            scrolltotop={true}
            template={{
              AccountRestricted: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                companyName: CONTACTS.companyName,
                companySiteName: CONTACTS.companySiteName,
              },
              InvalidStatus: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                companyName: CONTACTS.companyName,
                companySiteName: CONTACTS.companySiteName,
              },
              NotRegistered: {
                companySiteName: CONTACTS.companySiteName,
              },
            }}
            contents={{
              AccountRestricted: {
                defaultValue:
                  'You are restricted from accessing your accounts online. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.',
              },
              InvalidStatus: {
                defaultValue:
                  'There has been an issue accessing your account. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.',
              },
              NotRegistered: {
                defaultValue:
                  'It seems you are not yet registered for $[companySiteName]. Please register to use this service.',
              },
              Timeout: {
                defaultValue:
                  'You took too long to complete the credentials reset process. Please try again.',
              },
              IdentifyAndVerifyFailed: {
                defaultValue:
                  'The details you have entered do not match our records. Please try again.',
              },
              InvalidTicket: {
                defaultValue:
                  'Your code has expired. Please restart the forgotten password process.',
              },
            }}
          />
        ) : (
          ''
        )}
        {this.getCredentialErrors(this.state.credentialErrors)}
        {this.state.stage === 'PersonalDetails' ? (
          <React.Fragment>
            <Markdown
              cmsTag="Authentication:Forgotten-password:Retrieve:alert-1"
              markdown={`\nIf you're having trouble signing in, you can reset your security details. 
              \nYou'll need to confirm your account and personal details. We will send an authentication code via a text message to the mobile number that is registered to your account to enter before you can reset your details.`}
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {this.currentStage()}
      </div>
    );
  }
}
Retrieve.propTypes = {
  stateData: PropTypes.object,
  refreshUrl: PropTypes.any,
  urlParams: PropTypes.any,
  updateUrl: PropTypes.any,
  match: PropTypes.object,
  forgottenPasswordDetails: PropTypes.object,
  history: PropTypes.any,
  resetComponentState: PropTypes.any,
  updateState: PropTypes.func,
  resetForgotten: PropTypes.any,
  resetForgottenPassword: PropTypes.any,
  showTimeoutModal: PropTypes.any,
  updateForgottenDetails: PropTypes.object,
  userLogout: PropTypes.any,
  setupCredentialsActivate: PropTypes.any,
  isFetching: PropTypes.bool,
};

const mapStateToProps = state => {
  const {
    authenticationDefinition,
    custombrandAuthenticationDefinition,
  } = state;
  return {
    ...authenticationDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    {
      ...mapDispatchToProps_shared,
      ...brandAuthenticationDispatch,
      ...authenticationDispatch,
    },
  )(Retrieve),
  storage.name,
);
