import React from 'react';
import PropTypes from 'prop-types';
import { Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Confirmation extends React.Component {
  deletThiseGoal = () => {
    const { deleteGoal } = this.props;
    deleteGoal();
  };

  render() {
    const { goal, getBackButton, page } = this.props;
    return (
      <div id="Savings-servicing-delete">
        <AppMeta
          id="delete-savings-goal"
          contacts={CONTACTS}
          stage="child"
          title="Goal deleted"
          metaDescription="Goal deleted"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Delete-goal:Confirmation:h1"
          template={{
            copytext: {
              goalName: goal.Name,
            },
          }}
          copytext={`You have deleted your $[goalName] goal`}
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Delete-goal:Confirmation:p1"
          copytext={`Your account balance has not been affected. You can add another goal by navigating to the savings goal list page and clicking "Add a savings goal".`}
        />
        <div className="form-button-group">{getBackButton(page)}</div>
      </div>
    );
  }
}

Confirmation.propTypes = {
  deleteGoal: PropTypes.any,
  getBackButton: PropTypes.func,
  page: PropTypes.string,
  goal: PropTypes.any,
};

export default Confirmation;
