import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route } from 'react-router-dom';
import MakeADeposit from './MakeADeposit/MakeADeposit';

class MakeADepositRoute extends Component {
  render() {
    return (
      <div id="make-a-deposit">
        <Switch>
          <PrivateRoute
            path="/make-a-deposit/:page?/:id?/:toId?"
            component={MakeADeposit}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export { MakeADepositRoute };
