import React, { Component } from 'react';
import { Form, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { FormErrorList } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps as mapSavingDispatchToProps } from '@myie/interact-account-type-savings';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { Validate, Utility } from '@myie/interact';
import Amount from '../../shared/inputAmount';
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = { form: {} };
  }

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };
  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'detail':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    const { data, setStateDynamic } = this.props;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    setStateDynamic({
      data,
    });
    this.setState({ ...this.state, form });
  };

  updateCurrency = e => {
    let { name, value } = e.target;
    const { data, setStateDynamic } = this.props;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;
    setStateDynamic({
      data,
    });
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const { submitForm } = this.props;
    let { form } = this.state;

    let newForm = form;

    newForm = Validate.form(newForm);
    this.setState({ ...this.state, form: newForm });
    if (!newForm.approved) {
      window.scrollTo(0, 0);
      return;
    }
    if (typeof submitForm === 'function') {
      submitForm(newForm);
    }
  };

  setAmountRules = ({ amount }) => {
    const form = { ...this.state.form, amount };
    this.setState({
      ...this.state,
      form,
    });
  };

  render() {
    const {
      accounts = {},
      accountList,
      allProducts,
      account,
      data,
    } = this.props;
    const { form } = this.state || {};
    let options = [];

    if (!allProducts && !accounts) {
      return null;
    }

    if (accounts) {
      options = accountList(accounts ? accounts.Accounts : []);
      if (!options.length) {
        return <Redirect to="/make-a-deposit" />;
      }
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Make a deposit step 1"
          metaDescription="Make a deposit step 1"
        />
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Markdown
          template={{
            markdown: {
              companyName: CONTACTS.companyName,
              sortcode: CONTACTS.sortcode,
              accountNumber: CONTACTS.accountNumber,
            },
          }}
          cmsTag="Savings-servicing:Make-a-deposit:Make-a-deposit:Create:p1"
          markdown={`\nYou can make deposits into your account using a debit card or using our bank details: sort code $[sortcode] and account number $[accountNumber]. If depositing by bank transfer, please quote your 8-digit $[companyName] account number (the savings account you wish to transfer the money into) as the reference. Your payment will not be accepted if you do not use a valid reference.\n\nMake sure you check the terms and conditions of the account before making your deposit. Your balance will be updated the following working day.\n\nPlease be aware, to make your payment by debit card you will be navigated to the Worldpay payment gateway.`}
        />
        <Form
          autoComplete="off"
          id="make-a-deposit-form"
          onSubmit={this.onSubmitHandler}
        >
          <RedirectByAccountDropdown
            extendedId="Account-to-make-deposit-to"
            accounts={{ Accounts: options }}
            label={'Account to make deposit to'}
            baseUrl={
              `/make-a-deposit/summary/` +
              (account ? Utility.hexEncode(account.AccountKey) : '')
            }
            defaultSelect={`${
              account && account.AccountKey ? account.AccountKey.Key : ''
            }`}
            onChangeAccount={() => {}}
            id="accountFormValidation"
            suffix={``}
          />
          {account && (
            <Amount
              form={form}
              account={account}
              onChange={this.updateCurrency}
              onBlur={this.onBlur}
              data={data}
              code={account && account.CurrencyCode}
              setAmountRules={this.setAmountRules}
              label="Amount to deposit"
              cmsTag="GLOBAL:Amount-to-deposit"
              showErrors={true}
              validation={form.amount}
              field="amount"
              id="amount"
              inputGroupClassName="half-field"
              dp={2}
              ruleType="make_deposit"
            />
          )}

          <div className="form-button-group">
            {account && (
              <Button id="create-make-deposit" type="submit" color="primary">
                <Content
                  cmsTag="GLOBAL:Enter-payment-details"
                  copytext="Enter payment details"
                />
              </Button>
            )}
            {this.getBackButton(this.props.match?.params?.page)}
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  accounts: PropTypes.any,
  data: PropTypes.any,
  saveData: PropTypes.any,
  setStateDynamic: PropTypes.func,
  depositAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  actionDate: PropTypes.any,
  onChangeAccount: PropTypes.func,
  //callAccountValidations: PropTypes.func,
  combineForm: PropTypes.any,
  onCancelled: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  makeaDeposit: PropTypes.func,
  accountForm: PropTypes.any,
  account: PropTypes.any,
  accountList: PropTypes.func,
  submitForm: PropTypes.func,
  updateAccountKey: PropTypes.func,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  fetchSavingsAccountTransactions: PropTypes.func,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapSavingsServicingDispatchToProps,
    ...mapSavingDispatchToProps,
  },
)(withRouter(Create));
