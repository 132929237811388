import React, { Component } from 'react';
import { Content, AppMeta, Switch } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-brand-savings-servicing';
import { CONTACTS } from '@myie/interact-local-dom';

class CardDetails extends Component {
  componentDidMount() {
    const { depositResponse, updateUrl } = this.props;
    let stateData = {
      formData: null,
      depositData: depositResponse,
      url: {
        pageStage: 'Confirmation',
      },
    };
    window.scrollTo(0, 0);
    updateUrl(stateData);
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: '',
      disabled: false,
    };
  }
  submit = () => {
    const { onSubmitDeposit } = this.props;
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    onSubmitDeposit();
  };

  editDeposit = () => {
    const { updateField, setStateDynamic, data, combinedForm } = this.props;
    let stateData = {
      url: {
        pageStage: 'createDeposit',
      },
    };
    this.props.updateUrl(stateData);
    const form = updateField('accept', false);
    data['accept'] = false;
    setStateDynamic({
      stage: 'createDeposit',
      data: data,
      accountForm: form,
      combinedForm: {
        ...combinedForm,
        ...form,
      },
    });
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeDepositResponse = {} } = nextProps;

    if (
      makeDepositResponse &&
      makeDepositResponse.ResponseStatus === 'Failed'
    ) {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return null;
  };

  render() {
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Make a deposit step 2"
          metaDescription="Make a deposit step 2"
        />
        <Switch
          id="create-transfer-review-alert"
          value={this.state.message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          scrolltotop={true}
          contents={{
            failed: {
              defaultValue: 'Make a deposit failed',
            },
          }}
        />
        <p>Worldpay iframe goes here</p>

        <div className="form-button-group">
          <Button
            id="edit-transfer-details"
            onClick={this.editDeposit}
            color="light"
            disabled={this.state.disabled}
          >
            <Content
              cmsTag="GLOBAL:Edit-account-details"
              copytext="Edit account details"
            />
          </Button>
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

CardDetails.propTypes = {
  match: PropTypes.any,
  updateUrl: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  setStateDynamic: PropTypes.any,
  combinedForm: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  createResponse: PropTypes.any,
  onSubmitDeposit: PropTypes.any,
  account: PropTypes.any,
  setStage: PropTypes.any,
  updateField: PropTypes.any,
  form: PropTypes.any,
  depositResponse: PropTypes.any,
  actionDate: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CardDetails));
