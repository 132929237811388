import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import { Button } from 'reactstrap';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { connect } from 'react-redux';
import { Content, CONSTANTS } from '@myie/interact-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { Link } from 'react-router-dom';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';

class Create extends Component {
  goalFilter = accounts => {
    if (accounts) {
      return accounts.filter(account => {
        const products = this.props.allProducts;
        const { ExtendedProperties } = account;
        const product = products.find(product => {
          return product.Product_Code === ExtendedProperties?.ProductCode;
        });
        if (product) {
          return (
            account.AccountStatus !== 'Closed' &&
            account.ExtendedProperties?.AccountMode !==
              CONSTANTS.ACCOUNT_CLOSED &&
            product.Savings_Goal_Accounts === 'Y'
          );
        } else {
          return false;
        }
      });
    }
    return [];
  };

  clickNext = () => {
    const { setStateDynamic, updateUrl } = this.props;
    let stateData = {
      formData: this.props.data,
      url: {
        pageStage: 'selectGoal',
      },
    };
    updateUrl(stateData);
    setStateDynamic({
      stage: 'selectGoal',
    });
  };
  getlinks = goal => {
    const { account, page } = this.props;
    let backPage = CONSTANTS.SOURCE_PAGE_GOAL_LIST;
    if ((goal && account) || account?.ExtendedProperties?.HasSavingsGoals) {
      if (page !== 'undefined') {
        backPage = page;
      }
      return (
        <Link
          id="select-goal-purpose"
          className="btn btn-primary"
          role="button"
          to={
            '/money-tools/savings-goals/replace-goal/' +
            backPage +
            '/' +
            Utility.hexEncode(account.AccountKey.Key)
          }
        >
          <Content
            cmsTag="Savings-servicing:Money-tools:Savings-goals:Add-a-new-goal:Create:Select-goal-purpose"
            copytext="Select goal purpose"
          />
        </Link>
      );
    } else {
      if (account) {
        return (
          <Button id="add-a-purpose" onClick={this.clickNext} color="primary">
            <Content
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Add-a-new-goal:Create:Select-goal-purpose"
              copytext="Select goal purpose"
            />
          </Button>
        );
      } else {
        return (
          <Button id="add-a-purpose" disabled color="primary">
            <Content
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Add-a-new-goal:Create:Select-goal-purpose"
              copytext="Select goal purpose"
            />
          </Button>
        );
      }
    }
  };
  render() {
    const {
      account,
      accounts = {},
      onChangeAccount,
      page,
      match,
      viewGoalDetails,
      getBackButton,
    } = this.props;

    let goal;
    if (accounts && viewGoalDetails && viewGoalDetails.Goals) {
      goal = viewGoalDetails.Goals.find(element => {
        if (element) {
          return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
        }

        return null;
      });
    }

    return (
      <React.Fragment>
        <Content
          tag="p"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Add-a-new-goal:Create:p1"
          copytext="To start saving for your goal, you can link this goal with an existing savings account."
        />
        <RedirectByAccountDropdown
          accounts={accounts}
          label="Select the account you want to link to"
          extendedId="savings-goals"
          optionFunc={this.goalFilter}
          baseUrl={`/money-tools/savings-goals/create-goals/${page}/`}
          suffixAlert={true}
          defaultSelect={`${
            account && account.AccountKey ? account.AccountKey.Key : ''
          }`}
          id="savings-goals"
          onChangeAccount={onChangeAccount}
          filter="label"
          suffix={`Please note: if the account already has an existing goal, your new goal will replace it.`}
          sourcePage={CONSTANTS.SOURCE_PAGE_ADD_GOAL}
        />

        <div className="form-button-group">
          {this.getlinks(goal)}
          {getBackButton(page)}
        </div>
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  accounts: PropTypes.any,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  page: PropTypes.string,
  onCreate: PropTypes.func,
  setStateDynamic: PropTypes.func,
  match: PropTypes.any,
  allProducts: PropTypes.any,
  viewGoalDetails: PropTypes.object,
  data: PropTypes.object,
  actionDate: PropTypes.any,
  onChangeAccount: PropTypes.func,
  onCancelled: PropTypes.func,
  updateUrl: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  account: PropTypes.any,
  submitForm: PropTypes.any,
  goal: PropTypes.any,
  getBackButton: PropTypes.func,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(Create));
