import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FormattedCurrency, Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';

const SuccessfulPayment = props => {
  const { account, form, referrer = '/accounts', payment } = props;
  return (
    <div id="paysavings-success">
      <p>
        <Content
          cmsTag="Savings-servicing:Pay-by-card:Successful-payment:alert-1"
          copytext="Payment completed successfully."
        />
      </p>
      <Row tag="dl" title="Card details">
        <Col tag="dt" sm={6} md={3}>
          <Content cmsTag="GLOBAL:From" copytext="From" />
        </Col>
        <Col tag="dd" sm={6} md={9}>
          <Content
            cmsTag="GLOBAL:ending-in-CARD-NUMBER"
            template={{
              copytext: {
                cardNumber: form.cardNumber.value.substr(-4),
              },
            }}
            copytext="ending in $[cardNumber]"
          />
        </Col>
        <Col tag="dt" sm={6} md={3}>
          <Content cmsTag="GLOBAL:To" copytext="To" />
        </Col>
        <Col tag="dd" sm={6} md={9}>
          {account.AccountName}{' '}
          <FormattedCurrency
            quantity={account.Balance}
            currency={account.CurrencyCode}
          />
        </Col>
        <Col tag="dt" sm={6} md={3}>
          <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
        </Col>
        <Col tag="dd" sm={6} md={9}>
          <FormattedCurrency
            quantity={form.amount.value}
            currency={account.CurrencyCode}
          />
        </Col>
        <Col tag="dt" sm={6} md={3}>
          <Content
            cmsTag="GLOBAL:Payment reference"
            copytext="Payment reference"
          />
        </Col>
        <Col tag="dd" sm={6} md={9}>
          {payment.PaymentReference}
        </Col>
      </Row>
      <div className="form-button-group">
        <Link className="btn btn-secondary" role="button" to={referrer}>
          <Content
            cmsTag="GLOBAL:Back-to-payments"
            copytext="Back to payments"
          />
        </Link>
      </div>
    </div>
  );
};

SuccessfulPayment.propTypes = {
  account: PropTypes.any,
  form: PropTypes.any,
  info: PropTypes.any,
  payment: PropTypes.any,
  referrer: PropTypes.any,
};

export default SuccessfulPayment;
