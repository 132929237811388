import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppMeta, Content, Iframe } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class Awaiting extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { saveShortApplyResponse } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Application awaiting`}
          metaDescription={`Application awaiting`}
        />
        <Content
          tag="h1"
          cmsTag="Apply-pages:Application-responses:Declined:h1"
          copytext="Thank you for your application"
        />
        <Iframe
          iframeclassname="w-100 trunarrative-iframe"
          title="Upload documents iframe. If you are having problems using this iframe, please contact Darlington Building Society."
          url={saveShortApplyResponse.UploadDocLinkUrl}
        />
        <div className="form-button-group">
          <Link
            id="back-to-accounts"
            className="btn btn-secondary"
            role="button"
            to={`/accounts/list`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-accounts"
              copytext="Back to accounts"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

Awaiting.propTypes = {
  match: PropTypes.any,
  account: PropTypes.any,
  savedData: PropTypes.any,
  updateUrl: PropTypes.func,
  saveShortApplyResponse: PropTypes.object,
  formData: PropTypes.object,
  form: PropTypes.any,
};

const mapStateToProps = state => {
  const { longApplyDefinition } = state;
  return {
    ...longApplyDefinition,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Awaiting);
