import SummaryMenuItems from './SummaryMenuItems';

const MoveMoneyMenuItems = [
  {
    url: '/move-money',
    menuItem: SummaryMenuItems,
  },
];

export default MoveMoneyMenuItems;
