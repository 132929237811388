import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Content, Icon } from '@myie/interact-dom';

class ExportAccountCSV extends React.Component {
  render() {
    const {
      account,
      reverseTransactionSort,
      forwardTransactionSort,
      reverse,
      transactionsList,
      range,
    } = this.props;

    const csvTransactions = [];
    let csvTransactionsFilename = '';
    const transactions = transactionsList;

    if (transactions && transactions.length) {
      csvTransactions.push([
        account.AccountName +
          ' Transactions exported on ' +
          moment(new Date()).format('YYYY-MM-DD h:mm'),
      ]);
      if (range) {
        const fromDate = range.From
          ? moment(range.From).format('YYYY-MM-DD')
          : '';
        const toDate = range.To ? moment(range.To).format('YYYY-MM-DD') : '';
        csvTransactions.push(['Start', fromDate, 'End', toDate]);
        csvTransactionsFilename =
          account.AccountName + '_' + fromDate + '_' + toDate + '.csv';
      } else {
        csvTransactions.push(['Latest transactions']);
        csvTransactionsFilename =
          account.AccountName + '_Latest transactions.csv';
      }
      csvTransactions.push(['Date', 'Description', 'Amount', 'Balance']);

      if (transactions) {
        transactions.sort(
          reverse ? reverseTransactionSort : forwardTransactionSort,
        );
      }

      transactions.forEach(transaction => {
        let {
          ExtendedProperties: { Balance, Pending, Amount, TransactionDate },
          Description,
        } = transaction;

        let balance = Balance;

        csvTransactions.push([
          TransactionDate,
          Description + (Pending ? '(Pending)' : ''),
          Amount,
          balance ? balance : 0,
        ]);
      });

      return (
        <CSVLink
          filename={csvTransactionsFilename}
          data={csvTransactions}
          id="download-statement"
          className="btn btn-light mr-auto"
        >
          <Icon name="Export" />
          <Content
            tagClassName="sr-only"
            tag="span"
            cmsTag="GLOBAL:Export-transactions"
            copytext="Export transactions"
          />
        </CSVLink>
      );
    } else {
      return '';
    }
  }
}

ExportAccountCSV.propTypes = {
  account: PropTypes.any,
  reverseTransactionSort: PropTypes.func,
  forwardTransactionSort: PropTypes.func,
  reverse: PropTypes.any,
  range: PropTypes.any,
  transactionsList: PropTypes.any,
};

export default ExportAccountCSV;
