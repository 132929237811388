/* eslint-disable */
export const BUDGET_PLANNER = {
    "ExtendedProperties": [],
    "income_and_expenditure": {
      "currency_code": "GBP",
      "description": "",
      "categories": [
        {
          "allow_add": false,
          "items": [
            {
              "name": "Your wage",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Your partner's wage",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Part-time wages",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Child benefit",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Rent or board received",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "State pension",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Private pensions",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Pension credit",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Universal credit",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Employment & Support Allowance (ESA)",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Jobseeker's Allowance (JSA)",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Child Support or CSA",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Disability Living Allowance (DLA)",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Income Support",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Working Tax Credit",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Child Tax Credit",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Housing Benefit",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "income",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            }
          ],
          "name": "Income",
          "type": "income",
          "description": "If your income changes, enter the average you earn."
        },
        {
          "allow_add": false,
          "items": [
            {
              "name": "Rent",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "rent",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Mortgage",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "mortgage",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Secured loan (other than your mortgage)",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "security-loan",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Mortgage endowment premium",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "mortgage-endowment-premium",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Service charge or ground rent",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "Service-charge-or-ground-rent",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Water",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "water",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Council tax",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "council-tax",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Gas",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "gas",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Electricity",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "electricity",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Other household fuels (for example: oil or coal)",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "other-household-fuels",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            }
          ],
          "name": "Housing & utility bills",
          "type": "housing-utility-bills",
          "description": ""
        },
        {
          "allow_add": false,
          "items": [
            {
              "name": "Buildings & contents insurance",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "buildings-contents-insurance",
              "allow_delete": false,
              "allow_add": true,
              "items": null
            },
            {
              "name": "Telephone and internet",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "telephone-and-internet",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "TV licence",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "tv-licence",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Satellite or cable TV",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "Satellite-or-cable-tv",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Repairs, service contracts",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "repairs-service-contracts",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Appliance rental",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "appliance-rental",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Child support paid by you",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "child-support",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Life insurance & private pension",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "life-insurance",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Medical or accident insurance",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "medical-accident-insurance",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            }
          ],
          "name": "Household services",
          "type": "household-services",
          "description": ""
        },
        {
          "allow_add": false,
          "items": [
            {
              "name": "Spares & servicing",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "spares-and-servicing",
              "allow_delete": false,
              "allow_add": true,
              "items": null
            },
            {
              "name": "Road tax",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "road-tax",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Car insurance",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "car-insurance",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Breakdown cover",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "breakdown-cover",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Fuel & parking",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "fuel-and-parking",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Public transport",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "public-transport",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            }
          ],
          "name": "Travel",
          "type": "travel",
          "description": ""
        },
        {
          "allow_add": false,
          "items": [
            {
              "name": "Food, toiletries & cleaning products",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "food-toiletries-and-cleaning-products",
              "allow_delete": false,
              "allow_add": true,
              "items": null
            },
            {
              "name": "School meals",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "school-meals",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Meals at work ",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "meals-at-work",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Pets (food & insurance)",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "pets-food-insurance",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Tobacco",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "Tobacco",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Clothing & footwear",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "clothing-and-footwear",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            }
          ],
          "name": "Food & housekeeping",
          "type": "food-and-housekeeping",
          "description": ""
        },
        {
          "allow_add": false,
          "items": [
            {
              "name": "School trips & activities",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "school-trips",
              "allow_delete": false,
              "allow_add": true,
              "items": null
            },
            {
              "name": "Medicines & prescriptions",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "medicines-and-prescriptions",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Dentist & opticians",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "dentist-and-opticians",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Hairdressing",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "hairdressing",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Professional or union fees",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "professional-or-union-fees",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Education fees",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "education-fees",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Laundry or dry cleaning",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "laundry-or-dry-cleaning",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            }
          ],
          "name": "Other services",
          "type": "other-services",
          "description": ""
        },
        {
          "allow_add": false,
          "items": [
            {
              "name": "Newspapers & magazines",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "newspapers-and-magazines",
              "allow_delete": false,
              "allow_add": true,
              "items": null
            },
            {
              "name": "Sports, hobbies & entertainment",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "Sports-hobbies",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Children's pocket money",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "Childrens-pocket-money",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Church or charity donations",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "Church-charity-donations",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            },
            {
              "name": "Sundries & emergencies",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "sundries-and-emergencies",
              "allow_delete": false,
              "allow_add": false,
              "items": null
            }
          ],
          "name": "Personal & leisure",
          "type": "personal-and-leisure",
          "description": ""
        },
        {
          "allow_add": true,
          "items": [
            {
              "name": "Gym membership",
              "frequency": "",
              "amount": null,
              "description": "",
              "note": "",
              "type": "debt",
              "allow_delete": true,
              "allow_add": true,
              "items": null
            }
          ],
          "name": "Other costs",
          "type": "other-costs",
          "description": ""
        }
      ],
      "debts": {
        "allow_add": true,
        "items": []
      },
      "court_payments": {
        "allow_add": true,
        "items": []
      },
      "assets": {
        "allow_add": true,
        "items": [
          {
            "type": "asset",
            "amount": null,
            "note": "",
            "allow_delete": true
          }
        ]
      },
      "last_updated": "2022-07-18T13:57:57.021405776Z",
      "submitted": false,
      "note": ""
    }
}
