import React from 'react';
import { Markdown, Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import NominatedAccount from '../shared/NominatedAccount';
import WithdrawalWithClose from '../shared/WithdrawalWithClose';
import WithdrawalAccount from '../shared/WithdrawalAccount';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';

class MakePayment extends React.Component {
  constructor(props) {
    super(props);
    const { account = {} } = props;
    this.state = {
      account: account,
    };
  }

  submitForm = form => {
    const { submitForm } = this.props;
    submitForm('ReviewPayment', form);
  };

  render() {
    const { account } = this.state;
    const {
      accounts = {},
      onChangeAccount,
      onCancelled,
      actionDate,
    } = this.props;
    if (!account) {
      return null;
    }
    return (
      <React.Fragment>
        <Markdown
          cmsTag="Savings-servicing:Make-withdrawal:Make-payment:p1"
          markdown={`\nPlease confirm the following details and add the amount to transfer money. The payment will be made immediately.`}
        />
        <RedirectByAccountDropdown
          accounts={accounts}
          extendedId="make-payment"
          id="make-payment"
          baseUrl="/make-withdrawal/"
          filterAccounts={['EASY_ACCESS']}
          defaultSelect={`${account.AccountKey.Key}`}
          onChangeAccount={onChangeAccount}
          filter="withdrawal"
        />

        <h2 className="mt-5 float-left clear-both w-100">
          <Content
            cmsTag="Savings-servicing:Make-withdrawal:Make-payment:h2-1"
            copytext="Account details"
          />
        </h2>
        <WithdrawalAccount account={account} withdrawDate={actionDate} />

        <h2 className="mt-5 float-left clear-both w-100">
          <Content
            cmsTag="Savings-servicing:Make-withdrawal:Make-payment:h2-2"
            copytext="Your nominated account"
          />
        </h2>
        <NominatedAccount account={account} />
        <WithdrawalWithClose
          account={account}
          onCancelled={onCancelled}
          onSubmit={this.submitForm}
        />
      </React.Fragment>
    );
  }
}

MakePayment.propTypes = {
  withdrawalAccount: PropTypes.object,
  history: PropTypes.object,
  resetMakeWithdrawal: PropTypes.func,
  accounts: PropTypes.object,
  fetchAccountsIfNeeded: PropTypes.func,
  account: PropTypes.object,
  makeaWithdraw: PropTypes.func,
  onChangeAccount: PropTypes.func,
  match: PropTypes.any,
  actionDate: PropTypes.any,
  submitForm: PropTypes.func,
  onCancelled: PropTypes.func,
};

export default MakePayment;
