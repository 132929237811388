import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps as brandAuthenticationDispatch } from '@myie/interact-brand-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { CONTACTS } from '@myie/interact-local-dom';
import { Content, AppMeta } from '@myie/interact-dom';
import { Redirect } from 'react-router-dom';

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  startRedirection = () => {
    this.setState({
      ...this.state,
      redirect: true,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/sign-in/step-1" />;
    }
    return (
      <div id="registration-success">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Registration success"
          metaDescription="Registration success"
        />
        <Content
          tag="h1"
          tagID="registration-success-title"
          cmsTag="Authentication:Registration:Success:h1"
          copytext="Thank you for completing our registration process"
        />
        <Content
          tag="p"
          cmsTag="Authentication:Registration:Success:p1"
          copytext={`Clicking on "Sign in" will start an authentication process to make sure we are protecting your information.`}
        />
        <Content
          tag="p"
          cmsTag="Authentication:Registration:Success:p2"
          copytext="We will send an authentication code to your mobile which you will be asked to enter on the website. Please keep the device handy."
        />
        <div className="form-button-group">
          <Button
            id="registration-success-home-button"
            color="primary"
            onClick={this.startRedirection}
          >
            <Content cmsTag="GLOBAL:Sign-in" copytext="Sign in" />
          </Button>
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  userLogout: PropTypes.any,
  credentialsSetup: PropTypes.any,
  setupcredentialsActivate: PropTypes.any,
  credentialsActivate: PropTypes.any,
  urlParams: PropTypes.any,
  stateData: PropTypes.any,
  setupCredentials: PropTypes.any,
};

const mapStateToProps = state => {
  const {
    authenticationDefinition,
    custombrandAuthenticationDefinition,
  } = state;
  return {
    ...authenticationDefinition,
    ...custombrandAuthenticationDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...brandAuthenticationDispatch,
    ...mapDispatchToProps,
  },
)(Success);
