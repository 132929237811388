import { CONSTANTS, RULES } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import moment from 'moment';

const getWithdrawalInfo = transactions => {
  const startOfYear = moment()
    .startOf('year')
    .toISOString();
  const now = moment()
    .startOf('minute')
    .toISOString();
  let withDrawalsOfYear = 0;
  transactions.forEach(trans => {
    const {
      ExtendedProperties: { GlobalTransactionType, TransactionDate },
    } = trans;
    if (GlobalTransactionType === CONSTANTS.TRANSACTION_TYPE_WITHDRAW) {
      if (moment(TransactionDate).isBetween(startOfYear, now)) {
        withDrawalsOfYear++;
      }
    }
  });
  return withDrawalsOfYear;
};

const isWithdrawalsPermitted = (account, Max_Withdrawals_Per_Year) => {
  const withdrawalsForYear = getWithdrawalInfo(
    account.transactions.Transactions,
  );
  let withdrawalsPermitted = true;
  if (Max_Withdrawals_Per_Year === CONSTANTS.TRANSACTION_MAX_WITHDRAWAL_NONE) {
    withdrawalsPermitted = false;
  }
  if (
    Max_Withdrawals_Per_Year !==
      CONSTANTS.TRANSACTION_MAX_WITHDRAWAL_UNLIMITED &&
    Max_Withdrawals_Per_Year &&
    Max_Withdrawals_Per_Year <= withdrawalsForYear
  ) {
    withdrawalsPermitted = false;
  }
  return withdrawalsPermitted;
};

const getDepositInfo = transactions => {
  const startOfYear = moment()
    .startOf('year')
    .toISOString();
  const startOfMonth = moment()
    .startOf('month')
    .toISOString();
  const now = moment()
    .startOf('minute')
    .toISOString();
  let depositsOfMonth = 0,
    depositsOfYear = 0;
  transactions.forEach(trans => {
    const {
      ExtendedProperties: { GlobalTransactionType, TransactionDate },
    } = trans;
    if (GlobalTransactionType === CONSTANTS.TRANSACTION_TYPE_DEPOSIT) {
      if (moment(TransactionDate).isBetween(startOfYear, now)) {
        depositsOfYear += trans.ExtendedProperties.Amount;
      }
      if (moment(TransactionDate).isBetween(startOfMonth, now)) {
        depositsOfMonth += trans.ExtendedProperties.Amount;
      }
    }
  });
  return {
    depositsOfMonth,
    depositsOfYear,
  };
};

export const ruleSet = (account, allProducts, data, toAccount) => {
  if (!account) {
    return {};
  }

  // ToAccount Rules
  let productForToAccount,
    toAccountRules = {};
  if (toAccount) {
    const {
      ExtendedProperties: {
        TotalAmountAvailableToDeposit,
        AvailableBalance,
        PendingBalance,
      },
    } = toAccount;
    productForToAccount = allProducts.find(
      product =>
        product.Product_Code === toAccount.ExtendedProperties.ProductCode,
    );
    const { depositsOfMonth, depositsOfYear } = getDepositInfo(
      toAccount.transactions.Transactions,
    );
    const {
      Maximum_monthly_deposit,
      Maximum_yearly_deposit,
      Maximum_Operating_Balance,
    } = productForToAccount;

    const balance = AvailableBalance + PendingBalance;

    toAccountRules = {
      isMonthlyAllowanceExceeded: {
        message: `Please check and re-enter the amount as the maximum monthly allowance for this account is £${Maximum_monthly_deposit}.${
          depositsOfMonth
            ? ` You have already used £${depositsOfMonth} of this allowance.`
            : ''
        }`,
        comparison: () => {
          return [Maximum_monthly_deposit, depositsOfMonth];
        },
      },
      isYearlyAllowanceExceeded: {
        message: `Please check and re-enter the amount as the maximum yearly allowance for this account is  £${Maximum_yearly_deposit}.${
          depositsOfYear
            ? ` You have already used £${depositsOfYear} of this allowance.`
            : ''
        }`,
        comparison: () => {
          return [Maximum_yearly_deposit, depositsOfYear];
        },
      },
      totalDepositAvailableExceeded: {
        message: `Please check and re-enter the amount as the total amount available to deposit for this account is  £${TotalAmountAvailableToDeposit}.`,
        comparison: () => {
          return [TotalAmountAvailableToDeposit || 0, 0];
        },
      },
      maxOperationalBalance: {
        message: `Please check and re-enter the amount as the maximum balance required for this account is £${Maximum_Operating_Balance}.`,
        comparison: () => {
          return [Maximum_Operating_Balance, balance];
        },
      },
    };
  }

  //  FromAccount Rules
  const product = allProducts.find(
    product => product.Product_Code === account.ExtendedProperties.ProductCode,
  );
  const { Minimum_Operating_Balance } = product;
  const {
    ExtendedProperties: { AvailableBalance, PendingBalance } = {},
  } = account;
  const balance = AvailableBalance + PendingBalance;
  const amount = {
    rules: {
      title: 'Amount',
      stop: true,
      required: {
        message: 'Please enter an amount.',
      },
      format: {
        regex: RULES.ammountFormat,
        message: 'Please enter a valid amount.',
      },
      insufficientCheck: {
        message: 'You have insufficient balance in your account.',
        comparison: () => {
          return balance;
        },
      },
      compareWithAvailableBalance: {
        message: `To close your account and transfer the funds to another ${CONTACTS.companyName} account, please contact our Online Support Team on ${CONTACTS.phone}`,
        comparison: () => {
          return balance;
        },
      },
      minOperationalBalance: {
        message: `Please check and re-enter the amount as the minimum balance required for this account is £${Minimum_Operating_Balance}.`,
        comparison: () => {
          return [Minimum_Operating_Balance, balance];
        },
      },
      ...toAccountRules,
    },
    hasUpdated: Date.now(),
    value: data && data['amount'] ? data['amount'] : '',
  };

  let withdrawalRule;

  if (
    toAccount &&
    account &&
    account.AccountKey.Key === toAccount.AccountKey.Key
  ) {
    withdrawalRule = {
      isPermitted: false,
      message: `Source and destination accounts cannot be the same`,
    };
  } else {
    const withdrawalsPermitted = isWithdrawalsPermitted(
      account,
      product.Max_Withdrawals_Per_Year,
    );
    withdrawalRule = {
      isPermitted: withdrawalsPermitted,
      message: `You have exceeded maximum withdrawals for this year for this account`,
    };
  }
  return { amount, withdrawalRule };
};
