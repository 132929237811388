import {
  CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE,
  CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST,
  CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS,
  RESET_CANCEL_NOTICE_TO_WITHDRAWAL,
  SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS,
  RESET_CREATE_NOTICE_TO_WITHDRAWAL,
  SET_MAKE_A_WITHDRAW_PARAM,
  MAKE_A_WITHDRAW_SUCCESS,
  MAKE_A_WITHDRAW_FALIURE,
  MAKE_A_WITHDRAW_REQUEST,
  RESET_MAKE_A_WITHDRAW,
  BANK_HOLIDAYS,
  NOTICE_TO_WITHDRAWAL_LIST_SUCCESS,
  NOTICE_TO_WITHDRAWAL_LIST_FAILURE,
  WITHDRAWAL_CLOSING_BAL_SUCCESS,
  WITHDRAWAL_CLOSING_BAL_FAILURE,
  GET_NOMINATED_ACCOUNT_REQUEST,
  GET_NOMINATED_ACCOUNT_SUCCESS,
  GET_NOMINATED_ACCOUNT_FAILURE,
  RESET_NOMINATED_ACCOUNT,
} from '../actions/withdrawalTypes';

const brandWithdrawalDefinition = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST:
    case MAKE_A_WITHDRAW_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case BANK_HOLIDAYS:
      return {
        ...state,
        holidays: action.holidays,
        isFetching: action.isFetching,
      };
    case CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        cancellationResponse: action.cancellationResponse,
        isFetching: action.isFetching,
      };
    case CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_CANCEL_NOTICE_TO_WITHDRAWAL:
      return {
        ...state,
        cancellationResponse: null,
        isFetching: false,
        error: null,
      };
    case SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS:
      return {
        ...state,
        createNoticeParam: action.createNoticeParam,
        error: null,
      };
    case SET_MAKE_A_WITHDRAW_PARAM:
      return {
        ...state,
        withdrawalParam: action.withdrawalParam,
        error: null,
      };
    case RESET_CREATE_NOTICE_TO_WITHDRAWAL:
      return {
        ...state,
        createNoticeParam: null,
        createResponse: null,
        error: null,
      };
    case MAKE_A_WITHDRAW_SUCCESS: {
      return {
        ...state,
        makeWithdrawResponse: action.makeWithdrawResponse,
        isFetching: false,
        error: null,
      };
    }
    case MAKE_A_WITHDRAW_FALIURE: {
      return {
        ...state,
        makeWithdrawResponse: null,
        isFetching: false,
        error: action.error,
      };
    }
    case RESET_MAKE_A_WITHDRAW:
      return {
        ...state,
        makeWithdrawResponse: null,
        isFetching: false,
        error: null,
      };
    case NOTICE_TO_WITHDRAWAL_LIST_SUCCESS: {
      return {
        ...state,
        noticesList: action.noticeListResponse.notices,
        isFetching: false,
        error: null,
      };
    }
    case NOTICE_TO_WITHDRAWAL_LIST_FAILURE:
      return {
        ...state,
        noticesList: action.noticeListResponse,
        isFetching: false,
        error: action.error,
      };
    case WITHDRAWAL_CLOSING_BAL_SUCCESS: {
      return {
        ...state,
        closingBalResponse: action.closingBalResponse,
        isFetching: false,
        error: null,
      };
    }
    case WITHDRAWAL_CLOSING_BAL_FAILURE:
      return {
        ...state,
        closingBalResponse: action.closingBalResponse,
        isFetching: false,
        error: action.error,
      };
    case GET_NOMINATED_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case GET_NOMINATED_ACCOUNT_SUCCESS:
      return {
        ...state,
        linkedNominatedAccount: action.nominatedAccountResponse,
        isFetching: action.isFetching,
      };
    case GET_NOMINATED_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case RESET_NOMINATED_ACCOUNT:
      return {
        ...state,
        linkedNominatedAccount: action.nominatedAccountResponse,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
};

export default brandWithdrawalDefinition;
