import React, { Component } from 'react';
import { BreadcrumbItem } from 'reactstrap';
import { storage } from './../storageName';
import { Redirect } from 'react-router-dom';
import { Content, AppMeta } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Utility } from '@myie/interact';
import moment from 'moment';
import { CONSTANTS, FormErrorList } from '@myie/interact-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapMoveMoneyDispatchToProps } from '@myie/interact-move-money';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Create from './Create';
import Confirmation from './Confirmation';
import CreationSuccess from './CreationSuccess';
import dateValidation from './../../utility';
import loadStateHOC from '../../../shared/stateManager/loadStateHOC';
import checkDownTime from '../../../shared/HO_components/checkDownTime';
import { CONTACTS } from '@myie/interact-local-dom';
import { Validate } from '@myie/interact';
import {
  accountFilter,
  accountsEligibleToTranfer,
} from '@myie/interact-savings-servicing-dom';

class CreateTransfer extends Component {
  constructor(props) {
    super(props);
    const { allProducts, getAllProducts } = this.props;
    this.state = this.initialState(this.state);
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  initialState = state => {
    const { accounts } = this.props;
    const { urlParams, refreshUrl, stateData } = this.props;
    const stateList = ['createTransfer', 'ReviewTransfer', 'Completed'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : 'createTransfer';
    if (!hasStage) {
      stage = 'createTransfer';
      refreshUrl();
    }
    let account = null;
    let toAccount = null;
    if (accounts) {
      if (stateData && stateData.From) {
        account = accounts.Accounts.find(function(element) {
          return Utility.hexEncode(element.AccountKey.Key) === stateData.From;
        });
      }
      if (stateData && stateData.To) {
        toAccount = accounts.Accounts.find(function(element) {
          return Utility.hexEncode(element.AccountKey.Key) === stateData.To;
        });
      }
    }
    return {
      account: account,
      toAccount: toAccount,
      stage: stage,
      combinedForm: {},
      accountForm: {
        accountFormValidation: {
          rules: {
            stop: true,
            required: {
              message: 'Please select an account to transfer from.',
            },
          },
          value: state && state.account ? state.account.AccountKey.Key : '',
        },
        toAccountFormValidation: {
          rules: {
            stop: true,
            required: {
              message: 'Please select an account to transfer to.',
            },
          },
          value: state && state.toAccount ? state.toAccount.AccountKey.Key : '',
        },
      },
      withdrawDate: moment(),
      data:
        this.props.stateData && this.props.stateData.formData
          ? this.props.stateData.formData
          : {},
      form: {},
    };
  };

  componentWillUnmount() {
    const { initializeMoveMoney } = this.props;
    initializeMoveMoney();
  }

  componentDidMount() {
    const { initializeMoveMoney, fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
    initializeMoveMoney();
  }

  componentDidUpdate(prevProps) {
    const { account, toAccount } = this.state;
    const { accounts } = this.props;
    if (accounts && prevProps.accounts !== accounts) {
      let updatedAccount, updatedToAccount;
      if (account && Object.keys(account).length) {
        updatedAccount = accounts.Accounts.find(function(element) {
          return element.AccountKey.Key === account.AccountKey.Key;
        });
      }
      if (toAccount && Object.keys(toAccount).length) {
        updatedToAccount = accounts.Accounts.find(function(element) {
          return element.AccountKey.Key === toAccount.AccountKey.Key;
        });
      }

      this.setState({
        ...this.state,
        account: updatedAccount,
        toAccount: updatedToAccount,
      });
    }
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const {
      makeWithdrawResponse = {},
      transfer,
      updateUrl,
      urlParams,
    } = nextProps;
    let { accountForm } = state;
    if (
      transfer &&
      transfer.ResponseStatus &&
      transfer.ResponseStatus === 'Completed' &&
      state.stage !== 'Completed'
    ) {
      let stateData = {
        url: {
          pageStage: 'Completed',
        },
      };
      if (urlParams.page && urlParams.page === 'account') {
        stateData.url.page = 'account';
      }
      updateUrl(stateData);
      return { stage: 'Completed' };
    }
    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Success'
    ) {
      return {
        stage: 'CompleteNotice',
      };
    }
    let newForm = accountForm;
    if (nextProps.accounts && !state.account) {
      let account = null;
      let toAccount = null;
      const { stateData } = nextProps;
      if (nextProps.accounts) {
        if (stateData && stateData.To) {
          toAccount = nextProps.accounts.Accounts.find(function(element) {
            return Utility.hexEncode(element.AccountKey.Key) === stateData.To;
          });
          newForm = Validate.input(
            'toAccountFormValidation',
            toAccount.AccountKey.Key,
            newForm,
          );
        }
        if (stateData && stateData.From) {
          account = nextProps.accounts.Accounts.find(function(element) {
            return Utility.hexEncode(element.AccountKey.Key) === stateData.From;
          });
          newForm = Validate.input(
            'accountFormValidation',
            account.AccountKey.Key,
            newForm,
          );
        }
      } else {
        account = null;
        toAccount = null;
      }
      return {
        account: account,
        toAccount: toAccount,
        accountForm: newForm,
      };
    }

    return null;
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  //update the stage in state

  callAccountValidations = () => {
    const { toAccount, accountForm, combinedForm, account } = this.state;
    window.scrollTo(0, 0);
    if (!toAccount || !account) {
      let form = Validate.form(accountForm);
      this.setState({
        ...this.state,
        accountForm: form,
        combinedForm: {
          ...combinedForm,
          ...form,
        },
      });
      return;
    }
  };
  submitForm = (stage, form) => {
    if (stage === 'createTransfer') {
      const { initializeMoveMoney } = this.props;
      initializeMoveMoney();
    }
    let stateData = {
      formData: this.state.data,
      url: {
        pageStage: stage,
      },
    };
    this.props.updateUrl(stateData);
    this.setState({ ...this.state, stage, form, message: null });
  };

  getActionDate = account => {
    const { holidays } = this.props;
    let numberOfDays = '';
    if (account.ExtendedProperties.AccountType === CONSTANTS.REGULAR_SAVINGS) {
      let segments = account.AccountName.split(/\s+/);
      numberOfDays = parseInt(segments[0]);
    }

    const NumberOfDays = numberOfDays === 0 ? 0 : numberOfDays;
    let days = [];
    if (holidays) {
      days = holidays.split(',');
    }
    let date = moment(new Date())
      .utc()
      .add(NumberOfDays, 'days')
      .format('YYYY-MM-DD');

    while (
      dateValidation.isWeekEnd(date) ||
      dateValidation.isHoliday(date, days)
    ) {
      let createdDate = moment(date).format('YYYY-MM-DD');
      date = moment(createdDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    }

    return date;
  };

  onSubmitWithdrawal = () => {
    const { account, toAccount, data } = this.state;
    const { transferMoney } = this.props;

    let formCloseAccount = false;
    let request;

    request = {
      SourceKey: account.AccountKey,
      TargetKey: toAccount.AccountKey,
      Amount: data['amount'].replace(/,/g, ''),
      ExtendedProperties: {
        ShouldCloseAccount: formCloseAccount,
        Society: account.ExtendedProperties.Society,
        FromSubAccountId: account.ExtendedProperties.SubAccountNumber,
        ToSubAccountId: toAccount.ExtendedProperties.SubAccountNumber,
      },
    };

    transferMoney(request);
  };

  // common onChangeAccount function
  onChangeFromAccount = e => {
    const { accounts, updateState } = this.props;
    const { value } = e.target;
    let { accountForm, combinedForm } = this.state;
    let newForm = accountForm;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      if (!account) {
        return;
      }
      newForm = Validate.input('accountFormValidation', value, newForm);
      let object = {};
      object.From = Utility.hexEncode(account.AccountKey.Key);
      updateState(object);
      newForm = Validate.input('toAccountFormValidation', '', newForm, true);
      this.setState({
        account,
        toAccount: null,
        accountForm: newForm,
        combinedForm: { ...combinedForm, ...newForm },
      });
    }
  };
  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };
  // common onChangeAccount function
  onChangeToAccount = e => {
    const { accounts, updateState } = this.props;
    const { value } = e.target;
    let { accountForm, combinedForm } = this.state;
    let newForm = accountForm;
    if (accounts && accounts.Accounts) {
      const toAccount = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      if (!toAccount) {
        return;
      }
      newForm = Validate.input('toAccountFormValidation', value, newForm);
      let object = {};
      object.To = Utility.hexEncode(toAccount.AccountKey.Key);
      updateState(object);
      this.setState({
        toAccount,
        accountForm: newForm,
        combinedForm: { ...combinedForm, ...newForm },
      });
    }
  };

  setStateDynamic = list => {
    this.setState({ ...this.state, ...list });
  };

  // select current stage
  currentStage = (account, fromAccounts, toAccounts) => {
    const { accounts = {}, match } = this.props;
    const { toAccount } = this.state;

    switch (this.state.stage) {
      case 'createTransfer':
        return (
          <Create
            setForm={this.setForm}
            callAccountValidations={this.callAccountValidations}
            accountForm={this.state.accountForm}
            setStateDynamic={this.setStateDynamic}
            submitForm={this.submitForm}
            onChangeFromAccount={this.onChangeFromAccount}
            onChangeToAccount={this.onChangeToAccount}
            fromMatch={match ? match.params.id : ''}
            saveData={this.saveData}
            data={this.state.data}
            accounts={accounts}
            account={account}
            toAccount={toAccount}
            fromAccounts={fromAccounts}
            toAccounts={toAccounts}
          />
        );
      case 'ReviewTransfer':
        return (
          <Confirmation
            onSubmitWithdrawal={this.onSubmitWithdrawal}
            urlParams={this.props.urlParams}
            form={this.state.form}
            updateUrl={this.props.updateUrl}
            data={this.state.data}
            setStage={this.setStage}
            account={account}
            toAccount={toAccount}
          />
        );

      default:
    }
  };

  render() {
    const { stage, account, toAccount, combinedForm } = this.state;
    const {
      accounts,
      urlParams,
      systemDownStatus, //From checkDownTime HOC
      allProducts,
    } = this.props;
    if (!accounts || !allProducts) {
      return null;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    const fromAccounts = accountFilter(accounts.Accounts, allProducts, true);
    const toAccounts = accountFilter(
      accounts.Accounts,
      allProducts,
      false,
      true,
    );
    if (accountsEligibleToTranfer(fromAccounts, toAccounts)) {
      return <Redirect to="/internal-transfer/max-accounts-limit" />;
    }

    let condition =
      this.state.stage === 'ReviewTransfer' ||
      this.state.stage === 'CompleteNotice';
    if (stage === 'Completed') {
      return (
        <CreationSuccess
          account={account}
          urlParams={urlParams.page}
          setStage={this.setStage}
          toAccount={toAccount}
          data={this.state.data}
          activate={this.activate}
        />
      );
    }
    return (
      <div id="internal-transfer">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Transfer money between your ${CONTACTS.companyNameShorthand} savings accounts step 1`}
          metaDescription={`Transfer money between your ${CONTACTS.companyNameShorthand} savings accounts step 1`}
        />
        {systemDownStatus.msg}
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Transfer:Create-transfer:Create-transfer:h1"
          template={{
            copytext: {
              companyNameShorthand: CONTACTS.companyNameShorthand,
            },
          }}
          copytext="Transfer money between your $[companyNameShorthand] savings accounts"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={stage === 'createTransfer'}
              className={condition ? 'completed' : ''}
            >
              {stage === 'createTransfer' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {condition ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Transfer-details"
                copytext="Transfer details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'ReviewTransfer'}>
              {stage === 'ReviewTransfer' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        {this.state.stage === 'createTransfer' ? (
          <FormErrorList
            validations={combinedForm}
            disabled={false}
            required={true}
            groupClassName=""
            title="h2"
            showErrors={true}
          />
        ) : (
          ''
        )}
        {this.currentStage(account, fromAccounts, toAccounts)}
      </div>
    );
  }
}

CreateTransfer.propTypes = {
  accounts: PropTypes.any,
  updateState: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  initializeMoveMoney: PropTypes.func,
  transferMoney: PropTypes.func,
  holidays: PropTypes.any,
  transfer: PropTypes.any,
  account: PropTypes.any,
  urlParams: PropTypes.any,
  stateData: PropTypes.any,
  refreshUrl: PropTypes.any,
  updateUrl: PropTypes.any,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    accountsDefinition,
    custombrandSavingsServicingDefinition,
    moveMoneyDefinition,
  } = state;
  return {
    ...moveMoneyDefinition,
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      {
        ...mapDispatchToProps,
        ...mapMoveMoneyDispatchToProps,
        ...mapSavingsServicingDispatchToProps,
      },
    )(CreateTransfer),
  ),
  storage.name,
);
