import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppMeta, Content, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';

class DecisionSuccessNonISA extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { resetSaveShortApply } = this.props;
    resetSaveShortApply();
  }

  render() {
    const { saveShortApplyResponse, selectedProduct } = this.props;
    const {
      ExtendedProperties: [
        { Value: bankAccountValue },
        { Value: sortCodeValue },
      ] = {},
    } = saveShortApplyResponse;
    const { Account_Mode, Deposits_Allowed } = selectedProduct;
    let showAdditionalLinks =
      Account_Mode === 'transact'
        ? Deposits_Allowed === 'Yes'
          ? true
          : false
        : false;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Application Successful`}
          metaDescription={`Application Successful`}
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:h1"
          copytext="Your application was successful"
        />
        <Markdown
          cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:p1"
          template={{
            markdown: {
              companyName: CONTACTS.companyName,
            },
          }}
          markdown={`\nThank you for opening a new Savings account with $[companyName]. Please take note of your account number. Your welcome pack will be sent by your preferred contact method within five working days. `}
        />
        <div className="referral-number-container">
          <Row
            tag="dl"
            title="Your new account details"
            className="account-details-dl mb-0"
          >
            <Col tag="dt" sm="12" md="6">
              <Content
                cmsTag="GLOBAL:Your-account-number"
                copytext="Your account number"
              />
            </Col>
            <Col tag="dd" sm="12" md="6">
              {saveShortApplyResponse
                ? saveShortApplyResponse.ReferenceNumber
                : ''}
            </Col>
          </Row>
        </div>
        <section>
          <Content
            tag="h2"
            cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:h2-1"
            copytext="Transferring funds"
          />
          <Content
            tag="p"
            cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:p2"
            copytext="Please check the terms and conditions of the account before making your deposit."
          />
          <Content
            tag="h3"
            cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:h3-1"
            copytext="Funding your account by debit card"
          />
          <Content
            tag="p"
            cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:p3"
            template={{
              copytext: {
                companyName: CONTACTS.companySiteName,
              },
            }}
            copytext='To fund your account by debit card, please click "Make a Deposit" by visiting the "Move Money" section of $[companyName]. You will be able to make a deposit by debit card using this service. '
          />
          <Content
            tag="h3"
            cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:h3-2"
            copytext="Funding your account by bank transfer"
          />
          <Content
            tag="p"
            cmsTag="Savings-servicing:Short-apply:Application-responses:Decision-success-non-ISA:p4"
            template={{
              copytext: {
                companyName: CONTACTS.companyName,
              },
            }}
            copytext="To transfer money from your bank to your $[companyName] savings account you need to use the following bank details:"
          />
          <Row tag="dl" title="Nominated account details">
            {sortCodeValue && (
              <>
                <Col tag="dt" xs={12} md={4} lg={2}>
                  <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
                </Col>
                <Col tag="dd" xs={6} md={8} lg={9}>
                  {sortCodeValue}
                </Col>
              </>
            )}
            {bankAccountValue && (
              <>
                <Col tag="dt" xs={12} md={4} lg={2}>
                  <Content
                    cmsTag="GLOBAL:Account-number"
                    copytext="Account number"
                  />
                </Col>
                <Col tag="dd" xs={6} md={8} lg={9}>
                  {bankAccountValue}
                </Col>
              </>
            )}
            <Col tag="dt" xs={12} md={4} lg={2}>
              <Content
                cmsTag="GLOBAL:Reference-number"
                copytext="Reference number"
              />
            </Col>
            <Col tag="dd" xs={6} md={8} lg={9}>
              <Markdown
                template={{
                  markdown: {
                    companyName: CONTACTS.companyName,
                  },
                }}
                cmsTag="GLOBAL:Reference-number-description"
                markdown={`Your 8-digit $[companyName] account number`}
              />
            </Col>
          </Row>
        </section>
        <div className="form-button-group">
          {showAdditionalLinks && (
            <>
              <Link
                id="make-a-deposit-link"
                className="btn btn-primary"
                role="button"
                to={'/make-a-deposit/moveMoney/'}
              >
                <Content
                  cmsTag="GLOBAL:Make-a-deposit"
                  copytext="Make a deposit"
                />
              </Link>
              <Link
                id="make-a-transfer-link"
                className="btn btn-secondary"
                role="button"
                to={`/internal-transfer/create/moveMoney`}
              >
                <Content
                  cmsTag="GLOBAL:Make-a-transfer"
                  copytext="Make a transfer"
                />
              </Link>
            </>
          )}
          <Link
            id="back-to-accounts-link"
            className="btn btn-light"
            role="button"
            to={`/accounts/list`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-accounts"
              copytext="Back to accounts"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

DecisionSuccessNonISA.propTypes = {
  history: PropTypes.any,
  account: PropTypes.any,
  resetSaveShortApply: PropTypes.func,
  savedData: PropTypes.any,
  saveShortApplyResponse: PropTypes.object,
  updateUrl: PropTypes.func,
  formData: PropTypes.object,
  form: PropTypes.any,
  selectedProduct: PropTypes.object,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DecisionSuccessNonISA),
);
