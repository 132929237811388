import React from 'react';
import CreateTransfer from './CreateTranfer/CreateTransfer';

class TransferWidgetTest extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>Test Widget:</h1>
        <CreateTransfer />
      </React.Fragment>
    );
  }
}

export default TransferWidgetTest;
