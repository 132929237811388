import React from 'react';
import PropTypes from 'prop-types';

import {
  Content,
  FormattedCurrency,
  CONSTANTS,
  FormattedDate,
} from '@myie/interact-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapStateToProps } from '@myie/interact-accounts';

class SubAccountSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { account } = this.props;
    const interestRate = account.InterestRate;
    const {
      ExtendedProperties: {
        CurrentBalance,
        LoanAmount,
        RemainingTerm,
        OriginalTerm,
      } = {},
    } = account;
    const convertedValue =
      interestRate && Number(interestRate) ? Number(interestRate) : Number(0);

    return (
      <React.Fragment>
        <Row tag="dl" title="Account summary details">
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Current-balance"
              copytext="Current balance"
            />
          </Col>
          <Col
            id={'sub-current-balance-amount-' + account.AccountNumber}
            tag="dd"
            xs={12}
            md={7}
            lg={8}
          >
            <FormattedCurrency
              quantity={CurrentBalance}
              currency={CONSTANTS.DEFAULT_CURRENCY}
            />
          </Col>
          <Col
            id={'sub-remaining-terms-' + account.AccountNumber}
            tag="dt"
            xs={12}
            md={5}
            lg={4}
          >
            <Content
              cmsTag="GLOBAL:Remaining-terms"
              copytext="Remaining terms"
            />
            <Content
              tagClassName="d-block"
              tag="small"
              cmsTag="GLOBAL:Remaining-terms-description"
              copytext="(This is not a redemption figure)"
            />
          </Col>
          {RemainingTerm && (
            <Col
              id={'sub-remaining-terms-value-' + account.AccountNumber}
              tag="dd"
              xs={12}
              md={7}
              lg={8}
            >
              <Content
                cmsTag="Account-type-mortgage:Sub-account-summary:Remaining-terms-month"
                copytext={`${RemainingTerm}`}
              />
            </Col>
          )}
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Loan-amount" copytext="Loan amount" />
          </Col>
          <Col
            id={'sub-loan-amount-' + account.AccountNumber}
            tag="dd"
            xs={12}
            md={7}
            lg={8}
          >
            <FormattedCurrency
              quantity={LoanAmount}
              currency={CONSTANTS.DEFAULT_CURRENCY}
            />
          </Col>

          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Original-term" copytext="Original term" />
          </Col>
          {OriginalTerm && (
            <Col
              id={'sub-orginal-term-' + account.AccountNumber}
              tag="dd"
              xs={12}
              md={7}
              lg={8}
            >
              <Content
                cmsTag="Account-type-mortgage:Sub-account-summary:Original-terms-month"
                copytext={`${OriginalTerm}`}
              />
            </Col>
          )}
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Term-start-date"
              copytext="Term start date"
            />
          </Col>
          <Col
            id={'sub-term-start-date-' + account.AccountNumber}
            tag="dd"
            xs={12}
            md={7}
            lg={8}
          >
            <FormattedDate
              date={account.ExtendedProperties.TermStartDate}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Term-end-date" copytext="Term end date" />
          </Col>
          <Col
            id={'sub-term-end-date-' + account.AccountNumber}
            tag="dd"
            xs={12}
            md={7}
            lg={8}
          >
            <FormattedDate
              date={account.ExtendedProperties.TermEndDate}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content
              cmsTag="GLOBAL:Mortgage-description"
              copytext="Mortgage description"
            />
          </Col>
          <Col
            id={'sub-product-description-' + account.AccountNumber}
            tag="dd"
            xs={12}
            md={7}
            lg={8}
          >
            {account.ExtendedProperties.ProductDescription}
          </Col>
          <Col tag="dt" xs={12} md={5} lg={4}>
            <Content cmsTag="GLOBAL:Interest-rate" copytext="Interest rate" />
          </Col>
          <Col
            id={'sub-interest-rate-value-' + account.AccountNumber}
            tag="dd"
            xs={12}
            md={7}
            lg={8}
          >
            {convertedValue}%
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

SubAccountSummary.propTypes = {
  account: PropTypes.any,
  tabAccounts: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubAccountSummary);
