import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BreadcrumbItem, Alert } from 'reactstrap';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { storage } from './storageName';
import { Redirect } from 'react-router-dom';
import PersonalDetails from '../shared/PersonalDetails';
import UserNameReminder from './UserNameReminder';
import AccountDetails from '../shared/AccountDetails';
import { Switch, Content, AppMeta, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Session } from '@myie/interact';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';

const META_TITLE = {
  PersonalDetails: 'Forgotten username Step 1',
  accountDetails: 'Forgotten username Step 2',
};
class Retrieve extends React.Component {
  constructor(props) {
    super(props);
    const { urlParams, refreshUrl } = props;
    const stateList = ['PersonalDetails', 'accountDetails', 'UsernameSuccess'];
    const hasStage = stateList.find(element => element === urlParams.pageStage);
    let stage =
      props.stateData &&
      props.stateData.pageStage &&
      props.urlParams &&
      props.urlParams.pageStage
        ? props.urlParams.pageStage
        : 'PersonalDetails';
    if (!hasStage) {
      stage = 'PersonalDetails';
      refreshUrl();
    }
    this.state = {
      stateList: stateList,
      stage: stage,
      credentialErrors: [],
      requestFailedCount: 0,
      message: null,
      data:
        props.stateData && props.stateData.formData
          ? props.stateData.formData
          : {},
    };
  }

  componentWillUnmount() {
    const { resetComponentState } = this.props;
    resetComponentState();
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (prevProps, prevState) => {
    const { retrievedUserName = {}, resetForgottenUN, updateUrl } = prevProps;
    const credentialErrors =
      retrievedUserName.ExtendedProperties &&
      retrievedUserName.ExtendedProperties.IdentityResponse &&
      retrievedUserName.ExtendedProperties.IdentityResponse.CredentialErrors
        ? retrievedUserName.ExtendedProperties.IdentityResponse.CredentialErrors
        : null;
    let failedCount = prevState.requestFailedCount;
    if (credentialErrors) {
      failedCount = failedCount + 1;
    }
    if (retrievedUserName.Status === 'IdentifyAndVerifyFailed') {
      resetForgottenUN();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        credentialErrors: credentialErrors,
        requestFailedCount: failedCount,
        message: 'IdentifyAndVerifyFailed',
      };
    }
    if (retrievedUserName.Status === 'NotRegistered') {
      resetForgottenUN();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        requestFailedCount: failedCount,
        credentialErrors: credentialErrors,
        message: 'NotRegistered',
      };
    }

    //if account restricted or sopra invalid status
    if (
      retrievedUserName.Status === 'AccountRestricted' ||
      retrievedUserName.Status === 'InvalidStatus'
    ) {
      failedCount = failedCount + 1;
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      resetForgottenUN();
      return {
        data: {},
        stage: 'PersonalDetails',
        requestFailedCount: failedCount,
        credentialErrors: credentialErrors,
        message: retrievedUserName.Status,
      };
    }
    if (
      retrievedUserName &&
      retrievedUserName.ExtendedProperties &&
      retrievedUserName.ExtendedProperties.IdentityResponse &&
      retrievedUserName.ExtendedProperties.IdentityResponse.CredentialErrors
    ) {
      resetForgottenUN();
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      return {
        stage: 'PersonalDetails',
        credentialErrors:
          retrievedUserName.ExtendedProperties.IdentityResponse
            .CredentialErrors,
        fetching: false,
        requestFailedCount: failedCount,
      };
    }
    return null;
  };

  componentDidUpdate(prevProps) {
    const { stage, stateList } = this.state;
    let hasStage = false;
    if (prevProps.urlParams && prevProps.urlParams.pageStage) {
      hasStage = stateList.find(
        element => element === prevProps.urlParams.pageStage,
      );
    }
    if (
      prevProps.stateData &&
      prevProps.stateData.url &&
      prevProps.stateData.url.pageStage &&
      prevProps.urlParams &&
      prevProps.urlParams.pageStage &&
      hasStage &&
      stage !== prevProps.stateData.url.pageStage
    ) {
      this.setState({
        ...this.state,
        stage: prevProps.stateData.url.pageStage,
      });
    }
  }

  getCredentialErrors = credentialErrors => {
    if (credentialErrors) {
      return credentialErrors.map(item => {
        return (
          <Alert key={item} color="danger">
            {item === 'InvalidClientStatus' ? (
              <Markdown
                cmsTag="Authentication:Forgotten-username:Retrieve:alert-1"
                template={{
                  markdown: {
                    phone: CONTACTS.phone,
                    phoneLink: CONTACTS.phoneLink,
                    companyName: CONTACTS.companyName,
                  },
                }}
                markdown={`There has been an issue verifying your identity. Please contact $[companyName] on <a href="tel:$[phoneLink]">$[phone]</a>, quoting status code 101.`}
              />
            ) : (
              ''
            )}
            {item === 'InvalidAccountStatus' ? (
              <Markdown
                cmsTag="Authentication:Forgotten-username:Retrieve:alert-2"
                template={{
                  markdown: {
                    phone: CONTACTS.phone,
                    phoneLink: CONTACTS.phoneLink,
                    companyName: CONTACTS.companyName,
                  },
                }}
                markdown={`There has been an issue accessing your account. Please contact $[companyName] on <a href="tel:$[phoneLink]">$[phone]</a>, quoting status code 102.`}
              />
            ) : (
              ''
            )}
          </Alert>
        );
      });
    }
  };
  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // change the stage of the form
  setStage = stage => {
    if (stage !== 'PersonalDetails') {
      this.setState({
        ...this.state,
        stage,
        credentialErrors: [],
        message: null,
      });
    } else {
      this.setState({ ...this.state, stage, message: null });
    }
  };

  // verify the identification and verfication entries
  verify = () => {
    const { data } = this.state;
    const { retrieveUserName } = this.props;
    let accountNumber = '';
    switch (data.type_Of_account) {
      case 'SavingsAccount':
        accountNumber = data.savings_account_number;
        break;
      case 'MortgageAccount':
        accountNumber = data.mortgage_account_number;
        break;
      default:
    }

    // personal data
    const request = {
      Entries: [
        {
          Type: 'firstName',
          Value: data.firstname,
        },
        {
          Type: 'lastName',
          Value: data.lastname,
        },
        {
          Type: 'dateOfBirth',
          Value: data.b_day,
        },
        {
          Type: 'postCode',
          Value: data.postcode,
        },
        {
          Type: 'accountNumber',
          Value: accountNumber,
        },
      ],
      ExtendedProperties: {},
    };
    retrieveUserName(request);
  };

  // select current stage
  currentStage = () => {
    const { message } = this.state;
    const { updateUrl, match } = this.props;
    switch (this.state.stage) {
      case 'accountDetails':
        return (
          <AccountDetails
            match={match}
            saveData={this.saveData}
            setStage={this.setStage}
            verify={this.verify}
            updateUrl={updateUrl}
            message={message}
            parentPage="forgotten-username"
            privacyPolicy={false}
          />
        );

      case 'PersonalDetails':
      default:
        return (
          <PersonalDetails
            verify={this.verify}
            data={this.state.data}
            updateUrl={updateUrl}
            parentPage="forgotten-username"
            saveData={this.saveData}
            setStage={this.setStage}
          />
        );
    }
  };

  render() {
    const {
      retrievedUserName = {},
      updateUrl,
      urlParams,
      resetForgottenUN,
    } = this.props;
    let { requestFailedCount, stage } = this.state;
    let userNameStatus = retrievedUserName.Status;

    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }

    if (requestFailedCount === 3) {
      return <Redirect to="/registration/failed" />;
    }

    if (stage === 'UsernameSuccess' || retrievedUserName.Status === 'Success') {
      userNameStatus = 'Success';
    }
    /* To be replaced by HOC */
    switch (userNameStatus) {
      case 'Blocked':
        return <Redirect to="/forgotten-username/access-denied" />;
      case 'Success':
        return (
          <UserNameReminder
            stateList={this.state.stateList}
            resetForgottenUN={resetForgottenUN}
            setStage={this.setStage}
            data={this.state.data}
            urlParams={urlParams}
            updateUrl={updateUrl}
            username={retrievedUserName.Username}
          />
        );
      default:
    }
    const { message } = this.state;

    return (
      <div id="forgotten-username-retrieve">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={META_TITLE[stage]}
          metaDescription={META_TITLE[stage]}
        />
        <Content
          tag="h1"
          cmsTag="Authentication:Forgotten-username:Retrieve:h1"
          copytext="Forgotten username"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem
              active={this.state.stage === 'PersonalDetails'}
              className={
                this.state.stage === 'accountDetails' ? 'completed' : ''
              }
            >
              {this.state.stage === 'PersonalDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}
              {this.state.stage === 'accountDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Completed"
                  copytext="Completed: "
                />
              ) : (
                ''
              )}

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Personal-details"
                copytext="Personal details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={this.state.stage === 'accountDetails'}>
              {this.state.stage === 'accountDetails' ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="BREADCRUMB:Active"
                  copytext="Active: "
                />
              ) : (
                ''
              )}

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="Account details"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
        {!this.state.credentialErrors ? (
          <Switch
            id="registration-alert"
            value={message || ''}
            tag="div"
            className="alert alert-danger"
            role="alert"
            scrolltotop={true}
            template={{
              AccountRestricted: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                companyName: CONTACTS.companyName,
                companySiteName: CONTACTS.companySiteName,
              },
              InvalidStatus: {
                phone: CONTACTS.phone,
                phoneLink: CONTACTS.phoneLink,
                companyName: CONTACTS.companyName,
              },
              NotRegistered: {
                companySiteName: CONTACTS.companySiteName,
              },
            }}
            contents={{
              AccountRestricted: {
                defaultValue:
                  'You are restricted from accessing your accounts online. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.',
              },
              InvalidStatus: {
                defaultValue:
                  'There has been an issue accessing your account. Please contact us on <a href="tel:$[phoneLink]">$[phone]</a>.',
              },
              NotRegistered: {
                defaultValue:
                  'It seems you are not yet registered for $[companySiteName]. You can register now to use this service.',
              },
              IdentifyAndVerifyFailed: {
                defaultValue:
                  'The details you have entered do not match our records. Please try again.',
              },
            }}
          />
        ) : (
          ''
        )}
        {this.getCredentialErrors(this.state.credentialErrors)}
        {this.currentStage()}
      </div>
    );
  }
}

Retrieve.propTypes = {
  stateData: PropTypes.object,
  resetComponentState: PropTypes.func,
  urlParams: PropTypes.any,
  updateUrl: PropTypes.any,
  refreshUrl: PropTypes.any,
  match: PropTypes.object,
  retrievedUserName: PropTypes.any,
  resetForgottenUN: PropTypes.any,
  retrieveUserName: PropTypes.any,
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Retrieve),
  storage.name,
);
