import { Connection, TwoFactor } from '@myie/interact';
import {
  TRANSFER_MONEY_REQUEST,
  TRANSFER_MONEY_SUCCESS,
  TRANSFER_MONEY_FAILURE,
  INITIALIZE_MOVE_MONEY,
} from './transferTypes';

export const initializeMoveMoney = () => ({
  type: INITIALIZE_MOVE_MONEY,
  isFetching: false,
  transferResponse: null,
  error: null,
});

export const requestTransferMoney = () => ({
  type: TRANSFER_MONEY_REQUEST,
  isFetching: true,
  error: null,
});

export const transferMoneySuccess = json => ({
  type: TRANSFER_MONEY_SUCCESS,
  transferResponse: json,
  isFetching: false,
  error: null,
});

export const transferMoneyFailure = error => ({
  type: TRANSFER_MONEY_FAILURE,
  isFetching: false,
  error: error.message,
});

export const transferInternalMoney = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(requestTransferMoney());
    return fetch(Connection.baseUrl('movemoney') + `v1.0/transfer`, {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    })
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(transferMoneySuccess(json)))
      .catch(error => dispatch(transferMoneyFailure(error)));
  });
};
