import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Text, Errors, Currency, Date } from '@myie/interact-dom';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { Content } from '@myie/interact-dom';

class MakePayment extends React.Component {
  render() {
    const {
      account,
      accounts,
      form,
      updateStage,
      onChangeAccount,
      onChange,
      onBlur,
      error,
      referrer = '/accounts',
    } = this.props;
    return (
      <div id="paysavings-make-payment">
        {error}

        <Form
          autoComplete="off"
          id="make-payment-form"
          onSubmit={e => updateStage(e, 'ReviewPayment')}
        >
          <Content
            tag="p"
            cmsTag="Savings-servicing:Pay-by-card:Make-payment:p1"
            copytext="Please complete the following details to make a payment from an  external card to your savings or loan account."
          />
          <fieldset>
            <Content
              tag="legend"
              cmsTag="Savings-servicing:Pay-by-card:Make-payment:legend-1"
              copytext="Select the amount you want to pay:"
            />
            <Row>
              <Col xs={12} sm={8} md={6}>
                <RedirectByAccountDropdown
                  accounts={accounts}
                  extendedId="pay-by-card"
                  id="pay-by-card-index"
                  baseUrl="/{accountType}-servicing/pay-into-account/"
                  filterAccounts={['Savings', 'Loan']}
                  defaultSelect={`${account.AccountKey.Key}`}
                  onChangeAccount={onChangeAccount}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} md={6}>
                <Currency
                  label="Enter the amount to pay"
                  cmsTag="GLOBAL:Enter-the-amount-to-pay"
                  showErrors={false}
                  validation={form.amount}
                  disabled={form.paymentType.value !== 'specific'}
                  onChange={onChange}
                  onBlur={onBlur}
                  code={account.CurrencyCode}
                  field="amount"
                  id="amount"
                  dp={2}
                />
              </Col>
            </Row>
            <Errors state={form.paymentType.state} field="paymentType" />
            <Errors state={form.amount.state} field="Amount" />
          </fieldset>
          <fieldset>
            <Content
              tag="legend"
              cmsTag="Savings-servicing:Pay-by-card:Make-payment:p2"
              copytext="Enter the details of the card that you want to add funds from:"
            />
            <Row>
              <Col xs={12} sm={8} md={6}>
                <Text
                  onChange={onChange}
                  onBlur={onBlur}
                  validation={form.cardNumber}
                  label="Enter your card number"
                  cmsTag="GLOBAL:Enter-your-card-number"
                  field="cardNumber"
                  id="cardNumber"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} md={6}>
                <Date
                  label="Enter your card expiry date"
                  cmsTag="GLOBAL:Enter-your-card-expiry-date"
                  id="expiryDate"
                  field="expiryDate"
                  onChange={onChange}
                  onBlur={onBlur}
                  validation={form.expiryDate}
                  mask="99/99"
                  className="w-50"
                  placeholder="MM/YY"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} md={6}>
                <Text
                  className="w-50"
                  onChange={onChange}
                  onBlur={onBlur}
                  validation={form.securityCode}
                  maxLength={3}
                  label="Enter your card security number (CVV)"
                  field="securityCode"
                  cmsTag="GLOBAL:Enter-your-card-securiy-number"
                  id="securityCode"
                />
              </Col>
            </Row>
          </fieldset>
          <div className="form-button-group">
            <Button id="make-payment-continue" type="submit" color="primary">
              <Content
                cmsTag="GLOBAL:Review-and-pay"
                copytext="Review and pay"
              />
            </Button>
            <Link
              id="make-payment-cancel"
              className="btn btn-secondary"
              role="button"
              to={referrer}
            >
              <Content
                cmsTag="GLOBAL:Cancel-payment"
                copytext="Cancel payment"
              />
            </Link>
          </div>
        </Form>
      </div>
    );
  }
}

MakePayment.propTypes = {
  account: PropTypes.any,
  accounts: PropTypes.any,
  error: PropTypes.any,
  form: PropTypes.any,
  info: PropTypes.any,
  isFetching: PropTypes.any,
  match: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  onChangeAccount: PropTypes.any,
  referrer: PropTypes.any,
  updateStage: PropTypes.any,
};

export default MakePayment;
