import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route } from 'react-router-dom';
import NominatedTransfer from './TranferIntoNominatedAccount/NominatedTransfer';
import Unavailable from './TranferIntoNominatedAccount/Unavailable';
import RequestPending from './TranferIntoNominatedAccount/RequestPending';

class NominatedAccount extends Component {
  render() {
    return (
      <div id="nominated-account-route-container">
        <Switch>
          <PrivateRoute
            path="/nominated-account/create/:page?/:id?"
            component={NominatedTransfer}
          />
          <PrivateRoute
            path="/nominated-account/no-nominated-account/:page?/:id?"
            component={Unavailable}
          />

          <PrivateRoute
            path="/nominated-account/request-pending"
            component={RequestPending}
          />

          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export { NominatedAccount };
