import { CONSTANTS, RULES } from '@myie/interact-dom';
import moment from 'moment';
import Currency from 'react-currency-formatter';
const getWithdrawalInfo = transactions => {
  const startOfYear = moment()
    .startOf('year')
    .toISOString();
  const now = moment()
    .startOf('minute')
    .toISOString();
  let withDrawalsOfYear = 0;
  transactions.forEach(trans => {
    const {
      ExtendedProperties: { GlobalTransactionType, TransactionDate },
    } = trans;
    if (GlobalTransactionType === CONSTANTS.TRANSACTION_TYPE_WITHDRAW) {
      if (moment(TransactionDate).isBetween(startOfYear, now)) {
        withDrawalsOfYear++;
      }
    }
  });
  return withDrawalsOfYear;
};

const isWithdrawalsPermitted = (account, Max_Withdrawals_Per_Year) => {
  const withdrawalsForYear = getWithdrawalInfo(
    account.transactions.Transactions,
  );
  let withdrawalsPermitted = true;
  if (Max_Withdrawals_Per_Year === CONSTANTS.TRANSACTION_MAX_WITHDRAWAL_NONE) {
    withdrawalsPermitted = false;
  }
  if (
    Max_Withdrawals_Per_Year !==
      CONSTANTS.TRANSACTION_MAX_WITHDRAWAL_UNLIMITED &&
    Max_Withdrawals_Per_Year &&
    Max_Withdrawals_Per_Year <= withdrawalsForYear
  ) {
    withdrawalsPermitted = false;
  }
  return withdrawalsPermitted;
};

export const ruleSet = (account, allProducts, data) => {
  if (!account) {
    return {};
  }
  const product = allProducts.find(
    product => product.Product_Code === account.ExtendedProperties.ProductCode,
  );
  const { Minimum_Operating_Balance } = product;
  const {
    ExtendedProperties: { AvailableBalance, PendingBalance } = {},
  } = account;
  const balance = AvailableBalance + PendingBalance;
  let maxAmountAllowed =
    balance < CONSTANTS.TWENTY_FIVE_THOUSAND
      ? balance
      : CONSTANTS.TWENTY_FIVE_THOUSAND;
  let formattedAmount = Currency({
    quantity: maxAmountAllowed,
    currency: 'GBP',
  });
  let maxAmountAllowedMessage =
    balance < CONSTANTS.TWENTY_FIVE_THOUSAND
      ? `Please enter an amount less than or equal to your full balance`
      : `Please enter a maximum amount of ${formattedAmount}`;
  const amount = {
    rules: {
      title: 'Amount',
      stop: true,
      required: {
        message: 'Please enter an amount.',
      },
      format: {
        regex: RULES.ammountFormat,
        message: 'Please enter a valid amount.',
      },
      minAmountCheck: {
        message: 'Please enter a minimum amount of £10.',
        comparison: () => {
          return [10, balance];
        },
      },
      maxAmountAllowedCheck: {
        message: `${maxAmountAllowedMessage}.`,
        comparison: () => {
          return maxAmountAllowed;
        },
      },
      minOperationalBalanceIfNotFullWithdraw: {
        message: `Please check and re-enter the amount as the minimum balance required for this account is £${Minimum_Operating_Balance}.`,
        comparison: () => {
          return [Minimum_Operating_Balance, balance];
        },
      },
    },
    hasUpdated: Date.now(),
    value: data && data['amount'] ? data['amount'] : '',
  };
  const withdrawalsPermitted = isWithdrawalsPermitted(
    account,
    product.Max_Withdrawals_Per_Year,
  );
  const withdrawalRule = {
    isPermitted: withdrawalsPermitted,
    message: `You have exceeded maximum withdrawals for this year for this account`,
  };
  return { amount, withdrawalRule };
};
