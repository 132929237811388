import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import MaturityDetails from './MaturityDetails';
import NoticeWithdrawalDate from './NoticeWithdrawalDate';
import SavingsGoals from './SavingsGoals';
import { CONSTANTS, Content } from '@myie/interact-dom';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      range: this.setInitialRange(props),
    };
  }

  setInitialRange(props) {
    if (props.range) {
      return props.range;
    }
    const to = moment
      .utc()
      .startOf('day')
      .toISOString();
    const from = moment
      .utc()
      .subtract(40, 'years')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    return {
      To: to,
      From: from,
      Latest: true,
    };
  }

  componentDidMount() {
    const { getAllProducts } = this.props;

    getAllProducts({
      checkLimitExceeded: false,
      isShortApply: false,
    });
  }

  accountType = () => {
    let { account, loopIndex, pageMode = 'summary' } = this.props;

    switch (account.ExtendedProperties.AccountType) {
      case CONSTANTS.SAVINGS_PLAN:
        return <MaturityDetails loopIndex={loopIndex} account={account} />;
      case CONSTANTS.REGULAR_SAVINGS:
        return (
          <React.Fragment>
            <NoticeWithdrawalDate
              loopIndex={loopIndex}
              isDetail={false}
              account={account}
            />
            <SavingsGoals
              loopIndex={loopIndex}
              isDetail={false}
              account={account}
              pageMode={pageMode}
            />
          </React.Fragment>
        );
      default:
    }
  };

  render() {
    const { loopIndex, account, pageMode = 'summary' } = this.props;
    const { ExtendedProperties: { AccountMode } = {} } = account;
    const products = this.props.allProducts;
    if (!products) {
      return null;
    }
    const product = products.find(product => {
      return product.Product_Code === account.ExtendedProperties?.ProductCode;
    });
    return (
      <React.Fragment>
        {this.accountType()}

        {product &&
        (account.AccountStatus !== 'Closed' &&
          account.ExtendedProperties?.AccountMode !==
            CONSTANTS.ACCOUNT_CLOSED) &&
        product.Savings_Goal_Accounts === 'Y' ? (
          <SavingsGoals
            loopIndex={loopIndex}
            isDetail={false}
            account={account}
            pageMode={pageMode}
          />
        ) : (
          ''
        )}

        {AccountMode === CONSTANTS.ACCOUNT_VIEW_ONLY ? (
          <Card
            key={`no-transactions-${loopIndex}`}
            className="no-transactions-card mb-0"
          >
            <CardBody>
              <Content
                cmsTag="Account-type-savings:Overview:Account-does-not-allow-transactions-online"
                copytext="This account does not allow transactions online."
              />
            </CardBody>
          </Card>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

Overview.propTypes = {
  account: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.any,
  range: PropTypes.any,
  allProducts: PropTypes.any,
  getAllProducts: PropTypes.func,
  viewGoalDetails: PropTypes.object,
  pageMode: PropTypes.string,
};

const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition, accountsDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...accountsDefinition,
  };
};
export default connect(
  mapStateToProps,
  { ...mapSavingsServicingDispatchToProps, ...mapDispatchToProps },
)(Overview);
