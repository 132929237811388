import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, BreadcrumbItem } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { Text, Check, RULES, sharedUtility } from '@myie/interact-dom';
import {
  Content,
  Switch,
  AppMeta,
  LinkTag,
  FormErrorList,
} from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-authentication';
import { mapDispatchToProps as mapSharedDispatchToProps } from '@myie/interact-shared';
import { CONTACTS } from '@myie/interact-local-dom';
import { Session, Validate, Utility } from '@myie/interact';
import { SystemMessage } from '@myie/interact-widget-system-message-dom';

class CreateLogin extends React.Component {
  constructor(props) {
    super(props);
    const { login, toggleRememberMe } = this.props;
    let signoutMessage = window.sessionStorage.getItem('timeoutMessage');
    window.sessionStorage.removeItem('timeoutMessage');
    let error;
    if (signoutMessage) {
      error = signoutMessage;
    }
    if (login && login.Status === 'InvalidTicket') {
      error = 'InvalidTicket';
    }

    const remembermeUsername = localStorage.getItem('rememberMe');
    toggleRememberMe(!!remembermeUsername);
    this.state = {
      error: error,
      count: 0,
      rememberMeFlag: remembermeUsername,
      loginStatus: '',
      cachedUserName: remembermeUsername
        ? Utility.hexDecode(remembermeUsername)
        : '',
      form: {
        username: {
          value: '',
          title: 'Enter your username',
          rules: {
            title: 'Username',
            stop: true,
            required: {
              message: 'Please enter a username.',
            },
            range: {
              min: 6,
              max: 23,
              message:
                'Your username must be at least 6 characters, and no more than 23 characters.',
            },
            format: {
              regex: RULES.username,
              message: 'Please enter a valid username.',
            },
          },
          cmsTag: 'GLOBAL:Enter-your-username',
        },
        password: {
          title: 'Enter your password.',
          rules: {
            stop: true,
            title: 'Password',
            required: {
              message: 'Please enter a password.',
            },
            format: {
              regex: RULES.password,
              message: 'Please enter a valid password.',
            },
          },
          cmsTag: 'GLOBAL:Enter-your-password',
        },
        rememberMe: {
          value: !!remembermeUsername,
          rules: {
            title:
              'Remember me (please do not use this option if you are using a shared computer)',
          },
        },
      },
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { login, isFetching } = nextProps;

    if (login) {
      if (login.statusCountCreate === 2 && login.Status === 'Failed') {
        return {
          loginStatus: 'warning',
        };
      }
      if (login.Status !== 'Failed') {
        return {
          loginStatus: login.Status,
          count: 0,
        };
      }
      if (
        login.statusCountCreate === 3 &&
        prevState.loginStatus === 'warning'
      ) {
        return null;
      }
      if (
        login.statusCountCreate === 1 &&
        login.Status === 'Failed' &&
        !isFetching
      ) {
        return {
          loginStatus: login.Status,
        };
      }
    }
    return null;
  };

  componentDidMount() {
    const { setReferrer, userLogout } = this.props;
    const { referrer } = this.props || { referrer: { pathname: '/accounts' } };
    userLogout();
    setReferrer(referrer);
    sharedUtility.setUrl('/sign-in');
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  onClick = e => {
    const { name, checked } = e.target;
    const { toggleRememberMe } = this.props;
    let { form } = this.state;
    form = Validate.input(name, checked ? 'true' : null, form);
    this.setState({ ...this.state, form });
    toggleRememberMe(checked);
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = e => {
    e.preventDefault();
    const { createLogin, setRememberMeUserName } = this.props;
    let { form, count, rememberMeFlag, cachedUserName } = this.state;
    let countValue = count;
    let formCopy = { ...form };
    let Username = form.username.value;
    if (rememberMeFlag && form.rememberMe.value && !form.username.value) {
      formCopy.username.value = cachedUserName;
      Username = cachedUserName;
      formCopy = Validate.form(formCopy);
      if (!formCopy.approved) {
        this.setState({
          ...this.state,
          form: formCopy,
          count: countValue,
          error: null,
        });
        return;
      }
      countValue = count + 1;
    } else {
      form = Validate.form(form);

      if (!form.approved) {
        this.setState({ ...this.state, form, count: countValue, error: null });
        window.scrollTo(0, 0);
        return;
      }
      countValue = count + 1;
    }
    this.setState({ ...this.state, form, count: countValue, error: null });
    const request = {
      Username,
      CredentialValues: [
        {
          Name: 'Password',
          Value: form.password.value,
          Context: null,
        },
      ],
      ExtendedProperties: null,
    };
    setRememberMeUserName(Username);
    createLogin(request);
  };

  render() {
    const { login } = this.props;
    const { form, loginStatus, rememberMeFlag } = this.state;
    if (Session.isAuthenticated()) {
      Session.abandon(null);
    }
    let error;
    if (login) {
      switch (loginStatus) {
        case 'Success':
          Session.set(login.SessionTicket, login.Customer);
          return <Redirect to={this.props.referrer.pathname} />;
        case 'Failed':
          error = 'Failed';
          break;
        case 'warning':
          error = 'warning';
          break;
        case 'Blocked':
          return <Redirect to="/sign-in/access-denied" />;
        case 'CredentialBlocked':
          return <Redirect to="/sign-in/blocked" />;
        case 'Inactive':
          error = 'Inactive';
          break;
        case 'Incomplete':
          return <Redirect to="/sign-in/step-2" />;
        default:
      }
    }
    return (
      <div id="sign-in-step-1">
        <AppMeta
          id="meta-data"
          stage="child"
          contacts={CONTACTS}
          title="Sign in Step 1"
          metaDescription="Sign in step 1"
        />
        <Content
          tag="h1"
          cmsTag="Authentication:Sign-in:Create:h1"
          copytext="Sign in"
        />
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem active>
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="BREADCRUMB:Active"
                copytext="Active: "
              />
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Username-and-password"
                copytext="Username &amp; password"
              />
            </BreadcrumbItem>
            <BreadcrumbItem>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content cmsTag="BREADCRUMB:Passcode" copytext="Passcode" />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
        {<SystemMessage />}
        <Switch
          id="login-user-alert"
          value={this.state.error ? this.state.error : error ? error : ''}
          defaultText={this.state.error ? this.state.error : error ? error : ''}
          scrolltotop={true}
          tag="div"
          template={{
            CredentialBlocked: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              companyName: CONTACTS.companyName,
            },
            Inactive: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              companyName: CONTACTS.companyName,
            },
          }}
          className="alert alert-danger"
          contents={{
            secondAttempt: {
              defaultValue:
                'Please be aware as this is your second attempt you have one attempt left, after that you will be locked out of your account. If you are locked out, you can reset your passcode to access your account.',
            },
            TimeoutReset: {
              defaultValue:
                'You took too long to complete the sign in process. Please try again.',
            },
            UnauthorizedSignedOut: {
              defaultValue:
                'You have been logged out of your current session due to issues with your session on the server. Please sign in again to continue using the system.',
            },
            Timeout: {
              defaultValue:
                'For security reasons we have logged you out. Please restart the registration process.',
            },
            CredentialBlocked: {
              defaultValue:
                'Please contact the $[companyName] Helpline on <a href="tel:$[phoneLink]">$[phone]</a> as you have reached the maximum number of failed attempts, therefore your account is now blocked.',
            },
            Inactive: {
              defaultValue: `Please be aware your account status is set to 'inactive' and therefore access to the Servicing Portal is unavailable. Please contact the $[companyName] Helpline on <a href="tel:$[phoneLink]">$[phone]</a>.`,
            },
            warning: {
              defaultValue:
                'Please be aware as this is your second attempt you have one attempt left, after that you will be locked out of your account. If you are locked out, you can reset your password to access your account.',
            },
            Failed: {
              defaultValue: 'Unknown Username/Password combination.',
            },
          }}
        />
        <FormErrorList
          validations={form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form autoComplete="off" id="login-create-form" onSubmit={this.submit}>
          {rememberMeFlag && form.rememberMe.value && !form.username.value ? (
            <div className="remembered-title">
              <h2>
                <Content
                  cmsTag="Authentication:Sign-in:Create:Remembered-greeting"
                  copytext="Hi "
                />
                {this.state.cachedUserName}
              </h2>
              <p>
                <Content
                  cmsTag="Authentication:Sign-in:Create:p1-part-1"
                  copytext="If this is not your account, you can "
                />
                <Check
                  cmsTag="Authentication:Sign-in:Create:Change-user"
                  label={`login as a different user`}
                  id="remember-me2"
                  field="rememberMe"
                  className="hidden-checkbox inline-checkbox"
                  checked={!!form.rememberMe.value}
                  value="true"
                  validation={form.rememberMe}
                  onChange={this.onClick}
                  labelClassName="link-label"
                  removeFormGroup={false}
                />
                <Content
                  cmsTag="Authentication:Sign-in:Create:change-user-p1-part-2"
                  copytext="."
                />
              </p>
            </div>
          ) : (
            <Text
              label="Username"
              cmsTag="GLOBAL:Enter-your-username"
              id="username"
              field="username"
              onChange={this.onChange}
              onBlur={this.onBlur}
              validation={form.username}
              maxLength={23}
              suffix={
                <Link to="/forgotten-username">
                  <Content
                    cmsTag="Authentication:Sign-in:Create:Request-a-username-reminder"
                    copytext="Request a username reminder"
                  />
                </Link>
              }
            />
          )}

          <Text
            label="Enter your password"
            cmsTag="GLOBAL:Enter-your-password"
            id="password"
            field="password"
            onChange={this.onChange}
            maxLength={50}
            onBlur={this.onBlur}
            type="password"
            validation={form.password}
            suffix={
              <Link to="/forgotten-password">
                <Content
                  cmsTag="GLOBAL:Reset-password"
                  copytext="Reset password"
                />
              </Link>
            }
          />
          {!(
            rememberMeFlag &&
            form.rememberMe.value &&
            !form.username.value
          ) ? (
            <Check
              label="Remember my login details"
              cmsTag="Authentication:Sign-in:Create:Remember-me"
              id="remember-me"
              field="rememberMe"
              checked={!!form.rememberMe.value}
              value="true"
              validation={form.rememberMe}
              onChange={this.onClick}
              suffix={
                <React.Fragment>
                  <Content
                    cmsTag="Authentication:Sign-in:Create:Sublabel-1"
                    copytext="You need to allow cookies to do this. "
                  />
                  <LinkTag href={CONTACTS.externalHomeURL}>
                    <Content
                      cmsTag="Authentication:Sign-in:Create:Sublabel-2"
                      copytext="Do you want to know more?"
                    />
                  </LinkTag>
                </React.Fragment>
              }
            />
          ) : (
            ''
          )}

          <div className="form-button-group">
            <Button
              id="login-create-submit"
              type="submit"
              color="primary"
              disabled={this.props.isFetching}
            >
              <Content
                cmsTag="Authentication:Sign-in:Continue"
                copytext="Continue"
              />
            </Button>
            <LinkTag
              className="btn btn-secondary"
              role="button"
              href={CONTACTS.externalHomeURL}
            >
              <Content
                cmsTag="GLOBAL:Back-to-home-page"
                copytext="Back to home page"
              />
            </LinkTag>
          </div>
        </Form>
      </div>
    );
  }
}

CreateLogin.propTypes = {
  createLogin: PropTypes.any,
  login: PropTypes.any,
  referrer: PropTypes.any,
  setReferrer: PropTypes.any,
  userLogout: PropTypes.any,
  toggleRememberMe: PropTypes.func,
  setRememberMeUserName: PropTypes.func,
  isFetching: PropTypes.bool,
  contacts: PropTypes.object,
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...mapSharedDispatchToProps,
  },
)(CreateLogin);
