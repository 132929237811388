import React from 'react';
import { Redirect } from 'react-router-dom';
import { Session } from '@myie/interact';

const Home = () => {
  if (localStorage.getItem('firstLogin'))
    return <Redirect to={'/personal-details/additional-details'} />;

  return (
    <React.Fragment>
      {Session.isAuthenticated() ? (
        <Redirect to="/accounts" />
      ) : (
        <Redirect to="/sign-in" />
      )}
    </React.Fragment>
  );
};

export default Home;
