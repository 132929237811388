import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  mapDispatchToProps,
  mapStateToProps,
} from '@myie/interact-brand-savings-servicing';
import Item from './Item';
import { Row, Col, Card, Button, CardBody } from 'reactstrap';
import { Content, Text, DropDown } from '@myie/interact-dom';
import { Validate } from '@myie/interact';

class Category extends React.Component {
  constructor(props) {
    super(props);
    const { category } = this.props;
    this.state = {
      isOpen: category.type === 'income' ? true : false,
      form: this.getFormInit(),
    };
  }

  getFormInit = () => {
    return {
      other_costs: {
        value: '',
        rules: {
          required: { message: 'Please enter a value.' },
          stop: true,
        },
      },
      debts: {
        value: '',
        rules: {
          required: { message: 'Please enter a value.' },
          stop: true,
        },
      },
      court_payments: {
        value: '',
        rules: {
          required: { message: 'Please enter a value.' },
          stop: true,
        },
      },
      assets: {
        value: '',
        rules: {
          required: { message: 'Please enter a value.' },
          stop: true,
        },
      },
    };
  };

  onClick = e => {
    e.preventDefault();
    const { isOpen } = this.state;
    this.setState({ ...this.state, isOpen: !isOpen });
  };

  onItemAdd = (categoryType, formInput) => {
    const { addBudgetPlannerCatItem } = this.props;
    let { form } = this.state;
    const value = form[formInput].value;
    form = Validate.input(formInput, value, form);
    this.setState({ ...this.state, form });
    if (form[formInput].value) {
      let item;
      if (categoryType === 'other-costs') {
        item = {
          allow_add: true,
          allow_delete: true,
          amount: null,
          description: '',
          frequency: 'month',
          items: null,
          name: value,
          note: '',
          type: value.replace(/\s/g, '-').toLowerCase(),
        };
      } else if (categoryType === 'debts') {
        item = {
          name: value,
          frequency: 'month',
          amount: null,
          type: null,
          total_owed: null,
          allow_delete: true,
        };
      } else if (categoryType === 'court_payments') {
        item = {
          allow_delete: true,
          total_owed: null,
          amount: null,
          frequency: 'month',
          type: value.replace(/\s/g, '-').toLowerCase(),
        };
      } else if (categoryType === 'assets') {
        item = {
          allow_delete: true,
          amount: null,
          type: value.replace(/\s/g, '-').toLowerCase(),
        };
      }
      this.setState({ ...this.state, form: this.getFormInit() });
      addBudgetPlannerCatItem(categoryType, item);
    }
  };

  onNewItemTextChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  getCategoryItemAddContent = (category, categoryType) => {
    const { form } = this.state;
    let content;

    let labelText,
      formInput = null;
    let allowAddItem = false;

    if (categoryType === 'other-costs') {
      labelText = 'Name of cost';
      formInput = 'other_costs';
      allowAddItem =
        category.items && category.items.length === 2 ? false : true;
    } else if (categoryType === 'debts') {
      labelText = 'Name of organisation or lender';
      formInput = 'debts';
      allowAddItem =
        category.items && category.items.length === 2 ? false : true;
    } else if (categoryType === 'court_payments') {
      labelText = 'Type of court order';
      formInput = 'court_payments';
      allowAddItem =
        category.items && category.items.length === 2 ? false : true;
    } else if (categoryType === 'assets') {
      labelText = 'Type of asset';
      formInput = 'assets';
      allowAddItem =
        category.items && category.items.length === 2 ? false : true;
    }

    if (allowAddItem) {
      content = (
        <Row>
          <Col xs={12} md={12} lg={12}>
            <label htmlFor={formInput} id={`${formInput}-add-item-label`}>
              {labelText}
            </label>
          </Col>
          <Col xs={12} md={5} lg={4}>
            {categoryType === 'court_payments' ? (
              <DropDown
                id={formInput}
                field={formInput}
                value={form[formInput].value}
                validation={form[formInput]}
                onChange={this.onNewItemTextChange}
                onBlur={this.onNewItemTextChange}
              >
                <option
                  key="0"
                  id="court-payments-default"
                  value=""
                  defaultText="Please select"
                />
                <option
                  key="1"
                  id="court-payments-option-1"
                  value="Charging order"
                  defaultText="Charging order"
                />
                <option
                  key="2"
                  id="court-payments-option-2"
                  value="Child support (CSA) liability order"
                  defaultText="Child support (CSA) liability order"
                />
                <option
                  key="3"
                  id="court-payments-option-3"
                  value="County Court judgment (CCJ)"
                  defaultText="County Court judgment (CCJ)"
                />
                <option
                  key="4"
                  id="court-payments-option-4"
                  value="Criminal fine"
                  defaultText="Criminal fine"
                />
                <option
                  key="5"
                  id="court-payments-option-5"
                  value="Fixed penalty notice"
                  defaultText="Fixed penalty notice"
                />
                <option
                  key="6"
                  id="court-payments-option-6"
                  value="High Court writ"
                  defaultText="High Court writ"
                />
                <option
                  key="7"
                  id="court-payments-option-7"
                  value="Inhibition"
                  defaultText="Inhibition"
                />
                <option
                  key="8"
                  id="court-payments-option-8"
                  value="Money judgement"
                  defaultText="Money judgement"
                />
                <option
                  key="9"
                  id="court-payments-option-9"
                  value="Penalty charge notice (PCN)"
                  defaultText="Penalty charge notice (PCN)"
                />
                <option
                  key="10"
                  id="court-payments-option-10"
                  value="Payday loan"
                  defaultText="Payday loan"
                />
                <option
                  key="11"
                  id="court-payments-option-11"
                  value="Rent arrears"
                  defaultText="Rent arrears"
                />
                <option
                  key="12"
                  id="court-payments-option-12"
                  value="Store card"
                  defaultText="Store card"
                />
                <option
                  key="13"
                  id="court-payments-option-13"
                  value="TV Licence arrears"
                  defaultText="TV Licence arrears"
                />
                <option
                  key="14"
                  id="court-payments-option-14"
                  value="Water arrears"
                  defaultText="Water arrears"
                />
              </DropDown>
            ) : (
              <Text
                id={formInput}
                field={formInput}
                value={form[formInput].value}
                validation={form[formInput]}
                onChange={this.onNewItemTextChange}
                onBlur={this.onNewItemTextChange}
              />
            )}
          </Col>
          <Col xs={12} md={3} lg={4}>
            <Button
              className="add-a-debt-button"
              type="submit"
              color="secondary"
              onClick={() => this.onItemAdd(categoryType, formInput)}
            >
              {categoryType === 'court_payments' ? (
                <Content
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Category:Add-a-court-payment"
                  copytext="Add a court payment"
                />
              ) : categoryType === 'assets' ? (
                <Content
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Category:Add-an-asset"
                  copytext="Add an asset"
                />
              ) : categoryType === 'other-costs' ? (
                <Content
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Category:Add-another-cost"
                  copytext="Add another cost"
                />
              ) : (
                <Content
                  cmsTag="Savings-servicing:Money-tools:Budget-calculator:Category:Add-a-debt"
                  copytext="Add a debt"
                />
              )}
            </Button>
          </Col>
        </Row>
      );
    }

    return <React.Fragment>{content}</React.Fragment>;
  };

  render() {
    const { category, categoryIndex } = this.props;
    const { isOpen } = this.state;
    let categoryName;
    let categoryDescription;
    let categoryType = categoryIndex;

    if (categoryType === 'debts') {
      categoryName = 'Debts: who do you owe money to?';
      categoryDescription =
        'This could include rent arrears, gas or electricity, credit and store cards, or bank loans.';
    } else if (categoryType === 'court_payments') {
      categoryName = 'Court Payments';
    } else if (categoryType === 'assets') {
      categoryName = 'Assets: what do you own?';
    } else {
      categoryName = category.name;
      categoryDescription = category.description;
      categoryType = category.type;
    }

    let items = [];
    if (category.items) {
      category.items.forEach((item, index) => {
        let itemType = item.type;
        if (categoryIndex === 'debts') {
          itemType = item.name;
        }
        let hyphenedName = itemType
          .replace(/\s/g, '-')
          .replace('&', '-')
          .replace('(', '-')
          .replace(')', '-')
          .replace('/', '-');

        items.push(
          <Card
            id={`item-${hyphenedName}-${index}`}
            key={`item-${hyphenedName}-${index}`}
            className={`entry-card ${categoryType}-card`}
          >
            <CardBody>
              <Item
                item={item}
                index={index}
                category={categoryName}
                group=""
                categoryIndex={categoryIndex}
                categoryType={categoryType}
              />
            </CardBody>
          </Card>,
        );
      });
    }
    return (
      <React.Fragment>
        <section className="budget-planner-section" id={category.type}>
          <h3>
            <Button
              color="link"
              onClick={e => this.onClick(e)}
              className={`${
                isOpen
                  ? 'd-flex expand-button chevron up'
                  : 'd-flex expand-button chevron down'
              }`}
              id={`cat-index-${categoryIndex}`}
            >
              {categoryName}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 11.41 7.12"
                className="ml-auto align-self-center"
              >
                <path
                  fill="#404040"
                  d="M0,1.41L1.41,0l4.29,4.29L10,0l1.41,1.41L5.71,7.12L0,1.41z"
                />
              </svg>
              {isOpen ? (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:Close-CATEGORYNAME"
                  template={{
                    copytext: {
                      categoryName: categoryName,
                    },
                  }}
                  copytext="Close $[categoryName]"
                />
              ) : (
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:Open-CATEGORYNAME"
                  template={{
                    copytext: {
                      categoryName: categoryName,
                    },
                  }}
                  copytext="Open $[categoryName]"
                />
              )}
            </Button>
          </h3>
          {isOpen ? (
            <React.Fragment>
              {categoryDescription ? (
                <p className="mt-3">{categoryDescription}</p>
              ) : null}
              {this.getCategoryItemAddContent(category, categoryType)}
              {items}
            </React.Fragment>
          ) : (
            ''
          )}
        </section>
      </React.Fragment>
    );
  }
}

Category.propTypes = {
  category: PropTypes.any,
  categoryIndex: PropTypes.any,
  calcAmountPerFrequency: PropTypes.func,
  addBudgetPlannerCatItem: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Category);
