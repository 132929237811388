import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stateUtility, Content } from '@myie/interact-dom';
import { storage } from './storageName';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import Delete from './Delete';
import { Utility } from '@myie/interact';
import loadStateHOC from '../../../../shared/stateManager/loadStateHOC';
import Confirmation from './Confirmation';

class DeleteGoal extends Component {
  constructor(props) {
    super(props);
    const { match, stateData, urlParams } = this.props;
    const stateList = ['delete', 'confirm'];

    const hasStage = stateList.find(element =>
      element === urlParams ? urlParams.pageStage : '',
    );
    let stage =
      stateData &&
      stateData.url &&
      stateData.url.pageStage &&
      props.urlParams &&
      props.urlParams.pageStage
        ? props.urlParams.pageStage
        : 'delete';
    if (!hasStage) {
      stage = 'delete';
    }
    if (match && match.params && match.params.replace) {
      stage = 'selectGoal';
    }
    // const data = stateData && stateData.formData ? stateData.formData : {};
    stateUtility.setGlobalValue({ storage: storage.name });
    this.state = {
      stage: stage,
    };
  }

  // save the data for the request from several forms
  saveData = (name, value) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...this.state, data, message: null });
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps(props, state) {
    const {
      accounts,
      match,
      viewGoalDetails,
      viewGoals,
      isFetching,
      deleteGoalDetails,
    } = props;
    if (deleteGoalDetails && deleteGoalDetails.ResponseStatus) {
      return {
        stage: 'confirm',
      };
    }
    let account;
    let keys = [];

    if (
      accounts &&
      accounts.Accounts &&
      !viewGoalDetails &&
      !isFetching &&
      !state.called
    ) {
      if (match && match.params.id) {
        account = accounts.Accounts.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.AccountKey.Key) === match.params.id
            );
          }
          return false;
        });
        if (account) {
          keys.push(account.AccountKey);
        }
        viewGoals({
          AccountKeys: keys,
        });
        return {
          called: true,
        };
      }
    }
    return null;
  }

  changeUrl = () => {
    const { match, history } = this.props;

    switch (match.params.page) {
      case 'goal-list':
        history.push('/money-tools/savings-goals/view');
        break;
      case 'detail':
      case 'account-details':
        {
          let url = `/accounts/details/${match.params.id}`;
          history.push(url);
        }
        break;
      case 'summary':
        history.push('/accounts/list');
        break;
      default:
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'goal-list':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-savings-goal-list"
            copytext="Back to savings goal list"
          />
        );
      case 'myDetails':
      default:
        return null;
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  // change the stage of the form
  setStage = stage => {
    this.setState({ ...this.state, stage, message: null });
  };

  setForm = form => {
    this.setState({ ...this.state, form, message: null });
  };

  componentDidMount() {
    const { fetchAccountsIfNeeded, urlParams, updateUrl } = this.props;

    if (!urlParams.pageStage) {
      let stateData = {
        formData: null,
        url: {
          pageStage: 'delete',
        },
      };
      updateUrl(stateData);
    }

    fetchAccountsIfNeeded();
  }

  componentWillUnmount() {
    const { resetDeleteGoals, resetViewGoals } = this.props;
    resetDeleteGoals();
    resetViewGoals();
  }

  deleteGoal = () => {
    const { accounts, match, updateUrl } = this.props;
    if (match && match.params.id) {
      let account = accounts.Accounts.find(function(element) {
        if (element) {
          return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
        }
        return false;
      });
      const { deleteGoals } = this.props;
      let stateData = {
        formData: null,
        url: {
          pageStage: 'confirm',
        },
      };
      updateUrl(stateData);
      let request = {
        AccountKey: account.AccountKey,
        AccountCurrency: 'GBP',
      };

      deleteGoals(request);
    }
  };

  currentStage = () => {
    const { viewGoalDetails, accounts, match } = this.props;
    let goal;
    if (!accounts || !viewGoalDetails) {
      return null;
    }
    if (accounts && viewGoalDetails && viewGoalDetails.Goals) {
      goal = viewGoalDetails.Goals.find(element => {
        if (element) {
          return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
        }
        return null;
      });
    }

    let account = accounts.Accounts.find(function(element) {
      if (element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      }
      return false;
    });

    switch (this.state.stage) {
      case 'delete':
        return (
          <Delete
            getBackButton={this.getBackButton}
            account={account}
            goal={goal}
            page={match.params.page}
            deleteGoal={this.deleteGoal}
          />
        );
      case 'confirm':
        return (
          <Confirmation
            page={match.params.page}
            getBackButton={this.getBackButton}
            goal={goal}
          />
        );

      default:
    }
  };

  render() {
    const { viewGoalDetails } = this.props;

    if (!viewGoalDetails) {
      return null;
    }
    return <div id="delete-goal">{this.currentStage()}</div>;
  }
}

DeleteGoal.propTypes = {
  accounts: PropTypes.any,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  createGoalDetails: PropTypes.func,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.func,
  deleteGoals: PropTypes.func,
  resetCreateGoals: PropTypes.any,
  viewGoalDetails: PropTypes.any,
  stateData: PropTypes.any,
  urlParams: PropTypes.func,
  account: PropTypes.any,
  resetDeleteGoals: PropTypes.func,
  deleteGoalDetails: PropTypes.any,
  viewGoals: PropTypes.func,
  updateUrl: PropTypes.func,
  resetViewGoals: PropTypes.func,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
  )(withRouter(DeleteGoal)),
  storage.name,
);
