import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CardColumns, Button, ButtonGroup, Fade, Col, Row } from 'reactstrap';
import _ from 'lodash';
import LocalAccountSummary from './LocalAccountSummary';
import { SavingsGoalMessage } from '@myie/interact-savings-servicing-dom';
import AccountBannerMessage from './AccountBannerMessage';
import { mapDispatchToProps } from '@myie/interact-accounts';
import Message from './SignInMessage/Message';
import { CONTACTS } from '@myie/interact-local-dom';
import NominatedAccountUnsetMessage from './NominatedAccountUnsetMessage';
import NominatedAccountPendingMessage from './NominatedAccountPendingMessage';
import { Markdown, AppMeta, Content, CONSTANTS } from '@myie/interact-dom';
import { storage } from './storageName';
import { Validate } from '@myie/interact';
import loadStateHOC from '../shared/stateManager/loadStateHOC';
import checkDownTime from '../shared/HO_components/checkDownTime';

class AccountsList extends React.Component {
  constructor(props) {
    super(props);
    let mode =
      props.urlParams && props.urlParams.mode ? props.urlParams.mode : 'active';

    if (mode === 'active') {
      props.setStatusMode(0);
    } else {
      props.setStatusMode(1);
    }
    this.state = {
      companyList: [],
      shown: true,
      form: {
        company: {
          rules: {
            title: 'Filter by company',
            stop: true,
          },
          value: '',
        },
      },
    };
  }

  static getDerivedStateFromProps = nextProps => {
    const elements = [];
    const keyValues = [];
    const { accounts = {}, status } = nextProps;
    let { Accounts: list = [] } = accounts;
    if (list.length > 0) {
      // status === 1 means show only closed accounts
      list.forEach(account => {
        if (
          (account.AccountStatus !== 'Closed' &&
            account.ExtendedProperties?.AccountMode !==
              CONSTANTS.ACCOUNT_CLOSED) === !status &&
          account.ExtendedProperties?.CompanyName
        ) {
          if (
            !keyValues.find(function(key) {
              return account.ExtendedProperties.CompanyName === key;
            })
          ) {
            elements.push(
              <option
                id={`company-${account.AccountNumber}-option`}
                key={`company-${account.AccountNumber}-option`}
                value={account.ExtendedProperties.CompanyName}
              >
                {account.ExtendedProperties.CompanyName}
              </option>,
            );
          }
          keyValues.push(account.ExtendedProperties.CompanyName);
        } else {
          // status === 1 means show only closed accounts
          if (status === 1 && account.ExtendedProperties?.CompanyName) {
            if (
              !keyValues.find(function(key) {
                return account.ExtendedProperties.CompanyName === key;
              })
            ) {
              elements.push(
                <option
                  id={`company-${account.AccountNumber}-option`}
                  key={`company-${account.AccountNumber}-option`}
                  value={account.ExtendedProperties.CompanyName}
                >
                  {account.ExtendedProperties.CompanyName}
                </option>,
              );
            }
            keyValues.push(account.ExtendedProperties.CompanyName);
          }
        }
      });

      return {
        companyList: elements,
      };
    }

    return null;
  };

  setMode = mode => {
    const props = this.props;
    if (mode === props.mode) {
      return;
    }
    const { setListMode } = props;
    this.setState({ shown: false });
    setTimeout(
      function() {
        setListMode(mode);
        this.setState({ shown: true });
      }.bind(this),
      250,
    );
  };

  onChangeAccount = e => {
    const { value, name } = e.target;

    let { form } = this.state;
    form = Validate.input(name, value, form);
    this.setState({ ...this.state, form });
  };

  setStatus = status => {
    const props = this.props;

    let stateData = {
      url: {
        mode: status ? 'closed' : 'active',
      },
    };
    props.updateUrl(stateData);
    if (status === props.status) {
      return;
    }
    let { form } = this.state;
    let companyName = form.company.value;
    if (status === 0) {
      // If now show open accounts
      // and current company doesn't have any
      // clear it out
      if (
        this.props.accounts.Accounts.filter(a => {
          return (
            a.ExtendedProperties.CompanyName === companyName &&
            (a.AccountStatus !== 'Closed' &&
              a.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED)
          );
        }).length === 0
      ) {
        form.company.value = '';
      }
    }
    const { setStatusMode } = props;
    this.setState({ shown: false, form });
    setTimeout(
      function() {
        setStatusMode(status);
        this.setState({ shown: true });
      }.bind(this),
      250,
    );
  };

  sortAccountList = accounts => {
    let mortgageList = [];
    let savingsList = [];
    accounts.forEach(account => {
      let thisAccount = account;

      if (
        account.ExtendedProperties.AccountType ===
        CONSTANTS.ACCOUNT_TYPE_MORTGAGE
      ) {
        if (account.ExtendedProperties.SubAccounts) {
          const { ExtendedProperties: { CurrentBalance } = {} } = account;
          let outstandingAmount = CurrentBalance;
          account.ExtendedProperties.SubAccounts.forEach(element => {
            const { ExtendedProperties: { CurrentBalance } = {} } = element;

            outstandingAmount = outstandingAmount + CurrentBalance;
          });
          account.outstandingAmount = outstandingAmount;
        }
        mortgageList.push(thisAccount);
      } else {
        const {
          ExtendedProperties: { AvailableBalance, PendingBalance } = {},
        } = account;
        thisAccount.AvailableBalance = AvailableBalance + PendingBalance;
        savingsList.push(thisAccount);
      }
    });
    savingsList = _.orderBy(savingsList, 'AvailableBalance', 'desc');
    mortgageList = _.orderBy(mortgageList, 'outstandingAmount', 'desc');

    const list = savingsList.concat(mortgageList);
    return list;
  };

  addPeriods = accounts => {
    if (accounts) {
      return accounts.map(account => {
        let segments = account.AccountName.split(/\s+/);
        const intValue = parseInt(segments[0]);
        let period = 0;
        switch (segments[1].toLowerCase()) {
          case 'year':
            period = intValue * 360;
            break;
          case 'month':
            period = intValue * 30;
            break;
          case 'day':
            period = intValue;
            break;
          case 'hour':
            period = intValue / 24;
            break;
          case 'minute':
            period = intValue / 1440;
            break;
          default:
        }
        account.period = period;
        return account;
      });
    }
    return accounts;
  };

  render() {
    let {
      accounts = {},
      mode,
      status,
      nominatedAccountStatus,
      viewGoalDetails,
      isAccountFetching,
      systemDownStatus, //From checkDownTime HOC
    } = this.props;
    let {
      Accounts: list = [],
      ExtendedProperties: { HasNominatedAccount = null } = {},
    } = accounts;
    let children = [];
    let messages = [];
    let gridToggle = null;
    let statusToggle = null;
    let accountsToList = [];
    const { shown } = this.state;

    list.forEach(account => {
      let thisAccount = account;
      if (
        (thisAccount.AccountStatus !== 'Closed') === !status &&
        thisAccount.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED
      ) {
        accountsToList.push(thisAccount);
      }
    });
    if (list.length) {
      accountsToList = this.sortAccountList(accountsToList);
    }

    statusToggle = (
      <ButtonGroup className="d-inline">
        <Button
          id="filter-active-btn"
          color="light"
          active={!status}
          aria-pressed={!status}
          onClick={() => this.setStatus(0)}
        >
          <Content
            tagClassName="sr-only"
            tag="span"
            cmsTag="GLOBAL:View-"
            copytext="View "
          />
          <Content
            cmsTag="Accounts:Accounts-list:Toggle-button:Active"
            copytext="Active"
          />
          <Content
            tagClassName="sr-only"
            tag="span"
            cmsTag="Accounts:Accounts-list:Toggle-button:-accounts"
            copytext=" accounts"
          />
        </Button>
        <Button
          id="filter-closed-btn"
          color="light"
          active={!!status}
          aria-pressed={!!status}
          onClick={() => this.setStatus(1)}
        >
          <Content
            tagClassName="sr-only"
            tag="span"
            cmsTag="GLOBAL:View-"
            copytext="View "
          />
          <Content
            cmsTag="Accounts:Accounts-list:Toggle-button:Closed"
            copytext="Closed"
          />
          <Content
            tagClassName="sr-only"
            tag="span"
            cmsTag="Accounts:Accounts-list:Toggle-button:-accounts"
            copytext=" accounts"
          />
        </Button>
      </ButtonGroup>
    );

    accountsToList.forEach((account, index) => {
      children.push(
        <LocalAccountSummary
          mode={mode}
          loopIndex={index}
          account={account}
          accounts={accounts}
          key={account.AccountKey.Key}
          status={status}
        />,
      );
    });
    messages.push(<Message key={`first-time-sign-in-message`} />);

    accountsToList.forEach((account, index) => {
      messages.push(
        <AccountBannerMessage
          key={`maturity-goal-${index}`}
          loopIndex={index}
          account={account}
        />,
      );
    });

    if (
      accounts?.Accounts &&
      nominatedAccountStatus?.DecisionStatus !== 'PENDING' &&
      (!HasNominatedAccount ||
        HasNominatedAccount === CONSTANTS.NO_NOMINATED_ACCOUNT)
    ) {
      messages.push(
        <NominatedAccountUnsetMessage key={`no-nominated-account-message`} />,
      );
    }
    if (
      nominatedAccountStatus &&
      // !nominatedAccountStatus.ClientApplicationRef &&
      nominatedAccountStatus.DocumentUploadStatus === 'PENDING' &&
      nominatedAccountStatus.DecisionStatus === 'PENDING'
    ) {
      messages.push(
        <NominatedAccountPendingMessage
          visibility={nominatedAccountStatus.DocumentUploadStatus}
          iframe={this.props.iframe ? this.props.iframe : ''}
          key={`nominated-account-docs-pending-message`}
        />,
      );
    }
    accountsToList.forEach((account, index) => {
      messages.push(
        <SavingsGoalMessage
          loopIndex={`savings-goal-${index}`}
          key={`savings-goal-${index}`}
          account={account}
          viewGoalDetails={viewGoalDetails}
        />,
      );
    });

    return (
      <div id="account-list">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Accounts"
          metaDescription="Accounts description"
        />
        <Row className="pb-3">
          <Col xs={12} md={6} lg={6}>
            <h1 className="mb-0">
              <Content cmsTag="Accounts-list:h1" copytext="Accounts" />
            </h1>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            className="text-xs-left text-sm-right align-self-center"
          >
            <div className="account-summary-filter">
              {statusToggle} {gridToggle}
            </div>
          </Col>
        </Row>
        {systemDownStatus.msg}
        {messages}

        <div className="mt-3 alert alert-info">
          <Content
            cmsTag="Accounts-list:p1"
            copytext="Please note: Your available balance may not show your most recent transactions."
          />
        </div>

        <Fade in={shown} aria-live="polite">
          <CardColumns
            id="accounts-container"
            className={!mode ? 'list-mode' : 'grid-mode'}
          >
            {children.length === 0 && !isAccountFetching ? (
              <p id="no-accounts-in-list" key={0}>
                <Markdown
                  cmsTag="Accounts:Accounts-list:There-are-no-STATE-accounts-to-view"
                  template={{
                    markdown: {
                      state: !status ? 'active' : 'closed',
                    },
                  }}
                  markdown={`There are no $[state] accounts to view.`}
                />
              </p>
            ) : (
              ''
            )}
            {children.length === 0 && isAccountFetching ? (
              <p key={0} aria-live="polite">
                <Content
                  cmsTag="GLOBAL:Loading"
                  copytext="Please wait while the page loads."
                />
              </p>
            ) : (
              ''
            )}
            {children.length ? children : ''}
          </CardColumns>
        </Fade>
      </div>
    );
  }
}

AccountsList.propTypes = {
  acc: PropTypes.object,
  accounts: PropTypes.object,
  history: PropTypes.any,
  urlParams: PropTypes.any,
  mode: PropTypes.any,
  iframe: PropTypes.any,
  userDetails: PropTypes.any,
  nominatedAccountStatus: PropTypes.any,
  setListMode: PropTypes.any,
  setStatusMode: PropTypes.any,
  updateUrl: PropTypes.any,
  status: PropTypes.any,
  viewGoalDetails: PropTypes.object,
  isAccountFetching: PropTypes.bool,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    custombrandSavingsServicingDefinition,
    accountsDefinition,
    userManagementDefinition,
    customUserManagementDefinition,
  } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...accountsDefinition,
    isAccountFetching: accountsDefinition.isFetching,
    ...userManagementDefinition,
    ...customUserManagementDefinition,
  };
};

export default loadStateHOC(
  checkDownTime(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(AccountsList),
    { moduleName: 'AccountsList' },
  ),
  storage.name,
);
