import React from 'react';
import { Col, Row } from 'reactstrap';
import { Content } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { Session } from '@myie/interact';

const NominatedAccount = ({ account }) => {
  const {
    ExtendedProperties: { NominatedAccountNumber, NominatedSortCode } = {},
  } = account;
  const customer = Session.customer();
  return (
    <div className="nominated-account-details" id="nominated-account-details">
      <Content
        tag="p"
        tagID="nominated-account-label"
        cmsTag="Savings-servicing:shared:Nominated-accounts:p1"
        copytext="Your funds will be sent to:"
      />
      <Row tag="dl" aria-labelledby="nominated-account-label">
        <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
          <Content
            cmsTag="GLOBAL:Account-holder-name"
            copytext="Account holder name"
          />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
          {customer && customer.Username ? customer.Name : ''}
        </Col>
        <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
          <Content cmsTag="GLOBAL:Account-number" copytext="Account number" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
          {NominatedAccountNumber}
        </Col>
        <Col tag="dt" xs={12} sm={6} md={4} lg={4}>
          <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
        </Col>
        <Col tag="dd" xs={12} sm={6} md={8} lg={8}>
          {NominatedSortCode}
        </Col>
      </Row>
    </div>
  );
};

NominatedAccount.propTypes = {
  account: PropTypes.object,
};

export default NominatedAccount;
