import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form } from 'reactstrap';
import { mapDispatchToProps } from '@myie/interact-user-management';
import { mapDispatchToProps as brandUserManagementDispatch } from '@myie/interact-brand-user-management';
import EditAboutYou from './AboutYou';
import EditMarketingPreferences from './MarketingPreferences';
import { Validate } from '@myie/interact';
import { Content, AppMeta, FormErrorList, RULES } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Redirect } from 'react-router-dom';
import checkDownTime from '../../../shared/HO_components/checkDownTime';

class AdditionalDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      resetUserDetails,
      resetPreferences,
      resetUserManagement,
    } = this.props;
    this.state = {
      mode: 'view',
      localUserDetails: null,
      aboutYouData: {},
      updateStatus: null,
      marketingPreferences: {
        preferences: '',
      },
      marketingPreferencesForm: {
        preferences: {
          rules: {
            title: 'preferences',
            stop: true,
            required: {
              message: 'Please select a contact option.',
            },
          },
          value: '',
        },
      },
      aboutYouForm: {
        EmailAddress: {
          rules: {
            title: 'EmailAddress',
            stop: true,
            required: {
              message: 'Please enter an email address.',
            },
            email: {
              message: 'Please enter a valid email address.',
            },
          },
          value: '',
        },
        ConfirmEmailAddress: {
          rules: {
            title: 'ConfirmEmailAddress',
            stop: true,
            required: {
              message: 'Please confirm your email address.',
            },
            email: {
              message: 'Please enter a valid email address.',
            },
            compare: {
              message: 'Email addresses do not match.',
              field: 'EmailAddress',
              comparison: () => {
                return this.state.aboutYouForm.EmailAddress.value;
              },
            },
          },
          value: '',
        },
        HomeNumber: {
          rules: {
            title: 'HomeNumber',
            stop: false,
            format: {
              regex: RULES.daytimePhoneNumber,
              message: 'Please enter a valid home phone number.',
            },
          },
          value: '',
        },
      },
    };
    resetUserDetails();
    resetPreferences();
    resetUserManagement();
  }

  updateMarketingPreferences = (name, value) => {
    let { marketingPreferencesForm, marketingPreferences } = this.state;
    marketingPreferences[name] = value;
    marketingPreferencesForm = Validate.input(
      name,
      value,
      marketingPreferencesForm,
    );

    this.setState({
      ...this.state,
      marketingPreferencesForm,
      marketingPreferences,
    });
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = (nextProps, state) => {
    const {
      resetUserDetails,
      resetPreferences,
      resetUserManagement,
      userManagementSuccess,
      userDetails,
    } = nextProps;

    if (userManagementSuccess && userManagementSuccess.ResponseStatus) {
      resetPreferences();
      resetUserDetails();
      resetUserManagement();
      return { updateStatus: userManagementSuccess.ResponseStatus };
    }

    if (userDetails && !state.localUserDetails) {
      return {
        marketingPreferences: {
          post:
            userDetails.ExtendedProperties.MarketingPreference.Post === 'FALSE'
              ? false
              : true,
          email:
            userDetails.ExtendedProperties.MarketingPreference.Email === 'FALSE'
              ? false
              : true,
        },
        localUserDetails: userDetails,
      };
    }

    return null;
  };

  onChange = e => {
    const { name, value } = e.target;
    let { aboutYouData, aboutYouForm } = this.state;
    aboutYouData[name] = value;
    aboutYouForm = Validate.input(name, value, aboutYouForm);
    this.setState({ ...this.state, aboutYouForm, aboutYouData });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { aboutYouForm } = this.state;
    aboutYouForm = Validate.input(name, value, aboutYouForm, true);
    this.setState({ ...this.state, aboutYouForm });
  };

  submit = e => {
    e.preventDefault();
    let {
      aboutYouForm,
      yourAddressForm,
      correspondenceAddressForm,
    } = this.state;
    aboutYouForm = Validate.form(aboutYouForm);
    yourAddressForm = Validate.form(yourAddressForm);
    correspondenceAddressForm = Validate.form(correspondenceAddressForm);
    this.setState({
      ...this.state,
      aboutYouForm,
      yourAddressForm,
      correspondenceAddressForm,
    });
    if (
      !aboutYouForm.approved ||
      !yourAddressForm.approved ||
      !correspondenceAddressForm.approved
    ) {
      return;
    }
    this.submitData();
  };

  updatePreferences = e => {
    e.preventDefault();
    const { aboutYouData } = this.state;
    const {
      resetUserDetails,
      resetUserManagement,
      setupUserDetails,
    } = this.props;

    let { aboutYouForm, marketingPreferencesForm } = this.state;

    marketingPreferencesForm = Validate.form(marketingPreferencesForm);
    aboutYouForm = Validate.form(aboutYouForm);
    this.setState({
      ...this.state,
      aboutYouForm,
      marketingPreferencesForm,
    });
    if (!aboutYouForm.approved || !marketingPreferencesForm.approved) {
      window.scrollTo(0, 0);
      return;
    }

    let request = {
      EmailAddress: aboutYouData.EmailAddress,
      HomeNumber: aboutYouData.HomeNumber,
      ExtendedProperties: {
        PreferredContactMethod: marketingPreferencesForm.preferences.value,
      },
    };
    resetUserDetails();
    resetUserManagement();
    setupUserDetails(request);
  };

  render() {
    const {
      userDetails,
      error,
      systemDownStatus, //From checkDownTime HOC
    } = this.props;
    const { aboutYouForm, aboutYouData, marketingPreferencesForm } = this.state;
    const { marketingPreferences, updateStatus } = this.state;
    const combinedForm = { ...aboutYouForm, ...marketingPreferencesForm };
    if (error) {
      return <Redirect to="/network-error" />;
    }
    if (updateStatus && updateStatus === 'Success') {
      localStorage.removeItem('firstLogin');
      return <Redirect to="/accounts" />;
    }
    if (updateStatus && updateStatus === 'Failed') {
      return <Redirect to="/network-error" />;
    }

    if (systemDownStatus.shouldBlock) {
      return systemDownStatus.msg;
    }

    return (
      <div id="additional-details">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Additional details"
          metaDescription="Additional details"
        />
        {systemDownStatus.msg}
        <Content
          tag="h1"
          cmsTag="User-management:Personal-details:Additional-details:h1"
          copytext="Additional details"
        />
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:Additional-details:p1"
          copytext="We will need some additional details from you before you sign in."
        />
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:Additional-details:require-twofa"
          copytext="In order to change any of your contact details, you will need to enter an authentication code that we will send to the mobile number associated with your account. Please ensure that you have your mobile phone to hand."
        />
        <FormErrorList
          validations={combinedForm}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          id="add-additional-details-details"
          onSubmit={this.updatePreferences}
        >
          <EditAboutYou
            userDetails={userDetails}
            aboutYouForm={aboutYouForm}
            aboutYouData={aboutYouData}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />

          <EditMarketingPreferences
            marketingPreferences={marketingPreferences}
            marketingPreferencesForm={marketingPreferencesForm}
            updateMarketingPreferences={this.updateMarketingPreferences}
          />
          <div className="form-button-group">
            <Button type="submit" id="save-changes" color="primary">
              <Content
                cmsTag="User-management:Personal-details:Additional-details:Continue"
                copytext="Continue"
              />
              <Content
                tagClassName="sr-only"
                tag="span"
                cmsTag="User-management:Personal-details:Additional-details:-to-accounts-list"
                copytext=" to accounts list"
              />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

AdditionalDetails.propTypes = {
  contactOptions: PropTypes.object,
  fetchContactOptionsIfNeeded: PropTypes.func,
  fetchUserDetailsIfNeeded: PropTypes.func,
  resetContactOptions: PropTypes.any,
  setUserDetailSuccess: PropTypes.any,
  userManagementActivate: PropTypes.any,
  resetUserDetails: PropTypes.any,
  resetPreferences: PropTypes.any,
  resetUserManagement: PropTypes.func,
  setupUserDetails: PropTypes.func,
  userDetails: PropTypes.object,
  error: PropTypes.any,
  match: PropTypes.object,
  systemDownStatus: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    userManagementDefinition,
    customUserManagementDefinition,
    twoFactorDefinition,
  } = state;
  return {
    ...userManagementDefinition,
    ...customUserManagementDefinition,
    ...twoFactorDefinition,
  };
};

export default checkDownTime(
  connect(
    mapStateToProps,
    { ...mapDispatchToProps, ...brandUserManagementDispatch },
  )(AdditionalDetails),
);
