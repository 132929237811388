import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Markdown } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';

class ApplicationSuccess extends Component {
  componentDidMount() {
    const { resetCreateNoticeToWithdraw } = this.props;
    resetCreateNoticeToWithdraw();
  }

  render() {
    const { account = {}, savingshortapply } = this.props;
    if (!account) {
      return null;
    }

    return (
      <React.Fragment>
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Short-apply:Application-success:h1"
          template={{
            copytext: {
              companyName: CONTACTS.companyName,
            },
          }}
          copytext={`Thank you for applying for a $[companyName] savings account`}
        />
        <p>
          <Content
            cmsTag="Savings-servicing:Short-apply:Application-success:p1"
            copytext="We have everything we need to process your application and we will send you a secure message shortly."
          />
        </p>
        {savingshortapply && savingshortapply.PreferenceSuccess === 'FALSE' ? (
          <p>
            <Content
              cmsTag="Savings-servicing:Short-apply:Application-success:p2-part-1"
              copytext="Unfortunately we failed to update your marketing preferences, you can update them on our "
            />
            <Link
              id="personal-details-link"
              role="button"
              to={`/personal-details/view`}
            >
              <Content
                cmsTag="Savings-servicing:Short-apply:Application-success:personal-details"
                copytext="personal details"
              />
            </Link>
            <Content
              cmsTag="Savings-servicing:Short-apply:Application-success:p2-part-2"
              copytext=" page or "
            />
            <Link
              id="secure-message-link"
              role="button"
              to={'/messaging-twoway/new'}
            >
              <Content
                cmsTag="Savings-servicing:Short-apply:Application-success:send-us-a-secure-message"
                copytext="send us a secure message"
              />
            </Link>
            <Content
              cmsTag="Savings-servicing:Short-apply:Application-success:p2-part-3"
              copytext="."
            />
          </p>
        ) : (
          ''
        )}

        <Markdown
          cmsTag="Savings-servicing:Short-apply:Application-success:p3"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
              email: CONTACTS.email,
              openTimes: CONTACTS.openTimes,
            },
          }}
          markdown={`\nIf you have any questions, Please call us on <a href="tel:$[phoneLink]">$[phone]</a> or email us at <a href="mailto:$[email]">$[email]</a>. Our operating hours are $[openTimes].`}
        />

        <div className="form-button-group">
          <Link
            id="back-to-accounts"
            className="btn btn-secondary"
            role="button"
            to={`/accounts/list`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-accounts"
              copytext="Back to accounts"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

ApplicationSuccess.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  savingshortapply: PropTypes.object,
  match: PropTypes.any,
  account: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(ApplicationSuccess);
