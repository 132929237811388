import {
  UPDATE_BUDGET_PLANNER,
  BUDGET_PLANNER_REQUEST,
  BUDGET_PLANNER_SUCCESS,
  BUDGET_PLANNER_FAILURE,
  BUDGET_PLANNER_SAVE_SUCCESS,
  CLEAR_BUDGET_PLANNER,
  BUDGET_PLANNER_ADD_CATEGORY_ITEM,
  BUDGET_PLANNER_SAVED_VERSION,
  BUDGET_PLANNER_DELETE_CATEGORY_ITEM,
} from '../actions/budgetPlannerTypes';
import update from 'immutability-helper';

const budgetPlannerDefinition = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BUDGET_PLANNER:
      var budgetPlanner = state.budgetPlanner;
      var updatedPlanner;
      var value;
      if ((action.name === 'amount') | (action.name === 'total_owed')) {
        value = parseFloat(action.value);
        if (isNaN(value)) {
          value = null;
        }
      } else {
        value = action.value;
      }
      if (
        (action.categoryIndex === 'debts') |
        (action.categoryIndex === 'court_payments') |
        (action.categoryIndex === 'assets')
      ) {
        let c = budgetPlanner.income_and_expenditure[action.categoryIndex];
        c.items.forEach((g, ig) => {
          if (g.type === action.item) {
            updatedPlanner = update(budgetPlanner, {
              income_and_expenditure: {
                [action.categoryIndex]: {
                  items: {
                    [ig]: {
                      [action.name]: { $set: value },
                    },
                  },
                },
              },
            });
          }
          if (g.name === action.group) {
            g.items.forEach((i, ii) => {
              if (i.name === action.item) {
                updatedPlanner = update(budgetPlanner, {
                  income_and_expenditure: {
                    [action.categoryIndex]: {
                      items: {
                        [ig]: {
                          items: {
                            [ii]: {
                              [action.name]: { $set: value },
                            },
                          },
                        },
                      },
                    },
                  },
                });
              }
            });
          }
        });
      } else {
        budgetPlanner.income_and_expenditure.categories.forEach((c, ic) => {
          if (c.name === action.category) {
            c.items.forEach((g, ig) => {
              if (g.name === action.item) {
                updatedPlanner = update(budgetPlanner, {
                  income_and_expenditure: {
                    categories: {
                      [ic]: {
                        items: {
                          [ig]: {
                            [action.name]: { $set: value },
                          },
                        },
                      },
                    },
                  },
                });
              }
              if (g.name === action.group) {
                g.items.forEach((i, ii) => {
                  if (i.name === action.item) {
                    updatedPlanner = update(budgetPlanner, {
                      income_and_expenditure: {
                        categories: {
                          [ic]: {
                            items: {
                              [ig]: {
                                items: {
                                  [ii]: {
                                    [action.name]: { $set: value },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    });
                  }
                });
              }
            });
          }
        });
      }

      return {
        ...state,
        budgetPlanner: updatedPlanner,
      };
    case BUDGET_PLANNER_SUCCESS:
      return {
        ...state,
        budgetPlanner: action.budgetPlanner,
        isFetching: action.isFetching,
      };
    case BUDGET_PLANNER_SAVE_SUCCESS:
      return {
        ...state,
        budgetSavePlanner: action.budgetSavePlanner,
        isFetching: action.isFetching,
        isBPAutoSave: action.isBPAutoSave,
      };
    case BUDGET_PLANNER_REQUEST:
    case BUDGET_PLANNER_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case BUDGET_PLANNER_SAVED_VERSION:
      return {
        ...state,
        savedBudgetPlanner: action.savedBudgetPlanner,
        isFetching: action.isFetching,
      };
    case CLEAR_BUDGET_PLANNER:
      return {
        ...state,
        budgetPlanner: null,
        budgetSavePlanner: null,
        isFetching: false,
        error: false,
      };
    case BUDGET_PLANNER_ADD_CATEGORY_ITEM:
      var oldBudgetPlanner = state.budgetPlanner;
      var newBudgetPlanner;
      if (action.categoryType === 'other-costs') {
        oldBudgetPlanner.income_and_expenditure.categories.forEach((c, ci) => {
          if (c.type === action.categoryType) {
            if (c.items) {
              newBudgetPlanner = update(oldBudgetPlanner, {
                income_and_expenditure: {
                  categories: {
                    [ci]: {
                      items: {
                        $set: [
                          ...oldBudgetPlanner.income_and_expenditure.categories[
                            ci
                          ].items,
                          action.item,
                        ],
                      },
                    },
                  },
                },
              });
            } else {
              newBudgetPlanner = update(oldBudgetPlanner, {
                income_and_expenditure: {
                  categories: {
                    [ci]: {
                      items: { $set: [action.item] },
                    },
                  },
                },
              });
            }
          }
        });
      } else {
        if (
          oldBudgetPlanner.income_and_expenditure[action.categoryType].items
        ) {
          newBudgetPlanner = update(oldBudgetPlanner, {
            income_and_expenditure: {
              [action.categoryType]: {
                items: {
                  $set: [
                    ...oldBudgetPlanner.income_and_expenditure[
                      action.categoryType
                    ].items,
                    action.item,
                  ],
                },
              },
            },
          });
        } else {
          newBudgetPlanner = update(oldBudgetPlanner, {
            income_and_expenditure: {
              [action.categoryType]: {
                items: { $set: [action.item] },
              },
            },
          });
        }
      }

      return {
        ...state,
        budgetPlanner: newBudgetPlanner,
        isFetching: false,
        error: null,
      };
    case BUDGET_PLANNER_DELETE_CATEGORY_ITEM:
      var BudgetPlannerBeforeDelete = state.budgetPlanner;
      var budgetPlannerAfterDelete;
      if (action.deleteItem.categoryType === 'other-costs') {
        budgetPlannerAfterDelete = update(BudgetPlannerBeforeDelete, {
          income_and_expenditure: {
            categories: {
              [action.deleteItem.categoryIndex]: {
                items: { $splice: [[action.deleteItem.itemIndex, 1]] },
              },
            },
          },
        });
      } else {
        if (
          (action.deleteItem.categoryType === 'debts') |
          (action.deleteItem.categoryType === 'court_payments') |
          (action.deleteItem.categoryType === 'assets')
        ) {
          budgetPlannerAfterDelete = update(BudgetPlannerBeforeDelete, {
            income_and_expenditure: {
              [action.deleteItem.categoryIndex]: {
                items: { $splice: [[action.deleteItem.itemIndex, 1]] },
              },
            },
          });
        }
      }
      return {
        ...state,
        budgetPlanner: budgetPlannerAfterDelete,
        isFetching: false,
        error: null,
      };
    default:
      return state;
  }
};

export default budgetPlannerDefinition;
