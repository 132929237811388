import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Utility } from '@myie/interact';
import { Content } from '@myie/interact-dom';

class Links extends React.Component {
  getLinks = () => {
    const {
      accountType,
      loopIndex,
      accountKey,
      accountStatus,
      accountName,
      account,
    } = this.props;
    switch (accountType) {
      case 'REGULAR_SAVINGS':
        if (accountStatus === 'Active') {
          if (
            account.ExtendedProperties.MaturityCallDepositAccount === 'TRUE'
          ) {
            return;
          } else {
            return (
              <Link
                id={`mortgage-account-list-notice-to-withdraw-link-${loopIndex}`}
                className="mr-0 btn-light btn"
                to={
                  '/notice-to-withdraw/view/summary/' +
                  Utility.hexEncode(accountKey)
                }
              >
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:View-"
                  copytext="View "
                />
                <Content
                  cmsTag="GLOBAL:Notice-to-withdraw"
                  copytext="Notice to withdraw"
                />
                <Content
                  tag="span"
                  tagClassName="sr-only"
                  cmsTag="GLOBAL:-for-ACCOUNT-NAME"
                  template={{
                    copytext: {
                      accountName: accountName,
                    },
                  }}
                  copytext=" for $[accountName]"
                />
              </Link>
            );
          }
        }
        break;
      case 'EASY_ACCESS':
        if (accountStatus === 'Active') {
          return (
            <Link
              id={`mortgage-account-list-make-a-withdrawal-link-${loopIndex}`}
              className="mr-0 btn-primary btn"
              to={'/make-withdrawal/' + Utility.hexEncode(accountKey)}
            >
              <Content
                cmsTag="GLOBAL:Make-a-withdrawal"
                copytext="Make a withdrawal"
              />
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="GLOBAL:-for-ACCOUNT-NAME"
                template={{
                  copytext: {
                    accountName: accountName,
                  },
                }}
                copytext=" for $[accountName]"
              />
            </Link>
          );
        }
        break;
      default:
    }
  };

  render() {
    const { props } = this;
    const { loopIndex, pageMode } = props;

    return (
      <div className="pt-4" id={`account-type-savings-link-${loopIndex}`}>
        {pageMode !== 'detail' ? (
          <Link
            id={`mortgage-account-list-show-details-link-${loopIndex}`}
            className="btn-light btn"
            to={'/accounts/details/' + Utility.hexEncode(props.accountKey)}
          >
            <Content
              tagClassName="sr-only"
              tag="span"
              cmsTag="GLOBAL:View-"
              copytext="View "
            />
            <Content
              cmsTag="GLOBAL:Account-details"
              copytext="Account details"
            />
            <Content
              tag="span"
              tagClassName="sr-only"
              cmsTag="GLOBAL:-for-PROPS-ACCOUNT-NAME"
              template={{
                copytext: {
                  accountName: props.accountName,
                },
              }}
              copytext=" for $[accountName]"
            />
          </Link>
        ) : (
          ''
        )}

        {this.getLinks()}
      </div>
    );
  }
}

Links.propTypes = {
  mode: PropTypes.any,
  pageMode: PropTypes.any,
  status: PropTypes.number,
  accountKey: PropTypes.any,
  accountName: PropTypes.any,
  loopIndex: PropTypes.any,
  accountType: PropTypes.string,
  accountStatus: PropTypes.string,
  account: PropTypes.any,
};

export default Links;
