import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Content } from '@myie/interact-dom';
import { Col, Row, Button } from 'reactstrap';

class AddNominatedAccountSuccess extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  render() {
    const { match, data } = this.props;
    return (
      <React.Fragment>
        <div className="confirm-account-details-add-nominated-account">
          <Content
            tag="p"
            cmsTag="Accounts:Nominated-account:Add-nominated-account:Success:p1"
            copytext="You have successfully added your nominated account details. You can now make withdrawals into this account."
          />
          <Row tag="dl" title="Add nominated account details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Name-of-account-holder"
                copytext="Name of account holder"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['accountHolderName'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Account-number"
                copytext="Account number"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['accountNumber'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Sort-code" copytext="Sort code" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['sortcode'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content
                cmsTag="GLOBAL:Payee-reference"
                copytext="Payee reference"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data ? data['payeeReference'] : ''}
            </Col>
          </Row>
        </div>
        <div className="form-button-group">
          {this.getBackButton(match.params.page)}
        </div>
      </React.Fragment>
    );
  }
}

AddNominatedAccountSuccess.propTypes = {
  page: PropTypes.any,
  data: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default connect(
  null,
  mapDispatchToProps,
)(AddNominatedAccountSuccess);
