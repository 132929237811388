import React, { Component } from 'react';
import {
  Content,
  FormattedCurrency,
  AppMeta,
  Switch,
} from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-brand-savings-servicing';
import { CONTACTS } from '@myie/interact-local-dom';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: '',
      disabled: false,
    };
  }
  componentDidMount() {
    const {
      nominatedTransferResponse,
      updateUrl,
      getAccClosingBalance,
      account,
      data,
    } = this.props;

    let stateData = {
      formData: null,
      transferData: nominatedTransferResponse,
      url: {
        pageStage: 'Confirmation',
      },
    };
    const closeOption =
      data && data['closeAccount'] ? data['closeAccount'] : false;
    if (closeOption) {
      getAccClosingBalance({
        AccountKey: account.AccountKey,
        SubAccountId: account.ExtendedProperties.SubAccountNumber,
      });
    }
    window.scrollTo(0, 0);
    updateUrl(stateData);
    window.scrollTo(0, 0);
  }
  submit = () => {
    const { onSubmitWithdrawal } = this.props;
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    onSubmitWithdrawal();
  };

  editTransfer = () => {
    const { updateField, setStateDynamic, data, combinedForm } = this.props;
    let stateData = {
      url: {
        pageStage: 'createTransfer',
      },
    };
    this.props.updateUrl(stateData);
    const form = updateField('accept', false);
    data['accept'] = false;
    setStateDynamic({
      stage: 'createTransfer',
      data: data,
      accountForm: form,
      combinedForm: {
        ...combinedForm,
        ...form,
      },
    });
  };

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Failed'
    ) {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return null;
  };

  render() {
    const {
      account = {},
      data,
      closingBalResponse = null,
      accounts,
      linkedNominatedAccount,
      isFetching,
    } = this.props;
    let amount = data && data['amount'] ? data['amount'] : '';
    const closeOption =
      data && data['closeAccount'] ? data['closeAccount'] : false;
    let purposeOption =
      data && data['purposeOptions'] ? data['purposeOptions'] : '';

    const {
      CurrencyCode,
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;

    let { NominatedAccount } = linkedNominatedAccount || {};
    if (
      account &&
      !NominatedAccount &&
      !isFetching &&
      Object.keys(account).length
    ) {
      NominatedAccount =
        accounts?.ExtendedProperties?.NominatedAccountDetails?.NominatedAccount;
    }
    const balance = AvailableBalance + PendingBalance;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Withdraw to your nominated account step 2"
          metaDescription="Withdraw to your nominated account step 2"
        />
        <Switch
          id="create-transfer-review-alert"
          value={this.state.message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          scrolltotop={true}
          contents={{
            failed: {
              defaultValue: 'Withdraw to your nominated account failed',
            },
          }}
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Confirmation:p1"
          copytext="Before we carry out your request, please check the details you have entered are correct."
        />
        <div className="alert alert-info">
          <Content
            tag="p"
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Confirmation:p2"
            copytext="Please note:"
          />
          <ul className="transfer-details-note mb-0">
            <li>
              <Content
                cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Confirmation:li-1"
                copytext="If you are withdrawing money to a nominated account, the withdrawn amount will not earn any interest with us."
              />
            </li>
            <li>
              <Content
                cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Confirmation:li-2"
                copytext="Any withdrawals made on a weekend or a bank holiday will not reach your nominated account until the next working day. Please do not attempt the withdrawal again unless advised to do so."
              />
            </li>
            <li>
              <Content
                cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:Confirmation:li-3"
                copytext="If you have decided to withdraw your full balance and close the account, you will withdraw your closing balance which will include any accrued interest."
              />
            </li>
          </ul>
        </div>
        <div className="confirm-transfer-to-another-account-details pt-5">
          <Row tag="dl" title="Transfer details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:From" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}
              <span className="">{account.AccountNumber}</span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:To" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {NominatedAccount.Name}
              <span className="d-block">{NominatedAccount.AccountNumber}</span>
              <span className="d-block">{NominatedAccount.SortCode}</span>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {!closeOption ? (
                <FormattedCurrency
                  quantity={amount.replace(/,/g, '')}
                  currency={CurrencyCode}
                />
              ) : (
                <React.Fragment>
                  <FormattedCurrency
                    quantity={balance}
                    currency={CurrencyCode}
                  />
                  <Content
                    cmsTag="GLOBAL:-Full-balance"
                    copytext=" (Full balance)"
                  />
                </React.Fragment>
              )}
            </Col>

            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Purpose" copytext="Purpose" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {purposeOption}
            </Col>

            {closeOption && closingBalResponse ? (
              <React.Fragment>
                <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                  <Content
                    cmsTag="GLOBAL:Closing-balance"
                    copytext="Closing balance"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                  <FormattedCurrency
                    quantity={closingBalResponse.ClosureAmount}
                    currency={CurrencyCode}
                  />
                  {true && (
                    <Content
                      cmsTag="GLOBAL:-including-any-incurred-interest"
                      copytext=" (including any accrued interest)"
                    />
                  )}
                </Col>
              </React.Fragment>
            ) : (
              ''
            )}
          </Row>
        </div>
        <div className="form-button-group">
          <Button
            id="confirm-withdrawal"
            type="submit"
            onClick={this.submit}
            disabled={this.state.disabled}
            color="primary"
          >
            <Content
              cmsTag="GLOBAL:Confirm-withdrawal"
              copytext="Confirm withdrawal"
            />
          </Button>
          <Button
            id="edit-transfer-details"
            onClick={this.editTransfer}
            color="light"
            disabled={this.state.disabled}
          >
            <Content
              cmsTag="GLOBAL:Edit-withdrawal-details"
              copytext="Edit withdrawal details"
            />
          </Button>
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  match: PropTypes.any,
  updateUrl: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  setStateDynamic: PropTypes.any,
  createNoticeParam: PropTypes.any,
  combinedForm: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  createResponse: PropTypes.any,
  onSubmitWithdrawal: PropTypes.any,
  createNoticeToWithdraw: PropTypes.func,
  account: PropTypes.any,
  setStage: PropTypes.any,
  updateField: PropTypes.any,
  form: PropTypes.any,
  nominatedTransferResponse: PropTypes.any,
  actionDate: PropTypes.any,
  getAccClosingBalance: PropTypes.func,
  closingBalResponse: PropTypes.object,
  accounts: PropTypes.object,
  linkedNominatedAccount: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Confirmation));
