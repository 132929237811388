import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route } from 'react-router-dom';
import MoneyToolsIndex from './MoneyToolsIndex';
import SavingsGoalsRoutes from './SavingsGoals/Routes';
import BudgetCalculatorIndex from './BudgetCalculator/index';

class MoneyToolsRoutes extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute path="/money-tools/index" component={MoneyToolsIndex} />
        <PrivateRoute
          key="/money-tools/budget-calculator"
          path="/money-tools/budget-calculator/:id?"
          component={BudgetCalculatorIndex}
        />
        <Route
          key="/money-tools/savings-goals"
          path="/money-tools/savings-goals"
          component={SavingsGoalsRoutes}
        />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default MoneyToolsRoutes;
