import React, { Component } from 'react';
import {
  Content,
  Markdown,
  Text,
  Mask,
  Check,
  FormErrorList,
} from '@myie/interact-dom';
import { Button, Form, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';

class EditNominatedAccountDetails extends Component {
  onSubmitHandler = e => {
    const { submit } = this.props;
    e.preventDefault();
    submit();
  };

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };
  render() {
    const { form, stage, match } = this.props;
    return (
      <React.Fragment>
        <div className="breadcrumb-container">
          <ol className="breadcrumb">
            <BreadcrumbItem active={stage === 'AccountDetails'}>
              <Content
                tag="span"
                tagClassName="sr-only"
                cmsTag="BREADCRUMB:Active"
                copytext="Active: "
              />

              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-1-" copytext="Step 1 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Account-details"
                copytext="Account details"
              />
            </BreadcrumbItem>
            <BreadcrumbItem active={stage === 'Confirm'}>
              <span className="d-inline d-lg-none d-xl-none">
                <span className="sr-only">
                  <Content cmsTag="BREADCRUMB:Step-2-" copytext="Step 2 " />
                </span>
                <Content cmsTag="BREADCRUMB:-of-" copytext=" of " />
                2:&nbsp;
              </span>
              <Content
                cmsTag="BREADCRUMB:Review-and-submit"
                copytext="Review and submit"
              />
            </BreadcrumbItem>
          </ol>
        </div>
        <div className="clear-both"></div>
        <div className="alert alert-info">
          <Markdown
            cmsTag="Accounts:Nominated-account:Edit-nominated-account:Account-details:alert-1"
            markdown={`\nTo change your nominated bank account you will need to enter an authentication code that we will send to your mobile number.\n\nWe will check the account details to make sure the nominated bank account is held in your name. We may ask you to provide documentation to prove the account is yours if our checks are not sufficient.\n\nWhen you change the nominated account details, you will not be able to make a withdrawal until a final decision has been made.`}
          />
        </div>
        <Content
          tag="h2"
          cmsTag="Accounts:Nominated-account:Edit-nominated-account:Account-details:h2-1"
          copytext="Account details"
        />
        <FormErrorList
          validations={this.props.form}
          disabled={false}
          required={true}
          groupClassName=""
          title="h2"
          showErrors={true}
        />
        <Form
          autoComplete="off"
          id="transfer-to-another-form"
          onSubmit={this.onSubmitHandler}
        >
          <Text
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            validation={form.accountHolderName}
            label="Name of account holder"
            cmsTag="GLOBAL:Name-of-account-holder"
            field="accountHolderName"
            id="accountHolderName"
            maxLength={'18'}
          />
          <Text
            label="Account number"
            cmsTag="GLOBAL:Account-number"
            id="accountNumber"
            field="accountNumber"
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            validation={form.accountNumber}
            maxLength={'8'}
          />
          <Mask
            label="Sort code"
            id="sortcode"
            cmsTag="GLOBAL:Sort-code"
            field="sortcode"
            autoFocus={false}
            groupClassName="half-field"
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            validation={form.sortcode}
            mask="99-99-99"
          />
          <Text
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            validation={form.payeeReference}
            label="Payee reference"
            cmsTag="GLOBAL:Payee-reference"
            field="payeeReference"
            id="payeeReference"
          />
          <Check
            id={`termsAndConditions`}
            autoFocus={false}
            label="I confirm that this account will be used for faster payments."
            field="termsAndConditions"
            checked={form && !!form.termsAndConditions.value}
            value="true"
            validation={form.termsAndConditions}
            onChange={this.props.onClick}
          />

          <div className="form-button-group">
            <Button
              id="confirm-edit-nominated-account"
              type="submit"
              color="primary"
            >
              <Content
                cmsTag="GLOBAL:Edit-nominated-account"
                copytext="Edit nominated account"
              />
            </Button>
            {this.getBackButton(match.params.page)}
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

EditNominatedAccountDetails.propTypes = {
  match: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  stage: PropTypes.string,
  form: PropTypes.object,
  submit: PropTypes.func,
  history: PropTypes.any,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(EditNominatedAccountDetails));
