import React, { Component } from 'react';
import { mapDispatchToProps as servicingMapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Goal from './Goal';
import { Utility } from '@myie/interact';
import { Content, AppMeta, CONSTANTS } from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class ViewGoals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      called: false,
      isEligibleToAddNewGoal: false,
    };
  }
  componentDidMount() {
    const {
      fetchAccountsIfNeeded,
      resetCreateGoals,
      resetViewGoals,
    } = this.props;
    fetchAccountsIfNeeded();
    resetCreateGoals();
    resetViewGoals();

    this.checkGoalEligibility(this.props.accounts.Accounts);
  }

  componentWillUnmount() {
    const { resetViewGoals } = this.props;
    resetViewGoals();
  }

  static getDerivedStateFromProps(props, state) {
    const { accounts, match, viewGoalDetails, viewGoals, isFetching } = props;
    let account;
    let keys = [];
    if (
      accounts &&
      accounts.Accounts &&
      !viewGoalDetails &&
      !isFetching &&
      !state.called
    ) {
      if (match && match.params.id) {
        account = accounts.Accounts.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.AccountKey.Key) === match.params.id
            );
          }
          return false;
        });
        if (account) {
          keys.push(account.AccountKey);
        }
        viewGoals({
          AccountKeys: keys,
        });
        return {
          called: true,
        };
      } else {
        accounts.Accounts.forEach(account => {
          keys.push(account.AccountKey);
        });
        viewGoals({
          AccountKeys: keys,
        });
        return {
          called: true,
        };
      }
    }
    return null;
  }

  checkGoalEligibility = (accounts = []) => {
    let { isEligibleToAddNewGoal } = this.state;
    let acct;
    for (let index = 0; index < accounts.length; index++) {
      acct = accounts[index];

      if (
        acct.AccountStatus &&
        (acct.AccountStatus.toLowerCase() !== 'closed' &&
          acct.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED)
      ) {
        if (acct.ExtendedProperties.SavingsGoal === 'Y') {
          // c3
          isEligibleToAddNewGoal = true;
          break;
        }
        // else if (acct.ExtendedProperties.HasSavingsGoals) {
        //   isEligibleToAddNewGoal = false;
        //   break;
        // } else {
        //   // c1
        //   isEligibleToAddNewGoal = false;
        // }
      }
    }

    this.setState({ ...this.state, isEligibleToAddNewGoal });
  };

  createGoals = () => {
    const { viewGoalDetails, accounts } = this.props;
    const { isEligibleToAddNewGoal } = this.state;
    let goals = [];
    if (accounts && viewGoalDetails && viewGoalDetails.Goals) {
      viewGoalDetails.Goals.forEach((goal, index) => {
        goals.push(
          <Goal
            AccountKey={goal.AccountKey.Key}
            key={index}
            goal={goal}
            accounts={accounts}
            isEligibleToAddNewGoal={isEligibleToAddNewGoal}
          />,
        );
      });
    }
    return goals;
  };

  render() {
    const { account = {}, viewGoalDetails } = this.props;
    const { isEligibleToAddNewGoal } = this.state;
    if (!account) {
      return null;
    }

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Savings goals"
          metaDescription="Savings goals"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:h1"
          copytext="Savings goals"
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:p1"
          copytext="Whether you are saving for that dream car or for a deposit on a house, savings goals will help you stay on track. Please note that a goal progress will always include your current balance."
        />

        {isEligibleToAddNewGoal && (
          <Link
            id="add-savings-goal-link"
            role="button"
            className="btn btn-secondary mb-5"
            to={'/money-tools/savings-goals/create-goals/goal-list/'}
          >
            <Content
              cmsTag="GLOBAL:Add-a-savings-goal"
              copytext="Add a savings goal"
            />
          </Link>
        )}

        {this.createGoals()}
        {viewGoalDetails && viewGoalDetails.Goals.length === 0 ? (
          <div className="mt-3 alert alert-info">
            <Content
              cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:alert-1"
              copytext="You have no current savings goals."
            />
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

ViewGoals.propTypes = {
  savingshortapply: PropTypes.object,
  match: PropTypes.any,
  account: PropTypes.any,
  viewGoals: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  accounts: PropTypes.object,
  resetViewGoals: PropTypes.func,
  viewGoalDetails: PropTypes.object,
  resetCreateGoals: PropTypes.func,
  isFetching: PropTypes.any,
};
const mapStateToProps = state => {
  const { custombrandSavingsServicingDefinition, accountsDefinition } = state;
  return {
    ...custombrandSavingsServicingDefinition,
    ...accountsDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...servicingMapDispatchToProps, ...mapDispatchToProps },
)(ViewGoals);
