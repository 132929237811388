import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import MoveMoneyIndex from './MoveMoneyIndex';
import { mapStateToProps, mapDispatchToProps } from '@myie/interact-accounts';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

class MoveMoney extends React.Component {
  render() {
    const { error } = this.props;
    let re = new RegExp('^/move-money[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/move-money/index" />;
    }
    return (
      <React.Fragment>
        {error ? <Alert color="danger">{error}</Alert> : ''}
        <Switch>
          <PrivateRoute
            exact
            path="/move-money/index"
            component={MoveMoneyIndex}
          />
          <Route component={NoMatch} />
        </Switch>
      </React.Fragment>
    );
  }
}

MoveMoney.propTypes = {
  error: PropTypes.any,
  location: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoveMoney);
