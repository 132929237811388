import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Utility } from '@myie/interact';
import _ from 'lodash';
import {
  FormattedDate,
  Pagination,
  Content,
  CONSTANTS,
  AppMeta,
} from '@myie/interact-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import moment from 'moment';
import loadStateHOC from '../../shared/stateManager/loadStateHOC';
import { storage } from './../storageName';
import { CONTACTS } from '@myie/interact-local-dom';
import messagingUtility from './../utility';

class View extends React.Component {
  constructor(props) {
    super(props);
    const { refreshUrl } = props;
    let count =
      this.props.stateData &&
      this.props.stateData.url &&
      this.props.stateData.url.pageStage &&
      this.props.urlParams &&
      this.props.urlParams.pageStage
        ? this.props.urlParams.pageStage
        : '0';

    if (!parseInt(count)) {
      refreshUrl();
    }

    this.state = {
      Offset: parseInt(count),
      Size: 8,
      loading: true,
      messages: {},
    };
    this.getMessages(this.state.Offset, this.state.Size);
  }

  static getDerivedStateFromProps(props, state) {
    let { loading, messages } = state;
    const { allMessages } = props;
    const equal = _.isEqual(messages, allMessages);
    if (loading && allMessages && !equal) {
      return {
        messages: allMessages,
        loading: false,
      };
    }

    return null;
  }

  getMessages = (Offset, Size) => {
    const request = {
      Offset: Offset,
      Size: Size,
      // Page: 0,
      ExtendedProperties: {},
    };
    let stateData = {
      url: {
        pageStage: Offset,
      },
    };
    this.props.updateUrl(stateData);
    this.props.fetchAllMessagesIfNeeded(request);
  };

  render() {
    const { allMessages } = this.props;
    const { Offset, Size, loading } = this.state;

    if (allMessages) {
      const messageList = allMessages.Conversations.sort(
        (a, b) => moment(b.LastModifiedDate) - moment(a.LastModifiedDate),
      );

      return (
        <div id={'messages'}>
          <AppMeta
            id="meta-data"
            stage="child"
            contacts={CONTACTS}
            title="Messages"
            metaDescription="Messages"
          />
          <Row>
            <Col>
              <Content
                tag="h1"
                tagClassName="float-sm-left"
                cmsTag="Messaging-two-way:View:Index:h1"
                copytext="Messages"
              />
              <Link
                id="send-new-message-btn"
                className="btn btn-secondary float-sm-right mr-0"
                role="button"
                to="/messaging-twoway/new"
              >
                <Content
                  cmsTag="GLOBAL:Send-a-new-message"
                  copytext="Send a new message"
                />
              </Link>
            </Col>
          </Row>
          <ul
            className="conversation-list list-unstyled"
            title="List of your messages"
          >
            {messageList.map(Conversation => (
              <li
                id={'message-thread-' + Conversation.MessageCount}
                className={Conversation.HasUnreadMessages ? 'alert-link ' : ''}
                key={Conversation.ConversationKey.Key + '_link'}
              >
                <Link
                  to={
                    '/messaging-twoway/message/view/' +
                    Utility.hexEncode(Conversation.ConversationKey.Key)
                  }
                >
                  {Conversation.HasUnreadMessages ? (
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="Messaging-two-way:View:Index:Unopened-message-"
                      copytext="(Unopened message) "
                    />
                  ) : (
                    ''
                  )}
                  <Content
                    tagClassName="sr-only"
                    tag="span"
                    cmsTag="Messaging-two-way:View:Index:Title-"
                    copytext="Title "
                  />
                  <span
                    key={Conversation.ConversationKey.Key + '_reason'}
                    id={
                      'message-thread-' + Conversation.MessageCount + '-reason'
                    }
                  >
                    {Conversation.Reason.search(
                      CONSTANTS.AUTO_GENERATED_MESSAGE,
                    ) !== -1 ? (
                      <Content
                        cmsTag="Messaging-two-way:View:Index:Message-about-your-account"
                        copytext="Message About your Account"
                      />
                    ) : (
                      messagingUtility.stripEndQuotes(Conversation.Reason)
                    )}
                  </span>
                  <span className="float-right position-relative">
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="Messaging-two-way:View:Index:Date-sent-"
                      copytext="Date sent: "
                    />
                    <FormattedDate
                      format={
                        moment(Conversation.LastModifiedDate).format(
                          'yyyy-MM-DD',
                        ) !== moment().format('yyyy-MM-DD')
                          ? 'Do MMMM h:mma'
                          : 'h:mma'
                      }
                      date={Conversation.LastModifiedDate}
                    />
                    {Conversation.Reason.search('hasattachments') !== -1 ? (
                      <span className="attachement-icon">
                        <Content
                          tagClassName="sr-only"
                          tag="span"
                          cmsTag="Messaging-two-way:View:Index:Contains-attachment"
                          copytext="This message contains an attachment."
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
          <Pagination
            Total={allMessages.TotalConversationsCount}
            Offset={Offset}
            Size={Size}
            loading={loading}
            getPreviousPage={this.getPreviousPage}
            getNextPage={this.getNextPage}
          />
        </div>
      );
    } else {
      return (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      );
    }
  }

  getPreviousPage = (Offset, Size) => {
    const previousOffset = Offset - Size;
    if (previousOffset >= 0) {
      this.setState({ ...this.state, Offset: previousOffset, loading: true });
      this.getMessages(previousOffset, Size);
    }
  };

  getNextPage = (Offset, Size, TotalConversationsCount) => {
    const nextOffset = Offset + Size;
    if (nextOffset <= TotalConversationsCount) {
      this.setState({ ...this.state, Offset: nextOffset, loading: true });
      this.getMessages(nextOffset, Size);
    }
  };
}

View.propTypes = {
  allMessages: PropTypes.object,
  refreshUrl: PropTypes.any,
  urlParams: PropTypes.any,
  updateUrl: PropTypes.any,
  stateData: PropTypes.any,
  fetchAllMessagesIfNeeded: PropTypes.func,
};

export default loadStateHOC(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(View),
  storage.name,
);
