import {
  MESSAGE_ATTACHMENT_REQUEST,
  MESSAGE_ATTACHMENT_SUCCESS,
  MESSAGE_ATTACHMENT_FAILURE,
  RESET_MESSAGE_ATTACHMENT,
} from '../actions/messageAttachmentsTypes';

const messageAttachmentDefinition = (state = {}, action) => {
  switch (action.type) {
    case MESSAGE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        messageAttachments: action.messageAttachments,
        isFetching: action.isFetching,
      };
    case RESET_MESSAGE_ATTACHMENT:
      return {
        ...state,
        messageAttachments: null,
      };
    case MESSAGE_ATTACHMENT_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case MESSAGE_ATTACHMENT_REQUEST:
    default:
      return state;
  }
};

export default messageAttachmentDefinition;
