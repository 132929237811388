export const RESET_VIEW_GOALS = 'RESET_VIEW_GOALS';
export const VIEW_GOALS_REQUEST = 'VIEW_GOALS_REQUEST';
export const VIEW_GOALS_SUCCESS = 'VIEW_GOALS_SUCCESS';
export const VIEW_GOALS_FALIURE = 'VIEW_GOALS_FALIURE';

export const RESET_CREATE_GOALS = 'RESET_CREATE_GOALS';
export const CREATE_GOALS_REQUEST = 'CREATE_GOALS_REQUEST';
export const CREATE_GOALS_SUCCESS = 'CREATE_GOALS_SUCCESS';
export const CREATE_GOALS_FALIURE = 'CREATE_GOALS_FALIURE';

export const RESET_UPDATE_GOALS = 'RESET_UPDATE_GOALS';
export const UPDATE_GOALS_REQUEST = 'UPDATE_GOALS_REQUEST';
export const UPDATE_GOALS_SUCCESS = 'UPDATE_GOALS_SUCCESS';
export const UPDATE_GOALS_FALIURE = 'UPDATE_GOALS_FALIURE';

export const RESET_DELETE_GOALS = 'RESET_DELETE_GOALS';
export const DELETE_GOALS_REQUEST = 'DELETE_GOALS_REQUEST';
export const DELETE_GOALS_SUCCESS = 'DELETE_GOALS_SUCCESS';
export const DELETE_GOALS_FALIURE = 'DELETE_GOALS_FALIURE';

export const PRESERVE_GOALS_STATE = 'PRESERVE_GOALS_STATE';
export const RESET_PRESERVE_GOALS_STATE = 'RESET_PRESERVE_GOALS_STATE';

export const SAVINGS_GOALS_BANNER_MESSAGW_ARRAY =
  'SAVINGS_GOALS_BANNER_MESSAGW_ARRAY';

export const SET_BACK_PAGE_MODE = 'SET_BACK_PAGE_MODE';
