import { combineReducers } from 'redux';
import interactReducers from './interactbrandReducers';
import { custombrandAuthenticationDefinition } from '@myie/interact-brand-authentication';
import { customUserManagementDefinition } from '@myie/interact-brand-user-management';
import { custombrandSavingsServicingDefinition } from '@myie/interact-brand-savings-servicing';

const includeCustombrandReducers = {
  ...interactReducers,
  custombrandAuthenticationDefinition,
  custombrandSavingsServicingDefinition,
  customUserManagementDefinition,
};

const appReducer = combineReducers({ ...includeCustombrandReducers });

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = { cmsDefinition: state.cmsDefinition };
  }
  return appReducer(state, action);
};
export default rootReducer;
