import React, { Component } from 'react';
import { Content, AppMeta, CONSTANTS } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Utility } from '@myie/interact';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Goal from './viewGoals/Goal';
import { CONTACTS } from '@myie/interact-local-dom';

import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';

class ReplaceGoal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      called: false,
    };
  }

  componentDidMount() {
    const { fetchAccountsIfNeeded, resetCreateGoals } = this.props;
    fetchAccountsIfNeeded();

    resetCreateGoals();
  }

  static getDerivedStateFromProps(props, state) {
    const { accounts, match, viewGoalDetails, viewGoals, isFetching } = props;
    let account;
    let keys = [];
    if (
      accounts &&
      accounts.Accounts &&
      !viewGoalDetails &&
      !isFetching &&
      !state.called
    ) {
      if (match && match.params.id) {
        account = accounts.Accounts.find(function(element) {
          if (element) {
            return (
              Utility.hexEncode(element.AccountKey.Key) === match.params.id
            );
          }
          return false;
        });
        if (account) {
          keys.push(account.AccountKey);
        }
        viewGoals({
          AccountKeys: keys,
        });
        return {
          called: true,
        };
      }
    }
    return null;
  }

  changeUrl = page => {
    const { match, history } = this.props;

    switch (page) {
      case 'goal-list':
        history.push('/money-tools/savings-goals/view');
        break;
      case 'detail':
      case 'account-details':
        {
          let url = `/accounts/details/${match.params.id}`;
          history.push(url);
        }
        break;
      case 'summary':
        history.push('/accounts/list');
        break;
      default:
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'goal-list':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-savings-goal-list"
            copytext="Back to savings goal list"
          />
        );
      case 'myDetails':
      default:
        return null;
    }
  };

  getBackButton = (page = CONSTANTS.SOURCE_PAGE_GOAL_LIST) => {
    if (page === 'undefined') {
      if (this.props.backPageMode !== 'undefined') {
        page = this.props.backPageMode;
      } else {
        page = CONSTANTS.SOURCE_PAGE_GOAL_LIST;
      }
    }
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl(page);
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    let {
      viewGoalDetails,
      accounts,
      match,
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST,
    } = this.props;
    let goal;
    if (viewGoalDetails && viewGoalDetails.Goals) {
      goal = viewGoalDetails.Goals[0];
    }
    if (!goal) {
      return null;
    }
    if (backPageMode === 'undefined') {
      backPageMode = CONSTANTS.SOURCE_PAGE_GOAL_LIST;
    }
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Goal already exists"
          metaDescription="Goal already exists"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Replace-goal:h1"
          copytext="Add a savings goal"
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Replace-goal:p1"
          copytext="You already have an existing goal set up on this account. Do you want to replace your existing goal?"
        />
        <Goal
          AccountKey={goal.AccountKey.Key}
          goal={goal}
          hideButtons={true}
          accounts={accounts}
        />
        <div className="form-button-group">
          <Link
            id="view-goals-replace-link"
            className="btn btn-primary"
            role="button"
            to={
              '/money-tools/savings-goals/create-goals/' +
              backPageMode +
              '/' +
              Utility.hexEncode(goal.AccountKey.Key) +
              '/replace'
            }
          >
            <Content
              cmsTag="Savings-servicing:Money-tools:Savings-goals:Replace-goal:Replace-goal"
              copytext="Replace goal"
            />
          </Link>
          <Link
            id="view-goals-another-account-link"
            className="btn btn btn-light"
            role="button"
            to={'/money-tools/savings-goals/create-goals/' + backPageMode + '/'}
          >
            <Content
              cmsTag="GLOBAL:Choose-another-account"
              copytext="Choose another account"
            />
          </Link>
          {this.getBackButton(match.params.page)}
        </div>
      </React.Fragment>
    );
  }
}

ReplaceGoal.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  createNoticeParam: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  viewGoalDetails: PropTypes.any,
  accounts: PropTypes.any,
  viewGoals: PropTypes.func,
  account: PropTypes.any,
  resetCreateGoals: PropTypes.any,
  form: PropTypes.any,
  actionDate: PropTypes.any,
  options: PropTypes.array,
  fetchAccountsIfNeeded: PropTypes.any,
  setStateDynamic: PropTypes.func,
  backPageMode: PropTypes.string,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...mapSavingsServicingDispatchToProps },
)(withRouter(ReplaceGoal));
