import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-messaging-twoway';
import { Content, Switch } from '@myie/interact-dom';

class DeleteConfirmation extends React.Component {
  render() {
    const { deleteMessageStatus, viewMessage } = this.props;
    let Conversation;

    if (!deleteMessageStatus) {
      return null;
    }
    if (viewMessage) {
      Conversation = viewMessage.Conversation;
    }

    let alertId;
    if (deleteMessageStatus) {
      switch (deleteMessageStatus.ResponseStatus) {
        case 'Success':
          alertId = 'messaging-twoway-delete-successful';
          break;
        case 'Failed':
          alertId = 'messaging-twoway-delete-request-error';
          break;
        default:
          alertId = 'messaging-twoway-delete-unknown-error';
          break;
      }
    }

    return (
      <React.Fragment>
        <Content
          tag="h1"
          cmsTag="Messaging-two-way:Conversation:Delete:Confirmation:h1"
          copytext="Delete message"
        />
        {deleteMessageStatus && (
          <Alert
            id={alertId}
            color={
              deleteMessageStatus &&
              deleteMessageStatus.ResponseStatus === 'Success'
                ? 'success'
                : 'danger'
            }
          >
            <Switch
              id="confirmAlert"
              tag="<>"
              value={deleteMessageStatus.ResponseStatus}
              scrolltotop={true}
              defaultText="An unknown error occured please check your accounts before retrying."
              contents={{
                Success: {
                  defaultValue: 'Your message has been deleted.',
                },
                Failed: {
                  defaultValue:
                    'An error occured while trying to process your request.',
                },
              }}
            />
          </Alert>
        )}
        {Conversation && (
          <Row
            tag="dl"
            title="Payee details"
            className="message-details-header mb-5"
          >
            <Col tag="dt" xs={12} sm={6} md={3}>
              <Content cmsTag="GLOBAL:Subject" copytext="Subject" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={9}>
              {Conversation.Reason}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={3}>
              <Content
                cmsTag="GLOBAL:Related-account"
                copytext="Related account"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={9}>
              {`${Conversation.AccountNumber}${Conversation.SortCode}`}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={3}>
              <Content
                cmsTag="GLOBAL:Reference-number"
                copytext="Reference number"
              />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={9}>
              {Conversation.Reference}
            </Col>
          </Row>
        )}
        <div className="form-button-group">
          <Link
            id="messaging-twoway-delete-back-to-link"
            className="btn btn-secondary"
            role="button"
            to="/messaging-twoway/view"
          >
            <Content
              cmsTag="GLOBAL:Back-to-message-list"
              copytext="Back to message list"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

DeleteConfirmation.propTypes = {
  deleteMessageStatus: PropTypes.any,
  transactionData: PropTypes.any,
  transfer: PropTypes.any,
  viewMessage: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteConfirmation);
