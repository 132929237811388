import { Connection } from '@myie/interact';
import {
  COMMON_SYSTEM_MESSAGE_REQUEST,
  COMMON_SYSTEM_MESSAGE_FAILURE,
  GET_SYSTEM_MESSAGES_SUCCESS,
  SAVE_ARRAY,
  RESET_SYSTEM_MESSAGES,
} from './types';

export const resetSystemMessages = () => ({
  type: RESET_SYSTEM_MESSAGES,
  isFetching: false,
  error: null,
  alertVisibleArray: [],
  messageArray: [],
  systemMessages: null,
});

/* Get System Messages */
export const commonSystemMsgRequest = () => ({
  type: COMMON_SYSTEM_MESSAGE_REQUEST,
  isFetching: true,
  error: null,
});

export const commonSystemMsgFailure = error => ({
  type: COMMON_SYSTEM_MESSAGE_FAILURE,
  isFetching: false,
  error: error.message,
});

// To Do: REMOVE this Hardoding by EOD 24/02/2023
export const getSystemMessagesSuccess = json => ({
  type: GET_SYSTEM_MESSAGES_SUCCESS,
  systemMessages: json,
  isFetching: false,
  error: null,
});

export const saveSytemMessageArray = (visibleArray, messageArray) => ({
  type: SAVE_ARRAY,
  alertVisibleArray: visibleArray,
  messageArray: messageArray,
});

export const getSystemMessages = request => dispatch => {
  dispatch(commonSystemMsgRequest());
  return fetch(Connection.baseUrl('messaging') + `v1.0/system/getmessages`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getSystemMessagesSuccess(json)))
    .catch(error => dispatch(commonSystemMsgFailure(error)));
};

export const isSystemReadOnly = () => dispatch => {
  dispatch(commonSystemMsgRequest());
  return fetch(
    Connection.baseUrl('custombrand') + `v1.0/checkSystemDowntime/`,
    {
      method: 'post',
      headers: Connection.headers(),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getSystemMessagesSuccess(json)))
    .catch(error => dispatch(commonSystemMsgFailure(error)));
};

export const shouldFetchSystemMessages = state => {
  if (!state.systemMessages || !state.systemMessages.ExpiryDate) {
    return true;
  }

  const expiry = state.systemMessages.ExpiryDate;
  return new Date() > new Date(expiry);
};

export const fetchSystemMessagesIfNeeded = request => (dispatch, getState) => {
  if (shouldFetchSystemMessages(getState().messagingSystemDefinition)) {
    return dispatch(getSystemMessages(request));
  }
};
