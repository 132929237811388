import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class UserNameReminder extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      data,
      updateUrl,
      urlParams,
      stateList,
      setStage,
      resetForgottenUN,
    } = this.props;
    let hasStage = false;
    if (urlParams && urlParams.pageStage) {
      hasStage = stateList.find(element => element === urlParams.pageStage);
    }
    if (hasStage) {
      let stateData = {
        formData: data,
        url: { pageStage: 'UsernameSuccess' },
      };
      updateUrl(stateData);
    } else {
      let stateData = {
        url: {
          pageStage: 'PersonalDetails',
        },
      };
      updateUrl(stateData);
      setStage('PersonalDetails');
      resetForgottenUN();
    }
  }
  render() {
    return (
      <div id="forgotten-username-success">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Forgotten username success"
          metaDescription="Forgotten username success"
        />
        <Content
          tag="h1"
          tagID="forgotten-username-success-title"
          cmsTag="Authentication:forgotten-username:Username-reminder:h1"
          copytext="Username reminder request successful"
        />
        <p className="lead">
          <Markdown
            cmsTag="Authentication:forgotten-username:Username-reminder:p1"
            template={{
              markdown: {
                username: this.props.username,
              },
            }}
            markdown={`Your username is: **$[username]**.`}
          />
        </p>
        <Content
          tag="p"
          cmsTag="Authentication:forgotten-username:Username-reminder:p2"
          copytext="Please memorise this as you will need it every time you sign in online or if you need to call us to discuss your account."
        />
        <Content
          tag="p"
          cmsTag="Authentication:forgotten-username:Username-reminder:p3"
          copytext={`When you have signed in you can change your username to something more memorable by editing your details in "Security details" in the "My Details" tab.`}
        />
        <div className="form-button-group">
          <Link className="btn btn-primary" role="button" to="/sign-in">
            <Content cmsTag="GLOBAL:Sign-in" copytext="Sign in" />
          </Link>
        </div>
      </div>
    );
  }
}

UserNameReminder.propTypes = {
  username: PropTypes.any,
  setStage: PropTypes.any,
  urlParams: PropTypes.any,
  stateList: PropTypes.any,
  updateUrl: PropTypes.any,
  data: PropTypes.any,
  resetForgottenUN: PropTypes.any,
};

export default UserNameReminder;
