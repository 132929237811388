import React from 'react';
import PropTypes from 'prop-types';
import { Content, Markdown, RadioGroup, Radio } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class EditMarketingPreferences extends React.Component {
  onChangePreferences = e => {
    const { name, value } = e.target;

    const { updateMarketingPreferences } = this.props;

    updateMarketingPreferences(name, value);
  };
  render() {
    const { marketingPreferencesForm } = this.props;

    return (
      <section className="mt-5 pt-5">
        <Content
          tag="h2"
          cmsTag="User-management:Personal-details:Additional-details:Marketing-preferences:h2-1"
          copytext="Contact preferences"
        />
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:Additional-details:Marketing-preferences:legend-1"
          copytext="From time to time we will need to contact you with important information for example, changes to your savings account interest rate, and other regulatory information we are required to send you."
        />
        <Content
          tag="p"
          cmsTag="User-management:Personal-details:Additional-details:Marketing-preferences:p1"
          copytext="We have an ongoing commitment to reduce our environmental impact and we would like to send this information to you by email. If you are happy for us to do this, please tick the box. Otherwise, we will keep you up to date with important account information by post."
        />
        <RadioGroup
          legend="Please confirm how you would like us to contact you"
          cmsTag="GLOBAL:Please=confirm-how-you-would-like-us-to-contact-you"
          required={true}
          field="preferences"
          id="marketingPreferences"
          validation={marketingPreferencesForm.preferences}
        >
          <Radio
            label="Email"
            cmsTag="GLOBAL:Email"
            id="preferences-e"
            field="preferences"
            onChange={this.onChangePreferences}
            validation={marketingPreferencesForm?.preferences}
            value="E"
          />
          <Radio
            label="Post"
            cmsTag="GLOBAL:Post"
            id="preferences-w"
            field="preferences"
            onChange={this.onChangePreferences}
            validation={marketingPreferencesForm?.preferences}
            value="W"
          />
        </RadioGroup>
        <Markdown
          cmsTag="User-management:Personal-details:Additional-details:Marketing-preferences:section-newsletter"
          markdown={`\nWe also have a society newsletter which highlights any new savings accounts, mortgages, and other offers that we think you may be interested in by email. You can unsubscribe from the newsletter at any time by clicking unsubscribe on the email.`}
        />
        <Markdown
          template={{
            markdown: {
              companySiteName: CONTACTS.companySiteName,
              privacyPolicyURL: CONTACTS.privacyPolicyURL,
            },
          }}
          cmsTag="User-management:Personal-details:Additional-details:Marketing-preferences:section-1"
          markdown={`\nIf you ever need to change your contact preferences, please give us a call, email or pop into one of our branches. You can also update your preferences within $[companySiteName].\n\nTo find out how we use your information, and keep it safe, please read our <a href="$[privacyPolicyURL] "target="_blank">Privacy Policy</a> for more information.`}
        />
      </section>
    );
  }
}

EditMarketingPreferences.propTypes = {
  userDetails: PropTypes.object,
  updateMarketingPreferences: PropTypes.func,
  marketingPreferences: PropTypes.object,
  marketingPreferencesForm: PropTypes.object,
};

export default EditMarketingPreferences;
