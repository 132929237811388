// start fetch process - get list of "customers" for person
export const MESSAGE_ATTACHMENT_REQUEST = 'MESSAGE_ATTACHMENT_REQUEST';

// show details of each customer on list
export const MESSAGE_ATTACHMENT_SUCCESS = 'MESSAGE_ATTACHMENT_SUCCESS';

// we failed to fetch list or we failed to fetch details - show error
export const MESSAGE_ATTACHMENT_FAILURE = 'MESSAGE_ATTACHMENT_FAILURE';

export const RESET_MESSAGE_ATTACHMENT = 'RESET_MESSAGE_ATTACHMENT';
