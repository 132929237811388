import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Session } from '@myie/interact';
import { withRouter } from 'react-router-dom';
import { Content } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Menu } from '@myie/interact-widget-menu-dom';

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: '',
    };
  }

  faqClick = e => {
    if (navigator.userAgent.includes('Firefox')) {
      e.preventDefault();
      window.open(CONTACTS.HelpURL);
      return false;
    }
    return true;
  };

  render() {
    return (
      <header className={`top-bar ${this.state.activeClass}`}>
        {Session.isAuthenticated() ? (
          <a href="#main-menu" className="sr-only sr-only-focusable">
            <Content cmsTag="GLOBAL:Skip-to-menu" copytext="Skip to Menu" />
          </a>
        ) : null}
        <a
          href="#main-content"
          className="sr-only sr-only-focusable skip-button"
        >
          <Content cmsTag="GLOBAL:Skip-to-content" copytext="Skip to Content" />
        </a>
        <Menu contacts={CONTACTS} />
      </header>
    );
  }
}

Connect.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  children: PropTypes.any,
};

export default withRouter(Connect);
