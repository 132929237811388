import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '@myie/interact-authentication';
import { mapDispatchToProps } from '@myie/interact-shared';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class NotAllowed extends React.Component {
  componentWillUnmount() {
    const { userLogout } = this.props;
    userLogout();
  }

  render() {
    return (
      <div id="registration-not-allowed">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Registration not allowed"
          metaDescription="Registration not allowed"
        />
        <Content
          tag="h1"
          tagID="registration-not-allowed-title"
          cmsTag="Authentication:Registration:Not-allowed:h1"
          copytext="Registration not allowed"
        />
        <Content
          tag="p"
          cmsTag="Authentication:Registration:Not-allowed:p1"
          copytext={`Sorry, you are not allowed to register for online banking.`}
        />
        <Markdown
          cmsTag="Authentication:Registration:Not-allowed:p2"
          template={{
            markdown: {
              phone: CONTACTS.phone,
              phoneLink: CONTACTS.phoneLink,
            },
          }}
          markdown={`\nPlease contact our call centre so that we can help on <a href="tel:$[phoneLink]">$[phone]</a>.`}
        />

        <div className="form-button-group">
          <Link
            id="registration-not-allowed-home-button"
            className="btn btn-secondary"
            role="button"
            to="/"
          >
            <Content
              cmsTag="GLOBAL:Back-to-home-page"
              copytext="Back to home page"
            />
          </Link>
        </div>
      </div>
    );
  }
}

NotAllowed.propTypes = {
  userLogout: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotAllowed);
