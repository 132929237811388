import React, { Component } from 'react';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  Content,
  FormattedCurrency,
  FormattedDate,
  Markdown,
  AppMeta,
} from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import { CONTACTS } from '@myie/interact-local-dom';

class CreationSuccess extends Component {
  componentDidMount() {
    const { resetCreateNoticeToWithdraw } = this.props;
    resetCreateNoticeToWithdraw();
  }

  render() {
    const { account = {} } = this.props;
    if (!account) {
      return null;
    }

    const { data } = this.props;
    let amount = data && data['amount'] ? data['amount'] : '';
    const closeOption = false;

    const { CurrencyCode } = account;

    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;

    const balance = AvailableBalance + PendingBalance;

    return (
      <div id="Transfer-to-another-account-successful">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Transfer to another ${CONTACTS.companyNameShorthand} member's savings account success`}
          metaDescription={`Transfer to another ${CONTACTS.companyNameShorthand} member's savings account success`}
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Transfer-to-another-account:Create-transfer:Transfer-success:h1"
          copytext="Your money has been transferred"
        />
        <div className="confirm-transfer-to-another-account-details">
          <Row tag="dl" title="Transfer details">
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:From" copytext="From" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {account.AccountName}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:To" copytext="To" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              <p className="mb-0">
                {data && data['payeeName'] ? data['payeeName'] : ''}
              </p>
              <p className="mb-0">
                {data && data['payeeAccount'] ? data['payeeAccount'] : ''}
              </p>
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {!closeOption ? (
                <FormattedCurrency
                  quantity={amount.replace(/,/g, '')}
                  currency={CurrencyCode}
                />
              ) : (
                <React.Fragment>
                  <FormattedCurrency
                    quantity={balance}
                    currency={CurrencyCode}
                  />
                  <Content
                    cmsTag="GLOBAL:-Full-balance"
                    copytext=" (Full balance)"
                  />
                </React.Fragment>
              )}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Reference" copytext="Reference" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              {data && data['reference'] ? data['reference'] : ''}
            </Col>
            <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
              <Content cmsTag="GLOBAL:Transfer-date" copytext="Transfer date" />
            </Col>
            <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
              <FormattedDate date={new Date()} format="Do MMMM YYYY" />
            </Col>
            {closeOption ? (
              <React.Fragment>
                <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
                  <Content
                    cmsTag="GLOBAL:Closing-balance"
                    copytext="Closing balance"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
                  <FormattedCurrency
                    quantity={balance}
                    currency={CurrencyCode}
                  />
                </Col>
              </React.Fragment>
            ) : (
              ''
            )}
          </Row>
        </div>
        <div className="alert alert-info">
          <Markdown
            cmsTag="GLOBAL:Transfer-to-another-date-warning"
            markdown={
              'Please note: If your transfer date falls on a weekend or bank holiday, it will be processed the following working day.'
            }
          />
        </div>
        <div className="form-button-group">
          <Link
            id="back-to-move-money"
            className="btn btn-secondary"
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </div>
    );
  }
}

CreationSuccess.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  match: PropTypes.any,
  account: PropTypes.any,
  toAccount: PropTypes.object,
  urlParams: PropTypes.string,
  data: PropTypes.object,
};

export default connect(
  null,
  mapDispatchToProps,
)(CreationSuccess);
