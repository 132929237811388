import React from 'react';
import PropTypes from 'prop-types';
import { CustomAlert as Alert } from '@myie/interact-dom';

class CustomAlert extends React.Component {
  onDismiss = () => {
    const { closeAlert, index } = this.props;
    closeAlert(index);
  };

  render() {
    const { message, index, alertVisibleArray } = this.props;
    return (
      <React.Fragment>
        <Alert
          ariaLabelledby={`system-message-${index}`}
          visibility={alertVisibleArray[index]}
          onDismiss={this.onDismiss}
        >
          {message}
        </Alert>
      </React.Fragment>
    );
  }
}

CustomAlert.propTypes = {
  alertVisibleArray: PropTypes.any,
  closeAlert: PropTypes.any,
  index: PropTypes.any,
  message: PropTypes.any,
};

export default CustomAlert;
