import React from 'react';
import PropTypes from 'prop-types';
import { DropDown, CONSTANTS } from '@myie/interact-dom';
import { withRouter } from 'react-router-dom';
import { Validate, Utility } from '@myie/interact';

class RedirectByAccountDropdown extends React.Component {
  constructor(props) {
    super(props);
    const { defaultSelect } = this.props;
    this.state = {
      form: {
        accountType: {
          value: defaultSelect,
          rules: {
            title: 'Source Account',
            stop: true,
            required: {
              message: 'Please select your account.',
            },
          },
        },
      },
    };
  }

  withdrawalFilter = accounts => {
    if (accounts) {
      return accounts.filter(
        account =>
          account.AccountStatus !== 'Closed' &&
          account.ExtendedProperties?.AccountMode !==
            CONSTANTS.ACCOUNT_CLOSED &&
          account.ExtendedProperties.AccountType ===
            CONSTANTS.ACCOUNT_TYPE_EASY_ACCESS,
      );
    }
    return [];
  };

  noticeFilter = accounts => {
    if (accounts) {
      return accounts.filter(
        account =>
          account.AccountStatus !== 'Closed' &&
          account.ExtendedProperties?.AccountMode !==
            CONSTANTS.ACCOUNT_CLOSED &&
          account.ExtendedProperties.AccountType ===
            CONSTANTS.REGULAR_SAVINGS &&
          account.ExtendedProperties.MaturityCallDepositAccount !== 'TRUE',
      );
    }
    return [];
  };

  allAccountFilter = accounts => {
    if (accounts) {
      return accounts.filter(
        account =>
          account.AccountStatus !== 'Closed' &&
          account.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED,
      );
    }
    return [];
  };

  onChangeTarget = e => {
    const { name, value } = e.target;
    if (value === '') {
      return;
    }
    let { form } = this.state;
    const {
      baseUrl,
      accounts,
      history,
      onChangeAccount,

      accountType = null,
      updateState,
      sourcePage = null,
      accOptions = null,
    } = this.props;
    form = Validate.input(name, value, form);
    const accList = accounts?.Accounts || accOptions;
    if (accList) {
      const account = accList.find(function(element) {
        return element.AccountKey.Key === value;
      });

      this.setState({ ...this.state, form });
      onChangeAccount(e);
      if (accountType) {
        let object = {};
        switch (accountType) {
          case 'From':
            object.From = Utility.hexEncode(account.AccountKey.Key);
            break;
          case 'To':
            object.To = Utility.hexEncode(account.AccountKey.Key);
            break;
          default:
        }
        updateState(object);
      } else {
        const newUrl = baseUrl + Utility.hexEncode(account.AccountKey.Key);
        if (sourcePage) {
          history.push(newUrl, { sourcePage });
        } else {
          history.push(newUrl);
        }
      }
    }
  };

  accountOptions(prefix, item, index, extendedId) {
    return (
      <option
        id={`accountType-option-${extendedId}-${index}`}
        key={prefix + item.AccountKey.Context}
        value={item.AccountKey.Key}
      >
        {item.AccountName} {item.AccountNumber}
      </option>
    );
  }

  render() {
    const {
      accounts,
      id = 'redirect-by-account',
      defaultSelect,
      disabled = false,
      label,
      extendedId,
      optionFunc,
      accOptions,
      suffix,
      suffixAlert,
    } = this.props;

    const { form } = this.state;
    if (form.accountType.value === undefined) {
      form.accountType.value = defaultSelect;
    }

    let options = [];
    if (accOptions) {
      options = accOptions;
    } else {
      options = optionFunc ? optionFunc(accounts.Accounts) : accounts.Accounts;
    }
    return (
      <DropDown
        cmsTag="Accounts:Components:Redirect-by-account-dropdown.accountType"
        description={this.props.description}
        label={label}
        id={id}
        field="accountType"
        disabled={disabled}
        onChange={this.onChangeTarget}
        validation={form.accountType}
        suffix={suffix ? suffix : ''}
        suffixAlert={suffixAlert}
      >
        <option
          id={`accountType-option-${id}-default`}
          key="0"
          value=""
          defaultText="Please select"
        ></option>
        {options.map((item, index) => {
          return this.accountOptions('accountType', item, index, extendedId);
        })}
      </DropDown>
    );
  }
}

RedirectByAccountDropdown.propTypes = {
  accounts: PropTypes.any,
  description: PropTypes.any,
  baseUrl: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  defaultSelect: PropTypes.any,
  filterAccounts: PropTypes.any,
  history: PropTypes.any,
  onChangeAccount: PropTypes.any,
  accountType: PropTypes.any,
  extendedId: PropTypes.any,
  updateState: PropTypes.any,
  filter: PropTypes.any,
  accOptions: PropTypes.any,
  label: PropTypes.any,
  optionFunc: PropTypes.func,
  suffix: PropTypes.string,
  suffixAlert: PropTypes.bool,
  sourcePage: PropTypes.string,
};

export default withRouter(RedirectByAccountDropdown);
