import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import GoalNotice from './GoalNotice';
import Progress from './Progress';
import { Utility } from '@myie/interact';
import { Link } from 'react-router-dom';
import {
  Content,
  Icon,
  FormattedCurrency,
  FormattedDate,
  CONSTANTS,
  Markdown,
} from '@myie/interact-dom';

class Goal extends Component {
  render() {
    const { goal = {}, accounts, AccountKey, hideButtons = false } = this.props;

    let account = null;

    if (accounts && accounts.Accounts) {
      account = accounts.Accounts.find(function(element) {
        if (element) {
          return element.AccountKey.Key === AccountKey;
        }
        return false;
      });
    }

    if (!goal || !accounts || !AccountKey || !account) {
      return null;
    }

    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;

    return (
      <Card className="savings-goal-card">
        <CardHeader>
          <h2 className="media goal-icon-title">
            <Icon name={`${goal.Category}`} />
            <span className="media-body ml-3">
              {goal.Name}
              <Content
                cmsTag="Savings-servicing:Money-tools:Savings-goals:View-goals:Goal:h2-1-part-2"
                copytext=" goal"
              />
            </span>
          </h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={12} sm={12} md={12} lg={7} xl={8}>
              <Row tag="dl" title={`Goal details`}>
                <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
                  <Content
                    cmsTag="GLOBAL:Linked-account"
                    copytext="Linked account"
                  />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
                  {account && account.AccountName ? account.AccountName : ''}
                </Col>
                <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
                  <Content cmsTag="GLOBAL:You-have" copytext="You have" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
                  <FormattedCurrency
                    quantity={
                      PendingBalance
                        ? AvailableBalance + PendingBalance
                        : AvailableBalance
                    }
                    currency={account.CurrencyCode}
                  />
                </Col>
                <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
                  <Content cmsTag="GLOBAL:You-need" copytext="You need" />
                </Col>
                <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
                  <FormattedCurrency
                    quantity={goal.Amount}
                    currency={account.CurrencyCode}
                  />
                  <Content cmsTag="GLOBAL:-by-" copytext=" by " />
                  <FormattedDate
                    date={goal.TargetDate}
                    format={CONSTANTS.STANDARD_DATE_FORMAT}
                  />
                </Col>
              </Row>
              <GoalNotice
                goal={goal}
                account={account}
                isEligibleToAddNewGoal={this.props.isEligibleToAddNewGoal}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} xl={4}>
              <Progress account={account} goal={goal} />
            </Col>
          </Row>
        </CardBody>
        {!hideButtons ? (
          <CardFooter>
            <Link
              className="btn btn-light"
              role="button"
              to={
                '/money-tools/savings-goals/edit-goals/goal-list/' +
                Utility.hexEncode(account.AccountKey.Key)
              }
            >
              <Markdown
                template={{
                  markdown: {
                    goalName: goal.Name,
                  },
                }}
                tagClassName="sr-only"
                cmsTag="GLOBAL:Edit-GOALNAME-goal"
                markdown={`Edit $[goalName] goal`}
              />
            </Link>
            <Link
              className="btn btn-danger mr-0"
              role="button"
              to={
                '/money-tools/savings-goals/delete-goals/goal-list/' +
                Utility.hexEncode(account.AccountKey.Key)
              }
            >
              <Markdown
                template={{
                  markdown: {
                    goalName: goal.Name,
                  },
                }}
                tagClassName="sr-only"
                cmsTag="GLOBAL:Delete-GOALNAME-goal"
                markdown={`Delete $[goalName] goal`}
              />
            </Link>
          </CardFooter>
        ) : (
          ''
        )}
      </Card>
    );
  }
}

Goal.propTypes = {
  savingshortapply: PropTypes.object,
  AccountKey: PropTypes.string,
  match: PropTypes.any,
  account: PropTypes.any,
  viewGoals: PropTypes.func,
  hideButtons: PropTypes.bool,
  fetchAccountsIfNeeded: PropTypes.func,
  accounts: PropTypes.object,
  resetViewGoals: PropTypes.func,
  viewGoalDetails: PropTypes.object,
  goal: PropTypes.object,
  PendingBalance: PropTypes.any,
  AvailableBalance: PropTypes.any,
  isEligibleToAddNewGoal: PropTypes.bool,
};

export default Goal;
