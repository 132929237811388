import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utility } from '@myie/interact';
import SavingsDetailSummary from './SavingsDetailSummary';
import { Session } from '@myie/interact';
import MaturityDetails from './MaturityDetails';
import SavingsSummary from './SavingsSummary';

import NoticeWithdrawalDate from './NoticeWithdrawalDate';
import { Row, Col, Nav, TabContent } from 'reactstrap';

import _ from 'lodash';
import {
  TabNavItem,
  TabTransaction,
  TabDocument,
  TabSummary,
} from '@myie/interact-accounts-dom';

import { mapDispatchToProps as mapAccountsDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as brandAccountsMapDispatch } from '@myie/interact-brand-accounts';
import { Content, CONSTANTS, LinkTag } from '@myie/interact-dom';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { storage } from './storageName';
import loadStateHOC from '../shared/stateManager/loadStateHOC';
import tabNavHOC from '../shared/HO_components/tabNavHOC';

class SavingsDetails extends React.Component {
  constructor(props) {
    super(props);
    const { tabInit, getAllProducts, allProducts } = props;
    let tab =
      props.urlParams && props.urlParams.tab ? props.urlParams.tab : 'summary';

    tabInit(['summary', 'transactions']);
    this.state = {
      reversed: false,
      activeTab: tab,
      AccountKey: props.account.AccountKey.Key,
      count: 0,
      buttonStatus: false,
      allTransactions: [],
      transactionOffset: 0,
      listCount: 10,
      range: this.setInitialRange(props),
      downloadTypes: [],
      loadingTransactions: true,
    };

    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  setUpdateButton = status => {
    this.setState({ ...this.state, buttonStatus: status });
  };

  updateListCount = count => {
    this.setState({ ...this.state, listCount: count });
  };

  updateCountAndButton = (count, status) => {
    this.setState({ ...this.state, listCount: count, buttonStatus: status });
  };

  viewMore = (status, count) => {
    this.setState({
      ...this.state,
      viewMoreAvtive: true,
      listCount: count,
      buttonStatus: status,
    });
  };

  setInitialRange(props) {
    if (props.range) {
      return props.range;
    }
    const to = moment
      .utc()
      .startOf('day')
      .toISOString();
    const from = moment
      .utc()
      .subtract(1, 'months')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    return {
      To: to,
      From: from,
      Latest: true,
    };
  }

  componentDidUpdate() {
    let { activeTab } = this.state;
    const { activeKeyTab } = this.props;
    if (activeKeyTab && activeKeyTab.tab && activeKeyTab.tab !== activeTab) {
      this.setTab(activeKeyTab.tab);
    }
  }

  static getDerivedStateFromProps(props, state) {
    let { AccountKey, loadingTransactions, ...rest } = state;
    const { account, fetchSavingsAccountTransactions } = props;

    if (
      account &&
      account.AccountKey &&
      account.AccountKey.Key &&
      AccountKey !== account.AccountKey.Key
    ) {
      let rangeLocal = state.range;
      const from = moment
        .utc()
        .subtract(25, 'years')
        .add(1, 'day')
        .startOf('day')
        .toISOString();
      rangeLocal.From = from;
      const transactionType =
        account.ExtendedProperties.AccountType ===
        CONSTANTS.ACCOUNT_TYPE_MORTGAGE
          ? 'loan'
          : 'savings';
      fetchSavingsAccountTransactions(
        {
          AccountKey: account.AccountKey,
          Range: rangeLocal,
          ExtendedProperties: {
            ListSubAccountNumbers:
              account.ExtendedProperties.AccountType ===
              CONSTANTS.ACCOUNT_TYPE_MORTGAGE
                ? undefined
                : [account.ExtendedProperties.SubAccountNumber],
            BrandProductType: account.ExtendedProperties.AccountType,
            PaginationDetails: 'true',
            Limit: '50',
            Offset: '0',
          },
        },
        transactionType,
      );
      return {
        ...rest,
        AccountKey: account.AccountKey.Key,
        loadingTransactions: true,
        allTransactions: [],
        transactionOffset: 50,
      };
    }

    if (
      account &&
      account.transactions &&
      account.transactions.Transactions &&
      loadingTransactions
    ) {
      return {
        ...rest,
        allTransactions: account.transactions.Transactions,
        loadingTransactions: false,
      };
    }

    if (
      account &&
      account.AccountKey &&
      account.AccountKey.Key &&
      AccountKey !== account.AccountKey.Key
    ) {
      return {
        ...rest,
        activeTab: 'summary',
        AccountKey: account.AccountKey.Key,
        count: 0,
      };
    }

    return null;
  }

  setCount = count => {
    this.setState({ ...this.state, count });
  };

  orderTransactions = () => {
    const { allTransactions } = this.state;
    const { account } = this.props;
    let newList = [];
    if (allTransactions) {
      newList = allTransactions.map(transaction => {
        transaction.newDate = moment(
          transaction.ExtendedProperties.TransactionDate,
          'DD/MM/YYYY',
        ).unix();
        transaction.sortId =
          account.ExtendedProperties.AccountType ===
          CONSTANTS.ACCOUNT_TYPE_MORTGAGE
            ? transaction.TransactionIdentifier
            : transaction.ExtendedProperties.TransactionId;
        return transaction;
      });
      newList = _.sortBy(newList, 'sortId');
      newList = _.sortBy(newList, 'newDate').reverse();
    }

    return newList;
  };

  componentDidMount() {
    const {
      getDownloadlinkUrl,
      account,
      fetchSavingsAccountTransactions,
    } = this.props;
    getDownloadlinkUrl(Session);
    const { allTransactions } = this.state;

    const to = moment().startOf('day');
    const from = moment()
      .subtract(25, 'years')
      .add(1, 'day')
      .startOf('day');
    const range = {
      To: to.toISOString(),
      From: from.toISOString(),
      Latest: true,
    };
    if (!allTransactions.length) {
      const transactionType =
        account.ExtendedProperties.AccountType ===
        CONSTANTS.ACCOUNT_TYPE_MORTGAGE
          ? 'loan'
          : 'savings';
      fetchSavingsAccountTransactions(
        {
          AccountKey: account.AccountKey,
          Range: range,
          ExtendedProperties: {
            ListSubAccountNumbers:
              account.ExtendedProperties.AccountType ===
              CONSTANTS.ACCOUNT_TYPE_MORTGAGE
                ? undefined
                : [account.ExtendedProperties.SubAccountNumber],
            BrandProductType: account.ExtendedProperties.AccountType,
            PaginationDetails: 'true',
            Limit: '50',
            Offset: '0',
          },
        },
        transactionType,
      );
    }
  }

  setTabEvent(e) {
    const { changeTab } = this.props;
    e.preventDefault();
    const current = e.target.href.substring(e.target.href.indexOf('#') + 1);
    changeTab(current);
  }

  setTab(tab = null) {
    let current = tab;

    switch (current) {
      case 'transactions': {
        let rangeLocal = this.state.range;
        this.setCount(0);
        const from = moment
          .utc()
          .subtract(3, 'years')
          .add(1, 'day')
          .startOf('day')
          .toISOString();
        rangeLocal.From = from;
        this.setState({
          ...this.state,
          activeTab: current,
          range: rangeLocal,
          buttonStatus: false,
          listCount: 10,
        });

        break;
      }

      case 'summary': {
        let rangeLocal = this.state.range;
        const from = moment
          .utc()
          .subtract(40, 'years')
          .add(1, 'day')
          .startOf('day')
          .toISOString();
        rangeLocal.From = from;

        break;
      }
      default:
    }
    let stateData = {
      url: {
        tab: current,
      },
    };
    this.props.updateUrl(stateData);
    this.setState({
      ...this.state,
      activeTab: current,
      buttonStatus: false,
      listCount: 10,
    });
  }

  render() {
    const {
      activeTab,
      range,
      loadingTransactions,
      allTransactions,
    } = this.state;

    const { props } = this;
    let {
      account,
      keyTabs,
      accounts,
      downloadlinkUrl,
      location = { pathname: '' },
      fetchSavingsAccountTransactionsIfNeeded,
      loopIndex = 0,
      allProducts,
    } = props;

    if (!allProducts) {
      return null;
    }

    const product = allProducts.find(
      product =>
        product.Product_Code === account.ExtendedProperties.ProductCode,
    );

    let currentAccount = null;
    const orderedTransactions = this.orderTransactions();

    if (accounts && accounts.Accounts) {
      currentAccount = accounts.Accounts.find(function(element) {
        if (element) {
          return (
            Utility.hexEncode(element.AccountKey.Key) ===
            location.pathname.split('/').pop()
          );
        }
        return false;
      });
    }

    if (!currentAccount) {
      currentAccount = account;
    }

    let activeTabOverride = activeTab;
    const { ExtendedProperties: { MaturityDate } = {} } = account;

    let linkTNC = product?.Product_Specific_TAndCs_Link || null;
    if (linkTNC?.search('http:') === -1) {
      linkTNC = `https://${linkTNC}`;
    }

    return (
      <div id={`account-details-${loopIndex}`} className="account-header">
        <SavingsSummary
          loopIndex={loopIndex}
          account={account}
          pageMode={'detail'}
        />

        <Row>
          <Col xs={12} lg={6}>
            {currentAccount.ExtendedProperties.AccountType ===
            CONSTANTS.REGULAR_SAVINGS ? (
              <NoticeWithdrawalDate
                loopIndex={loopIndex}
                isDetail={true}
                account={account}
              />
            ) : (
              ''
            )}

            {currentAccount.ExtendedProperties.AccountType ===
              CONSTANTS.SAVINGS_PLAN && MaturityDate ? (
              <MaturityDetails loopIndex={loopIndex} account={account} />
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Content
          tagClassName="sr-only"
          tag="span"
          cmsTag="Account-type-savings:Savings-details:Tab-menu-title"
          copytext="Account details tab menu"
          tagID="savings-account-tab-menu"
        />
        <Nav tabs aria-labelledby="savings-account-tab-menu" role="tablist">
          {keyTabs ? (
            <React.Fragment>
              <TabNavItem
                name="summary"
                caption="Summary"
                activeTab={activeTabOverride}
                tablist={keyTabs}
                onClick={e => this.setTabEvent(e)}
              />
              <TabNavItem
                name="transactions"
                caption="Transactions"
                activeTab={activeTabOverride}
                tablist={keyTabs}
                onClick={e => this.setTabEvent(e)}
              />
            </React.Fragment>
          ) : (
            ''
          )}
        </Nav>
        <TabContent activeTab={activeTabOverride}>
          {keyTabs && product ? (
            <TabSummary
              name="summary"
              activeTab={activeTabOverride}
              tablist={keyTabs}
            >
              <SavingsDetailSummary
                loopIndex={loopIndex}
                account={account}
                brandProductType={account.ExtendedProperties.AccountType}
                product={product}
                allTransactions={allTransactions}
              />
              {activeTabOverride === 'summary' && linkTNC && (
                <LinkTag
                  target="_blank"
                  id="account-details-terms-link"
                  href={linkTNC}
                >
                  <Content
                    cmsTag="GLOBAL:View-product-terms-and-conditions"
                    copytext="View product terms &amp; conditions"
                  />
                  <Content
                    tagClassName="sr-only"
                    tag="span"
                    cmsTag="GLOBAL:-opens-in-a-new-window"
                    copytext=" (opens in a new window)"
                  />
                </LinkTag>
              )}
            </TabSummary>
          ) : (
            ''
          )}
          {keyTabs ? (
            <React.Fragment>
              <TabTransaction
                name="transactions"
                fetchTransactions={fetchSavingsAccountTransactionsIfNeeded}
                account={account}
                propTransactions={orderedTransactions}
                downloadlinkUrl={downloadlinkUrl}
                activeTab={activeTabOverride}
                setUpdateButton={this.setUpdateButton}
                updateListCount={this.updateListCount}
                updateCountAndButton={this.updateCountAndButton}
                listCount={this.state.listCount}
                viewMore={this.viewMore}
                buttonStatus={this.state.buttonStatus}
                tablist={keyTabs}
                setCount={this.setCount}
                count={this.state.count}
                range={range}
                loading={loadingTransactions}
                interestRates={
                  this.props.interestHistory ? this.props.interestHistory : {}
                }
              />
              <TabDocument
                name="statements"
                account={account}
                activeTab={activeTabOverride}
                tablist={keyTabs}
              />
            </React.Fragment>
          ) : (
            ''
          )}
        </TabContent>
      </div>
    );
  }
}

SavingsDetails.propTypes = {
  account: PropTypes.any,
  accounts: PropTypes.object,
  documents: PropTypes.object,
  fetchSavingsAccountIfNeeded: PropTypes.any,
  fetchSavingsAccountTransactions: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.any,
  fetchDocumentsIfNeeded: PropTypes.func,
  loopIndex: PropTypes.number,
  getInterestHistory: PropTypes.any,
  interestHistory: PropTypes.any,
  updateUrl: PropTypes.any,
  location: PropTypes.any,
  isFetching: PropTypes.any,
  tabInit: PropTypes.any,
  keyTabs: PropTypes.any,
  activeKeyTab: PropTypes.any,
  urlParams: PropTypes.any,
  range: PropTypes.any,
  downloadlinkUrl: PropTypes.any,
  savedTransactions: PropTypes.array,
  saveTransactions: PropTypes.func,
  changeTab: PropTypes.any,
  getDownloadlinkUrl: PropTypes.func,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
};

const mapStateToProps = state => {
  const {
    accountsDefinition,
    custombrandAccountsDefinition,
    custombrandSavingsServicingDefinition,
  } = state;
  return {
    ...accountsDefinition,
    ...custombrandAccountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default loadStateHOC(
  tabNavHOC(
    withRouter(
      connect(
        mapStateToProps,
        {
          ...mapDispatchToProps,
          ...mapAccountsDispatchToProps,
          ...brandAccountsMapDispatch,
          ...mapSavingsServicingDispatchToProps,
        },
      )(SavingsDetails),
    ),
  ),
  storage.name,
);
