import React, { Component } from 'react';
import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavbarToggler,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Session } from '@myie/interact';
import RegisteredMenus from './Registered';
import {
  AuthButton,
  Content,
  Icon,
  Markdown,
  CONSTANTS,
} from '@myie/interact-dom';
import { CONTACTS, URLS } from '@myie/interact-local-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { mapDispatchToProps as userManagementDispatches } from '@myie/interact-user-management';
import moment from 'moment';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      showMoneyToolsMenu: false,
    };
  }

  componentDidUpdate() {
    const { accounts = [] } = this.props;
    if (accounts && this.state.showMoneyToolsMenu !== true) {
      this.checkGoalEligibility(accounts.Accounts);
    }
  }

  componentDidMount() {
    if (Session.isAuthenticated()) {
      const { fetchUserDetailsIfNeeded } = this.props;
      let request = {
        ExtendedProperties: {},
      };
      fetchUserDetailsIfNeeded(request);
    }
  }

  isActive(route, exact) {
    if (exact) {
      return window.location.pathname === route;
    }
    let re = new RegExp(`^${route}[/]{0,1}`);
    return window.location.pathname.match(re) !== null;
  }

  toggle() {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen,
    });
  }

  checkGoalEligibility = (accounts = []) => {
    let acct;
    for (let index = 0; index < accounts.length; index++) {
      acct = accounts[index];
      if (
        acct.AccountStatus &&
        (acct.AccountStatus.toLowerCase() !== 'closed' &&
          acct.ExtendedProperties?.AccountMode !== CONSTANTS.ACCOUNT_CLOSED)
      ) {
        if (
          acct.ExtendedProperties.SavingsGoal === 'Y' ||
          acct.ExtendedProperties.HasSavingsGoals
        ) {
          this.setState({ showMoneyToolsMenu: true });
        }
      }
    }
  };

  render() {
    const { contacts, userDetails } = this.props;
    const firstLogin = localStorage.getItem('firstLogin') || false;

    const age = moment().diff(
      moment(
        userDetails?.ExtendedProperties?.DateOfBirth,
        CONSTANTS.DATE_FORMAT_YYYYMMDD,
      ),
      'years',
    );

    // don't show menu if not logged in
    if (!Session.isAuthenticated() || firstLogin) {
      return (
        <Navbar id="main-menu" expand="xl" aria-label="Main menu">
          <span role="banner">
            <NavbarBrand href="/">
              <Icon name="Logo" />
              <span className="sr-only">
                <Markdown
                  template={{
                    markdown: {
                      companyName: CONTACTS.companyName,
                    },
                  }}
                  cmsTag="GLOBAL:Company-name"
                  markdown={`$[companyName]`}
                />
              </span>
            </NavbarBrand>
          </span>
        </Navbar>
      );
    } else {
      return (
        <React.Fragment>
          <Navbar id="main-menu" expand="xl" aria-label="Main menu">
            <span role="banner">
              <NavbarBrand href="/">
                <Icon name="Logo" />
              </NavbarBrand>
            </span>
            <NavbarToggler onClick={this.toggle}>
              <span></span>
              <span></span>
              <span></span>
              <small>Menu</small>
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <AuthButton url="/signed-out" />
              <Nav navbar className="mr-auto">
                {RegisteredMenus.map(sb => {
                  switch (sb.url) {
                    case URLS.SavingsGoals:
                      return this.state.showMoneyToolsMenu ? (
                        <NavItem key={sb.url} active={this.isActive(sb.url)}>
                          {<sb.menuItem />}
                        </NavItem>
                      ) : (
                        ''
                      );
                    case URLS.ShortApply:
                      return age >= CONSTANTS.MIN_SHORT_APPLY_AGE ? (
                        <NavItem key={sb.url} active={this.isActive(sb.url)}>
                          {<sb.menuItem />}
                        </NavItem>
                      ) : (
                        ''
                      );
                    default:
                      return (
                        <NavItem key={sb.url} active={this.isActive(sb.url)}>
                          {<sb.menuItem />}
                        </NavItem>
                      );
                  }
                })}
                <NavItem>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    id="help-nav-menu"
                    className="nav-link"
                    href={contacts.helpURL}
                  >
                    <Content cmsTag="Menu:Help" copytext="Help" />
                    <Content
                      tagClassName="sr-only"
                      tag="span"
                      cmsTag="GLOBAL:-opens-in-a-new-window"
                      copytext=" (opens in a new window)"
                    />
                  </a>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </React.Fragment>
      );
    }
  }
}

Menu.propTypes = {
  location: PropTypes.object,
  contacts: PropTypes.object,
  accounts: PropTypes.any,
  fetchUserDetailsIfNeeded: PropTypes.func,
  userDetails: PropTypes.any,
};

const mapStateToProps = state => {
  const {
    accountsDefinition,
    userManagementDefinition,
    custombrandSavingsServicingDefinition,
  } = state;
  return {
    ...accountsDefinition,
    ...userManagementDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapSavingsServicingDispatchToProps,
    ...mapDispatchToProps,
    ...userManagementDispatches,
  },
)(withRouter(Menu));
