import React, { Component } from 'react';
import {
  Content,
  Markdown,
  FormattedCurrency,
  Switch,
  AppMeta,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { withRouter, Link } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Utility } from '@myie/interact';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '@myie/interact-brand-savings-servicing';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(new Date())
        .utc()
        .format('YYYY-MM-DD'),
      message: '',
      disabled: false,
    };
  }
  submit = () => {
    const { onSubmitWithdrawal } = this.props;
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    onSubmitWithdrawal();
  };

  editNotice = () => {
    const { setStage } = this.props;
    setStage('createNotice');
  };

  cancelWithdrawal = () => {
    const { setStage } = this.props;
    setStage('createNotice');
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // these response status force the form to be reset and switched back to the first page
  static getDerivedStateFromProps = nextProps => {
    const { makeWithdrawResponse = {} } = nextProps;

    if (
      makeWithdrawResponse &&
      makeWithdrawResponse.ResponseStatus === 'Failed'
    ) {
      return {
        message: 'failed',
        disabled: false,
      };
    }

    return null;
  };

  render() {
    const { data = {}, account = {} } = this.props;
    let amount = data && data['amount'] ? data['amount'] : '';
    const closeOption = data['closeAccount'] ? data['closeAccount'] : false;
    const {
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    const balance = AvailableBalance + PendingBalance;

    const { CurrencyCode } = account;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Add new notice to withdraw step 2"
          metaDescription="Add new notice to withdraw step 2"
        />
        <Switch
          id="notice-to-withdraw-alert"
          value={this.state.message || ''}
          tag="div"
          className="alert alert-danger"
          role="alert"
          scrolltotop={true}
          contents={{
            failed: {
              defaultValue: 'Notice to withdraw Failed',
            },
          }}
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Confirmation:p1"
          copytext="Please check the details you have entered are correct before continuing."
        />
        <Content
          tag="p"
          cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Confirmation:p2"
          copytext="If you have decided to withdraw your full balance and close the account, you will also withdraw any accrued interest on the withdrawal date."
        />

        <Row tag="dl" title="Account details">
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:From" copytext="From" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {account.AccountName}&nbsp;
            <span className="d-block">{account.AccountNumber}</span>
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={3}>
            <Content cmsTag="GLOBAL:Amount" copytext="Amount" />
          </Col>

          <Col tag="dd" xs={12} sm={6} md={8} lg={9}>
            {!closeOption ? (
              <FormattedCurrency
                quantity={amount.replace(/,/g, '')}
                currency={CurrencyCode}
              />
            ) : (
              <React.Fragment>
                <FormattedCurrency quantity={balance} currency={CurrencyCode} />
                <Content
                  cmsTag="GLOBAL:-Full-balance"
                  copytext=" (Full balance)"
                />
              </React.Fragment>
            )}
          </Col>
        </Row>
        {closeOption ? (
          <Content
            tag="p"
            cmsTag="Savings-servicing:Notice-to-withdrawal:Create-notice-to-withdraw:Confirmation:p3"
            copytext="You have requested that the account is closed with this withdrawal."
          />
        ) : (
          ''
        )}

        <Markdown
          tag="div"
          tagClassName="alert alert-info"
          cmsTag="GLOBAL:Withdrawal-date-warning"
          markdown={
            'Please note: If your withdrawal date falls on a weekend or bank holiday, it will be processed the following working day.'
          }
        />
        <div className="form-button-group">
          <Button
            id="confirm-notice-details"
            type="submit"
            onClick={this.submit}
            disabled={this.state.disabled}
            color="primary"
          >
            <Content
              cmsTag="GLOBAL:Confirm-notice-details"
              copytext="Confirm notice details"
            />
          </Button>
          <Button
            id="edit-create-new-notice"
            onClick={this.editNotice}
            color="light"
            disabled={this.state.disabled}
          >
            <Content
              cmsTag="GLOBAL:Edit-notice-details"
              copytext="Edit notice details"
            />
          </Button>
          <Link
            id="back-to-notice"
            className="btn btn-secondary"
            role="button"
            to={`/notice-to-withdraw/view/summary/${Utility.hexEncode(
              account.AccountKey.Key,
            )}`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-notice-list"
              copytext="Back to notice list"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  createNoticeParam: PropTypes.any,
  error: PropTypes.any,
  isFetching: PropTypes.any,
  createResponse: PropTypes.any,
  onSubmitWithdrawal: PropTypes.any,
  createNoticeToWithdraw: PropTypes.func,
  account: PropTypes.any,
  setStage: PropTypes.any,
  data: PropTypes.any,
  actionDate: PropTypes.any,
  noticeExpiryDate: PropTypes.any,
  withdrawalDate: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Confirmation));
