import React from 'react';
import { SideLink } from '@myie/interact-dom';

class UpdateSecurityDetails extends React.Component {
  render() {
    return (
      <SideLink
        linkTitle="Security details"
        linkURL="/update-security-details"
        linkID="security-details-link"
        iconImage="MyServicesSecuritySettings"
        showMessageCount={true}
        linkVersion="primary"
      />
    );
  }
}

export default UpdateSecurityDetails;
