import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input, Label } from 'reactstrap';
import { Validate } from '@myie/interact';
import moment from 'moment';
import Errors, { HasErrors } from './Errors';
import contentManaged, { ContentType } from '../HO_components/contentManaged';
import { Content } from '@myie/interact-dom';

/**
 * DateMonthYear component
 * will display Day Year and Month inputs
 */
class DateMonthYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      day: '',
      month: '',
      year: '',
    };
    this.setManagecontent();
  }

  setManagecontent = () => {
    const {
      label,
      subLabel,
      description,
      suffix,
      managedContent,
      validation,
    } = this.props;

    managedContent({
      label: { defaultValue: label },
      subLabel: { defaultValue: subLabel },
      description: { defaultValue: description },
      suffix: { defaultValue: suffix },
      validation: validation,
      dayLabel: { defaultValue: 'Day' },
      monthLabel: { defaultValue: 'Month' },
      yearLabel: { defaultValue: 'Year' },
    });
  };

  componentDidUpdate(prevProps) {
    const { validation } = this.props;
    if (validation !== prevProps.validation) this.setManagecontent();
  }

  componentDidMount() {
    if (this.props.selectedDate) {
      let dateArr = this.props.selectedDate.split('-');
      this.setState({
        year: dateArr[0],
        month: dateArr[1],
        day: dateArr[2],
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props.validation;
    if (value) {
      const { day, month, year } = state;
      let dateArr;
      if (value) {
        dateArr = value.split('-');
      }
      if (
        dateArr[0].length === 4 &&
        dateArr[0] !== year &&
        (dateArr[1].length === 2 && dateArr[1] !== month) &&
        (dateArr[2].length === 2 && dateArr[2] !== day)
      ) {
        let thisDate = {};
        thisDate.year = dateArr[0];
        thisDate.month = dateArr[1];
        thisDate.day = dateArr[2];

        return { ...thisDate };
      }
    }

    return null;
  }

  getDaysInMonth = (year, month) => {
    return moment(year + '-' + month, 'YYYY-MM').daysInMonth();
  };

  changeDate = (e, f) => {
    const { name, value } = e.target;
    if (isNaN(value)) return;
    const {
      validation,
      field,
      minYearsDiff,
      updateValidation,
      validationRulesFormat,
    } = this.props;
    const data = { ...this.state };
    data[name] = value;
    if (data.year.length === 4 && data.month.length === 2) {
      const daysInMonth = this.getDaysInMonth(data.year, data.month);
      data.day = data.day > daysInMonth ? daysInMonth : data.day;
    }

    if (data.year && data.month && data.day) {
      const date = data.year + '-' + data.month + '-' + data.day;
      f({ target: { name: field, value: date } });
      let validate = {
        ...validation,
        value: date,
      };
      validate.rules.format = validationRulesFormat;
      if (
        data.year.length === 4 &&
        data.month.length === 2 &&
        data.day.length === 2
      ) {
        const yearsDiff = moment().diff(date, 'years', true);

        if (minYearsDiff && yearsDiff && minYearsDiff > yearsDiff) {
          validate.rules.format = {
            regex: /^notadate$/,
            message: `minimum age of ${minYearsDiff}`,
          };
        }
      }
      updateValidation(field, validate);
    }

    this.setState({
      ...this.state,
      ...data,
    });
  };

  dateOnChange = e => {
    const { onChange } = this.props;
    this.changeDate(e, onChange);
  };

  dateOnBlur = e => {
    const { onBlur, onChange } = this.props;
    const data = { ...this.state };
    if (data.day && data.month && data.year) {
      this.changeDate(e, onBlur);
    } else {
      this.changeDate(e, onChange);
    }
  };

  render() {
    const {
      showErrors = true,
      field,
      id,
      validation = {},
      groupClassName,
      labelClassName,
      disabled,
      required,
      contentValue,
    } = this.props;
    const { state = {} } = validation;
    const label = contentValue(ContentType.Text, 'label');
    const subLabel = contentValue(ContentType.Text, 'subLabel');
    const description = contentValue(ContentType.Text, 'description');
    const suffix = contentValue(ContentType.Text, 'suffix');
    var fieldName =
      typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
    const isRequired =
      required || (validation && validation.rules && validation.rules.required);
    return (
      <fieldset
        className={`${HasErrors(validation) ? null : `is-invalid`} ${
          groupClassName ? groupClassName : ''
        } ${disabled ? 'disabled-input' : ''} ${
          required ? 'required-input' : ''
        }`.trim()}
        id={id}
      >
        <legend
          className={`${labelClassName ? labelClassName : ''} label`.trim()}
        >
          {label}
          {isRequired ? (
            <React.Fragment>
              <span aria-hidden="true">*</span>
              <span className="sr-only">
                <Content
                  cmsTag="GLOBAL:-required-field"
                  copytext=" (required field)"
                />
              </span>
            </React.Fragment>
          ) : (
            ''
          )}{' '}
          {subLabel.length > 0 ? <small>{subLabel}</small> : ''}
          {description.length > 0 ? (
            <span className="description">{description}</span>
          ) : (
            ''
          )}
        </legend>
        <InputGroup id={id + '-input-group'}>
          <Label
            id={id + '-day-label'}
            htmlFor={id + '-day'}
            className="sr-only"
          >
            {contentValue(ContentType.Text, 'dayLabel')}
          </Label>
          <Input
            aria-describedby={
              HasErrors(validation) ? null : `error-${fieldName}-validation`
            }
            //aria-labelledby={id + '-day-label'}"
            disabled={disabled}
            required={required}
            name={'day'}
            id={id + '-day'}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            value={this.state.day}
            onChange={this.dateOnChange}
            onBlur={this.dateOnBlur}
            placeholder="DD"
            maxLength={2}
            className={'mr-3'}
          />
          <Label
            id={id + '-month-label'}
            htmlFor={id + '-month'}
            className="sr-only"
          >
            {contentValue(ContentType.Text, 'monthLabel')}
          </Label>
          <Input
            aria-describedby={
              HasErrors(validation) ? null : `error-${fieldName}-validation`
            }
            //aria-labelledby={id + '-month-label'}
            disabled={disabled}
            required={required}
            name={'month'}
            id={id + '-month'}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            value={this.state.month}
            onChange={this.dateOnChange}
            onBlur={this.dateOnBlur}
            placeholder="MM"
            maxLength={2}
            className={'mr-3'}
          />
          <Label
            id={id + '-year-label'}
            htmlFor={id + '-year'}
            className="sr-only"
          >
            {contentValue(ContentType.Text, 'yearLabel')}
          </Label>
          <Input
            aria-describedby={
              HasErrors(validation) ? null : `error-${fieldName}-validation`
            }
            //aria-labelledby={id + '-year-label'}
            disabled={disabled}
            required={required}
            name={'year'}
            id={id + '-year'}
            invalid={Validate.isInvalid(state)}
            valid={Validate.isValid(state)}
            value={this.state.year}
            onChange={this.dateOnChange}
            onBlur={this.dateOnBlur}
            placeholder="YYYY"
            maxLength={4}
          />
        </InputGroup>
        {suffix.length > 0 ? <p className="suffix clearfix">{suffix}</p> : ''}
        {showErrors ? (
          <Errors
            contentValue={contentValue}
            validation={validation}
            field={field}
          />
        ) : (
          ''
        )}
      </fieldset>
    );
  }
}

DateMonthYear.propTypes = {
  contentValue: PropTypes.any,
  description: PropTypes.string,
  disabled: PropTypes.any,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  groupClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  managedContent: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  selectedDate: PropTypes.string,
  showErrors: PropTypes.bool,
  showFutureDate: PropTypes.bool,
  subLabel: PropTypes.string,
  suffix: PropTypes.string,
  validation: PropTypes.object,
  updateValidation: PropTypes.func,
  minYearsDiff: PropTypes.number,
  validationRulesFormat: PropTypes.object,
};

export default contentManaged(DateMonthYear);
