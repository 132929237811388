import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';

import {
  Content,
  Markdown,
  FormattedCurrency,
  CONSTANTS,
  FormattedDate,
} from '@myie/interact-dom';

class ReviewAndSubmit extends Component {
  submitForm = () => {
    const { submit } = this.props;
    submit();
  };

  edit = () => {
    const { setStateDynamic, updateUrl } = this.props;
    let stateData = {
      formData: this.props.formData,
      url: {
        pageStage: 'goalDetails',
      },
    };
    updateUrl(stateData);
    setStateDynamic({
      stage: 'goalDetails',
    });
  };

  render() {
    const { account, page, getBackButton, goalName } = this.props;
    const { formData = {} } = this.props;
    const monthlyAmount = formData['monthlyAmount']
      .toString()
      .replace(/,/g, '');

    return (
      <React.Fragment>
        <Markdown
          cmsTag="Savings-servicing:Money-tools:Savings-goals:Shared:Review-and-submit:p1"
          markdown={`\nPlease check the details you have entered are correct before continuing.`}
        />
        <Row tag="dl" title={`Goal details`}>
          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Name-of-goal" copytext="Name of goal" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            {goalName}
          </Col>
          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Linked-account" copytext="Linked account" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            {account && account.AccountName ? account.AccountName : ''}
          </Col>

          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Amount-to-save" copytext="Amount to save" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedCurrency
              quantity={formData['amount'].toString().replace(/,/g, '')}
              currency={account.CurrencyCode}
            />
          </Col>

          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content cmsTag="GLOBAL:Goal-date" copytext="Goal date" />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedDate
              date={formData['date']}
              format={CONSTANTS.STANDARD_DATE_FORMAT}
            />
          </Col>

          <Col tag="dt" xs={12} sm={6} md={4} lg={4} xl={3}>
            <Content
              cmsTag="GLOBAL:Monthly-contribution"
              copytext="Monthly contribution"
            />
          </Col>
          <Col tag="dd" xs={12} sm={6} md={8} lg={8} xl={9}>
            <FormattedCurrency
              quantity={monthlyAmount.toString().replace(/,/g, '')}
              currency={account.CurrencyCode}
            />
          </Col>
        </Row>
        <div className="form-button-group">
          <Button
            id="savings-goals-submit"
            onClick={() => this.submitForm()}
            color="primary"
          >
            <Content
              cmsTag="GLOBAL:Confirm-goal-details"
              copytext="Confirm goal details"
            />
          </Button>
          <Button
            id="savings-goals-edit"
            onClick={() => this.edit()}
            color="light"
          >
            <Content
              cmsTag="GLOBAL:Edit-goal-details"
              copytext="Edit goal details"
            />
          </Button>
          {getBackButton(page)}
        </div>
      </React.Fragment>
    );
  }
}

ReviewAndSubmit.propTypes = {
  submit: PropTypes.func,
  account: PropTypes.any,
  goalName: PropTypes.string,
  goal: PropTypes.object,
  page: PropTypes.string,
  getBackButton: PropTypes.func,
  updateUrl: PropTypes.func,
  setStateDynamic: PropTypes.func,
  formData: PropTypes.object,
};

export default ReviewAndSubmit;
