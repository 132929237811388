/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import React from 'react';
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'
import { Markdown, CONSTANTS } from '@myie/interact-dom';
import { SavingsDetails } from '@myie/interact-account-type-savings-dom';
import { MortgageDetails } from '@myie/interact-account-type-mortgage-dom';


class LocalAccountDetails extends React.Component {
render() {
// eslint-disable-next-line no-unused-vars
const { account, loopIndex, mode, accounts } = this.props
switch (account.ExtendedProperties.AccountType) {
		case CONSTANTS.ACCOUNT_TYPE_SAVINGS: return (<SavingsDetails loopIndex={loopIndex} account={account} mode={mode}/>); 
        case CONSTANTS.ACCOUNT_TYPE_MORTGAGE: return (<MortgageDetails accounts={accounts} loopIndex={loopIndex} account={account} mode={mode}/>); 

default: return (
<Alert color="warning" className="account-card account-summary">
    <Markdown cmsTag="Account-details:PRODUCTTYPE-not-supported-alert-1" template={ { markdown: { productType: account.ExtendedProperties.AccountType, }, } } markdown={`Account type **$[productType]** not supported`} />
</Alert>
)
}
}
}

LocalAccountDetails.propTypes = {
account: PropTypes.any,
loopIndex: PropTypes.any,
accounts: PropTypes.any,
mode: PropTypes.any
}

export default LocalAccountDetails