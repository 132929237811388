import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@myie/interact';
import { Switch, Route, withRouter } from 'react-router-dom';
import {
  Switch as MessageSwitch,
  Content,
  NoMatch,
  NetworkError,
  GoogleTagManager,
  TimeOutModal,
  AvailableRoutes,
  Interceptor,
  BankHolidays,
  AppMeta,
  ScrollToTop,
} from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Sidebar, HasSidebar } from '@myie/interact-widget-sidebar-dom';
import { Container } from 'reactstrap';
import Home from './Home';
import { TwoFactorPages } from '@myie/interact-two-factor-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Routes as cmuRoutes } from '@myie/interact-manager-dom';
import Header from './Header';
import Footer from './Footer';

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: '',
    };
  }

  componentDidMount() {
    this.updateLocation();
  }

  componentDidUpdate() {
    this.updateLocation();
  }

  updateLocation = () => {
    this.unlisten = this.props.history.listen(location => {
      this.event = { eventupdate: location, test: 'test' };
    });
    const location = this.props.location;
    if (location !== this.state.location) {
      this.setState({ location });
    }
  };

  faqClick = e => {
    if (navigator.userAgent.includes('Firefox')) {
      e.preventDefault();
      window.open(CONTACTS.HelpURL);
      return false;
    }
    return true;
  };

  render() {
    const localProps = { contacts: CONTACTS };
    const nomatchProps = { contacts: CONTACTS, location: this.props.location };
    return (
      <HelmetProvider>
        <a
          href="#main-content"
          className="sr-only sr-only-focusable skip-button"
        >
          <Content cmsTag="GLOBAL:Skip-to-content" copytext="Skip to Content" />
        </a>
        <AppMeta
          id="meta-data-default"
          contacts={CONTACTS}
          stage="child"
          title={`${CONTACTS.companyName}`}
          metaDescription={`${CONTACTS.companyName}`}
        />

        <ScrollToTop>
          <div>
            <div className="sr-only" aria-hidden="true">
              <BankHolidays
                id="bank-holidays"
                holidayList="2020-08-31,2020-12-25,2020-12-28,2021-01-01,2021-04-02,2021-05-03,2021-08-30,2021-12-27,2021-12-28"
              />
            </div>
            <MessageSwitch
              value="NotDisplayed"
              cmsTag="Timeout:Model:Messages"
              tag="div"
              className="alert alert-info w-100"
              template={{
                TimerCountDown: { count: 0 },
              }}
              contents={{
                TimerCountDown: {
                  defaultValue:
                    'For security reasons, this page will timeout in $[count] seconds. Would you like more time?',
                },
                SessionTimeout: {
                  defaultValue:
                    'You will be logged out in one minute. Do you want to continue your session?',
                },
                yesButton: {
                  defaultValue: 'Yes',
                },
                noButton: {
                  defaultValue: 'No',
                },
                SignInTimeout: {
                  defaultValue:
                    'You have taken too long to complete the sign in process. You will be returned to the first sign in page.',
                },
                ForgottenPasswordTimeout: {
                  defaultValue:
                    'You have taken too long to complete the credentials reset process. You will be returned to the first forgotten credentials page.',
                },
                RegistreationTimeout: {
                  defaultValue:
                    'You have taken too long to complete the registration process. You will be returned to the first registration page.',
                },
              }}
            />
            <GoogleTagManager
              gtmId={Settings.gtmId}
              scriptId={'gtmId'}
              additionalEvents={this.event}
            />
            <GoogleTagManager
              gtmId={Settings.iegtmId}
              scriptId={'iegtmId'}
              additionalEvents={this.event}
            />
            <div className="content">
              <Header />
              <div className="content-background">
                <Container className="content-container clear-both">
                  <main
                    className={` ${
                      HasSidebar(this.props) ? 'left-container' : 'w-100'
                    }`.trim()}
                    id="main-content"
                    aria-live="polite"
                  >
                    <Interceptor TwoFactorWrapper={TwoFactorPages}>
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route
                          exact
                          path="/network-error"
                          render={() => <NetworkError {...localProps} />}
                        />
                        {AvailableRoutes}
                        {cmuRoutes}
                        <Route render={() => <NoMatch {...nomatchProps} />} />
                      </Switch>
                    </Interceptor>
                  </main>

                  <Sidebar location={this.state.location} />
                </Container>
              </div>
            </div>
            <Footer />
            <TimeOutModal history={this.props.history} />
          </div>
        </ScrollToTop>
      </HelmetProvider>
    );
  }
}

Connect.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  children: PropTypes.any,
};

export default withRouter(Connect);
