import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Content, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

class AddNominatedAccountScreen extends Component {
  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'account-details':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        case 'myDetails':
          history.push('/details/index');
          break;
        default:
      }
    }
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
      case 'account-details':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
      case 'myDetails':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-my-details"
            copytext="Back to my details"
          />
        );
    }
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-add-dynamic-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Your nominated account for withdrawals - no account set up"
          metaDescription="Your nominated account for withdrawals - no account set up"
        />
        <Content
          tag="h1"
          cmsTag="Accounts:Nominated-account:Add-nominated-account-screen:h1"
          copytext="Your nominated account for withdrawals"
        />
        <Content
          tag="p"
          cmsTag="Accounts:Nominated-account:Add-nominated-account-screen:p1"
          copytext='There is no nominated account set up in your name for withdrawals. Please click on "Add nominated account" to set one up.'
        />
        <div className="form-button-group">
          <Link
            id="no-nominated-account-add-new"
            className="btn btn-primary"
            role="button"
            to={`/nominated-account-for-withdrawals/add/${
              match.params.page ? match.params.page + '/' : ''
            }${match.params.id ? match.params.id : ''}`}
          >
            <Content
              cmsTag="GLOBAL:Add-nominated-account"
              copytext="Add nominated account"
            />
          </Link>

          {this.getBackButton(match.params.page)}
        </div>
      </React.Fragment>
    );
  }
}

AddNominatedAccountScreen.propTypes = {
  resetCreateNoticeToWithdraw: PropTypes.func,
  match: PropTypes.any,
  history: PropTypes.any,
  account: PropTypes.any,
};

export default AddNominatedAccountScreen;
