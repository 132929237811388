import { Connection, TwoFactor } from '@myie/interact';
import {
  MAKE_A_DEPOSIT_REQUEST,
  MAKE_A_DEPOSIT_SUCCESS,
  MAKE_A_DEPOSIT_FAILURE,
  RESET_MAKE_A_DEPOSIT,
} from './makeADepositTypes';

export const resetMakeADeposit = () => ({
  type: RESET_MAKE_A_DEPOSIT,
});

export const requestMakeADeposit = () => ({
  type: MAKE_A_DEPOSIT_REQUEST,
  isFetching: true,
  error: null,
});

export const makeADepositSuccess = json => ({
  type: MAKE_A_DEPOSIT_SUCCESS,
  makeADepositResponse: json,
  isFetching: false,
  error: null,
});

export const makeADepositFailure = error => ({
  type: MAKE_A_DEPOSIT_FAILURE,
  isFetching: false,
  error: error.message,
});

export const makeADeposit = request => dispatch => {
  return TwoFactor.run(() => {
    dispatch(requestMakeADeposit());
    return fetch(
      Connection.baseUrl('serverRoot') + '/customdar/v1.0/paymentservice',
      {
        //return fetch(Connection.baseUrl('custombrand') + `v1.0/paymentservice`, {
        method: 'post',
        headers: Connection.headers(),
        body: JSON.stringify(request),
      },
    )
      .then(response => Connection.errored(response))
      .then(response => response.json())
      .then(json => dispatch(makeADepositSuccess(json)))
      .catch(error => dispatch(makeADepositFailure(error)));
  });
};

// Special case: UI should not care about the response,
// hence did not dispatched other actions (request, success, fail)
export const checkPaymentHash = request => () => {
  return fetch(Connection.baseUrl('custombrand') + `v1.0/checkPaymentHash`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(() => {})
    .catch(() => {});
};
