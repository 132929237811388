import React from 'react';
import PropTypes from 'prop-types';
import contentManaged, { ContentType } from '../HO_components/contentManaged';

class Content extends React.Component {
  constructor(props) {
    super(props);
    const { copytext, managedContent } = props;
    managedContent({
      copytext: { defaultValue: copytext },
    });
  }

  componentDidUpdate(prevProps) {
    /* 
      When copytext becomes dynamic, manageContent should be reinitialized
    */
    const { copytext, managedContent } = this.props;
    if (prevProps.copytext && prevProps.copytext !== copytext) {
      managedContent({
        copytext: { defaultValue: copytext },
      });
    }
  }

  render() {
    const {
      contentValue,
      managedContent,
      tag,
      tagClassName,
      tagID,
      ...rest
    } = this.props;
    if (!managedContent()) return '';
    Object.keys(managedContent()).forEach(k => {
      delete rest[k];
    });
    const copytext = contentValue(ContentType.Text, 'copytext');
    if (tag) {
      let Component = tag;

      return (
        <Component id={tagID} className={tagClassName} {...rest}>
          {copytext}
        </Component>
      );
    }
    return <React.Fragment>{copytext}</React.Fragment>;
  }
}

Content.propTypes = {
  contentValue: PropTypes.func.isRequired,
  copytext: PropTypes.any,
  managedContent: PropTypes.func.isRequired,
  tag: PropTypes.any,
  template: PropTypes.any,
  tagClassName: PropTypes.string,
  tagID: PropTypes.string,
};

export default contentManaged(Content);
