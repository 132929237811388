import React, { Component } from 'react';
import { PrivateRoute, NoMatch } from '@myie/interact-dom';
import { Switch, Route, Redirect } from 'react-router-dom';
import EditNominatedAccount from './EditNominatedAccount';
import AddNominatedAccount from './AddNominatedAccount';
import NominatedAccountDetails from './NominatedAccountDetails';
import DocumentUpload from './DocumentUpload';
import PendingRequest from './PendingRequest';
import PropTypes from 'prop-types';

class NominatedAccountRoute extends Component {
  render() {
    const re = new RegExp('^/nominated-account-for-withdrawals[/]{0,1}$');
    if (this.props.location.pathname.match(re)) {
      return <Redirect to="/nominated-account-for-withdrawals/details" />;
    }
    return (
      <React.Fragment>
        <Switch>
          <PrivateRoute
            path="/nominated-account-for-withdrawals/details/:page?/:id?"
            component={NominatedAccountDetails}
          />
          <PrivateRoute
            path="/nominated-account-for-withdrawals/add/:page?/:id?"
            component={AddNominatedAccount}
          />
          <PrivateRoute
            path="/nominated-account-for-withdrawals/edit/:page?/:id?"
            component={EditNominatedAccount}
          />
          <PrivateRoute
            path="/nominated-account-for-withdrawals/upload/:page?/:id?"
            component={DocumentUpload}
          />
          <PrivateRoute
            path="/nominated-account-for-withdrawals/pending"
            component={PendingRequest}
          />
          <Route component={NoMatch} />
        </Switch>
      </React.Fragment>
    );
  }
}

NominatedAccountRoute.propTypes = {
  location: PropTypes.any,
};

export { NominatedAccountRoute };
