import { Connection } from '@myie/interact';

import {
  CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE,
  CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS,
  CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST,
  RESET_CANCEL_NOTICE_TO_WITHDRAWAL,
  SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS,
  RESET_CREATE_NOTICE_TO_WITHDRAWAL,
  SET_MAKE_A_WITHDRAW_PARAM,
  MAKE_A_WITHDRAW_REQUEST,
  MAKE_A_WITHDRAW_SUCCESS,
  MAKE_A_WITHDRAW_FALIURE,
  RESET_MAKE_A_WITHDRAW,
  BANK_HOLIDAYS,
  NOTICE_TO_WITHDRAWAL_LIST_SUCCESS,
  NOTICE_TO_WITHDRAWAL_LIST_FAILURE,
  WITHDRAWAL_CLOSING_BAL_SUCCESS,
  WITHDRAWAL_CLOSING_BAL_FAILURE,
  GET_NOMINATED_ACCOUNT_REQUEST,
  GET_NOMINATED_ACCOUNT_SUCCESS,
  GET_NOMINATED_ACCOUNT_FAILURE,
  RESET_NOMINATED_ACCOUNT,
} from './withdrawalTypes';

export const cancellationRequest = () => ({
  type: CANCEL_NOTICE_TO_WITHDRAWAL_REQUEST,
  isFetching: true,
  error: null,
});

export const cancellationFailed = error => ({
  type: CANCEL_NOTICE_TO_WITHDRAWAL_FAILURE,
  isFetching: false,
  error: error.message,
});

export const cancellationSuccess = json => ({
  type: CANCEL_NOTICE_TO_WITHDRAWAL_SUCCESS,
  cancellationResponse: json,
  isFetching: false,
  error: null,
});

export const resetCancellation = () => ({
  type: RESET_CANCEL_NOTICE_TO_WITHDRAWAL,
});

export const setCreateNoticeToWithdrawParam = data => ({
  type: SET_CREATE_NOTICE_TO_WITHDRAWAL_PARAMS,
  createNoticeParam: data,
});

export const resetCreateNoticeToWithdraw = () => ({
  type: RESET_CREATE_NOTICE_TO_WITHDRAWAL,
});

export const setBankHolidays = json => ({
  type: BANK_HOLIDAYS,
  holidays: json,
  isFetching: false,
  error: null,
});

export const createWithdrawalRequest = () => ({
  type: MAKE_A_WITHDRAW_REQUEST,
  isFetching: true,
  error: null,
});

export const makeaWithdrawSuccess = json => ({
  type: MAKE_A_WITHDRAW_SUCCESS,
  makeWithdrawResponse: json,
  isFetching: false,
  error: null,
});

export const makeaWithdrawFaliure = error => ({
  type: MAKE_A_WITHDRAW_FALIURE,
  makeWithdrawResponse: null,
  isFetching: false,
  error: error.message,
});

export const setWithdrawalParam = data => ({
  type: SET_MAKE_A_WITHDRAW_PARAM,
  withdrawalParam: data,
});

export const resetMakeWithdrawal = () => ({
  type: RESET_MAKE_A_WITHDRAW,
});

export const noticeListSuccess = json => ({
  type: NOTICE_TO_WITHDRAWAL_LIST_SUCCESS,
  noticeListResponse: json,
  isFetching: false,
  error: null,
});

export const noticeListFaliure = error => ({
  type: NOTICE_TO_WITHDRAWAL_LIST_FAILURE,
  noticeListResponse: null,
  isFetching: false,
  error: error.message,
});
export const accClosingBalSuccess = json => ({
  type: WITHDRAWAL_CLOSING_BAL_SUCCESS,
  closingBalResponse: json,
  isFetching: false,
  error: null,
});

export const accClosingBalFaliure = error => ({
  type: WITHDRAWAL_CLOSING_BAL_FAILURE,
  closingBalResponse: null,
  isFetching: false,
  error: error.message,
});

export const cancelNoticeToWithdraw = request => dispatch => {
  dispatch(cancellationRequest());
  return fetch(Connection.baseUrl('custombrand') + `v1.0/cancelnotice`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(cancellationSuccess(json)))
    .catch(error => {
      dispatch(cancellationFailed(error));
    });
};

export const makeaWithdraw = (
  request,
  closeAccount = false,
  isAddNotice = false,
) => dispatch => {
  dispatch(createWithdrawalRequest());
  let apiURL = 'v1.0/addwithdrawalnotice';

  if (!isAddNotice) {
    apiURL = closeAccount
      ? 'v1.0/fullwithdrawlinkaccount'
      : 'v1.0/partialwithdrawlinkaccount';
  }

  return fetch(Connection.baseUrl('custombrand') + apiURL, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(makeaWithdrawSuccess(json)))
    .catch(error => dispatch(makeaWithdrawFaliure(error)));
};

export const getListNoticeToWithdraw = request => dispatch => {
  return fetch(Connection.baseUrl('custombrand') + `v1.0/listnotices`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(noticeListSuccess(json)))
    .catch(error => dispatch(noticeListFaliure(error)));
};

export const getAccClosingBalance = request => dispatch => {
  return fetch(
    Connection.baseUrl('custombrand') + `v1.0/getaccountclosureenquiry`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(accClosingBalSuccess(json)))
    .catch(error => dispatch(accClosingBalFaliure(error)));
};

export const getNominatedAccountRequest = () => ({
  type: GET_NOMINATED_ACCOUNT_REQUEST,
  isFetching: true,
  error: null,
});

export const getNominatedAccountFailed = error => ({
  type: GET_NOMINATED_ACCOUNT_FAILURE,
  isFetching: false,
  error: error.message,
});

export const getNominatedAccountSuccess = json => ({
  type: GET_NOMINATED_ACCOUNT_SUCCESS,
  nominatedAccountResponse: json,
  isFetching: false,
  error: null,
});

export const resetNominatedAccount = () => ({
  type: RESET_NOMINATED_ACCOUNT,
  nominatedAccountResponse: null,
});

export const getNominatedAccount = request => dispatch => {
  dispatch(getNominatedAccountRequest());
  return fetch(Connection.baseUrl('custombrand') + `v1.0/querylinkedacc`, {
    method: 'post',
    headers: Connection.headers(),
    body: JSON.stringify(request),
  })
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getNominatedAccountSuccess(json)))
    .catch(error => dispatch(getNominatedAccountFailed(error)));
};
