import React from 'react';
import SelectAccounts from './SelectAccounts';

class SelectAccountsTest extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>Test Widget:</h1>
        <SelectAccounts />
      </React.Fragment>
    );
  }
}

export default SelectAccountsTest;
