import { Connection } from '@myie/interact';

import {
  RESET_DEPOSIT_ACCOUNT,
  DEPOSIT_ACCOUNT_REQUEST,
  DEPOSIT_ACCOUNT_SUCCESS,
  DEPOSIT_ACCOUNT_FALIURE,
  RESET_MATURITY_OPTIONS,
  MATURITY_OPTIONS_REQUEST,
  MATURITY_OPTIONS_SUCCESS,
  MATURITY_OPTIONS_FALIURE,
  RESET_SHOW_MATURITY_OPTIONS,
  SHOW_MATURITY_OPTIONS_REQUEST,
  SHOW_MATURITY_OPTIONS_SUCCESS,
  SHOW_MATURITY_OPTIONS_FALIURE,
} from './maturityOptionsTypes';

export const resetDepositAccount = () => ({
  type: RESET_DEPOSIT_ACCOUNT,
});

export const getDepositAccountRequest = () => ({
  type: DEPOSIT_ACCOUNT_REQUEST,
  isFetching: true,
  error: null,
});

export const getDepositAccountSuccess = json => ({
  type: DEPOSIT_ACCOUNT_SUCCESS,
  getDepositResponse: json,
  isFetching: false,
  error: null,
});

export const getDepositAccountFaliure = error => ({
  type: DEPOSIT_ACCOUNT_FALIURE,
  getDepositResponse: null,
  isFetching: false,
  error: error.message,
});

export const getDepositAccountDetails = request => dispatch => {
  dispatch(getDepositAccountRequest());
  return fetch(
    Connection.baseUrl('accounts') + `v3.0/savings/getcalldepositaccounts`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getDepositAccountSuccess(json)))
    .catch(error => dispatch(getDepositAccountFaliure(error)));
};

export const resetSetMaturityOptions = () => ({
  type: RESET_MATURITY_OPTIONS,
});

export const setMaturityOptionsRequest = () => ({
  type: MATURITY_OPTIONS_REQUEST,
  isFetching: true,
  error: null,
});

export const setMaturityOptionsSuccess = json => ({
  type: MATURITY_OPTIONS_SUCCESS,
  setDepositResponse: json,
  isFetching: false,
  error: null,
});

export const setMaturityOptionsFaliure = error => ({
  type: MATURITY_OPTIONS_FALIURE,
  setDepositResponse: null,
  isFetching: false,
  error: error.message,
});

export const setMaturityOptions = request => dispatch => {
  dispatch(setMaturityOptionsRequest());
  return fetch(
    Connection.baseUrl('accounts') + `v3.0/savings/setmaturityoption`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(setMaturityOptionsSuccess(json)))
    .catch(error => dispatch(setMaturityOptionsFaliure(error)));
};

export const resetGetShowMaturity = () => ({
  type: RESET_SHOW_MATURITY_OPTIONS,
});

export const getShowMaturityRequest = () => ({
  type: SHOW_MATURITY_OPTIONS_REQUEST,
  isFetching: true,
  error: null,
});

export const getShowMaturitySuccess = json => ({
  type: SHOW_MATURITY_OPTIONS_SUCCESS,
  showMaturityResponse: json,
  isFetching: false,
  error: null,
});

export const getShowMaturityFaliure = error => ({
  type: SHOW_MATURITY_OPTIONS_FALIURE,
  showMaturityResponse: null,
  isFetching: false,
  error: error.message,
});

export const getShowMaturity = request => dispatch => {
  dispatch(getShowMaturityRequest());
  return fetch(
    Connection.baseUrl('accounts') + `v3.0/savings/getshowmaturityaccounts`,
    {
      method: 'post',
      headers: Connection.headers(),
      body: JSON.stringify(request),
    },
  )
    .then(response => Connection.errored(response))
    .then(response => response.json())
    .then(json => dispatch(getShowMaturitySuccess(json)))
    .catch(error => dispatch(getShowMaturityFaliure(error)));
};
