import React, { Component } from 'react';
import { mapDispatchToProps as accountsDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddAndEditForm from '../shared/AddAndEditForm';
import { RULES } from '@myie/interact-dom';
import { Validate } from '@myie/interact';

class GoalDetailsForm extends Component {
  constructor(props) {
    super(props);
    const { account, formData } = props;
    const { ExtendedProperties: { AvailableBalance } = {} } = account;
    let balance = AvailableBalance;

    if (
      account.ExtendedProperties.AvailableBalance &&
      account.ExtendedProperties.PendingBalance
    ) {
      balance =
        account.ExtendedProperties.AvailableBalance +
        account.ExtendedProperties.PendingBalance;
    }

    this.state = {
      form: {
        amount: {
          rules: {
            title: 'Amount',
            stop: true,
            required: {
              message: 'Please enter an amount to save.',
            },
            thisValuehigherThan: {
              message:
                'Please enter a savings goal amount higher than the current balance of your account.',
              comparison: () => {
                return balance;
              },
            },
            format: {
              regex: RULES.ammountFormat,
              message: 'Please enter a valid amount.',
            },
          },
          value: formData ? formData['amount'] : '',
        },

        date: {
          rules: {
            title: 'bday',
            stop: true,
            format: {
              regex: RULES.dateFormat,
              message: 'Please enter a valid date.',
            },
            required: {
              message: 'Please enter a date.',
            },
            futureFormat: {
              message: 'Please enter a savings goal date later than today.',
            },
          },
          value: formData ? moment(formData['date']).format('YYYY-MM-DD') : '',
        },

        monthlyAmount: {
          rules: {
            title: 'Amount',
            stop: true,
            required: {
              message: 'Please enter your monthly savings amount.',
            },
            format: {
              regex: RULES.ammountFormat,
              message: 'Please enter a valid amount.',
            },
          },
          value: formData ? formData['monthlyAmount'] : '',
        },
      },
      disabled: false,
    };
  }

  updateCurrency = e => {
    let { name, value } = e.target;

    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    if (this.props.saveData) {
      this.props.saveData(name, value);
    }

    this.setState({ ...this.state, form });
  };

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  onChange = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form);
    if (this.props.saveData) {
      this.props.saveData(name, value);
    }
    this.setState({ ...this.state, form });
  };

  onBlur = e => {
    const { name, value } = e.target;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    this.setState({ ...this.state, form });
  };

  submit = decision => {
    const { updateUrl, setStage } = this.props;
    let { form } = this.state;

    form = Validate.form(form);
    this.setState({ ...this.state, form: form });
    if (!form.approved) {
      return;
    }
    if (decision) {
      return;
    }

    let stateData = {
      formData: this.props.data,
      url: {
        pageStage: 'reviewAndsubmit',
      },
    };
    updateUrl(stateData);
    setStage('reviewAndsubmit');
  };

  newDateChange = (name, value) => {
    let { form } = this.state;
    form = Validate.input(name, value, form);
    if (this.props.saveData) {
      this.props.saveData(name, value);
    }
    this.setState({ ...this.state, form });
  };

  changeNewAmount = (name, value) => {
    let { form } = this.state;
    const newValue = Math.ceil(
      parseFloat(value.toString().replace(/,/g, '')).toFixed(2),
    );
    form = Validate.input(name, newValue, form, true);
    if (this.props.saveData) {
      this.props.saveData(name, newValue);
    }
    this.setState({ ...this.state, form });
  };

  render() {
    const {
      viewGoalDetails,
      //formData = {},
      goal,
      page,
      //options,
      getBackButton,
      account,
    } = this.props;
    if (!viewGoalDetails) {
      return null;
    }
    const { form } = this.state;

    if (!viewGoalDetails || !account || !account.AccountKey) {
      return null;
    }
    const { ExtendedProperties: { ListNoticeToWithdraw } = {} } = account;
    let balance = account.ExtendedProperties.AvailableBalance;

    if (
      account.ExtendedProperties.AvailableBalance &&
      account.ExtendedProperties.PendingBalance
    ) {
      balance =
        account.ExtendedProperties.AvailableBalance +
        account.ExtendedProperties.PendingBalance;
    }

    let currentBalance = balance;

    let totalAmount = 0;
    if (ListNoticeToWithdraw && ListNoticeToWithdraw.length) {
      ListNoticeToWithdraw.forEach(transaction => {
        if (transaction.Amount) {
          totalAmount = totalAmount + parseFloat(transaction.Amount);
        } else {
          totalAmount = balance;
        }
      });
    }
    balance = balance - totalAmount;

    let goalName = goal.Name;
    let goalKey = goal.Category;

    // if (formData && formData['goal']) {
    //   const goal = options.find(element => element.key === formData['goal']);
    //   goalName = goal.name;
    //   goalKey = goal.key;
    // }
    let targetDate = moment(form.date.value);
    let today = moment();
    let diff = targetDate.diff(today, 'months');
    const isAfter = targetDate.isAfter(today, 'month');
    if (isAfter) {
      diff = diff + 2;
    }
    const targetAmount = parseInt(
      form.amount.value ? form.amount.value.toString().replace(/,/g, '') : 0,
    );
    let monthlyAmount;
    if (balance > targetAmount) {
      monthlyAmount = 0;
    } else {
      monthlyAmount = targetAmount - balance;
    }

    const monthlyEntered = Math.ceil(
      parseFloat(
        form.monthlyAmount.value
          ? form.monthlyAmount.value.toString().replace(/,/g, '')
          : 0,
      ).toFixed(2),
    );
    let suggestedAmount = monthlyEntered + balance;
    if (diff > 0 && balance < targetAmount) {
      monthlyAmount = (targetAmount - balance) / diff;
      suggestedAmount = diff * monthlyEntered + balance; // Reduce the total ammount to
    }

    let suggestedMonthMonth = moment();
    let suggestedMonthlyAmount = 0;
    if (suggestedAmount < targetAmount && balance < targetAmount) {
      const suggestedMonths = targetAmount / monthlyEntered;
      suggestedMonthMonth = moment().add(Math.ceil(suggestedMonths) + 1, 'M'); // Cnange date to
      suggestedMonthlyAmount = targetAmount - balance;
      if (diff > 0) {
        suggestedMonthlyAmount = (targetAmount - balance) / diff; // monthly contribution
      }
    }

    return (
      <AddAndEditForm
        onChange={this.onChange}
        onBlur={this.onBlur}
        submit={this.submit}
        saveForBudgetCalculation={this.props.saveForBudgetCalculation}
        getBackButton={getBackButton}
        newDateChange={this.newDateChange}
        currentBalance={currentBalance}
        changeGoalType={this.changeGoalType}
        updateCurrency={this.updateCurrency}
        changeNewAmount={this.changeNewAmount}
        monthlyAmount={monthlyAmount}
        form={this.state.form}
        decision={
          targetAmount > currentBalance &&
          form.monthlyAmount &&
          form.monthlyAmount.value &&
          suggestedAmount < targetAmount
        }
        page={page}
        mode="edit"
        goalName={goalName}
        goalKey={goalKey}
        targetAmount={targetAmount}
        targetDate={targetDate}
        suggestedMonthlyAmount={suggestedMonthlyAmount}
        suggestedAmount={suggestedAmount}
        suggestedMonthMonth={suggestedMonthMonth}
        account={account}
      />
    );
  }
}

GoalDetailsForm.propTypes = {
  fetchAccountsIfNeeded: PropTypes.func,
  getBackButton: PropTypes.func,
  submit: PropTypes.func,
  page: PropTypes.string,
  formData: PropTypes.any,
  data: PropTypes.object,
  updateUrl: PropTypes.func,
  options: PropTypes.any,
  setStage: PropTypes.func,
  match: PropTypes.any,
  viewGoals: PropTypes.any,
  viewGoalDetails: PropTypes.any,
  isFetching: PropTypes.any,
  saveForBudgetCalculation: PropTypes.func,
  goal: PropTypes.object,
  accounts: PropTypes.any,
  updateGoals: PropTypes.any,
  saveData: PropTypes.any,
  account: PropTypes.any,
};
const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  { ...mapDispatchToProps, ...accountsDispatchToProps },
)(GoalDetailsForm);
