import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Button, Alert, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Content, Markdown, AppMeta } from '@myie/interact-dom';
import { CONTACTS } from '@myie/interact-local-dom';
import NoticeToWithdraw from './NoticeToWithdraw';
import { Utility } from '@myie/interact';
import { RedirectByAccountDropdown } from '@myie/interact-accounts-dom';
import { mapDispatchToProps } from '@myie/interact-accounts';
import { mapDispatchToProps as savingsMapDispatchToProps } from '@myie/interact-account-type-savings';
import { mapDispatchToProps as mapSavingsServicingDispatchToProps } from '@myie/interact-brand-savings-servicing';
import { accountFilter } from '@myie/interact-savings-servicing-dom';
import { connect } from 'react-redux';
import moment from 'moment';

class NoticeToWithdrawList extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
    const { allProducts, getAllProducts } = this.props;
    if (!allProducts) {
      getAllProducts({
        checkLimitExceeded: false,
        isShortApply: false,
      });
    }
  }

  initialState = () => {
    const { accounts, match, allProducts } = this.props;
    let account;
    if (accounts) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
    } else {
      account = null;
    }
    return {
      account: account,
      products: allProducts,
      page: '',
      range: this.setInitialRange(this.props),
      createStatus: true,
      totalAmountStatus: false,
    };
  };

  setInitialRange = props => {
    if (props.range) {
      return props.range;
    }
    const to = moment
      .utc()
      .startOf('day')
      .toISOString();
    const from = moment
      .utc()
      .subtract(12, 'months')
      .add(1, 'day')
      .startOf('day')
      .toISOString();
    return {
      To: to,
      From: from,
      Latest: true,
    };
  };

  componentDidMount() {
    const { fetchAccountsIfNeeded } = this.props;
    fetchAccountsIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const { match, accounts, getListNoticeToWithdraw } = this.props;
    const { page } = this.state;
    let newPage = '';
    let account = null;
    if (prevProps.match && prevProps.match.params.page && !page) {
      newPage = prevProps.match.params.page;
      this.setState({ ...this.state, page: newPage });
    }

    if (accounts && prevProps.accounts !== accounts && match.params?.id) {
      account = accounts.Accounts.find(function(element) {
        return Utility.hexEncode(element.AccountKey.Key) === match.params.id;
      });
      getListNoticeToWithdraw({
        AccountKey: account.AccountKey,
        SubAccountNumber: account.ExtendedProperties.SubAccountNumber,
      });
      this.setState({ ...this.state, account });
    }
  }

  changeUrl = () => {
    const { match, history } = this.props;

    if (match.params && (match.params.page || match.params.id)) {
      switch (match.params.page) {
        case 'detail':
          {
            let url = `/accounts/details/${match.params.id}`;
            history.push(url);
          }
          break;
        case 'summary':
          history.push('/accounts/list');
          break;
        case 'moveMoney':
          history.push('/move-money/index');
          break;
        default:
      }
    }
  };

  onChangeAccount = e => {
    const { fetchAccountsIfNeeded, accounts } = this.props;
    fetchAccountsIfNeeded();
    const { value } = e.target;
    if (accounts && accounts.Accounts) {
      const account = accounts.Accounts.find(function(element) {
        return element.AccountKey.Key === value;
      });
      this.setState({ ...this.initialState(), account });
    }
  };

  createNotice = noticesList => {
    const { history, allProducts } = this.props;
    const { account } = this.state;
    let totalAmount = 0;
    const {
      AccountKey: { Key } = {},
      ExtendedProperties: { AvailableBalance, PendingBalance } = {},
    } = account;
    const balance = AvailableBalance + PendingBalance;
    noticesList.forEach(transaction => {
      if (transaction.Amount) {
        totalAmount = totalAmount + parseInt(transaction.Amount);
      } else {
        totalAmount = balance;
      }
    });

    const accountKey = Key && Utility.hexEncode(Key);
    const product = allProducts.find(
      product =>
        product.Product_Code === account.ExtendedProperties.ProductCode,
    );

    let createStatus = true;
    let limit = product.Max_Withdrawals_Per_Year;
    if (!isNaN(limit)) {
      createStatus = noticesList.length < limit;
    }

    if (createStatus && totalAmount < balance) {
      history.push(`/notice-to-withdraw/create/${accountKey}`);
    }
    this.setState({
      ...this.initialState(),
      createStatus: createStatus,
      totalAmountStatus: totalAmount >= balance,
    });
  };

  getTransactions = (transactions, account, accountKey, page) => {
    let list = [];
    if (transactions && transactions.length > 0) {
      transactions.forEach((transaction, index) => {
        list.push(
          <NoticeToWithdraw
            key={index}
            withdrawal={transaction}
            account={account}
            Index={transaction.NoticeId}
            paymentMethod={transaction.PaymentMethod}
            loopIndex={`notice-` + index}
            accountKey={accountKey}
            page={page}
          />,
        );
      });
    }

    return list;
  };

  getBackButton = page => {
    return (
      <React.Fragment>
        <Button
          id="back-to-accounts-button"
          color="secondary"
          onClick={() => {
            this.changeUrl();
          }}
        >
          {this.getContent(page)}
        </Button>
      </React.Fragment>
    );
  };

  getContent = page => {
    switch (page) {
      case 'summary':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-accounts"
            copytext="Back to accounts"
          />
        );
      case 'detail':
        return (
          <Content
            cmsTag="GLOBAL:Back-to-account-details"
            copytext="Back to account details"
          />
        );
      case 'moveMoney':
      default:
        return (
          <Content
            cmsTag="GLOBAL:Back-to-move-money"
            copytext="Back to move money"
          />
        );
    }
  };

  render() {
    const {
      isFetching,
      accounts = null,
      match,
      noticesList = [],
      allProducts,
    } = this.props;
    const { account, page, createStatus, totalAmountStatus } = this.state;

    let pendingTransactions;
    let accountKey;
    if (!accounts || !allProducts) {
      return null;
    }

    const options = accountFilter(
      accounts.Accounts,
      allProducts,
      true,
      false,
      true,
    );
    if (account) {
      const { AccountKey: { Key } = {} } = account;
      accountKey = Key && Utility.hexEncode(Key);
      pendingTransactions = this.getTransactions(
        noticesList,
        account,
        accountKey,
        page,
      );
    }

    const allNotices =
      isFetching || !allProducts ? (
        <p aria-live="polite">
          <Content
            cmsTag="GLOBAL:Loading"
            copytext="Please wait while the page loads."
          />
        </p>
      ) : (
        pendingTransactions
      );

    return (
      <div id="create-notice-to-withdraw">
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title="Notice to withdraw"
          metaDescription="Notice to withdraw"
        />
        <Content
          tag="h1"
          cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraws:Index:h1"
          copytext="Notice to withdraw"
        />
        {account ? (
          <Row className="mb-4">
            <Col>
              <Button
                id="create-notice-button"
                color="light"
                className="float-right"
                size="sm"
                onClick={() => {
                  this.createNotice(noticesList);
                }}
              >
                <Content
                  cmsTag="GLOBAL:Add-a-new-withdrawal-notice"
                  copytext="Add a new withdrawal notice"
                />
              </Button>
            </Col>
          </Row>
        ) : null}
        {!options.length ? (
          <React.Fragment>
            <Content
              tag="p"
              cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraws:Index:p1"
              copytext="Your savings accounts are not eligible for transactions."
            />
            {this.getBackButton(match.params.page)}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!createStatus ? (
              <Alert color="danger">
                <Markdown
                  cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraws:Index:alert-1"
                  template={{
                    markdown: {
                      phone: CONTACTS.phone,
                      phoneLink: CONTACTS.phoneLink,
                      email: CONTACTS.email,
                      openTimes: CONTACTS.openTimes,
                    },
                  }}
                  markdown={`You have reached your maximum withdrawal limit. If you have any questions, please contact our Online Support Team on <a id="contact_form" href="mailto:$[email]">$[email]</a> or call us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
                />
              </Alert>
            ) : null}
            {totalAmountStatus ? (
              <Alert color="danger">
                <Markdown
                  cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraws:Index:alert-2"
                  template={{
                    markdown: {
                      phone: CONTACTS.phone,
                      phoneLink: CONTACTS.phoneLink,
                      email: CONTACTS.email,
                      openTimes: CONTACTS.openTimes,
                    },
                  }}
                  markdown={`Your balance is insufficient to make a withdrawal. If you have any questions, please contact our Online Support Team on <a id="contact_form" href="mailto:$[email]">$[email]</a> or call us on <a href="tel:$[phoneLink]">$[phone]</a>.`}
                />
              </Alert>
            ) : null}
            <RedirectByAccountDropdown
              extendedId="select-notice-account"
              accOptions={options}
              label={'Select an account to view notices to withdraw'}
              baseUrl={`/notice-to-withdraw/view/${page}/`}
              defaultSelect={`${
                account && account.AccountKey ? account.AccountKey.Key : ''
              }`}
              onChangeAccount={this.onChangeAccount}
              id="list-notices"
              filter="notice"
            />
            <React.Fragment>
              {!isFetching && pendingTransactions ? (
                pendingTransactions.length > 0 ? (
                  <Fade in={true}>
                    <Content
                      tag="p"
                      cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraws:Index:p2"
                      copytext="You have set up the following notices to withdraw on this account:"
                    />
                    {allNotices}
                  </Fade>
                ) : (
                  <Content
                    tag="p"
                    cmsTag="Savings-servicing:Notice-to-withdrawal:List-notice-to-withdraws:Index:p3"
                    copytext="You have no notices to withdraw set up on this account."
                  />
                )
              ) : null}
            </React.Fragment>
            <React.Fragment>
              <div className="form-button-group">
                {this.getBackButton(match.params.page)}
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    );
  }
}

NoticeToWithdrawList.propTypes = {
  accounts: PropTypes.any,
  isFetching: PropTypes.bool,
  selectedAccount: PropTypes.object,
  history: PropTypes.any,
  withdrawalNotices: PropTypes.any,
  match: PropTypes.any,
  fetchAccountsIfNeeded: PropTypes.any,
  fetchSavingsAccountTransactionsIfNeeded: PropTypes.any,
  getListNoticeToWithdraw: PropTypes.func,
  noticesList: PropTypes.array,
  getAllProducts: PropTypes.func,
  allProducts: PropTypes.array,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  {
    ...mapDispatchToProps,
    ...savingsMapDispatchToProps,
    ...mapSavingsServicingDispatchToProps,
  },
)(withRouter(NoticeToWithdrawList));
