import React from 'react';
import { SideLink } from '@myie/interact-dom';

class NominatedAccountDetails extends React.Component {
  render() {
    return (
      <SideLink
        linkTitle="Nominated account details"
        linkURL="/nominated-account-for-withdrawals/details/myDetails"
        linkID="nominated-account-details-link"
        iconImage="NominatedAccountDetails"
        showMessageCount={false}
        linkVersion="primary"
      />
    );
  }
}

export default NominatedAccountDetails;
