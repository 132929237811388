import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, CardBody, CardHeader, CardFooter } from 'reactstrap';
import Balance from './Balance';
import Overview from './Overview';
import Links from './Links';
import { Content, CONSTANTS } from '@myie/interact-dom';
class MortgageSummary extends React.Component {
  render() {
    let colSize = this.props.mode === 1 ? 12 : 6;

    const { account, loopIndex, status, pageMode } = this.props;
    const { ExtendedProperties: { CurrentBalance } = {} } = account;

    let nextPayments = {};

    let tabAccounts = account.ExtendedProperties.SubAccounts
      ? account.ExtendedProperties.SubAccounts
      : [];

    if (tabAccounts.length) {
      tabAccounts.forEach(element => {
        const { ExtendedProperties: { NextPaymentDate } = {} } = element;
        nextPayments[NextPaymentDate] = nextPayments[NextPaymentDate]
          ? nextPayments[NextPaymentDate] +
            element.ExtendedProperties.NextPaymentAmount
          : element.ExtendedProperties.NextPaymentAmount;
      });
    }

    const hideAmount =
      account.AccountStatus === 'Closed' ||
      account.ExtendedProperties?.AccountMode === CONSTANTS.ACCOUNT_CLOSED
        ? true
        : false;
    const accountType =
      account.ExtendedProperties && account.ExtendedProperties.AccountType
        ? account.ExtendedProperties.AccountType
        : '';

    return (
      <Card
        className="account-card account-summary"
        id={`account-type-mortgage-summary-${loopIndex}`}
      >
        <React.Fragment>
          <CardHeader>
            {pageMode === 'detail' ? (
              <h1 id={`account-type-mortgage-title-${loopIndex}`}>
                {account.AccountName}
              </h1>
            ) : (
              <h2 id={`account-type-mortgage-title-${loopIndex}`}>
                {account.AccountName}
              </h2>
            )}
            <p className="account-number">
              <Content
                tagClassName="sr-only"
                tag="span"
                cmsTag="GLOBAL:Account-number"
                copytext="Account number"
              />
              <Content
                cmsTag="Account-type-mortgage:Mortgage-summary:-ACCOUNT-NUMBER"
                template={{
                  copytext: {
                    accountNumber: account.AccountNumber,
                  },
                }}
                copytext=" $[accountNumber]"
              />
            </p>
            {account.ExtendedProperties.CompanyName ? (
              <p className="account-name">
                <Content
                  tagClassName="sr-only"
                  tag="span"
                  cmsTag="GLOBAL:Company-name"
                  copytext="Company name"
                />
                <Content
                  cmsTag="Account-type-mortgage-summary:Mortgage:-COMPANY-NAME"
                  template={{
                    copytext: {
                      CompanyName: account.CompanyName,
                    },
                  }}
                  copytext=" $[CompanyName]"
                />
              </p>
            ) : (
              ''
            )}
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={12} md={colSize}>
                {!hideAmount ? (
                  <Card className="balance-card">
                    <CardBody>
                      <Row tag="dl" title="Account details">
                        <Balance
                          loopIndex={loopIndex}
                          account={account}
                          outstandingAmount={CurrentBalance}
                        />
                      </Row>
                    </CardBody>
                  </Card>
                ) : (
                  ''
                )}
              </Col>

              <Col xs={12} md={colSize}>
                <Overview
                  loopIndex={loopIndex}
                  account={account}
                  nextPayments={nextPayments}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Links
              accountName={account.AccountName}
              loopIndex={loopIndex}
              pageMode={pageMode}
              accountKey={account.AccountKey.Key}
              accountStatus={account.AccountStatus}
              accountType={accountType}
              status={status}
              account={account}
            />
          </CardFooter>
        </React.Fragment>
      </Card>
    );
  }
}

MortgageSummary.propTypes = {
  account: PropTypes.any,
  pageMode: PropTypes.any,
  loopIndex: PropTypes.any,
  mode: PropTypes.any,
  status: PropTypes.number,
};

export default MortgageSummary;
