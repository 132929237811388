import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Value, Content } from '@myie/interact-dom';

class Interest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: 0,
    };
  }

  render() {
    const { props } = this;
    const { account } = props;
    let interest = '';

    if (account.InterestRate) {
      interest = account.InterestRate;
    }

    return (
      <React.Fragment>
        <Col
          tag="dt"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="align-self-center"
        >
          <Content
            cmsTag="Account-type-savings:interest:Annual-variable-interest"
            copytext="Annual variable interest"
          />
        </Col>
        <Col
          tag="dd"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="align-self-center"
        >
          <Value decimalPlaces={2} value={interest} />%
        </Col>
      </React.Fragment>
    );
  }
}

Interest.propTypes = {
  account: PropTypes.any,
  loopIndex: PropTypes.any,
  loadingTransactions: PropTypes.any,
  transactions: PropTypes.any,
};

export default Interest;
