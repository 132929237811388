import React, { Component } from 'react';
import { Form, Button } from 'reactstrap';
import {
  Content,
  DropDown,
  FormattedCurrency,
  Check,
  CONSTANTS,
} from '@myie/interact-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Validate } from '@myie/interact';
import Amount from '../../shared/inputAmount';

class TransferForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props.account, props.data);
  }

  componentDidUpdate() {
    const { account } = this.props;

    if (!this.state.account && this.props.account) {
      let state = this.initialState(this.props.account, this.props.data);

      this.setState({
        ...state,
      });
    }
    if (
      this.state.account &&
      this.props.account &&
      account.AccountKey.Key !== this.state.account.AccountKey.Key
    ) {
      let state = this.initialState(this.props.account, this.props.data);

      this.setState({
        ...state,
      });
    }
  }

  initialState = (account, data) => {
    let balance = 0;
    if (account) {
      const {
        ExtendedProperties: { AvailableBalance, PendingBalance } = {},
      } = account;
      balance = AvailableBalance + PendingBalance;
    }
    return {
      account: account,
      form: {
        closeAccount: {
          rules: {
            stop: true,
            title: 'Close Account',
            maxAmountCheckCheckbox: {
              message:
                'You cannot withdraw your full balance and close the account since your full balance exceeds the max limit of £25,000. Please specify an amount less than or equal to your full balance in the "Amount to withdraw" field',
              comparison: () => {
                return [CONSTANTS.TWENTY_FIVE_THOUSAND, balance];
              },
            },
            conditionalCheck: {
              balance: balance,
              message:
                'You have decided to withdraw your full balance. Please confirm that you are happy to close the account.',
              comparison: () => {
                const value = this.state.form.amount
                  ? this.state.form.amount.value
                  : 'NA';

                return value;
              },
            },
          },
          value: data && data['closeAccount'] ? data['closeAccount'] : null,
        },
        purposeOptions: {
          rules: {
            stop: true,
            required: {
              message: 'Please select your purpose of withdrawal.',
            },
          },
          value: data && data['purposeOptions'] ? data['purposeOptions'] : '',
        },
      },
    };
  };

  onChange = e => {
    this.updateForm(e);
  };

  onBlur = e => {
    this.updateForm(e);
  };

  updateForm = e => {
    const { name, value } = e.target;
    const { accountForm, data, setStateDynamic } = this.props;
    let { form } = this.state;
    form = Validate.input(name, value, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...accountForm,
        ...form,
      },
    });

    this.setState({ ...this.state, form });
  };

  updateCurrency = e => {
    let { name, value } = e.target;
    const { accountForm, data, setStateDynamic } = this.props;
    let newValue = value;
    newValue = new Intl.NumberFormat().format(value.replace(/,/g, ''));

    // Deal with trailing . or .0 or .n0
    if (value.charAt(value.length - 1) === '.') {
      newValue += '.';
    }
    if (value.slice(value.length - 2) === '.0') {
      newValue += '.0';
    }
    if (
      value.charAt(value.length - 3) === '.' &&
      value.charAt(value.length - 1) === '0'
    ) {
      if (value.charAt(value.length - 2) === '0') {
        newValue += '.0';
      }
      newValue += '0';
    }
    if (newValue === '0') {
      newValue = '';
    }
    let { form } = this.state;
    form = Validate.input(name, newValue, form, true);
    data[name] = value;
    setStateDynamic({
      data,
      combinedForm: {
        ...accountForm,
        ...form,
      },
    });
    this.setState({ ...this.state, form });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    const {
      onSubmit,
      account,
      callAccountValidations,
      accountForm,
      setStateDynamic,
    } = this.props;
    let { form } = this.state;
    let validatedAccountForm = Validate.form(accountForm);

    if (!account || !validatedAccountForm.approved) {
      callAccountValidations();
      return;
    }

    let newForm = form;
    if (form.closeAccount.value) {
      newForm = {
        closeAccount: form.closeAccount,
        purposeOptions: form.purposeOptions,
      };
    }
    newForm = Validate.form(newForm);
    this.setState({ ...this.state, form: newForm });
    setStateDynamic(
      {
        combinedForm: { ...validatedAccountForm, ...newForm },
      },
      true,
    );
    if (!newForm.approved) {
      window.scrollTo(0, 0);
      return;
    }
    if (typeof onSubmit === 'function') {
      onSubmit(newForm, true);
    }
    onSubmit(newForm, true);
  };

  onClick = e => {
    const { name, checked } = e.target;
    let { form, amount } = this.state;

    if (checked) {
      form = {
        closeAccount: form.closeAccount,
        purposeOptions: form.purposeOptions,
      };
    } else {
      if (!form.amount) {
        form = {
          amount: amount,
          closeAccount: form.closeAccount,
          purposeOptions: form.purposeOptions,
        };
      }
    }
    form = Validate.input(name, checked ? 'true' : null, form);
    this.props.saveData(name, checked ? 'true' : null);
    this.setState({ ...this.state, form });
  };

  setAmountRules = ({ amount, withdrawalRule }) => {
    const form = { ...this.state.form, amount };
    this.setState({
      ...this.state,
      amount,
      form,
      withdrawalRule,
    });
  };

  render() {
    const { form, withdrawalRule } = this.state;
    const {
      withdrawPermitted,
      maxWithdrawals,
      data,
      account,
      nominatedAccount,
    } = this.props;
    let code = '';
    let balance = 'NaN';
    if (account) {
      const {
        CurrencyCode,
        ExtendedProperties: { AvailableBalance, PendingBalance } = {},
      } = account;
      code = CurrencyCode;
      balance = AvailableBalance + PendingBalance;
    }
    const isDisabled =
      (withdrawalRule && !withdrawalRule.isPermitted) ||
      !account ||
      !nominatedAccount;
    if (account && !withdrawPermitted) {
      return (
        <div role="alert" className="alert alert-danger">
          <Content
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:alert-2:part1"
            copytext={`Please note you have already exceeded the maximum number of `}
          />
          <strong>
            {maxWithdrawals}
            <Content
              cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:alert-2:part2"
              copytext={` transactions`}
            />
          </strong>
          <Content
            cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:alert-1:part-3"
            copytext={' this year.'}
          />
        </div>
      );
    }
    return (
      <Form
        autoComplete="off"
        id="nominated-account-form"
        onSubmit={this.onSubmitHandler}
      >
        {!(balance > CONSTANTS.TWENTY_FIVE_THOUSAND) ? (
          <Check
            cmsTag="GLOBAL:I-wish-to-withdraw-my-balance-and-close-the-account-including-accrued-interest-within-the-notice-period"
            label="I wish to withdraw my full balance and close the account (including accrued interest within the notice period)."
            id="closeAccount"
            field="closeAccount"
            checked={!!form.closeAccount.value}
            disabled={isDisabled}
            value="true"
            validation={form.closeAccount}
            onChange={this.onClick}
          />
        ) : (
          ''
        )}
        {account && withdrawalRule && !withdrawalRule.isPermitted && (
          <div role="alert" className="alert alert-danger">
            <Content
              cmsTag="Savings-servicing:move-money:error-message"
              copytext={withdrawalRule.message}
            />
          </div>
        )}
        {balance !== 'NaN' ? (
          <div role="alert" className="alert alert-info">
            <Content
              cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:alert-1:part-1"
              copytext={
                'Please note if your full balance is less than or equal to '
              }
            />
            <FormattedCurrency
              quantity={CONSTANTS.TWENTY_FIVE_THOUSAND}
              currency={code}
            />
            <Content
              cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:alert-1:part-2"
              copytext={' and if your accrued interest exceeds '}
            />
            <FormattedCurrency
              quantity={CONSTANTS.TWENTY_FIVE_THOUSAND}
              currency={code}
            />
            <Content
              cmsTag="Savings-servicing:Nominated-account:Transfer-into-nominated-account:alert-1:part-3"
              copytext={' maximum limit, then cheque will be issued.'}
            />
          </div>
        ) : (
          ''
        )}
        {!form || (form && !form.closeAccount.value) ? (
          <Amount
            form={form}
            account={account}
            onChange={this.updateCurrency}
            onBlur={this.onBlur}
            data={data}
            code={account && account.CurrencyCode}
            setAmountRules={this.setAmountRules}
            disabled={isDisabled}
            label="Amount to withdraw"
            cmsTag="GLOBAL:Anmount-to-withdraw"
            showErrors={true}
            validation={form.amount}
            field="amount"
            id="amount"
            inputGroupClassName="half-field"
            dp={2}
            ruleType="withdrawal_to_nominated_account"
          />
        ) : (
          ''
        )}
        <DropDown
          cmsTag="GLOBAL:Purpose"
          label="Purpose"
          id="purposeOptions"
          key={Date.now()} //Force redraw when refreshing due to a bug in the core dropdown component
          field="purposeOptions"
          disabled={isDisabled}
          onChange={this.onChange}
          validation={form.purposeOptions}
        >
          <option
            key="0"
            id="purpose-option-default"
            value=""
            defaultText="Please select"
          />
          <option
            key="1"
            id="purpose-option-1"
            value="Transfer to own account"
            defaultText="Transfer to own account"
          />
          <option
            key="2"
            id="purpose-option-2"
            value="Paying a friend"
            defaultText="Paying a friend"
          />
          <option
            key="3"
            id="purpose-option-3"
            value="Paying Family"
            defaultText="Paying Family"
          />
          <option
            key="4"
            id="purpose-option-4"
            value="Pay for a service"
            defaultText="Pay for a service"
          />
          <option
            key="5"
            id="purpose-option-5"
            value="Buying goods"
            defaultText="Buying goods"
          />
          <option
            key="6"
            id="purpose-option-6"
            value="Paying for building work"
            defaultText="Paying for building work"
          />
          <option
            key="7"
            id="purpose-option-7"
            value="Property or large purchase"
            defaultText="Property or large purchase"
          />
          <option
            key="8"
            id="purpose-option-8"
            value="Transfer to an investment"
            defaultText="Transfer to an investment"
          />
          <option
            key="9"
            id="purpose-option-9"
            value="Paying for Car"
            defaultText="Paying for Car"
          />
          <option
            key="10"
            id="purpose-option-10"
            value="Paying for Holiday"
            defaultText="Paying for Holiday"
          />
          <option
            key="11"
            id="purpose-option-11"
            value="OTHER"
            defaultText="OTHER"
          />
        </DropDown>

        <div className="form-button-group">
          <Button
            id="create-transfer-to-nominated-account"
            type="submit"
            color="primary"
            disabled={isDisabled}
          >
            <Content
              cmsTag="GLOBAL:Review-transfer-details"
              copytext="Review withdrawal details"
            />
          </Button>
          <Link
            id={`back-to-move-money`}
            className={`btn btn-secondary`}
            role="button"
            to={`/move-money/index`}
          >
            <Content
              cmsTag="GLOBAL:Back-to-move-money"
              copytext="Back to move money"
            />
          </Link>
        </div>
      </Form>
    );
  }
}

TransferForm.propTypes = {
  account: PropTypes.object,
  accountForm: PropTypes.any,
  setStateDynamic: PropTypes.func,
  combineForm: PropTypes.any,
  saveData: PropTypes.any,
  data: PropTypes.any,
  callAccountValidations: PropTypes.func,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.any,
  withdrawPermitted: PropTypes.bool,
  maxWithdrawals: PropTypes.number,
  nominatedAccount: PropTypes.object,
};

export default TransferForm;
