import brandUserManagementDefinition from './reducers/brandUserManagementDefinition';

const customUserManagementDefinition = (state = {}, action) => {
  var newState = state;
  newState = brandUserManagementDefinition(newState, action);

  return newState;
};

export default customUserManagementDefinition;
