import {
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_REQUEST,
  RESET_DOCUMENT_DOWNLOAD,
  FETCH_DOCUMENT_DOWNLOAD_SUCCESS,
  FETCH_DOCUMENT_DOWNLOAD_FAILURE,
  FETCH_DOCUMENT_DOWNLOAD_REQUEST,
} from '../actions/documentTypes';
import update from 'immutability-helper';

const brandDocumentsDefinition = (state = {}, action) => {
  let oldAccounts;
  let index;
  let account;
  switch (action.type) {
    case RESET_DOCUMENT_DOWNLOAD:
      return {
        ...state,
        downloadedDocument: null,
      };
    case FETCH_DOCUMENTS_SUCCESS: {
      oldAccounts = state.accounts;
      index = oldAccounts.Accounts.findIndex(
        x => x.AccountKey.Key === action.accountkey.Key,
      );
      account = oldAccounts.Accounts[index];
      const documents = action.documents;

      const accountWithDocuments = update(account, {
        documents: { $set: documents },
      });
      const updatedAccounts = update(oldAccounts, {
        Accounts: { [index]: { $set: accountWithDocuments } },
      });
      return {
        ...state,
        accounts: updatedAccounts,
        isFetching: action.isFetching,
      };
    }

    case FETCH_DOCUMENTS_FAILURE:
    case FETCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    case FETCH_DOCUMENT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadedDocument: {
          document: action.downloadedDocument,
          documentKey: action.documentKey,
        },
        isFetching: action.isFetching,
      };
    case FETCH_DOCUMENT_DOWNLOAD_FAILURE:
    case FETCH_DOCUMENT_DOWNLOAD_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};

export default brandDocumentsDefinition;
