/* eslint-disable no-mixed-spaces-and-tabs, prettier/prettier */

import { cmsDefinition  } from '@myie/interact-cms'
import { sharedDefinition  } from '@myie/interact-shared'
import { twoFactorDefinition  } from '@myie/interact-two-factor'
import { accountsDefinition as accountsSharedDefinition } from '@myie/interact-accounts'
import { authenticationDefinition  } from '@myie/interact-authentication'
import { payByCardDefinition  } from '@myie/interact-pay-by-card'
import { messagingTwowayDefinition  } from '@myie/interact-messaging-twoway'
import { messagingSystemDefinition  } from '@myie/interact-messaging-system'
import { custombrandAccountsDefinition } from '@myie/interact-brand-accounts';
import {custombrandMessageAttachmentDefinition } from '@myie/interact-brand-message-attachments';
import { userManagementDefinition  } from '@myie/interact-user-management'
import {moveMoneyDefinition } from '@myie/interact-move-money';

import { accountsDefinition as accountsSavingsDefinition } from '@myie/interact-account-type-savings'

const accountsDefinition = (state = {}, action) => {
    let newState = state
    newState = accountsSharedDefinition(newState, action)
    newState = custombrandMessageAttachmentDefinition(newState, action)
    newState = accountsSavingsDefinition(newState, action)
    newState = custombrandAccountsDefinition(newState, action)
    return newState
}

const interactReducers = { moveMoneyDefinition, cmsDefinition, sharedDefinition, twoFactorDefinition, accountsDefinition, authenticationDefinition, payByCardDefinition, messagingTwowayDefinition, messagingSystemDefinition, userManagementDefinition }

export default interactReducers
