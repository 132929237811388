export const DEPOSIT_ACCOUNT_REQUEST = 'DEPOSIT_ACCOUNT_REQUEST';
export const DEPOSIT_ACCOUNT_SUCCESS = 'DEPOSIT_ACCOUNT_SUCCESS';
export const DEPOSIT_ACCOUNT_FALIURE = 'DEPOSIT_ACCOUNT_FALIURE';
export const RESET_DEPOSIT_ACCOUNT = 'RESET_DEPOSIT_ACCOUNT';

export const RESET_MATURITY_OPTIONS = 'RESET_MATURITY_OPTIONS';
export const MATURITY_OPTIONS_REQUEST = 'MATURITY_OPTIONS_REQUEST';
export const MATURITY_OPTIONS_SUCCESS = 'MATURITY_OPTIONS_SUCCESS';
export const MATURITY_OPTIONS_FALIURE = 'MATURITY_OPTIONS_FALIURE';

export const RESET_SHOW_MATURITY_OPTIONS = 'RESET_SHOW_MATURITY_OPTIONS';
export const SHOW_MATURITY_OPTIONS_REQUEST = 'SHOW_MATURITY_OPTIONS_REQUEST';
export const SHOW_MATURITY_OPTIONS_SUCCESS = 'SHOW_MATURITY_OPTIONS_SUCCESS';
export const SHOW_MATURITY_OPTIONS_FALIURE = 'SHOW_MATURITY_OPTIONS_FALIURE';
