import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Content, Markdown, DropDown, AppMeta } from '@myie/interact-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '@myie/interact-accounts';
import TransferForm from './TransferForm';
import { CONTACTS } from '@myie/interact-local-dom';

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
    };
  }

  submitForm = form => {
    const { submitForm } = this.props;
    submitForm('ReviewTransfer', form);
  };

  static getDerivedStateFromProps = (nextProps, stete) => {
    if (nextProps.account && !stete.account) {
      let account;
      if (nextProps.accounts) {
        account = nextProps.account;
      } else {
        account = null;
      }
      return {
        account: account,
      };
    }

    return null;
  };

  accountOptions(prefix, item, index, extendedId) {
    return (
      <option
        id={`transfer-from-dropdown-${extendedId}-option-${index}`}
        key={prefix + item.AccountKey.Context}
        value={item.AccountKey.Key}
      >
        {item.AccountName} {item.AccountNumber}
      </option>
    );
  }

  render() {
    const {
      options = [],
      onChangeAccount,
      onCancelled,
      //isFetching,
      accountForm,
      setStateDynamic,
      data,
    } = this.props;

    return (
      <React.Fragment>
        <AppMeta
          id="meta-data"
          contacts={CONTACTS}
          stage="child"
          title={`Transfer to another ${CONTACTS.companyNameShorthand} member's savings account step 1`}
          metaDescription={`Transfer to another ${CONTACTS.companyNameShorthand} member's savings account step 1`}
        />
        <Content
          tag="h2"
          cmsTag="Savings-servicing:Transfer-to-another-account:Create:h2-1"
          copytext="Transfer details"
        />
        <Markdown
          cmsTag="Savings-servicing:Transfer-to-another-account:Create:section-1"
          markdown={`\nWe will need some information to transfer your money.\n\nSome of our savings accounts are subject to conditions, such as notice periods and withdrawal restrictions.\n\nPlease check the terms and conditions of your savings account first to ensure you can transfer money. You can find this in the "Account Details" section.`}
        />
        <DropDown
          cmsTag="GLOBAL:Account-to-transfer-from"
          label={'Account to transfer from'}
          id="transfer-from-dropdown"
          key={Date.now() + '1'} //Force redraw when refreshing due to a bug in the core dropdown component
          field="accountFormValidation"
          onChange={onChangeAccount}
          validation={accountForm.accountFormValidation}
        >
          <option
            id={`transfer-from-dropdown-default`}
            key="0"
            value=""
            defaultText="Please select"
          ></option>
          {options.map((item, index) => {
            return this.accountOptions('accountType', item, index, 'from');
          })}
        </DropDown>
        {/* <RedirectByAccountDropdown
          label={'Account to transfer from'}
          extendedId="from"
          accounts={accounts}
          optionFunc={this.noticeFilter}
          baseUrl="/transfer-to-another/create/moveMoney/"
          defaultSelect={`${
            account && account.AccountKey ? account.AccountKey.Key : null
          }`}
          onChangeAccount={onChangeAccount}
          filter="noticeCreate"
          id="craete-notice-to-withdraw"
        />
        {accountForm ? (
          <ListItem
            key={'account-0'}
            validation={accountForm.accountFormValidation}
            tagType={'p'}
            index={0}
            field={'accountFormValidation'}
            id={'0'}
          />
        ) : (
          ''
        )} */}
        <TransferForm
          account={this.props.account}
          saveData={this.props.saveData}
          accountForm={accountForm}
          setStateDynamic={setStateDynamic}
          callAccountValidations={this.props.callAccountValidations}
          data={data}
          onCancelled={onCancelled}
          onSubmit={this.submitForm}
        />
      </React.Fragment>
    );
  }
}

Create.propTypes = {
  accounts: PropTypes.any,
  data: PropTypes.any,
  saveData: PropTypes.any,
  withdrawalAccount: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.any,
  onCreate: PropTypes.func,
  match: PropTypes.any,
  actionDate: PropTypes.any,
  onChangeAccount: PropTypes.func,
  onCancelled: PropTypes.func,
  fetchAccountsIfNeeded: PropTypes.func,
  resetMakeWithdrawal: PropTypes.func,
  accountFormValidation: PropTypes.func,
  setStateDynamic: PropTypes.any,
  makeaWithdraw: PropTypes.func,
  account: PropTypes.any,
  callAccountValidations: PropTypes.func,
  accountForm: PropTypes.any,
  submitForm: PropTypes.any,
  options: PropTypes.array,
};

const mapStateToProps = state => {
  const { accountsDefinition, custombrandSavingsServicingDefinition } = state;
  return {
    ...accountsDefinition,
    ...custombrandSavingsServicingDefinition,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Create));
